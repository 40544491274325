
class LayerController {
    constructor() {

    }

    deactivate() {
        
    }

    onFloorChange(floorData) {

    }

    onModeChange(modeName) {

    }

    onToolChange(tool, floorData) {
        
    }
}

export default LayerController;