import React, { useState } from 'react';
import Button from '../../UiComponents/Button';
import BankItem from './BankItem';
import CanvasBox from './CanvasBox';
import heatmapTypes from './heatmapTypes';
import List from './StyledComponents/CanvasBoxList';
import ColorMarker from './StyledComponents/ColorMarker'
import {getZoneColor} from '../canvas/colorUtils';


const ZoneBank = props => {

    const placeZoneHandler = (zone) => {
        props.actionHandler(heatmapTypes.actions.DESELECT_ALL_ZONES, null);
        props.actionHandler(heatmapTypes.actions.PLACE_ZONE, zone);
        props.setSelectedZone(zone.floorElementId);
    }

    const removeZone = (zone) => {
        props.actionHandler(heatmapTypes.actions.REMOVE_ZONE, zone);
    }

    const markZone = (zone) => {
        props.actionHandler(heatmapTypes.actions.DESELECT_ALL_ZONES, null);

        if (zone.floorElementId != props.selectedZone) {
            props.actionHandler(heatmapTypes.actions.ZONE_SELECT, zone);
            props.setSelectedZone(zone.floorElementId);
        } else {
            props.setSelectedZone(null);
        }
    }

    const doneEditingHandler = () => {
        props.actionHandler(heatmapTypes.actions.DESELECT_ALL_ZONES, null);
        props.setSelectedZone(null);
    }

    return <CanvasBox>
        <h4>Zones</h4>
        <List>
            {props.zones.map((zone) => {
                var zoneColor = getZoneColor(zone, props.zones);
                return <BankItem
                    key={zone.floorElementId}
                    displayName={zone.zoneName}
                    item={zone}
                    placeItem={placeZoneHandler}
                    removeItem={() => removeZone(zone)}
                    isMarked={zone.floorElementId === props.selectedZone}
                    markItem={markZone}
                    floor={props.floors.find(floor => floor.floorId === zone.floorId)}
                    colorMarker={<ColorMarker color={zoneColor}/>}
                />
            })}
        </List>
        {props.selectedZone ?
            <>
                <Button onClick={doneEditingHandler} primary>Done editing</Button>
            </>
            : <></>}

    </CanvasBox>
}

export default ZoneBank;