import styled from "styled-components";
import theme from "../../../UI/theme";
import React from "react";
import { CardHeader } from "../insightsStyles";
import { resolveTimeText } from "./decibelInsightsUtils";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    background-color: ${theme.colors.darkSpace90};
    padding: 20px;
    box-sizing: border-box;
    color: ${theme.colors.textGray};

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        padding: 10px;
    }
`

const WarningsContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;
`

const TimeLabel = styled.label`
    color: ${theme.colors.textGray};
    border-right: 2px solid ${theme.colors.raspberryRed};
    padding-right: 10px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    white-space: nowrap;
`

const ZoneName = styled.label`
    border-bottom: 1px solid ${theme.colors.textGray}50;
    grid-column: 1 / -1;
`

const HistoryInsightsReport = props => {
    if (props?.warnings?.some(zoneList => zoneList?.length > 0)) {
        return <Container>
            <CardHeader>Decibel Level Notifications</CardHeader>

            <WarningsContainer>
                {props.zones?.map(zone => {
                    const zoneWarnings = props.warnings?.find(warningList => warningList?.some(warning => warning?.zoneId === zone?.zoneId));
                    if (zoneWarnings?.length) {
                        return <React.Fragment key={zone?.zoneId}>
                            <ZoneName>{zone?.zoneName}</ZoneName>
                            {zoneWarnings?.sort((a, b) => a?.start > b?.start).map((warning, index) => {
                                return <React.Fragment key={`${index}`}>
                                    <TimeLabel>{resolveTimeText(warning?.start)}</TimeLabel>
                                    <label>{warning.text}</label>
                                </React.Fragment>
                            })}
                        </React.Fragment>
                    }
                })}
            </WarningsContainer>
        </Container>
    } else {
        return <></>
    }

}

export default HistoryInsightsReport;