import React from 'react';
import useServerStatus from '../../hooks/useServerStatus';

const ConnectionStatus = props => {
    const serverStatus = useServerStatus();

    if (serverStatus === false) {
        return <div>
             Not connected
        </div>
    } else {
        return <></>;
    }

}

export default ConnectionStatus;