import styled from "styled-components";
import { TableRow } from "../support/SupportStyles";
import CustomerHealthMetrics from "./CustomerHealthMetrics";
import { RowDetailsContainer } from "./customerHealthStyles";
import entityTypes from "./entityTypes";
import SensorHealthMetrics from "./SensorHealthMetrics";
import ZoneHealthMetrics from "./ZoneHealthMetrics";

const SummaryTableData = styled.td.attrs({ colSpan: 8 })`
`

const CustomerHealthOverview = props => {
    return <TableRow>
        <SummaryTableData>
            <RowDetailsContainer>
                {props.metrics?.some(metric => metric.entityType === entityTypes.customer.key) ?
                    <CustomerHealthMetrics
                        customer={props.customer}
                        metrics={props.metrics?.filter(metric => metric?.entityType === entityTypes.customer.key)}
                        previousMetrics={props.previousMetrics?.filter(metric => metric?.entityType === entityTypes.customer.key)}
                    />
                    : <></>}
                {props.metrics?.some(metric => metric.entityType === entityTypes.zone.key) ?
                    <ZoneHealthMetrics
                        customerId={props.customer?.customerId}
                        metrics={props.metrics?.filter(metric => metric?.entityType === entityTypes.zone.key)}
                        previousMetrics={props.previousMetrics?.filter(metric => metric?.entityType === entityTypes.zone.key)}
                    />
                    : <></>}
                {props.metrics?.some(metric => metric.entityType === entityTypes.sensor.key) ?
                    <SensorHealthMetrics
                        customerId={props.customer?.customerId}
                        metrics={props.metrics?.filter(metric => metric?.entityType === entityTypes.sensor.key)}
                        previousMetrics={props.previousMetrics?.filter(metric => metric?.entityType === entityTypes.sensor.key)}
                    />
                    : <></>}
            </RowDetailsContainer>
        </SummaryTableData>
    </TableRow>
}

export default CustomerHealthOverview;