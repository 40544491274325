import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Chart, registerables } from 'chart.js';
import theme from '../../UI/theme';
Chart.register(...registerables);


const Container = styled.div`
    display: inline-block; 
    width: 800px;
    height: auto;
    margin: 20px 20px; 

    @media only screen and (max-width: 800px) {
        width: 400px;
        height: auto;
    }

    @media only screen and (max-width: 450px) {
        width: 320px;
        height: auto;
    }
`

const chartData = {
    labels: ['January', 'February', 'March',
                 'April', 'May'],
    datasets: [
        {
            type: 'bar',
            label: 'Weekly evolution',
            backgroundColor: theme.colors.greenReadable,
            borderColor: 'rgba(0,0,0,0)',
            borderWidth: 2,
            data: [5, 4, 3, 2, 1]
        },
    ]
};

const options = {
    title:{
        display:true,
        text:'Average Rainfall per month',
        fontSize:20
    },
    legend:{
        display:true,
        position:'right'
    }
}

const defaultChartConfig = {
    data: chartData,
    options
}

const NorthstarChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const chartConfig = useRef(JSON.parse(JSON.stringify(defaultChartConfig)));
    

    useEffect(() => {

        if (props.data && props.labels) {
            chartConfig.current.data.labels = props.labels;
            chartConfig.current.data.datasets[0].data = props.data;
            chartConfig.current.data.datasets[0].backgroundColor = props.data.map((point, i) => (i == props.data.length-1) ?  theme.colors.greenEnergy+'85' : theme.colors.greenEnergy)
        }
    }, [props.data]);


    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, { ...chartConfig.current});
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <Container>
            <canvas id={props.chartID} ref={chartContainer} />
        </Container>
    );
}

export default NorthstarChart;


