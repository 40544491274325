import ImageList from "../../../../imageHandling/ImageList"
import ImageUpload from "../../../../imageHandling/ImageUpload"
import { PropsTable } from "../../../table/styles/ListStyles";


const DocumentationPhotos = ({ customerId, heatmapFloorElement, saveUpdatedFloorElement, readOnly }) => {

    const onUpload = async (fileUrls) => {
        try {
            let updatedUrls = heatmapFloorElement.imageUrls || [];
            updatedUrls = [...updatedUrls, ...fileUrls];
            await saveUpdatedFloorElement({ imageUrls: updatedUrls });
        } catch (err) {
            console.log('Error failed to update heatmapfloorelement', err);
        }
    }

    const onDelete = async (imageUrl) => {
        try {
            let updatedUrls = heatmapFloorElement.imageUrls?.filter(url => url !== imageUrl);
            await saveUpdatedFloorElement({ imageUrls: updatedUrls });
        } catch (err) {
            console.log('Failed to delete');
        }
    }

    return <>
        {(readOnly === true)?
            <></>
        :
            <ImageUpload
                customerId={customerId}
                onUpload={onUpload}
            />
        }

        {heatmapFloorElement?.imageUrls ? <>
            <ImageList
                customerId={customerId}
                imageUrls={heatmapFloorElement.imageUrls}
                onDelete={onDelete}
                readOnly={readOnly}
            />
        </> : <></>}
    </>
}

export default DocumentationPhotos;