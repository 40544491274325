import { useState, useEffect, useContext } from "react"
import UserContext from "../../../../hooks/UserContext"
import LoadDataContainer, { loadDataHandler } from "../../../UiComponents/containers/LoadDataContainer";
import { loadingStates } from "../../../installation/installationTypes";
import { getUserVenues, getVenueAdmins } from "../../../../scripts/permission";
import { checkPermissionBundle, getDefaultVenueId, getUserDisplayName } from "../userAdministration/userAdministrationUtils";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import ViewHeader from "../../../UiComponents/ViewHeader";
import { ButtonRowContainer, ModalHeader } from "../../../panel/ModalStyles";
import Button from "../../../UiComponents/Button";
import { updateUser } from "../../../../scripts/user";
import styled from "styled-components";
import theme from "../../../../UI/theme";
import { bundleColumns } from "../userAdministration/PermissionsTable";
import { SettingsSection } from "../../settingsStyles";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-gap: 10px;
    }
`

const VenueCard = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    background-color: ${theme.colors.darkSpace90};
    padding: 10px;
    box-sizing: border-box;
    border: 2px;
`

const HeaderContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: baseline;
`

const UserVenuesView = ({ }) => {
    const userContext = useContext(UserContext);
    const [venueAdmins, setVenueAdmins] = useState();
    const [venues, setVenues] = useState();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    useEffect(() => {
        loadDataHandler(getData, setLoadingState);
    }, [])

    const getData = async () => {
        const venueAdminsRes = await getVenueAdmins();
        const venuesRes = await getUserVenues();
        setVenueAdmins(venueAdminsRes);
        setVenues(venuesRes);
    }

    const setDefault = async (venueId) => {
        try {
            await updateUser({ userId: userContext.user.userId, defaultVenueId: venueId });
            userContext.setUser(current => ({ ...current, defaultVenueId: venueId }));
        } catch (err) {
            console.log('Failed to update user');
        }
    }

    return <>
        <ViewHeader headerText={'My venues'} backLink={'/settings'} />
        <LoadDataContainer loadingState={loadingState}>
            <Container>
                {venues && venueAdmins ? <>
                    {venues.map(venue => {
                        const myVenuePermissions = userContext.permissions.filter(p => p.entityId === venue.customerId.toString());
                        const bundleKey = checkPermissionBundle(myVenuePermissions.map(p => p.permissionType));
                        const bundleDisplay = bundleColumns.find(col => col.value === bundleKey)?.display;
                        const admins = venueAdmins.filter(u => u.entityId === venue.customerId.toString());
                        const isDefault = getDefaultVenueId(userContext.user, venues) === venue.customerId;

                        return <VenueCard>
                            <HeaderContainer>
                                <ModalHeader>{venue.companyName}</ModalHeader>
                                {isDefault ? <ModalHeader style={{ color: theme.colors.darkSpace50 }}>Default</ModalHeader> : <></>}
                            </HeaderContainer>
                            <SettingsSection>
                                <label>My permission</label>
                                <TextMuted>{bundleDisplay}</TextMuted>
                            </SettingsSection>

                            <SettingsSection>
                                <label>Venue admin{admins?.length > 1 ? 's' : ''}</label>
                                {admins?.length ? <>
                                    {admins?.map(user => {
                                        return <TextMuted key={user?.userId}>
                                            {user.firstName ? getUserDisplayName(user) : user.username} {user.email ? `(${user.email})` : ''}
                                            {user.userId === userContext.user.userId ? <span style={{ color: theme.colors.greenEnergy }}>  You</span> : <></>}
                                        </TextMuted>
                                    })}
                                </> : <>
                                    <TextMuted>No venue admins</TextMuted>
                                </>}
                            </SettingsSection>

                            {venues.length > 1 ? <>
                                <SettingsSection>
                                    <ButtonRowContainer>
                                        {isDefault ? <>
                                            <Button tertiary small onClick={() => setDefault(null)}>Remove as default</Button>
                                        </> : <>
                                            <Button tertiary small onClick={() => setDefault(venue.customerId)}>Set as default</Button>
                                        </>}
                                        {/* <RemoveVenueButton user={userContext.user} venue={venue} /> */}
                                    </ButtonRowContainer>
                                </SettingsSection>
                            </> : <></>}
                        </VenueCard>
                    })}
                </> : <></>}
            </Container>
        </LoadDataContainer>
    </>
}

export default UserVenuesView;