import Button from "../UiComponents/Button";

const FileInputButton = ({inputRef, onFileSelect }) => {

    const handleFileSelect = () => {
        inputRef.current.click();
    }

    return <>
        <input
            ref={inputRef}
            type="file"
            accept="image/*"
            multiple
            onChange={onFileSelect}
            style={{ display: 'none' }}
        />

        <Button tertiary small style={{marginRight: '12px'}} onClick={handleFileSelect}>
            Upload photos
        </Button>
    </>
}

export default FileInputButton