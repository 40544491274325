import React, { useEffect, useState } from 'react';
import { useSubscription } from '../../../hooks/useSubscription';
import { subscriptionsTypes } from '../../../scripts/subscription/subscriptionTypes';
import { getZone, updateZoneLiveSettings } from '../../../scripts/zone';
import ZoneInfoModal from './ZoneInfoModal';
import { postUserInteraction } from '../../../scripts/userInteraction';
import { userInteractionTypes } from '../../admin/customerDetails/userInteractionTypes';
import { useContext } from 'react';
import UserContext from '../../../hooks/UserContext';
import { getWavedVolume } from '../../../scripts/volume';
import ZoneSkeleton from '../skeletons/ZoneSkeleton';
import WaVolumeOff from '../../UiComponents/Icons/WaVolumeOff';
import WaVolumeUp from '../../UiComponents/Icons/WaVolumeUp';
import { hasVenuePermission, permissionTypes } from '../../permission/permissions';
import { StyledLiveChart, StyledMuteButton, StyledRegulationButton, StyledVibeButton, StyledVolumeSlider, StyledZoneVolume, ZoneContainer, ZoneHeader } from './zoneStyles';
import hierarchyTypes from '../../settings/venue/device/hierarchyTypes';
import MasterAutomatingInfo from '../masters/MasterAutomatingInfo';

const Zone = props => {
    const calibrationSysvols = props.zone?.calibrationPoints?.points?.map(point => point.sysvol);
    const [zoneLive, setZoneLive] = useSubscription(subscriptionsTypes.zoneLive, props.zone.zoneId);
    const [lastVolumeCommit, setLastVolumeCommit] = useState(0);
    const [systemVolume, setSystemVolume] = useState(!isNaN(zoneLive?.sysvolAbsolute) ? zoneLive?.sysvolAbsolute : null);
    const [volume, setVolume] = useState(!isNaN(zoneLive?.sysvol) ? zoneLive?.sysvol : 0);
    const [isChangingVolume, setIsChangingVolume] = useState(false);
    const [showZoneModal, setShowZoneModal] = useState(false);
    const userContext = useContext(UserContext);

    useEffect(() => {
        if (isChangingVolume === false && (new Date()).getTime() - lastVolumeCommit >= 2000) {
            setVolume(!isNaN(zoneLive?.sysvol) ? zoneLive?.sysvol : null);
            setSystemVolume(!isNaN(zoneLive?.sysvolAbsolute) ? zoneLive?.sysvolAbsolute : null);
        }
    }, [zoneLive?.sysvol, zoneLive?.sysvolAbsolute]);

    const onVolumeChange = (value) => {
        setSystemVolume(Number(value));
        setVolume(getWavedVolume(value, calibrationSysvols));
    }

    const onVolumeCommitted = (value) => {
        setSystemVolume(Number(value));
        setVolume(getWavedVolume(value, calibrationSysvols));
        setIsChangingVolume(false);
    }

    const updateZoneWithEvent = async (zoneProps) => {
        props.setZone(props.zone.zoneId, zoneProps);
        try {
            await updateZoneLiveSettings({ ...zoneProps });
        } catch (err) {
            props.setZone(props.zone.zoneId, await getZone(props.zone.zoneId));
        }
    }

    const toggleRegulation = async (newState) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }

        await updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, isRegulating: +newState });
        postUserInteraction(props.zone.customerId, {
            zoneId: props.zone.zoneId,
            setting: userInteractionTypes.isRegulating.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    const toggleMute = async (newState) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }

        await updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, mute: +newState });
        postUserInteraction(props.zone.customerId, {
            zoneId: props.zone.zoneId,
            setting: userInteractionTypes.mute.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    if (props.zone) {
        return <>
            {props.zone && zoneLive ?
                <>
                    <ZoneContainer
                        groupHasSourceSelectors={props.groupHasSourceSelectors}
                        hasSourceSelector={props.hasSourceSelector}
                        isMasterZone={props.zone?.hierarchyType === hierarchyTypes.MASTER.value}
                    >
                        <ZoneHeader
                            onClick={() => setShowZoneModal(true)}
                            disabled={!props.processorConnected}>
                            {props.zone?.zoneName}
                        </ZoneHeader>

                        <StyledZoneVolume
                            disabled={!props.processorConnected}
                            zone={props.zone}
                            wavedVolume={volume}
                            systemVolume={systemVolume}
                            isRegulating={props.zone?.isRegulating && props.isRegulatingAudioSource}
                            shouldRegulate={props.zone?.shouldRegulate}
                        />

                        <StyledMuteButton
                            onClick={() => toggleMute(!props.zone?.mute)}
                            deactivated={!!props.zone?.mute}
                            disabled={!props.processorConnected}
                        >
                            {props.zone?.mute ? <WaVolumeOff /> : <WaVolumeUp />}
                            <label>{props.zone?.mute ? 'Muted' : 'On'}</label>
                        </StyledMuteButton>

                        {(props.zone?.hierarchyType === hierarchyTypes.MASTER.value && props.zone?.isRegulating === 1 && zoneLive?.isRegulatingAudioSource === 1) ||
                            (props.zone?.hierarchyType !== hierarchyTypes.MASTER.value && props.zone?.isRegulating && props.isRegulatingAudioSource) ? <>
                            {props.zone?.hierarchyType === hierarchyTypes.MASTER.value ? <>
                                <MasterAutomatingInfo zone={props.zone} />
                            </> : <>
                                <StyledLiveChart
                                    zone={props.zone}
                                    zoneLive={zoneLive}
                                    processorConnected={props.processorConnected} />

                                <StyledVibeButton
                                    zone={props.zone}
                                    updateZoneWithEvent={updateZoneWithEvent}
                                    vibe={props.zone?.zoneModeOrderIndex}
                                    disabled={!props.processorConnected}
                                />
                            </>}
                        </> : <>
                            <StyledVolumeSlider
                                volume={systemVolume ?? 0}
                                lastVolumeCommit={lastVolumeCommit}
                                setLastVolumeCommit={setLastVolumeCommit}
                                onChangeStarted={(value) => { setIsChangingVolume(true); }}
                                onChange={(value) => { onVolumeChange(value); }}
                                onChangeCommitted={(value) => { onVolumeCommitted(value); }}
                                disabled={!props.processorConnected}
                                zone={props.zone}
                                maximum={props.zone?.panelLimit}
                            />
                        </>}

                        <StyledRegulationButton
                            onClick={() => toggleRegulation(!props.zone?.isRegulating)}
                            deactivated={!props.zone?.isRegulating || !props.isRegulatingAudioSource}
                            disabled={
                                !props.isRegulatingAudioSource ||
                                props.zone?.shouldRegulate === 0 ||
                                !props.processorConnected ||
                                props.zone?.hierarchyType === hierarchyTypes.MASTER.value
                            }
                            unavailable={
                                props.zone?.shouldRegulate === 0 ||
                                props.zone?.hierarchyType === hierarchyTypes.MASTER.value}
                            isRegulating={props.zone?.isRegulating}
                            isRegulatingAudioSource={props.isRegulatingAudioSource}
                        />

                    </ZoneContainer>
                </>
                : <>
                    <ZoneSkeleton
                        hasSourceSelector={props.hasSourceSelector}
                        groupHasSourceSelectors={props.groupHasSourceSelectors} />
                </>
            }

            <ZoneInfoModal
                show={showZoneModal}
                close={() => setShowZoneModal(false)}
                zone={props.zone}
                schedules={props.schedules}
                updateZoneWithEvent={updateZoneWithEvent}
                processorConnected={props.processorConnected}
            />
        </>
    } else {
        return <></>
    }
}

export default Zone;