import threeSeaterCouch from '../images/threeSeaterCouch.svg';
import roundTable from '../images/roundTable.svg';
import largeTable from '../images/largeTable.svg';
import smallTable from '../images/smallTable.svg';
import cowHornChair from '../images/cowHornChair.svg';
import windsorChair from '../images/windsorChair.svg';

export default {
    THREE_SEATER_COUCH: {
        displayName: 'Three seater couch',
        source: threeSeaterCouch,
        type: 'THREE_SEATER_COUCH',
        defaultSize: 2
    },
    LARGE_TABLE: {
        displayName: 'Large table',
        source: largeTable,
        type: 'LARGE_TABLE',
        defaultSize: 2
    },
    SMALL_TABLE: {
        displayName: 'Small Table',
        source: smallTable,
        type: 'SMALL_TABLE',
        defaultSize: 1
    },
    ROUND_TABLE: {
        displayName: 'Round table',
        source: roundTable,
        type: 'ROUND_TABLE',
        defaultSize: 1,
    },
    COW_HORN_CHAIR: {
        displayName: 'Cow horn chair',
        source: cowHornChair,
        type: 'COW_HORN_CHAIR',
        defaultSize: 1
    },
    WINDSOR_CHAIR: {
        displayName: 'Windsor chair',
        source: windsorChair,
        type: 'WINDSOR_CHAIR',
        defaultSize: 1
    }
}