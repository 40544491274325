import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/schedule';

async function getSchedules(customerId) {
    const res = await axios.get(`${hostname}${path}?customerId=${customerId}`);
    return res.data;
}

async function deleteSchedule(scheduleId) {
    const res = await axios.delete(`${hostname}${path}?scheduleId=${scheduleId}`);
    return res.data;
}

async function updateSchedule(schedule) {
    const res = await axios.put(`${hostname}${path}`, schedule);
    return res.data;
}

async function createSchedule(schedule) {
    const res = await axios.post(`${hostname}${path}`, schedule);
    return res.data;
}

function compareScheduleTimesAsc(a, b) {
    if (parseInt(a.time) < parseInt(b.time)) {
        return -1;
    }
    if (parseInt(a.time) > parseInt(b.time)) {
        return 1;
    }
    return 0;
}

function compareScheduleTimesDesc(a, b) {
    if (parseInt(a.time) > parseInt(b.time)) {
        return -1;
    }
    if (parseInt(a.time) < parseInt(b.time)) {
        return 1;
    }
    return 0;
}

export {
    getSchedules,
    updateSchedule,
    createSchedule,
    deleteSchedule,
    compareScheduleTimesAsc,
    compareScheduleTimesDesc
}