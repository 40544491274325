import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import styled from "styled-components";
import serverConnection from "../../../../../scripts/server/ServerConnection";
import NeutralSpinner from "../../../../UiComponents/Animations/NeutralSpinner";
import Button from "../../../../UiComponents/Button";
import { testStates } from "../../../installationTypes";

const Container = styled.div`
    div {
        margin: 4px 0;
    }
`

const TestProcessorConnection = ({ processor, hub }) => {
    const [state, setState] = useState(testStates.NONE);
    const [result, setResult] = useState();
    
    const testConnection = async () => {
        try {
            setState(testStates.LOADING);
            const res = await serverConnection.hubEventHandler.sendEvent(
                hub.hubId,
                hub.customerId,
                'PROCESSOR_CONNECTION_TEST',
                {...processor}
            )
            setResult(res);
            setState(testStates.SUCCESS);
        } catch (err) {
            setState(testStates.FAILED);
        }
    }
    return <Container>
        {state !== testStates.LOADING ? <>
            <div>
            <Button small tertiary onClick={testConnection}>Check connection</Button>
            </div>
        </> : <></>}
        {state === testStates.SUCCESS || state === testStates.FAILED ? <>
            <div>{result ? <>
                <FaCheckCircle /> <span>Reachable</span>
            </> : <>
                <span>Not reachable</span>
            </>}</div>
        </> : <></>}
        {state === testStates.LOADING ? <>
            <div>Testing... <NeutralSpinner /></div>
        </> : <></>}
    </Container>
}

export default TestProcessorConnection;