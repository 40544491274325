import styled from "styled-components";
import { updateSourceSelectorZoneGroup } from "../../../../scripts/sourceSelector";
import theme from "../../../../UI/theme";
import Zone from "./Zone";
import ZoneGroupDropdown from "./ZoneGroupDropdown";

const Container = styled.div`
    width: fill-available;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;
    background-color: ${theme.colors.darkSpace};
    padding: 10px;
    border-radius: 2px;
    box-sizing: border-box;
    align-items: center;
`

const ZonesContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`

const SourceSelectorGroup = props => {

    const onChangeZoneGroup = async (newZoneGroup) => {
        const pickedSourceSelector = (({ customerId, sourceSelectorId }) => ({ customerId, sourceSelectorId }))(props.sourceSelector);
        await updateSourceSelectorZoneGroup({ ...pickedSourceSelector, zoneGroupId: newZoneGroup?.zoneGroupId });
    }

    return <Container>

        <ZonesContainer>
            {props?.zones?.map((zone, index) => {
                return <Zone key={index} zone={zone} hasSourceSelector={true} />
            })}
        </ZonesContainer>

        <ZoneGroupDropdown small
            zoneGroups={props.zoneGroups}
            zoneGroupId={props.sourceSelector?.zoneGroupId}
            onClick={onChangeZoneGroup} />
    </Container>
}

export default SourceSelectorGroup;