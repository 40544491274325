import styled from 'styled-components';
import theme from '../../../UI/theme';

const RoundButton = styled.button`
    border: none;
    border-radius: 50%;
    height: ${props => props.height || 50}px;
    width: ${props => props.width || props.height || 50}px;
    font-size: 16px;
    color: ${props => props.color || theme.colors.textGray};
    background-color: ${props => props.backgroundColor || 'transparent'};

    :hover {
        cursor: pointer;
    }
`

export default RoundButton;