import Button from "../../../../UiComponents/Button";
import { installationTabs } from "../../../installationTypes";

const ShowMeterTabButton = ({
    setSelectedTab
}) => <Button
    small
    secondary
    onClick={() => setSelectedTab(installationTabs.find(tab => tab.key === 'METER'))}
>
    Add meter
</Button>

export default ShowMeterTabButton;