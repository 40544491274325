import ChoiceField from "../../../fields/ChoiceField";
import { sourceSelectorTypes } from "../sourceSelectorTypes";

const SourceMatrixChoiceField = ({ sourceSelector, sourceMatrixes, updateSourceSelector, cellControl }) => {
    const currentSourceMatrix = sourceMatrixes.find(sourceMatrix => sourceMatrix.sourceMatrixId === sourceSelector.sourceMatrixId);
    const disabled = disableSourceMatrixChoiceField(sourceSelector);
    return <ChoiceField
        selectedItem={currentSourceMatrix && { value: currentSourceMatrix.sourceMatrixId, label: currentSourceMatrix.name }}
        items={sourceMatrixes.map(sourceMatrix => ({ value: sourceMatrix.sourceMatrixId, label: sourceMatrix.name }))}
        setValue={(value) => updateSourceSelector({ sourceMatrixId: value })}
        disabled={disabled}
        cellControl={cellControl}
    />
}

export default SourceMatrixChoiceField;

export const disableSourceMatrixChoiceField = (sourceSelector) => {
    return sourceSelector.type !== sourceSelectorTypes.MATRIX;
}

export const hideSourceMatrixChoiceField = (sourceSelectors) => {
    return !sourceSelectors?.some(sourceSelector => !disableSourceMatrixChoiceField(sourceSelector))
}
