import styled, { css } from "styled-components";
import theme from "../../UI/theme";

const Container = styled.div`
    height: 20px;
    width: 100%;
    border-radius: 2px;
    background-color: ${theme.colors.darkSpace90};
    overflow: hidden;
`

const Progress = styled.div`
    height: 100%;
    width: ${props => props.width};
    background-color: ${theme.colors.greenReadable};
    border-radius: 2px;
    transition: width 0.5s, background-color 0.5s;

    ${props => props.width === "100%" && css`
      background-color: ${theme.colors.greenEnergy};
    `}
`

const ProgressBar = props => {
  return <Container>
    <Progress width={`${props.progress}%`} />
  </Container>
}

export default ProgressBar;