import Paper, { Point } from "paper";
import canvasController from "./CanvasController";
import heatmapTypes from "../container/heatmapTypes";
import * as Hammer from 'hammerjs';

const zoomLimits = {
    min: 10,
    max: 150
};
const panLimits = {
    x: 20,
    y: 20
};

let floorCenter = new Point(0, 0);
let dragStartPoint;
let view;


const initHeatmap = (paperView, _floorLayerController) => {
    view = paperView;
    view.zoom = 30;
    view.center = new Point(0, 0);

    var canvas = document.getElementById('canvas');
    var hammertime;
    addPanEvents(view);
    if (canvasController.heatmapType != heatmapTypes.heatmapTypes.MINI) {
        hammertime = new Hammer(canvas, { prevent_default: true });
        hammertime.on('pinchin pinchout', onPinch);
        hammertime.get('pinch').set({ enable: true });
        canvas.addEventListener('wheel', onWheel);
    }
}


function onWheel(event) {
    event.preventDefault();
    var delta = event.deltaY;
    var zoomFactor = 1.1;
    var oldZoom = view.zoom;
    var zoomValue = 0;
    if (delta > 0) {
        zoomValue = oldZoom / zoomFactor;
    } else if (delta < 0) {
        zoomValue = oldZoom * zoomFactor;
    }
    if (zoomValue != 0 && zoomValue > zoomLimits.min && zoomValue < zoomLimits.max) {
        view.zoom = zoomValue;
    }
}

function onPinch(event) {
    event.preventDefault();
    var zoomFactor = 1.02;
    var oldZoom = view.zoom;
    var zoomValue = 0;
    if (event.scale > 1) {
      zoomValue = oldZoom * zoomFactor;
    } else if (event.scale < 1) {
      zoomValue = oldZoom / zoomFactor;
    }
    if (zoomValue != 0 && zoomValue > zoomLimits.min && zoomValue < zoomLimits.max) {
      view.zoom = zoomValue;
    }
  }

  function addPanEvents() {
    if (canvasController.heatmapType === heatmapTypes.heatmapTypes.MINI) {
      return;
    }
    view.onMouseDrag = (event) => {
      if (!dragStartPoint) {
        dragStartPoint = event.point;
      }
      var newDelta = event.point.subtract(dragStartPoint);
      var newCenter = view.center.subtract(newDelta);
      if (Math.abs(newCenter.x - floorCenter.x) < panLimits.x && Math.abs(newCenter.y - floorCenter.y) < panLimits.y) {
        view.center = newCenter;
      }
    }
    view.onMouseUp = (event) => {
      dragStartPoint = null;
    }
  }

  function setZoomAndCenter(outerWallElement) {
    var zoomAndCenter = canvasController.layerControllers.floor.getZoomAndCenter(outerWallElement);
    if (zoomAndCenter) {
      var { zoom, center, bounds } = zoomAndCenter;
      view.zoom = zoom;
      view.center = center;
      zoomLimits.min = Math.round(zoom / 2);
      zoomLimits.max = zoom * 6;

      floorCenter = center;
      panLimits.x = bounds.width * 0.7;
      panLimits.y = bounds.height * 0.7;
    }
  }

  export {
    initHeatmap,
    setZoomAndCenter,
    addPanEvents
  }