import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/sharedUser';

async function addSharedUser(user) {
    const res = await axios.post(`${hostname}${path}`, user);
    return res.data;
}

async function deleteSharedUser(userId, customerId) {
    const res = await axios.delete(`${hostname}${path}?userId=${userId}&customerId=${customerId}`);
    return res.data;
}

async function updateSharedUserPassword(userId, customerId, password) {
    const res = await axios.put(`${hostname}${path}/password`, { userId: userId, customerId: customerId, password: password });
    return res.data;
}

export {
    addSharedUser,
    deleteSharedUser,
    updateSharedUserPassword
}