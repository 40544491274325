import { useState } from "react";
import InputField from "../components/UiComponents/InputField";
import Button from "../components/UiComponents/Button";
import { updatePassword } from "../scripts/user";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import ViewHeader from "../components/UiComponents/ViewHeader";
import { SettingsParagraph, SettingsSection } from "../components/settings/settingsStyles";
import styled from "styled-components";
import theme from "../UI/theme";
import { loadingStates } from "../components/installation/installationTypes";
import { ButtonRowContainer } from "../components/panel/ModalStyles";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    background-color: ${theme.colors.darkSpace90};
    padding: 20px;
    box-sizing: border-box;
`

const ResetPasswordView = props => {
    const { resetId } = useParams();
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [error, setError] = useState('');
    const history = useHistory();

    const handlePasswordUpdate = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            await updatePassword(newPassword, resetId);
            setLoadingState(loadingStates.SUCCESS);
        } catch (error) {
            if (error?.response?.status === 410 || error?.response?.status === 404) {
                setError('Error resetting password: ' + error?.response?.data?.error);
            } else {
                setError('Password change failed.');
            }

            setLoadingState(loadingStates.FAILED);
        }
    }

    return <>
        <ViewHeader headerText={'Password Reset'} />

        <Container>
            {loadingState === loadingStates.NONE ? <>
                <SettingsSection>
                    <label>New password</label>
                    <InputField value={newPassword} type="password" onChange={(e) => setNewPassword(e.target.value)} />
                </SettingsSection>

                <SettingsSection>
                    <label>Confirm new password</label>
                    <InputField value={confirmNewPassword} type="password" onChange={(e) => setConfirmNewPassword(e.target.value)} />
                </SettingsSection>

                <SettingsSection>
                    <ButtonRowContainer>
                        <Button
                            disabled={newPassword?.length < 1 || newPassword !== confirmNewPassword || loadingState !== loadingStates.NONE}
                            primary
                            onClick={() => handlePasswordUpdate()}>Change password</Button>
                    </ButtonRowContainer>
                </SettingsSection>
            </> : <></>}

            {loadingState === loadingStates.SUCCESS ? <>
                <SettingsParagraph>Password changed successfully. Please reload the Waved application and log in.</SettingsParagraph>
            </> : <></>}

            {loadingState === loadingStates.LOADING ? <>
                <SettingsParagraph>Changing password...</SettingsParagraph>
            </> : <></>}

            {loadingState === loadingStates.FAILED ? <>
                <SettingsParagraph>{error}</SettingsParagraph>
            </> : <></>}

            {loadingState === loadingStates.SUCCESS || loadingState === loadingStates.FAILED ? <>
                <ButtonRowContainer>
                    <Button primary onClick={() => history.push('/')}>Go to sign-in</Button>
                </ButtonRowContainer>
            </> : <></>}
        </Container>

    </>
}

export default ResetPasswordView;