import { Switch, Route, useRouteMatch } from "react-router-dom";
import SensorDetailView from '../components/admin/sensor/SensorDetailView';
import WebSocketView from '../components/websocket/WebSocketView';

const AdminView = props => {
    let match = useRouteMatch();

    return <div>
        <Switch>
            <Route path={`${match.path}/sensor-detail/:initialSensorID?`}>
                <SensorDetailView customerId={props.customerId}/>
            </Route>

            <Route path={`${match.path}/websocket`}>
                <WebSocketView customerId={props.customerId} />
            </Route>

            <Route path={`${match.path}`}>
                <div>
                    Admin view
                </div>
            </Route>
        </Switch>
    </div>
}

export default AdminView;