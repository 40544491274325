import StyledIcon from "../StyledIcon";

const WaVolumeUp = props => {
    return <StyledIcon width="31" height="27" viewBox="0 0 31 27" fill="none" {...props}>
        <path d="M13.2589 4.43848L6.8474 9.53848H1.73828V17.2385H6.8474L13.2589 22.3385V4.43848Z" strokeWidth="2" strokeLinejoin="round" />
        <path d="M24.7793 1.43848C31.3911 8.03848 31.3911 18.8385 24.7793 25.4385" strokeWidth="2" strokeLinejoin="round" />
        <path d="M19.2695 7.43848C22.5754 10.7385 22.5754 16.1385 19.2695 19.4385" strokeWidth="2" strokeLinejoin="round" />
    </StyledIcon>
}

export default WaVolumeUp;