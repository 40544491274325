import {IconContext} from 'react-icons';
import { HiOutlineHome } from "react-icons/hi";


const  HomeIcon = (props) => {

  return (
    <IconContext.Provider
      value={{ color: props.color || "#FFFFFF", size: props.size || '28px' }}
    >
      <HiOutlineHome/>
    </IconContext.Provider>
  )
}

export default HomeIcon;