import theme from "../../../../../UI/theme";
import { CalibrationMetric, LiveMetricsContainer } from "../calibrationStyles";

const LiveMetrics = props => {
    return <LiveMetricsContainer>
        <CalibrationMetric color={theme.colors.purplePleasure}>
            <label>Base</label>
            <label>{(props.newCalibrationPoints?.[0]?.measuredDb)?.toFixed(2)}</label>
        </CalibrationMetric>
        <CalibrationMetric color={theme.colors.darkSpace50}>
            <label>Saved</label>
            <label>{(props.savedCalibrationPoints?.[0]?.measuredDb)?.toFixed(2)}</label>
        </CalibrationMetric>
        <CalibrationMetric color={theme.colors.blueMonday}>
            <label>Live</label>
            <label>{(props.zoneLive?.averageDecibel - props.zoneLive?.averageDiff)?.toFixed(2)}</label>
        </CalibrationMetric>
        <CalibrationMetric color={theme.colors.blueMonday}>
            <label>Live avg</label>
            <label>{props.averageFilteredDb?.toFixed(2)}</label>
        </CalibrationMetric>
    </LiveMetricsContainer>
}

export default LiveMetrics;