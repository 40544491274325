import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const speakerPath = '/v2/speaker';

async function getSpeaker(speakerId) {
    const res = await axios.get(`${hostname}${speakerPath}?speakerId=${speakerId}`);
    return res.data;
}

async function getSpeakers(customerId) {
    const res = await axios.get(`${hostname}${speakerPath}/speakers?customerId=${customerId}`);
    return res.data;
}

async function updateSpeaker(speaker) {
    const res = await axios.put(`${hostname}${speakerPath}`, speaker);
    return res.data;
}

async function updateSpeakers(speakers) {
    const res = await axios.put(`${hostname}${speakerPath}/list`, speakers);
    return res.data;
}

async function deleteSpeaker(speakerId) {
    const res = await axios.delete(`${hostname}${speakerPath}?speakerId=${speakerId}`);
    return res.data;
}

async function getAllSpeakers() {
    const res = await axios.get(`${hostname}${speakerPath}/all`);
    return res.data;
}

async function updateAffectedZones(speakers, zones) {
    const res = await axios.put(`${hostname}${speakerPath}/zones`, {speakers, zones});
    return res.data;
}

export {
    getSpeaker,
    getSpeakers,
    updateSpeaker,
    updateSpeakers,
    deleteSpeaker,
    getAllSpeakers,
    updateAffectedZones
}