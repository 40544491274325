import { Switch, Route, useRouteMatch } from "react-router-dom";
import CustomerErrorsView from "./CustomerErrorsView";
import CustomerDetailsView from "./CustomerDetailsView";
import styled from "styled-components";
import theme from "../UI/theme";
import { FaBandAid, FaChartBar, FaExclamationCircle, FaHdd, FaTripadvisor } from "react-icons/fa";
import InvisibleLink from "../components/UiComponents/InvisibleLink";
import CustomerUseView from "./CustomerUseView";
import ZoneDetailsView from "./ZoneDetailsView";
import AdminDeviceView from "../components/admin/devices/AdminDeviceView";
import DownloadsView from "../components/admin/downloads/DownloadsView";
import WaDownload from "../components/UiComponents/Icons/WaDownload";
import CustomerHealthView from "./CustomerHealthView";

const SupportContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-template-columns: 1fr 1fr;
        margin-bottom: 100px;
    }
`

const SupportTile = styled.div`
    width: fill-available;
    aspect-ratio: 1;
    background-color: ${theme.colors.darkSpace80};
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    text-align: center;
    white-space: nowrap;
    font-size: 1.2rem;

    @media (hover:hover) {
        &:hover {
            background-color: ${theme.colors.grayHover};
            cursor: pointer;
        }
    }

    svg {
        stroke: white;
        font-size: 20px;
    }
`

const SupportView = props => {
    let match = useRouteMatch();

    return <div>
        <Switch>
            <Route exact path={`${match.path}/customererrors`}>
                <CustomerErrorsView setCustomer={props.setCustomer} />
            </Route>
            <Route path={`${match.path}/customerdetails/:customerId/:zoneId`}>
                <ZoneDetailsView />
            </Route>
            <Route path={`${match.path}/customerdetails/:customerId`}>
                <CustomerDetailsView />
            </Route>
            <Route exact path={`${match.path}/customeruse`}>
                <CustomerUseView setCustomer={props.setCustomer} />
            </Route>
            <Route exact path={`${match.path}/customerhealth`}>
                <CustomerHealthView />
            </Route>
            <Route exact path={`${match.path}/devices`}>
                <AdminDeviceView />
            </Route>
            <Route exact path={`${match.path}/downloads`}>
                <DownloadsView />
            </Route>
            <Route path={`${match.path}`}>
                <SupportContainer>
                    <InvisibleLink to={"supportcenter/customererrors"}>
                        <SupportTile>
                            <label>Customer Errors</label>
                            <FaExclamationCircle />
                        </SupportTile>
                    </InvisibleLink>

                    <InvisibleLink to={"supportcenter/customeruse"}>
                        <SupportTile>
                            <label>Customer Use</label>
                            <FaChartBar />
                        </SupportTile>
                    </InvisibleLink>

                    <InvisibleLink to={"supportcenter/customerhealth"}>
                        <SupportTile>
                            <label>Customer Health</label>
                            <FaBandAid />
                        </SupportTile>
                    </InvisibleLink>

                    <InvisibleLink to={{ pathname: "https://cit.waved.app/" }} target="_blank" >
                        <SupportTile>
                            <label>Customer Insight Tool</label>
                            <FaTripadvisor />
                        </SupportTile>
                    </InvisibleLink>

                    <InvisibleLink to={"supportcenter/devices"}>
                        <SupportTile>
                            <label>Devices</label>
                            <FaHdd />
                        </SupportTile>
                    </InvisibleLink>

                    <InvisibleLink to={"supportcenter/downloads"}>
                        <SupportTile>
                            <label>Downloads</label>
                            <WaDownload />
                        </SupportTile>
                    </InvisibleLink>

                </SupportContainer>
            </Route>
        </Switch>
    </div>
}

export default SupportView;