import React, { useState } from "react";
import Button from "../UiComponents/Button";
import Footer from "../navigation/Footer";
import { TroubleshootingContainer, TroubleshootingButton, SolutionContainer, LoadingBar } from "./StylesTroubleshooting";
import theme from "../../UI/theme";
import { performHubAction } from "./utils";
import { HUB_ATTTEMPT_TIMEOUT } from "./utils";

const action = {
    reconnectProcessor: 'RECONNECT_PROCESSOR',
    restartHubProgram: 'RESTART_APP',
    scanForProcessor: 'SCAN_FOR_DEVICES'
}

const TroubleshootProcessor = (props) => {

    const [expand, setExpand] = useState(false);
    const [reconnectAttempt, setReconnectAttempt] = useState(false);
    const [restartAttempt, setRestartAttempt] = useState(false);
    const [ongoingAttempt, setOngoingAttempt] = useState(false);

    async function reconnectProcessors(){
        if (ongoingAttempt) return; 
        toggleOngoingAttempt(action.reconnectProcessor);
        for (let i=0; i<props.disconnectedProcessors.length; i++){
            var processor = props.disconnectedProcessors[i];
            await performHubAction(processor.hubId, processor.customerId, action.reconnectProcessor , { processorId: processor.processorId })
        }
    }

    async function restartHubPrograms() {
        if (ongoingAttempt) return;
        toggleOngoingAttempt(action.restartHubProgram);
        for (let i=0; i<props.disconnectedProcessors.length; i++) {
            var processor = props.disconnectedProcessors[i];
            await performHubAction(processor.hubId, processor.customerId, action.restartHubProgram);
        }
    }

    function toggleOngoingAttempt(attempt) {
        setOngoingAttempt(attempt)
        setTimeout(() => {
            setOngoingAttempt(false);
            if (attempt === action.reconnectProcessor) setReconnectAttempt(true);
            else if (attempt === action.restartHubProgram) setRestartAttempt(true);
        }, HUB_ATTTEMPT_TIMEOUT);
    }

    return (
        <TroubleshootingContainer>
            <h4>Warning</h4>
            <p>Waved may have lost connection with your sound system.</p>
            {!expand? 
                <TroubleshootingButton primary small onClick={() => setExpand(true)}>See solutions</TroubleshootingButton>
            :<>
                <TroubleshootingButton secondary small onClick={() => setExpand(false)}>Hide solutions</TroubleshootingButton>
                <SolutionContainer>
                    <h4>Try the following solutions:</h4>
                    <ol>
                        <li>
                            <p>Attempt a forced reconnection.</p>
                            {ongoingAttempt===action.reconnectProcessor?
                                <LoadingBar>Attempting reconnect...</LoadingBar>
                            :<>
                                {ongoingAttempt?
                                    <Button style={{color: theme.colors.grayBorder}} tertiary small >Reconnect</Button>
                                :
                                    <Button tertiary small onClick={reconnectProcessors}>Reconnect</Button>
                                }
                                
                            </>}
                        </li>
                        <li>
                            <p>Restart the Waved hub.</p>
                            {ongoingAttempt===action.restartHubProgram?
                                <LoadingBar>Restarting hub...</LoadingBar>
                            :<>
                                {ongoingAttempt?
                                    <Button style={{color: theme.colors.grayBorder}} tertiary small >Restart</Button>
                                :
                                    <Button tertiary small onClick={restartHubPrograms}>Restart</Button>
                                }    
                            </>}
                            
                        </li>
                    </ol>
                    {( reconnectAttempt && restartAttempt )? <>    
                        <p>If the app is still disconnected, please contact Waved support:</p>
                        <div style={{maxWidth: '340px'}}><Footer/></div>
                    </>:<></>}
                </SolutionContainer>
            </>}
        </TroubleshootingContainer>
    )
}

export default TroubleshootProcessor;