import { useRouteMatch } from "react-router-dom";
import LogoutButton from '../authentication/LogoutButton';
import { SettingsContainer, SettingsSection } from './settingsStyles';
import ViewHeader from '../UiComponents/ViewHeader';
import UserContext from '../../hooks/UserContext';
import { useContext } from 'react';
import LinkItem from './LinkItem';
import AuthorizeAdminSettings from './AuthorizeAdminSettings';
import { useState } from 'react';
import { useEffect } from 'react';
import { authorizeAdmin } from '../../scripts/authentication';
import { hasRole, hasVenuePermission, permissionTypes, roles } from '../permission/permissions';
import { isLocalApp } from '../../scripts/server/server';
import theme from "../../UI/theme";

const settingsPath = '/settings';

const settingsLinks = [
    {
        key: "VENUE",
        display: "Venue information",
        link: `${settingsPath}/venue`,
        permission: permissionTypes.settingsRead,
        localApp: true
    },
    {
        key: "SCHEDULES",
        display: "Schedules",
        link: `${settingsPath}/schedules`,
        permission: permissionTypes.settingsRead,
        localApp: true
    },
    {
        key: "MUSIC_PLAYERS",
        display: "Music players",
        link: `${settingsPath}/musicPlayers`,
        permission: permissionTypes.settingsRead,
        localApp: true
    },
    {
        key: "GROUPS",
        display: "Groups",
        link: `${settingsPath}/groups`,
        permission: permissionTypes.settingsRead,
        localApp: true
    },
    {
        key: "CALIBRATION",
        display: "Calibration",
        link: `${settingsPath}/calibration`,
        permission: permissionTypes.calibrationRead,
        localApp: true
    },
    {
        key: "LIVE_CALIBRATION",
        display: "Live calibration",
        link: `${settingsPath}/liveCalibration`,
        permission: permissionTypes.calibrationRead,
        localApp: true
    },
    {
        key: "ZONES",
        display: "Zones",
        link: `${settingsPath}/zones`,
        permission: permissionTypes.settingsRead,
        localApp: true
    },
    {
        key: "LOCAL_APP",
        display: "Local app",
        link: `${settingsPath}/localApp`,
        admin: false,
        localApp: true
    },
    {
        key: "INSTALLATION",
        display: "Installation",
        link: `/installation`,
        permission: permissionTypes.installationViewRead,
        localApp: true
    }
]

const SettingsMain = props => {
    let match = useRouteMatch();
    const userContext = useContext(UserContext);
    const [adminAuthorized, setAdminAuthorized] = useState(false);
    const [readyToRender, setReadyToRender] = useState(false);

    useEffect(() => {
        if (!hasRole(userContext, roles.impersonator)) {
            checkAdminAuthorization();
        } else {
            setReadyToRender(true);
        }
    }, [])

    const checkAdminAuthorization = async () => {
        try {
            const pin = window.sessionStorage.getItem('adminAuthorized');
            if (pin) {
                const res = await authorizeAdmin(pin);
                if (res === true) {
                    setAdminAuthorized(res);
                    return;
                }
            }
            setAdminAuthorized(false);
        } catch (error) {
            setAdminAuthorized(false);
        } finally {
            setReadyToRender(true);
        }
    }

    const checkLinkPermission = (settingsLink) => {
        if (isLocalApp() && settingsLink.localApp) {
            if (settingsLink.permission) {
                return adminAuthorized;
            } else {
                return true;
            }
        } else {
            return hasVenuePermission(userContext, settingsLink.permission);
        }
    }

    const onAdminAuthorized = (pin) => {
        window.sessionStorage.setItem('adminAuthorized', pin);
        setAdminAuthorized(true);
    }

    return <>
        {readyToRender === true ? <>

            <SettingsContainer>
                {process.env.REACT_APP_HOST_TYPE !== 'HUB' ? <>
                    <SettingsSection>
                        <ViewHeader headerText={'User settings'} showVenueName={false} color={theme.colors.darkSpace10}/>
                        <LinkItem to={`${match.url}/user`} display={'User information'} />
                        <LinkItem to={`${match.url}/uservenues`} display={'My venues'} />
                        {hasVenuePermission(userContext, permissionTypes.userAdministrationRead) ? <>
                            <LinkItem to={`/userAdministration`} display={'User administration'} />
                        </> : <></>}
                    </SettingsSection>
                </> : <></>}

                <SettingsSection>
                    <ViewHeader headerText={'Venue settings'} showVenueName={true} color={theme.colors.darkSpace10}/>

                    {settingsLinks?.map(setting => {
                        if (checkLinkPermission(setting)) {
                            return <LinkItem key={setting.key} to={`${setting.link}`} display={setting.display} />
                        } else {
                            return <></>
                        }
                    })}

                    {isLocalApp() && !hasRole(userContext, roles.impersonator) && adminAuthorized !== true ? <>
                        <AuthorizeAdminSettings onAuthorize={(pin) => onAdminAuthorized(pin)} />
                    </> : <></>}

                </SettingsSection>

                {process.env.REACT_APP_HOST_TYPE !== 'HUB' ? <>
                    <div>
                        <LogoutButton />
                    </div>
                </> : <></>}

            </SettingsContainer>
        </> : <></>}
    </>
}

export default SettingsMain;