import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const clientPath = '/v2/client/'

const getCustomer = async (customerId) => {
    const res = await axios.get(`${hostname}${clientPath}?customerId=${customerId}`);
    return res.data;
}

const checkExistingCustomer = async (customerId) => {
    const res = await axios.get(`${hostname}${clientPath}checkExisting?customerId=${customerId}`);
    return res.data;
}

const updateCustomer = async (customer) => {
    const res = await axios.put(`${hostname}${clientPath}`, customer);
    return res.data;
}

const createCustomer = async (customer) => {
    const res = await axios.post(`${hostname}${clientPath}`, customer);
    return res.data;
}

const getAllCustomers = async () => {
    const res = await axios.get(`${hostname}${clientPath}/customers`);
    return res.data;
}

const getOpeningHours = async (customerId) => {
    var result = await axios.get(`${hostname}${clientPath}/openingHours?customerId=${customerId}`);
    if (result.data.hours) {
        return JSON.parse(result.data.hours);
    } else if (result.data.error) {
        console.log(result.data.error);
    }
    return [];
}

const setOpeningHours = async (customerId, hours) => {
    await axios.post(`${hostname}${clientPath}/openingHours?customerId=${customerId}`, { hours });
}

export {
    getCustomer,
    updateCustomer,
    createCustomer,
    getAllCustomers,
    getOpeningHours,
    setOpeningHours,
    checkExistingCustomer
}


