export default {
    states: {
        NONE: 'NONE',
        DATA: 'DATA',
        NO_DATA: 'NO_DATA',
        LOADING: 'LOADING'
    },
    modes: {
        SETUP: 'SETUP',
        DATA: 'DATA',
        DOCUMENTATION: 'DOCUMENTATION'
    },
    tools: {
        FLOOR_GENERAL: 'FLOOR_GENERAL',
        FLOOR: 'FLOOR',
        SENSOR: 'SENSOR',
        HUB: 'HUB',
        PROCESSOR: 'PROCESSOR',
        ZONE: 'ZONE',
        FURNITURE: 'FURNITURE',
        NONE: 'NONE'
    },
    actions: {
        PLACE_DEVICE: 'PLACE_DEVICE',
        REMOVE_DEVICE: 'REMOVE_DEVICE',
        MARK_DEVICE: 'MARK_DEVICE',
        UNMARK_ALL_DEVICES: 'UNMARK_ALL_DEVICES',
        DEVICE_PLACED: 'DEVICE_PLACED',
        DEVICE_REMOVED: 'DEVICE_REMOVED',
        OUTER_WALL_CLOSED: 'OUTER_WALL_CLOSED',
        DEVICE_MARKED: 'DEVICE_MARKED',
        SAVE: 'SAVE',
        FLOOR_SELECT: 'FLOOR_SELECT',
        TOOL_SELECT: 'TOOL_SELECT',
        MODE_SELECT: 'MODE_SELECT',
        WALL_SELECT: 'WALL_SELECT',
        SENSOR_DATA: 'SENSOR_DATA',
        ZONE_DATA: 'ZONE_DATA',
        WALL_DESELECT: 'WALL_DESELECT',
        DESELECT_ALL_WALLS: 'DESELECT_ALL_WALLS',
        ADD_OUTER_WALL: 'ADD_OUTER_WALL',
        ADD_INNER_WALL: 'ADD_INNER_WALL',
        COMPLETE_INNER_WALL: 'COMPLETE_INNER_WALL',
        DELETE_WALL: 'DELETE_WALL',
        PLACE_ZONE: 'PLACE_ZONE',
        REMOVE_ZONE: 'REMOVE_ZONE',
        ZONE_PLACED: 'ZONE_PLACED',
        ZONE_REMOVED: 'ZONE_REMOVED',
        ZONE_SELECT: 'ZONE_SELECT',
        DESELECT_ALL_ZONES: 'DESELECT_ALL_ZONES',
        ADD_FURNITURE: 'ADD_FURNITURE',
        REMOVE_FURNITURE: 'REMOVE_FURNITURE',
        SHOW_SENSOR_INFO: 'SHOW_SENSOR_INFO',
        SET_DATA_MODE: 'SET_DATA_MODE',
        SET_DATA_TYPE: 'SET_DATA_TYPE',
        GET_OUTER_WALL: 'GET_OUTER_WALL',
        CANVAS_CLEANUP: 'CANVAS_CLEANUP',
        DEVICE_UPDATE: 'DEVICE_UPDATE'
    },
    elementTypes: {
        SENSOR: 'SENSOR',
        WALL: 'WALL',
        ZONE: 'ZONE',
        OUTERWALL: 'OUTERWALL',
        INNERWALL: 'INNERWALL',
        FURNITURE: 'FURNITURE',
        HUB: 'HUB',
        PROCESSOR: 'PROCESSOR'
    }, 
    elementStates: {
        PLACED: 'PLACED',
        PLACING: 'PLACING',
        NOT_PLACED: 'NOT_PLACED'
    },
    displayMethods: {
        CONTINUOUS: 'CONTINUOUS',
        SIMPLE: 'SIMPLE'
    }, 
    dataModes: {
        LIVE: 'LIVE',
        HISTORY: 'HISTORY',
        TREND: 'TREND'
    },
    dataTypes: {
        AVG_DB: 'AVG_DB',
        AVG_DIFF_DB: 'AVG_DIFF_DB',
        AVG_FIL_DB: 'AVG_FIL_DB'
    },
    dataIntervals: {
        LAST_15_MINUTES: 'LAST_15_MINUTES',
        LAST_4_HOURS: 'LAST_4_HOURS',
        LAST_24_HOURS: 'LAST_24_HOURS'
    },
    heatmapTypes: {
        MINI: 'MINI'
    }
}