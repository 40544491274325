import React from 'react';
import styled from 'styled-components';
import theme from '../../../UI/theme';
import FloorSelector from './FloorSelector';
import heatmapTypes from './heatmapTypes';
import { FaEdit, FaTimes, FaPlus } from 'react-icons/fa';
import Button from '../../UiComponents/Button';

const MenuContainer = styled.div`
    display: flex;
    justify-content: space-between;
    
    &>*:not(:last-child) {
        margin-right: 10px;
    }
    
    > button {
        white-space: nowrap;
        height: fit-content;
    }
`

const FloorSelectorContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: fill-available;
    &>*:not(:last-child) {
        margin-right: 10px;
    }
`

const AddFloorButtonContainer = styled.div`
    white-space: nowrap;
`

const MenuBar = props => {

    const showEditButton = (window.innerWidth > theme.screenSizes.medium);

    return <MenuContainer>

        <FloorSelectorContainer>
            <FloorSelector
                floors={props.floors}
                activeFloor={props.activeFloor}
                setActiveFloor={props.setActiveFloor}

            />

            {props.activeMode === heatmapTypes.modes.SETUP ? <>
                <AddFloorButtonContainer>
                    <Button secondary icon small onClick={props.addFloorHandler}>
                        <span>Add floor</span>
                        <FaPlus />
                    </Button>
                </AddFloorButtonContainer>
            </> : <></>}
        </FloorSelectorContainer>

        {props.activeMode === heatmapTypes.modes.SETUP ? <>
            <Button secondary icon small onClick={() => props.setModeHandler(props.enableDocumentationMode ? heatmapTypes.modes.DOCUMENTATION : heatmapTypes.modes.DATA)}>
                <span>End edit</span><FaTimes />
            </Button>
        </> : <>
            {showEditButton ? <>
                <Button secondary icon small onClick={() => props.setModeHandler(heatmapTypes.modes.SETUP)}>
                    <span>Edit map</span><FaEdit />
                </Button>
            </> : <></>}
        </>}

    </MenuContainer>
}

export default MenuBar;