import Device from '../device/Device';
import icon from '../../images/processorIcon.svg';
import theme from '../../../../UI/theme';

const params = {
    fillColor: '#3C3C3C',
    size: 1.2,
    radius: 0.1,
    label: 'Processor',
    iconColor: theme.colors.darkSpace10,
    icon
}

class Processor extends Device {

    constructor(deviceInfo, layer) {
        super(deviceInfo, layer, params);
    }

    drawElementInGroup(point) {
        return this.drawIconElement(point);
    }
}

export default Processor;


