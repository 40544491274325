import styled from 'styled-components';

const ColorMarker = styled.div`
    width: 14px;
    height: 14px;
    border-radius: 4px;
    background-color: ${props => props.color};
    margin-right: 12px;
`

export default ColorMarker;
