import { getUuid } from "../common";
import { getZoneLives } from "../zoneLive";
import { subscriptionsTypes } from "./subscriptionTypes";

class SubscriptionController {
    constructor(serverConnection) {
        this.list = [];
        this.serverConnection = serverConnection;
        this.startPollSubscription();
    }

    addSubscription(subscriptionType, id, setState) {

        let subscription = {
            subscriptionId: getUuid(),
            subscriptionType,
            id,
            setState
        }

        if (!validateSubscription(subscription)) {
            console.log('Invalid subscription', subscription);
            throw new Error('Invalid subscription');
        }

        this.list.push(subscription);
        this.updateSubscriber(subscription);
        return subscription.subscriptionId;
    }

    removeSubscription(subscriptionId) {
        this.list = this.list.filter(subscription => subscription.subscriptionId !== subscriptionId);
    }

    async handleUpdate(updateKey, id) {
        if (updateKey === 'zoneLive') {
            this.updateDataSubscribers(id);
        } else {
            this.list.forEach(subscription => {
                if (shouldUpdate(subscription, updateKey, id)) {
                    this.updateSubscriber(subscription);
                }
            });
        }
        if (!this.serverConnection.serverStatus) {
            this.serverConnection.setServerStatus(true);
        }
    }

    async updateSubscriber(subscription) {
        try {
            const data = await subscription.subscriptionType.get(this.serverConnection.customerId, subscription.id);
            subscription.setState(data);
            this.serverConnection.setServerStatus(true);
        } catch (err) {
            this.serverConnection.setServerStatus(false);
        }
    }

    async updateDataSubscribers(id) {
        try {

            if (id) {
                this.list.filter(subscription => subscription.id === id).forEach(subscription => this.updateSubscriber(subscription));
            }

            if (this.list.some(subscription => subscription.subscriptionType.key === 'zoneLives') ||
                (id === undefined && this.list.some(subscription => subscription.subscriptionType.key === 'zoneLive'))) {
                const data = await getZoneLives(this.serverConnection.customerId);
                const multiSubscriptions = this.list.filter(subscription => subscription.subscriptionType.key === 'zoneLives');
                multiSubscriptions.forEach(subscription => { subscription.setState(data) });
                const singleSubscriptions = this.list.filter(subscription => subscription.subscriptionType.key === 'zoneLive');
                singleSubscriptions.forEach(subscription => {
                    const record = data.find(record => record.zoneId === subscription.id);
                    subscription.setState(record);
                });
            }

        } catch (err) {
            console.log('Failed to update data subscribers', err);
        }
    }

    startPollSubscription = () => {
        this.isPolling = true;
        clearInterval(this.pollInterval);
        this.pollInterval = setInterval(() => {
            const pollSubscriptions = (!this.serverConnection.socketStatus()) ? [...this.list] : this.list.filter(subscription => subscription.subscriptionType.usePoll);
            
            pollSubscriptions.forEach(subscription => {
                this.updateSubscriber(subscription);
            });
        }, 5000)
    }

    refreshAllSubscriptions = () => {
        this.list.forEach(subscription => { this.updateSubscriber(subscription) });
    }
}

export default SubscriptionController;

const shouldUpdate = (subscription, updateKey, id) => {
    const subscriptionType = subscription.subscriptionType;
    return subscriptionType.updateKey === updateKey && (!subscriptionType.needsId || subscription.id === id);
}

const validateSubscription = (subscription) => {
    const subscriptionType = subscription.subscriptionType;
    return subscriptionType && subscriptionsTypes[subscriptionType.key] && (!subscriptionType.needsId || subscription.id);
}
