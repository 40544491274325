import { useState } from "react";
import Button from "../../../../UiComponents/Button"
import Modal from "../../../../UiComponents/Modal";
import { ButtonRowContainer, ModalContainer, ModalHeader } from "../../../../panel/ModalStyles";
import { SettingsParagraph, SettingsSection } from "../../../settingsStyles";
import InputField from "../../../../UiComponents/InputField";
import { updateSharedUserPassword } from "../../../../../scripts/sharedUser";
import { loadingStates } from "../../../../installation/installationTypes";

const UpdateSharedAccountPasswordButton = props => {
    const [showModal, setShowModal] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    const handleCancel = () => {
        setNewPassword('');
        setConfirmedPassword('');
        setLoadingState(loadingStates.NONE);
        setShowModal(false);
    }

    const handleChangePassword = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            await updateSharedUserPassword(props.user?.userId, Number(props.user?.venueIds?.[0]), newPassword);
            setLoadingState(loadingStates.SUCCESS);
        } catch (error) {
            setLoadingState(loadingStates.FAILED);
        }
    }

    return <>
        <Button small secondary onClick={() => setShowModal(true)}>Change Password</Button>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Change shared account password</ModalHeader>
                
                <SettingsSection>
                    <SettingsParagraph>Please note that all currently logged-in devices will need to re-authenticate.</SettingsParagraph>
                </SettingsSection>

                {loadingState === loadingStates.NONE ? <>
                    <SettingsSection>
                        <label>New password</label>
                        <InputField type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                    </SettingsSection>
                    <SettingsSection>
                        <label>Confirm password</label>
                        <InputField type="password" value={confirmedPassword} onChange={(e) => setConfirmedPassword(e.target.value)} />
                    </SettingsSection>

                    <ButtonRowContainer>
                        <Button primary disabled={newPassword?.length < 1 || newPassword !== confirmedPassword} onClick={() => handleChangePassword()}>Change password</Button>
                        <Button secondary onClick={() => handleCancel()}>Cancel</Button>
                    </ButtonRowContainer>
                </> : <></>}

                {loadingState === loadingStates.LOADING ? <>
                    <SettingsSection>
                        <SettingsParagraph>Changing password...</SettingsParagraph>
                    </SettingsSection>
                </> : <></>}

                {loadingState === loadingStates.SUCCESS ? <>
                    <SettingsSection>
                        <SettingsParagraph>Password changed successfully.</SettingsParagraph>
                    </SettingsSection>
                </> : <></>}

                {loadingState === loadingStates.FAILED ? <>
                    <SettingsSection>
                        <SettingsParagraph>Error. Could not change shared account password.</SettingsParagraph>
                    </SettingsSection>
                </> : <></>}

                {loadingState === loadingStates.FAILED || loadingState === loadingStates.SUCCESS ? <>
                    <ButtonRowContainer>
                        <Button primary onClick={() => handleCancel()}>Close</Button>
                    </ButtonRowContainer>
                </> : <></>}

            </ModalContainer>
        </Modal>
    </>
}

export default UpdateSharedAccountPasswordButton;