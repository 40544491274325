import React, { useState, useEffect, useContext } from 'react';
import ViewHeader from '../../UiComponents/ViewHeader';
import VenueInfo from './VenueInfo';
import UserContext from '../../../hooks/UserContext';
import { getCustomer, getOpeningHours } from '../../../scripts/datasource';
import { hasVenuePermission, permissionTypes } from '../../permission/permissions';

const VenueSettings = props => {
    const [venue, setVenue] = useState();
    const [openingHours, setOpeningHours] = useState();
    const userContext = useContext(UserContext);

    const readOnly = !hasVenuePermission(userContext, permissionTypes.settingsWrite);

    useEffect(() => {
        getDataHandler();
    }, [props.customerId]);

    const getDataHandler = async () => {
        await getVenueHandler();
        await getOpeningHoursHandler();
    }

    const getVenueHandler = async () => {
        var venueRes = await getCustomer(props.customerId);
        if (venueRes) {
            setVenue(venueRes);
        }
    }

    const getOpeningHoursHandler = async () => {
        var venueOpeningHours = await getOpeningHours(props.customerId);
        if (venueOpeningHours.length) {
            setOpeningHours(venueOpeningHours);
        }
    }

    const refreshVenueInfo = async () => {
        getVenueHandler();
        getOpeningHoursHandler();
    }


    return <>
        <ViewHeader headerText={'Venue Information'} backLink={'/settings'} showVenueName={true} />

        <VenueInfo
            venue={venue}
            setVenue={setVenue}
            readOnly={readOnly}
            openingHours={openingHours}
            refresh={refreshVenueInfo}
        />
    </>
}

export default VenueSettings;