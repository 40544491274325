import React from 'react';
import styled from 'styled-components';
import theme from '../../../../UI/theme';
import Slider from '../../../UiComponents/legacy/WavedSlider';

const SliderContainer = styled.div`
    max-width: 600px;
    min-width: 300px;
    margin: 20px 0;

    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        min-width: 500px;
    }
`

const HistoryModeSlider = props => {
    return <SliderContainer>
        <Slider value={10} {...props} marklabelcolor={theme.colors.darkSpace70}/>
    </SliderContainer>
}

export default HistoryModeSlider;