import { useEffect, useState } from "react";
import LoadDataContainer, { loadDataHandler } from "../../../../UiComponents/containers/LoadDataContainer";
import { getZone, updateZone, updateZoneCalibration, updateZoneLiveSettings } from "../../../../../scripts/zone";
import { loadingStates } from "../../../../installation/installationTypes";
import CalibrationSliders from "./CalibrationSliders";
import Card from "../../../../UiComponents/Card";
import ToggleButton from "../../../../UiComponents/ToggleButton";


const CalibrationContainer = ({ zoneId }) => {
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [zone, setZone] = useState();

    useEffect(() => {
        loadDataHandler(getData, setLoadingState);
    }, []);

    const getData = async () => {
        setZone(await getZone(zoneId));
    }

    const setParameters = (updatedParameters) => {
        setZone({ ...zone, calibrationPoints: { ...zone.calibrationPoints, parameters: { ...zone.calibrationPoints?.parameters, ...updatedParameters } } });
    }

    const saveParameters = async () => {
        try {
            const updatedZone = { zoneId: zone.zoneId, calibrationPoints: zone.calibrationPoints };
            await updateZoneCalibration(updatedZone);
        } catch (err) {
            console.log('Failed to update parameters');
        }
    }

    const toggleRegulation = async () => {
        try {
            const toState = +!zone.isRegulating;
            await updateZoneLiveSettings({zoneId: zone.zoneId, isRegulating: toState});
            setZone({...zone, isRegulating: toState});
        } catch (err) {
            console.log('Failed to toggle regulation')
        }
    }

    const toggleCalibrationMethod = async () => {
        try {
            const toState = zone.calibrationPoints?.method !== 2 ? 2 : 0;
            const updatedCalibrationPoints = {...zone.calibrationPoints, method: toState};
            await updateZoneCalibration({zoneId: zone.zoneId, calibrationPoints: updatedCalibrationPoints});
            setZone({...zone, calibrationPoints: updatedCalibrationPoints});
        } catch (err) {
            console.log('Failed to toggle calibration method');
        }
    }


    return <LoadDataContainer loadingState={loadingState}>
        <Card style={{ maxWidth: '700px' }}>
            Calibration
            {zone && <>
                <div>
                    Regulation: <ToggleButton checked={zone.isRegulating} onToggle={toggleRegulation} />
                </div>
                <div>
                    New calibration: <ToggleButton checked={zone.calibrationPoints?.method === 2} onToggle={toggleCalibrationMethod} />
                </div>
                <CalibrationSliders
                    parameters={zone.calibrationPoints?.parameters}
                    setParameters={setParameters}
                    save={saveParameters}
                />
            </>}
        </Card>
    </LoadDataContainer>
}

export default CalibrationContainer;