import { useEffect, useState } from 'react';
import { getChangedProps, getRemoteUpdatedProps } from '../tableUtils';

const useEditableProps = ({ parentObject, idField, saveObject }) => {
    const [updatedObject, setUpdatedObject] = useState(parentObject);
    const [updatedKeys, setUpdatedKeys] = useState([]);

    useEffect(() => {
        handleRemoteUpdate(parentObject);
        if (parentObject[idField] !== updatedObject[idField]) {
            setUpdatedKeys([]);
        }
    }, [parentObject])

    const handleRemoteUpdate = (remoteObject) => {
        const remoteUpdatedObject = getRemoteUpdatedProps(remoteObject, updatedObject, updatedKeys);
        setUpdatedObject(remoteUpdatedObject);
    }

    const updateObject = (updatedProps) => {
        const keys = [...updatedKeys];
        Object.keys(updatedProps).forEach(key => {
            if (!keys.includes(key)) {
                keys.push(key);
            }
        })
        setUpdatedKeys(keys);
        setUpdatedObject({ ...updatedObject, ...updatedProps });
    }

    const isEdited = () => {
        return updatedKeys.length;
    }


    const saveHandler = async () => {
        try {
            const changedProps = getChangedProps(updatedObject, updatedKeys, idField);
            await saveObject(changedProps);
            setUpdatedKeys([]);
        } catch (err) {

        }
    }

    return [updatedObject, updateObject, isEdited, saveHandler];
}

export default useEditableProps;