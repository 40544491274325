import styled from "styled-components";
import { getDeviceNumber } from "../../../../../scripts/common";
import MeterMappingChart from "../graph/MeterMappingChart";

const ChartContainer = styled.div`
    margin-left: 20px;
    height: 300px;
    width: 400px;
`

const SensorDetail = ({ sensor }) => {
    return <div>
        Sensor {getDeviceNumber(sensor)} detail
        <ChartContainer>
            <MeterMappingChart data={{ meterJumps: sensor.meterJumps, meterMappingParams: sensor.meterMappingParams }} />
        </ChartContainer>
    </div>
}

export default SensorDetail;