import styled from 'styled-components';

const OuterWallSymbol = styled.div`
    height: 10px;
    width: 10px;
    background-color: transparent;
    border-radius: 4px;
    border: 2px solid gray;
    margin-right: 10px;
`

export default OuterWallSymbol;