export const getSourceSelectorsInZoneGroup = (sourceSelectors, zoneGroup) => {
    return sourceSelectors?.filter(sourceSelector => sourceSelector?.zoneGroupId === zoneGroup?.zoneGroupId) ?? [];
}

export const getZonesInZoneGroup = (zones, sourceSelectors, zoneGroup) => {
    let sourceSelectorsInZoneGroup = getSourceSelectorsInZoneGroup(sourceSelectors, zoneGroup);
    return zones?.filter(zone =>
        sourceSelectorsInZoneGroup?.some(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId) ||
        (
            zoneGroup?.zoneGroupId === zone?.zoneGroupId &&
            !sourceSelectors?.some(sourceSelector => sourceSelector.sourceSelectorId === zone?.sourceSelectorId)
        )
    ) ?? [];
}

export const getZonesForSourceSelector = (zones, sourceSelector) => {
    return zones?.filter(zone => zone?.sourceSelectorId === sourceSelector?.sourceSelectorId) ?? [];
}

export const getSourceSelectorsWithoutZoneGroup = (sourceSelectors, zoneGroups) => {
    return sourceSelectors?.filter(sourceSelector => !zoneGroups?.some(zoneGroup => zoneGroup?.zoneGroupId === sourceSelector?.zoneGroupId)) ?? [];
}

export const getZonesWithoutZoneGroup = (zones, sourceSelectors, zoneGroups) => {
    let sourceSelectorsWithoutZoneGroup = getSourceSelectorsWithoutZoneGroup(sourceSelectors, zoneGroups);
    return zones?.filter(zone =>
        sourceSelectorsWithoutZoneGroup?.some(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId) ||
        (
            !zoneGroups?.some(zoneGroup => zoneGroup.zoneGroupId === zone?.zoneGroupId) &&
            !sourceSelectors?.some(sourceSelector => sourceSelector.sourceSelectorId === zone?.sourceSelectorId)
        )
    ) ?? [];
}

export const getZonesWithoutSourceSelector = (zones, sourceSelectors) => {
    return zones?.filter(zone => !sourceSelectors?.some(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId) || !zone?.sourceSelectorId) ?? [];
}