import { useState } from "react";
import { HeaderRow, Table, TDHeader, THead } from "../support/SupportStyles";
import customerHealthMetricTypes from "./customerHealthMetricTypes";
import entityTypes from "./entityTypes";
import ZoneHealthMetric from "./ZoneHealthMetric";

const ZoneHealthMetrics = props => {
    const [sortProperty, setSortProperty] = useState();
    const [sortAsc, setSortAsc] = useState(true);
    let groupedMetrics = groupMetricsByZone(props?.metrics);
    const previousGroupedMetrics = groupMetricsByZone(props.previousMetrics);
    const zoneMetricTypes = Object.keys(customerHealthMetricTypes).map(key => customerHealthMetricTypes[key]).filter(metricType => metricType.entityType.key === entityTypes.zone.key);

    const sortBy = (property) => {
        setSortProperty(property);

        if (sortProperty === property) {
            setSortAsc(prev => !prev);
        } else {
            setSortAsc(false);
        }
    }

    const compareZones = (a, b) => {
        let aProp, bProp;

        if (sortProperty === 'customerId' || sortProperty === 'entityId') {
            aProp = a?.obj?.[0]?.[sortProperty];
            bProp = b?.obj?.[0]?.[sortProperty];
        } else if (zoneMetricTypes?.some(type => type.key === sortProperty)) {
            aProp = a?.obj?.find(metric => metric?.metricType === sortProperty)?.metricValue;
            bProp = b?.obj?.find(metric => metric?.metricType === sortProperty)?.metricValue;
        }

        if (aProp === undefined) {
            return 1;
        } else if (bProp === undefined) {
            return -1;
        }

        if (sortAsc === false) {
            return aProp < bProp ? 1 : bProp < aProp ? -1 : 0;
        } else {

            return aProp < bProp ? -1 : bProp < aProp ? 1 : 0;
        }
    }

    return <Table>
        <colgroup>
            <col style={{ width: "1%" }} />
        </colgroup>
        <THead>
            <HeaderRow>
                <TDHeader sorted={sortProperty === 'customerId'} ascending={sortAsc} onClick={() => sortBy('customerId')}>Customer ID</TDHeader>
                <TDHeader sorted={sortProperty === 'entityId'} ascending={sortAsc} onClick={() => sortBy('entityId')}>Zone ID</TDHeader>
                {zoneMetricTypes?.map((metricType, index) => {
                    return <TDHeader key={index} sorted={sortProperty === metricType.key} ascending={sortAsc} onClick={() => sortBy(metricType?.key)}>{metricType?.display}</TDHeader>
                })}
            </HeaderRow>
        </THead>

        <tbody>
            {Object.keys(groupedMetrics)
                ?.map(key => ({ key, obj: groupedMetrics?.[key] }))
                ?.sort((a, b) => { return compareZones(a, b) })
                ?.map(item => {
                    const zoneMetrics = item?.obj;
                    const previousZoneMetrics = previousGroupedMetrics?.[item?.key];

                    return <ZoneHealthMetric
                        key={item.key}
                        customerId={props.customerId}
                        zoneId={item.key}
                        zoneMetrics={zoneMetrics}
                        zoneMetricTypes={zoneMetricTypes}
                        previousZoneMetrics={previousZoneMetrics}
                    />
                })}
        </tbody>
    </Table>
}

export default ZoneHealthMetrics;

const groupMetricsByZone = metrics => {
    return metrics?.reduce((groups, metric) => {
        const zoneId = metric?.entityId;

        if (!groups[zoneId]) {
            groups[zoneId] = [];
        }

        groups[zoneId].push(metric);
        return groups;
    }, {});
}