import styled from "styled-components";
import theme from "../../../UI/theme";
import { SkeletonItem } from "./skeletonStyles";

const SkeletonContainer = styled(SkeletonItem)`
    width: 50px;
    grid-column: 1;
    grid-row: ${props => `1 / span ${props.rowSpan}`};
    opacity: 40%;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        width: 40px;
    }
`

let SourceSelectorSkeleton = props => {
    return <SkeletonContainer {...props}>
    </SkeletonContainer>
}

export default SourceSelectorSkeleton;