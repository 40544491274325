import axios from 'axios';
import { getHostname } from './server/server';

const hostname = getHostname();
const path = '/v2/zoneLive';

async function getZoneLive(customerId, zoneId) {
    const res = await axios.get(`${hostname}${path}?customerId=${customerId}&zoneId=${zoneId}`);
    return res.data;
}

async function getZoneLives(customerId) {
    const res = await axios.get(`${hostname}${path}/zoneLives?customerId=${customerId}`);
    return res.data;
}

export {
    getZoneLives,
    getZoneLive
}