import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import heatmapTypes from '../heatmapTypes';
import HistoryModeSlider from './HistoryModeSlider';
import TimeLineGraph from './TimeLineGraph';
import { getSensorLogInterval } from '../../../../scripts/heatmap';
import HistoryModeDropdown from './HistoryModeDropdown';
import LoadingSpinner from '../../../UiComponents/LoadingSpinner';

const Container = styled.div`
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

const SLIDER_MARK_NR = 5;

const HistoryModeContainer = props => {
    const [state, setState] = useState(heatmapTypes.states.NONE);
    const [selectedInterval, setSelectedInterval] = useState(heatmapTypes.dataIntervals.LAST_15_MINUTES);
    const [sliderValues, setSliderValues] = useState({ value: 0 });
    const [data, setData] = useState();

    useEffect(() => {
        getSensorLogIntervalHandler(selectedInterval);
    }, [props.customerId]);

    const getSensorLogIntervalHandler = async (interval) => {
        setState(heatmapTypes.states.LOADING)
        var res = await getSensorLogInterval(props.customerId, interval);
        if (res && res.sensordata.length) {
            // set slider values: marks, min, max etc, format labels

            var updatedSliderValues = {
                min: 0,
                max: res.sensordata.length - 1,
                value: 0,
                marks: formatSliderLabels(res.sensordata)
            }
            setSliderValues(updatedSliderValues);

            // pass selected data (default first in array) to dataLayerController
            if (res.sensordata) {
                props.actionHandler(heatmapTypes.actions.SENSOR_DATA, res.sensordata[0].averagedValues);
            }

            // add data to state
            setData(res.sensordata);
            setState(heatmapTypes.states.DATA)
        } else {
            setState(heatmapTypes.states.NO_DATA)
        }
    }

    const selectIntervalHandler = interval => {
        // get selected data from state and pass to dataLayerController
        setSelectedInterval(interval);
        getSensorLogIntervalHandler(interval);
    }

    const onSliderChange = (event, newValue) => {
        setSliderValues(values => ({ ...values, value: newValue }));
    }

    const onSliderCommited = (event, newValue) => {
        if (sliderValues.value < data.length) {
            props.actionHandler(heatmapTypes.actions.SENSOR_DATA, data[sliderValues.value].averagedValues);
        }
    }

    return <Container>

        <HistoryModeDropdown selectedInterval={selectedInterval} setSelectedInterval={selectIntervalHandler} />
        {state === heatmapTypes.states.LOADING ? <>
            <LoadingSpinner />
        </> : <></>}
        {state === heatmapTypes.states.NO_DATA ? <>
            <p>No data</p>
        </> : <></>}

        {state === heatmapTypes.states.DATA ? <>
            <div>
                <TimeLineGraph data={getDataAverages(data)} />
                <HistoryModeSlider
                    min={sliderValues.min}
                    max={sliderValues.max}
                    value={sliderValues.value}
                    marks={sliderValues.marks}
                    showValue={'off'}
                    onChange={onSliderChange}
                    onChangeCommitted={onSliderCommited}
                />
            </div>
        </> : <></>}

    </Container>
}

export default HistoryModeContainer;


function formatSliderLabels(sensorData) {
    var L = sensorData.length;
    // show max SLIDER_MARK_NR labels
    var step = Math.round(L / SLIDER_MARK_NR) || 1;
    var marks = [];
    for (var i = 0; i < L; i += step) {
        marks.push({
            value: i,
            label: getHourLabel(new Date(sensorData[i].time))
        });
    }

    // add last label
    /*
    var i = sensorData.length-1;
    marks.push({
        value: i,
        label: getHourLabel(new Date(sensorData[i].time))
    });
    */

    return marks;
}

function getHourLabel(dt) {
    var mins = (dt.getMinutes() < 10) ? '0' + dt.getMinutes() : dt.getMinutes();
    var hours = (dt.getHours() < 10) ? '0' + dt.getHours() : dt.getHours();
    return `${hours}:${mins}`;
}

function getDataAverages(sensordata) {
    // consider filtering on floor
    var averageArr = [];
    sensordata.forEach(dataPoint => {

        if (dataPoint.averagedValues) {
            var sum = 0;
            var count = 0;
            dataPoint.averagedValues.forEach(sensor => {
                if (sensor.averageDecibel) {
                    sum += sensor.averageDecibel;
                    count++;
                }
            })
            averageArr.push(sum / count);
        }

    });
    return averageArr;
}