import StyledIcon from "../StyledIcon";

const WaWaved = props => {
    return <StyledIcon width="27" height="27" viewBox="0 0 27 27" fill="none" {...props}>
        <path d="M19.1234 25.3574H7.92344C4.40344 25.3574 1.52344 22.4774 1.52344 18.9574V7.75742C1.52344 4.23742 4.40344 1.35742 7.92344 1.35742H19.1234C22.6434 1.35742 25.5234 4.23742 25.5234 7.75742V18.9574C25.5234 22.4774 22.6434 25.3574 19.1234 25.3574Z" strokeWidth="2" strokeLinejoin="round" />
        <path d="M2.52344 8.50049H3.07344C4.09094 8.50049 4.97094 9.24299 5.13594 10.2605L6.23594 17.328C6.40094 18.3455 7.28094 19.088 8.29844 19.088C9.31594 19.088 10.1959 18.3455 10.3609 17.328L11.4609 10.2605C11.6259 9.24299 12.5059 8.50049 13.5234 8.50049C14.5409 8.50049 15.4209 9.24299 15.5859 10.2605L16.6859 17.328C16.8509 18.3455 17.7309 19.088 18.7484 19.088C19.7659 19.088 20.6459 18.3455 20.8109 17.328L21.9109 10.2605C22.0759 9.24299 22.9559 8.50049 23.9734 8.50049H24.5234" strokeWidth="2" />
    </StyledIcon>

}

export default WaWaved;