import styled from 'styled-components';
import theme from '../../../UI/theme';

export const CustomerDetailsTable = styled.table`
    width: 100%;

    tr {
        padding: 5px 0;
    }

    thead {
        tr {
            background-color: ${theme.colors.darkSpace90};
        }
    }

    tbody {
        tr:nth-of-type(odd) {
            background-color: ${theme.colors.darkSpace70};
        }

        tr:nth-of-type(even) {
            background-color: ${theme.colors.darkSpace80};
        }

        tr:hover {
            background-color: ${theme.colors.darkSpace60};
        }
    }
    
    td {
        padding: 5px
    }
`