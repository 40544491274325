import { useEffect } from "react";
import { useState } from "react";
import VolumeSlider from "../../../../panel/VolumeSlider";
import { LiveCalibrationSliderContainer } from "./liveCalibrationStyles";

const LiveCalibrationSlider = props => {
    const [systemVolume, setSystemVolume] = useState(!isNaN(props?.zoneLive?.sysvolAbsolute) ? props?.zoneLive?.sysvolAbsolute : null);
    const isRegulatingAudioSource = !props.sourceSelector
        || props.sources?.find(source => source.sourceId === props.sourceSelector?.activeSourceId)?.regulate == true
        || props.sources?.length === 0 ? true : false;

    useEffect(() => {
        setSystemVolume(!isNaN(props?.zoneLive?.sysvolAbsolute) ? props?.zoneLive?.sysvolAbsolute : null);
    }, [props?.zoneLive?.sysvolAbsolute])

    return <LiveCalibrationSliderContainer>
        <VolumeSlider
            disabled={props.zone?.isRegulating || !isRegulatingAudioSource}
            zone={props.zone}
            volume={systemVolume ?? 0} />
    </LiveCalibrationSliderContainer>
}

export default LiveCalibrationSlider;