
const colormap = ['#a8dc00','#a3d601','#9fd002','#9aca02','#96c403','#91be04','#8cb805','#88b206','#83ac07','#7fa607','#7aa008','#769a09','#71940a','#6c8e0b','#68880c','#63820c','#5f7c0d','#5a760e','#55700f','#516a10','#4c6410','#485e11','#435812','#3e5213','#3a4c14','#354615','#314015','#2c3a16','#283417','#232e18','#1e2819','#1a221a','#151c1a','#11161b','#0c101c']

function getColorMap(nColors) {
    var step = Math.floor((colormap.length - 1) / (nColors - 1));
    var nLongerSteps = (colormap.length - 1) % (nColors - 1);
    if (nColors && nColors < colormap.length) {
        var result = [colormap[0]];
        var colormapIndex = 0;
        for (var i = 0; i < nColors - 1; i++) {
            colormapIndex += nLongerSteps-- > 0 ? step + 1 : step;
            result.push(colormap[colormapIndex]);
        }
        return result;
    } else {
        return colormap;
    }
}


export default getColorMap;