import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/trend';

const getTrendData = async (customerId, period, date, type, objectID, dataType) => {
    const result = await axios.get(`${hostname}${path}/trendData?customerId=${customerId}&period=${period}&date=${date.getTime()}&type=${type}&objectID=${objectID}&dataType=${dataType}`);
    if (result.data.trendData) {
        return result.data.trendData;
    } else {
        console.log('Error while fetching trend data');
        return false;
    }
}

const getDailyActivity = async (customerId, period, date, type, objectID) => {
    const result = await axios.get(`${hostname}${path}/dailyActivity?customerId=${customerId}&period=${period}&date=${date.getTime()}&type=${type}&objectID=${objectID}`);
    if (result.data.trendData) {
        return result.data.trendData;
    } else {
        console.log('Error while fetching trend data');
        return false;
    }
}

const getDailyTrendData = async (customerId, period, date, type, objectID, dataType, useOpeningHours) => {
    const result = await axios.get(`${hostname}${path}/dailyTrendData?customerId=${customerId}&period=${period}&date=${date.getTime()}&type=${type}&objectID=${objectID}&dataType=${dataType}&useOpeningHours=${useOpeningHours}`);
    if (result.data.trendData) {
        return result.data.trendData;
    } else {
        console.log('Error while fetching trend data');
        return false;
    }
}

const getAverageTrend = async (customerId, objectID, dataType, hierarchyLevel) => {
    const result = await axios.get(`${hostname}${path}/averageTrend?customerId=${customerId}&objectID=${objectID}&dataType=${dataType}&${hierarchyLevel ? `hierarchyLevel=${hierarchyLevel}` : ''}`);
    if (result.data.trendData) {
        return result.data.trendData;
    } else {
        console.log('Error while fetching trend data');
        return false;
    }
}

const getTrendForDay = async (customerId, date, objectID, dataType) => {
    const result = await axios.get(`${hostname}${path}/trendForDay?customerId=${customerId}&date=${date.getTime()}&objectID=${objectID}&dataType=${dataType}`);
    if (result.data.trendData != undefined) {
        return result.data.trendData;
    } else {
        console.log('Error while fetching trend data');
        return false;
    }
}

const getSensorYearTrend = async (customerId) => {
    const result = await axios.get(`${hostname}${path}/sensorYearTrend?customerId=${customerId}`);
    if (result.data.trendData) {
        return result.data.trendData;
    } else {
        console.log('Error while fetching trend data');
        return false;
    }
}

export {
    getTrendData,
    getDailyActivity,
    getDailyTrendData,
    getAverageTrend,
    getTrendForDay,
    getSensorYearTrend
}