import Paper, { Point, Path, Key } from "paper";
import heatmapTypes from "../../container/heatmapTypes";
import canvasController from "../CanvasController";

var gridSpace = 1;

class Wall {
    constructor(wall = {}, tool, type = heatmapTypes.elementTypes.INNERWALL, getNearestPointOnWallHandler) {
        /* this.wall = new Path({ strokeColor: theme.colors.greenReadable, strokeWidth: 0.2 }); */
        this.wall = new Path({ strokeColor: 'black', strokeWidth: 0.2 });
        this.completed = false;
        this.getNearestPointOnWallHandler = getNearestPointOnWallHandler;

        if (wall.geometry && wall.geometry.length > 0) {
            this.wall.addSegments(wall.geometry.map(point => new Point(point.x, point.y)));
            if (type === heatmapTypes.elementTypes.OUTERWALL) {
                this.wall.closed = true;
                this.completed = true;
            } else if (type === heatmapTypes.elementTypes.INNERWALL) {
                if (wall.geometry.length > 1) {
                    this.completed = true;
                } else {
                    this.completed = false;
                }
            }
        } else {
            this.wall.closed = false;
            this.completed = false;
        }

        this.floorElementID = wall.floorElementId;
        this.cornerNodes = [];
        this.selectedPath = new Path({ strokeColor: 'blue', strokeWidth: 0.2 });
        this.active = false;
        this.tool = tool;
        this.type = type;
    }

    addEventHandlers() {

        Paper.view.onMouseDown = (event) => {
            if (this.active) {
                if (event.event.button == 0) {
                    this.selectedPath.removeSegments();
                    if (!Key.isDown('control')) {
                        Paper.project.deselectAll();
                    }

                    if (!this.isCompleted()) {
                        var closestPoint = this.findClosestPoint(event.point);
                        if (this.wall.firstSegment && this.wall.firstSegment.point.equals(closestPoint)) {
                            this.wall.add(closestPoint);
                            this.wall.closed = true;
                            this.completed = true;
                            if (this.type === heatmapTypes.elementTypes.OUTERWALL) {
                                canvasController.canvasCallback(heatmapTypes.actions.OUTER_WALL_CLOSED, this.wall.reduce());
                            }
                        } else if (!this.wall.segments.some(seg => seg.point.equals(closestPoint))) {
                            this.wall.add(closestPoint);
                            this.addCornerNode(closestPoint);
                        }
                    } else {
                        if (this.active) {
                            canvasController.canvasCallback(heatmapTypes.actions.WALL_SELECT, null);
                            this.deactivate();
                        }
                    }
                }
            }
        }

        Paper.view.onMouseMove = (event) => {
            if (this.active && !this.isCompleted()) {
                var closestPoint = this.findClosestPoint(event.point);
                this.drawNextLinePreview(closestPoint);
            }
        }

        this.tool.onKeyDown = (event) => {
            if (this.active) {
                if (event.key === 'z' && Key.isDown('control')) {
                    this.wall.removeSegment(this.wall.segments.length - 1);
                    if (!this.wall.closed) {
                        var node = this.cornerNodes.pop();
                        node.remove();
                    }
                    this.wall.closed = false;
                } else if (event.key === 'delete') {
                    if (this.selectedPath.segments.length > 0) {
                        var startPoint = this.selectedPath.firstSegment.point;
                        var endPoint = this.selectedPath.lastSegment.point;
                        var curveIndex = this.wall.curves.findIndex((c) => c.point1.equals(startPoint) && c.point2.equals(endPoint));
                        if (this.isCompleted()) {
                            if (this.type === heatmapTypes.elementTypes.OUTERWALL) {
                                this.wall = this.wall.splitAt(this.wall.curves[curveIndex].getNearestLocation(startPoint + endPoint.subtract(startPoint) / 2));
                                this.wall.removeSegment(0);
                                this.selectedPath.removeSegments();
                            } else if (this.type === heatmapTypes.elementTypes.INNERWALL) {
                                this.wall.removeSegments();
                                this.cornerNodes.forEach((node) => {
                                    node.remove();
                                });
                                this.cornerNodes = [];
                                this.completed = false;
                                this.wall.closed = false;
                                this.selectedPath.removeSegments();
                            }
                        }
                    } else {
                        this.deleteSelectedCornerNodes();
                    }
                } else if (event.key === 'escape' && this.type === heatmapTypes.elementTypes.INNERWALL) {
                    this.complete();
                }
            }
        }

        this.wall.onMouseDown = (event) => {
            if (this.active) {
                event.stopPropagation();
                this.activate();
                var hitRes = Paper.project.hitTest(event.point, { tolerance: 0.1, curves: true });
                this.selectedPath.removeSegments();
                Paper.project.deselectAll();
                if (this.type === heatmapTypes.elementTypes.OUTERWALL) {
                    this.selectedPath.addSegments([hitRes.location.curve.point1, hitRes.location.curve.point2]);
                } else if (this.type === heatmapTypes.elementTypes.INNERWALL) {
                    this.selectedPath.addSegments(this.wall.segments);
                }
            }
        }
    }

    complete() {
        this.completed = true;
        this.deactivate();
        canvasController.canvasCallback(heatmapTypes.actions.ADD_INNER_WALL, { wall: this.wall.reduce(), id: this.floorElementID });
    }

    addCornerNode(closestPoint) {
        var newCornerNode = new Path.Circle(closestPoint, 0.3);
        newCornerNode.strokeColor = 'green';
        newCornerNode.fillColor = 'white';
        newCornerNode.strokeWidth = 0.1;
        var mouseDownPoint;
        var segmentIndexes = [];
        newCornerNode.onMouseDown = (event) => {
            if (this.active) {
                if (!Key.isDown('control')) {
                    Paper.project.deselectAll();
                    this.selectedPath.removeSegments();
                }
                if (this.isCompleted()) {
                    event.stopPropagation();
                    newCornerNode.selected = true;
                }

                mouseDownPoint = newCornerNode.position; //this.findClosestPoint(event.point);
                this.wall.segments.forEach((seg, index) => {
                    if (seg.point.equals(mouseDownPoint)) {
                        segmentIndexes.push(index);
                    }
                });
            }
        };
        newCornerNode.onMouseDrag = (event) => {
            if (this.active) {
                event.stopPropagation();
                var cp = this.findClosestPoint(event.point);
                newCornerNode.position = cp;
                segmentIndexes.forEach((index) => this.wall.segments[index].point = cp);

            }
        };
        newCornerNode.onMouseUp = (event) => {
            if (this.active) {
                mouseDownPoint = null;
                segmentIndexes = [];
                if (this.type === heatmapTypes.elementTypes.OUTERWALL) {
                    canvasController.canvasCallback(heatmapTypes.actions.OUTER_WALL_CLOSED, this.wall);
                } else if (this.type === heatmapTypes.elementTypes.INNERWALL) {
                    canvasController.canvasCallback(heatmapTypes.actions.ADD_INNER_WALL, { wall: this.wall, id: this.floorElementID });
                }
            }
        };

        newCornerNode.onMouseEnter = (event) => {
            if (this.type === heatmapTypes.elementTypes.OUTERWALL && !this.isCompleted()) {
                return;
            }
            Paper.view.element.style.setProperty('cursor', 'move');
        }

        newCornerNode.onMouseLeave = (event) => {
            Paper.view.element.style.setProperty('cursor', null);
        }

        this.cornerNodes.push(newCornerNode);
    }

    deleteSelectedCornerNodes() {
        var selectedNodes = this.cornerNodes.filter(node => node.selected == true);
        this.cornerNodes = this.cornerNodes.filter(node => node.selected == false);
        selectedNodes.forEach((node) => {
            var indexes = [];
            this.wall.segments.forEach((seg, index) => {
                if (seg.point.equals(node.position)) {
                    indexes.push(index);
                }
            });
            indexes.sort((a, b) => b - a).forEach((index) => {
                this.wall.removeSegment(index);
            })
            node.remove();
        });
        if (this.type === heatmapTypes.elementTypes.OUTERWALL && this.wall.segments.length < 3) {
            this.wall.closed = false;
            this.completed = false;
        } else if (this.type === heatmapTypes.elementTypes.INNERWALL && this.wall.segments.length < 2) {
            this.completed = false;
        } else {
            canvasController.canvasCallback(heatmapTypes.actions.OUTER_WALL_CLOSED, this.wall);
        }
    }

    drawNextLinePreview(closestPoint) {
        if (this.wall.segments && this.wall.segments.length > 0) {
            var nextLinePath = new Path.Line(this.wall.lastSegment.point, closestPoint).removeOnMove();
            nextLinePath.strokeColor = 'blue';
            nextLinePath.strokeWidth = 0.2;
        } else {
            var newCornerNode = new Path.Circle(closestPoint, 0.3).removeOnMove();
            newCornerNode.strokeColor = 'green';
            newCornerNode.fillColor = 'white';
            newCornerNode.strokeWidth = 0.1;
        }
    }

    findClosestPoint(point) {
        var closestPoint = new Point(Math.round(point.x / gridSpace) * gridSpace, Math.round(point.y / gridSpace) * gridSpace);
        if (this.type === heatmapTypes.elementTypes.INNERWALL) {
            var closestInnerWallPoint = this.getNearestPointOnWallHandler(point, this.floorElementID);
            var threshold = 0.1;
            if (closestInnerWallPoint) {
                if (point.getDistance(closestInnerWallPoint) + threshold < point.getDistance(closestPoint)) {
                    closestPoint = closestInnerWallPoint;
                }
            }
        }
        return closestPoint;
    }

    getNearestPointOnWall(point) {
        if (this.wall && this.wall.segments && this.wall.segments.length > 0) {
            return this.wall.getNearestPoint(point);
        } else {
            return null;
        }
    }

    isCompleted() {
        if (this.type === heatmapTypes.elementTypes.OUTERWALL && this.wall.closed) {
            return true;
        }
        if (this.type === heatmapTypes.elementTypes.INNERWALL && this.completed) {
            return true;
        }
        return false;
    }

    activate() {
        this.active = true;
        this.addEventHandlers();
        this.highlight();
    }

    deactivate() {
        this.active = false;
        this.lowlight();
        this.selectedPath.removeSegments();
    }

    highlight() {
        this.wall.segments.forEach((seg) => {
            if (!this.cornerNodes.some(node => node.position.equals(seg.point))) {
                this.addCornerNode(seg.point);
            }
        })
    }

    lowlight() {
        this.cornerNodes.forEach((node) => {
            node.remove();
        });
        this.cornerNodes = [];
    }

    remove() {
        if (this.wall) {
            this.wall.remove();
        }
    }
}

export default Wall;
