import styled from "styled-components"
import LiveChart from "./graph/MeterLiveChart"
import LoadDataContainer, { loadDataHandler } from "../../../UiComponents/containers/LoadDataContainer"
import { loadingStates } from "../../../installation/installationTypes"
import { subscriptionsTypes } from "../../../../scripts/subscription/subscriptionTypes"
import zoneTypes from "../../../live/zone/zoneTypes"
import { DateTime } from "luxon"
import { getDateTime, getDefinedLogInterval } from "../../../../scripts/log"
import { useEffect, useState } from "react"
import { useSubscription } from "../../../../hooks/useSubscription"

const StyledLiveChart = styled(LiveChart)`
    height: 400px;
    width: 700px;
`

const LiveChartContainer = styled.div`
    height: 400px;
    width: 700px;
`

const ZoneLiveDataContainer = ({ customerId, zoneId }) => {
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [zoneLive, _] = useSubscription(subscriptionsTypes.zoneLive, zoneId);
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        loadDataHandler(getData, setLoadingState);
    }, [])

    useEffect(() => {
        if (graphData.length) {
            setGraphData(prev => {
                const prevCopy = JSON.parse(JSON.stringify(prev));
                prevCopy.pop();
                prevCopy.unshift(formatData(zoneLive));
                return prevCopy;
            });
        }
    }, [zoneLive?.time]);

    const getData = async () => {
        const newData = await getDefinedLogInterval(customerId, zoneId, zoneTypes.intervals.LAST_15_MINUTES, null);
        setGraphData(newData.map(dataPoint => formatData(dataPoint)));
    }

    return <LoadDataContainer loadingState={loadingState}>
        <LiveChartContainer>
            <StyledLiveChart data={graphData} />
        </LiveChartContainer>
    </LoadDataContainer>
}

export default ZoneLiveDataContainer;

const formatData = (data) => {
    return {
        time: data.time,
        timestamp: DateTime.fromJSDate(getDateTime(data.time)),
        totDb: data.averageDecibel,
        filteredDb: data.averageDecibel - data.averageDiff,
        ambienceDb: data.ambienceDb,
        meterDb: data.meterDb + 80,
        gainDb: data.gainDb + 80

        //compensationDb: data.compensationDb,
        //gain: data.gain,

        //avgAmbienceDb: data.avgAmbienceDb,
        //avgFilDb: data.avgFilDb,
        //ambienceBaseLevel: data.ambienceBaseLevel,
        //outputMeterDb: data.outputMeterDb,
        //gain: data.gain,
        //vibe: data.vibe
    }
}