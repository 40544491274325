import { useEffect, useRef, useState } from "react";
import SearchField from "../../../UiComponents/SearchField";
import { ListContainer, ListItem, SidePanelInnerContainer } from "./styles";

const SidePanelList = ({ items, selectedId, setSelectedId, searchFilter, renderItem, idField }) => {
    const [searchString, setSearchString] = useState('');
    const selectedRef = useRef();

    const filteredItems = items.filter(item => searchFilter(item, searchString));

    useEffect(() => {
        //selectedRef.current?.scrollIntoView()
    }, [])

    return <SidePanelInnerContainer>
        <div>
            <SearchField placeholder={'Search'} value={searchString} onChange={(e) => setSearchString(e.target.value)} />
        </div>
        <ListContainer>
            <ul>
                {filteredItems.map(item => <ListItem
                    key={item[idField]}
                    onClick={() => setSelectedId(item[idField])}
                    active={item[idField] === selectedId}
                    ref={item[idField] === selectedId ? selectedRef : null}
                >
                    {renderItem(item)}
                </ListItem>)}
                <div style={{height: '50px'}}></div>
            </ul>
        </ListContainer>
    </SidePanelInnerContainer>
}

export default SidePanelList;