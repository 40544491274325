
const filterOpeningHours = (trend, openingHours) => {
    if (trend && openingHours) {
        var copyTrend = JSON.parse(JSON.stringify(trend));
        copyTrend.data.map((day, index) => {
            var start = parseInt(openingHours[index].start)/100;
            var end = parseInt(openingHours[index].end)/100;

            if (start == end) {
                return day;
            } else if (end > start) {
                day.hourData = day.hourData.filter((hour, hourIndex) => hourIndex >= start && hourIndex < end);
            } else if (end < start) {
                if (index < 6) {
                    day.hourData = day.hourData.filter((hour, hourIndex) => hourIndex >= start).concat(trend.data[index + 1].hourData.filter((hour, hourIndex) => hourIndex < end));
                } else {
                    return day;
                }
            }
        })
        return copyTrend;
    } else {
        return trend;
    }
}

const getOpeningHourLabels = (openingHours) => {
    var labelArray = [];
    if (openingHours) {
        openingHours.forEach((day) => {
            if (day.open == "true") {
                labelArray.push([]);
            }
            var labels = [];
            var start = parseInt(day.start)/100;
            var end = parseInt(day.end)/100;
            for (let index = 0; index < (end - start + 24) % 24; index++) {
                labels.push((start + index) % 24);
            }
            labelArray.push(labels.map(key => ("0" + key).slice(-2)));
        })
        return labelArray;
    } else {
        for (let index = 0; index < 7; index++) {
            labelArray.push(([...Array(24).keys()]).map(key => ("0" + key).slice(-2)));
        }
        return labelArray;
    }
}

export {
    filterOpeningHours,
    getOpeningHourLabels
}