import React from 'react';
import ChoiceField from '../../../fields/ChoiceField';

const ZoneChoiceField = ({ object, zones, processors, updateObject, cellControl }) => {
    const currentZone = zones.find(zone => zone.zoneId === object.zoneId);
    var processorId = processors.find(p => p.type === object.type).processorId;
    var approvedZones = zones.filter(zone => zone.processorId === processorId);
    approvedZones.push({zoneId: null, zoneName: '-- None --'}, );
    return <ChoiceField
        selectedItem={currentZone && { value: currentZone.zoneId, label: currentZone?.zoneName }}
        items={approvedZones.map(zone => ({ value: zone.zoneId, label: zone.zoneName }))}
        setValue={(value) => updateObject({ zoneId: value })}
        cellControl={cellControl}
        excludeNone={true}
    />
}

export default ZoneChoiceField;