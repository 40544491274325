import { useState } from "react";
import styled from "styled-components";
import theme from "../../../../UI/theme";
import ButtonContainer from "../../../UiComponents/containers/ButtonContainer";
import InputField from "../../../UiComponents/InputField";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import Button from "../../../UiComponents/Button";
import { updateGoogleOpeningHours } from "../../../../scripts/client";
import InnerCard from "../../../UiComponents/containers/InnerCard";
import ExpandCollapseButton from "../../../UiComponents/buttons/ExpandCollapseButton";
import AnimateHeight from "react-animate-height";
import { updateCustomer } from "../../../../scripts/datasource";
import { useEffect } from "react";

const StyledCard = styled(InnerCard)`
    padding: 6px 16px;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    :hover {
        cursor: pointer;
    }

    h4 {
        margin: 16px 0;
    }
`

const InfoText = styled(TextMuted)`
    margin: 10px 0; 
`

const StyledLink = styled.a`
    color: ${theme.colors.greenReadable};
`

const ErrorMessage = styled.label`
    margin: 10px 0;
    color: ${theme.colors.raspberryRed};
    padding: 0;
`

const ButtonCont = styled(ButtonContainer)`
    margin-bottom: 10px;
`

const StyledInputField = styled(InputField)`
    background-color: ${theme.colors.darkSpace90};
`

const GooglePlaceIDForm = props => {
    const [placeID, setPlaceID] = useState(props.venue?.googlePlaceId);
    const [error, setError] = useState();
    const [expanded, setExpanded] = useState();

    useEffect(() => {
        setPlaceID(props.venue?.googlePlaceId);
    }, [props.venue?.googlePlaceId])

    const updateOpeningHoursHandler = async () => {
        if (placeID && placeID.length) {
            var res = await updateGoogleOpeningHours(props.customerId, placeID);
            if (res === false) {
                setError('Could not update opening hours from Google');
            } else {
                setError(null);
                await props.refresh();
            }
        }
    }

    const unlinkVenueHandler = async () => {
        try {
            await updateCustomer({ customerId: props.customerId, googlePlaceId: null });
            setError(null);
            props.refresh();
        } catch (error) {
            setError('Could not unlink venue from Google');
        }
    }

    return <StyledCard>
        <NavigationContainer onClick={() => setExpanded(prev => { return !prev })}>
            <h4>Auto-import venue information from Google</h4>
            <ExpandCollapseButton showState={expanded} color={theme.colors.darkSpace60} />
        </NavigationContainer>

        <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
            <Container>
                {placeID !== null && placeID === props.venue?.googlePlaceId ? <>
                    <InfoText>Linked Google place ID</InfoText>
                    <StyledInputField value={placeID === null ? '' : placeID} disabled />
                    <ButtonCont>
                        <Button secondary onClick={() => unlinkVenueHandler()}>Unlink</Button>
                    </ButtonCont>
                </>
                    : <>
                        <InfoText>Google place ID</InfoText>
                        <StyledInputField value={placeID === null ? '' : placeID} onChange={(e) => setPlaceID(e.target.value)} />
                        <InfoText>Don't know your Google place ID? Find it <StyledLink href={'https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder?hl=nb'} target="_blank">here</StyledLink></InfoText>

                        <ButtonCont>
                            <Button primary onClick={() => updateOpeningHoursHandler()}>Link venue to Google</Button>
                        </ButtonCont>
                    </>}

                {error ? <ErrorMessage>{error}</ErrorMessage> : <></>}
            </Container>
        </AnimateHeight>

    </StyledCard>
}

export default GooglePlaceIDForm;