
import Paper, { Layer, Point } from "paper";
import canvasController from "../CanvasController";
import heatmapTypes from "../../container/heatmapTypes";
import LayerController from "../LayerController";
import { getHubConnectedStatus } from "../../../installation/utils/connectionStatus";

var gridSpace = 1;
class DeviceLayerController extends LayerController {
    constructor() {
        super();
        this.layer = new Layer();
        this.active = false;
        this.list = [];
        this.layer.name = 'Device Layer';
    }

    onModeChange(modeName) {
        this.list.forEach(device => {
            device.removeEventHandlers();
        })
        if (modeName === heatmapTypes.modes.DOCUMENTATION) {
            this.list.forEach(device => {
                device.addDocumentationModeEventHandlers();
            });
            this.list.forEach(device => device.showConnectionStatus());
        } else {
            this.list.forEach(device => device.hideConnectionStatus());
        }
    }

    activate() {
        this.layer.activate();
        this.active = true;
        this.addEventHandlers();
    }

    deactivate() {
        this.active = false;
        this.list.forEach(device => {
            device.removeEventHandlers();
        });
    }

    async drawDevices(devices) {
        this.layer.removeChildren();
        this.removeData();

        let promises = [];
        devices.forEach(device => {
            const currentDevice = this.list.find(item => item.id === device.floorElementId);
            if (currentDevice) {
                const promise = currentDevice.placeElement();
                promises.push(promise);
            } else {
                this.list.push(this.constructObject(device));
            }
        });
        if (canvasController.activeMode === heatmapTypes.modes.DOCUMENTATION) {
            await Promise.all(promises);
            this.list.forEach(device => {
                device.showConnectionStatus();
            })
        }
    }

    addEventHandlers() {
        Paper.view.onMouseDown = (event) => {
            if (this.active) {

            }
        }
        this.list.forEach(device => {
            device.addEventHandlers();
        });
    }

    placeDevice(device) {
        const deviceItem = this.list.find(s => s.id == device.floorElementId);
        if (deviceItem) {
            deviceItem.startPlacing();
        } else {
            const newDevice = this.constructObject(device);
            newDevice.startPlacing();
            this.list.push(newDevice);
        }
    }

    removeDevice(device) {
        var deviceItem = this.list.find(s => s.id == device.floorElementId);
        if (deviceItem) {
            deviceItem.remove();
        } else {
            canvasController.canvasCallback(heatmapTypes.actions.DEVICE_REMOVED, { id: device.floorElementId });
        }
    }

    markDevice(device) {
        this.list.find(s => s.id == device.floorElementId)?.mark();
    }

    unmarkAllDevices() {
        this.list.forEach(device => {
            device.unmark();
        });
    }

    initSetupMode() {

    }

    initDataMode() {

    }

    removeData() {
        
    }

    changeFloorHandler(outerWallElement) {
        this.outerWallElement = outerWallElement;
    }
}

export default DeviceLayerController;