import styled, { css } from 'styled-components';
import theme from '../../../UI/theme';
import Button from '../../UiComponents/Button';

export const CustomerList = styled.ul`
    list-style: none;
    overflow-y: auto;
    max-height: 50vh;
    padding: 0;
    margin: 0;
`;

export const ListItem = styled.li`
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr auto;
    gap: 10px;
    align-items: center;
    white-space: nowrap;
    background-color: ${theme.colors.darkSpace90};
    border-radius: 2px;

    > label {
        min-width: 20px;
        text-align: end;
    }

    @media (hover:hover) {
        &:hover {
            background-color: ${theme.colors.darkSpace80};
        }
    }

    :not(:last-child) {
        margin-bottom: 5px;
    }

    ${props => props.current && css`
        color: ${theme.colors.greenEnergy};
        background-color: ${theme.colors.darkSpace80};
    `}
`

export const ImpersonatorInfoContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`

export const CustomerInfoContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
`

export const ImpersonateContainer = styled.div`
    position: fixed;
    touch-action: none;
    top: 10px;

    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        right: 10px;
    }
    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        left: 10px;
    }

    z-index: 20;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.darkSpace70};
    opacity: 0.9;
    padding: 6px;
    border-radius: 4px;
    font-size: 12px;

    >div {
        color: ${theme.colors.greenEnergy};
        white-space: nowrap;
    }
`
export const ImpersonateButton = styled(Button)`
    white-space: nowrap;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    &> label {
        cursor: inherit;
    }
`

export const CurrentImpersonationLabel = styled.label`
    color: ${theme.colors.greenReadable};
`

export const TopContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;

    > button {
        height: fit-content;
        aspect-ratio: 1;
    }
`

export const SearchContainer = styled.div`
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 10px;
    overflow: hidden;
`