import { useEffect, useState } from "react";
import { loadingStates } from "../installation/installationTypes";
import LoadingSpinner from "../UiComponents/LoadingSpinner";
import Button from "../UiComponents/Button";
import { TextMuted } from "../UiComponents/text/StyledText";
import { GoodLabel } from "./setupStyles";
import { FaCheckCircle } from "react-icons/fa";
import serverConnection from "../../scripts/server/ServerConnection";
import { isLocalApp } from "../../scripts/server/server";

const sendHubEventHandler = (command, hub) => {
    return serverConnection.hubEventHandler.sendEvent(
        hub?.hubId,
        hub?.customerId,
        command,
        null,
        7000,
        true
    );
}

const checkHubVersion = (hub) => sendHubEventHandler('CHECK_HUB_VERSION', hub);
const updateHubVersion = (hub) => sendHubEventHandler('UPDATE_HUB_VERSION', hub);
const restartApp = (hub) => sendHubEventHandler('RESTART_APP_DIRECT', hub);


const VersionUpdate = ({ hub }) => {
    const [updateAvailable, setUpdateAvailable] = useState(false);
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    useEffect(() => {
        checkVersion();
    }, []);

    const checkVersion = async () => {
        try {
            const timeout = setTimeout(() => {
                setLoadingState(loadingStates.FAILED);
            }, 3000);
            setLoadingState(loadingStates.LOADING);
            const updateAvailableRes = await checkHubVersion(hub);
            clearTimeout(timeout);
            setUpdateAvailable(updateAvailableRes);
            setLoadingState(loadingStates.SUCCESS);
        } catch (err) {
            setLoadingState(loadingStates.FAILED);
        }
    }



    return <>
        {loadingState === loadingStates.SUCCESS ? <>
            {updateAvailable ? <>
                <div>
                    <UpdateButton hub={hub} />
                </div>
            </> : <>
                <GoodLabel><FaCheckCircle /> Up to date</GoodLabel>
            </>}
        </> : <></>}

        {loadingState === loadingStates.LOADING ? <>
            <LoadingSpinner />
        </> : <></>}
    </>
}

export default VersionUpdate;

const UpdateButton = ({ hub }) => {
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    const startUpdate = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            const updateRes = await updateHubVersion(hub);
            if (updateRes) {
                setLoadingState(loadingStates.SUCCESS);
            } else {
                setLoadingState(loadingStates.FAILED);
            }
        } catch (err) {
            setLoadingState(loadingStates.FAILED);
        }
    }

    const restartAppHandler = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            await restartApp(hub);

            setTimeout(() => {
                if (isLocalApp()) {
                    window.location.reload(false);
                } else {
                    setLoadingState('COMPLETED');
                }
            }, 2500);
        } catch (err) {
            setLoadingState(loadingStates.FAILED);
        }
    }

    return <>
        {loadingState === loadingStates.NONE ? <>
            <Button small primary onClick={startUpdate} style={{ marginRight: '6px' }}>Update</Button>
            <TextMuted>Update is available</TextMuted>
        </> : <></>}

        {loadingState === loadingStates.LOADING ? <>
            <LoadingSpinner />
        </> : <></>}

        {loadingState === loadingStates.SUCCESS ? <>
            <Button small primary onClick={restartAppHandler} style={{ marginRight: '6px' }}>Reload</Button>
            <TextMuted>Update downloaded successfully</TextMuted>
        </> : <></>}

        {loadingState === loadingStates.FAILED ? <>
            Something went wrong
        </> : <></>}

        {loadingState === 'COMPLETED' ? <>
            Completed
        </> : <></>}
    </>
}