import { useState } from "react";
import { useEffect } from "react";
import { getCustomer } from "../../../scripts/datasource";
import { RowDetailsContainer } from "./customerHealthStyles";

const CustomerDetails = props => {
    const [customer, setCustomer] = useState();

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        let customerResult = await getCustomer(props.customerId);
        setCustomer(customerResult);
    }

    return <RowDetailsContainer>
        <label>Venue: {customer?.companyName}</label>
    </RowDetailsContainer>
}

export default CustomerDetails;