import { ApolloClient, InMemoryCache, gql } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";

export const createApolloClient = (link) => {
    const client = new ApolloClient({
        link: link,
        cache: new InMemoryCache()
    });

    return client;
}

export const createWebSocketLink = (url, token) => {
    const client = createClient({
        url: url,
        connectionParams: {
            'Authorization': `Bearer ${token}`,
        }
    })
    const wsLink = new GraphQLWsLink(client);

    return wsLink;
}