import { useState } from "react";
import Button from "../../../../UiComponents/Button";
import Modal from "../../../../UiComponents/Modal";
import styled from "styled-components";
import { FaEye } from "react-icons/fa";
import { loadingStates } from "../../../../installation/installationTypes";
import { TextMuted } from "../../../../UiComponents/text/StyledText";
import WiFiNetworkItem from "./WiFiNetworkItem";
import WiFiNetworks from "./WiFiNetworks";
import serverConnection from "../../../../../scripts/server/ServerConnection";
import { ModalContainer, ModalHeader } from "../../../../panel/ModalStyles";

export const ConfigureWifiButton = ({ hub }) => {
    const [showModal, setShowModal] = useState(false);

    return <>
        <Button tertiary small onClick={() => setShowModal(true)}>Edit</Button>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Wi-Fi configuration</ModalHeader>

                <label>Available Wi-Fi networks</label>

                <WiFiNetworks hub={hub} />

                <label>Manual Wi-Fi entry</label>
                <WiFiNetworkItem hub={hub} manualEntry={true} selected={true} />

            </ModalContainer>
        </Modal>
    </>
}

const StyledButton = styled(Button)`
    border: none;
    background-color: transparent;
    padding: 4px;
`

export const ShowPasswordButton = (props) => {
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [password, setPassword] = useState();

    const getPassword = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            const res = await serverConnection.hubEventHandler.sendEvent(
                props.hub?.hubId,
                props.hub?.customerId,
                'GET_WIFI_CREDENTIALS',
                null,
                20000
            );

            if (res.password) {
                setPassword(res.password);
                setLoadingState(loadingStates.SUCCESS);
            } else {
                setLoadingState(loadingStates.FAILED);
            }
        } catch (err) {
            setLoadingState(loadingStates.FAILED);
        }
    }

    return <>
        <TextMuted>Password:&nbsp;
            {loadingState === loadingStates.NONE ? <>
                <StyledButton onClick={getPassword}><FaEye /></StyledButton>
            </> : <></>}

            {loadingState === loadingStates.LOADING ? <>
                <span>loading...</span>
            </> : <></>}

            {loadingState === loadingStates.SUCCESS ? <>
                <span>{password}</span>
            </> : <></>}

            {loadingState === loadingStates.FAILED ? <>
                <span>Could not get password</span>
                <Button tertiary small onClick={getPassword}>Retry</Button>
            </> : <></>}
        </TextMuted>

    </>
}