import React, { useState, useEffect, useContext } from 'react';
import InputField from '../../UiComponents/InputField';
import Button from '../../UiComponents/Button';
import Card from '../../UiComponents/Card';
import OpeningHoursMenuItem from './openingHours/OpeningHoursMenuItem';
import GooglePlaceIDForm from './openingHours/GooglePlaceIDForm';
import { updateCustomer } from '../../../scripts/datasource';
import { SettingsContainer, SettingsParagraph, SettingsSection } from '../settingsStyles';
import { ButtonRowContainer } from '../../panel/ModalStyles';

const VenueInfo = props => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedInfo, setEditedInfo] = useState();
    const [edited, setEdited] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        if (error) {
            setError(null);
        }
    }, [isEditing])

    const changeHandler = (key, newValue) => {
        var updatedVenue = { ...editedInfo };
        updatedVenue[key] = newValue;
        setEditedInfo(updatedVenue);
        if (!edited) {
            setEdited(true);
        }
    }

    const saveHandler = async () => {

        if (editedInfo) {
            try {
                await updateCustomer({ ...editedInfo, customerId: props.venue?.customerId });
                props.setVenue(prev => { return { ...prev, ...editedInfo } });
                setIsEditing(false);
                setEditedInfo(null);
            } catch (error) {
                setError('Something went wrong, could not update info');
            }
        }
    }

    const startEditing = () => {
        setEditedInfo((({ address, companyName, timezone }) => ({ companyName, address, timezone }))(props.venue));
        setIsEditing(true);
    }

    const getInfoItem = (key, label) => {
        return <SettingsSection>
            <label>{label}</label>
            {isEditing && editedInfo ? <>
                <InputField value={editedInfo[key]} onChange={(e) => changeHandler(key, e.target.value)} />
            </> : <>
                <SettingsParagraph>{props.venue?.[key] ?? '-'}</SettingsParagraph>
            </>}
        </SettingsSection>
    }

    return <Card>
        <SettingsContainer>
            {getInfoItem('companyName', 'Name')}
            {getInfoItem('address', 'Address')}
            {getInfoItem('timezone', 'Time zone')}

            {error ? <>
                <SettingsSection>
                    <SettingsParagraph>
                        {error}
                    </SettingsParagraph>
                </SettingsSection>
            </> : <></>}

            <SettingsSection>
                <ButtonRowContainer>
                    {isEditing ? <>
                        {edited && <Button small primary onClick={saveHandler}>
                            Save
                        </Button>}
                        <Button small secondary onClick={() => setIsEditing(false)}>Cancel</Button>
                    </> : <>
                        <Button disabled={props.readOnly || props.venue?.googlePlaceId !== null} small tertiary onClick={() => startEditing()}>
                            Edit
                        </Button>
                    </>}
                </ButtonRowContainer>
            </SettingsSection>

            <OpeningHoursMenuItem
                customerId={props.venue?.customerId}
                placeID={props.venue?.googlePlaceId}
                openingHours={props.openingHours}
                refresh={props.refresh} />

            <GooglePlaceIDForm
                customerId={props.venue?.customerId}
                venue={props.venue}
                refresh={props.refresh} />
        </SettingsContainer>

    </Card>
}

export default VenueInfo;