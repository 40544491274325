import { useState } from "react";
import { MdLocationOn } from "react-icons/md";
import { InnerContainer, ItemTitle, StyledLink } from "./MenuBarStyles";
import MyVenuesView from "../../views/MyVenuesView";
import Modal from "../UiComponents/Modal";
import theme from "../../UI/theme";

const VenuesMenuItem = props => {
    const [showModal, setShowModal] = useState(false);

    return <>
        <StyledLink onClick={(e) => { e.preventDefault(); setShowModal(true) }} >
            <InnerContainer>
                <MdLocationOn color={showModal ? theme.colors.greenEnergy : theme.colors.textGray} />
                <ItemTitle>Venues</ItemTitle>
            </InnerContainer>
        </StyledLink>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <MyVenuesView
                venues={props.venues}
                user={props.user}
                customer={props.customer}
                setCustomer={props.setCustomer}
                onClick={() => setShowModal(false)}
            />
        </Modal>
    </>
}

export default VenuesMenuItem;