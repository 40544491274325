import { FaChevronRight } from "react-icons/fa";
import InvisibleLink from "../UiComponents/InvisibleLink";
import { SettingsLink } from "./settingsStyles";

const LinkItem = props => {
    const Icon = props.icon;
    return <InvisibleLink to={props.to} target={props.target}>
        <SettingsLink>
            <label>{props.display}</label>
            {props.icon ? <Icon /> : <FaChevronRight />}
        </SettingsLink>
    </InvisibleLink>
}

export default LinkItem;