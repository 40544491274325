import { isLocalApp } from "../../scripts/server/server";

const permissionTypes = {
    panelViewRead: 'panelView.read',
    panelViewWrite: 'panelView.write',
    insightsRead: 'insights.read',
    settingsRead: 'settings.read',
    settingsWrite: 'settings.write',
    installationViewRead: 'installationView.read',
    installationViewWrite: 'installationView.write',
    calibrationRead: 'calibration.read',
    calibrationWrite: 'calibration.write',
    userAdministrationRead: 'userAdministration.read',
    userAdministrationWrite: 'userAdministration.write'
}

const roles = {
    admin: 'admin',
    impersonator: 'impersonator',
    user: 'user'
}

const permissionHierarchy = {
    [permissionTypes.installationViewWrite]: [
        permissionTypes.installationViewRead,
    ],
    [permissionTypes.panelViewWrite]: [
        permissionTypes.panelViewRead,
    ],
    [permissionTypes.calibrationWrite]: [
        permissionTypes.calibrationRead
    ],
    [permissionTypes.settingsWrite]: [
        permissionTypes.settingsRead
    ],
    [permissionTypes.userAdministrationWrite]: [
        permissionTypes.userAdministrationRead
    ]
}

const addPermission = (inheritedPermissions, permission) => {
    if (!inheritedPermissions.includes(permission)) {
        inheritedPermissions.push(permission);
        const childPermissions = permissionHierarchy[permission];
        if (childPermissions) {
            childPermissions.forEach(childPermission => {
                addPermission(inheritedPermissions, childPermission);
            })
        }
    }
}

const combineInheritedPermissions = (permissions) => {
    const inheritedPermissions = [];
    permissions.forEach(permission => {
        addPermission(inheritedPermissions, permission);
    })
    return inheritedPermissions;
}

const getInheritedPermissions = (permission) => {
    const inheritedPermissions = [];
    addPermission(inheritedPermissions, permission);
    return inheritedPermissions;
}

const includesPermission = (userPermission, requiredPermission) => {
    const inheritedPermissions = getInheritedPermissions(userPermission);
    return inheritedPermissions.includes(requiredPermission);
}

const entityTypes = {
    organization: 'organization',
    venue: 'venue',
    zone: 'zone',
}

const hasUserPermission = (permissions, permissionType, entityType, entityId) => {
    return permissions.some(userPermission =>
        includesPermission(userPermission.permissionType, permissionType) &&
        userPermission.entityType === entityType &&
        userPermission.entityId === entityId.toString()
    )
}

const hasVenuePermission = (context, permissionType) => {
    if (!permissionType) {
        return true;
    }
    if (isLocalApp()) {
        return true;
    }
    const role = context.user.role;
    const venueId = context.customer.customerId;
    const userPermissions = context.permissions;
    if (role === roles.admin) {
        return true;
    } else if (role === roles.impersonator) {
        if (permissionType.includes('.read') || hasUserPermission(userPermissions, permissionType, entityTypes.venue, venueId)) {
            return true;
        }   
    } else if (role === roles.user) {
        if (hasUserPermission(userPermissions, permissionType, entityTypes.venue, venueId)) {
            return true;
        }
    }
    return false;
}

const hasRole = (context, requiredRole) => {
    const userRole = context.user.role;
    if (requiredRole) {
        if (requiredRole === roles.admin) {
            return userRole === roles.admin
        } else if (requiredRole === roles.impersonator) {
            return userRole === roles.impersonator || userRole === roles.admin;
        } else {
            return false;
        }
    } else {
        return true;
    }
}

const getVenueIdsFromPermissions = (permissions) => {
    const venueIds = [];
    for (let permission of permissions.filter(p => p.entityType === entityTypes.venue)) {
        if (!venueIds.includes(permission.entityId)) {
            venueIds.push(permission.entityId);
        }
    }

    return venueIds;
}

export {
    permissionTypes,
    entityTypes,
    roles,
    getInheritedPermissions,
    includesPermission,
    combineInheritedPermissions,
    hasVenuePermission,
    hasRole,
    getVenueIdsFromPermissions
}