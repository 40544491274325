import styled from 'styled-components';

const CanvasMessageBox = styled.div`
    width: 200px;
    position: absolute;
    z-index: 20;
    left: 10px;
    bottom: 10px;
`

export default CanvasMessageBox;