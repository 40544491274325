import StyledIcon from "../StyledIcon";

const WaSpeakers = props => {
    return <StyledIcon width="30" height="27" viewBox="0 0 30 27" fill="none" {...props}>
        <path d="M18.8747 20.3574C20.8064 20.3574 22.3724 18.7904 22.3724 16.8574C22.3724 14.9244 20.8064 13.3574 18.8747 13.3574C16.9429 13.3574 15.377 14.9244 15.377 16.8574C15.377 18.7904 16.9429 20.3574 18.8747 20.3574Z" strokeWidth="2" strokeLinejoin="round" />
        <path d="M28.3684 1.35742H9.38086V25.3574H28.3684V1.35742Z" strokeWidth="2" strokeLinejoin="round" />
        <path d="M6.36587 25.3574H1.36914V6.35742H6.36587" strokeWidth="2" strokeLinejoin="round" />
        <path d="M18.875 9.24578C19.9796 9.24578 20.875 8.34977 20.875 7.24447C20.875 6.13918 19.9796 5.24316 18.875 5.24316C17.7704 5.24316 16.875 6.13918 16.875 7.24447C16.875 8.34977 17.7704 9.24578 18.875 9.24578Z" />
        <path d="M18.875 9.24578C19.9796 9.24578 20.875 8.34977 20.875 7.24447C20.875 6.13918 19.9796 5.24316 18.875 5.24316C17.7704 5.24316 16.875 6.13918 16.875 7.24447C16.875 8.34977 17.7704 9.24578 18.875 9.24578Z" />
    </StyledIcon>
}

export default WaSpeakers;