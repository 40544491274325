import { useEffect, useState } from "react";
import { getZones } from "../../../../scripts/zone";
import LoadDataContainer, { loadDataHandler } from "../../../UiComponents/containers/LoadDataContainer";
import { loadingStates } from "../../../installation/installationTypes";
import Dropdown from "../../../UiComponents/Dropdown";

const ZoneSelector = ({ selectedZoneId, setSelectedZoneId, customerId }) => {
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [zones, setZones] = useState();

    useEffect(() => {
        loadDataHandler(getData, setLoadingState);
    }, [customerId]);

    const getData = async () => {
        const zoneRes = await getZones(customerId)
        setZones(zoneRes);
        if (zoneRes.length === 1) {
            setSelectedZoneId(zoneRes[0].zoneId);
        }
    }

    const selectedZone = zones?.find(zone => zone.zoneId === selectedZoneId);

    return <LoadDataContainer loadingState={loadingState}>
        <label>Select zone</label>
        {zones &&
            <Dropdown
                small
                items={zones?.map(z => ({label: z.zoneName, value: z.zoneId}))}
                selectedItem={{ label: selectedZone?.zoneName, value: selectedZone?.zoneId }}
                onClick={(item) => setSelectedZoneId(item.value)} />
        }
    </LoadDataContainer>
}

export default ZoneSelector;