import { Indicator } from "../../admin/support/SupportStyles";
import PopupElement from "../../UiComponents/PopupElement"
import styled from 'styled-components';
import theme from "../../../UI/theme";

const Container = styled.div`
    position: relative;
    display: inline-block;
`

const FieldError = ({error}) => {

    const mainContent = <Indicator color={getIndicatorColor(error.type)} />
    const popupContent = <div>{error.message}</div>

    return <Container>
        <PopupElement
            mainContent={mainContent}
            popupContent={popupContent}
        />
    </Container>
}

export default FieldError;

const getIndicatorColor = (errorType) => {
    switch (errorType) {
        case 'WARNING':
            return theme.colors.mellowYellow;
        default:
            return theme.colors.raspberryRed;
    }
}