import { useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { FaGripLinesVertical } from "react-icons/fa";
import theme from "../../../../../UI/theme";
import { DragIndicatorIcon } from "../../../../UiComponents/Icons/materialIcons";
import './calibrationSliderStyles.css';

const CalibrationPointsSlider = props => {
    const [values, setValues] = useState({ min: props.minVal, max: props.maxVal });
    const minValRef = useRef(null);
    const maxValRef = useRef(null);
    const rangeRef = useRef(null);

    useEffect(() => {
        setValues({ min: props.minVal, max: props.maxVal });
    }, [props.minVal, props.maxVal])

    const onChangeValues = (e, minHandle) => {
        if (minHandle === true) {
            const difference = +e.target.value - values.min;
            const newMax = Math.min(100, values.max + difference);
            const actualDifference = newMax - values.max;
            const newMin = values.min + actualDifference;
            setValues({ min: newMin, max: newMax });
            minValRef.current.value = newMin.toString();
            maxValRef.current.value = newMax.toString();
        } else {
            const newMax = Math.max(+e.target.value, values.min + 1);
            setValues(prev => {
                return { ...prev, max: newMax };
            })
            maxValRef.current.value = newMax.toString();
        }

        if (props.setHasChanged) {
            props.setHasChanged(true);
        }
    }

    const getPercent = useCallback(
        (value) => ((value - props.min) / (props.max - props.min)) * 100, [props.min, props.max]
    );

    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(values.min);
            const maxPercent = getPercent(+maxValRef.current.value);

            if (rangeRef.current) {
                rangeRef.current.style.left = `${minPercent}%`;
                rangeRef.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [values.min, getPercent]);

    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(values.max);

            if (rangeRef.current) {
                rangeRef.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [values.max, getPercent]);

    useEffect(() => {
        if (props.onChange && props.hasChanged === true) {
            props.onChange({ min: values.min, max: values.max });
        }
    }, [values.min, values.max]);

    return <div
        className={window.matchMedia(`(max-width: ${theme.screenSizes.medium - 1}px)`).matches ? 'smallScreenSliderVariables' : 'largeScreenSliderVariables'}
    >
        <input
            type="range"
            className="hiddenInput"
            min={props?.min}
            max={props?.max}
            step={1}
            value={values?.min}
            ref={minValRef}
            onChange={(e) => onChangeValues(e, true)}
        />
        <input
            type="range"
            className="hiddenInput"
            min={props?.min}
            max={props?.max}
            step={1}
            value={values.max}
            ref={maxValRef}
            onChange={(e) => onChangeValues(e, false)}
        />

        <div className="slider">
            <div className="track" />
            <div ref={rangeRef} className="range" />
            <div className="handle" style={{ left: `calc(${values.min}% - ${values.min / 100}*var(--thumbWidth))` }}>
                <DragIndicatorIcon />
            </div>
            <div className="handle" style={{ left: `calc(${values.max}% - ${values.max / 100}*var(--thumbWidth))` }}>
                <FaGripLinesVertical style={{ width: '15px', height: '15px' }} />
            </div>
        </div>
    </div>
}

export default CalibrationPointsSlider;