import styled, { css } from 'styled-components';
import theme from '../../../UI/theme';
import InputField from '../../UiComponents/InputField';
import { cellStates } from '../table/tableTypes';
import { requiredStates } from '../utils/installationTypes';

export const editableColor = theme.colors.darkSpace20;
export const readOnlyColor = theme.colors.darkSpace40;

export const StyledInputField = styled(InputField)`
    font-size: 1em;
    width: 100%;
    height: 100%;
    color: ${editableColor};
    padding: 0 4px;
    background-color: transparent;

    &:hover {
        cursor: default;
    }
`

export const DisplayField = styled.span`
    color: ${readOnlyColor};
    font-size: 1em;
    padding-left: 4px;

    ${props => !props.readOnly && css`
        &:hover {
            cursor: pointer;
        }
    `}
`

export const Disabled = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    color: ${readOnlyColor};
`

export const TableCell = styled.td`
    height: 30px;
    &:hover {
        cursor: pointer;
    }

    ${props => props.marked && css`
        outline: solid 1px ${theme.colors.greenEnergy};
    `}

    ${props => props.cellState === cellStates.ACTIVE  && css`
        outline: solid 2px ${theme.colors.greenEnergy};
    `}

    ${props => props.updated && css`
        background-color: ${theme.colors.darkSpace70};
    `}

    ${props => props.requiredState === requiredStates.RECOMMENDED && css`
        background-color: #69861b;
    `}
`

