import { useState, useEffect } from "react";
import { usePageVisibility } from "react-page-visibility";
import { getDefinedLogInterval } from "../../../scripts/log";
import styled from "styled-components";
import zoneTypes from "../../live/zone/zoneTypes";
import LiveDecibelChart from "./LiveDecibelChart";
import theme from "../../../UI/theme";

const Container = styled.div`
    height: 50px;
    width: 100%;
    overflow: hidden;
    grid-column: 1 / span 2;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        height: 40px;
    }
    
    canvas {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }
`

const MetricsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    grid-gap: 10px;
    grid-column: 1 / span 2;
    width: 100%;
    align-items: end;
`

const MetricContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.darkSpace80};
    padding: 2px 5px;
    box-sizing: border-box;
    border-radius: 2px;
    text-align: center;
    font-size: ${theme.fontSize.tiny};
`

const LiveDecibelChartContainer = props => {
    const [graphData, setGraphData] = useState([]);
    const isVisible = usePageVisibility();
    const [averageDecibel, setAverageDecibel] = useState();
    const [maxDecibel, setMaxDecibel] = useState();
    const [minDecibel, setMinDecibel] = useState();

    useEffect(() => {
        if (isVisible === true) {
            getData();
        }
    }, [isVisible, props.dataInterval]);

    useEffect(() => {
        setGraphData(prev => {
            const prevCopy = JSON.parse(JSON.stringify(prev));
            prevCopy.pop();
            prevCopy.unshift({ time: props.zoneLive?.time, averageDecibel: props.zoneLive?.averageDecibel, noise: props.zoneLive?.noise, sysvol: props.zoneLive?.sysvol });
            computeMetrics(prevCopy);
            return prevCopy;
        });
    }, [props.zoneLive?.time]);

    const getData = async () => {
        var newData = await getDefinedLogInterval(props.zone?.customerId, props.zone?.zoneId, zoneTypes.intervals.LAST_15_MINUTES, null);
        computeMetrics(newData);
        setGraphData(newData);
    }

    const computeMetrics = (data) => {
        const filteredDataValues = data?.filter(dataPoint => dataPoint.averageDecibel > 0)?.map(dataPoint => dataPoint?.averageDecibel);
        setMaxDecibel(Math.max(...filteredDataValues));
        setMinDecibel(Math.min(...filteredDataValues));
        setAverageDecibel(filteredDataValues?.reduce((acc, num) => acc + num, 0) / filteredDataValues?.length);
    }

    return <>
        <Container>
            <LiveDecibelChart data={graphData} />
        </Container>
        <MetricsContainer>
            <label>Last 15 mins</label>
            <MetricContainer>
                <label>Avg</label>
                <label>{averageDecibel?.toFixed(1)} dB</label>
            </MetricContainer>
            <MetricContainer>
                <label>Max</label>
                <label>{maxDecibel?.toFixed(1)} dB</label>
            </MetricContainer>
            <MetricContainer>
                <label>Min</label>
                <label>{minDecibel?.toFixed(1)} dB</label>
            </MetricContainer>
        </MetricsContainer>
    </>
}

export default LiveDecibelChartContainer;