import { useState } from "react";
import { isLocalApp } from "../../../../../scripts/server/server";
import serverConnection from "../../../../../scripts/server/ServerConnection";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../../panel/ModalStyles";
import Button from "../../../../UiComponents/Button";
import Modal from "../../../../UiComponents/Modal";

const DisconnectWifiButton = props => {
    const [showModal, setShowModal] = useState(false);
    const hostname = window.location.hostname;

    const disconnect = async () => {
        try {
            await serverConnection.hubEventHandler.sendEvent(
                props.hub?.hubId ?? props.hubId,
                props.hub?.customerId,
                'DISCONNECT_FROM_WIFI_NETWORK',
                null,
                20000,
                true
            );

            if (props.onDisconnect) {
                props.onDisconnect();
            }
        } catch (err) {
            console.log('could not disconnect')
        } finally {
            setShowModal(false);
        }
    }

    return <>
        <Button secondary small onClick={() => setShowModal(true)}>Disconnect</Button>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                {(isLocalApp() && props.networkConfig?.eth0?.address === hostname) || props.connectedToEthernet === true ? <>
                    <ModalHeader>Danger disconnecting from Wi-Fi!</ModalHeader>
                    <ModalText>Are you sure you want to disconnect from Wi-Fi? This can make the hub unreachable.</ModalText>
                    <ButtonRowContainer>
                        <Button small tertiary onClick={disconnect}>Disconnect</Button>
                        <Button small secondary margin onClick={() => setShowModal(false)}>Cancel</Button>
                    </ButtonRowContainer>
                </> : <>
                    <ModalHeader>Disconnecting from Wi-Fi not allowed</ModalHeader>
                    <ModalText>It does not look like the hub is connected to cabled internet. You are not allowed to disconnect from Wi-Fi.</ModalText>
                </>}
            </ModalContainer>
        </Modal>
    </>
}

export default DisconnectWifiButton;