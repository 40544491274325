import styled, { css } from "styled-components";
import theme from "../../UI/theme";
import PanelButton from "./PanelButton";

export const MuteButton = styled(PanelButton)`
    @keyframes pulse {
        0% { box-shadow: 0 0 0 0 ${theme.colors.darkSpace80}; }
        70% { box-shadow: 0 0 0 10px ${theme.colors.darkSpace80}00; }
        100% { box-shadow: 0 0 0 0 ${theme.colors.darkSpace80}00; }
    }

    ${props => props.deactivated && !props.disabled && css`
        box-shadow: 0 0 0 0 ${theme.colors.darkSpace80};
        animation: pulse 2s infinite;
    `}
`