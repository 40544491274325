import VolumeSlider from "../../../../panel/VolumeSlider"
import CalibrationPointsSlider from "./CalibrationPointsSlider"
import { CalibrationSliderContainer, CalibrationPointMark } from "../calibrationStyles"
import AdvancedCalibrationPointsSlider from "./AdvancedCalibrationPointsSlider"
import './calibrationSliderStyles.css';
import { postEvent } from "../../../../../scripts/event";

const CalibrationSlider = props => {

    const onChangeCalibrationPoints = (min, mid, max) => {
        if (props.setNewCalibrationPoints) {
            props.setNewCalibrationPoints(prev => {
                let newPoints = JSON.parse(JSON.stringify(prev));

                newPoints[0].sysvol = +min;
                if (mid === undefined) {
                    newPoints[1].sysvol = (+max + +min) / 2;
                } else {
                    newPoints[1].sysvol = +mid;
                }
                newPoints[2].sysvol = +max;

                return newPoints;
            });
        }
    }

    const setAbsoluteVolumeDirect = async (newValue, preventEventLog = false) => {
        await postEvent(props.zone.hubId, props.zone.customerId, 'SET_ABSOLUTE_VOLUME_DIRECT', { zoneId: props.zone.zoneId, value: newValue, preventEventLog: preventEventLog });
        props.setSystemVolume(newValue);
    }

    return <CalibrationSliderContainer>
        <VolumeSlider
            disabled={props.zone?.isRegulating && props.isRegulatingAudioSource}
            volume={props.systemVolume ?? 0}
            zone={props.zone}
            lastVolumeCommit={props.lastVolumeCommit}
            setLastVolumeCommit={props.setLastVolumeCommit}
            onChange={(newValue) => props.setSystemVolume(newValue)}
            onChangeStarted={(value) => props.setIsChangingVolume(true)}
            onChangeCommitted={(value) => { props.setIsChangingVolume(false); props.setSystemVolume(value) }}
        />

        {props.advanced === true ? <AdvancedCalibrationPointsSlider
            min={0}
            max={100}
            minVal={props?.newCalibrationPoints[0]?.sysvol}
            midVal={props?.newCalibrationPoints[1]?.sysvol}
            maxVal={props?.newCalibrationPoints[2]?.sysvol}
            onChange={(points) => onChangeCalibrationPoints(points.min, points.mid, points.max)}
            hasChanged={props.hasChanged}
            setHasChanged={props.setHasChanged}
        />
            :
            <CalibrationPointsSlider
                min={0}
                max={100}
                minVal={props?.newCalibrationPoints[0]?.sysvol}
                maxVal={props?.newCalibrationPoints[2]?.sysvol}
                onChange={(points) => onChangeCalibrationPoints(points.min, points.mid, points.max)}
                hasChanged={props.hasChanged}
                setHasChanged={props.setHasChanged}
            />}

        {props.newCalibrationPoints?.map((point, index) => {
            return <label
                key={index}
                className="calibrationPointLabel"
                onClick={() => setAbsoluteVolumeDirect(point?.sysvol, false)}
                disabled={props.zone?.isRegulating && props.isRegulatingAudioSource}
                style={{ left: `calc((100% - var(--width))*(${point?.sysvol}/100))` }}
            >
                {point?.sysvol?.toFixed(2)}
            </label>
        })}

        {props.savedCalibrationPoints?.map((point, index) => {
            return <CalibrationPointMark key={index} left={point?.sysvol} />
        })}
    </CalibrationSliderContainer>
}

export default CalibrationSlider;