import { entityTypes, permissionTypes } from "../../../permission/permissions";

const arraysHaveSameElements = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
    return sortedArr1.every((element, index) => element === sortedArr2[index]);
}

const bundleKeys = {
    readOnly: 'readOnly',
    basic: 'basic',
    admin: 'admin',
    techAdmin: 'techAdmin',
    custom: 'custom'
};

const bundles = {
    [bundleKeys.readOnly]: [
        permissionTypes.panelViewRead,
        permissionTypes.insightsRead
    ],
    [bundleKeys.basic]: [
        permissionTypes.panelViewWrite,
        permissionTypes.insightsRead
    ],
    [bundleKeys.admin]: [
        permissionTypes.panelViewWrite,
        permissionTypes.insightsRead,
        permissionTypes.settingsWrite,
        permissionTypes.userAdministrationWrite
    ],
    [bundleKeys.techAdmin]: [
        permissionTypes.panelViewWrite,
        permissionTypes.insightsRead,
        permissionTypes.settingsWrite,
        permissionTypes.userAdministrationWrite,
        permissionTypes.calibrationWrite,
        permissionTypes.installationViewWrite
    ]
}

const permissionTableTypes = {
    venue: 'venue',
    user: 'user'
}

const checkPermissionBundle = (permissionTypes) => {
    for (let bundleKey in bundles) {
        if (arraysHaveSameElements(bundles[bundleKey], permissionTypes)) {
            return bundleKey;
        }
    }
    return bundleKeys.custom;
}

const getPermissionsFromBundle = (bundleKey) => {
    return bundles[bundleKey];
}

const getUserDisplayName = (user) => {
    if (user?.shared === 1) {
        return user.firstName ? user.firstName : user?.username;
    } else {
        return user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : (user.email || user.username);
    }
}

const groupPermissions = (venues, users, permissions) => {
    const permissionObjects = [];
    for (let user of users) {
        const userPermissions = permissions.filter(p => p.userId === user.userId);
        for (let venue of venues) {
            const venuePermissions = userPermissions.filter(p => p.entityId === venue.customerId.toString());
            if (venuePermissions.length) {
                permissionObjects.push({
                    ...user,
                    ...venue,
                    userDisplayName: getUserDisplayName(user),
                    venueId: venue.customerId,
                    permissions: venuePermissions,
                    bundle: checkPermissionBundle(venuePermissions.map(p => p.permissionType))
                })
            }
        }
    }
    return permissionObjects;
}

const findUncommonPermissions = (permissions1, permissions2) => {
    return permissions1.filter(p1 => !permissions2.find(p2 => p2.entityId === p1.entityId && p2.userId === p1.userId && p2.permissionType === p1.permissionType));
}

const containsVenuePermission = (permissions, userId, venueId) => {
    return permissions.some(p => p.entityType === entityTypes.venue && userId === p.userId && p.entityId === venueId.toString());
}

const getDefaultVenueId = (user, venues) => {
    let venueId;
    if (venues.length === 1) {
        venueId = venues[0].customerId;
    } else if (user.defaultVenueId && venues.some(v => v.customerId === user.defaultVenueId)) {
        venueId = user.defaultVenueId;
    }
    return venueId;
}

export {
    bundleKeys,
    permissionTableTypes,
    checkPermissionBundle,
    groupPermissions,
    getUserDisplayName,
    getPermissionsFromBundle,
    findUncommonPermissions,
    containsVenuePermission,
    getDefaultVenueId
}