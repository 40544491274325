import styled from "styled-components";
import SidePanelContainer from "../../../../UiComponents/containers/SidePanelContainer";
import VenueList from "./VenueList";
import VenuePermissionsTable from "./VenuePermissionsTable";
import { UserAdminSectionContainer } from "../styles";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
`

const VenuePermissions = ({ venues, users, viewState, setViewState, permissionTableRef, refreshUsers }) => {
    const selectedVenue = venues.find(v => v.customerId === viewState.venueId);
    return <Container>
        {venues.length > 1 ? <>
            <SidePanelContainer >
                <VenueList
                    venues={venues}
                    users={users}
                    selectedVenueId={viewState.venueId}
                    setSelectedVenueId={(venueId) => setViewState(current => ({...current, venueId}))}
                />
            </SidePanelContainer>
        </> : <></>}

        {selectedVenue && users ? <>
            <UserAdminSectionContainer>
                <VenuePermissionsTable
                    venue={selectedVenue}
                    users={users}
                    permissionTableRef={permissionTableRef}
                    setViewState={setViewState}
                    refreshUsers={refreshUsers}
                />
            </UserAdminSectionContainer>
        </> : <></>}
    </Container>
}

export default VenuePermissions;

