import theme from "../../../../UI/theme";
import { userInteractionTypes } from "../userInteractionTypes";
import { Chart } from "chart.js";
import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import 'chartjs-adapter-luxon';
import { getDateTime } from "../../../../scripts/log";

const chartData = {
    datasets:
        Object.keys(userInteractionTypes).map(type => {
            return {
                label: userInteractionTypes[type].display,
                data: [],
                backgroundColor: userInteractionTypes[type].color,
                barPercentage: 1,
                categoryPercentage: 1
            }
        })
}

const chartConfig = {
    type: 'bar',
    data: chartData,
    options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                stacked: true,
                time: {
                    tooltipFormat: 'EEE dd.MM',
                    displayFormats: {
                        'day': 'EEE dd.MM'
                    },
                    unit: 'day'
                },
                grid: {
                    color: theme.colors.darkSpace80
                }
            },
            y: {
                stacked: true,
                grid: {
                    color: theme.colors.darkSpace80
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    }
}

const NoOfUserInteractionsGraph = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    if (props.from && props.to) {
        var from = DateTime.fromJSDate(props.from).startOf('day');
        var to = DateTime.fromJSDate(props.to).startOf('day');
        var chartLabels = [];

        while (from <= to) {
            chartLabels.push(new Date(from.toJSDate()));
            from = DateTime.fromMillis(from.toMillis()).plus({ days: 1 }).startOf('day');
        }

        chartConfig.data.labels = chartLabels;
        for (let index = 0; index < chartConfig.data.datasets.length; index++) {
            const dataset = chartConfig.data.datasets[index];
            dataset.data = [];
        }

        if (props.userInteractions) {
            chartLabels.map(date => {
                Object.keys(userInteractionTypes).map((type, index) => {
                    chartConfig.data.datasets[index].data.push(props.userInteractions
                        .filter(ui => ui.setting === userInteractionTypes[type].key
                            && DateTime.fromJSDate(getDateTime(ui.time)).startOf('day')
                                .equals(DateTime.fromJSDate(date).startOf('day')))
                        .length
                    )
                });
            })
        }
    }

    if (chartInstance) {
        chartInstance.update();
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfig);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return <canvas ref={chartContainer} style={{ height: '100%', width: '100%', maxWidth: '100%' }} ></canvas>

}

export default NoOfUserInteractionsGraph;