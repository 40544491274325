import { Chart } from "chart.js";
import { useEffect, useRef, useState } from "react";
import theme from "../../../UI/theme";
import { replaceZeroValues } from "../../../scripts/common";
import { DateTime } from "luxon";

const HIGHLIGHT_BAR_COLOR = theme.colors.crystalBlue;

const chartData = {
    labels: [],
    datasets: [
        {
            label: 'Decibel average (hourly)',
            type: 'bar',
            borderColor: `${theme.colors.crystalBlue}30`,
            borderRadius: Number.MAX_VALUE,
            borderSkipped: false,
            fill: false,
            data: [],
            backgroundColor: `${theme.colors.crystalBlue}30`,
        },
        {
            label: 'Decibel trend',
            type: 'line',
            borderColor: `${theme.colors.crystalBlue}70`,
            borderDash: [4, 2],
            borderWidth: 2,
            pointRadius: 0,
            tension: 0.5,
            fill: false,
            data: [],
        }]
}

const options = {
    maintainAspectRatio: false,
    layout: {
        padding: 0
    },
    scales: {
        x: {
            title: {
                text: "Hour",
                display: true,
                color: theme.colors.textGray
            },
            ticks: {
                color: theme.colors.textGray
            },
            grid: {
                display: false,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70
            }
        },
        y: {
            type: 'linear',
            title: {
                text: "Measured dB",
                display: true,
                color: theme.colors.textGray,
            },
            ticks: {
                color: theme.colors.textGray
            },
            grid: {
                drawBorder: true,
                display: false,
                borderColor: theme.colors.darkSpace70
            }
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            displayColors: false,
            callbacks: {
                label: function (context) {
                    return `${context?.parsed?.y?.toFixed(1)} dB` || '';
                },
                title: function (context) {
                    return `${DateTime.fromFormat(context[0]?.label, 'hh').toFormat('h a')}`;
                }
            }
        },
    },
    pointHitRadius: 7,
}

const chartConfig = {
    data: chartData,
    options,
}

const ActivityChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    if (props.data && props.data.trend) {
        chartConfig.data.datasets[0].data = props.data.data?.map(value => { return [value ? 2 : 0, value] });
        chartConfig.data.datasets[1].data = replaceZeroValues(props.data.trend);
        chartConfig.data.labels = props.data.labels;

        if (props.highlightIndex != undefined) {
            chartConfig.data.datasets[0].backgroundColor = getHighlightBackgroundColors(props.data?.data?.length, props.highlightIndex);
        }

    } else {
        chartConfig.data.datasets[0].data = [];
        chartConfig.data.datasets[1].data = [];
    }

    if (chartInstance) {
        chartInstance.update();
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfig);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <canvas ref={chartContainer} />
    );
}

export default ActivityChart;

function getHighlightBackgroundColors(totalLength, highlightIndex) {
    const highlightColor = HIGHLIGHT_BAR_COLOR;
    const unHiglightedColor = `${theme.colors.crystalBlue}30`;
    var backgroundColors = Array(totalLength).fill(unHiglightedColor);
    backgroundColors[highlightIndex] = highlightColor;
    return backgroundColors
}