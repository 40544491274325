import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { SortIconContainer } from "./SupportStyles";

const SortIcon = props => {

    return <SortIconContainer sorted={props.sortProperty === props.columnKey} onClick={() => props.setSort(props.columnKey)}>
        {props.sortProperty === props.columnKey && props.sortAsc ? <FaChevronUp /> : <FaChevronDown />}
    </SortIconContainer>
}

export default SortIcon;