import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../UiComponents/Button';
import Card from '../UiComponents/Card';
import { TextMuted } from '../UiComponents/text/StyledText';
import styled from 'styled-components';

const Container = styled.div`
    text-align: center;
`

const TextContainer = styled.div`
    margin-bottom: 20px;
`

const TitleContiner = styled.h2`
    margin: 12px 0;
`


const StartSetupLink = () => {

    return <Card>
        <Container>
            <TextContainer>
                <TextMuted>Welcome to</TextMuted>
                <TitleContiner>Waved</TitleContiner>
                <TextMuted>Set up a new hub to get started</TextMuted>
            </TextContainer>
        </Container>
    </Card>
}

export default StartSetupLink;