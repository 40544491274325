import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const zonePath = '/v2/zone';

const updateZone = async (customerId, updatedZone) => {
    const result = await axios.put(`${hostname}${zonePath}/zone?customerId=${customerId}`, updatedZone);
    return result.data;
}

const getZones = async (customerId) => {
    const result = await axios.get(`${hostname}${zonePath}/zones?customerId=${customerId}`);
    return result.data;
}

const updateZoneCalibration = async (updatedZone) => {
    const result = await axios.put(`${hostname}${zonePath}/calibration`, updatedZone);
    return result.data;
}

const updateZoneUserSettings = async (updatedZone) => {
    const result = await axios.put(`${hostname}${zonePath}/userSettings`, updatedZone);
    return result.data;
}

const updateZoneLiveSettings = async (updatedZone) => {
    const result = await axios.put(`${hostname}${zonePath}/liveSettings`, updatedZone);
    return result.data;
}

const updateZones = async (zones) => {
    const result = await axios.put(`${hostname}${zonePath}/list`, zones);
    return result.data;
}

const getZone = async (zoneId) => {
    const result = await axios.get(`${hostname}${zonePath}?zoneId=${zoneId}`);
    return result.data;
}

const createZone = async (zone) => {
    const res = await axios.post(`${hostname}${zonePath}`, zone);
    return res.data;
}

const deleteZone = async (zoneId) => {
    const res = await axios.delete(`${hostname}${zonePath}?zoneId=${zoneId}`);
    return res.data;
}

const updateZoneLiveSettingsList = async (zones) => {
    const result = await axios.put(`${hostname}${zonePath}/liveSettingsList`, zones);
    return result.data;
}

export {
    updateZone,
    updateZoneCalibration,
    updateZoneUserSettings,
    updateZoneLiveSettings,
    updateZones,
    getZones,
    getZone,
    createZone,
    deleteZone,
    updateZoneLiveSettingsList
};