import { useEffect, useState } from "react";
import styled from "styled-components";
import { processorTypes } from "../../../../settings/venue/device/AudioSystem/processorTypes";
import Checkbox from "../../../../UiComponents/Checkbox";

const FlexRow = styled.div`
    margin: 10px 0;
    display: flex;
    align-items: center;
    gap: 10px;
`

const PredefinedObjects = props => {
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        props.updateSubmitProps({ addPredefinedConfig: checked });
    }, [checked])

    const processorType = processorTypes[props.type];

    if (processorType?.predefinedConfig) {
        return <>
            <FlexRow>
                <Checkbox checked={checked} onChange={() => setChecked(c => !c)} />
                <span>Add sources, source selectors and zones automatically</span>
            </FlexRow>
        </>

    } else {
        return <></>
    }
}

export default PredefinedObjects;
