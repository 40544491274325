import React from 'react';
import { useSubscription } from '../../../../hooks/useSubscription';
import { subscriptionsTypes } from '../../../../scripts/subscription/subscriptionTypes';
import ViewHeader from '../../../UiComponents/ViewHeader';
import LinkItem from '../../LinkItem';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';
import { SettingsSection } from '../../settingsStyles';

const ZoneSettingsView = props => {
    const match = useRouteMatch();
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);

    return <>
        <ViewHeader headerText={'Zone Settings'} backLink={'/settings'} showVenueName={true} />

        <SettingsSection>
            {zones?.map(zone => {
                return <LinkItem key={zone?.zoneId} to={`${match.url}/${zone.zoneId}`} display={zone?.zoneName} />
            })}
        </SettingsSection>
    </>
}

export default ZoneSettingsView;