
import { connectedStatus, getProcessorStatus } from "../../../installation/utils/connectionStatus";
import heatmapTypes from "../../container/heatmapTypes";
import DeviceLayerController from "../device/DeviceLayerController";
import { getFloorElementsByType } from "../floor/floorUtils";
import Processor from "./Processor";

class ProcessorLayerController extends DeviceLayerController {
    constructor() {
        super();
        this.sensorData = null;
        this.layer.name = 'Processor Layer';
    }

    onFloorChange(floorData) {
        const processors = getFloorElementsByType(floorData, heatmapTypes.elementTypes.PROCESSOR);
        this.drawDevices(processors);
    }

    onModeChange(modeName) {
        super.onModeChange(modeName);
        if (modeName === heatmapTypes.modes.DATA) {
            this.layer.visible = false;
        } else {
            this.layer.visible = true;
        }
    }

    constructObject(device) {
        return new Processor(device, this);
    }

    handleDeviceUpdates(updatedProcessors, hubs) {
        updatedProcessors.forEach(updatedProcessor => {
            const processor = this.list.find(d => d.id === updatedProcessor.processorId);
            if (processor) {
                const hub = hubs.find(hub => hub.hubId === updatedProcessor.hubId);
                const status = getProcessorStatus(updatedProcessor, hub, true);
                const updatedConnectedState = status === connectedStatus.CONNECTED;
                if (processor.connectedState !== updatedConnectedState) {
                    processor.setConnectedState(updatedConnectedState);
                }
            }
        });
    }
}

export default ProcessorLayerController;