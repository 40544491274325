import styled from 'styled-components';
import theme from '../../UI/theme';
import { Link } from 'react-router-dom';

const Container = styled.div`
    height: 85px;
    box-sizing: border-box;
    width: 100%;
    z-index: 100;
    background-color: ${props => props.backgroundColor || theme.colors.darkSpace90};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 30px;
    
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        position: fixed;
        top: 0;
        border-bottom: 2px solid ${theme.colors.darkSpace};
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        position: fixed;
        bottom: 0;
        border-top: 2px solid ${theme.colors.darkSpace};
        justify-content: space-around;
        padding: 0 20px;
    } 
    
`
const StatusBanner = styled.div`
    background-color: ${theme.colors.darkSpace80};
    display: flex;
    justify-content: center;
    font-size: ${theme.fontSize.tiny};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 120;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color: white;
    font-size: 20px;
    margin: 0 20px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        margin: 0 auto;
    }
`;

const ItemTitle = styled.h3`
    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        display: none;
    }

    font-size: 18px;
    margin: 0;
    margin-left: 10px;
`

const InnerContainer = styled.div`
    display: flex;
    align-items: center;
`

const TopPlaceholder = styled.div`
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        height: 85px;
    }
`

export {
    Container,
    StyledLink,
    ItemTitle,
    InnerContainer,
    TopPlaceholder,
    StatusBanner
};