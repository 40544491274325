import React from 'react';
import StringField from './StringField';

const NumberField = props => {
    const setValue = (value) => {
        if (value !== undefined && value !== '' && value !== null) {
            props.setValue(Number(value));
        } else {
            props.setValue(null);
        }
    }
    return <StringField type={'number'} maxWidth={'30px'} {...props} setValue={setValue} min={0} />;
}

export default NumberField;