const entityTypes = require("./entityTypes");

const customerHealthMetricTypes = {
    appUserInteractions: {
        key: 'appUserInteractions',
        entityType: entityTypes.customer,
        display: 'App UIs',
        computeAverage: false,
        isRatio: false,
        positiveDirectionality: null
    },
    externalUserInteractions: {
        key: 'externalUserInteractions',
        entityType: entityTypes.customer,
        display: 'External UIs',
        computeAverage: false,
        isRatio: false,
        positiveDirectionality: false
    },
    regulationEnabledRatio: {
        key: 'regulationEnabledRatio',
        entityType: entityTypes.zone,
        display: 'Regulation enabled ratio',
        computeAverage: true,
        isRatio: true,
        positiveDirectionality: true
    },
    regulatingRatio: {
        key: 'regulatingRatio',
        entityType: entityTypes.zone,
        display: 'Regulation ratio',
        computeAverage: true,
        isRatio: true,
        positiveDirectionality: true
    },
    regulationFrequency: {
        key: 'regulationFrequency',
        entityType: entityTypes.zone,
        display: 'Regulation frequency',
        computeAverage: true,
        isRatio: true,
        positiveDirectionality: true
    },
    externalVolumeInteractions: {
        key: 'externalVolumeInteractions',
        entityType: entityTypes.zone,
        display: 'External volume UIs',
        computeAverage: true,
        isRatio: false,
        positiveDirectionality: false
    },
    doubleVibeRatio: {
        key: 'doubleVibeRatio',
        entityType: entityTypes.zone,
        display: 'Double vibe ratio',
        computeAverage: true,
        isRatio: true,
        positiveDirectionality: false
    },
    vibeDirectionScore: {
        key: 'vibeDirectionScore',
        entityType: entityTypes.zone,
        display: 'Vibe direction score',
        computeAverage: false,
        isRatio: false,
        positiveDirectionality: null
    },
    // zoneSpan: {
    //     key: 'zoneSpan',
    //     entityType: entityTypes.zone,
    //     display: 'Zone span'
    // },
    sensorConnectionRatio: {
        key: 'sensorConnectionRatio',
        entityType: entityTypes.sensor,
        display: 'Sensor connection ratio',
        computeAverage: true,
        isRatio: true,
        positiveDirectionality: true
    },
    // sensorSpan: {
    //     key: 'sensorSpan',
    //     entityType: entityTypes.sensor,
    //     display: 'Sensor span'
    // },
}

module.exports = customerHealthMetricTypes;