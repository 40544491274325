import React from 'react';
import AddressField from '../../../fields/AddressField';
import { getProtocol } from '../../protocol/protocolUtils';
import { sourceSelectorTypes } from '../sourceSelectorTypes';

const SourceSelectorAddressField = ({ sourceSelector, updateSourceSelector, addressField, sourceSelectors, processors, cellState }) => {
    addressField = addressField || 'address';
    const processor = processors.find(processor => processor.processorId === sourceSelector.processorId); 
    const Protocol = getProtocol(processor?.type);

    const validate = (address) => {
        const processorObjects = sourceSelectors.filter(s => s.processorId === sourceSelector.processorId); 
        const addressesOnSameProcessor = processorObjects.filter(s => s.sourceSelectorId !== sourceSelector.sourceSelectorId).map(s => s.addressField?.toLowerCase());
        if (address && addressesOnSameProcessor.includes(address?.toString().toLowerCase())) {
            return ({type: 'WARNING', message: 'Multiple source selectors with same address on same processor'});
        } else {
            return Protocol.validateSourceSelectorAddress(address);
        }
    }

    const disabled = disableSourceSelectorAddressField(sourceSelector);

    return <AddressField
        processorId={sourceSelector.processorId}
        hubId={processor?.hubId}
        type={'SOURCE'}
        address={!disabled ? sourceSelector[addressField] : 'N/A'}
        customerId={processor?.customerId}
        setAddress={(address) => updateSourceSelector({ [addressField]: address })}
        disabled={disabled}
        error={validate(sourceSelector[addressField])}
        cellState={cellState}
    />
}

export default SourceSelectorAddressField;

export const disableSourceSelectorAddressField = (sourceSelector) => {
    return sourceSelector.type === sourceSelectorTypes.MATRIX;
}

export const hideSourceSelectorAddressField = (sourceSelectors) => {
    return !sourceSelectors?.some(sourceSelector => !disableSourceSelectorAddressField(sourceSelector));
}


