import { BarChartBar, BarChartSkeletonContainer } from "./skeletonStyles"

const BarChartSkeleton = props => {
    let numberOfBars = props.numberOfBars ?? 5;
    let array = new Array(numberOfBars).fill(0);

    return <BarChartSkeletonContainer numberOfBars={numberOfBars}>
        {array?.map((value, index) => {
            return <BarChartBar key={index} index={index} />
        })}
    </BarChartSkeletonContainer>
}

export default BarChartSkeleton;