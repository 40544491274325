import { useState } from "react";
import { useContext } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import UserContext from "../../../../../hooks/UserContext";
import useServerStatus from "../../../../../hooks/useServerStatus";
import { postUserInteraction } from "../../../../../scripts/userInteraction";
import { updateZoneLiveSettings } from "../../../../../scripts/zone";
import theme from "../../../../../UI/theme";
import { userInteractionTypes } from "../../../../admin/customerDetails/userInteractionTypes";
import PanelButton from "../../../../panel/PanelButton";
import SourceSelectorModal from "../../../../panel/SourceSelectorModal";
import WaInput from "../../../../UiComponents/Icons/WaInput";
import { SettingsParagraph } from "../../../settingsStyles";
import { RuleContainer } from "./liveCalibrationStyles";
import { hasVenuePermission, permissionTypes } from "../../../../permission/permissions";
import ZoneRegulationButton from "../../../../panel/zone/ZoneRegulationButton";

const LiveCalibrationRules = props => {
    const serverStatus = useServerStatus();
    const userContext = useContext(UserContext);
    const [showSourceSelectorModal, setShowSourceSelectorModal] = useState(false);
    const isRegulatingAudioSource = !props.sourceSelector
        || props.sources?.find(source => source.sourceId === props.sourceSelector?.activeSourceId)?.regulate == true
        || props.sources?.length === 0 ? true : false;

    const updateZoneWithEvent = async (zoneProps) => {
        try {
            await updateZoneLiveSettings({ ...zoneProps });
        } catch (err) {
        }
    }

    const toggleRegulation = async (newState) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }

        await updateZoneWithEvent({ zoneId: props.zone?.zoneId, hubId: props.zone?.hubId, isRegulating: +newState });
        postUserInteraction(props.zone?.customerId, {
            zoneId: props.zone?.zoneId,
            setting: userInteractionTypes.isRegulating.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    return <>
        <RuleContainer>
            {!isRegulatingAudioSource ? <FaTimesCircle style={{ color: theme.colors.raspberryRed }} /> : <FaCheckCircle style={{ color: theme.colors.greenEnergy }} />}
            <SettingsParagraph>Play an automated source</SettingsParagraph>
            <PanelButton onClick={() => setShowSourceSelectorModal(true)}>
                <WaInput />
                <label>Source</label>
            </PanelButton>
        </RuleContainer>

        <SourceSelectorModal
            show={showSourceSelectorModal}
            close={() => { setShowSourceSelectorModal(false); }}
            sourceSelector={props.sourceSelector}
            processor={props.processor}
            sources={props.sources}
            processorConnected={checkProcessorConnected(props.processor, props.hub, serverStatus)}
            setActiveSource={(sourceId) => setShowSourceSelectorModal(false)}
        />

        <RuleContainer>
            {props.zone?.isRegulating ? <FaTimesCircle style={{ color: theme.colors.raspberryRed }} /> : <FaCheckCircle style={{ color: theme.colors.greenEnergy }} />}
            <SettingsParagraph>Turn automation off</SettingsParagraph>
            <ZoneRegulationButton
                onClick={() => toggleRegulation(!props.zone?.isRegulating)}
                deactivated={!props.zone?.isRegulating || !isRegulatingAudioSource}
                disabled={!isRegulatingAudioSource}
                isRegulating={props.zone?.isRegulating}
                isRegulatingAudioSource={isRegulatingAudioSource}
            />
        </RuleContainer>

    </>
}

const OLD_DATA_LIMIT_SECONDS = 10;

const checkProcessorConnected = (processor, hub, serverStatus) => {
    return (hub?.secondsSinceLastUpdate <= OLD_DATA_LIMIT_SECONDS &&
        processor?.isConnected &&
        serverStatus);
}

export default LiveCalibrationRules;