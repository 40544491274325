import { useEffect, useState } from "react";
import ViewHeader from "../components/UiComponents/ViewHeader";
import { getCustomerHealthMetrics } from "../scripts/customerHealthMetric";
import { getAllCustomers } from "../scripts/datasource";
import CustomerHealthSummary from "../components/admin/customerHealth/CustomerHealthSummary";
import { SensorIcon } from "../components/UiComponents/Icons";
import WaUsers from "../components/UiComponents/Icons/WaUsers";
import { ZoneIcon } from "../components/installation/styling/Icons";
import TabSelector from "../components/UiComponents/TabSelector";
import SensorHealthMetrics from "../components/admin/customerHealth/SensorHealthMetrics";
import entityTypes from "../components/admin/customerHealth/entityTypes";
import ZoneHealthMetrics from "../components/admin/customerHealth/ZoneHealthMetrics";
import CustomerHealthMetrics from "../components/admin/customerHealth/CustomerHealthMetrics";
import { DateTime } from "luxon";
import customerHealthMetricTypes from "../components/admin/customerHealth/customerHealthMetricTypes";
import ClearMenuBarContainer from "../components/UiComponents/containers/ClearMenuBarContainer";

const tabs = [
    { key: 'SUMMARY', display: 'Summaries' },
    { key: 'CUSTOMER', display: 'User interactions', icon: WaUsers },
    { key: 'ZONE', display: 'Zones', icon: ZoneIcon },
    { key: 'SENSOR', display: 'Sensors', icon: SensorIcon }
]

const CustomerHealthView = props => {
    const [customerHealthMetrics, setCustomerHealthMetrics] = useState();
    const [previousWeekMetrics, setPreviousWeekMetrics] = useState();
    const [customerAverages, setCustomerAverages] = useState();
    const [previousWeekAverages, setPreviousWeekAverages] = useState();
    const [customers, setCustomers] = useState();
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        const customerResults = await getAllCustomers();
        setCustomers(customerResults);

        const customerHealthResults = await getCustomerHealthMetrics();
        setCustomerHealthMetrics(customerHealthResults);

        const previousWeek = DateTime.utc().minus({ weeks: 2 }).toISO();
        const previousWeekMetrics = await getCustomerHealthMetrics(previousWeek);
        setPreviousWeekMetrics(previousWeekMetrics);

        const customerAverageResults = computeCustomerAverages(customerResults, customerHealthResults);
        setCustomerAverages(customerAverageResults);

        const previousWeekAverages = computeCustomerAverages(customerResults, previousWeekMetrics?.filter(prevMetric => customerHealthResults?.some(metric => metric?.entityId === prevMetric?.entityId)));
        setPreviousWeekAverages(previousWeekAverages);
    }

    const computeCustomerAverages = (customerArray, metricArray) => {
        const customerAverages = [];
        customerArray?.forEach(customer => {
            const customerMetrics = metricArray?.filter(metric => metric?.customerId === customer?.customerId);
            let customerAverage = { customerId: customer?.customerId };

            Object.keys(customerHealthMetricTypes)?.forEach(key => {
                const metricType = customerHealthMetricTypes[key];
                if (metricType?.computeAverage === true) {
                    const metricsOfType = customerMetrics?.filter(metric => metric?.metricType === metricType?.key && metric?.metricValue !== null && !isNaN(metric?.metricValue));
                    const averageMetricValue = metricsOfType?.reduce((a, b) => a + b?.metricValue, 0) / metricsOfType?.length;
                    if (!isNaN(averageMetricValue) && metricsOfType?.length > 0) {
                        customerAverage[metricType.key] = Math.round(averageMetricValue * 1000) / 1000;
                    } else {
                        customerAverage[metricType.key] = null;
                    }
                }
            })
            customerAverages.push(customerAverage);
        });
        return customerAverages;
    }

    return <>
        <ViewHeader headerText={'Customer Health'} backLink={'/supportcenter'} />

        <TabSelector tabs={tabs} selectedTab={selectedTab} onClick={(newTab) => setSelectedTab(newTab)} />

        <ClearMenuBarContainer>
            {selectedTab?.key === 'SUMMARY' ? <>
                <CustomerHealthSummary
                    customers={customers}
                    metrics={customerHealthMetrics}
                    previousMetrics={previousWeekMetrics}
                    customerAverages={customerAverages}
                    previousAverages={previousWeekAverages}
                />
            </>
                : <></>}
            {selectedTab?.key === 'CUSTOMER' ? <>
                <CustomerHealthMetrics
                    metrics={customerHealthMetrics?.filter(metric => metric.entityType === entityTypes.customer.key)}
                    previousMetrics={previousWeekMetrics?.filter(metric => metric.entityType === entityTypes.customer.key)}
                />
            </>
                : <></>}
            {selectedTab?.key === 'ZONE' ? <>
                <ZoneHealthMetrics
                    metrics={customerHealthMetrics?.filter(metric => metric.entityType === entityTypes.zone.key)}
                    previousMetrics={previousWeekMetrics?.filter(metric => metric.entityType === entityTypes.zone.key)}
                />
            </>
                : <></>}
            {selectedTab?.key === 'SENSOR' ? <>
                <SensorHealthMetrics
                    metrics={customerHealthMetrics?.filter(metric => metric?.entityType === entityTypes.sensor.key)}
                    previousMetrics={previousWeekMetrics?.filter(metric => metric.entityType === entityTypes.sensor.key)}
                />
            </>
                : <></>}
        </ClearMenuBarContainer>

    </>
}

export default CustomerHealthView;