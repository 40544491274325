import React from 'react';
import styled from 'styled-components';
import theme from '../../../UI/theme';
import Dropdown from '../../UiComponents/Dropdown';
import Selector from '../../UiComponents/Selector';

const Container = styled.div`
    display: flex;
    position: relative;
    align-items: center;
`

const FloorSelector = props => {

    var showAsDropdown = (props.floors.length > 3 && window.innerWidth < theme.screenSizes.medium);
    var items;
    var selectedItem;
    if (showAsDropdown) {
        items = props.floors.map(floor => ({ ...floor, label: floor.name }));
        selectedItem = props.floors.find(floor => floor.floorId === props.activeFloor);
        if (selectedItem) {
            selectedItem.label = selectedItem.name;
        }
    } else {
        items = props.floors.map(floor => ({ ...floor, display: floor.name, key: floor.floorId }));
        selectedItem = items.find(floor => floor.floorId === props.activeFloor);
        if (selectedItem) {
            selectedItem.display = selectedItem.name;
        }
    }

    if (props.floors && props.floors.length > 0) {
        return <Container>
            {showAsDropdown ? <>
                <Dropdown items={items} selectedItem={selectedItem} onClick={(item) => props.setActiveFloor(item.floorId)} />
            </> : <>
                <div>
                    <Selector
                        items={items}
                        selectedItem={selectedItem}
                        onClick={(item) => props.setActiveFloor(item.key)}
                    />
                </div>
            </>}
        </Container>
    } else {
        return <></>;
    }

}

export default FloorSelector;