import styled, { css } from "styled-components";
import theme from "../../UI/theme";

const PanelButton = styled.button`
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    background-color: ${theme.colors.darkSpace90};
    color: ${theme.colors.greenReadable};
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    aspect-ratio: 1;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 0.65rem;
    font-weight: 100;
    line-height: 0.65rem;
    touch-action: manipulation;
    user-select: none;

    > label {
        cursor: inherit;
    }

    > svg {
        height: 1rem;
        width: 1.1rem;
        size: 1.1rem;
        color: ${theme.colors.greenEnergy};
        stroke: ${theme.colors.greenEnergy};
    }

    @media (hover:hover) {
        &:hover {
            ${props => !props.disabled && !props.unavailable && css`
                cursor: pointer;
                background-color: ${theme.colors.darkSpace80};
            `}
        }
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        width: 40px;
        height: 40px;
    }

    ${props => props.deactivated && css`
        color: ${theme.colors.textGray};
        
        svg {
            color: ${theme.colors.textGray};
            stroke: ${theme.colors.textGray};
        }
    `}

    ${props => props.disabled && css`
        opacity: 40%;
        color: ${theme.colors.textGray};
        
        @media (hover:hover) {
            &:hover {
                cursor: default;
            }
        }
    `}
`

export default PanelButton;