
class Yamaha {

    static validateObjectAddress(value) {
    }

    static validateMuteAddress(value) {
    }

    static suggestObjectAddress() {
    }

    static suggestMuteAddress() {
    }

    static testFormat(address) {
    }

    static validateSourceSelectorAddress(value) {
    }
}

Yamaha.props = {
    muteAddress: true
}

export default Yamaha;