import { FaChevronRight } from "react-icons/fa";
import styled, { css } from "styled-components";
import theme from "../../UI/theme";
import Button from "../UiComponents/Button";
import { getItemIcon } from "./styling/Icons";

const BreadcrumbsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: fill-available;
    overflow-x: auto;
    line-height: 1rem;
    box-sizing: border-box;
    color: ${theme.colors.textGray};
`

const CrumbContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;

    > svg {
        font-size: 1rem;
        max-width: 1rem;
        max-height: 1rem;
    }
`

const CrumbLabel = styled.label`
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    color: ${theme.colors.textGray};
    stroke: ${theme.colors.textGray};
    font-size: ${theme.fontSize.small};
    white-space: nowrap;
    height: 100%;

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    > svg {
        font-size: 1rem;
        max-width: 1rem;
        max-height: 1rem;
    }

    ${props => props.selected && css`
        color: ${theme.colors.greenReadable};
        stroke: ${theme.colors.greenReadable};
    `}
`

const ClearFilterButton = styled(Button)`
    white-space: nowrap;
    line-height: 1rem;
    padding: 5px;
    visibility: ${props => props.visibility ? props.visibility : ''};
`

const Breadcrumbs = props => {
    const clearFilter = () => {
        if (props.setSelectedItem) {
            props.setSelectedItem(null);
        }
        if (props.setSelectedItemHierarchy) {
            props.setSelectedItemHierarchy(null);
        }
    }

    const onClickBreadCrumb = (item, index) => {
        if (props.setSelectedItem) {
            props.setSelectedItem(item)
        }
        if (props.setSelectedItemHierarchy) {
            props.setSelectedItemHierarchy(prev => {
                return prev?.slice(0, index + 1);
            });
        }
    }

    return <BreadcrumbsContainer>

        {props.selectedItemHierarchy ? <>
            <ClearFilterButton
                small
                tertiary
                visibility={props.selectedItemHierarchy ? 'visible' : 'hidden'}
                onClick={() => clearFilter()}>
                Clear
            </ClearFilterButton>
        </> : <></>}

        {props.selectedItemHierarchy?.map((item, index) => {
            const ItemIcon = getItemIcon(item.type);
            const isSelectedElement = index === props.selectedItemHierarchy?.length - 1;

            return <CrumbContainer key={index}>
                <CrumbLabel
                    selected={isSelectedElement}
                    onClick={() => onClickBreadCrumb(item, index)}
                >
                    <ItemIcon /> {item.name}
                </CrumbLabel>
                {!isSelectedElement ? <FaChevronRight /> : <></>}
            </CrumbContainer>
        })}
    </BreadcrumbsContainer>
}

export default Breadcrumbs;