import { postEvent } from "../../scripts/event";

const HUB_ATTTEMPT_TIMEOUT = 21000;
const MAX_OFFLINE_TIME = 10;


async function performHubAction(hubId, customerId, command, payload=false) {
    try {
        var response = false; 
        if (payload) response = await postEvent(hubId, customerId, command, payload);
        else response = await postEvent(hubId, customerId, command);
        return response;
    } catch (err) {
        return false;
    }
}

export {
    performHubAction,
    HUB_ATTTEMPT_TIMEOUT,
    MAX_OFFLINE_TIME,
}