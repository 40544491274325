import { useState } from "react";
import styled, { css } from "styled-components";
import theme from "../../UI/theme";

const RadioContainer = styled.div`
    display: flex;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    margin: 5px 5px 5px 0;
    ${props => props.centered && css`
        justify-content: center;
    `}
`

const HiddenRadio = styled.input.attrs(() => ({ type: "radio" }))`
    height: 0;
    width: 0;
    opacity: 0;
    z-index: -1;
`

const Dot = styled.div`
    display: block;
    border-radius: 1em;
    background-color: ${theme.colors.greenEnergy};
    width: 0.6rem;
    height: 0.6rem;
    top: 50%;
    left: 50%;
    transform: translate(50%, 50%);
}
`

const StyledRadio = styled.div`
    display: inline-block;
    background: ${theme.colors.darkSpace80};
    border: 1px solid ${props => props.checked ? theme.colors.greenEnergy : theme.colors.darkSpace80};
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;

    &:hover {
        ${props => !props.disabled && css`
        border: 1px solid ${theme.colors.greenEnergy};
        `}
    }

    ${Dot} {
        visibility: ${props => props.checked ? 'visible' : 'hidden'};
        opacity: ${props => props.checked ? '100%' : '0%'};
      }
`

const Radio = props => {
    const onChangeHandler = () => {
        if (!props.disabled && props.onChange) {
            props.onChange();
        }
    }

    return <RadioContainer disabled={props.disabled} onClick={onChangeHandler} centered={props.centered}>
        <HiddenRadio name={props.name} checked={props.checked} {...props} />
        <StyledRadio checked={props.checked} disabled={props.disabled}>
            <Dot />
        </StyledRadio>
    </RadioContainer>
}

export default Radio;