import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../UI/theme';

const Container = styled.div`
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        left: 48%;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        display: none;
    }
    position: fixed;
    top: 20px;
    margin: 4px 8px;
    padding: 4px;
    font-size: 1.6rem;
    border: 1px solid white;
    cursor: pointer;
`

const EnvironmentTag = props => {
    
    if (props.markEnvironment) {
        return <Container onClick={() => props.setMarkEnvironment(false)}>
            {props.display}
        </Container>
    } else {
        return <></>
    }

}

export default EnvironmentTag;