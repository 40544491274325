import React from 'react';
import ChoiceField from '../../../fields/ChoiceField';
import { Disabled } from '../../../fields/Styles';
import { hierarchyTypeValues } from '../zoneTypes';


const MasterZoneIdChoiceField = ({zone, zones, setValue, cellControl}) => {
    const disabled = zone.hierarchyType !== hierarchyTypeValues.SLAVE;
    if (!disabled) {
        const masterZones = zones.filter(z => z.hierarchyType === hierarchyTypeValues.MASTER && z.processorId === zone.processorId && z.zoneId !== zone.zoneId);
        const selectedMasterZone = masterZones.find(z => z.zoneId === zone.masterId);
        return <ChoiceField
            selectedItem={selectedMasterZone && { value: selectedMasterZone.zoneId, label: selectedMasterZone.zoneName}}
            items={masterZones.map(zone => ({ value: zone.zoneId, label: zone.zoneName}))}
            setValue={(value) => setValue(value)}
            cellControl={cellControl}
        />
    } else {
        return <Disabled>N/A</Disabled>;
    }
    
}

export default MasterZoneIdChoiceField;

export const hideMasterZoneIdField = (zones) => {
    return zones?.filter(zone => zone.hierarchyType === hierarchyTypeValues.MASTER).length === 0;
}