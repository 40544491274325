
import { getHubConnectedStatus } from "../../../installation/utils/connectionStatus";
import heatmapTypes from "../../container/heatmapTypes";
import DeviceLayerController from "../device/DeviceLayerController";
import { getFloorElementsByType } from "../floor/floorUtils";
import Hub from "./Hub";

class HubLayerController extends DeviceLayerController {
    constructor() {
        super();
        this.sensorData = null;
        this.layer.name = 'Hub Layer';
    }

    onFloorChange(floorData) {
        const hubs = getFloorElementsByType(floorData, heatmapTypes.elementTypes.HUB);
        this.drawDevices(hubs);
    }

    onModeChange(modeName) {
        super.onModeChange(modeName);
        if (modeName === heatmapTypes.modes.DATA) {
            this.layer.visible = false;
        } else {
            this.layer.visible = true;
        }
    }

    constructObject(device) {
        return new Hub(device, this);
    }


    handleDeviceUpdates(updatedHubs) {
        updatedHubs.forEach(updatedHub => {
            const hub = this.list.find(d => d.id === updatedHub.hubId);
            if (hub) {
                const updatedConnectedState = getHubConnectedStatus(updatedHub)
                if (hub.connectedState !== updatedConnectedState) {
                    hub.setConnectedState(updatedConnectedState);
                }
            }
        });
    }
}

export default HubLayerController;