import styled, { css } from "styled-components";
import theme from "../../UI/theme";

export const SetupContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    padding: 20px;
    box-sizing: border-box;
    background-color: ${theme.colors.darkSpace90};
`

export const SectionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`

export const Header = styled.label`
    font-size: 2rem;
`

export const InputFieldContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${theme.fontSize.small};
    color: ${theme.colors.textGray};
`

export const RowContainer = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
`

export const InfoTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    color: ${theme.colors.textGray};
    font-size: ${theme.fontSize.small};
`

export const SetupLabel = styled.label`
    display: flex;
    align-items: center;
    cursor: inherit;
    
    &>svg {
        margin-right: 5px;
    }
`

export const ConnectionLabel = styled(SetupLabel)`
    ${props => props.connected === true && css`
        color: ${theme.colors.greenReadable};
    `}
    ${props => props.connected === false && css`
        color: ${theme.colors.raspberryRed};
    `}
`

export const ErrorLabel = styled(SetupLabel)`
    color: ${theme.colors.raspberryRed};
`

export const WarningLabel = styled(SetupLabel)`
    color: ${theme.colors.yellowFever};
`

export const GoodLabel = styled(SetupLabel)`
    color: ${theme.colors.greenReadable};
`