import { useContext, useRef, useState } from "react";
import styled from "styled-components";
import useServerStatus from "../../hooks/useServerStatus";
import { isMobile } from "../../scripts/common";
import Breadcrumbs from "./Breadcrumbs";
import { getHubTableProps } from "./modules/hub/hubTableProps";
import InstallationTabSelector from "./InstallationTabSelector";
import { getProcessorTableProps } from "./modules/processor/processorTableProps";
import { getSensorTableProps } from "./modules/sensor/sensorTableProps";
import { getSourceTableProps } from "./modules/source/sourceTableProps";
import { getSourceSelectorTableProps } from "./modules/sourceSelector/sourceSelectorTableProps";
import EditableTableContainer from "./table/EditableTableContainer";
import { getSpeakerTableProps } from "./modules/speaker/speakerTableProps";
import { displayModes } from "./utils/installationTypes";
import { getZoneTableProps } from "./modules/zone/zoneTableProps";
import { getSourceMatrixTableProps } from "./modules/sourceMatrix/sourceMatrixTableProps";
import theme from "../../UI/theme";
import InstallationDocumentationView from "./modules/installationDocumentation/InstallationDocumentationView";
import VenueName from "./VenueName";
import { getMeterTableProps } from "./modules/meter/meterTableProps";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: fill-available;
    padding: 10px;

    --marginBottom: 0px;

    @media only screen and (max-width: ${theme.screenSizes.large - 1}px) {
        --marginBottom: 40px;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        --marginBottom: 126px;
    }

    margin-bottom: var(--marginBottom);
`

const SettingsContainer = ({
    customerId,
    hubs,
    processors,
    sources,
    sourceSelectors,
    zones,
    sensors,
    sourceMatrixes,
    meters,
    speakers,
    selectedTab,
    setSelectedTab,
    selectedItem,
    setSelectedItem,
    selectedItemHierarchy,
    setSelectedItemHierarchy
}) => {
    const [displayMode, setDisplayMode] = useState(isMobile() ? displayModes.LIST : displayModes.TABLE);
    const editableTableRef = useRef();
    const serverStatus = useServerStatus();

    const getTableProps = () => {
        switch (selectedTab.key) {
            case 'HUB':
                return getHubTableProps({ hubs, processors, sensors, customerId, serverStatus, displayMode })

            case 'PROCESSOR':
                return getProcessorTableProps({ processors, hubs, customerId, serverStatus, displayMode })

            case 'SOURCE':
                return getSourceTableProps({ sources, processors, customerId })

            case 'SOURCE_MATRIX':
                return getSourceMatrixTableProps({ sourceMatrixes, processors, sources, customerId })

            case 'SOURCE_SELECTOR':
                return getSourceSelectorTableProps({ sourceSelectors, processors, sources, sourceMatrixes, customerId, setSelectedTab })

            case 'ZONE':
                return getZoneTableProps({ customerId, zones, processors, sourceSelectors, meters, hubs, setSelectedTab })

            case 'SPEAKER':
                return getSpeakerTableProps({ customerId, hubs, processors, zones, speakers, setSelectedTab });

            case 'METER':
                return getMeterTableProps({ customerId, meters, processors })

            case 'SENSOR':
                return getSensorTableProps({ sensors, zones, hubs, customerId, serverStatus, displayMode })
            default:
                return <></>
        }
    }

    return <Container>
        <VenueName />
        <Breadcrumbs
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            selectedItemHierarchy={selectedItemHierarchy}
            setSelectedItemHierarchy={setSelectedItemHierarchy} />
        <InstallationTabSelector
            selectedTab={selectedTab}
            onClick={(newTab) => setSelectedTab(newTab)}
            editableTableRef={editableTableRef}
            sourceMatrixes={sourceMatrixes}
            meters={meters}
            processors={processors}
        />

        {selectedTab.key === 'DOCUMENTATION' ? <>
            <InstallationDocumentationView
                customerId={customerId}
                hubs={hubs}
                processors={processors}
                sourceSelectors={sourceSelectors}
                zones={zones}
                sensors={sensors}
            />
        </> : <>
            <EditableTableContainer
                {...getTableProps()}
                displayMode={displayMode}
                setDisplayMode={setDisplayMode}
                editableTableRef={editableTableRef}
            />
        </>}
    </Container>
}

export default SettingsContainer;