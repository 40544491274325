import { FaInfoCircle } from "react-icons/fa";
import { useSubscription } from "../../../../hooks/useSubscription";
import { subscriptionsTypes } from "../../../../scripts/subscription/subscriptionTypes";
import ViewHeader from "../../../UiComponents/ViewHeader";
import { SettingsParagraph, SettingsParagraphContainer } from "../../settingsStyles";
import LocalAppLink from "./LocalAppLink";

const LocalAppView = props => {
    const [hubs, setHubs] = useSubscription(subscriptionsTypes.hubs);

    return <>
        <ViewHeader headerText={'Local App'} backLink={'/settings'} />

        <SettingsParagraphContainer>
            <SettingsParagraph>
                <FaInfoCircle /> To open the Waved app on the local network you must be connected to either the same Wi-Fi network or ethernet network as the Waved hub.
            </SettingsParagraph>
        </SettingsParagraphContainer>

        {hubs?.map(hub => {
            return <LocalAppLink hub={hub} />
        })}
    </>
}

export default LocalAppView;