import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../../UI/theme';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const Container = styled.div`
    height: 200px;
    canvas {
        max-width: 600px;
        min-width: 300px;
        margin: 20px 0;

        @media only screen and (min-width: ${theme.screenSizes.medium}px) {
            min-width: 500px;
        }
    }
`

const chartData = {
    labels: [],
    datasets: [
        {
            type: 'bar',
            label: 'Average dB (Hourly)',
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            borderWidth: 0.5,
            pointRadius: 1,
            categoryPercentage: 0.9,
            barPercentage: 0.9,
            fill: false,
            data: []
        }
    ]
};

const options = {
    maintainAspectRatio: false,
    scales: {
        x: {
            beginAtZero: true,
            ticks: {
                display: true,
            },
            grid: {
                display: false,
                drawBorder: false,
            }
        },
        y: {
            beginAtZero: true,
            ticks: {
                display: false,
            },
            grid: {
                display: false,
                drawBorder: false
            }
        }
    },
    plugins: {
        legend: {
            display: false
        }
    }
}

const chartConfig = {
    data: chartData,
    options
}

const TrendHourChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    const clickHandler = (e) => {
        if (chartInstance) {
            const points = chartInstance.getElementsAtEventForMode(e, 'nearest', { intersect: true }, true);

            if (points.length) {
                const firstPoint = points[0];
                var label = chartInstance.data.labels[firstPoint.index];
                var value = chartInstance.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
            }
            if (parseInt(label) >= 0 && parseInt(label) < 24) {
                props.setActiveHour(label);
            }
        }
    }
    
    chartConfig.data.datasets[0].data = props.data;
    chartConfig.options.scales.y.suggestedMin = (Math.round(Math.min(...props.data)) - 5) > 0 ? (Math.round(Math.min(...props.data)) - 5) : 0;
    chartConfig.options.scales.y.suggestedMax = Math.round(Math.max(...props.data)) + 5;
    chartConfig.data.labels = props.labels;
    if (chartInstance) {
        chartInstance.update();
    }

    useEffect(() => {
        var index = parseInt(props.activeHour);
        if (index >= 0 && index < 24) {
            chartConfig.data.datasets[0].backgroundColor = Array(chartConfig.data.datasets[0].data.length).fill('rgba(75,192,192,0.4)');
            chartConfig.data.datasets[0].backgroundColor[index] = 'rgba(75,192,192,1)';
        }
        if (chartInstance) {
            chartInstance.update();
        }
    }, [props.activeHour])

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(chartContainer.current, chartConfig);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <Container>
            <canvas ref={chartContainer} height={500} onClick={(e) => clickHandler(e)} />
        </Container>
    );
}

export default TrendHourChart;