import { useState } from "react";
import { useEffect } from "react";
import { getCustomer } from "../../../scripts/datasource";
import { getZone } from "../../../scripts/zone";
import { RowDetailsContainer } from "./customerHealthStyles";

const ZoneDetails = props => {
    const [zone, setZone] = useState();
    const [customer, setCustomer] = useState();

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            let zoneResult = await getZone(props.zoneId);
            setZone(zoneResult);
        } catch (error) {

        }
        try {
            let customerResult = await getCustomer(props.customerId);
            setCustomer(customerResult);
        } catch (error) {

        }
    }

    return <RowDetailsContainer>
        <label>Venue: {customer?.companyName}</label>
        <label>Zone ID: {zone?.zoneId}</label>
        <label>Zone name: {zone?.zoneName}</label>
    </RowDetailsContainer>
}

export default ZoneDetails;