import styled from 'styled-components';
import theme from '../../UI/theme';
import { Chevron } from '../UiComponents/Icons';

const StyledButton = styled.button`
    border: none;
    color: ${theme.colors.textGray};
    background-color: transparent;
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
`

const BackButton = props => {
    return <StyledButton {...props}>
        <Chevron height={20} width={20} />
    </StyledButton>
}

export default BackButton;