import styled from "styled-components";
import TabSelector from "../TabSelector";
import { useState } from "react";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const ChildContainer = styled.div`
    display: ${props => props.hidden ? 'none' : 'initial'}
`

const SmallScreenColumnContainer = props => {
    const tabs = props?.children?.map((child, index) => {
        return { key: index, display: child?.props?.columntitle }
    });
    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    return <Container>
        <TabSelector tabs={tabs} selectedTab={selectedTab} onClick={(tab) => setSelectedTab(tab)} />

        {props?.children?.map((child, index) => {
            return <ChildContainer key={index} hidden={selectedTab?.key !== index}>
                {child}
            </ChildContainer>
        })}
    </Container>
}

export default SmallScreenColumnContainer;