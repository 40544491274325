import styled from "styled-components";
import theme from "../../../../../UI/theme";
import AddUserButton from "./AddUserButton";

const ToolBarContainer = styled.div`
    display: grid;
    grid-template-columns: min-content;;
    grid-gap: 10px;
    grid-auto-flow: column;
    padding: 10px;
    background-color: ${theme.colors.darkSpace80};
    box-sizing: border-box;
    border-radius: 2px;
`

const UserToolBar = props => {
    return <>
        <ToolBarContainer>
            <AddUserButton />
        </ToolBarContainer>
    </>
}

export default UserToolBar;