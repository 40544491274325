const keys = {
    HUBS: 'HUBS',
    SENSORS: 'SENSORS'
}

const items = [
    {
        key: keys.HUBS,
        display: 'Hubs'
    }, {
        key: keys.SENSORS,
        display: 'Sensors'
    }
]

export default {
    keys,
    items
}