import React from 'react';
import ChoiceField from './ChoiceField';
import { getHubDisplayName } from '../modules/hub/hubUtils';

const HubChoiceField = ({ hubId, hubs, updateObject, cellControl }) => {
    const currentHub = hubs.find(hub => hub.hubId === hubId);
    return <ChoiceField
        selectedItem={currentHub && { value: currentHub.hubId, label: getHubDisplayName(currentHub) }}
        items={hubs.map(hub => ({ value: hub.hubId, label: getHubDisplayName(hub) }))}
        setValue={(value) => updateObject({ hubId: value })}
        cellControl={cellControl}
        excludeNone={true}  
    />
}

export default HubChoiceField;
