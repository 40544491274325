import { useEffect } from "react";
import { useState } from "react";
import styled, { css } from "styled-components";
import theme from "../../UI/theme";

const CheckboxContainer = styled.div`
    display: ${props => props.display || 'flex'};
    cursor: pointer;
    margin: 5px 5px 5px 0;
`

const Icon = styled.svg`
  fill: none;
  width: 100%;
  stroke: ${props => props.disabled ? theme.colors.darkSpace50 : theme.colors.greenEnergy};
  stroke-width: 4px;
`

const HiddenCheckbox = styled.input.attrs(() => ({ type: "checkbox" }))`
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 0px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`

const StyledCheckbox = styled.div`
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: ${props => props.checkedBackgroundColor && props.checked ? props.checkedBackgroundColor : (props.backgroundColor || theme.colors.darkSpace80)};
    border-radius: 4px;

    ${Icon} {
        visibility: ${props => props.checked ? 'visible' : 'hidden'};
        opacity: ${props => props.checked ? '100%' : '0%'};
      }
`

const Checkbox = props => {
    const [checked, setChecked] = useState(props.checked);

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    const onChangeHandler = () => {
        if (!props.disabled) {
            setChecked(prev => { return !prev; });
            if (props.onChange) {
                props.onChange();
            }
        }
    }

    return <CheckboxContainer display={props.display} onClick={onChangeHandler}>
        <HiddenCheckbox checked={checked} {...props} />
        <StyledCheckbox checked={checked} {...props}>
            {!props.hideIcon ? <>
                <Icon viewBox='0 0 24 24' {...props}>
                    <polyline points="20 4 9 15 4 10" />
                </Icon>
            </> : <></>}
        </StyledCheckbox>
    </CheckboxContainer>
}


export default Checkbox;