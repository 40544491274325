import styled, { css } from "styled-components";
import theme from "../../../../UI/theme";

const Container = styled.div`
    display: flex;
    flex-direction: row;
`

const ScheduleDays = props => {

    const selectDayHandler = (dayIndex) => {
        if (props.editable && props.onSelectDay) {
            var selectedDaysCopy = [...props.days];
            if (selectedDaysCopy.includes(dayIndex)) {
                props.onSelectDay(selectedDaysCopy.filter(d => d !== dayIndex));
            } else {
                selectedDaysCopy.push(dayIndex);
                props.onSelectDay(selectedDaysCopy);
            }
        }
    }

    return <Container>
        {days.map((day, index) => {
            return <Day editable={props.editable} selected={props.days?.includes(index)} key={index} onClick={() => selectDayHandler(index)}>{day}</Day>
        })}
    </Container>
}

export default ScheduleDays;

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const Day = styled.div`
    width: 14px;
    flex: 1 1 0px;
    display: flex;
    justify-content: center;
    color: ${props => props.selected ? theme.colors.greenReadable : theme.colors.textGray};
    :not(:last-of-type) {
        margin-right: 10px;
    }
    font-size: ${theme.fontSize.small};
    border: 1px solid;
    border-color: ${props => props.selected ? theme.colors.greenEnergy : theme.colors.textGray};
    border-radius: 104px;
    padding: 1px 10px 4px;

    ${props => props.editable && css`
        :hover {
            cursor: pointer;
        }
    `}
`