import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/heatmapFloorElement';

async function getHeatmapFloorElement(floorElementId) {
    const res = await axios.get(`${hostname}${path}?floorElementId=${floorElementId}`);
    return res.data;
}

async function updateHeatmapFloorElement(customerId, heatmapFloorElement) {
    const res = await axios.put(`${hostname}${path}?customerId=${customerId}`, heatmapFloorElement);
    return res.data;
}

async function createHeatmapFloorElement(customerId, heatmapFloorElement) {
    const res = await axios.post(`${hostname}${path}?customerId=${customerId}`, heatmapFloorElement);
    return res.data;
}

async function createOrUpdateHeatmapFloorElement(customerId, heatmapFloorElement) {
    try {
        const res = await updateHeatmapFloorElement(customerId, heatmapFloorElement);
        return res;
    } catch (error) {
        if (error?.response?.status === 404) {
            const res = await createHeatmapFloorElement(customerId, heatmapFloorElement);
            return res;
        } else {
            throw error;
        }
    }
}

export {
    getHeatmapFloorElement,
    updateHeatmapFloorElement,
    createHeatmapFloorElement,
    createOrUpdateHeatmapFloorElement
}