import styled from 'styled-components';
import theme from '../../UI/theme';
import TopNavigation from '../navigation/TopNavigation';
import { useContext } from 'react';
import UserContext from '../../hooks/UserContext';

const StyledViewHeader = styled.div`
    display: grid;
    grid-template-columns: 15% 70% 15%;
    width: 100%;
    height: 30px;
    text-align: center;
    align-items: center;
    margin: 30px 0;
    color: ${props => props.color || theme.colors.textGray};
    
    h3 {
        font-weight: normal;
        font-size: ${theme.fontSize.larger};
        line-height: ${theme.fontSize.larger};
        margin: 0;
    }

    @media only screen and (max-width: ${theme.screenSizes.small - 1}px) {
        margin: 15px 0;
    }
`

const VenueName = styled.div`
    font-size: ${theme.fontSize.tiny};
    margin: 4px 0;
    color: ${theme.colors.darkSpace50};
`


const ViewHeader = props => {
    const userContext = useContext(UserContext);
    return <StyledViewHeader {...props}>
        {props.backLink ? <>
            <TopNavigation to={props.backLink} />
        </> : <>
            <div></div>
        </>}
        <div>
        <h3>{props.headerText}</h3>
        {props.showVenueName ? <>
            <VenueName>
               {userContext.customer?.companyName}
            </VenueName>
        </> : <></>}
        
        </div>
    </StyledViewHeader>
}


export default ViewHeader;