import styled, { css } from "styled-components";
import theme from "../../../UI/theme";
import { SkeletonItem } from "./skeletonStyles";

const ZoneSkeletonContainer = styled.div`
    background-color: ${theme.colors.darkSpace};
    display: grid;
    grid-template-columns: 20% 50px 1fr 2.5fr 50px 50px;
    gap: 10px;
    align-items: center;
    padding: 10px;
    position: relative;

    grid-column: 2;
    grid-column: ${props => props.groupHasSourceSelectors === false ? '1 / span 2' : '2'};

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-template-columns: 20% 40px 1fr 2.5fr 40px 40px;
    }

    @media only screen and (max-width: ${650}px) {
        grid-template-columns: 1fr 40px 40px 40px;
    }
`

const StyledSkeletonItem = styled(SkeletonItem)`
    height: 50px;
    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        height: 40px;
    }

    @media only screen and (max-width: ${650}px) {
        ${props => [2, 3].includes(props.index) && css`
            grid-column: span 2;
        `}
    }
`

const ZoneSkeleton = props => {
    let skeletonItems = Array.from(Array(6).keys());

    return <ZoneSkeletonContainer {...props} >
        {skeletonItems.map((item, index) => {
            return <StyledSkeletonItem key={index} index={index} />
        })}
    </ZoneSkeletonContainer>
}

export default ZoneSkeleton;