import styled from 'styled-components';
import theme from '../../UI/theme';

const InputField = styled.input`
    outline: none;
    font-family: 'Karelia';
    font-size: ${theme.fontSize.medium};
    padding: 10px;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    background: ${theme.colors.darkSpace80};
    color: ${theme.colors.textGray};
    width: ${props => props.width ? `${props.width}px` : 'fill-available'};
    border: 1px solid transparent;

    &::-webkit-calendar-picker-indicator {
        filter: invert(100%) sepia(70%) saturate(1383%) hue-rotate(178deg) brightness(83%) contrast(84%);
        cursor: pointer;
    }

    &::placeholder {
        color: ${theme.colors.textGray}80;
    }

    &:focus, &:hover {
        border: 1px solid ${theme.colors.textGray};
    }

    &:disabled {
        border-color: transparent;
        opacity: 0.4;
    }
    
`

export const InputFieldContainer = styled.div`
    margin: 16px 0;
`

export default InputField;
