export const musicPlayerProviders = {
    SOUNDTRACK_YOUR_BRAND: {
        key: 'SoundtrackYourBrand',
        display: 'Soundtrack Your Brand',
    },
    // SPOTIFY: {
    //     key: 'SPOTIFY',
    //     display: 'Spotify'
    // },
    // LIVEQUBE: {
    //     key: 'LIVEQUBE',
    //     display: 'Liveqube'
    // }
}