import React from 'react';
import Button from '../../../../UiComponents/Button';
import styled from 'styled-components';
import { muteSpeakers, unmuteSpeakers } from '../speakerUtils';
import theme from '../../../../../UI/theme';


const StyledSpeakerMuteButton = styled(Button)`
    width: 100%;
    background-color: ${props => props.isMuted ? theme.colors.greenEnergy : theme.colors.secondary};
    color: ${props => props.isMuted ? theme.colors.darkSpace : theme.colors.whiteSpace};
`
const AnimatedSpeakerMuteButton = styled(Button)`
    width: 100%;
    @keyframes shimmer{
        0%{
            background-position: -100% 0
        }
        100%{
            background-position: 100% 0
        }
    }
    background: linear-gradient(to right, ${theme.colors.darkSpace90} 10%, ${theme.colors.darkSpace70} 18%, ${theme.colors.darkSpace90} 33%);
    background-size: 200%;
    animation: shimmer 1.5s infinite reverse;
        
`

const SpeakerMuteButton = ({ speaker, hubs }) => {

    const [pending, setPending] = React.useState(false);

    const toggleMute = () => {
        setPending(true);
        const zoneId = speaker.zoneId;
        var speakerHubs = hubs.filter(hub => hub.zoneId === zoneId);
        if (!speakerHubs.length) speakerHubs = hubs;
        if (speaker.isMuted){
            unmuteSpeakers([speaker], speakerHubs)
        } else {
            muteSpeakers([speaker], speakerHubs)
        }
        setTimeout(() => setPending(false), 3000);
    };

    return (<>
        {pending ? 
            <AnimatedSpeakerMuteButton secondary small >
                {speaker.isMuted ? 'Unmute' : 'Mute'}
            </AnimatedSpeakerMuteButton>
        :
            <StyledSpeakerMuteButton secondary small isMuted={speaker.isMuted} onClick={toggleMute} >
                {speaker.isMuted ? 'Unmute' : 'Mute'}
            </StyledSpeakerMuteButton>
        }
    </>);
};

export default SpeakerMuteButton;