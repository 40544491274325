import { useState } from "react";
import { HeaderRow, Table, TDHeader, THead } from "../support/SupportStyles";
import CustomerHealthMetric from "./CustomerHealthMetric";
import customerHealthMetricTypes from "./customerHealthMetricTypes";
import entityTypes from "./entityTypes";

const CustomerHealthMetrics = props => {
    const [sortProperty, setSortProperty] = useState();
    const [sortAsc, setSortAsc] = useState(true);
    const groupedMetrics = groupMetricsByCustomer(props.metrics);
    const previousGroupedMetrics = groupMetricsByCustomer(props.previousMetrics);
    const customerMetricTypes = Object.keys(customerHealthMetricTypes).map(key => customerHealthMetricTypes[key]).filter(metricType => metricType.entityType.key === entityTypes.customer.key);

    const sortBy = (property) => {
        setSortProperty(property);

        if (sortProperty === property) {
            setSortAsc(prev => !prev);
        } else {
            setSortAsc(false);
        }
    }

    const compareCustomers = (a, b) => {
        let aProp, bProp;

        if (sortProperty === 'customerId' || sortProperty === 'entityId') {
            aProp = a?.obj?.[0]?.[sortProperty];
            bProp = b?.obj?.[0]?.[sortProperty];
        } else if (customerMetricTypes?.some(type => type.key === sortProperty)) {
            aProp = a?.obj?.find(metric => metric?.metricType === sortProperty)?.metricValue;
            bProp = b?.obj?.find(metric => metric?.metricType === sortProperty)?.metricValue;
        }

        if (aProp === undefined) {
            return 1;
        } else if (bProp === undefined) {
            return -1;
        }

        if (sortAsc === false) {
            return aProp < bProp ? 1 : bProp < aProp ? -1 : 0;
        } else {

            return aProp < bProp ? -1 : bProp < aProp ? 1 : 0;
        }
    }

    return <Table>
        <colgroup>
            <col style={{ width: "1%" }} />
        </colgroup>
        <THead>
            <HeaderRow>
                <TDHeader sorted={sortProperty === 'customerId'} ascending={sortAsc} onClick={() => sortBy('customerId')}>Customer ID</TDHeader>
                {customerMetricTypes?.map((metricType, index) => {
                    return <TDHeader key={index} sorted={sortProperty === metricType.key} ascending={sortAsc} onClick={() => sortBy(metricType?.key)}>{metricType?.display}</TDHeader>
                })}
            </HeaderRow>
        </THead>

        <tbody>
            {Object.keys(groupedMetrics)
                ?.map(key => ({ key, obj: groupedMetrics?.[key] }))
                ?.sort((a, b) => { return compareCustomers(a, b) })
                ?.map(item => {
                    const customerMetrics = item.obj;
                    const previousCustomerMetrics = previousGroupedMetrics?.[item.key];

                    return <CustomerHealthMetric
                        key={item.key}
                        customerId={item.key}
                        customerMetrics={customerMetrics}
                        customerMetricTypes={customerMetricTypes}
                        previousCustomerMetrics={previousCustomerMetrics}
                    />
                })}
        </tbody>
    </Table>
}

export default CustomerHealthMetrics;

const groupMetricsByCustomer = metrics => {
    return metrics?.reduce((groups, metric) => {
        const customerId = metric?.customerId;

        if (!groups[customerId]) {
            groups[customerId] = [];
        }

        groups[customerId].push(metric);
        return groups;
    }, {});
}