import { getHubs } from "../hub";
import { getProcessors } from "../processor";
import { getSchedules } from "../schedules";
import { getSensors } from "../sensor";
import { getSourceSelector, getSourceSelectors } from "../sourceSelector";
import { getZone, getZones } from "../zone";
import { getZoneLive, getZoneLives } from "../zoneLive";
import { getZoneModes } from "../zoneModes";
import { getSources } from "../source";
import { getMusicPlayers } from "../musicPlayer";
import { getSourceMatrix, getSourceMatrixes } from "../sourceMatrix";
import { getZoneGroup, getZoneGroups } from "../zoneGroup";
import { getMeters } from "../meter";
import { getSpeakers } from "../speaker";

const subscriptionsTypes = {
    zone: {
        key: 'zone',
        updateKey: 'zone',
        get: (_, zoneId) => getZone(zoneId),
        needsId: true
    },
    sourceSelector: {
        key: 'sourceSelector',
        updateKey: 'sourceSelector',
        get: (_, sourceSelectorId) => getSourceSelector(sourceSelectorId)
    },
    zoneLive: {
        key: 'zoneLive',
        updateKey: 'zoneLive',
        get: (customerId, zoneId) => getZoneLive(customerId, zoneId),
        needsId: true
    },
    sourceMatrix: {
        key: 'sourceMatrix',
        updateKey: 'sourceMatrix',
        get: (_, sourceMatrixId) => getSourceMatrix(sourceMatrixId),
        needsId: true
    },
    hubs: {
        key: 'hubs',
        updateKey: 'hub',
        get: (customerId) => getHubs(customerId),
        usePoll: true
    },
    zones: {
        key: 'zones',
        updateKey: 'zone',
        get: (customerId) => getZones(customerId)
    },
    processors: {
        key: 'processors',
        updateKey: 'processor',
        get: (customerId) => getProcessors(customerId)
    },
    sourceSelectors: {
        key: 'sourceSelectors',
        updateKey: 'sourceSelector',
        get: (customerId) => getSourceSelectors(customerId)
    },
    sensors: {
        key: 'sensors',
        updateKey: 'sensor',
        get: (customerId) => getSensors(customerId)
    },
    schedules: {
        key: 'schedules',
        updateKey: 'schedule',
        get: (customerId) => getSchedules(customerId)
    },
    zoneModes: {
        key: 'zoneModes',
        updateKey: 'zoneMode',
        get: (customerId) => getZoneModes(customerId)
    },
    zoneLives: {
        key: 'zoneLives',
        updateKey: 'zoneLives',
        get: (customerId) => getZoneLives(customerId)
    },
    musicPlayers: {
        key: 'musicPlayers',
        updateKey: 'musicPlayer',
        get: (customerId) => getMusicPlayers(customerId)
    },
    sources: {
        key: 'sources',
        updateKey: 'source',
        get: (customerId) => getSources(customerId)
    },
    sourceMatrixes: {
        key: 'sourceMatrixes',
        updateKey: 'sourceMatrix',
        get: (customerId) => getSourceMatrixes(customerId)
    },
    meters: {
        key: 'meters',
        updateKey: 'meter',
        get: (customerId) => getMeters(customerId)
    },
    zoneGroup: {
        key: 'zoneGroup',
        updateKey: 'zoneGroup',
        get: (_, zoneGroupId) => getZoneGroup(zoneGroupId),
        needsId: true
    },
    zoneGroups: {
        key: 'zoneGroups',
        updateKey: 'zoneGroup',
        get: (customerId) => getZoneGroups(customerId)
    },
    speakers: {
        key: 'speakers',
        updateKey: 'speaker',
        get: (customerId) => getSpeakers(customerId)
    }
};

const getSubscriptionType = (updateKey) => {
    return subscriptionsTypes[updateKey];
}

export { 
    subscriptionsTypes,
    getSubscriptionType
 };