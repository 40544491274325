import { useState } from "react";
import styled from "styled-components";
import { useSubscription } from "../../../../hooks/useSubscription";
import { subscriptionsTypes } from "../../../../scripts/subscription/subscriptionTypes";
import { updateZoneGroup } from "../../../../scripts/zoneGroup";
import Button from "../../../UiComponents/Button";
import Card from "../../../UiComponents/Card";
import ViewHeader from "../../../UiComponents/ViewHeader";
import { GroupContainer, ZoneGroupHeader } from "./groupStyles";
import NewZoneGroupModal from "./NewZoneGroupModal";
import SourceSelectorGroup from "./SourceSelectorGroup";
import Zone from "./Zone";
import ZoneGroup from "./ZoneGroup";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-bottom: 20px;
`

const ZoneGroupView = props => {
    const [zoneGroups, setZoneGroups] = useSubscription(subscriptionsTypes.zoneGroups);
    const [sourceSelectors, setSourceSelectors] = useSubscription(subscriptionsTypes.sourceSelectors);
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);
    const [processors, setProcessors] = useSubscription(subscriptionsTypes.processors);
    const [showNewZoneGroupModal, setShowNewZoneGroupModal] = useState(false);

    const sourceSelectorsWithoutZoneGroup = sourceSelectors?.filter(sourceSelector => !zoneGroups?.some(zoneGroup => zoneGroup?.zoneGroupId === sourceSelector?.zoneGroupId));
    const zonesWithoutZoneGroup = zones?.filter(zone => sourceSelectorsWithoutZoneGroup?.some(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId) ||
        (!zoneGroups?.some(zoneGroup => zoneGroup.zoneGroupId === zone?.zoneGroupId) && !sourceSelectors?.some(sourceSelector => sourceSelector.sourceSelectorId === zone?.sourceSelectorId)));

    const changeZoneGroupOrder = async (zoneGroupId, orderChange) => {
        const orderedZoneGroups = JSON.parse(JSON.stringify(zoneGroups))?.sort((a, b) => { return a?.orderIndex - b?.orderIndex })?.map((zoneGroup, index) => {
            zoneGroup.orderIndex = index;
            return zoneGroup;
        });

        const zoneGroupIndex = orderedZoneGroups?.findIndex(zoneGroup => zoneGroup?.zoneGroupId === zoneGroupId);

        if (zoneGroupIndex > -1) {
            const zoneGroupNeighbourIndex = orderChange > 0 ? zoneGroupIndex + 1 : orderChange < 0 ? zoneGroupIndex - 1 : null;

            if (zoneGroupNeighbourIndex !== null && orderedZoneGroups[zoneGroupNeighbourIndex] !== undefined) {
                orderedZoneGroups[zoneGroupIndex].orderIndex = zoneGroupNeighbourIndex;
                orderedZoneGroups[zoneGroupNeighbourIndex].orderIndex = zoneGroupIndex;
            }
        }

        let changesMade = false;

        for (let index = 0; index < orderedZoneGroups.length; index++) {
            const zoneGroup = orderedZoneGroups[index];
            if (zoneGroup?.orderIndex !== zoneGroups?.find(zG => zG.zoneGroupId === zoneGroup?.zoneGroupId)?.orderIndex) {
                changesMade = true;
                let pickedZoneGroup = (({ zoneGroupId, customerId, orderIndex }) => ({ zoneGroupId, customerId, orderIndex }))(zoneGroup);
                await updateZoneGroup({ ...pickedZoneGroup });
            }
        }
    }

    return <>
        <ViewHeader headerText={'Groups'} backLink={'/settings'} showVenueName={true} />

        <Container>
            <Card>
                <p>Customize and improve your panel view layout by dividing your zones into groups.</p>
                <Button primary onClick={() => setShowNewZoneGroupModal(true)}>Add Group</Button>
            </Card>

            {zoneGroups?.sort((a, b) => { return a?.orderIndex - b?.orderIndex })?.map((zoneGroup, index) => {
                const sourceSelectorsInZoneGroup = sourceSelectors?.filter(sourceSelector => zoneGroup?.zoneGroupId === sourceSelector?.zoneGroupId);
                const zonesInZoneGroup = zones?.filter(zone => sourceSelectorsInZoneGroup?.some(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId) ||
                    (zoneGroup.zoneGroupId === zone?.zoneGroupId && !sourceSelectors?.some(sourceSelector => sourceSelector.sourceSelectorId === zone?.sourceSelectorId)));

                return <ZoneGroup
                    key={zoneGroup?.zoneGroupId ?? index}
                    zoneGroup={zoneGroup}
                    processors={processors}
                    sourceSelectors={sourceSelectorsInZoneGroup}
                    zones={zonesInZoneGroup}
                    zoneGroups={zoneGroups}
                    changeZoneGroupOrder={changeZoneGroupOrder} />
            })}

            {sourceSelectorsWithoutZoneGroup?.length > 0 || zonesWithoutZoneGroup?.length > 0 ?
                <GroupContainer>
                    <ZoneGroupHeader>Elements without group</ZoneGroupHeader>

                    {sourceSelectorsWithoutZoneGroup?.map((sourceSelector, index) => {
                        const hubId = processors?.find(processor => processor?.processorId === sourceSelector?.processorId)?.hubId;

                        return <SourceSelectorGroup
                            key={index}
                            hubId={hubId}
                            sourceSelector={sourceSelector}
                            zones={zonesWithoutZoneGroup?.filter(zone => zone.sourceSelectorId === sourceSelector.sourceSelectorId)}
                            zoneGroups={zoneGroups} />
                    })}

                    {zonesWithoutZoneGroup?.filter(zone => !sourceSelectorsWithoutZoneGroup?.some(sourceSelector => sourceSelector.sourceSelectorId === zone?.sourceSelectorId)).map((zone, index) => {
                        return <Zone
                            key={index}
                            zone={zone}
                            hasSourceSelector={false}
                            zoneGroups={zoneGroups} />
                    })}
                </GroupContainer>
                : <></>}

            <NewZoneGroupModal
                show={showNewZoneGroupModal}
                close={() => setShowNewZoneGroupModal(false)}
                customerId={props.customerId}
                processors={processors}
                zoneGroups={zoneGroups} />
        </Container>
    </>
}

export default ZoneGroupView;