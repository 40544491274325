import styled from 'styled-components';

const Overlay = styled.div`
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
`

export default Overlay;