import Paper, { Layer, Path, Point } from "paper";
import heatmapTypes from "../../container/heatmapTypes";
import canvasController from "../CanvasController";
import Wall from "./Wall";
import LayerController from "../LayerController";
import { getOuterWallElement } from "./wallUtils";

class FloorLayerController extends LayerController {
    constructor() {
        super();
        this.layer = new Layer();
        this.active = false;
        this.tool = new Paper.Tool();
        this.outerWall = null;
        this.innerWalls = [];
        this.layer.name = 'Floor Layer';
    }

    onFloorChange(floorData) {
        const activeFloor = floorData.activeFloor;
        this.initializeLayer();
        const outerWallElement = getOuterWallElement(floorData);
        const innerWallElements = floorData.floorElements.filter(e => e.type === heatmapTypes.elementTypes.INNERWALL && e.floorId === activeFloor);
        this.initializeWalls(outerWallElement ? outerWallElement : {}, innerWallElements ? innerWallElements : []);
    }

    onToolSelect() {
        this.activate();
        this.layer.bringToFront();
    }

    initializeLayer() {
        this.layer.removeChildren();
        this.outerWall = null;
        this.innerWalls = [];
    }

    deactivateAllWalls() {
        if (this.outerWall) {
            this.outerWall.deactivate();
        }
        this.innerWalls.forEach((wall) => {
            wall.deactivate();
        });
    }

    selectWallHandler(data) {
        if (data.highlight) {
            this.highlightWall(data.wallElement);
        } else {
            this.selectWall(data.wallElement);
        }
    }

    deselectWallHandler(data) {
        if (data.highlight) {
            if (!data.isSelected) {
                this.lowlightWall(data.wallElement);
            }
        } else {
            this.deselectWall(data.wallElement);
        }
    }

    getClosestPointOnWalls(point, floorElementID) {
        var closestPoint = null;
        if (this.outerWall && this.outerWall.floorElementID != floorElementID) {
            var closestPointOnOuterWall = this.outerWall.getNearestPointOnWall(point);
            if (closestPointOnOuterWall) {
                closestPoint = closestPointOnOuterWall;
            }
        }
        this.innerWalls.forEach(innerWall => {
            if (innerWall && innerWall.floorElementID != floorElementID) {
                var closestPointOnInnerWall = innerWall.getNearestPointOnWall(point);
                if (closestPointOnInnerWall) {
                    if (closestPoint) {
                        closestPoint = (point.getDistance(closestPoint) <= point.getDistance(closestPointOnInnerWall)) ? closestPoint : closestPointOnInnerWall;
                    } else {
                        closestPoint = closestPointOnInnerWall;
                    }
                }
            }
        })

        return closestPoint;
    }

    activate() {
        this.layer.activate();
        this.active = true;
        this.tool.activate();
    }

    selectWall(wallElement) {
        this.activate();
        if (wallElement.type === heatmapTypes.elementTypes.OUTERWALL) {
            this.outerWall.activate();
        } else if (wallElement.type === heatmapTypes.elementTypes.INNERWALL) {
            this.innerWalls.find(wall => wall.floorElementID === wallElement.floorElementId).activate();
        }
    }

    deselectWall(wallElement) {
        if (wallElement.type === heatmapTypes.elementTypes.OUTERWALL) {
            this.outerWall.deactivate();
        } else if (wallElement.type === heatmapTypes.elementTypes.INNERWALL) {
            this.innerWalls.find(wall => wall.floorElementID === wallElement.floorElementId).deactivate();
        }
    }

    highlightWall(wallElement) {
        this.activate();
        if (wallElement.type === heatmapTypes.elementTypes.OUTERWALL) {
            this.outerWall.highlight();
        } else if (wallElement.type === heatmapTypes.elementTypes.INNERWALL) {
            this.innerWalls.find(wall => wall.floorElementID === wallElement.floorElementId).highlight();
        }
    }

    lowlightWall(wallElement) {
        if (wallElement.type === heatmapTypes.elementTypes.OUTERWALL) {
            this.outerWall.lowlight();
        } else if (wallElement.type === heatmapTypes.elementTypes.INNERWALL) {
            this.innerWalls.find(wall => wall.floorElementID === wallElement.floorElementId).lowlight();
        }
    }

    initializeWalls(outerWall, innerWalls) {
        this.activate();
        this.outerWall = new Wall(outerWall, this.tool, heatmapTypes.elementTypes.OUTERWALL, (point, floorElementID) => this.getClosestPointOnWalls(point, floorElementID));
        this.innerWalls = [];
        innerWalls.forEach(innerWall => {
            this.innerWalls.push(new Wall(innerWall, this.tool, heatmapTypes.elementTypes.INNERWALL, (point, floorElementID) => this.getClosestPointOnWalls(point, floorElementID)));
        });
    }

    addInnerWall(id) {
        this.activate();
        this.innerWalls.push(new Wall({ floorElementId: id }, this.tool, heatmapTypes.elementTypes.INNERWALL, (point, floorElementID) => this.getClosestPointOnWalls(point, floorElementID)))
        this.innerWalls[this.innerWalls.length - 1].activate();
        canvasController.canvasCallback(heatmapTypes.actions.ADD_INNER_WALL, { wall: this.innerWalls[this.innerWalls.length - 1], id: id });
    }

    addOuterWall(id) {
        this.activate();
        this.outerWall = new Wall({ floorElementId: id }, this.tool, heatmapTypes.elementTypes.OUTERWALL, (point, floorElementID) => this.getClosestPointOnWalls(point, floorElementID));
        this.outerWall.activate();
        canvasController.canvasCallback(heatmapTypes.actions.ADD_OUTER_WALL, { wall: this.outerWall, id: id });
    }

    completeInnerWall(id) {
        var innerWall = this.innerWalls.find(wall => wall.floorElementID === id);
        if (innerWall) {
            innerWall.complete();
        }
    }

    deleteWall(id) {
        var innerWall = this.innerWalls.find(wall => wall.floorElementID === id);
        if (innerWall) {
            innerWall.remove();
            canvasController.canvasCallback(heatmapTypes.actions.DELETE_WALL, id);
        }
    }

    deactivate() {
        this.deactivateAllWalls();
        this.active = false;
    }

    getZoomAndCenter(outerWallElement) {
        if (outerWallElement) {
            var outerWallPath = new Path({});
            outerWallPath.addSegments(outerWallElement.geometry.map(point => new Point(point.x, point.y)));

            var bounds = outerWallPath.bounds;
            var viewBounds = Paper.view.bounds;
            var center = bounds.center;

            // check if height and width fits inside view
            var outsideBounds = 0;
            var longestBound;
            if (bounds.height > viewBounds.height) {
                outsideBounds = bounds.height - viewBounds.height;
                longestBound = bounds.height;
            }
            if (bounds.width - viewBounds.width > outsideBounds) {
                outsideBounds = bounds.width - viewBounds.width;
                longestBound = bounds.width;
            }

            // if both fits inside -> scale for longest
            if (!longestBound) {
                if (bounds.height < bounds.width) {
                    longestBound = bounds.width;
                } else {
                    longestBound = bounds.height;
                }
            }

            var zoom = Paper.view.zoom * Math.min(viewBounds.width, viewBounds.height) / (longestBound * 1.2); //Math.round(500/longestBound)


            return { zoom, center, bounds };
        }
    }
}

export default FloorLayerController;