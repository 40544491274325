import { Document, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import theme from "../../../UI/theme";
import { resolveTimeText } from "./decibelInsightsUtils";

const styles = StyleSheet.create({
    page: { backgroundColor: theme.colors.darkSpace, padding: 30 },
    pageHeaderSection: {},
    section: { color: theme.colors.textGray, padding: '20pt 0', borderBottom: `2px solid ${theme.colors.darkSpace80}` },
    image: { height: 30, aspectRatio: 1065/275, position: 'absolute', top: 0, right: 0 },
    header: { fontSize: 30, color: 'white' },
    subHeader: { fontSize: 20, color: 'white', marginBottom: 10 },
    breadText: { fontSize: 12, color: theme.colors.textGray },
    chart: { width: 535, height: 225, marginBottom: 10 },
    metricSection: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between' },
    metricContainer: {
        color: theme.colors.textGray,
        borderRadius: 2,
        width: '20%',
        backgroundColor: theme.colors.darkSpace90,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 10,
    },
    metricHeader: { fontSize: 10 },
    metricValue: { fontSize: 15, color: theme.colors.greenEnergy },
    warningSection: { marginTop: 10 },
    warningContainer: { display: 'flex', flexDirection: 'row', marginBottom: 5, alignItems: 'center' },
    warningTimeLabel: { fontSize: 12, color: theme.colors.textGray, borderRight: `2px solid ${theme.colors.raspberryRed}`, width: 45, marginRight: 10 },
})

const HistoryInsightsPdfReport = (props) => {

    return <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.pageHeaderSection} fixed>
                <Image src={'/primary_1065x275.png'} style={styles.image} />
                <Text style={styles.header}>Decibel Report</Text>
                <Text style={styles.breadText}>{props.companyName}</Text>
                <Text style={styles.breadText}>{props.dateTime?.toFormat('EEEE MMMM dd, yyyy')}</Text>
            </View>
            {props.zones?.map((zone, index) => {
                const imageString = props.imageStrings?.find(imageString => imageString.zoneId === zone?.zoneId);
                const trend = props.trends?.find(trend => trend?.id === zone?.zoneId);
                if (trend && imageString) {
                    const hourData = trend?.data?.[props.dateTime?.weekday - 1]?.hourData;
                    const relevantHourData = hourData?.filter(value => value > 0);
                    const sum = relevantHourData?.reduce((a, b) => a + b, 0);
                    const avg = sum / relevantHourData?.length;
                    const min = Math.min(...relevantHourData);
                    const max = Math.max(...relevantHourData);
                    const peakHour = hourData?.findIndex(value => value === max);
                    const warnings = trend?.data?.[props.dateTime?.weekday - 1]?.warnings;

                    return <View style={styles.section} key={zone?.zoneId} wrap={false}>
                        <View wrap={false}>
                            <Text style={styles.subHeader}>{zone?.zoneName}</Text>
                            <Image style={styles.chart} src={imageString.imageString} />
                        </View>

                        <View style={styles.metricSection} wrap={false}>
                            <View style={styles.metricContainer}>
                                <Text style={styles.metricHeader}>Minimum</Text>
                                <Text style={styles.metricValue}>{min.toFixed(1)} dB</Text>
                            </View>
                            <View style={styles.metricContainer}>
                                <Text style={styles.metricHeader}>Maximum</Text>
                                <Text style={styles.metricValue}>{max.toFixed(1)} dB</Text>
                            </View>
                            <View style={styles.metricContainer}>
                                <Text style={styles.metricHeader}>Average</Text>
                                <Text style={styles.metricValue}>{avg.toFixed(1)} dB</Text>
                            </View>
                            <View style={styles.metricContainer}>
                                <Text style={styles.metricHeader}>Peak hour</Text>
                                <Text style={styles.metricValue}>{resolveTimeText(peakHour)}</Text>
                            </View>
                        </View>

                        {warnings?.length > 0 ? <>
                            <View style={styles.warningSection} wrap={false} >
                                <Text style={styles.subHeader}>Decibel Level Notifications</Text>

                                {warnings?.map(warning => {
                                    return <View style={styles.warningContainer}>
                                        <Text style={styles.warningTimeLabel}>{resolveTimeText(warning?.start)}</Text>
                                        <Text style={styles.breadText}>{warning?.text}</Text>
                                    </View>
                                })}
                            </View>
                        </> : <></>}
                    </View>
                }
            })}
        </Page>
    </Document>
}

export default HistoryInsightsPdfReport;