import styled from "styled-components";
import theme from "../../../UI/theme";
import Modal from "../../UiComponents/Modal"
import { ModalContainer, ModalHeader } from "../ModalStyles";
import hierarchyTypes from "../../settings/venue/device/hierarchyTypes";

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px 0;
`

const VolumeLabel = styled.label`
    font-size: 1.2rem;
    background-color: ${theme.colors.darkSpace70};
    text-align: center;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
`

const WavedVolume = styled(VolumeLabel)`
    color: ${theme.colors.greenEnergy};
`

const HelpTextContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: fill-available;
    text-align: center;
    justify-content: space-evenly;
    padding: 10px;
    border: 2px solid ${theme.colors.darkSpace70};
    border-width: 2px;
`

const HelpText = styled.label`
    width: fill-available;
    font-size: 0.65rem;
    color: ${theme.colors.textGray};
`

const ZoneVolumeModal = props => {
    return <Modal {...props}>
        <ModalContainer>
            <ModalHeader>Zone Volume - {props.zone?.zoneName}</ModalHeader>

            <GridContainer>
                {props.zone?.shouldRegulate === 1 && props.zone?.hierarchyType !== hierarchyTypes.MASTER.value ?
                    <>
                        <WavedVolume>{!isNaN(props.wavedVolume) ? `${props.wavedVolume?.toFixed()}%` : 'X%'}</WavedVolume>
                        <HelpTextContainer>
                            <label>Comparable volume</label>
                            <HelpText>Can be compared with other zone volumes.</HelpText>
                        </HelpTextContainer>
                    </>
                    : <></>}

                <VolumeLabel>{!isNaN(props.systemVolume) ? `${props.systemVolume?.toFixed()}%` : 'X%'}</VolumeLabel>
                <HelpTextContainer>
                    <label>System Volume</label>
                    <HelpText>The absolute volume of the sound system.</HelpText>
                </HelpTextContainer>
            </GridContainer>

        </ModalContainer>
    </Modal>
}

export default ZoneVolumeModal;