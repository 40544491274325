import React, {useRef, useState, useEffect} from 'react';
import styled from 'styled-components';
import theme from '../../../../UI/theme';
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

const Container = styled.div`
    height: 60px;
    canvas {
        max-width: 600px;
        min-width: 300px;

        @media only screen and (min-width: ${theme.screenSizes.medium}px) {
            min-width: 500px;
        }
    }
`

const chartData = {
    labels: [],
    datasets: [
      {
        label: 'Decibel',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        pointRadius: 0,
        tension: 0.5,
        fill: false,
        data: []
      }]
  };
  
  const options = {
    maintainAspectRatio: false,
    scales: {
        x: {
            display: false,
        },
        y: {
            display: false,
            suggestedMin: 30,
            suggestedMax: 50,
        }
    },
    plugins: {
        legend: {
            display: false
        }
    }
  }

const chartConfig = {
    type: 'line',
    data: chartData,
    options
}

const TimeLineGraph = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    chartConfig.data.datasets[0].data = props.data.map(e => parseInt(e));
    chartConfig.data.labels = props.data.map(e => parseInt(e));

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
        const newChartInstance = new Chart(chartContainer.current, chartConfig);
        setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <Container>
            <canvas ref={chartContainer} height={150}/>
        </Container>
    );
}

export default TimeLineGraph