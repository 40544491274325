import StyledIcon from "../StyledIcon";

const WaInput = props => {
    return <StyledIcon width="27" height="27" viewBox="0 0 27 27" fill="none" {...props}>
        <path d="M2.52344 13.2446L17.5234 13.2446" strokeWidth="2" strokeLinejoin="round" />
        <path d="M11.5234 7.24463L17.5234 13.2446L11.5234 19.2446" strokeWidth="2" strokeLinejoin="round" />
        <path d="M19.1234 25.3574H7.92344C4.40344 25.3574 1.52344 22.4774 1.52344 18.9574V7.75742C1.52344 4.23742 4.40344 1.35742 7.92344 1.35742H19.1234C22.6434 1.35742 25.5234 4.23742 25.5234 7.75742V18.9574C25.5234 22.4774 22.6434 25.3574 19.1234 25.3574Z" strokeWidth="2" strokeLinejoin="round" />
    </StyledIcon>
}

export default WaInput;