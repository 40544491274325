import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getUserInteractions } from "../../../scripts/userInteraction";
import NoOfUserInteractionsMinimizedGraph from "./graph/NoOfUserInteractionsMinimizedGraph";

const Container = styled.div`
    padding: 0 5px;
    max-height: 2em;
    aspect-ratio: 3;
`

const UIWeekGraphContainer = props => {
    const [userInteractions, setUserInteractions] = useState();
    var fromDate = DateTime.local().startOf('day').minus({ days: 6 }).toJSDate();
    var toDate = DateTime.local().endOf('day').toJSDate();

    useEffect(() => {
        getData();

        return () => {
            setUserInteractions();
        };
    }, [])

    const getData = async () => {
        fromDate = DateTime.local().startOf('day').minus({ days: 6 }).toJSDate();
        toDate = DateTime.local().endOf('day').toJSDate();

        if (props.customerId) {
            var updatedUserInteractions = await getUserInteractions(props.customerId, fromDate, toDate);
            setUserInteractions(updatedUserInteractions);
        }
    }

    return <Container>
        <NoOfUserInteractionsMinimizedGraph userInteractions={userInteractions} from={fromDate} to={toDate} />
    </Container>
}

export default UIWeekGraphContainer;