import React from 'react';
import styled from 'styled-components';
import heatmapTypes from '../heatmapTypes';
import Selector from '../../../UiComponents/Selector';

const Container = styled.div`
    margin-top: 10px;
`

const dataModes = [{
    key: heatmapTypes.dataModes.LIVE,
    display: 'Live'
}, {
    key: heatmapTypes.dataModes.HISTORY,
    display: 'History'
}, {
    key: heatmapTypes.dataModes.TREND,
    display: 'Trend'
}];

const DataModeSelector = props => {

    const selectModeHandler = (mode) => {
        props.setSelectedMode(mode.key);
    }

    const selectedItem = dataModes.find(mode => mode.key === props.selectedMode);

    return <Container>
        <Selector large items={dataModes} selectedItem={selectedItem} onClick={selectModeHandler}/>
    </Container>
}

export default DataModeSelector;