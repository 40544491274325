import { Chart } from "chart.js";
import { useEffect, useRef, useState } from "react";
import theme from "../../../UI/theme";
import { DateTime } from "luxon";
import { getDateTime } from "../../../scripts/log";
import { decibelWarningThreshold } from "../decibel/decibelInsightsUtils";

const chartData = {
    labels: [],
    datasets: [
        {
            label: 'Decibel',
            type: 'line',
            borderColor: theme.colors.crystalBlue,
            backgroundColor: theme.colors.crystalBlue,
            borderWidth: 2,
            tension: 0.5,
            pointRadius: 0,
            fill: false,
            data: []
        }
    ]
}

const options = {
    maintainAspectRatio: false,
    animation: {
        duration: 0,
    },
    scales: {
        x: {
            display: false,
            type: 'time',
            time: {
                unit: 'hour'
            }
        },
        y: {
            display: false,
            type: 'linear',
            // suggestedMin: 30,
            // suggestedMax: 50
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        }
    }
}

const chartConfig = {
    data: chartData,
    options
}

const thresholdColorGradientPlugin = (chart) => {
    const dataset = chart.data.datasets[0];
    const yScale = chart.scales['y'];
    const yPosOne = yScale.getPixelForValue(decibelWarningThreshold - 2);
    const yPosTwo = yScale.getPixelForValue(decibelWarningThreshold + 2);
    const thresholdColorStopOne = Math.min(Math.max(yPosOne / chart.height, 0), 1);
    const thresholdColorStopTwo = Math.min(Math.max(yPosTwo / chart.height, 0), 1);

    const gradientColor = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
    gradientColor.addColorStop(0, theme.colors.raspberryRed);
    gradientColor.addColorStop(thresholdColorStopTwo, theme.colors.raspberryRed);
    gradientColor.addColorStop(thresholdColorStopOne, theme.colors.crystalBlue);
    gradientColor.addColorStop(1, `${theme.colors.crystalBlue}`);

    dataset.borderColor = gradientColor;
}

const LiveDecibelChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    useEffect(() => {
        chartConfig.data.datasets[0].data = props.data?.map((value) => {
            return {
                x: DateTime.fromJSDate(getDateTime(value.time)),
                y: value.averageDecibel > 0 ? value.averageDecibel : null
            }
        })

        if (chartInstance) {
            chartInstance.update();
        }
    }, [props.data])

    useEffect(() => {
        if (chartContainer?.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(
                chartContainer.current,
                {
                    ...chartConfig,
                    plugins: [{
                        beforeDatasetsUpdate: thresholdColorGradientPlugin
                    }]
                });
            setChartInstance(newChartInstance);
        }
    }, [chartContainer])

    return (
        <canvas ref={chartContainer} />
    );
}

export default LiveDecibelChart;