import { Chart } from "chart.js";
import { useEffect, useRef, useState } from "react";
import theme from "../../../../../UI/theme";


const chartData = {
    labels: [],
    datasets: [
        {
            label: 'Jumps',
            type: 'scatter',
            borderColor: theme.colors.mellowYellow,
            backgroundColor: theme.colors.mellowYellow,
            order: 1,
            data: [],
        },
        {
            label: 'Mapping %',
            type: 'line',
            borderColor: `${theme.colors.blueMonday}`,
            backgroundColor: `${theme.colors.blueMonday}`,
            borderWidth: 2,
            pointRadius: 0,
            fill: false,
            order: 2,
            data: [],
        }]
}

const commonOptions = {
    maintainAspectRatio: false,
    animation: {
        duration: 0,
    }
}

const detailedOptions = {
    ...commonOptions,
    scales: {
        x: {
            display: true,
            type: 'linear',
            min: -80,
            suggestedMax: 20,
            title: {
                text: "Output meter db",
                display: true,
                color: theme.colors.textGray,
            },
            grid: {
                display: true,
                drawOnChartArea: false,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70,
                drawTicks: true,
                tickColor: theme.colors.darkSpace70
            },
            ticks: {
                color: theme.colors.textGray
            }
        },
        y: {
            display: true,
            type: 'linear',
            min: 0,
            suggestedMax: 70,
            title: {
                text: "Music db",
                display: true,
                color: theme.colors.textGray,
            },
            grid: {
                display: true,
                drawOnChartArea: false,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70,
                drawTicks: true,
                tickColor: theme.colors.darkSpace70
            },
            ticks: {
                color: theme.colors.textGray
            }
        }
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                color: theme.colors.textGray,
                boxWidth: 7,
                boxHeight: 7,
                useBorderRadius: true,
                borderRadius: 1,
            }
        },
        tooltip: {
            enabled: true
        }
    },
    pointHitRadius: 7,
}

const MeterMappingChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    let chartConfig;
    chartConfig = JSON.parse(JSON.stringify({ data: chartData, options: { ...detailedOptions } }));
    //chartConfig.data.datasets[2].hidden = false;

    const chartConfigRef = useRef(JSON.parse(JSON.stringify(chartConfig)));

    useEffect(() => {
        chartConfigRef.current.data.datasets[0].data = props.data?.meterJumps?.map(value => ({ x: value.estimates.meterDbHigh, y: value.estimates.musicDbHigh }));
        const mappingParams = props.data.meterMappingParams;
        if (mappingParams && mappingParams.slope && mappingParams.intercept) {
            const x_arr = [mappingParams.protocolMeterMin, mappingParams.linearMinX, 20];
            const y_arr = x_arr.map(x => mappingParams.slope * x + mappingParams.intercept);
            y_arr[0] = 0;
            chartConfigRef.current.data.datasets[1].data = y_arr.map((y, i) => ({ x: x_arr[i], y }));
        } else {
            chartConfigRef.current.data.datasets[1].data = [];
        }

        if (chartInstance) {
            chartInstance.update();
        }
    }, [props.data])

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfigRef.current);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <canvas ref={chartContainer} />
    );
}

export default MeterMappingChart;