import { useState } from "react";
import AnimateHeight from "react-animate-height";
import { BaseLevelContainer, BaseLevelHeaderRow, ButtonContainer, CalibrationCardHeader, CalibrationContainerCard, CalibrationMetric, ColumnContainer, HeaderRow, SectionContainer, SubHeader } from "../calibrationStyles";
import { FaChevronDown, FaChevronUp, FaCogs, FaSave } from "react-icons/fa";
import { MdReplay } from "react-icons/md";
import LiveChartContainer from "../charts/LiveChartContainer";
import { postUserInteraction } from "../../../../../scripts/userInteraction";
import { updateZoneCalibration } from "../../../../../scripts/zone";
import { userInteractionTypes } from "../../../../admin/customerDetails/userInteractionTypes";
import PanelButton from "../../../../panel/PanelButton";
import theme from "../../../../../UI/theme";
import BaseLevelControls from "./BaseLevelControls";
import LiveMetrics from "./LiveMetrics";
import { CalibrationHistoryButton } from "./CalibrationHistory";
import { addCalibrationHistoryRecord } from "../../../../../scripts/calibrationHistory";
import VolumeControls from "./VolumeControls";

const defaultCalibrationPoints = [
    { sysvol: 40, measuredDb: 50 },
    { sysvol: 60, measuredDb: 60 },
    { sysvol: 80, measuredDb: 70 }
]

const ZoneCalibrationItem = props => {
    const savedCalibrationPoints = props?.zone?.calibrationPoints?.points ?? [...defaultCalibrationPoints];
    const [expanded, setExpanded] = useState(false);
    const [advanced, setAdvanced] = useState(false);
    const [baseLevelExpanded, setBaseLevelExpanded] = useState(false);
    const [hasChanged, setHasChanged] = useState(false);
    const [newCalibrationPoints, setNewCalibrationPoints] = useState(props?.zone?.calibrationPoints?.points ?? [...defaultCalibrationPoints]);
    const [averageFilteredDb, setAverageFilteredDb] = useState();

    const vibeIncrement = (6 * (savedCalibrationPoints?.[2]?.sysvol - savedCalibrationPoints?.[0]?.sysvol) / 40) || null;

    const handleReset = () => {
        setNewCalibrationPoints(props?.zone?.calibrationPoints?.points ?? [...defaultCalibrationPoints]);
        setHasChanged(false);
    }

    const handleSave = async () => {
        const oldCalibrationPoints = JSON.parse(JSON.stringify(props.zone.calibrationPoints));
        let result = await updateZoneCalibration({
            zoneId: props.zone?.zoneId,
            hubId: props.zone?.hubId,
            calibrationPoints: { points: newCalibrationPoints, calibrationId: props?.zone?.calibrationPoints?.calibrationId }
        });

        if (result) {
            setHasChanged(false);
            await addCalibrationHistoryRecord(props.zone.customerId, {
                zoneId: props.zone.zoneId,
                customerId: props.zone.customerId,
                calibrationPoints: newCalibrationPoints
            })
            await postUserInteraction(props.zone.customerId, {
                zoneId: props.zone.zoneId,
                setting: userInteractionTypes.calibrate.key,
                payload: {
                    from: {
                        calibrationPoints: oldCalibrationPoints
                    },
                    to: {
                        calibrationPoints: {
                            points: newCalibrationPoints,
                            calibrationId: props?.zone?.calibrationPoints?.calibrationId
                        }
                    }
                }
            })
        }
    }

    return <CalibrationContainerCard>
        <HeaderRow onClick={() => setExpanded(prev => !prev)}>
            <CalibrationCardHeader>{props?.zone?.zoneName}</CalibrationCardHeader>

            {newCalibrationPoints?.map((point, index) => {
                const header = index === 0 ? 'Low' : index === 1 ? 'Med' : index === 2 ? 'High' : '';
                const pointVibeIncrement = Math.round(((point?.sysvol - savedCalibrationPoints[index]?.sysvol) / vibeIncrement) * 100) / 100;

                return <CalibrationMetric key={index} vibeIncrement={pointVibeIncrement ? `${pointVibeIncrement > 0 ? '+' : ''}${pointVibeIncrement?.toFixed(2)} vb` : ''}>
                    <label>{header}</label>
                    {props.zone?.calibrationPoints?.points?.[index]?.sysvol !== undefined ?
                        <label style={{ color: `${theme.colors.yellowFever}` }}>{point?.sysvol?.toFixed(2)}</label>
                        : <label>-</label>}
                </CalibrationMetric>
            })}

            <CalibrationMetric>
                <label>Base</label>
                {props.zone?.calibrationPoints?.points?.[0]?.measuredDb !== undefined ?
                    <label style={{ color: `${theme.colors.purplePleasure}` }}>{newCalibrationPoints[0]?.measuredDb?.toFixed(2)}</label>
                    : <label>-</label>}
            </CalibrationMetric>

            {expanded === false ? <FaChevronDown /> : <FaChevronUp />}
        </HeaderRow>

        <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
            <ColumnContainer>
                <SectionContainer>
                    <SubHeader>Volume levels</SubHeader>

                    <VolumeControls
                        zone={props.zone}
                        zoneLive={props.zoneLive}
                        newCalibrationPoints={newCalibrationPoints}
                        setNewCalibrationPoints={setNewCalibrationPoints}
                        savedCalibrationPoints={savedCalibrationPoints}
                        hasChanged={hasChanged}
                        setHasChanged={setHasChanged}
                        advanced={advanced}
                    />

                </SectionContainer>

                <SectionContainer>
                    <BaseLevelHeaderRow onClick={() => setBaseLevelExpanded(prev => !prev)}>
                        <SubHeader>Base ambience decibel</SubHeader>
                        {baseLevelExpanded === false ? <FaChevronDown /> : <FaChevronUp />}
                    </BaseLevelHeaderRow>

                    <AnimateHeight duration={500} height={baseLevelExpanded ? 'auto' : 0}>
                        <BaseLevelContainer>
                            <LiveMetrics
                                newCalibrationPoints={newCalibrationPoints}
                                savedCalibrationPoints={savedCalibrationPoints}
                                zoneLive={props?.zoneLive}
                                averageFilteredDb={averageFilteredDb}
                            />

                            <LiveChartContainer
                                zone={props?.zone}
                                zoneLive={props?.zoneLive}
                                newCalibrationPoints={newCalibrationPoints}
                                savedCalibrationPoints={savedCalibrationPoints}
                                averageFilteredDb={averageFilteredDb}
                                setAverageFilteredDb={setAverageFilteredDb}
                            />

                            <BaseLevelControls
                                zone={props.zone}
                                setNewCalibrationPoints={setNewCalibrationPoints}
                                newCalibrationPoints={newCalibrationPoints}
                                setHasChanged={setHasChanged} />
                        </BaseLevelContainer>

                    </AnimateHeight>
                </SectionContainer>

                <ButtonContainer>
                    <PanelButton disabled={hasChanged === false} onClick={() => handleReset()}>
                        <MdReplay />
                        Reset
                    </PanelButton>

                    <PanelButton disabled={hasChanged === false} onClick={() => handleSave()}>
                        <FaSave />
                        Save
                    </PanelButton>

                    <PanelButton deactivated={advanced === false} onClick={() => setAdvanced(prev => !prev)}>
                        <FaCogs />
                        <label>Adv.</label>
                    </PanelButton>

                    <CalibrationHistoryButton
                        zone={props.zone}
                        setNewCalibrationPoints={setNewCalibrationPoints}
                        setHasChanged={setHasChanged}
                    />
                </ButtonContainer>
            </ColumnContainer>
        </AnimateHeight>
    </CalibrationContainerCard>
}

export default ZoneCalibrationItem;