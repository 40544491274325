import { useState } from "react";
import Button from "../../../UiComponents/Button"
import Modal from "../../../UiComponents/Modal";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../panel/ModalStyles";

const DeletePermissionButton = ({ removePermissionObject }) => {
    const [showModal, setShowModal] = useState(false);

    const onDeleteClick = () => {
        removePermissionObject();
        setShowModal(false);
    }

    return <>
        <Button small tertiary onClick={() => setShowModal(true)}>
            Remove
        </Button>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Delete permission?</ModalHeader>
                <ModalText>
                    This will remove the user's access to this venue
                </ModalText>
                <ButtonRowContainer>
                    <Button primary onClick={onDeleteClick}>Delete</Button>
                    <Button secondary onClick={() => setShowModal(false)}>Cancel</Button>
                </ButtonRowContainer>
            </ModalContainer>
        </Modal>
    </>
}

export default DeletePermissionButton;