import React, { useState, useEffect } from 'react';
import styled, {css} from 'styled-components';
import {FaEdit, FaCheck, FaRegTrashAlt} from 'react-icons/fa';
import heatmapTypes from './heatmapTypes';
import theme from '../../../UI/theme';


const ListItem = styled.li`
    padding: 10px 6px;
    border-bottom: 1px solid #efefef;
    min-height: 30px;
    display: flex;
    align-items: center;

    &:hover{
        cursor: pointer;
        background-color: ${theme.colors.grayHover};
    }

    ${props => props.selected && css`
        background-color: ${theme.colors.grayHover};
    `}

    &:last-child {
        border-bottom: none;
    }
`

const WallName = styled.span`
    font-weight: bold;
    margin-right: 20px;
`

const WallNameContainer = styled.div`
    display: flex;
    align-items: center;
`

const IconButton = styled.button`
    background-color: transparent;
    color: ${theme.colors.textGray};
    border: none;
    font-size: 14px;
    font-weight: bold;
    padding: 6px 10px;

    &:hover {
        cursor: pointer;
    }
    span {
        margin-right: 8px;
    }
`

const ButtonContainer = styled.div`
    display: flex;
`

const WallBankItem = props => {
    const wall = props.wall;

    const selectHandler = () => {
        props.selectWall(wall, true);
    };

    const deselectHandler = () => {
        props.deselectWall(wall, true, props.isSelected);
    };

    const clickHandler = () => {
        if (!props.isSelected) {
            props.deselectAllWalls();
            props.selectWall(wall, false);
        } else {
            props.deselectAllWalls();
        }
    };

    return <ListItem onMouseOver={selectHandler} onMouseLeave={deselectHandler} onClick={clickHandler} selected={props.isSelected}>
        <WallNameContainer>
            {props.symbol ? <>
                {props.symbol}
            </> : <></>}
            <WallName>{wall.type === heatmapTypes.elementTypes.OUTERWALL ? "Outer wall" : "Inner wall"}</WallName>
        </WallNameContainer>
        {props.isSelected ? <>
            <ButtonContainer>
                {wall.type === heatmapTypes.elementTypes.INNERWALL ? <>
                    <IconButton onClick={() => props.completeWallHandler(wall.floorElementId)}><FaCheck /></IconButton>
                    <IconButton onClick={() => props.deleteWallHandler(wall.floorElementId)}><FaRegTrashAlt /></IconButton>
                </> : <></>}
            </ButtonContainer>
        </> : <>
            {/* 
            <IconButton onClick={selectHandler} primary><FaEdit /></IconButton>
            */}
        </>}
        
    </ListItem>
}

export default WallBankItem;