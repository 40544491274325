import errorColumns from "./errorColumns";
import errorPriorities from "./errorPriorities";

const getObjectsWithErrors = (objects, errorTypes) => {
    var objectsWithErrors = [];
    objects.forEach(object => {
        var filteredErrors = object.errors.filter(objectError => errorTypes.includes(objectError.name));
        if (filteredErrors.length) {
            objectsWithErrors.push({
                ...object,
                errors: filteredErrors
            })
        }
    })
    return objectsWithErrors;
}

const getObjectsWithPriorityErrors = (objects, priorities) => {
    var objectsWithErrors = objects.filter(object => object.errors.filter(objectError => priorities.includes(objectError.priority)).length);
    return objectsWithErrors;
}

const getPriorityColor = (priorityValue) => {
    var priority = Object.values(errorPriorities).find(pri => pri.value === priorityValue);
    return priority?.color;
}
const getHighestPriorityFromObjects = (objects) => {
    var highestPriority = 4;
    objects.forEach(object => {
        var highestPriorityFromErrors = getHighestPriorityFromErrors(object.errors);
        if (highestPriorityFromErrors < highestPriority) {
            highestPriority = highestPriorityFromErrors
        }
    });
    return highestPriority;
}

const getHighestPriorityFromErrors = (errors) => {
    var highestPriority = 4;
    errors.forEach(error => {
        if (error.priority && error.priority < highestPriority) {
            highestPriority = error.priority;
        }
    })
    return highestPriority;
}

const getErrorTypesFromFilter = (filter, errorColumns) => {
    var filterKeys = Object.keys(filter).filter(key => filter[key] === true);
    var filterErrorColumns = errorColumns.filter(col => filterKeys.includes(col.key));

    var filterErrorTypes = [];
    filterErrorColumns.forEach(col => {
        filterErrorTypes = [...filterErrorTypes, ...col.errorTypes]
    });
    return filterErrorTypes;
}

const applyErrorTypeFilter = (array, filter) => {
    if (array?.length && filter) {
        var filteredArray = [];
        var filterErrorTypes = getErrorTypesFromFilter(filter, errorColumns);

        if (filterErrorTypes.length) {
            array.forEach(element => {
                var objectsWithErrors = getObjectsWithErrors(element.objects, filterErrorTypes);
                
                if (objectsWithErrors.length) {
                    filteredArray.push(element);
                }
            })
            return filteredArray;
        } else {
            return array;
        }
    } else {
        return array;
    }
}

const applyPriorityFilter = (array, priorityFilter) => {
    if (priorityFilter.length) {
        var filteredArray = [];
        array.forEach(element => {
            var objectsWithErrors = getObjectsWithPriorityErrors(element.objects, priorityFilter);
            if (objectsWithErrors.length) {
                filteredArray.push(element);
            }
        });
        return filteredArray;
    } else {
        return array;
    }
}


export {
    getObjectsWithErrors,
    getErrorTypesFromFilter,
    applyErrorTypeFilter,
    applyPriorityFilter,
    getHighestPriorityFromObjects,
    getHighestPriorityFromErrors,
    getPriorityColor
}