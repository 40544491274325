import React from 'react';
import { FaTimes } from 'react-icons/fa';
import Button from '../Button';
import styled from 'styled-components';

const CloseButton = props => {

    return <Button {...props}>
        <FaTimes/> 
    </Button>
}

export default CloseButton;
