import React, { useEffect } from 'react';
import Button from '../../../../UiComponents/Button';
import styled from 'styled-components';
import { blinkSpeakers, singleSpeakrSystems } from '../speakerUtils';
import theme from '../../../../../UI/theme';

const SPEAKER_BLINK_INTERVAL = 20000;


const StyledSpeakerButton = styled(Button)`
    width: 100%;
    background-color: ${props => props.isMuted ? theme.colors.greenEnergy : theme.colors.secondary};
    color: ${props => props.isMuted ? theme.colors.darkSpace : theme.colors.whiteSpace};
    opacity: ${props => props.isDisabled ? 0.4 : 1};
`
const AnimatedSpeakerButton = styled(Button)`
    width: 100%;
    background-color: ${theme.colors.greenEnergy};
    color: ${theme.colors.darkSpace};

    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
    animation: blinker 1s linear infinite;
        
`

const SpeakerBlinkButton = ({ speaker, hubs }) => {

    const [blinking, setBlinking] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);

    useEffect( () => {
        if (speaker.type === 'SONOS') setIsDisabled(speaker.isMuted);
    }, [speaker.isMuted]);

    const blink = () => {
        if (isDisabled) return;
        setBlinking(true);
        blinkSpeakers([speaker], hubs);
        setTimeout(() => { setBlinking(false) }, SPEAKER_BLINK_INTERVAL);
    };

    return (<>
        {blinking ? 
            <AnimatedSpeakerButton secondary small >
                Blinking
            </AnimatedSpeakerButton>
        :
            <StyledSpeakerButton secondary small isDisabled={isDisabled} onClick={blink} >
                Blink
            </StyledSpeakerButton>
    }
    </>);
};

export default SpeakerBlinkButton;