import { Path, Point, Layer } from "paper";
import LayerController from "./LayerController";
import heatmapTypes from "../container/heatmapTypes";
import theme from "../../../UI/theme";

var noGridLines = 100;
var canvasSize = new Point(0,0);

const GRID_COLOR = theme.colors.darkSpace50;

class GridLayerController extends LayerController {
    constructor(canSize) {
        super();
        canvasSize = canSize;
        this.layer = new Layer();
        this.gridSpace = 1;
        this.layer.name = 'Grid layer';
    }

    onModeChange(modeName) {
        if (modeName !== heatmapTypes.modes.SETUP) {
            this.clear();
        } else if (modeName === heatmapTypes.modes.SETUP) {
            this.draw();
        }
    }

    draw() {
        this.layer.activate();
        this.layer.removeChildren();

        var start = new Point(-this.gridSpace*Math.round(noGridLines/2),-this.gridSpace*Math.round(noGridLines/2))

        var horizontalGridLine = new Path();
        horizontalGridLine.strokeColor = GRID_COLOR;
        horizontalGridLine.strokeWidth = 0.1;
        horizontalGridLine.moveTo(start);
        horizontalGridLine.lineTo(start.add([noGridLines*this.gridSpace, 0]));

        var verticalGridLine = new Path();
        verticalGridLine.strokeColor = GRID_COLOR;
        verticalGridLine.strokeWidth = 0.1;
        verticalGridLine.moveTo(start);
        verticalGridLine.lineTo(start.add([0, noGridLines*this.gridSpace]));
        for (var i = 0; i <= noGridLines; i++) {
            var copyHorizontal = horizontalGridLine.clone();
            copyHorizontal.position.y += this.gridSpace * i;

            var copyVertical = verticalGridLine.clone();
            copyVertical.position.x += this.gridSpace * i;
        }
    }

    clear() {
        this.layer.removeChildren();
    }
}


export default GridLayerController;