
const sybGraphQl = {
    mutation: {
        login: (email, password) => {
            return {
                "query": `
                mutation {
                    loginUser(input: {email: "${email}", password: "${password}"}) {
                        token
                        refreshToken
                    }
                }
            `
            }
        },
        play: (id) => {
            return {
                "query": `
                mutation {
                    play (input: {soundZone: "${id}"}){
                        status
                    }
                }
            `
            }
        },
        pause: (id) => {
            return {
                "query": `
                mutation {
                    pause (input: {soundZone: "${id}"}){
                        status
                    }
                }
            `
            }
        },
        nextSong: (id) => {
            return {
                "query": `
                mutation {
                    skipTrack(input: {soundZone: "${id}"}){
                        status
                    }
                }
            `
            }
        },
        queuePlaylist: (id, playlistId) => {
            return {
                "query": `
                mutation {
                    setPlayFrom(input: {soundZone: "${id}", source: "${playlistId}"}){
                        playFrom {
                            __typename
                            ... on Playlist {
                                id
                                name
                            }
                            ... on Schedule {
                                id
                                name
                            }
                        }
                    }
                }
            `
            }
        },
        setPlayFrom: (id, playFromId) => {
            return {
                "query": `
                mutation {
                    soundZoneAssignSource(input: {soundZones: "${[id]}", source: "${playFromId}", immediate: ${true}}){
                        soundZones
                    }
                }
            `
            }
        },
        updateAuthorization: (refreshToken) => {
            return {
                "query": `
                    mutation {
                        refreshLogin(input:{refreshToken: "${refreshToken}"}){
                            token
                            refreshToken
                        }
                    }
                `
            }
        }
    },
    query: {
        authentication: (id) => {
            return {
                "query": `
                query {
                    soundZone(id: "${id}") {
                        id
                    }
                }
            `
            }
        },
        connection: (id) => {
            return {
                "query": `
                query {
                    soundZone(id: "${id}") {
                        online
                        isPaired
                    }
                }
            `
            }
        },
        state: (id) => {
            return {
                "query": `
                query {
                    soundZone(id: "${id}") {
                        playback {
                            state
                        }
                    }
                }
            `
            }
        },
        getVolume: (id) => {
            return {
                "query": `
                query {
                    soundZone(id: "${id}") {
                        playback {
                            volume
                        }
                    }
                }
            `
            }
        },
        setVolume: (id, volume) => {
            return {
                "query": `
                mutation {
                    setVolume(input: {soundZone: "${id}", volume: ${volume}}){
                        status
                    }
                }
            `
            }
        },
        getPlayingMusic: (id) => {
            return {
                "query": `
                query {
                    nowPlaying(soundZone: "${id}") {
                        playFrom {
                            __typename
                            ... on Playlist {
                                id
                                name
                            }
                            ... on Schedule {
                                id
                                name
                            }
                        }
                        track {
                            title
                            album {
                                title
                                image {
                                    url
                                }
                            }
                            artists {
                                name
                            }
                            display {
                                colors {
                                    primary {
                                        hex
                                    }
                                    secondary {
                                        hex
                                    }
                                }
                            }
                        }
                    }
                }
            `
            }
        },
        getAvailablePlaylists: (id) => {
            return {
                "query": `
                query {
                    soundZone(id: "${id}") {
                        account {
                            musicLibrary {
                                playlists (first: 1000) {
                                    edges{
                                        node {
                                            name
                                            id
                                            display {
                                                image {
                                                    placeholder
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `
            }
        },
        getAvailableSchedules: (id) => {
            return {
                "query": `
                query {
                    soundZone(id: "${id}") {
                        account {
                            musicLibrary {
                                schedules (first: 1000) {
                                    edges{
                                        node {
                                            name
                                            id
                                            display {
                                                image {
                                                    placeholder
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            `
            }
        },
        getZones: () => {
            return {
                "query": `
                query {
                    me {
                        ...on User {
                            accounts(first: 10) {
                            edges {
                                node {
                                    businessName
                                    locations(first: 10) {
                                        edges {
                                            node {
                                                name
                                                address
                                                soundZones(first: 100) {
                                                    edges {
                                                        node {
                                                            id
                                                            name
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    }
                }
            `
            }
        }
    },
    subscription: {
        playback: (id) => {
            return (`subscription PlaybackSubscripion {
                playbackUpdate(input: {soundZone: "${id}"}) {
                  playback {
                    state
                    volume
                  }
                }
              }`)
        },
        nowPlaying: (id) => {
            return (`subscription NowPlayingSubscription {
            nowPlayingUpdate(
              input: {soundZone: "${id}"}
            ) {
              nowPlaying {
                track {
                  album {
                    title
                    image {
                      url
                      width
                      height
                    }
                  }
                  title
                  artists {
                    name
                  }
                  display {
                    colors {
                      primary {
                        hex
                      }
                      secondary {
                        hex
                      }
                    }
                  }
                }
                playFrom {
                  ... on Playlist {
                    id
                    name
                  }
                  ... on Schedule {
                    id
                    name
                  }
                }
              }
            }
          }`)
        }
    }
}

export {
    sybGraphQl
}