import React from 'react';
import { FaPlusCircle } from 'react-icons/fa';
import styled, {css} from 'styled-components';
import theme from '../../../UI/theme';

const BankItemName = styled.span`
    font-weight: bold;
    margin-right: 30px;
    /* color: ${theme.colors.textGray}; */
`

const PlaceButton = styled.button`
    background-color: transparent;
    color: ${props => props.primary ? theme.colors.greenEnergy : 'darkgray'};
    border: none;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px;

    &:hover {
        cursor:pointer;
    }
`

const ListItem = styled.li`
    padding: 10px 6px;
    border-bottom: 1px solid ${theme.colors.darkSpace70};
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover{
        cursor: pointer;
        background-color: ${theme.colors.grayHover};
    }

    ${props => props.isMarked && css`
        background-color: #d8f0ff;
    `}
`

const SVGPreviewImg = styled.img`
    width: 30px;
    height: 30px;
    path {
        fill: white;
    }
`



const FurnitureBankItem = props => {

    const addFurnitureHandler = (event) => {
        event.stopPropagation();
        props.placeFurniture(props.furniture)
    }

    return <ListItem>
        <div>
            <div>
                <BankItemName>
                    {props.displayName}
                </BankItemName>
            </div>
            <div>
                <SVGPreviewImg src={props.furniture.source} alt={props.furniture.displayName}/>
            </div>
        </div>

        <PlaceButton onClick={(event) => addFurnitureHandler(event)} primary><FaPlusCircle /></PlaceButton>
    </ListItem>
}

export default FurnitureBankItem;