import React, { useState, useEffect } from 'react';
import SensorTable from './SensorTable';
import HubTable from './HubTable';
import CustomEvent from './CustomEvent';
import { postEvent } from '../../../scripts/event';
import Modal from '../../UiComponents/Modal';
import InputField, { InputFieldContainer } from '../../UiComponents/InputField';
import Button from '../../UiComponents/Button';
import styled from 'styled-components';
import theme from '../../../UI/theme';
import { FaRedo } from 'react-icons/fa';
import { MT10Container } from '../../settings/venue/device/DeviceStyles';
import { getLocalAppVersion } from '../../../scripts/app';
import LocalAppVersion from './LocalAppVersion';
import { ButtonRowContainer, ModalContainer, ModalText } from '../../panel/ModalStyles';

const TableContainer = styled.div`
    font-size: ${theme.fontSize.small};
    td {
        padding-right: 6px;
    }
`

const DeviceTableContainer = props => {
    const [searchString, setSearchString] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [selectedIds, setSelectedIds] = useState([]);
    const [localAppVersion, setLocalAppVersion] = useState();

    useEffect(() => {
        getData()
    }, []);


    const getData = async () => {
        try {
            const res = await getLocalAppVersion();
            setLocalAppVersion(res);
        } catch (err) {

        }
    }

    const idField = props.deviceType === 'HUB' ? 'hubId' : 'sensorId'

    function selectDeviceHandler(deviceId) {
        if (isSelected(deviceId)) {
            setSelectedIds(selectedIds.filter(id => id !== deviceId));
        } else {
            setSelectedIds([...selectedIds, deviceId]);
        }
    }

    function selectAll(selectedState) {
        if (selectedState) {
            setSelectedIds(props.devices.map(device => device[idField]));
        } else {
            setSelectedIds([]);
        }
    }

    function isSelected(deviceId) {
        return selectedIds.includes(deviceId);
    }

    function performAction(action) {
        const selectedDevices = getSelectedDevices();
        setModalContent(<>
            <ModalText>This is the action: {action}</ModalText>
            <ModalText>Number of devices to update: {selectedDevices.length}</ModalText>
            <ModalText>Are you sure that you want to perform the action?</ModalText>
            <ButtonRowContainer>
                <Button onClick={() => handleEvent(action)} primary>Confirm</Button>
                <Button onClick={() => setShowModal(false)} secondary>Cancel</Button>
            </ButtonRowContainer>
        </>
        )
        setShowModal(true);
    }

    function performCustomAction() {
        const selectedDevices = getSelectedDevices();
        setModalContent(<CustomEvent
            nDevices={selectedDevices.length}
            handleEvent={handleEvent}
            cancel={() => setShowModal(false)}
        />)
        setShowModal(true);
    }

    function getSelectedDevices() {
        return props.devices.filter(device => selectedIds.includes(device[idField]));
    }

    function handleEvent(action, payload) {
        const selectedDevices = getSelectedDevices();
        var command = action;
        selectedDevices.forEach(device => {
            postEvent(device[idField], null, command, payload);
        });
        setShowModal(false);
    }


    const filterDevices = (search) => {
        var searchFields = ['customerId'];
        return props.devices.filter(device => {
            var contains = false;
            searchFields.forEach(field => {
                if (device[field] && device[field].toString().indexOf(search) > -1) {
                    contains = true;
                }
            });
            return contains;
        });
    }

    async function refresh() {
        await props.refreshDevices();
    }

    const filteredDevices = filterDevices(searchString);

    return <>
        <div >
            <InputFieldContainer>
                <label>Search by customer id</label><br />
                <InputField small value={searchString} onChange={e => setSearchString(e.target.value)} />
            </InputFieldContainer>
        </div>
        {localAppVersion ? <>
            <LocalAppVersion localAppVersion={localAppVersion} />
        </> : <></>}

        <ButtonRowContainer>
            <Button onClick={() => performAction('REBOOT')} secondary small>Reboot</Button>
            {props.deviceType === 'HUB' ? <>
                <Button onClick={() => performAction('PULL_GIT')} secondary small>Pull Git</Button>
                <Button onClick={() => performAction('RESTART_APP')} secondary small>Restart app</Button>
                <Button onClick={() => performAction('UPDATE_LOCAL_APP')} secondary small>Update local app</Button>
                <Button onClick={() => performAction('NPM_INSTALL')} secondary small>Npm install</Button>
                <Button onClick={() => performAction('REBOOT_HAT')} secondary small>Reboot Hat</Button>
                <Button onClick={() => performAction('START_IMAGE_WRITER_HUB')} secondary small>Write LG image</Button>
                <Button onClick={() => performAction('START_IMAGE_WRITER_SENSOR')} secondary small>Write Sensor image</Button>
            </> : <></>}
            <Button onClick={() => performCustomAction()} secondary small>Custom event</Button>
        </ButtonRowContainer>

        <MT10Container>
            <Button tertiary small icon onClick={refresh}>
                <span><FaRedo /></span>
                <label>Refresh table</label>
            </Button>
        </MT10Container>

        <TableContainer>
            {props.deviceType === 'HUB' ? <>
                <HubTable devices={filteredDevices} isSelected={isSelected} selectAll={selectAll} selectHandler={selectDeviceHandler} />
            </> : <>
                <SensorTable sensors={filteredDevices} isSelected={isSelected} selectAll={selectAll} selectHandler={selectDeviceHandler} />
            </>}
        </TableContainer>


        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                {modalContent}
            </ModalContainer>
        </Modal>
    </>


}

export default DeviceTableContainer;