import styled from "styled-components"
import theme from "../../../UI/theme"
import { IconPath, wPath } from "../IconLogoStructure"

const WavedSvg = styled.svg`
    width: ${props => props.width || '20px'};
    height: ${props => props.height || '20px'};
    aspect-ratio: 1;
`

const WavedPath = styled(IconPath)`
    stroke: ${props => props.stroke || theme.colors.greenEnergy};
`

const WavedIcon = props => {
    return <WavedSvg width={props.width} height={props.height} viewBox="0 0 80 80">
        <WavedPath d={wPath} stroke={props.stroke} />
    </WavedSvg>
}

export default WavedIcon;