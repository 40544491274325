import axios from 'axios';
import { getHostname } from './server/server';

const hostname = getHostname();
const path = '/v2/musicPlayer';

async function getMusicPlayers(customerId) {
    const res = await axios.get(`${hostname}${path}/musicPlayers?customerId=${customerId}`);
    return res.data;
}

async function updateMusicPlayer(musicPlayer) {
    const res = await axios.put(`${hostname}${path}`, musicPlayer);
    return res.data;
}

async function addMusicPlayer(musicPlayer) {
    const res = await axios.post(`${hostname}${path}`, musicPlayer);
    return res.data;
}

async function deleteMusicPlayer(musicPlayerId) {
    const res = await axios.delete(`${hostname}${path}?musicPlayerId=${musicPlayerId}`);
    return res.data;
}

async function executeSYB(customerId, musicPlayerId, query, config) {
    const res = await axios.post(`${hostname}${path}/executeSYB`, { customerId, musicPlayerId, query, config });
    return res.data;
}

export {
    getMusicPlayers,
    updateMusicPlayer,
    addMusicPlayer,
    deleteMusicPlayer,
    executeSYB
}