import { useState } from "react";
import { FaUserNinja } from "react-icons/fa";
import theme from "../../../UI/theme";
import Button from "../../UiComponents/Button";
import ErrorObjectDetails from "./ErrorObjectDetails";
import { getHighestPriorityFromErrors, getHighestPriorityFromObjects, getObjectsWithErrors, getPriorityColor } from "./errorUtils";
import { DetailsRow, DetailsContainer, ErrorText, TableRow, Indicator } from "./SupportStyles";

const SupportTableRow = props => {
    const [expanded, setExpanded] = useState(false);
    const isOpen = props.isOpen;

    return <>
        <TableRow onClick={(e) => { setExpanded(prev => { return !prev; }); e.stopPropagation(); }}>
            <td>&nbsp;{props.customerId}</td>
            <td>&nbsp;<Indicator color={isOpen === true ? theme.colors.greenEnergy : isOpen === false ? theme.colors.raspberryRed : theme.colors.textGray} /> {props.companyName}</td>
            {props.errorColumns.map((column, index) => {
                var totalCount = props.totalCount[column.objectType];
                var objectsWithErrors = getObjectsWithErrors(props.objects, column.errorTypes);
                var highestPriority = getHighestPriorityFromObjects(objectsWithErrors);
                var color = getPriorityColor(highestPriority);

                return <td key={index}>
                    {objectsWithErrors.length > 0 ?
                        <ErrorText color={color}>
                            {`${objectsWithErrors.length} / ${totalCount}`}
                        </ErrorText>
                        : <></>}
                </td>
            })}

            <td><Button style={{ width: '100%' }} primary onClick={() => { props.setImpersonation(props.customerId); }}><FaUserNinja /></Button></td>
        </TableRow>
        {
            expanded ? <RowDetails {...props} />
                : <></>
        }
    </>
}


export default SupportTableRow;

const RowDetails = props => {

    return <DetailsRow>
        <td></td>
        <td></td>
        {props.errorColumns.map((column, index) => {
            var objectsWithErrors = getObjectsWithErrors(props.objects, column.errorTypes);

            return <td key={index}>
                {objectsWithErrors.length ? <>
                    <DetailsContainer>
                        {objectsWithErrors.map((object, index) => {
                            var priority = getHighestPriorityFromErrors(object.errors);
                            var color = getPriorityColor(priority);
                            return <ErrorObjectDetails
                                key={index}
                                object={object}
                                color={color}
                                column={column}
                                goToSensorDetail={props.goToSensorDetail}
                                customerId={props.customerId} />
                        })}
                    </DetailsContainer>
                </> : <></>}
            </td>
        })}
        <td></td>
    </DetailsRow>
}


