import { useState } from "react";
import styled, { css } from "styled-components";
import { BsThreeDots } from 'react-icons/bs';
import theme from "../../../UI/theme";
import { ListActionButton } from "../../installation/table/styles/ActionStyles";
import Overlay from "../Overlay";

const MoreButtonContainer = styled.div`
    position: relative;

    >button {
        ${props => props.active && css`
            background-color: ${theme.colors.darkSpace80};
        `}
    }
`

const ActionListContainer = styled.div`
    position: absolute;
    left: -150px;
    width: 200px;
    background-color: ${theme.colors.dropdownGray};
    border-radius: 4px;
    z-index: 20;
    margin-top: 4px;
    
    >button {
        font-size: ${theme.fontSize.small};
        padding: 20px;
        border: none;
        width: 100%;
        background-color: transparent;
        text-align: left;

        &:hover {
            background-color: ${theme.colors.darkSpace};
        }
    }
`

const MoreActionsButton = ({ actions, disabled }) => {
    const [showMore, setShowMore] = useState(false);

    return <MoreButtonContainer active={showMore} >
        <ListActionButton onClick={() => setShowMore(true)} disabled={disabled}>
            <BsThreeDots />
            <label>More</label>
        </ListActionButton>

        {showMore ? <>
            <MoreActionsPopup
                setShowMore={setShowMore}
                actions={actions}
            />
        </> : <></>}
    </MoreButtonContainer>
}


const MoreActionsPopup = props => {
    return <>
        <ActionListContainer>
            {props.actions?.map(action => action.render())}
        </ActionListContainer>
        <Overlay onClick={() => props.setShowMore(false)} />
    </>
}

export default MoreActionsButton;