import Socket from "../../components/websocket/socket";
import { getUuid, isNumber } from "../common";
import HubEventHandler from "../subscription/HubEventHandler";
import SubscriptionController from "../subscription/SubscriptionController";
import { isLocalApp, serverIsReachable } from "./server";

class ServerConnection {
    constructor() {
        this.socket = new Socket(this);
        this.subscriptionController = new SubscriptionController(this);
        this.statusHooks = [];
        this.customerIdSynced = false;
        this.hubEventHandler = new HubEventHandler();
        this.isLocal = isLocalApp();
        this.startHealthChecker();
    }

    socketCallback = (event, data) => {
        switch (event) {
            case 'update':
                this.subscriptionController.handleUpdate(data.type, data.id);
                break;
            case 'event_response':
                this.hubEventHandler.handleResponse(data);
                break;
            case 'open':
                this.onSocketOpen();
                break;
            case 'close':
                this.onSocketClose();
                break;
            default:
                break;
        }
    }

    startHealthChecker() {
        this.counter = 0;
        clearInterval(this.healthCheckInterval);

        const healthCheck = async () => {
            clearTimeout(this.healthCheckTimeout);
            if (!await this.socket.isReachable()) {
                if (!await serverIsReachable()) {
                    this.setServerStatus(false);
                } else {
                    this.refreshSocketConnection();
                }
            }
            this.healthCheckTimeout = setTimeout(healthCheck, 5000);
        }
        setTimeout(healthCheck, 5000);
    }

    async onSocketClose() {
        const reachable = await serverIsReachable();
        this.customerIdSynced = false;
        if (!reachable) {
            this.setServerStatus(false);
        }
    }

    onSocketOpen() {
        this.setServerStatus(true);
    }

    setServerStatus(status) {
        this.serverStatus = status;
        this.statusHooks.forEach(statusHook => {
            statusHook.statusSetter(status);
        });
    }

    async setCustomerId(customerId) {
        if (isNaN(parseInt(customerId))) {
            return;
        }
        this.customerId = parseInt(customerId);
        this.refreshSocketConnection();
        this.refreshSubscriptions();
    }

    async refreshSocketConnection() {
        if (!this.disableSocket && (isNumber(this.customerId) || this.isLocal)) {

            if (!await this.socket.isReachable()) {
                await this.socket.connect();
            }

            if (this.socket.isReady()) {
                await this.syncCustomerId();
            }
        }
    }

    async refreshSubscriptions() {
        if (this.customerId) {
            this.subscriptionController.refreshAllSubscriptions();
            this.subscriptionController.startPollSubscription();
        }
    }

    onVisible = () => {
        this.refreshSocketConnection();
        this.refreshSubscriptions();
    }

    socketStatus() {
        return this.socket.isReady() && this.customerIdSynced;
    }

    async syncCustomerId() {
        if (this.customerId === undefined) {
            return;
        }
        try {
            await this.socket.sendMessageSync({ customerId: this.customerId });
            this.customerIdSynced = true;
        } catch (err) {
            this.customerIdSynced = false;
        }
    }

    addStatusHook(statusSetter) {
        const id = getUuid();
        statusSetter(this.serverStatus);
        this.statusHooks.push({ id, statusSetter });
        return id;
    }

    removeStatusHook(id) {
        this.statusHooks = this.statusHooks.filter(statusHook => statusHook.id !== id);
    }
}

const serverConnection = new ServerConnection();

export default serverConnection;