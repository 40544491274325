import { useContext, useEffect, useState } from "react";
import UserContext from "../../../../../hooks/UserContext";
import { postUserInteraction } from "../../../../../scripts/userInteraction";
import { updateZoneCalibration } from "../../../../../scripts/zone";
import { userInteractionTypes } from "../../../../admin/customerDetails/userInteractionTypes";
import { VolumeMetric, VolumeRow } from "../calibrationStyles";
import CalibrationSlider from "./CalibrationSlider";
import { hasVenuePermission, permissionTypes } from "../../../../permission/permissions";
import ZoneRegulationButton from "../../../../panel/zone/ZoneRegulationButton";

const VolumeControls = props => {
    const userContext = useContext(UserContext);
    const [systemVolume, setSystemVolume] = useState(!isNaN(props?.zoneLive?.sysvolAbsolute) ? props?.zoneLive?.sysvolAbsolute : null);
    const [lastVolumeCommit, setLastVolumeCommit] = useState(0);
    const [isChangingVolume, setIsChangingVolume] = useState(false);

    const isRegulatingAudioSource = !props?.sourceSelector
        || props.sources?.find(source => source?.sourceId === props?.sourceSelector?.activeSourceId)?.regulate == true
        || props.sources?.length === 0 ? true : false;

    useEffect(() => {
        if (isChangingVolume === false && (new Date()).getTime() - lastVolumeCommit >= 2000) {
            setSystemVolume(!isNaN(props?.zoneLive?.sysvolAbsolute) ? props?.zoneLive?.sysvolAbsolute : null);
        }
    }, [props?.zoneLive?.sysvolAbsolute])

    const updateZoneWithEvent = async (zoneProps) => {
        try {
            await updateZoneCalibration({ ...zoneProps });
        } catch (err) {
        }
    }

    const toggleRegulation = async (newState) => {
        if (!hasVenuePermission(userContext, permissionTypes.calibrationWrite)) {
            return;
        }

        await updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, isRegulating: +newState });
        postUserInteraction(props.zone.customerId, {
            zoneId: props.zone.zoneId,
            setting: userInteractionTypes.isRegulating.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    return <VolumeRow>
        <VolumeMetric color="white">
            <label>Volume</label>
            <label>{systemVolume}</label>
        </VolumeMetric>

        <CalibrationSlider
            zone={props?.zone}
            isRegulatingAudioSource={isRegulatingAudioSource}
            systemVolume={systemVolume}
            setSystemVolume={setSystemVolume}
            newCalibrationPoints={props.newCalibrationPoints}
            setNewCalibrationPoints={props.setNewCalibrationPoints}
            savedCalibrationPoints={props.savedCalibrationPoints}
            hasChanged={props.hasChanged}
            setHasChanged={props.setHasChanged}
            lastVolumeCommit={lastVolumeCommit}
            setLastVolumeCommit={setLastVolumeCommit}
            isChangingVolume={isChangingVolume}
            setIsChangingVolume={setIsChangingVolume}
            advanced={props.advanced}
        />

        <ZoneRegulationButton
            onClick={() => toggleRegulation(!props.zone?.isRegulating)}
            deactivated={!props.zone?.isRegulating || !isRegulatingAudioSource}
            disabled={
                !isRegulatingAudioSource ||
                props.zone?.shouldRegulate === 0
            }
            isRegulating={props.zone?.isRegulating}
            isRegulatingAudioSource={isRegulatingAudioSource} />
    </VolumeRow>
}

export default VolumeControls;