import styled, { css } from "styled-components";
import theme from "../../UI/theme";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`

const MultipleSelector = props => {

    const selectItemHandler = (item) => {
        if (props.editable && props.onSelectItem) {
            props.onSelectItem(item);
        }
    }

    return <Container>
        {props.items?.map((item, index) => {
            return <Item editable={props.editable} selected={props.selectedItems?.some(selectedItem => item.key === selectedItem.key)} key={index} onClick={() => selectItemHandler(item)}>{item.display}</Item>
        })}
    </Container>
}

export default MultipleSelector;

const Item = styled.div`
    display: flex;
    justify-content: center;
    color: ${props => props.selected ? theme.colors.greenReadable : theme.colors.textGray};
    :not(:last-of-type) {
        margin-right: 10px;
    }
    font-size: ${theme.fontSize.small};
    border: 1px solid;
    border-color: ${props => props.selected ? theme.colors.greenEnergy : theme.colors.textGray};
    border-radius: 104px;
    padding: 1px 10px 4px;
    white-space: nowrap;
    margin-top: 10px;

    ${props => props.editable && css`
        :hover {
            cursor: pointer;
        }
    `}
`