import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/hub';

async function getHubs(customerId) {
    const res = await axios.get(`${hostname}${path}/hubs?customerId=${customerId}`);
    return res.data;
}

async function updateHub(hub) {
    const res = await axios.put(`${hostname}${path}`, hub);
    return res.data;
}

async function updateHubs(hubs) {
    const res = await axios.put(`${hostname}${path}/list`, hubs);
    return res.data;
}

async function getAllHubs() {
    const res = await axios.get(`${hostname}${path}/all`);
    return res.data;
}

async function localSetup(setupParameters) {
    const res = await axios.post(`${hostname}${path}/localSetup`, setupParameters);
    return res.data;
}

async function getHubIdFromLocalApp() {
    const res = await axios.get(`${hostname}${path}/id`);
    return res.data;
}


export {
    updateHub,
    updateHubs,
    getHubs,
    getAllHubs,
    localSetup,
    getHubIdFromLocalApp
}