import { FaLock } from "react-icons/fa";
import { SettingsLink } from "./settingsStyles";
import { useState } from "react";
import Modal from "../UiComponents/Modal";
import { ModalContainer, ModalHeader } from "../panel/ModalStyles";
import InputField from "../UiComponents/InputField";
import Button from "../UiComponents/Button";
import { authorizeAdmin } from "../../scripts/authentication";
import styled from "styled-components";
import theme from "../../UI/theme";

const ErrorMessage = styled.label`
    color: ${theme.colors.raspberryRed};
`

const AuthorizeAdminSettings = props => {
    const [showPinModal, setShowPinModal] = useState(false);
    const [pin, setPin] = useState();
    const [errorMessage, setErrorMessage] = useState();

    const authorize = async () => {
        try {
            setErrorMessage(null)
            const res = await authorizeAdmin(pin);
            if (res === true) {
                if (props.onAuthorize) {
                    props.onAuthorize(pin);
                }
                setShowPinModal(false);
            } else {
                setErrorMessage('Incorrect PIN')
            }
        } catch (error) {
            setErrorMessage('Unknown error occured');
        }
    }

    return <>
        <SettingsLink onClick={() => setShowPinModal(true)}>
            <label>Unlock installer settings</label>
            <FaLock />
        </SettingsLink>

        <Modal show={showPinModal} close={() => setShowPinModal(false)}>
            <ModalContainer>
                <ModalHeader>Enter PIN</ModalHeader>
                <InputField type={'password'} maxLength={4} onChange={(e) => setPin(e.target.value)} inputMode='numeric' pattern="[0-9]*" />
                {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : <></>}
                <div>
                    <Button primary onClick={() => authorize()}>Unlock</Button>
                </div>
            </ModalContainer>
        </Modal>
    </>
}

export default AuthorizeAdminSettings;