import StyledIcon from "../StyledIcon";

const WaUsers = props => {
    return <StyledIcon width="27" height="25" viewBox="0 0 27 25" fill="none" {...props}>
        <path d="M14.2942 24.9386V14.938L1.29297 14.938V24.9386" strokeWidth="2" strokeLinejoin="round" />
        <path d="M25.2969 24.9312V14.9312L17.2968 14.9312" strokeWidth="2" strokeLinejoin="round" />
        <path d="M7.43359 9.94629C9.64273 9.94629 11.4336 8.15543 11.4336 5.94629C11.4336 3.73715 9.64273 1.94629 7.43359 1.94629C5.22446 1.94629 3.43359 3.73715 3.43359 5.94629C3.43359 8.15543 5.22446 9.94629 7.43359 9.94629Z" strokeWidth="2" strokeLinejoin="round" />
        <path d="M15.3066 2.93848C15.9733 2.27181 16.9733 1.93848 17.9733 1.93848C20.14 1.93848 21.9733 3.77181 21.9733 5.93848C21.9733 8.10514 20.14 9.93848 17.9733 9.93848C16.9733 9.93848 15.9733 9.60514 15.3066 8.93848" strokeWidth="2" strokeLinejoin="round" />
    </StyledIcon>
}

export default WaUsers;