import { useEffect, useState } from "react";
import styled from "styled-components";
import { getDayOfWeek } from "../../../scripts/common";
import { getAverageTrend, getTrendData } from "../../../scripts/trends";
import trendTypes from "../../statistics/trendTypes";
import ActivityChart from "./ActivityChart";

const ActivityGraphContainer = styled.div`
    height: 150px;
    width: fill-available;

    display: flex;
    align-items: center;
    justify-content: center;

    canvas {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    &:hover {
        cursor: pointer;
    }
`

const ActivityChartContainer = props => {
    const [graphData, setGraphData] = useState();

    useEffect(() => {
        getDataHandler();
    }, [props.zone?.customerId]);

    const getDataHandler = async () => {
        var trendData = await getTrendData(props.zone?.customerId, 'WEEK', new Date(), 'ZONE', props.zone?.zoneId, trendTypes.dataTypes.averageDecibel);

        var avgTrendData = await getAverageTrend(props.zone?.customerId, props.zone?.zoneId, trendTypes.dataTypes.averageDecibel, 'ZONE');
        var dayOfWeek = getDayOfWeek(new Date());

        var labels = ([...Array(24).keys()]).map(key => ("0" + key).slice(-2));

        if (trendData && trendData.length && avgTrendData) {
            var updatedGraphData = {
                trend: avgTrendData.data[dayOfWeek].hourData,
                labels: labels,
                data: trendData[0]?.data[dayOfWeek].hourData
            }
            fillInMissingDataEarlyInCurrentHour(updatedGraphData.data);
            setGraphData(updatedGraphData);

        } else {
            setGraphData(null);
        }
    }

    return <ActivityGraphContainer {...props} >
        <ActivityChart data={graphData} highlightIndex={new Date().getHours()} />
    </ActivityGraphContainer>
}

export default ActivityChartContainer;

function fillInMissingDataEarlyInCurrentHour(hourData) {
    try {
        var hourIndex = new Date().getHours();
        if (!hourData[hourIndex]) {
            var minutes = new Date().getMinutes();
            if (minutes < 10 && hourData[hourIndex - 1]) {
                hourData[hourIndex] = hourData[hourIndex - 1];
            }
        }

    } catch (err) {

    }
}