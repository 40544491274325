import styled, { css } from "styled-components"
import theme from "../../../UI/theme"
import ZoneVolume from "./ZoneVolume"
import VolumeSlider from "../VolumeSlider"
import ZoneRegulationButton from "./ZoneRegulationButton"
import { MuteButton } from "../MuteButton"
import VibeButton from "../VibeButton"
import ZoneLiveChart from "../charts/ZoneLiveChart"

export const ZoneContainer = styled.div`
    @keyframes scaleUpX {
        0% {
        transform: scaleX(0%);
        }
        100% {
        transform: scaleX(100%);
        }
    }

    background-color: ${theme.colors.darkSpace};
    display: grid;
    grid-template-columns: 20% auto 1fr 2.5fr auto auto;
    grid-gap: 10px;
    align-items: center;
    padding: 10px;

    grid-column: 2;
    grid-column: ${props => props.groupHasSourceSelectors === false ? '1 / span 2' : '2'};
    
    @media only screen and (max-width: ${650}px) {
        grid-template-columns: 1fr auto 40px 40px;
    }
`

export const ZoneHeader = styled.label`
    font-size: ${theme.fontSize.larger};
    white-space: nowrap;
    cursor: pointer;
    grid-column: 1;
    grid-row: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    ${props => props.disabled && css`
        opacity: 40%;
        pointer-events: none;
    `}
`

export const StyledZoneVolume = styled(ZoneVolume)`
    grid-row: 1;
    grid-column: 2;

    @media only screen and (max-width: ${650}px) {
        grid-column: 2;
    }
`

export const StyledVolumeSlider = styled(VolumeSlider)`
    grid-column: 3 / span 2;

    @media only screen and (max-width: ${650}px) {
        grid-column: 1 / span 4;
        grid-row: 2;
    }
`

export const StyledRegulationButton = styled(ZoneRegulationButton)`
    grid-row: 1;
    grid-column: 6;

    @media only screen and (max-width: ${650}px) {
        grid-column: 4;
    }
`

export const StyledMuteButton = styled(MuteButton)`
    grid-row: 1;
    grid-column: 5;

    @media only screen and (max-width: ${650}px) {
        grid-column: 3;
    }
`

export const StyledVibeButton = styled(VibeButton)`
    grid-row: 1;
    grid-column: 4;

    @media only screen and (max-width: ${650}px) {
        grid-column: 1 / span 3;
        grid-row: 2;
    }
`

export const StyledLiveChart = styled(ZoneLiveChart)`
    animation: 0.5s ease-out 0s 1 scaleUpX;

    max-height: 50px;
    height: 50px;
    grid-row: 1;
    grid-column: 3;
    background-color: ${theme.colors.darkSpace90};

    @media only screen and (max-width: ${650}px) {
        grid-column: 4;
        grid-row: 2;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium}px) {
        height: 40px;
        max-height: 40px;
    }
`