var chartAreaBackgroundColorPlugin = {
    beforeDraw: (chart) => {
        const pluginIDs = chart.config.plugins?.map(plugin => plugin.id);
        pluginIDs.forEach(id => {
            if (chart.config.options.plugins[id]) {
                const pluginConfig = chart.config.options.plugins[id];
                const stopColor = pluginConfig.stopColor;
                var ctx = chart.ctx;
                const canvasID = ctx?.canvas?.id;

                if (pluginConfig.chartID === canvasID) {
                    ctx.save();
                    var chartArea = chart.chartArea;
                    let gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);

                    var stops = pluginConfig.stops;

                    if (stops?.length && stopColor) {
                        var noOfStops = stops?.length;
                        stops?.forEach((stop, index) => {
                            var leftBound = Math.max((index - 0.5) / (noOfStops - 1), 0);
                            var righBound = Math.min((index + 0.5) / (noOfStops - 1), 1);
                            gradient.addColorStop(leftBound, stop === 1 ? 'transparent' : stopColor);
                            gradient.addColorStop(righBound, stop === 1 ? 'transparent' : stopColor);
                        });
                        ctx.fillStyle = gradient;
                        ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
                    }

                    ctx.restore();
                }
            }
        })
    }
}

var chartAreaBackground = {
    beforeDraw: (chart) => {
        if (chart.config.options.plugins.chartAreaBackground) {
            var ctx = chart.ctx;
            var xScale = chart.scales.x;
            var max = xScale.max;
            var min = xScale.min;
            const pluginConfig = chart.config.options.plugins.chartAreaBackground;
            const stops = pluginConfig.stops;
            var chartArea = chart.chartArea;
            let gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
            var itemsBeforeLimits = stops?.filter(stop => stop.x < min);
            var startColor = itemsBeforeLimits[itemsBeforeLimits.length - 1]?.color;
            if (startColor) {
                gradient.addColorStop(0, startColor);
            }

            stops?.map(stop => {
                if (stop.x >= min && stop.x < max) {
                    gradient.addColorStop((stop.x - min) / (max - min), stop.color ?? 'transparent');
                }
            });

            ctx.fillStyle = gradient;
            ctx.fillRect(chartArea.left, chartArea.bottom, chartArea.right - chartArea.left, 8);
        }
    }
}

const getOpeningHoursWithinInterval = (openingHours, interval) => {
    let day = interval.start.startOf('day').minus({ days: 1 });
    var dateTimes = [];
    while (day < interval.end) {
        var weekday = day.weekday - 1;
        var dayOpeningHours = openingHours[weekday];
        if (dayOpeningHours?.start?.length === 4 && dayOpeningHours?.end?.length === 4) {
            var open = day.set({ hour: parseInt(dayOpeningHours.start?.substring(0, 2)), minute: parseInt(dayOpeningHours.start?.substring(2, 4)) });
            var close = day.set({ hour: parseInt(dayOpeningHours.end?.substring(0, 2)), minute: parseInt(dayOpeningHours.end?.substring(2, 4)) });
            if (dayOpeningHours.open === true) {
                if (close <= open) {
                    dateTimes.push({ milliseconds: open.toMillis(), open: true });
                    dateTimes.push({ milliseconds: close.plus({ days: 1 }).toMillis(), open: false });
                } else {
                    dateTimes.push({ milliseconds: open.toMillis(), open: true });
                    dateTimes.push({ milliseconds: close.toMillis(), open: false });
                }
            }
        }

        day = day.plus({ days: 1 });
    }

    return dateTimes;
}

export {
    chartAreaBackgroundColorPlugin,
    chartAreaBackground,
    getOpeningHoursWithinInterval
}