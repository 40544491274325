import { useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import UserContext from "../../../hooks/UserContext";
import { postUserInteraction } from "../../../scripts/userInteraction";
import { updateZoneLiveSettingsList } from "../../../scripts/zone";
import { userInteractionTypes } from "../../admin/customerDetails/userInteractionTypes";
import Button from "../../UiComponents/Button";
import Modal from "../../UiComponents/Modal";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../ModalStyles";
import RegulationButton from "../RegulationButton";
import { hasVenuePermission, permissionTypes } from "../../permission/permissions";

const GroupRegulationButton = props => {
    const [showModal, setShowModal] = useState(false);
    const newStateRef = useRef();
    const userContext = useContext(UserContext);

    const clickHandler = (newState) => {
        if (newState == false) {
            newStateRef.current = +newState;
            setShowModal(true);
        } else {
            toggleGlobalRegulation(+newState)
        }
    }

    const toggleGlobalRegulation = async (newState) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }

        const updatedZones = props.zones?.map(zone => ({zoneId: zone.zoneId, isRegulating: +newState}));
        await updateZoneLiveSettingsList(updatedZones);

        postUserInteraction(props.customerId, {
            setting: userInteractionTypes.globalRegulation.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    return <>
        <RegulationButton
            onClick={() => clickHandler(!props.groupIsRegulating)}
            deactivated={!props.groupIsRegulating}
            disabled={props.disabled}
            visibility={props.visibility}
            unavailable={props.unavailable}
        >
            <label>
                {!props.groupIsRegulating ? 'Off' : 'On'}
            </label>
        </RegulationButton>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Group automation</ModalHeader>
                <ModalText>Are you sure you want to pause for entire group?</ModalText>
                <ButtonRowContainer>
                    <Button primary onClick={() => { toggleGlobalRegulation(newStateRef.current); setShowModal(false); }}>Yes</Button>
                    <Button secondary onClick={() => setShowModal(false)}>Cancel</Button>
                </ButtonRowContainer>
            </ModalContainer>
        </Modal>
    </>
}

export default GroupRegulationButton;