import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Button from '../components/UiComponents/Button';
import Card from '../components/UiComponents/Card';
import IconLogo from '../components/UiComponents/IconLogo';
import InputField from '../components/UiComponents/InputField';
import { loginUser } from '../scripts/authentication';
import theme from '../UI/theme';
import { TextMuted } from '../components/UiComponents/text/StyledText';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { SettingsSection } from '../components/settings/settingsStyles';
import { ButtonRowContainer, ModalContainer, ModalHeader } from '../components/panel/ModalStyles';
import Modal from '../components/UiComponents/Modal';
import { IoShareOutline } from "react-icons/io5";
import useScreenSize from '../hooks/useScreenSize';

const Logo = styled(IconLogo)`
    width: 40px;
    height: 40px;
`

const LinkLabel = styled.label`
    color: ${theme.colors.textGray};
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    > *:not(:last-child) {
        margin-right: 10px;
    }
    align-items: center;
    gap: 10px;
`

const ErrorMessage = styled.label`
    color: ${theme.colors.mellowYellow};
`

const StyledModal = styled(Modal)`
    background-color: #FFFFFF;
`

const loadingStates = { READY: 'READY', LOADING: 'LOADING' };

const AuthView = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [loadingState, setLoadingState] = useState(loadingStates.READY);
    const history = useHistory();

    const [showInstallButton, setShowInstallButton] = useState(false);
    const [installEvent, setInstallEvent] = useState(null);
    const [showInstallModal, setShowInstallModal] = useState(true);
    const [installCheckComplete, setInstallCheckComplete] = useState(false);
    const screenSize = useScreenSize();

    useEffect(() => {
        window.addEventListener('appinstalled', () => setShowInstallModal(false));
        if (isLocalApp() || isDownloaded()) setShowInstallModal(false);
        if('BeforeInstallPromptEvent') {
            window.addEventListener('beforeinstallprompt', handleInstallPrompt);
            window.addEventListener('appinstalled', onInstall);
        }
        setTimeout(() => setInstallCheckComplete(true), 1000);
    }, []);

    

    const loginLocal = async () => {
        setLoadingState(loadingStates.LOADING);
        try {
            await loginUser(username, password);
            props.setAuthenticated(true);
        } catch (error) {
            if (error.response?.status === 400) {
                setErrorMsg('Incorrect username or password format.')
            }
            else if (error.response?.status === 401) {
                setErrorMsg('Incorrect username or password.');
            } else {
                setErrorMsg('Unknown error occurred. Please try again later or contact support at support@waved.no or +47 22 22 22 33.')
            }
        }
        setLoadingState(loadingStates.READY);
    }

    function isLocalApp() {
        const url = window.location.href;
        if (url.includes('waved') || process.env.REACT_APP_ENVIRONMENT === 'DEVELOPMENT') return false;
        else return true;
    } 

    function isDownloaded() {
        console.log(document.fullscreenElement);
        try {
            if ( window.navigator.standalone === true || 
                document.fullscreenElement
            ) return true; 
        else return false;
        } catch (e) {
            return false;
        }
    }

    const handleInstallPrompt = (event) => {
        setShowInstallButton(true);
        event.preventDefault();
        setInstallEvent(event);
    }

    const onInstall = () => {
        setShowInstallButton(false);
        setInstallEvent(null);
    };

    const installApp = async (e) => {
        if (!installEvent) return;
        installEvent.prompt();
        const result = await installEvent.userChoice;
        if (result.outcome === 'accepted') {
        onInstall();
        }
    };

    return (
        <Card>
            <ModalContainer>
                <RowContainer>
                    <Logo />
                    <ModalHeader>Welcome!</ModalHeader>
                    
                </RowContainer>

                {loadingState === loadingStates.LOADING ?
                    <label>Loading...</label>
                    : <>
                        {errorMsg ? <ErrorMessage>{errorMsg}</ErrorMessage> : <></>}
                    </>}

                <SettingsSection>
                    <TextMuted>Email / Username</TextMuted>
                    <InputField value={username} onChange={e => setUsername(e.target.value)} type="username" required />
                </SettingsSection>
                <SettingsSection>
                    <TextMuted>Password</TextMuted>
                    <InputField value={password} onChange={e => setPassword(e.target.value)} type="password" required />
                </SettingsSection>

                <LinkLabel onClick={() => history.push('/forgotPassword')}>I've forgotten my password</LinkLabel>

                <ButtonRowContainer>
                    <Button primary onClick={loginLocal} type={"button"}>Sign in</Button>
                </ButtonRowContainer>
            </ModalContainer>
            <Modal show={installCheckComplete && showInstallModal && screenSize.width <= 1400}>
                <ModalContainer style={{maxWidth: '400px'}}>
                    <h2 style={{marginTop: '0px'}}>Get the app</h2>
                    <p style={{margin: '0px'}} >For the best experience, install the app from your browser!</p>
                        {showInstallButton ? <>
                            <RowContainer>
                                <Button primary onClick={installApp}>Install App</Button>
                                <Button tertiary small style={{marginLeft: 'auto'}} onClick={() => setShowInstallModal(false)}>Dismiss</Button>
                            </RowContainer>
                        </>:<>
                                <p style={{margin: '0px'}}>Tap <IoShareOutline/>  then <strong>add to home screen</strong></p>  
                                <Button tertiary small style={{maxWidth: '110px'}} onClick={() => setShowInstallModal(false)}>Dismiss</Button>
                        </>}
                </ModalContainer>
            </Modal>
        </Card>
    );
}

export default AuthView;