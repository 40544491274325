import { isNumber } from "../../../../../scripts/common";
import Slider from "../../../../UiComponents/Slider";
import VolumeRangeSlider from "../../zone/VolumeRangeSlider";

const CalibrationSliders = ({ parameters, setParameters, save }) => {

    const baseLevel = isNumber(parameters?.gainBaseLevel) ? Math.max(parameters.gainBaseLevel, -80) : -30;
    const maxLevel = isNumber(parameters?.gainMaxLevel) ? Math.min(parameters.gainMaxLevel, 10) : -10;
    
    return <div>
        <br />
        <h4>Gain base and max level</h4>
        <VolumeRangeSlider
            min={-80}
            max={10}
            values={[baseLevel, maxLevel]}
            onChange={(newValues) => setParameters({ gainBaseLevel: newValues[0], gainMaxLevel: newValues[1] })}
            onChangeCommitted={save}
        />

        <div>
            <h4>Ambience base level {parameters?.ambienceBaseLevel}</h4>
            <Slider
                value={parameters?.ambienceBaseLevel}
                onChange={(newValue) => setParameters({ ambienceBaseLevel: newValue })}
                onChangeCommitted={save}
                min={15}
                max={60}
                step={0.5}
            />
        </div>

        <div>
            <h4>Slope {parameters?.gainSlope}</h4>
            <Slider
                value={parameters?.gainSlope}
                onChange={(newValue) => setParameters({ gainSlope: newValue })}
                onChangeCommitted={save}
                min={0.2}
                max={1.5}
                step={0.02}
            />
        </div>
    </div>
}

export default CalibrationSliders;