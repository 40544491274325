import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useSubscription } from "../../../../../hooks/useSubscription";
import { subscriptionsTypes } from "../../../../../scripts/subscription/subscriptionTypes";
import ViewHeader from "../../../../UiComponents/ViewHeader";
import LinkItem from "../../../LinkItem";
import { SettingsSection } from "../../../settingsStyles";
import hierarchyTypes from "../../device/hierarchyTypes";

const LiveCalibration = props => {
    const match = useRouteMatch();
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);

    return <>
        <ViewHeader headerText={'Live Calibration'} backLink={'/settings'} showVenueName={true} />

        <SettingsSection>
            {zones?.
                filter(zone => zone?.hierarchyType !== hierarchyTypes.MASTER.value && zone?.shouldRegulate === 1)?.
                map(zone => {
                    return <LinkItem key={zone?.zoneId} to={`${match.url}/${zone?.zoneId}`} display={zone?.zoneName} />
                })}
        </SettingsSection>
    </>
}

export default LiveCalibration;