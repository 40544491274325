import React from 'react';
import styled from 'styled-components';
import theme from '../../UI/theme';

const Container = styled.div`
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '30px'};
`

const StyledSlider = styled.input`
    -webkit-appearance: none;
    border-radius: 0;
    width: 100%;
    height: ${props => props.height || '30px'};
    background: ${props => props.backgroundColor || theme.colors.darkSpace};
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: ${props => props.thumbWidth || props.height || '30px'};
        height: ${props => props.height || '30px'};
        background: ${props => props.thumbColor || theme.colors.greenEnergy};
        cursor: pointer; 
    }

    &::-moz-range-thumb {
        width: ${props => props.thumbWidth || props.height || '30px'};
        height: ${props => props.height || '30px'};
        background: ${props => props.thumbColor || theme.colors.greenEnergy};
        cursor: pointer; 
    }
`


const Slider = props => {

    const changeHandler = (event) => {
        if (props.onChange) {
            var newValue = Number(event.target.value);
            props.onChange(newValue);
        }
    }

    const changeCommittedHandler = (event) => {
        if (props.onChangeCommitted) {
            var newValue = Number(event.target.value);
            props.onChangeCommitted(newValue);
        }
    }

    return <Container width={props.width} height={props.height}>
        <StyledSlider
            type="range"
            value={props.value}
            onChange={(e) => changeHandler(e)}
            onMouseUp={(e) => changeCommittedHandler(e)}
            onTouchEnd={(e) => changeCommittedHandler(e)}
            onClick={(e) => e.stopPropagation()}
            min={props.min || 0}
            max={props.max || 10}
            step={props.step || 1}
            height={props.height}
            backgroundColor={props.backgroundColor}
            thumbColor={props.thumbColor}
            thumbWidth={props.thumbWidth}
        />
    </Container>
}

export default Slider;