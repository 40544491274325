
class None {

    static validateObjectAddress(value) {

        return true;
    }

    static validateMuteAddress(value) {
        return true;
    }

    static validateSourceSelectorAddress(value) {
        return true;
    }

    static suggestObjectAddress() {
        return '';
    }

    static suggestMuteAddress() {
        return '';
    }

    static validateSourceSelectorAddress(address) {
        return true;
    }
}

None.props = {
    muteAddress: false
}

export default None;