import useLongPress from "../../../../../hooks/UseLongPress";
import PanelButton from "../../../../panel/PanelButton";
import WaMinus from "../../../../UiComponents/Icons/WaMinus";
import WaPlus from "../../../../UiComponents/Icons/WaPlus";
import { NoiseLevelControlContainer } from "../calibrationStyles";

const BaseLevelControls = props => {
    const onChangeBaseLevel = (increment) => {
        if (props.setNewCalibrationPoints) {
            props.setNewCalibrationPoints(prev => {
                let newPoints = JSON.parse(JSON.stringify(prev));

                for (let index = 0; index < newPoints.length; index++) {
                    newPoints[index].measuredDb += increment;
                }

                return newPoints;
            })

            if (props.setHasChanged) {
                props.setHasChanged(true);
            }
        }
    }

    return <NoiseLevelControlContainer>
        <PanelButton onClick={() => onChangeBaseLevel(0.5)} {...useLongPress(() => onChangeBaseLevel(1), 200)}><WaPlus /></PanelButton>
        <PanelButton onClick={() => onChangeBaseLevel(-0.5)} {...useLongPress(() => onChangeBaseLevel(-1), 200)}><WaMinus /></PanelButton>
    </NoiseLevelControlContainer>
}

export default BaseLevelControls;