import styled from "styled-components";
import theme from "../UI/theme";
import ViewHeader from "../components/UiComponents/ViewHeader";
import { SettingsParagraph, SettingsSection } from "../components/settings/settingsStyles";
import InputField from "../components/UiComponents/InputField";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom";
import { ButtonRowContainer } from "../components/panel/ModalStyles";
import Button from "../components/UiComponents/Button";
import { useState } from "react";
import { signUpUser } from "../scripts/user";
import { loadingStates } from "../components/installation/installationTypes";
import { loginUser } from "../scripts/authentication";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    background-color: ${theme.colors.darkSpace90};
    padding: 20px;
    box-sizing: border-box;
`

const SignUpView = props => {
    const { signUpRequestId } = useParams();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [error, setError] = useState();
    const [allowRetry, setAllowRetry] = useState(false);
    const history = useHistory();

    const handleSignUp = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            const userObject = {
                firstName: firstName,
                lastName: lastName,
                password: password,
            };

            await signUpUser(userObject, signUpRequestId);

            setLoadingState(loadingStates.SUCCESS);
        } catch (error) {
            setLoadingState(loadingStates.FAILED);
            if (error.response?.status === 410) {
                setError('Invalid or expired sign-up session. Please ask the venue manager of your venue to create a new sign-up request.')
            } else {
                setError('Sign-up failed.');
            }
        }
    }

    const handleRetry = () => {
        setError('');
        setAllowRetry(false);
        setLoadingState(loadingStates.NONE);
    }

    const handleSignIn = async () => {
        history.push('/');
        window.location.reload();
    }

    return <>
        <ViewHeader headerText={'Sign Up'} />

        <Container>
            {loadingState === loadingStates.NONE ? <>
                <SettingsSection>
                    <SettingsParagraph>First name *</SettingsParagraph>
                    <InputField value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </SettingsSection>

                <SettingsSection>
                    <SettingsParagraph>Last name *</SettingsParagraph>
                    <InputField value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </SettingsSection>

                <SettingsSection>
                    <SettingsParagraph>Password *</SettingsParagraph>
                    <InputField value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
                </SettingsSection>

                <SettingsSection>
                    <SettingsParagraph>Retype password *</SettingsParagraph>
                    <InputField value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} type="password" />
                </SettingsSection>

                <SettingsSection>
                    <ButtonRowContainer>
                        <Button
                            disabled={
                                firstName?.length < 1 ||
                                lastName?.length < 1 ||
                                password?.length < 1 ||
                                passwordConfirm?.length < 1 ||
                                password !== passwordConfirm
                            }
                            onClick={() => handleSignUp()}
                            primary>
                            Sign Up
                        </Button>
                    </ButtonRowContainer>
                </SettingsSection>
            </> : <></>}

            {loadingState === loadingStates.LOADING ? <>
                <SettingsParagraph>Signing up...</SettingsParagraph>
            </> : <></>}

            {loadingState === loadingStates.SUCCESS ? <>
                <SettingsSection>
                    <SettingsParagraph>Sign-up successful.</SettingsParagraph>
                </SettingsSection>

                <SettingsSection>
                    <ButtonRowContainer>
                        <Button primary onClick={() => handleSignIn()}>Go to sign-in</Button>
                    </ButtonRowContainer>
                </SettingsSection>
            </> : <></>}

            {loadingState === loadingStates.FAILED ? <>
                <SettingsSection>
                    <SettingsParagraph>{error}</SettingsParagraph>
                </SettingsSection>

                {allowRetry ? <>
                    <SettingsSection>
                        <ButtonRowContainer>
                            <Button primary onClick={() => handleRetry()}>Retry</Button>
                        </ButtonRowContainer>
                    </SettingsSection>
                </> : <></>}

            </> : <></>}
        </Container>
    </>
}

export default SignUpView;