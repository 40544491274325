import { useContext, useEffect, useState } from "react";
import UserContext from "../../../../hooks/UserContext";
import ZoneSelector from "./ZoneSelector";
import ViewHeader from "../../../UiComponents/ViewHeader";
import ZoneLiveDataContainer from "./ZoneLiveDataContainer";
import CalibrationContainer from "./calibration/CalibrationContainer";
import SensorContainer from "./sensor/SensorContainer";

const MeterView = () => {
    const userContext = useContext(UserContext)
    const [selectedZoneId, setSelectedZoneId] = useState();

    useEffect(() => {
        setSelectedZoneId(null);
    }, [userContext.customer.customerId])

    return <div style={{ margin: '20px' }}>
        <ViewHeader headerText={'Meter workbench'} />
        <div style={{ width: '200px' }}>
            <ZoneSelector
                selectedZoneId={selectedZoneId}
                setSelectedZoneId={setSelectedZoneId}
                customerId={userContext.customer.customerId}
            />
        </div>
        {selectedZoneId && <>
            <ZoneLiveDataContainer
                customerId={userContext.customer.customerId}
                zoneId={selectedZoneId}
            />
            <CalibrationContainer zoneId={selectedZoneId} />
            <SensorContainer
                customerId={userContext.customer.customerId}
                zoneId={selectedZoneId} 
            />
            <div style={{height: '100px'}} />
        </>}

    </div>
}

export default MeterView