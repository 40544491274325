import styled, {keyframes} from 'styled-components';
import theme from '../../../../UI/theme';

const appear = keyframes`
    from {
        transform:  translateY(10px);
    }
    to {
        transform:  translateY(0);
    }
`

const ToastMessage = styled.div`
    padding: 10px 20px;
    border-radius: 10px;
    background-color: ${theme.colors.darkSpace80};
    /* box-shadow: 0px 2px 4px #dcdcdc; */
    animation: ${appear} 0.2s linear;
    
    cursor: pointer;
`

export default ToastMessage;