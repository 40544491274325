
import { addSourceMatrix } from "../../../../scripts/sourceMatrix";

const addNewSourceMatrix = async (props, processors, customerId) => {
    try {
        const newSourceMatrix = { ...props, customerId };
        await addSourceMatrix(newSourceMatrix);
    } catch (err) {
        console.log('Failed to add new sourceMatrixSelector', err);
    }
}

const getNextAddress = (objects, processors) => {
    try {
        if (processors.length === 1) {
            return objects.length ? Math.max(...objects.map(object => object.address)) + 1 : 1
        }
    } catch (err) {
        return null;
    }
}

export {
    addNewSourceMatrix,
    getNextAddress
}