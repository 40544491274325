import styled from "styled-components";
import PanelButton from "../../../../panel/PanelButton";
import { useState } from "react";
import Modal from "../../../../UiComponents/Modal";
import { ButtonRowContainer, ModalContainer, ModalHeader } from "../../../../panel/ModalStyles";
import { FaPlus } from "react-icons/fa";
import InputField from "../../../../UiComponents/InputField";
import Button from "../../../../UiComponents/Button";
import { SettingsParagraph, SettingsSection } from "../../../settingsStyles";
import Radio from "../../../../UiComponents/Radio";
import { roles } from "../../../../permission/permissions";
import { TextMuted } from "../../../../UiComponents/text/StyledText";
import { isValidEmail } from "../../../../../scripts/common";
import { addUser } from "../../../../../scripts/user";
import { loadingStates } from "../../../../installation/installationTypes";

const RadioContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
`

const emptyUserObject = {
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    role: roles.user
};

const AddUserButton = props => {
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [newUser, setNewUser] = useState(JSON.parse(JSON.stringify(emptyUserObject)));
    const [error, setError] = useState();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    const updateUserState = (propsName, value) => {
        setNewUser(prev => {
            let userObject = { ...prev };
            userObject[propsName] = value;
            return userObject;
        });
    }

    const handleAddUser = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            await addUser(newUser);
            setLoadingState(loadingStates.SUCCESS);
        } catch (error) {
            setLoadingState(loadingStates.FAILED);
            if (error?.response?.status === 409) {
                setError(error?.response?.data?.error);
            } else {
                setError("Error creating user. Please try again.")
            }
        }
    }

    const handleClose = () => {
        handleResetForm();
        setShowAddUserModal(false);
    }

    const handleResetForm = () => {
        setError(null);
        setNewUser(JSON.parse(JSON.stringify(emptyUserObject)));
        setLoadingState(loadingStates.NONE);
    }

    const handleRetry = () => {
        setError(null);
        setLoadingState(loadingStates.NONE);
    }

    return <>
        <PanelButton onClick={() => setShowAddUserModal(true)}><FaPlus />Add</PanelButton>

        <Modal show={showAddUserModal} close={() => handleClose()}>
            <ModalContainer>
                <ModalHeader>Add user</ModalHeader>

                {loadingState === loadingStates.NONE ? <>
                    <SettingsSection>
                        <label>First name</label>
                        <InputField value={newUser?.firstName} onChange={(e) => updateUserState('firstName', e.target.value)} />
                    </SettingsSection>

                    <SettingsSection>
                        <label>Last name</label>
                        <InputField value={newUser?.lastName} onChange={(e) => updateUserState('lastName', e.target.value)} />
                    </SettingsSection>

                    <SettingsSection>
                        <label>Email</label>
                        <InputField value={newUser?.email} onChange={(e) => updateUserState('email', e.target.value)} />
                    </SettingsSection>

                    <SettingsSection>
                        <label>Username</label>
                        <InputField value={newUser?.username} onChange={(e) => updateUserState('username', e.target.value)} />
                    </SettingsSection>

                    <SettingsSection>
                        <label>Role</label>

                        <RadioContainer>
                            <Radio name="role" checked={newUser?.role === roles.user} onChange={() => updateUserState('role', roles.user)} />
                            <TextMuted>User</TextMuted>
                            <Radio name="role" checked={newUser?.role === roles.impersonator} onChange={() => updateUserState('role', roles.impersonator)} />
                            <TextMuted>Impersonator</TextMuted>
                            <Radio name="role" checked={newUser?.role === roles.admin} onChange={() => updateUserState('role', roles.admin)} />
                            <TextMuted>Admin</TextMuted>
                        </RadioContainer>
                    </SettingsSection>

                    <ButtonRowContainer>
                        <Button
                            primary
                            disabled={
                                !isValidEmail(newUser?.email) ||
                                newUser?.username?.length < 1}
                            onClick={() => handleAddUser()}>
                            Add user
                        </Button>
                    </ButtonRowContainer>
                </> : <></>}

                {loadingState === loadingStates.LOADING ? <>
                    <SettingsSection>
                        <SettingsParagraph>Creating user...</SettingsParagraph>
                    </SettingsSection>
                </> : <></>}

                {loadingState === loadingStates.SUCCESS ? <>
                    <SettingsSection>
                        <SettingsParagraph>
                            User created successfully.
                        </SettingsParagraph>
                    </SettingsSection>

                    <ButtonRowContainer>
                        <Button primary onClick={() => handleResetForm()}>Add another user</Button>
                        <Button secondary onClick={() => handleClose()}>Close</Button>
                    </ButtonRowContainer>
                </> : <></>}

                {loadingState === loadingStates.FAILED ? <>
                    <SettingsSection>
                        <SettingsParagraph>
                            {error}
                        </SettingsParagraph>
                    </SettingsSection>

                    <ButtonRowContainer>
                        <Button primary onClick={() => handleRetry()}>Return to form</Button>
                        <Button secondary onClick={() => handleClose()}>Close</Button>
                    </ButtonRowContainer>
                </> : <></>}

            </ModalContainer>
        </Modal>
    </>
}

export default AddUserButton;