import React, { useState } from 'react';
import { postEvent } from '../../../../../scripts/event';
import Button from '../../../../UiComponents/Button';


const ReconnectProcessorButton = props => {
    const [showButton, setShowButton] = useState(true);

    const reconnectProcessor = async () => {
        try {
            await postEvent(props.hub.hubId, props.hub.customerId, 'RECONNECT_PROCESSOR', { processorId: props.processor.processorId });
            setShowButton(false);
            setTimeout(() => {
                setShowButton(true);
            }, 10000)
        } catch {
            console.log('Could not post event');
        }
    }

    if (showButton) {
        return <div>
            <Button onClick={reconnectProcessor} small secondary>Reconnect</Button>
        </div>
    } else {
        return <></>
    }

}

export default ReconnectProcessorButton;