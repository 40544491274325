import styled from "styled-components";
import theme from "../../../UI/theme";
import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useEffect } from "react";
import { useRef } from "react";

const Container = styled.div`
    display: flex;
    background-color: ${theme.colors.darkSpace90};
    transition: all 0.5s;

    @media only screen and (max-width: ${theme.screenSizes.large - 1}px) {
        width: fill-available;
        position: fixed;
        z-index: 1;
        bottom: 0;
        left: 0;
        overflow: hidden;
        flex-direction: column;
        border-radius: 50px 50px 0 0;
    }

    @media only screen and (min-width: ${theme.screenSizes.large}px) {
        width: ${props => props.expanded ? '35%' : '40px'};
        flex-direction: row-reverse;
        border-radius: 2px 2px 0 0;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        margin-bottom: 86px
    }
`

export const ExpandButtonContainer = styled.div`

`

export const ExpandButton = styled.button`
    height: 40px;
    width: fill-available;
    background-color: ${theme.colors.darkSpace80};
    color: ${theme.colors.textGray};
    border: none;
    border-radius: 50px 50px 0 0;
    cursor: pointer;

    @media only screen and (min-width: ${theme.screenSizes.large}px) {
        height: fill-available;
        width: 40px;
        flex-direction: row;
        border-radius: 0 2px 2px 0;

        > svg {
            transform: rotate(90deg);
        }
    }
`

const SidePanelContainer = props => {
    const [expanded, setExpanded] = useState(props.defaultExpanded);
    const containerRef = useRef();

    const updateHeight = () => {
        if (containerRef?.current) {
            if (window.innerWidth < theme.screenSizes.large) {
                containerRef.current.style.height = expanded ? '75%' : '40px';
            } else {
                containerRef.current.style.height = `${window.innerHeight - containerRef?.current.getBoundingClientRect().top - 10}px`;
            }
        }
    }

    useEffect(() => {
        updateHeight();

        window.addEventListener('resize', updateHeight);

        return () => {
            window.removeEventListener('resize', updateHeight);
        }
    }, [])

    useEffect(() => {
        updateHeight();
    }, [expanded]);

    return <Container expanded={expanded} ref={containerRef}>
        <ExpandButtonContainer>
            <ExpandButton onClick={() => setExpanded(prev => !prev)}>{expanded ? <FaChevronDown /> : <FaChevronUp />}</ExpandButton>
        </ExpandButtonContainer>

        {props.children}
    </Container>
}

export default SidePanelContainer;