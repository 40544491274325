import { useState } from "react";
import { useEffect } from "react";
import { getCustomer } from "../../../scripts/datasource";
import { getSensor } from "../../../scripts/sensor";
import { getZone } from "../../../scripts/zone";
import { RowDetailsContainer } from "./customerHealthStyles";

const SensorDetails = props => {
    const [sensor, setSensor] = useState();
    const [zone, setZone] = useState();
    const [customer, setCustomer] = useState();

    useEffect(() => {
        getData();
    }, [])

    const getData = async () => {
        try {
            let sensorResult = await getSensor(props.sensorId);
            setSensor(sensorResult);

            let zoneResult = await getZone(sensorResult?.zoneId);
            setZone(zoneResult);
        } catch (error) { }

        try {
            let customerResult = await getCustomer(props.customerId);
            setCustomer(customerResult);
        } catch (error) { }
    }

    return <RowDetailsContainer>
        <label>Venue: {customer?.companyName}</label>
        <label>Zone ID: {zone?.zoneId}</label>
        <label>Zone name: {zone?.zoneName}</label>
        <label>Power source: {sensor?.powerSource === 1 ? 'Power plug' : sensor?.powerSource === 2 ? 'Battery pack' : sensor?.powerSource === 3 ? 'Track mount' : 'Unset'}</label>
        <label>Node type: {parseInt(sensor?.nodeType) === 1 ? 'Router' : 'End device'}</label>
        <label>Battery level: {sensor?.batteryLevel}</label>
        <label>Battery Temperature: {sensor?.batteryTemperature}</label>
    </RowDetailsContainer>
}

export default SensorDetails;