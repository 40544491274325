import styled from "styled-components";
import { getDeviceNumber } from "../../../../../scripts/common"
import theme from "../../../../../UI/theme";

const StyledList = styled.ul`
    list-style: none;
    padding: 0; 
    >li {
        width: 400px;
        display: flex;
        justify-content: space-between;
        background-color: ${theme.colors.darkSpace80};
        padding: 10px;
        border-radius: 4px;

        &:hover {
            background-color: ${theme.colors.darkSpace70};
            cursor: pointer;
        }
    }
`

const SensorList = ({ sensors, onSelect }) => {

    return <StyledList>
        {sensors.map(sensor => <li key={sensor.sensorId} onClick={() => onSelect(sensor)}>
            <div>
                Sensor {getDeviceNumber(sensor)}
            </div>
            <div>
                {/* Quality metric */}
                {'->'}
            </div>
        </li>)}
    </StyledList>
}

export default SensorList;