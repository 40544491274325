import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/heatmap';

async function getHeatmapData(customerId) {
    const res = await axios.get(`${hostname}${path}?customerId=${customerId}`);
    return res.data;
}

async function updateHeatmapData(customerId, floors, floorElements, deletedFloorElements) {
    const res = await axios.post(`${hostname}${path}?customerId=${customerId}`,
        {
            heatmapFloors: floors,
            heatmapFloorElements: floorElements,
            deletedFloorElements: deletedFloorElements
        }
    );
    return res.data;
}

async function getSensorLiveData(customerId) {
    const res = await axios.get(`${hostname}${path}/sensordata?customerId=${customerId}`);
    return res.data;
}

async function getZoneLiveData(customerId) {
    const res = await axios.get(`${hostname}${path}/zonedata?customerId=${customerId}`);
    return res.data;
}

async function setHeatmapFloorInactive(customerId, floorId) {
    const res = await axios.delete(`${hostname}${path}/floor?customerId=${customerId}`, { data: { floorId } });
    return res.data
}

async function getSensorLogInterval(customerId, interval) {
    const res = await axios.get(`${hostname}${path}/sensordata/history?customerId=${customerId}&interval=${interval}`);
    return res.data;
}

export {
    getHeatmapData,
    updateHeatmapData,
    getSensorLiveData,
    getZoneLiveData,
    setHeatmapFloorInactive,
    getSensorLogInterval
}