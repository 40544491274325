
class London {

    static validateObjectAddress(value) {
        const hasFormat = this.testFormat(value);
        if (!hasFormat) {
            return {type: 'WARNING', message: 'Should be on format: 0xA68303000100:0x0000'};
        }
    }

    static validateMuteAddress(value) {
        const hasFormat = this.testFormat(value);
        if (!hasFormat) {
            return {type: 'WARNING', message: 'Should be on format: 0xA68303000100:0x0000'};
        }
    }

    static suggestObjectAddress() {
        return '0xobjectaddress:0x0000';
    }

    static suggestMuteAddress() {
        return '0xmuteadress:0x0000';
    }

    static testFormat(address) {
        const addressRegex = /^[0]{1}[x]{1}[A-F0-9]{12}[:]{1}[0]{1}[x]{1}[A-F0-9]{4}/i
        const hexTest = /^[0]{1}[x]{1}/;
        return addressRegex.test(address) && hexTest.test(address.slice(0,2)) && hexTest.test(address.slice(15,17));
    }

    static validateSourceSelectorAddress(value) {
        const hasFormat = this.testFormat(value);
        if (!hasFormat) {
            return {type: 'WARNING', message: 'Should be on format: 0xA68303000100:0x0000'};
        }
    }
}

London.props = {
    muteAddress: true
}

export default London;