import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/zoneMode';

async function getZoneModes(customerId) {
    const res = await axios.get(`${hostname}${path}?customerId=${customerId}`);
    return res.data;
}

async function updateZoneModes(zoneModes) {
    const res = await axios.put(`${hostname}${path}/list`, zoneModes);
    return res.data;
}

export {
    getZoneModes,
    updateZoneModes
}