import { useEffect, useState } from "react";
import ViewHeader from "../components/UiComponents/ViewHeader";
import CustomerSuccessTable from "../components/admin/customerSuccess/CustomerSuccessTable";
import CountrySelector, { getCountry } from "../components/admin/impersonate/CountrySelector";
import SearchField from "../components/UiComponents/SearchField";
import { getCustomerUseSummaries } from "../scripts/support";
import { SearchContainer, SupportTableViewContainer } from "../components/admin/support/SupportStyles";

const CustomerUseView = props => {
    const [customerData, setCustomerData] = useState();
    const [filteredCustomerData, setFilteredCustomerData] = useState();
    const [searchValue, setSearchValue] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        setFilteredCustomerData(customerData?.filter(customer => {
            const isInSelectedCountry = selectedCountry === null || getCountry(customer) === selectedCountry?.key;

            return isInSelectedCountry && (!searchValue ||
                customer?.customerId?.toString()?.includes(searchValue)
                || customer.companyName?.toLowerCase().includes(searchValue.toLowerCase()))
        }))
    }, [searchValue, selectedCountry]);

    const getData = async () => {
        var data = await getCustomerUseSummaries();
        setCustomerData(data?.customerUseSummaries);
        setFilteredCustomerData(data?.customerUseSummaries);
    }

    return <>
        <ViewHeader headerText={'Customer Use'} backLink={'/supportcenter'} />

        <SupportTableViewContainer>
            <SearchContainer>
                <SearchField placeholder={'Search customer ID or name'} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />

                <CountrySelector selectedCountry={selectedCountry} customers={customerData} onSelect={(country) => setSelectedCountry(country)} />
            </SearchContainer>

            <CustomerSuccessTable customerUseSummaries={filteredCustomerData} setCustomer={props.setCustomer} />
        </SupportTableViewContainer>

    </>
}

export default CustomerUseView;