import { useState } from "react";
import styled from "styled-components";
import theme from "../../UI/theme";
import Button from "../UiComponents/Button";
import IconButton from "../UiComponents/buttons/IconButton";
import { FaTrash } from "react-icons/fa";
import { deleteImage } from "../../scripts/storage";
import { loadingStates } from "../installation/installationTypes";
import LoadingSpinner from "../UiComponents/LoadingSpinner";
import ImageCarousel from "./ImageCarousel";
import { ModalText } from "../panel/ModalStyles";


const StyledList = styled.ul`
    list-style: none;
    padding: 0;

    > li {
        margin-bottom: 20px;
    }
`

const ImageList = ({ customerId, imageUrls, onDelete, readOnly }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [showFullScreen, setShowFullScreen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState();

    const onImageClick = (imageUrl, index) => {
        setSelectedIndex(index);
        setShowFullScreen(true);
    }

    return <div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
            <ModalText>Photos</ModalText>
            {readOnly ? <></> :
                <div>
                    <Button tertiary small onClick={() => setIsEditing(!isEditing)}>{isEditing ? 'Done' : 'Edit'}</Button>
                </div>
            }
        </div>
        <StyledList>
            {imageUrls.map((imageUrl, ix) => <ImageListItem
                key={ix}
                onClick={() => onImageClick(imageUrl, ix)}
                customerId={customerId}
                imageUrl={imageUrl}
                onDelete={onDelete}
                isEditing={isEditing}
            />)}
        </StyledList>

        {showFullScreen ? <>
            <ImageCarousel
                imageUrls={imageUrls}
                startIndex={selectedIndex}
                close={() => setShowFullScreen(false)}
            />
        </> : <></>}
    </div>
}

export default ImageList;


const StyledListItem = styled.li`
    display: flex;
    justify-content: center;
    background-color: ${theme.colors.darkSpace90};
    position: relative;

    &:hover {
        cursor: pointer;
    }
`

const DeleteButtonContainer = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
`

const DeleteButton = styled(IconButton)`
    padding: 6px;
    border-radius: 4px;
    border: 2px solid ${theme.colors.darkSpace60};
    background-color: ${theme.colors.darkSpace90};
    color:${theme.colors.greenEnergy};
`

const ImageListItem = ({ customerId, imageUrl, onDelete, isEditing, onClick }) => {
    const [deleteState, setDeleteState] = useState(loadingStates.NONE);

    const deleteHandler = async (e, imageUrl) => {
        e.stopPropagation();
        try {
            setDeleteState(loadingStates.LOADING);
            await deleteImage(customerId, imageUrl);
            if (onDelete) {
                await onDelete(imageUrl);
            }
            setDeleteState(loadingStates.NONE);
        } catch (err) {
            setDeleteState(loadingStates.FAILED);
        }
    }

    return <StyledListItem onClick={onClick}>

        <img style={{ maxWidth: '100%', maxHeight: '500px' }} src={imageUrl} alt="Image" />
        {isEditing ? <>

            <DeleteButtonContainer>
                {deleteState === loadingStates.NONE ? <>
                    <DeleteButton onClick={(e) => deleteHandler(e, imageUrl)}><FaTrash /></DeleteButton>
                </> : <></>}

                {deleteState === loadingStates.LOADING ? <>
                    <LoadingSpinner></LoadingSpinner>
                </> : <></>}
            </DeleteButtonContainer>

            {deleteState === loadingStates.FAILED ? <>
                <div>Failed to delete image</div>
            </> : <></>}
        </> : <></>}

    </StyledListItem>
}