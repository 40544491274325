import { useState } from "react";
import styled from "styled-components";
import { ClickableTableRow, TableData, TableRow } from "../support/SupportStyles";
import HealthMetricLabel from "./HealthMetricLabel";
import SensorDetails from "./SensorDetails";

const SensorInfoTD = styled.td.attrs({ colSpan: 3 })`
`

const SensorHealthMetric = props => {
    const [expanded, setExpanded] = useState(false);

    return <>
        <ClickableTableRow onClick={() => setExpanded(prev => !prev)}>
            <TableData>{props.customerId ?? props.sensorMetrics?.[0]?.customerId}</TableData>
            <TableData>{props.sensorId}</TableData>
            {props.sensorMetricTypes?.map((metricType, index) => {
                const currentMetric = props.sensorMetrics?.find(metric => metric.metricType === metricType.key)?.metricValue;
                const previousMetric = props.previousSensorMetrics?.find(metric => metric.metricType === metricType.key)?.metricValue;

                return <TableData key={index}>
                    <HealthMetricLabel metricType={metricType} currentMetric={currentMetric} previousMetric={previousMetric} />
                </TableData>
            })}
        </ClickableTableRow>

        {expanded === true ? <TableRow>
            <SensorInfoTD>
                <SensorDetails customerId={props.customerId ?? props.sensorMetrics?.[0]?.customerId} sensorId={props.sensorId} />
            </SensorInfoTD>
        </TableRow> : <></>}
    </>
}

export default SensorHealthMetric;