export const decibelWarningThreshold = 80;

const harmfulLevelThresholds = [
    {
        value: decibelWarningThreshold,
        duration: 120,
    },
    {
        value: 95,
        duration: 50,
    },
    {
        value: 100,
        duration: 15,
    },
    {
        value: 110,
        duration: 5,
    },
    {
        value: 120,
        duration: 2,
    },
]

export const getDecibelWarnings = (trend, zone) => {
    for (let dayIndex = 0; dayIndex < trend?.data?.length; dayIndex++) {
        let dayData = trend?.data?.[dayIndex];
        let dayWarnings = [];
        let warningLastHour = false;
        for (let hourIndex = 0; hourIndex < dayData?.hourData?.length; hourIndex++) {
            const hourValue = dayData?.hourData?.[hourIndex];
            if (hourValue >= harmfulLevelThresholds[0].value) {
                if (warningLastHour === false) {
                    warningLastHour = true;
                    dayWarnings.push({
                        zoneId: zone?.zoneId,
                        start: hourIndex,
                        value: hourValue,
                        duration: 1,
                        text: getWarningText(hourIndex, 1, hourValue)
                    });
                } else {
                    warningLastHour = true;
                    let lastWarning = dayWarnings[dayWarnings?.length - 1];
                    lastWarning.value = (lastWarning.value * lastWarning.duration + hourValue) / (lastWarning.duration + 1);
                    lastWarning.duration += 1;
                    lastWarning.text = getWarningText(lastWarning.start, lastWarning.duration, lastWarning.value);
                }
            } else {
                warningLastHour = false;
            }
        }
        trend.data[dayIndex].warnings = [...dayWarnings];
    }
}

const getWarningText = (start, duration, value) => {
    const end = start + duration;
    const highestThreshold = harmfulLevelThresholds[harmfulLevelThresholds.findIndex(threshold => value < threshold.value) - 1];
    return `Measured an average of ${value?.toFixed(1)} dB from ${resolveTimeText(start)} - ${resolveTimeText(end)}. 
    This level can cause hearing damage after ${highestThreshold?.duration} minutes.`;
}

export const resolveTimeText = (hour) => {
    if (hour === 12) {
        return '12 noon';
    } else if (hour === 0) {
        return '12 midnight';
    } else {
        return `${(hour % 12) + (hour < 12 ? ' am' : ' pm')}`
    }
}