
const interfaceKeys = {
    ETH0: 'eth0',
    WLAN0: 'wlan0',
    ETH0_VIRTUAL: 'eth0:0',
    WLAN0_VIRTUAL: 'wlan0:0'
}

export {
    interfaceKeys
}