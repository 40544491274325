import React from 'react';
import PopupElement from '../../UiComponents/PopupElement';
import { Indicator, ObjectDetailsContainer } from './SupportStyles';
import { getDeviceNumber } from "../../../scripts/common";
import styled from 'styled-components';
import theme from '../../../UI/theme';

const PopupContentContainer = styled.div`
    width: 20em;
    white-space: pre-wrap;
    font-size: ${theme.fontSize.small};
    color: ${theme.colors.darkSpace20};
`

const Header = styled.div`
    margin: 5px 0;
`
const StyledError = styled.div`
    margin: 5px 0;
    color: ${theme.colors.textGray};
`
const StyledInfo = styled.div`
    font-size: ${theme.fontSize.tiny};
`

const LinkLabel = styled.div`
    font-size: ${theme.fontSize.tiny};
    color: ${theme.colors.greenReadable};
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`

const ErrorObjectDetails = props => {
    const clickHandler = () => {
        props.goToSensorDetail(props.object.customerId, props.object.sensorId);
    }

    const mainContent = <><Indicator color={props.color} /> {formatObjectName(props.object, props.column.objectType)}</>;
    const popupContent = <PopupContentContainer>
        <Header>{formatObjectName(props.object, props.column.objectType)} ({getObjectID(props.object, props.column.objectType)})</Header>
        {props.object.errors.map((error, index) => {
            return <StyledError key={index}>
                <div>Error: {error.name}</div>
                <StyledInfo>{error.details}</StyledInfo>
                {error.info ? <>
                    <StyledInfo>Info: {error.info}</StyledInfo>
                </> : <></>}

                {props.column.objectType === 'sensor' ?
                    <LinkLabel
                        onTouchStart={clickHandler}
                        onClick={clickHandler}>
                        View sensor details
                    </LinkLabel>
                    : <></>}
            </StyledError>
        })}
    </PopupContentContainer>;

    return <ObjectDetailsContainer>
        <PopupElement
            mainContent={mainContent}
            popupContent={popupContent}
        />
    </ObjectDetailsContainer>
}

export default ErrorObjectDetails;

const formatObjectName = (object, objectType) => {
    var name = '';
    if (objectType === 'zone') {
        name = object.zoneName;
    } else if (objectType === 'hub') {
        name = 'Hub ' + getDeviceNumber(object);
    } else if (objectType === 'sensor') {
        name = 'Sensor ' + getDeviceNumber(object);
    } else if (objectType === 'processor') {
        name = object.name || 'Processor'
    }
    return name;
}

const getObjectID = (object, objectType) => {
    var id = '';
    if (objectType === 'zone') {
        id = object.zoneId;
    } else if (objectType === 'hub') {
        id = object.hubId;
    } else if (objectType === 'sensor') {
        id = object.sensorId;
    } else if (objectType === 'processor') {
        id = object.processorId;
    }
    return id;
}