import { Chart } from "chart.js";
import { useEffect, useRef, useState } from "react";
import theme from "../../../UI/theme";
import { DateTime } from "luxon";
import trendTypes from "../../statistics/trendTypes";

const chartData = {
    labels: [],
    datasets: [
        {
            type: 'line',
            label: 'Music',
            borderWidth: 2,
            borderColor: theme.colors.yellowFever,
            backgroundColor: theme.colors.yellowFever,
            pointRadius: 0,
            tension: 0.3,
            fill: false,
            data: []
        },
        {
            type: 'line',
            label: 'Ambience',
            borderWidth: 2,
            borderColor: theme.colors.pinky,
            backgroundColor: theme.colors.pinky,
            pointRadius: 0,
            tension: 0.3,
            fill: false,
            data: []
        },
        {
            type: 'line',
            label: 'Music trend',
            borderWidth: 2,
            borderColor: `${theme.colors.yellowFever}70`,
            borderDash: [5, 5],
            backgroundColor: `${theme.colors.yellowFever}70`,
            pointRadius: 0,
            tension: 0.3,
            fill: false,
            data: []
        },
        {
            type: 'line',
            label: 'Ambience trend',
            borderWidth: 2,
            borderColor: `${theme.colors.pinky}70`,
            borderDash: [5, 5],
            backgroundColor: `${theme.colors.pinky}70`,
            pointRadius: 0,
            tension: 0.3,
            fill: false,
            data: []
        }
    ]
}

const commonOptions = {
    maintainAspectRatio: false,
}

const fullOptions = {
    ...commonOptions,
    scales: {
        x: {
            display: true,
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: {
                    day: 'EEE',
                    hour: 'HH'
                }
            },
            grid: {
                display: true,
                drawOnChartArea: true,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70,
                drawTicks: true,
                tickColor: theme.colors.darkSpace70,
                color: theme.colors.darkSpace70
            },
            ticks: {
                color: theme.colors.textGray,
                source: 'data',
                maxRotation: 0,
            }
        },
        y: {
            display: true,
            type: 'linear',
            // max: 110,
            suggestedMax: 100,
            min: 0,
            grid: {
                display: true,
                drawOnChartArea: true,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70,
                drawTicks: true,
                tickColor: theme.colors.darkSpace70,
                color: theme.colors.darkSpace70
            },
            ticks: {
                color: theme.colors.textGray
            }
        }
    },
    plugins: {
        legend: {
            display: true,
            position: 'top',
            align: 'center',
            labels: {
                color: theme.colors.textGray,
                textAlign: 'left',
                boxWidth: 7,
                boxHeight: 7,
                padding: 5,
                pointStyle: 'rectRounded',
                usePointStyle: true
            }
        }
    }
}

const thumbnailOptions = {
    ...commonOptions,
    scales: {
        x: {
            display: false,
            type: 'time',
            time: {
                unit: 'hour'
            },
            ticks: {
                color: theme.colors.textGray
            }
        },
        y: {
            display: false,
            type: 'linear',
            // max: 110,
            min: 0
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        }
    }
}

const AutomationChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    let chartConfig;
    if (props.thumbnail) {
        chartConfig = JSON.parse(JSON.stringify({ data: chartData, options: { ...thumbnailOptions } }));
    } else {
        chartConfig = JSON.parse(JSON.stringify({ data: chartData, options: { ...fullOptions } }));
    }

    const chartConfigRef = useRef(JSON.parse(JSON.stringify(chartConfig)));

    useEffect(() => {
        if (!props.thumbnail) {
            if (props.period === trendTypes.periods.WEEK) {
                chartConfigRef.current.options.scales.x.time.unit = 'day';
            } else {
                chartConfigRef.current.options.scales.x.time.unit = 'hour';
            }

            if (chartInstance) {
                chartInstance.update();
            }
        }
    }, [props.period])

    useEffect(() => {
        const startDateTime = props.period === trendTypes.periods.WEEK ? props.dateTime?.set({}).startOf('week') : props.dateTime.set({}).startOf('day');
        chartConfigRef.current.data.datasets[0].data = props.sysvolData?.map((value, index) => {
            return {
                x: startDateTime?.set({ hour: index }).startOf('hour'),
                y: value
            }
        })
        chartConfigRef.current.data.datasets[1].data = props.noiseData?.map((value, index) => {
            return {
                x: startDateTime?.set({ hour: index }).startOf('hour'),
                y: value
            }
        })
        chartConfigRef.current.data.datasets[2].data = props.averageSysvolData?.map((value, index) => {
            return {
                x: startDateTime?.set({ hour: index }).startOf('hour'),
                y: value
            }
        })
        chartConfigRef.current.data.datasets[3].data = props.averageNoiseData?.map((value, index) => {
            return {
                x: startDateTime?.set({ hour: index }).startOf('hour'),
                y: value
            }
        })

        if (chartInstance) {
            chartInstance.update();
        }
    }, [props.sysvolData, props.noiseData])

    useEffect(() => {
        if (chartContainer?.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfigRef.current);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer])

    return (
        <canvas ref={chartContainer} />
    );
}

export default AutomationChart;