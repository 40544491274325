import { useState } from "react";
import Button from "../../../UiComponents/Button";
import Modal from "../../../UiComponents/Modal";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../panel/ModalStyles";
import { deleteSharedUser } from "../../../../scripts/sharedUser";
import { loadingStates } from "../../../installation/installationTypes";

const DeleteSharedAccountButton = props => {
    const [showModal, setShowModal] = useState(false);
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    const handleDelete = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            await deleteSharedUser(props.userId, props.customerId)
            await props.refreshWithUpdate();
            setShowModal(false);
            setLoadingState(loadingStates.NONE);
        } catch (error) {
            setLoadingState(loadingStates.FAILED);
        }
    }

    return <>
        <Button small tertiary onClick={() => setShowModal(true)}>Delete</Button>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Delete shared user account "{props.userDisplayName}"</ModalHeader>

                {loadingState === loadingStates.NONE ? <>
                    <ModalText>Are you sure you want to delete this shared user account?</ModalText>
                    <ButtonRowContainer>
                        <Button primary onClick={() => handleDelete()}>Delete</Button>
                        <Button secondary onClick={() => setShowModal(false)}>Cancel</Button>
                    </ButtonRowContainer>
                </> : <></>}

                {loadingState === loadingStates.LOADING ? <>
                    <ModalText>Deleting shared user account...</ModalText>
                </> : <></>}

                {loadingState === loadingStates.FAILED ? <>
                    <ModalText>Error: could not delete shared user account.</ModalText>
                </> : <></>}

                {loadingState === loadingStates.FAILED ? <>
                    <ButtonRowContainer>
                        <Button primary onClick={() => setShowModal(false)}>Close</Button>
                    </ButtonRowContainer>
                </> : <></>}

            </ModalContainer>
        </Modal>
    </>
}

export default DeleteSharedAccountButton;