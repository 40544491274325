import React, { useRef, useEffect, useState } from 'react';
import Paper from 'paper';
import heatmap from '../canvas/heatmap';
import styled from 'styled-components';
import theme from '../../../UI/theme';
import heatmapTypes from './heatmapTypes';

const defaultHeight = '60vh';

const StyledCanvas = styled.canvas`
  border: none;
  width: ${props => props.fixedWidth || '100%'};
  background-color: ${props => modeBackgroundColors[props.activeMode] || 'whitesmoke'};
  height: ${props => props.height || defaultHeight};
`

const Canvas = props => {
  const canvasRef = useRef(null);

  const drawMap = () => {
    const canvas = canvasRef.current;
    Paper.setup(canvas);
    var canvasAction = heatmap(props);
    props.setCanvasAction(() => canvasAction);
    if (props.onMapDraw) {
      props.onMapDraw();
    }
  }

  useEffect(() => {
    drawMap();
  }, []);
  return <StyledCanvas ref={canvasRef} {...props} id="canvas" height={props.fixedHeight} width={props.fixedWidth} resize="true" />
}

const Container = (props) => {
  const [isReady, setIsReady] = useState(true);
  const currentWidth = useRef(null);

  const reDraw = () => {
    setIsReady(false);
    setTimeout(() => {
      setIsReady(true);
    }, 100);
  }

  const resizeHook = (columnWidths) => {
    if (columnWidths && columnWidths[1] > 20) {
      reDraw();
    }
  }

  useEffect(() => {
    if (props.addResizeHook) {
      props.addResizeHook('heatmapcanvas', resizeHook);
    }
  }, [])

  useEffect(() => {
    if (props.fixedWidth) {
      if (currentWidth.current && props.fixedWidth !== currentWidth.current) {
        reDraw();
      }
      currentWidth.current = props.fixedWidth;
    }
  }, [props.fixedWidth])

  if (isReady) {
    return <Canvas {...props} />
  } else {
    return <></>
  }
}

export default Container;


const modeBackgroundColors = {
  [heatmapTypes.modes.DATA]: theme.colors.darkSpace90,
  [heatmapTypes.modes.SETUP]: theme.colors.darkSpace30,
  [heatmapTypes.modes.DOCUMENTATION]: theme.colors.darkSpace30
}