import React, { useState, useEffect } from 'react';
import { getAllCustomers } from '../../../scripts/datasource';
import Modal from '../../UiComponents/Modal';
import { CustomerList, ListItem, ImpersonateContainer, ImpersonateButton, ScrollPadding, CustomerInfoContainer, CurrentImpersonationLabel, TopContainer, ImpersonatorInfoContainer, SearchContainer } from './ImpersonateStyles';
import { FaChartBar, FaLock, FaUserNinja } from 'react-icons/fa';
import Button from '../../UiComponents/Button';
import { isMobile } from '../../../scripts/common';
import { useHistory } from 'react-router';
import CloseButton from '../../UiComponents/buttons/CloseButton';
import { TextMuted } from '../../UiComponents/text/StyledText';
import SearchField from '../../UiComponents/SearchField';
import { useRef } from 'react';
import { ModalContainer, ButtonRowContainer } from '../../panel/ModalStyles';
import CountrySelector, { getCountry } from './CountrySelector';

const Impersonate = props => {
    const history = useHistory();
    const [customers, setCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const [searchString, setSearchString] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const impersonateRef = useRef();

    const mobile = isMobile();
    const isImpersonating = props.customer && props.user.defaultVenueId !== props.customer.customerId;

    useEffect(() => {
        getAllCustomers().then(customers => {
            if (customers.length > 0) {
                customers.sort((a, b) => a > b ? 1 : -1);
                setCustomers(customers);
                setFilteredCustomers(customers);
            }
        })
    }, []);

    useEffect(() => {
        if (isMobile() && impersonateRef?.current) {
            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;
            const refLeft = impersonateRef?.current?.offsetLeft;
            const refTop = impersonateRef?.current?.offsetTop;
            const refWidth = impersonateRef?.current?.offsetWidth;
            const refHeight = impersonateRef?.current?.offsetHeight;

            if (refLeft + refWidth / 2 > screenWidth / 2) {
                impersonateRef.current.style.left = `${screenWidth - refWidth - 10}px`;
            } else {
                impersonateRef.current.style.left = '10px';
            }

            if (refTop + refHeight > screenHeight - 10) {
                impersonateRef.current.style.top = `${screenHeight - refHeight - 10}px`;
            } else if (refTop < 10) {
                impersonateRef.current.style.top = '10px';
            }
        }
    }, [impersonateRef?.current?.offsetWidth, impersonateRef?.current?.offsetHeight])

    useEffect(() => {
        setFilteredCustomers(customers?.filter(customer => {
            const isInFilteredCountry = selectedCountry === null || getCountry(customer) === selectedCountry?.key;

            let upperCaseSearchString = searchString?.toUpperCase();
            return isInFilteredCountry &&
                (customer?.customerId?.toString()?.toUpperCase()?.includes(upperCaseSearchString) ||
                    customer?.companyName?.toUpperCase()?.includes(upperCaseSearchString) ||
                    customer?.email?.toUpperCase()?.includes(upperCaseSearchString));
        }))
    }, [searchString, selectedCountry])

    function setImpersonation(customerId) {
        props.setCustomer(customerId);
        setShowModal(false);
    };

    function goToDetails(customerId) {
        history.push(`/supportcenter/customerdetails/${customerId}`);
        window.scrollTo(0, 0);
        setShowModal(false);
    }

    function touchMoveHandler(e) {
        setIsDragging(true);

        const refWidth = impersonateRef?.current?.offsetWidth;
        const refHeight = impersonateRef?.current?.offsetHeight;
        const lastX = e.changedTouches[0].clientX;
        const lastY = e.changedTouches[0].clientY;
        impersonateRef.current.style.transition = 'all 0s';
        impersonateRef.current.style.left = `${lastX - refWidth / 2}px`;
        impersonateRef.current.style.top = `${lastY - refHeight / 2}px`;
    }

    function touchEndHandler(e) {
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const refWidth = impersonateRef?.current?.offsetWidth;
        const refHeight = impersonateRef?.current?.offsetHeight;
        const lastX = e.changedTouches[0].clientX;
        const lastY = Math.min(Math.max(e.changedTouches[0].clientY, refHeight / 2 + 10), screenHeight - refHeight / 2 - 10);

        if (isDragging) {
            if (lastX > (screenWidth / 2)) {
                impersonateRef.current.style.left = `${screenWidth - refWidth - 10}px`
            } else {
                impersonateRef.current.style.left = `10px`;
            }
            impersonateRef.current.style.top = `${lastY - refHeight / 2}px`;
            impersonateRef.current.style.transition = 'all 0.2s';
        }
    }

    const customerList = filteredCustomers.map(customer => {
        return <ListItem
            key={customer.customerId}
            onClick={() => setImpersonation(customer.customerId)}
            current={customer.customerId === props.customer?.customerId}
        >
            <label>{customer.customerId}</label>

            <CustomerInfoContainer>
                <label>{customer?.companyName}</label>
                <TextMuted>{customer?.address}</TextMuted>
            </CustomerInfoContainer>

            <div>
                {customer.lockedOut ? <span style={{ marginRight: '8px' }}>
                    <FaLock />
                </span> : <></>}
                <Button
                    secondary
                    small
                    onClick={(e) => { goToDetails(customer.customerId); e.stopPropagation() }}>
                    <FaChartBar />
                </Button>
            </div>

        </ListItem>
    });

    return <>

        {showButton ? <>
            <ImpersonateContainer
                draggable
                ref={impersonateRef}
                onTouchStart={(e) => { setIsDragging(false); }}
                onTouchMove={(e) => { touchMoveHandler(e) }}
                onTouchEnd={(e) => { touchEndHandler(e); }}>
                <ImpersonateButton icon onClick={() => setShowModal(true)}>
                    <FaUserNinja />
                    {isImpersonating ?
                        <CurrentImpersonationLabel>
                            {props.customer?.customerId}{!mobile ? ` : ${props.customer?.companyName}` : ''}
                        </CurrentImpersonationLabel> : <>
                            {!isMobile() ? <span>Impersonate</span> : <></>}
                        </>}
                </ImpersonateButton>
            </ImpersonateContainer>
        </> : <></>}

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <TopContainer>
                    <ImpersonatorInfoContainer>
                        <label>You</label>
                        <TextMuted>Username: {props.user.username}</TextMuted>
                        <TextMuted>Role: {props.user.role}</TextMuted>
                        {isImpersonating ? <CurrentImpersonationLabel>Impersonating: {props.customer?.customerId} - {props.customer?.companyName}</CurrentImpersonationLabel> : <></>}
                    </ImpersonatorInfoContainer>

                    <CloseButton secondary onClick={() => setShowModal(false)} />
                </TopContainer>

                <ButtonRowContainer>
                    {isImpersonating ? <>
                        <Button small secondary onClick={() => setImpersonation(props.user.defaultVenueId)}>End</Button>
                    </> : <></>}
                    <Button small secondary onClick={() => { setShowButton(false); setShowModal(false) }}>Hide</Button>

                    <Button small secondary onClick={() => { setImpersonation(props.user.defaultVenueId); history.push("/supportcenter"); setShowModal(false); }}>Support center</Button>
                </ButtonRowContainer>

                <SearchContainer>
                    <SearchField value={searchString} onChange={(e) => setSearchString(e.target.value)} />

                    <CountrySelector selectedCountry={selectedCountry} customers={customers} onSelect={(country) => setSelectedCountry(country)} />
                </SearchContainer>

                <CustomerList>
                    {customerList}
                </CustomerList>
            </ModalContainer>
        </Modal>
    </>
}

export default Impersonate;