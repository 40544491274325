import styled from "styled-components";
import theme from "../../../../../UI/theme";

export const LiveCalibrationContainer = styled.div`
    padding: 10px;
    box-sizing: border-box;
    border: 2px solid ${theme.colors.darkSpace80};
    box-sizing: border-box;
`

export const RuleContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 10px;
    align-items: center;
    border-radius: 2px;
    box-sizing: border-box;
    color: ${theme.colors.textGray};
    background-color: ${theme.colors.darkSpace};
`

export const LiveCalibrationSliderContainer = styled.div`
    box-sizing: border-box;
    background-color: ${theme.colors.darkSpace};
    border-radius: 2px;
`

export const PointContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto auto;
    grid-gap: 10px;
    align-items: center;
`

export const ButtonContainer = styled.div`
    display: flex;
    &>button:not(:last-of-type) {
        margin-right: 10px;
    }
`

export const ErrorLabel = styled.label`
    color: ${theme.colors.raspberryRed};
`