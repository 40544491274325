import { useState } from "react";
import { loadingStates } from "../installation/installationTypes";
import { TextMuted } from "../UiComponents/text/StyledText";
import { ButtonRowContainer } from "../panel/ModalStyles";
import Button from "../UiComponents/Button";
import { addInsallerPermissions } from "../../scripts/permission";
import InputField from "../UiComponents/InputField";
import styled from "styled-components";
import theme from "../../UI/theme";

const Container = styled.div`
    h4, p {
        margin: 0 0 6px 0;
        padding: 0;
    }
    h4 {
        font-weight: normal;
    }
    p {
        color: ${theme.colors.textGray};
        font-size: ${theme.fontSize.small};
    }
`

const InputFieldContainer = styled.div`
`

const states = {
    ...loadingStates,
    ADD: 'ADD'
}

const AddInstallerPermission = ({ customerSetupKey }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [state, setState] = useState(states.NONE);

    const addPermissions = async () => {
        try {
            setState(states.LOADING);
            await addInsallerPermissions(username, password, customerSetupKey);
            setState(states.SUCCESS);
        } catch (err) {
            setState(states.FAILED);
        }
    }

    if (state === states.SUCCESS) {
        return <TextMuted>Installer permission added to <span style={{color: 'white'}}>{username}</span></TextMuted>
    } else if (state === states.NONE) {
        return <Button primary small onClick={() => setState(states.ADD)}>Add Installer Permission</Button>
    } else {
        return <Container>
            <h4>Installer permission</h4>
            <p>Submit your waved credentials to add remote access to this venue from your account</p>
            <InputFieldContainer>
                <TextMuted>Username</TextMuted>
                <div>
                    <InputField small value={username} onChange={e => setUsername(e.target.value)} type="username" required />
                </div>
            </InputFieldContainer>
            <InputFieldContainer>
                <TextMuted>Password</TextMuted>
                <div>
                    <InputField small value={password} onChange={e => setPassword(e.target.value)} type="password" required />
                </div>
            </InputFieldContainer>
            {state === states.FAILED ? <>
                Failed to add installer permissions
            </> : <></>
            }
            {state === states.ADD || state === states.FAILED ? <>
                <ButtonRowContainer style={{marginTop: '12px'}}>
                    <Button primary small onClick={addPermissions}>{state === states.ADD ? 'Submit' : 'Retry'}</Button>
                    <Button secondary small onClick={() => setState(states.NONE)}>Cancel</Button>
                </ButtonRowContainer>
            </> : <></>
            }
        </Container>
    }


}

export default AddInstallerPermission;