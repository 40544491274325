import { useState } from "react";
import styled from "styled-components";
import Button from "../../../UiComponents/Button";
import Checkbox from "../../../UiComponents/Checkbox";
import ButtonContainer from "../../../UiComponents/containers/ButtonContainer";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import ScheduleDays from "./ScheduleDays"
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../panel/ModalStyles";

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const ScheduleFilter = props => {
    const [days, setDays] = useState(props.filter?.days ?? []);
    const [zones, setZones] = useState(props.filter?.zones ?? []);

    const selectZoneHandler = (zone) => {
        var newZones = [...zones];
        if (newZones.includes(zone)) {
            newZones = newZones.filter(z => z !== zone);
        } else {
            newZones.push(zone);
        }
        setZones(newZones);
    }

    const applyFilter = () => {
        if (days.length === 0 && zones.length === 0) {
            props.setFilter(null);
        } else {
            props.setFilter({ days: days, zones: zones });
        }
        props.setShowFilterModal(false);
    }

    return <ModalContainer>
        <ModalHeader>Filter</ModalHeader>

        <ModalText>Zones</ModalText>
        <div>
            {props.zones?.map((zone, index) => {
                return <RowContainer key={index}>
                    <Checkbox checked={zones.includes(zone.zoneId)} onChange={() => selectZoneHandler(zone.zoneId)} />
                    <TextMuted>&nbsp;{zone.zoneName}</TextMuted>
                </RowContainer>
            })}
        </div>

        <ModalText>Days</ModalText>
        <ScheduleDays editable days={days} onSelectDay={(newDays) => setDays(newDays)} />

        <ButtonRowContainer>
            <Button primary onClick={() => applyFilter()}>Apply filter</Button>
            <Button secondary onClick={() => props.setShowFilterModal(false)}>Cancel</Button>
        </ButtonRowContainer>
    </ModalContainer>
}

export default ScheduleFilter;