import React from 'react';
import { daysShort } from '../../../../scripts/common';
import Selector from '../../../UiComponents/Selector';

const WeekDaySelector = props => {

    var items = daysShort.map((wd, i) => ({ display: wd, key: i }));
    var selectedItem = items.find(item => item.key === props.activeDay);

    return (<>
        <Selector
            items={items}
            selectedItem={selectedItem}
            onClick={(item) => props.setActiveDay(item.key)}
        />
    </>
    )
}

export default WeekDaySelector;