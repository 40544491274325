import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import useServerStatus from '../../../hooks/useServerStatus';
import theme from '../../../UI/theme';
import GroupMuteButton from './GroupMuteButton';
import GroupRegulationButton from './GroupRegulationButton';
import PanelButton from '../PanelButton';
import { sortZones } from '../panelUtils';
import SourceSelector from '../SourceSelector';
import Zone from '../zone/Zone';
import GroupVibeButton from './GroupVibeButton';
import { GridContainer, GroupContainer, GroupHeader, GroupRow, GroupSettingsContainer, SubGrid } from './groupStyles';
import hierarchyTypes from '../../settings/venue/device/hierarchyTypes';

const OLD_DATA_LIMIT_SECONDS = 10;

const Group = props => {
    const serverStatus = useServerStatus();
    const [expanded, setExpanded] = useState(props.zoneGroupPreferences?.find(preference => preference?.zoneGroupId === props?.zoneGroupId)?.expanded === false ? false : true);
    const unhiddenZones = props.zones?.filter(zone => zone.hide === 0);
    let groupIsRegulating = unhiddenZones?.some(zone => zone.shouldRegulate === 1 && zone.isRegulating === 1);
    let groupMuted = unhiddenZones?.every(zone => zone.mute === 1);
    const sortedZones = sortZones(unhiddenZones);
    const sourceSelectorGroups = groupZones(sortedZones);
    const showingSourceSelectors = props?.showingSourceSelectors;
    const groupConnected = props.processors?.some(processor => checkProcessorConnected(processor, props.hubs?.find(hub => hub.hubId === processor.hubId), serverStatus));

    const handleExpandChange = (newValue) => {
        setExpanded(newValue);

        let newZoneGroupPreferences = JSON.parse(JSON.stringify(props?.zoneGroupPreferences));
        let zoneGroupIndex = newZoneGroupPreferences?.findIndex(preference => preference?.zoneGroupId === props?.zoneGroupId);
        if (zoneGroupIndex > -1) {
            newZoneGroupPreferences[zoneGroupIndex] = { ...newZoneGroupPreferences[zoneGroupIndex], expanded: newValue };
        } else {
            newZoneGroupPreferences.push({ zoneGroupId: props?.zoneGroupId, expanded: newValue });
        }

        props?.setZoneGroupPreferences(newZoneGroupPreferences);
        localStorage.setItem('zoneGroupPreferences', JSON.stringify(newZoneGroupPreferences));
    }

    return <>
        {unhiddenZones?.length > 0 ?
            <GroupContainer onClick={(e) => e.preventDefault()} expanded={expanded}>
                <GroupRow expanded={expanded}>
                    <GroupHeader>{props?.zoneGroup?.name ?? props.zoneGroupName ?? 'Zones'}</GroupHeader>
                    <GroupSettingsContainer>
                        <PanelButton onClick={() => handleExpandChange(!expanded)}>
                            {expanded === true ? <>
                                <FaChevronUp />
                            </> : <>
                                <FaChevronDown />
                            </>}
                        </PanelButton>

                        <GroupVibeButton customerId={props.customerId} zones={unhiddenZones} increment={-1} />
                        <GroupVibeButton customerId={props.customerId} zones={unhiddenZones} increment={1} />

                        <GroupMuteButton
                            zones={unhiddenZones}
                            groupMuted={groupMuted}
                            customerId={props.customerId}
                            disabled={!groupConnected}
                        />

                        <GroupRegulationButton
                            zones={unhiddenZones?.filter(zone => zone.shouldRegulate === 1)}
                            groupIsRegulating={groupIsRegulating}
                            customerId={props.customerId}
                            disabled={!groupConnected || !props.zones?.some(zone => zone.shouldRegulate === 1 && zone?.hierarchyType !== hierarchyTypes.MASTER.value)}
                            unavailable={!props.zones?.some(zone => zone?.shouldRegulate === 1 && zone?.hierarchyType !== hierarchyTypes.MASTER.value)}
                        />
                    </GroupSettingsContainer>
                </GroupRow>

                <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
                    <GridContainer>
                        {[...sourceSelectorGroups]?.map((group, index) => {
                            const sourceSelector = props.sourceSelectors?.find(sourceSel => sourceSel.sourceSelectorId === group?.[0]);
                            let sourceSelectorProcessor;
                            let sourceSelectorSources;
                            let sourceSelectorHub;
                            let sourceSelectorProcessorConnected;

                            if (sourceSelector) {
                                sourceSelectorProcessor = props.processors?.find(processor => sourceSelector.processorId === processor.processorId);
                                sourceSelectorSources = props.sources?.filter(source => source.processorId === sourceSelector.processorId)
                                sourceSelectorHub = props.hubs?.find(hub => sourceSelectorProcessor?.hubId === hub.hubId);
                                sourceSelectorProcessorConnected = checkProcessorConnected(sourceSelectorProcessor, sourceSelectorHub, serverStatus);
                            }

                            return <SubGrid key={index}>

                                {sourceSelector ?
                                    <SourceSelector
                                        key={sourceSelector.sourceSelectorId}
                                        rowSpan={sortedZones?.filter(zone => zone.sourceSelectorId === sourceSelector.sourceSelectorId)?.length}
                                        sourceSelector={sourceSelector}
                                        setSourceSelector={props.setSourceSelector}
                                        processor={sourceSelectorProcessor}
                                        sources={sourceSelectorSources}
                                        processorConnected={sourceSelectorProcessorConnected}
                                        disabled={!sourceSelectorProcessorConnected}
                                    />
                                    : <></>}

                                {group?.[1]?.map(zone => {
                                    const zoneProcessor = props.processors?.find(processor => zone.processorId === processor.processorId);
                                    const zoneHub = props.hubs?.find(hub => zone.hubId === hub.hubId);
                                    const zoneSourceSelector = props.sourceSelectors?.find(sourceSelector => zone.sourceSelectorId === sourceSelector.sourceSelectorId);
                                    const zoneProcessorConnected = checkProcessorConnected(zoneProcessor, zoneHub, serverStatus);
                                    const isRegulatingAudioSource = !zoneSourceSelector
                                        || props.sources?.find(source => source.sourceId === zoneSourceSelector?.activeSourceId)?.regulate == true
                                        || props.sources?.length === 0 ? true : false;

                                    return <>
                                        <Zone
                                            key={zone.zoneId}
                                            zone={zone}
                                            setZone={props.setZone}
                                            hub={zoneHub}
                                            groupHasSourceSelectors={showingSourceSelectors}
                                            hasSourceSelector={zoneSourceSelector ? true : false}
                                            processorConnected={zoneProcessorConnected}
                                            schedules={props.schedules?.filter(schedule => schedule.zones?.includes(zone.zoneId))}
                                            isRegulatingAudioSource={isRegulatingAudioSource}
                                        />
                                    </>
                                })}

                            </SubGrid>
                        })}
                    </GridContainer>
                </AnimateHeight>
            </GroupContainer>
            : <></>}
    </>
}

export default Group;

const checkProcessorConnected = (processor, hub, serverStatus) => {
    return (hub?.secondsSinceLastUpdate <= OLD_DATA_LIMIT_SECONDS &&
        processor?.isConnected &&
        serverStatus);
}

const groupZones = (zones) => {
    const map = new Map();
    for (let index = 0; index < zones.length; index++) {
        const zone = zones[index];
        const key = zone?.sourceSelectorId ?? zone.zoneId;
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [zone]);
        } else {
            collection.push(zone);
        }
    }

    return map;
}