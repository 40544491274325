import { useEffect, useState } from "react";
import PermissionsTable from "../PermissionsTable";
import { getPermissions } from "../../../../../scripts/permission";
import { loadingStates } from "../../../../installation/installationTypes";
import { permissionTableTypes } from "../userAdministrationUtils";
import LoadDataContainer, { loadDataHandler } from "../../../../UiComponents/containers/LoadDataContainer";

const VenuePermissionsTable = ({ venue, users, permissionTableRef, setViewState, refreshUsers }) => {
    const [permissions, setPermissions] = useState();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [venues, setVenues] = useState([venue]);

    useEffect(() => {
        loadDataHandler(getData, setLoadingState)
    }, [venue.customerId]);

    const getData = async () => {
        const permissionsRes = await getPermissions(venue.customerId);
        setPermissions(permissionsRes);
        setVenues([venue]);
        return permissionsRes;
    }

    return <>
        <LoadDataContainer loadingState={loadingState}>
            {venues && permissions ? <>
                <PermissionsTable
                    tableType={permissionTableTypes.venue}
                    venues={venues}
                    users={users}
                    originalPermissions={permissions}
                    refresh={getData}
                    refreshUsers={refreshUsers}
                    permissionTableRef={permissionTableRef}
                    setViewState={setViewState}
                />
            </> : <></>}
        </LoadDataContainer>
    </>
}

export default VenuePermissionsTable;

