import React from 'react';
import styled from 'styled-components';
import heatmapTypes from '../heatmapTypes';
import theme from '../../../../UI/theme';

const Container = styled.span`
    position: absolute;
    top: 16px;
    right: 16px;
    font-weight: bold;
    padding: 8px;
    border-radius: 4px;
    background-color: ${theme.colors.activeBlue};
    color: white;

    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        display: none;
    }
`

const LiveIcon = props => {

    if (props.activeDataMode === heatmapTypes.dataModes.LIVE) {
        return <Container>
            Live
        </Container>
    } else {
        return <></>;
    }
    
}

export default LiveIcon;



