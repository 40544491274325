import styled, { css } from "styled-components";
import theme from "../../../UI/theme";
import { useSubscription } from "../../../hooks/useSubscription";
import { subscriptionsTypes } from "../../../scripts/subscription/subscriptionTypes";
import LiveDecibelChartContainer from "./LiveDecibelChartContainer";
import { decibelWarningThreshold } from "../decibel/decibelInsightsUtils";
import { CardHeader } from "../insightsStyles";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-gap: 10px;
    }
`

const ZoneGridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-template-columns: 1fr;
        grid-gap: 10px;
    }
`

const ZoneContainer = styled.div`
    background-color: ${theme.colors.darkSpace90};
    box-sizing: border-box;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    color: ${theme.colors.textGray};
    overflow: hidden;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        padding: 10px;
    }
`

const LiveDecibelLabel = styled.label`
    color: ${theme.colors.greenEnergy};
    font-size: 2rem;
    font-weight: 100;
    white-space: nowrap;
    justify-self: end;

    ${props => props.value > decibelWarningThreshold && css`
        color: ${theme.colors.raspberryRed};
    `}
`

const LiveInsights = props => {
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);
    const [zoneLives, setZoneLives] = useSubscription(subscriptionsTypes.zoneLives);
    const [sensors, setSensors] = useSubscription(subscriptionsTypes.sensors);

    return <Container>
        <ZoneGridContainer>
            {zones?.filter(zone => zone.shouldRegulate === 1 && zone.hide === 0 && sensors?.some(sensor => sensor?.zoneId === zone?.zoneId || sensor?.zoneId === zone?.backupZoneId))
                ?.sort((a, b) => a?.orderIndex > b?.orderIndex)
                ?.map(zone => {
                    const zoneLive = zoneLives?.find(zl => zl.zoneId === zone?.zoneId);
                    return <ZoneContainer key={zone?.zoneId}>
                        <CardHeader>{zone?.zoneName}</CardHeader>
                        <LiveDecibelLabel value={zoneLive?.averageDecibel}>{zoneLive?.averageDecibel > 0 ? `${zoneLive?.averageDecibel?.toFixed(1)} dB` : 'N/A'}</LiveDecibelLabel>
                        <LiveDecibelChartContainer zone={zone} zoneLive={zoneLive} />
                    </ZoneContainer>
                })}
        </ZoneGridContainer>
    </Container>
}

export default LiveInsights;