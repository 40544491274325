import { useRef } from "react";
import { useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import UserContext from "../../hooks/UserContext";
import { postEvent } from "../../scripts/event";
import { isLocalApp } from "../../scripts/server/server";
import { postUserInteraction } from "../../scripts/userInteraction";
import theme from "../../UI/theme";
import { userInteractionTypes } from "../admin/customerDetails/userInteractionTypes";
import { DragIndicatorIcon } from "../UiComponents/Icons/materialIcons";
import WaMinus from "../UiComponents/Icons/WaMinus";
import WaPlus from "../UiComponents/Icons/WaPlus";
import { hasVenuePermission, permissionTypes } from "../permission/permissions";

const SliderContainer = styled.div`
    animation: 0.5s ease-out 0s 1 scaleUpX;

    --thumbSize: 50;
    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        --thumbSize: 40;
    }

    position: relative;
    //width: ${props => !isNaN(props.maximum) && props.maximum > 0 && props.maximum <= 100 ? `calc(var(--thumbSize)*1px + ${props.maximum}%)` : '100%'};
    width: 100%;
    max-width: 100%;
    height: 50px;
    background-color: ${theme.colors.darkSpace90};
    border-radius: 2px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        height: 40px;
    }

    ${props => props.disabled && css`
        opacity: 40%;
    `}
`

const StyledSlider = styled.input.attrs({ type: 'range' })`
    -webkit-appearance: none;
    border-radius: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    outline: none;
    border: none;
    -webkit-transition: .2s;
    transition: opacity .2s;
    pointer-events: none;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 50px;
        height: 50px;
        background: ${theme.colors.greenEnergy};
        cursor: pointer;
        pointer-events: all;
        border-radius: 2px;

        @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
            width: 40px;
            height: 40px;
        }
    }

    &::-moz-range-thumb {
        width: 50px;
        height: 50px;
        background: ${theme.colors.greenEnergy};
        cursor: pointer;
        pointer-events: all;
        border-radius: 2px;
        border: none;

        @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
            width: 40px;
            height: 40px;
        }
    }
    
    ${props => props.disabled && css`
        &::-webkit-slider-thumb {
            background: ${theme.colors.darkSpace70};
            cursor: default;
        }

        &::-moz-range-thumb {
            background: ${theme.colors.darkSpace70};
            cursor: default;
        }
    `}
`

const ValueBubble = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    color: white;
    background-color: ${theme.colors.darkSpace80}99;
    border-radius: 2px;
    aspect-ratio: 1;
    height: 20px;
    top: -40px;
    z-index: 10;
    left: ${props => !isNaN(props.value) && !isNaN(props.maximum) ? `calc(${props.value * 100 / props.maximum}% + var(--thumbSize)*(${(0.5 - props.value / props.maximum)}px))` : '50%'};
    visibility: ${props => props.visibility ?? 'hidden'};
    transform: translate(-50%, 0);
`

const DragIndicator = styled(DragIndicatorIcon)`
    position: absolute;
    color: ${theme.colors.darkSpace};
    opacity: 40%;
    width: 20px;
    aspect-ratio: 1;
    top: 50%;
    left: ${props => !isNaN(props.value) && !isNaN(props.maximum) ? `calc(${props.value * 100 / props.maximum}% + var(--thumbSize)*(${(0.5 - props.value / props.maximum)}px))` : '0'};
    transform: translate(-50%, -50%);
    cursor: pointer;
    pointer-events: none;
`

const ClickableArea = styled.div`
    @keyframes flash {
        0% { background-color: ${theme.colors.darkSpace80}; }
        100% { background-color: ${theme.colors.darkSpace80}00; }
    }

    @keyframes hoverFlash {
        0% { background-color: ${theme.colors.darkSpace80}00; }
        100% { background-color: ${theme.colors.darkSpace80}; }
    }

    height: calc(var(--thumbSize) * 1px);
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    ${props => props.disabled && css`
        pointer-events: none;
        cursor: inherit;
    `}

    :not(:active) {
        animation: flash 0.7s;
    }

    > svg {
        stroke: ${theme.colors.textGray};
        width: 1rem;
        height: 1rem;
        margin: 0 10px;
        pointer-events: none;
        overflow: visible;
    }

    @media (hover:hover) {
        &:hover {
            :not(:active) {
                animation: none;
            }
            :active {
                animation: hoverFlash 0.7s;
            }

            ${props => !props.disabled && css`
                cursor: pointer;
                background-color: ${theme.colors.darkSpace80};
            `}
        }
    }
`

const IncreaseVolumeArea = styled(ClickableArea)`
    width: ${props => `calc(100% - ${props.value * 100 / props.maximum}% + (1px * ${(props.value - props.maximum) / props.maximum} * var(--thumbSize)))`};
    right: 0;
    justify-content: flex-end;
`

const DecreaseVolumeArea = styled(ClickableArea)`
    width: ${props => `calc(${props.value * 100 / props.maximum}% - var(--thumbSize) * 1px * ${props.value / props.maximum})`};
    left: 0;
    justify-content: start;
`

const VolumeSlider = props => {
    const throttleInterval = isLocalApp() ? 100 : 1000;
    const userContext = useContext(UserContext);
    const sliderRef = useRef();
    const [volume, setVolume] = useState(0);
    const [isChangingVolume, setIsChangingVolume] = useState(false);
    const maximum = props.maximum ?? 100;

    useEffect(() => {
        if (isChangingVolume === false) {
            setVolume(props.volume ?? 0);
        }
    }, [props.volume]);

    const onChangeHandler = (newValue) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }

        newValue = Number(newValue);
        setIsChangingVolume(true);
        throttleCommitVolume(newValue, throttleInterval);
        setVolume(newValue);

        if (isChangingVolume === false) {
            if (props.onChangeStarted) {
                props.onChangeStarted(newValue);
            }
        }

        if (props.onChange) {
            props.onChange(newValue);
        }
    }

    const throttleCommitVolume = (newValue, timeFrame) => {
        var now = new Date().getTime();
        if (now - props.lastVolumeCommit >= timeFrame) {
            commitVolume(newValue, true);
            props.setLastVolumeCommit(now);
        }
    }

    const commitVolume = async (newValue, preventEventLog = false) => {
        await postEvent(props.zone.hubId, props.zone.customerId, 'SET_ABSOLUTE_VOLUME_DIRECT', { zoneId: props.zone.zoneId, value: newValue, preventEventLog: preventEventLog });
    }

    const onChangeCommittedHandler = async (newValue) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }
        newValue = Number(newValue);
        setIsChangingVolume(false);
        setVolume(newValue);
        await commitVolume(newValue);

        postUserInteraction(props.zone?.customerId, {
            setting: userInteractionTypes.directVolume.key,
            zoneId: props.zone?.zoneId,
            toValue: newValue
        });

        if (props.onChangeCommitted) {
            props.onChangeCommitted(newValue);
        }
    }

    const incrementVolume = async (increment) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }
        var now = new Date().getTime();
        let newVolume = volume + increment;
        setVolume(newVolume);
        await commitVolume(newVolume);

        if (props.setLastVolumeCommit) {
            props.setLastVolumeCommit(now);
        }

        postUserInteraction(props.zone?.customerId, {
            setting: userInteractionTypes.directVolume.key,
            zoneId: props.zone?.zoneId,
            toValue: newVolume
        });

        if (props.onChangeCommitted) {
            props.onChangeCommitted(newVolume);
        }
    }

    return <SliderContainer
        className={props.className}
        disabled={props.disabled}
        maximum={maximum}
    >
        <ValueBubble
            value={Number(sliderRef.current?.value)}
            maximum={maximum}
            visibility={isChangingVolume ? 'visible' : 'hidden'}
        >
            {volume}%
        </ValueBubble>
        <DragIndicator
            value={volume}
            maximum={maximum}
        />

        <IncreaseVolumeArea
            onClick={() => incrementVolume(1)}
            value={volume}
            disabled={volume >= 100 || props.disabled}
            maximum={maximum}>
            <WaPlus />
        </IncreaseVolumeArea>
        <DecreaseVolumeArea
            onClick={() => incrementVolume(-1)}
            value={volume}
            disabled={volume <= 0 || props.disabled}
            maximum={maximum}>
            <WaMinus />
        </DecreaseVolumeArea>

        <StyledSlider
            ref={sliderRef}
            disabled={props.disabled}
            value={volume}
            min={0}
            max={maximum}
            onChange={(e) => onChangeHandler(e.target.value)}
            onMouseUp={(e) => onChangeCommittedHandler(e.target.value)}
            onTouchEnd={(e) => onChangeCommittedHandler(e.target.value)}
            onClick={(e) => e.stopPropagation()}
        />
    </SliderContainer>
}

export default VolumeSlider;