import Paper, { Path, Size, Rectangle } from 'paper';
import Device from '../device/Device';
import canvasController from '../CanvasController';
import heatmapTypes from '../../container/heatmapTypes';
import theme from '../../../../UI/theme';
import logo from '../../images/STREK.svg';

const params = {
    fillColor: '#2C2C2C',
    size: 1.2,
    radius: 0.2
}

class Sensor extends Device {

    constructor(sensorInfo, layer) {
        super(sensorInfo, layer, params);
    }

    async drawElementInGroup(point) {
        const rectangle = new Rectangle(point, new Size(params.size, params.size));
        const radius = new Size(params.radius, params.radius);
        const path = new Path.Rectangle(rectangle, radius);
        path.position = point;
        path.fillColor = this.elementParams.fillColor;
        this.elementPath = path;
        this.element.addChild(path);

        this.statusPath = await this.drawStatusPath();
        this.element.addChild(this.statusPath);
    }

    addDataModeEventHandlers() {
        const onClickHandler = (event) => {
            if (this.layer.sensorData) {
                const sd = this.layer.sensorData.find(s => s.sensorId === this.id);
                if (sd) {
                    const data = {
                        ...sd,
                        pageX: event.event.pageX,
                        pageY: event.event.pageY,
                    }
                    canvasController.canvasCallback(heatmapTypes.actions.SHOW_SENSOR_INFO, data);
                }
            }
        }
        if (this.elementPath) {
            this.elementPath.onClick = onClickHandler;
        }
        if (this.statusPath) {
            this.statusPath.onClick = onClickHandler;
        }
    }

    setStatus(status) {
        this.status = status;
        if (!this.statusPath) {
            return;
        }
        if (status === 'CONNECTED') {
            this.statusPath.fillColor = theme.colors.greenEnergy;
        } else if (status === 'DISCONNECTED') {
            this.statusPath.fillColor = theme.colors.raspberryRed;
        } else {
            this.statusPath.fillColor = theme.colors.mellowYellow;
        }
    }

    showConnectionStatus() {
        const status = this.connectedState ? 'CONNECTED' : 'DISCONNECTED';
        this.setStatus(status);
    }

    drawStatusPath() {
        return new Promise((resolve, reject) => {
            this.layer.layer.activate();
            if (this.elementPath && !this.logoGroup) {
                Paper.project.importSVG(logo, {
                    onLoad: (group) => {
                        this.layer.layer.activate();
                        group.position = Paper.view.bounds.topLeft;
                        group = group.clone();
                        group.fitBounds(this.elementPath.bounds);
                        group.scale(0.8);
                        group.insertAbove(this.elementPath);
                        group.fillColor = 'transparent';
                        resolve(group);
                    }
                });
            }
        })
    }

    removeStatusPath() {
        if (this.logoGroup) {
            this.logoGroup.remove();
            this.logoGroup = null;
        }
    }

}

export default Sensor;


