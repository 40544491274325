import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/sourceMatrix';

async function getSourceMatrixes(customerId) {
    const res = await axios.get(`${hostname}${path}/sourceMatrixes?customerId=${customerId}`);
    return res.data;
}

async function getSourceMatrix(sourceMatrixId) {
    const res = await axios.get(`${hostname}${path}?sourceMatrixId=${sourceMatrixId}`);
    return res.data;
}

async function updateSourceMatrixes(sourceMatrixes) {
    const res = await axios.put(`${hostname}${path}/list`, sourceMatrixes);
    return res.data;
}

async function addSourceMatrix(sourceMatrix) {
    const res = await axios.post(`${hostname}${path}`, sourceMatrix);
    return res.data;
}

async function deleteSourceMatrix(sourceMatrixId) {
    const res = await axios.delete(`${hostname}${path}?sourceMatrixId=${sourceMatrixId}`);
    return res.data;
}


export {
    getSourceMatrixes,
    getSourceMatrix,
    addSourceMatrix,
    deleteSourceMatrix,
    updateSourceMatrixes
}