import styled from 'styled-components';

export const MT10Container = styled.div`
    margin-top: 10px;
`

export const ButtonRowContainer = styled(MT10Container)`
    margin: 0;
    button {
        margin: 10px 10px 0 0;
    }    
`