import { useState } from "react";
import { isSameSubnet } from "../../../settings/venue/device/NetworkConfig/networkUtils";
import { addVirtualInterface, getDefaultSubnetIp, getVirtualInterfaceIpFromProcessorIp, hasIpAddressesOnSameSubnet } from "../hub/hubUtils";
import EditableForm from "../../table/EditableForm";
import ProcessorScan from "./ProcessorScan";

const setupStates = {
    SCAN: 0,
    FORM: 1
}

const ProcessorSetup = ({ hubs, columns, processors, onSubmit, cancel }) => {
    const [setupState, setSetupState] = useState(setupStates.SCAN);
    const [selectedProcessor, setSelectedProcessor] = useState();

    const selectProcessor = async (processor, subnet) => {
        const hub = hubs.find(hub => hub.hubId === processor.hubId);
        if (!hasIpAddressesOnSameSubnet(processor.ipAddress, hub.networkConfig)) {
            await addVirtualInterfaceHandler(processor, subnet, hub);
        }

        setSelectedProcessor(processor);
        setSetupState(setupStates.FORM);
    }

    if (setupState === setupStates.FORM) {
        return <EditableForm
            initProps={selectedProcessor}
            columns={columns}
            objects={processors}
            onSubmit={onSubmit}
            onCancel={cancel}
        />
    } else if (setupState === setupStates.SCAN) {
        return <ProcessorScan
            hubs={hubs}
            selectProcessor={selectProcessor}
        />
    } else {
        return <></>
    }
}

export default ProcessorSetup;

const addVirtualInterfaceHandler = async (processor, subnet, hub) => {
    let virtualIp;
    if (subnet && isSameSubnet(processor.ipAddress, subnet)) {
        virtualIp = getDefaultSubnetIp(subnet);
    } else {
        virtualIp = getVirtualInterfaceIpFromProcessorIp(processor.ipAddress);
    }
    if (virtualIp) {
        await addVirtualInterface(hub, virtualIp);
    }
}