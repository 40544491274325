import styled, { css } from 'styled-components';
import theme from '../../../UI/theme';
import Card from '../../UiComponents/Card';
import Divider from '../../UiComponents/Divider';
import { readOnlyColor } from '../fields/Styles';

export const DeviceContainer = styled(Card)`
    padding: 10px 10px;
`

export const DeviceMainContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`

export const SummaryContainer = styled.div`
    display: flex;
    align-items: center;
`

export const NameContainer = styled.div`
    margin-right: 20px;
`
export const IndicatorDot = styled.div`
    >div {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        margin: 0px 12px;
        @media only screen and (max-width: ${theme.screenSizes.small - 1}px) {
            width: 20px;
            height: 20px;
            margin: 0px 2px;
        }

        ${props => props.small && css`
            width: 10px;
            height: 10px;
            margin: 6px;
        `}
    }
`

export const DeviceIconContainer = styled.div`
    margin-right: 12px;
`

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    font-size: ${theme.fontSize.small};
    /* padding: 0 10px 10px 10px; */
`

export const DetailsParagraph = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
`

export const DeviceList = styled.ul`
    margin: 0;
    padding-left: 0;
    margin: 0;
`

export const DeviceError = styled.div`
    font-size: ${theme.fontSize.tiny};
    margin: 5px 0;
    padding: 4px;
    >label {
        color: ${props => props.type === 'WARNING' ? theme.colors.mellowYellow : theme.colors.raspberryRed};
    }
`

export const StyledListItem = styled.li`
    list-style-type: none;
    margin-bottom: 10px;
`

export const ListContainer = styled.div`
    padding-bottom: 200px;
`

export const Flex = styled.div`
    display: flex;
    align-items: center;
`

export const ThinDivider = styled(Divider)`
    border-bottom: 1px solid ${theme.colors.darkSpace80};
`

export const InfoContainer = styled.div`
    margin: 12px 0;
    h4 {
        margin: 4px 0;
    }
`

export const InfoRow = styled.div`
    color: ${readOnlyColor};
    display: flex;
    >div {
        margin-right: 16px;
    }
`