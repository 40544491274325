import { useState } from "react";
import { DetailsRow, DetailsContainer, ErrorText, TableRow, ObjectDetailsContainer, Indicator } from "../support/SupportStyles";
import customerUseColumns from "./customerUseColumns";
import Button from "../../UiComponents/Button";
import PopupElement from "../../UiComponents/PopupElement";
import theme from "../../../UI/theme";
import styled from "styled-components";
import { FaChartBar, FaUserNinja } from 'react-icons/fa';
import UIWeekGraphContainer from "./UIWeekGraphContainer";
import { DateTime } from "luxon";

const PopupContainer = styled.div`
    width: 20em;
    white-space: pre-wrap;
    font-size: ${theme.fontSize.small};
    color: ${theme.colors.darkSpace20};
`

const CustomerSuccessTableRow = props => {
    const [expanded, setExpanded] = useState(false);
    const isOpen = props.customerUseSummary.isOpen;

    return <>
        <TableRow onClick={(e) => { setExpanded(prev => { return !prev; }); e.stopPropagation(); }}>
            <td>&nbsp;{props.customerId}</td>
            <td>&nbsp;<Indicator color={isOpen === true ? theme.colors.greenEnergy : isOpen === false ? theme.colors.raspberryRed : theme.colors.textGray} /> {props.companyName}</td>

            {customerUseColumns.map((column, index) => {
                return <td key={index}>
                    {props.customerUseSummary[column.key].value && props.customerUseSummary[column.key].value !== 0 ?
                        <ErrorText>
                            {props.customerUseSummary[column.key].value}
                        </ErrorText>
                        : <></>}
                </td>
            })}

            <td><Button primary style={{ width: '100%' }} onClick={() => { props.setImpersonation(props.customerId); }}><FaUserNinja /></Button></td>
            <td><Button primary style={{ width: '100%' }} onClick={() => { props.goToDetails(props.customerId); }}><FaChartBar /></Button></td>

        </TableRow>


        {expanded ? <RowDetails customerId={props.customerId} customerUseSummary={props.customerUseSummary} />
            : <></>}
    </>
}

export default CustomerSuccessTableRow;

const RowDetails = props => {
    return <DetailsRow>
        <th></th>
        <th></th>
        {customerUseColumns.map((column, colIndex) => {
            if (column.key === 'numberOfUserInteractions') {
                return <td key={colIndex}>
                    <DetailsContainer>
                        <ObjectDetailsContainer>
                            <UIWeekGraphContainer customerId={props.customerId} />
                        </ObjectDetailsContainer>
                    </DetailsContainer>
                </td>
            }

            return <td key={colIndex}>{props.customerUseSummary[column.key].details.map((detail, detailIndex) => {

                const mainContent = <div>{detail.header}</div>;
                const popupContent = <PopupContainer>
                    {detail.header} ({detail.info})
                </PopupContainer>;

                return <DetailsContainer key={detailIndex}>
                    <ObjectDetailsContainer>
                        <PopupElement
                            mainContent={mainContent}
                            popupContent={popupContent}
                        />
                    </ObjectDetailsContainer>
                </DetailsContainer>
            })}</td>
        })}

        <td></td>
    </DetailsRow>
}

const isOpen = (openingHours, timezone) => {
    if (openingHours && timezone) {
        const localTime = DateTime.fromObject({}, { zone: timezone });
        const weekday = localTime.weekday - 1;
        const isOpen = openingHours[weekday]?.open === true;
        const open = parseInt(openingHours[weekday]?.start);
        const close = parseInt(openingHours[weekday]?.end);
        const time = parseInt(localTime.toFormat('HHmm'));

        if (!isNaN(time) && !isNaN(open) && !isNaN(close) && isOpen !== undefined) {
            if (isOpen === true) {
                if (open <= close) {
                    if (time >= open && time <= close) {
                        return true;
                    }
                } else {
                    if (time >= open) {
                        return true;
                    } else {
                        const lastDayClose = parseInt(openingHours[localTime.minus({ days: 1 }).weekday - 1]?.end);
                        if (!isNaN(lastDayClose)) {
                            if (time <= lastDayClose) {
                                return true;
                            } else {
                                return false;
                            }
                        } else {
                            return undefined;
                        }
                    }
                }
            } else {
                return false;
            }
        } else {
            return undefined;
        }
    } else {
        return undefined;
    }
}