import styled from "styled-components";
import { toLocalISOString } from "../../../scripts/common";
import Checkbox from "../../UiComponents/Checkbox";
import DatePickerInput from "../../UiComponents/datepicker/StyledComponents/DatePickerInput";
import Button from "../../UiComponents/Button";
import { FaBackward, FaFastBackward, FaFastForward, FaForward } from "react-icons/fa";
import theme from "../../../UI/theme";

const DateTimePickerContainer = styled.div`
    width: fill-available;
    max-width: 900px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 10px;
    justify-items: center;
    background-color: ${theme.colors.darkSpace90};
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100%;
    justify-content: start;
    align-items: center;

    > button {
        width: fill-available;
    }
`

const ButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column: 1 / span 2;
    gap: 10px;
    align-items: center;
    width: 100%
`

const MoveIntervalButton = styled(Button)`
    display: flex;
    justify-content: center;
    align-items: center;
`

const DatePicker = props => {
    return <DateTimePickerContainer>
        <label>From</label>
        <label>To</label>

        <DatePickerInput
            type={'datetime-local'}
            content={`'${props.fromDateTime?.toLocaleString()}'`}
            max={(new Date()).toString()}
            value={toLocalISOString(props.fromDateTime)}
            onChange={(e) => props.setFromDateTime(new Date(e.target.value))} />

        {!props.detailedValues ?
            <DatePickerInput
                type={'datetime-local'}
                content={`'${props.toDateTime?.toLocaleString()}'`}
                max={(new Date()).toISOString()}
                value={toLocalISOString(props.toDateTime)}
                onChange={(e) => props.setToDateTime(new Date(e.target.value))} /> :
            <label>{props.toDateTime?.toLocaleString()}</label>}

        <ButtonContainer>
            <MoveIntervalButton secondary small onClick={() => props.moveInterval(-24)}><FaFastBackward /></MoveIntervalButton>
            <MoveIntervalButton secondary small onClick={() => props.moveInterval(-6)}><FaBackward /></MoveIntervalButton>
            <MoveIntervalButton secondary small onClick={() => props.moveInterval(6)}><FaForward /></MoveIntervalButton>
            <MoveIntervalButton secondary small onClick={() => props.moveInterval(24)}><FaFastForward /></MoveIntervalButton>
        </ButtonContainer>

        <RowContainer >
            <Checkbox checked={props.detailedValues} onChange={() => props.setDetailedValues(prev => !prev)} />
            <label>Detailed logs</label>
        </RowContainer>

        <RowContainer>
            <Button primary small disabled={props.isLoading} onClick={() => props.clickHandler()}>Fetch data</Button>
        </RowContainer>

    </DateTimePickerContainer>
}

export default DatePicker;