import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import theme from '../../../../UI/theme';
import FadeIn from '../../../UiComponents/Animations/FadeIn';
import Button from '../../../UiComponents/Button';
import Overlay from '../../../UiComponents/Overlay';
import { cellStates } from '../../table/tableTypes';
import { DisplayField, editableColor } from '../Styles';

const DropdowItem = styled.div`
    padding: 8px 4px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: fill-available;
    height: 100%;
    box-sizing: border-box;

    &:hover {
        background-color: ${theme.colors.darkSpace70};
    }

    :not(:last-of-type) {
        border-bottom: 1px solid ${theme.colors.darkSpace90};
    }

    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace80};
    `}
`

const DropdownList = styled.div`
    position: absolute;
    width: 100%;
    overflow: auto;
    list-style: none;
    z-index: 100;
    animation: ${FadeIn} 0.1s;
    text-align: left;
    background-color: ${theme.colors.darkSpace80};
    color: white;
    font-size: 1em;
    border: none;
    border-radius: 2px;

    ${props => props.width && css`
        width: ${props.width};
    `}
`

const DropdownButton = styled.button`
    position: relative;
    padding: 8px 4px;
    border: 0;
    border-radius: 2px;
    color: ${editableColor};
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1em;
    background-color: transparent;

    :hover {
        cursor: pointer;
    }

    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace90};
    `}
`

const ButtonLabel = styled.span`
    margin-right: 12px;
`

const CellDropdown = props => {
    const [show, setShow] = useState(false);
    const [activeIndex, _setActiveIndex] = useState(0);
    const activeIndexRef = useRef(activeIndex);
    const listRef = useRef();
    const buttonRef = useRef();

    const items = props.excludeNone ? props.items : [...props.items, { label: '- None -', value: null }];

    const setActiveIndex = (value) => {
        activeIndexRef.current = value;
        _setActiveIndex(value);
    }

    useEffect(() => {
        if (show) {
            setActiveIndex(getStartIndex(items));

            const listBoundingRect = listRef?.current?.getBoundingClientRect();
            const screenHeight = window.innerHeight;
            const buttonBoundingRect = buttonRef?.current?.getBoundingClientRect();

            if (buttonBoundingRect?.y + buttonBoundingRect?.height + listBoundingRect?.height >= screenHeight && buttonBoundingRect?.y > screenHeight * 2 / 3) {
                listRef.current.style.maxHeight = `${buttonBoundingRect?.y}px`;
                const updated = listRef?.current?.getBoundingClientRect();
                listRef.current.style.top = `${buttonRef?.current?.offsetTop - updated?.height}px`;
            } else {
                listRef.current.style.maxHeight = `${screenHeight - buttonBoundingRect?.y - buttonBoundingRect?.height - 20}px`;
                listRef.current.style.top = `${buttonRef?.current?.offsetTop + buttonBoundingRect?.height}px`;
            }
            listRef.current.style.width = `${buttonBoundingRect?.width}px`;
        }
    }, [show])

    const getStartIndex = (items) => {
        let index = items.findIndex(item => item.value === props.selectedItem?.value);
        index = Math.max(index, 0);
        return index;
    }

    const keyCallback = (event) => {
        const key = event.key;
        if (['ArrowDown', 'ArrowUp'].includes(key)) {
            event.preventDefault();
            let nextIndex;
            if (key === 'ArrowDown') {
                nextIndex = Math.min(items.length - 1, activeIndexRef.current + 1);
            } else if (key === 'ArrowUp') {
                nextIndex = Math.max(0, activeIndexRef.current - 1);
            }
            if (nextIndex !== undefined) {
                setActiveIndex(nextIndex);
                return true;
            }
        }

        if (key === 'Enter') {

            const item = items[activeIndexRef.current];
            if (item) {
                clickHandler(item.value);
            }
            setShow(false);
        }
    }

    const addCallback = () => {
        if (props.cellControl.addCallback) {
            return props.cellControl?.addCallback(keyCallback);
        }
    }

    const removeCallback = (callbackId) => {
        if (props.cellControl.removeCallback) {
            return props.cellControl?.removeCallback(callbackId);
        }
    }

    useEffect(() => {
        let callbackId;
        if (props.cellControl.cellState === cellStates.ACTIVE) {
            callbackId = addCallback();
            setShow(true);
        } else {
            removeCallback(removeCallback)
            setShow(false);
        }
        return () => removeCallback(callbackId);
    }, [props.cellControl.cellState]);

    function clickHandler(value) {
        setShow(false);
        props.onClick(value);
    }

    function displayItems(items) {
        return items.map((item, ix) =>
            <DropdowItem key={ix}
                onClick={() => clickHandler(item.value)}
                active={ix === activeIndex}
            >
                {item.label}
            </DropdowItem>);
    }

    const active = (props.cellControl.cellState === cellStates.ACTIVE || props.cellControl.cellState === cellStates.FORM);
    return <>
        {!props.disabled ? <>
            <DropdownButton ref={buttonRef} active={active} onClick={() => { active && setShow(s => !s) }} >
                <ButtonLabel>{props.selectedItem ? props.selectedItem.label : '-- None --'}</ButtonLabel>
                <FaChevronDown />
            </DropdownButton>
        </> : <>
            <DisplayField>N/A</DisplayField>
        </>}
        {show ?
            <>
                <DropdownList ref={listRef} ulPosition={props.ulPosition} type={props.type}>
                    {displayItems(items)}
                </DropdownList>
                <Overlay onClick={() => setShow(false)} />
            </>
            : <></>}
    </>
}

export default CellDropdown;