import styled from "styled-components";
import theme from "../../../UI/theme";
import { useState } from "react";
import Modal from "../../UiComponents/Modal";
import { ModalContainer, ModalHeader, ModalText } from "../ModalStyles";
import { FaExclamationCircle, FaLock, FaQuestionCircle } from "react-icons/fa";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.darkSpace90};
    color: ${theme.colors.textGray};
    width: fill-available;
    padding: 10px;
    border-radius: 2px;
    box-sizing: border-box;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: ${theme.fontSize.small};
    cursor: pointer;

    &>label {
        cursor: inherit;
        margin-left: 5px;
        text-align: center;
    }
    
    grid-column: 3 / span 2;

    @media only screen and (max-width: ${650}px) {
        grid-column: 1 / span 4;
        grid-row: 2;
    }

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        height: 40px;
        font-size: ${theme.fontSize.tiny};
    }

    @media (hover:hover) {
        &:hover {
            background-color: ${theme.colors.darkSpace80};
        }
    }
`

const MasterAutomatingInfo = props => {
    const [showModal, setShowModal] = useState(false)

    return <>
        <Container onClick={() => setShowModal(true)}>
            <FaLock />
            <label>Master volume is fixed when volume automation is enabled</label>
        </Container>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Fixed master volume - {props.zone?.zoneName}</ModalHeader>
                <ModalText>
                    When volume automation is turned on for any of the zones affected by this master volume, the master volume remains fixed at a specific level to maintain proper volume levels in those zones.
                </ModalText>

                <ModalText>
                    To control this master volume, all affected zones must first disable volume automation.
                </ModalText>
            </ModalContainer>
        </Modal>
    </>
}

export default MasterAutomatingInfo;