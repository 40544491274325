import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../UI/theme';
import InputField from '../components/UiComponents/InputField';
import Button from '../components/UiComponents/Button';
import LinkItem from '../components/settings/LinkItem';
import { hub } from '../components/admin/customerHealth/entityTypes';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 100px;
    height: 100vh;

    h1 {
        color: ${theme.colors.greenEnergy};
    }

    ol {
        padding: 20px; 
        margin: 0px; 
    }
    
    a {
        
        color: ${theme.colors.greenEnergy};
    }

    @media (max-width: ${theme.screenSizes.medium}px) {
        padding: 0px 50px;
    }
    
    @media (max-width: ${theme.screenSizes.small}px) {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px 10px;
    }
`

const NewInstalationView = (props) => {

    const [ipAddress, setIpAddress] = useState('');
    const [subnet, setSubnet] = useState('');
    const [invalidIp, setInvalidIp] = useState(false);
    const [isScanning, setIsScanning] = useState(false);
    const [reachableHubs, setReachableHubs] = useState([]);

    const ipEntered = (ip) => {
        const isValid = validateIp(ip);
        if (isValid) {
            setSubnet(ip.split('.').slice(0,3).join('.'));
            setInvalidIp(false);
        } else {
            setInvalidIp(true);
            setSubnet('');
        }
    }

    const validateIp = (ip) => {
        if (ip === '') return false;
        if (ip.match(/./g).length < 2) return false;
        var ipComponents = ip.split('.');
        if (ipComponents.length < 3) return false;
        for (let i = 0; i < ipComponents.length; i++) {
            var component = ipComponents[i];
            if (component==='' && i<3) return false;
            const numericComponent = Number(component); 
            if (isNaN(numericComponent)) return false;
            if (0 > numericComponent || numericComponent > 255) return false;
        };
        return true;
    }

    const initiateScan = () => {
        const isValid = validateIp(ipAddress);
        if (!isValid) {
            setInvalidIp(true);
            return;
        }
        scan();
    }

    const scan = () => {
        setIsScanning(true);
        var ipArray = buildIpArray();
        var foundHubs = [];
        ipArray.forEach(async ip => {
            const url = `http://${ip}:4000`;
            console.log('Scanning: ', url);
            try {
                await fetch(url, { mode: 'no-cors' })
                foundHubs.push(url);
                openInNewTab(url);
            } catch (error) {}
        });
        setTimeout(() => setReachableHubsHandler(foundHubs), 5000);
    }

    const buildIpArray = () => {
        var ipArray = [];
        for (let i = 0; i < 255; i++) {
            ipArray.push(subnet + '.' + i);
        }
        return ipArray;
    }

    const openInNewTab = (url) => {
        var win = window.open(url, '_blank');
        win.focus();
    }

    const setReachableHubsHandler = (hubList) => {
        hubList.sort();
        setReachableHubs(hubList);
    }



    return (
        <Container>
            <h1>Lets install Waved!</h1>
            <ol>
                <li><p>Make sure Waved Hub and this device is connected to the same network</p></li>
                <li>
                    <p>
                        Enter your ip address
                        <InputField
                            value={ipAddress}
                            onChange={(e) => setIpAddress(e.target.value)}
                            onBlur={(e) => ipEntered(e.target.value)}
                        />
                    </p>
                    {invalidIp ? <p style={{color: theme.colors.raspberryRed}}>Invalid IP address</p> : <></>}
                </li>
                <li>
                    <p>
                        Scan for Waved Hubs and follow the setup instructions
                    </p>
                    <Button primary small onClick={initiateScan}>Start scan</Button>
                    {isScanning ? <h4>Scanning for Waved Hub on: {subnet}.XXX</h4> : <></>}
                    {reachableHubs.length > 0 ? <h4>Continue instalation by visiting:</h4> : <></>}
                    {reachableHubs.map((url, index) => {
                        return (<p key={index}>Hub {index+1}: <a href={url} target="_blank">{url}</a></p>)
                    })}
                </li>
            </ol>
        </Container>
    );
}

export default NewInstalationView;