import { useState } from "react";
import { ButtonRowContainer, ModalContainer, ModalHeader } from "../../../panel/ModalStyles";
import Modal from "../../../UiComponents/Modal";
import PanelButton from "../../../panel/PanelButton";
import { FaPlus } from "react-icons/fa";
import UserInviteForm from "./UserInviteForm";
import SharedAccountForm from "./SharedAccountForm";
import styled from "styled-components";
import Radio from "../../../UiComponents/Radio";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import Button from "../../../UiComponents/Button";

const accountTypes = {
    user: 'user',
    shared: 'shared'
}

const RadioContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
`

const AddVenueUserButton = props => {
    const [showModal, setShowModal] = useState(false);
    const [accountType, setAccountType] = useState(accountTypes.user);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleClose = () => {
        setAccountType(accountTypes.user);
        setFormSubmitted(false);
        setShowModal(false);
    }

    return <>
        <PanelButton onClick={() => setShowModal(true)}>
            <FaPlus />
            <label>Add</label>
        </PanelButton>

        <Modal show={showModal} close={() => handleClose()}>
            <ModalContainer>
                <ModalHeader>Add a user to your Waved team</ModalHeader>

                {formSubmitted === false ? <>
                    <RadioContainer>
                        <Radio name={"accountType"} checked={accountType === accountTypes.user} onChange={() => setAccountType(accountTypes.user)} />
                        <TextMuted>Invite a team member by email</TextMuted>
                        <Radio name={"accountType"} checked={accountType === accountTypes.shared} onChange={() => setAccountType(accountTypes.shared)} />
                        <TextMuted>Create a shared account for your team</TextMuted>
                    </RadioContainer>
                </> : <></>}


                {accountType === accountTypes.user ? <>
                    <UserInviteForm
                        venue={props.venue}
                        refreshWithUpdate={props.refreshWithUpdate}
                        onSubmit={() => setFormSubmitted(true)}
                        onReset={() => setFormSubmitted(false)}
                        onClose={() => handleClose()}
                    />
                </> : <></>}

                {accountType === accountTypes.shared ? <>
                    <SharedAccountForm
                        venue={props.venue}
                        refreshWithUpdate={props.refreshWithUpdate}
                        onSubmit={() => setFormSubmitted(true)}
                        onReset={() => setFormSubmitted(false)}
                        onClose={() => handleClose()}
                    />
                </> : <></>}

            </ModalContainer>
        </Modal>
    </>
}

export default AddVenueUserButton;