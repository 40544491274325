import React from 'react';
import { processorTypes } from '../../settings/venue/device/AudioSystem/processorTypes';
import ChoiceField from './ChoiceField';

const ProcessorChoiceField = ({ object, processors, updateObject, cellControl }) => {
    const currentProcessor = processors.find(processor => processor.processorId === object.processorId);
    return <ChoiceField
        selectedItem={currentProcessor && { value: currentProcessor.processorId, label: getProcessorDisplayName(currentProcessor) }}
        items={processors.map(processor => ({ value: processor.processorId, label: getProcessorDisplayName(processor) }))}
        setValue={(value) => updateObject({ processorId: value })}
        cellControl={cellControl}
        excludeNone={true}
    />
}

export default ProcessorChoiceField;

const getProcessorDisplayName = (processor) => {
    if (processor && processor.type) {
        if (processor.name) {
            return processor.name;
        } else {
            return processorTypes[processor.type]?.label;
        }
    }
}