import { useRef, useState } from "react";
import ResizableColumnContainer from "../../../UiComponents/containers/ResizableColumnContainer"
import SystemTree from "../../SystemTree";
import MapContainer from "./map/MapContainer";
import SmallScreenColumnContainer from "../../../UiComponents/containers/SmallScreenColumnContainer";
import theme from "../../../../UI/theme";
import { useEffect } from "react";
import InstallationItemInfo from "./info/InstallationItemInfo";

const InstallationDocumentationView = ({ customerId, hubs, processors, sourceSelectors, zones, sensors}) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemHierarchy, setSelectedItemHierarchy] = useState();
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const resizeHooks = useRef([]);

    const onStopColumnResize = (columnWidths) => {
        resizeHooks.current.forEach(hook => {
            hook.callback(columnWidths);
        })
    }

    const addResizeHook = (name, callback) => {
        if (!resizeHooks.current.some(hook => hook.name === name)) {
            resizeHooks.current.push({ name, callback });
        }
    }

    const updateScreenWidth = () => setScreenWidth(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', updateScreenWidth);

        return () => {
            window.removeEventListener('resize', updateScreenWidth);
        }
    }, [])

    const systemTree = (<SystemTree
        columntitle="System Tree"
        hubs={hubs}
        processors={processors}
        sourceSelectors={sourceSelectors}
        zones={zones}
        sensors={sensors}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        selectedItemHierarchy={selectedItemHierarchy}
        setSelectedItemHierarchy={setSelectedItemHierarchy}
    />);

    const map = (
        <MapContainer
            columntitle={"Map"}
            customerId={customerId}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            screenWidth={screenWidth}
            hubs={hubs}
            zones={zones}
            processors={processors}
            sensors={sensors}
            addResizeHook={addResizeHook}
        />
    );

    const info = (<InstallationItemInfo columntitle={"Info"} selectedItem={selectedItem} customerId={customerId} />);

    return <>
        {screenWidth > theme.screenSizes.large ?
            <ResizableColumnContainer
                defaultColumnWidths={[20, 50, 30]}
                onStopResize={onStopColumnResize}>
                {systemTree}
                {map}
                {info}
            </ResizableColumnContainer>
            :
            <SmallScreenColumnContainer>
                {systemTree}
                {map}
                {info}
            </SmallScreenColumnContainer>
        }
    </>

}

export default InstallationDocumentationView;