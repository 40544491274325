import React, { useContext } from 'react';
import styled from 'styled-components';
import UserContext from '../../../hooks/UserContext';
import { getDeviceNumber, isSameDay, toLocalISOString } from '../../../scripts/common';
import theme from '../../../UI/theme';
import { powerSourceChoices } from '../../installation/modules/sensor/sensorTypes';
import deviceTypes from '../../settings/venue/device/deviceTypes';
import InfoBubble from '../../UiComponents/InfoBubble';
import { hasRole, hasVenuePermission, permissionTypes } from '../../permission/permissions';

const Header = styled.h3`
    margin: 0;
`

const StatContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: ${theme.fontSize.tiny};
    border-bottom: 1px solid black;
`

const StatHeader = styled.label`
    margin: 0 10px 0 0;
`

const StatValue = styled.label`
    margin: 0;
    font-weight: lighter;
`

const SensorInfoBubble = props => {
    const userContext = useContext(UserContext);
    const lastUpdated = new Date((props.sensorData.time + 'Z').replace(' ', 'T'));
    var dateTimeString = lastUpdated.toLocaleString();
    if (isSameDay(lastUpdated, new Date())) {
        dateTimeString = lastUpdated.toLocaleTimeString();
    }

    if (props.sensorData.averageDecibel) {
        return (
            <InfoBubble show={props.show} close={props.close} display='block' pageX={props.sensorData.pageX} pageY={props.sensorData.pageY}>
                <Header>Sensor {getDeviceNumber(props.sensorData)}</Header>

                <StatContainer>
                    <StatHeader>Volume:</StatHeader>
                    <StatValue>{!props.sensorData.ignore ? `${Math.round(props.sensorData.averageDecibel)} dB` : 'No data'}</StatValue>
                </StatContainer>
                    <StatContainer>
                        <StatHeader>Battery level:</StatHeader>
                        <StatValue>{props.sensorData.batteryLevel} %</StatValue>
                    </StatContainer>
                <StatContainer>
                    <StatHeader>Last updated:</StatHeader>
                    <StatValue>{dateTimeString}</StatValue>
                </StatContainer>

                {hasVenuePermission(userContext, permissionTypes.installationViewRead) ? <>
                        <StatContainer>
                            <StatHeader>Sensor ID:</StatHeader>
                            <StatValue>{props.sensorData.sensorId}</StatValue>
                        </StatContainer>
                        <StatContainer>
                            <StatHeader>LQI:</StatHeader>
                            <StatValue>{props.sensorData.lqi}</StatValue>
                        </StatContainer>
                        <StatContainer>
                            <StatHeader>Node ID:</StatHeader>
                            <StatValue>{props.sensorData.ipAddress}</StatValue>
                        </StatContainer>
                        <StatContainer>
                            <StatHeader>Parent node ID:</StatHeader>
                            <StatValue>{props.sensorData.parentNodeId}</StatValue>
                        </StatContainer>
                        <StatContainer>
                            <StatHeader>Power source:</StatHeader>
                            <StatValue>{powerSourceChoices.find(powerSource => powerSource.value === props.sensorData.powerSource)?.label ?? 'Unknown'}</StatValue>
                        </StatContainer>
                        <StatContainer>
                            <StatHeader>Device type:</StatHeader>
                            <StatValue>{deviceTypes.nodeTypes.find(nodeType => nodeType.key === parseInt(props.sensorData.nodeType))?.display ?? 'N/A'}</StatValue>
                        </StatContainer>
                        <StatContainer>
                            <StatHeader>App mode:</StatHeader>
                            <StatValue>{props.sensorData.appMode}</StatValue>
                        </StatContainer>
                    </> : <></>}
                    <StatContainer>
                        <StatHeader>Version:</StatHeader>
                        <StatValue>{props.sensorData.version}</StatValue>
                    </StatContainer>

            </InfoBubble>
        );
    } else return (<></>);
}

export default SensorInfoBubble;