import { useRef, useState } from 'react';
import Button from '../UiComponents/Button';
import { uploadImage } from '../../scripts/storage';
import { resizeImage } from './utils';
import { loadingStates } from '../installation/installationTypes';
import LoadingSpinner from '../UiComponents/LoadingSpinner';
import FileInputButton from './FileInputButton';
import { FaCheckCircle } from 'react-icons/fa';
import styled from 'styled-components';
import theme from '../../UI/theme';

const Container = styled.div`
    color: ${theme.colors.textGray};
`

const ImageUpload = ({ customerId, onUpload }) => {
    const inputRef = useRef();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [errorMessage, setErrorMessage] = useState();

    const onFileSelect = async (event) => {
        setErrorMessage(null);
        setLoadingState(loadingStates.NONE)
        let validated = true;
        for (let i = 0; i < event.target.files.length; i++) {
            const file = event.target.files[i];
            const fileExtension = file.name.split('.')[1].toLowerCase();
            const isImage = ['png', 'jpg', 'jpeg', 'heif'].indexOf(fileExtension) > -1;
            if (!isImage) {
                validated = false;
                break;
            }
        }

        if (!validated) {
            inputRef.current.value = null;
            setErrorMessage('Error: wrong format');
        } else {
            upload();
        }
    };

    const upload = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            const formData = new FormData();
            Array.from(inputRef.current.files).forEach((file, index) => {
                formData.append(`image`, file);
            })

            const uploadRes = await uploadImage(customerId, formData);
            if (onUpload) {
                onUpload(uploadRes);
            }
            if (inputRef?.current) {
                inputRef.current.value = null;
            }
            setLoadingState(loadingStates.SUCCESS);
            setTimeout(() => {
                setLoadingState(loadingStates.NONE);
            }, 2000);
        } catch (err) {
            console.log('err', err);
            setErrorMessage('Upload failed');
            setLoadingState(loadingStates.FAILED);
        }
    }

    return (
        <Container>
            <div>
                {loadingState === loadingStates.LOADING ? <>
                    <LoadingSpinner /><span>Uploading...</span>
                </> : <>
                    <FileInputButton
                        inputRef={inputRef}
                        onFileSelect={onFileSelect}
                    />
                </>}

                {loadingState === loadingStates.SUCCESS ? <>
                    <FaCheckCircle style={{marginRight: '4px'}}/><span>Uploaded</span>
                </> : <></>}

                {loadingState === loadingStates.FAILED ? <>
                    <span>Upload failed</span>
                </> : <></>}
            </div>

            <div>
                {errorMessage}
            </div>
        </Container>
    );
}

export default ImageUpload;