import StyledIcon from './StyledIcon'


const SensorIcon = (props) => {
    return <StyledIcon viewBox="0 0 32 32" width="24px" height="24px" {...props}>
        <g><path d="M21.0667 28H8.93333C5.12 28 2 24.88 2 21.0667V8.93333C2 5.12 5.12 2 8.93333 2H21.0667C24.88 2 28 5.12 28 8.93333V21.0667C28 24.88 24.88 28 21.0667 28Z" className="icon__line"></path></g>
    </StyledIcon>
}

const HubIcon = (props) => {
    return <SensorIcon {...props}/>;
}

const BarIcon = props => {
    return <StyledIcon viewBox="0 0 32 32" width="24px" height="24px" className="nav-bar__icon" {...props}>
        <path d="M16 29V13"></path>
        <path d="M27 29V4"></path>
        <path d="M5 29V23"></path>
    </StyledIcon>
}


const SettingsIcon = props => {
    return <StyledIcon width="24px" height="24px" viewBox="0 0 32 32" className="icon icon--settings" {...props}>
        <g><path d="M5 30V19" className="icon__line"></path><path d="M5 13V2" className="icon__line"></path><path d="M16 30V16" className="icon__line"></path><path d="M16 10V2" className="icon__line"></path><path d="M27 30V22" className="icon__line"></path><path d="M27 16V2" className="icon__line"></path><path d="M0 18H10" className="icon__line"></path><path d="M11 11H20" className="icon__line"></path><path d="M22 21H32" className="icon__line"></path></g>
    </StyledIcon>
}

const MusicIcon = props => {
    return <StyledIcon width="32px" height="32px" viewBox="0 0 32 32" className="icon icon--music" {...props}>
        <g><path d="M22.6 25.6C24.6435 25.6 26.3 23.9435 26.3 21.9C26.3 19.8565 24.6435 18.2 22.6 18.2C20.5565 18.2 18.9 19.8565 18.9 21.9C18.9 23.9435 20.5565 25.6 22.6 25.6Z" className="icon__line"></path><path d="M12.4 24.8V7.5L26.3 4.5V21.9" className="icon__line"></path><path d="M8.7 28.4C10.7435 28.4 12.4 26.7435 12.4 24.7C12.4 22.6565 10.7435 21 8.7 21C6.65655 21 5 22.6565 5 24.7C5 26.7435 6.65655 28.4 8.7 28.4Z" className="icon__line"></path></g>
    </StyledIcon>
}

const SpeakersIcon = props => {
    return <StyledIcon width="32px" height="32px" viewBox="0 0 32 32" className="icon icon--speakers" {...props}>
        <g><path d="M20.5 25C22.433 25 24 23.433 24 21.5C24 19.567 22.433 18 20.5 18C18.567 18 17 19.567 17 21.5C17 23.433 18.567 25 20.5 25Z" className="icon__line"></path><path d="M30 6H11V30H30V6Z" className="icon__line"></path><path d="M7 30H2V11H7" className="icon__line"></path><path d="M20.5 14C21.8807 14 23 12.8807 23 11.5C23 10.1193 21.8807 9 20.5 9C19.1193 9 18 10.1193 18 11.5C18 12.8807 19.1193 14 20.5 14Z" className="icon__fill"></path></g>
    </StyledIcon>
}

const VolumeIcon = props => {
    return <StyledIcon width="32px" height="32px" viewBox="0 0 32 32" className="icon icon--volume" {...props}>
        <g><path d="M13 7.1L6.6 12.2H1.5V19.9H6.6L13 25V7.1Z" className="icon__line"></path><path d="M25.5 4.1C32.1 10.7 32.1 21.5 25.5 28.1" className="icon__line"></path><path d="M19 10.1C22.3 13.4 22.3 18.8 19 22.1" className="icon__line"></path></g>
    </StyledIcon>
}

const Chevron = props => {
    return <StyledIcon viewBox="0 0 32 32" {...props}><path d="M17 28L5 16L17 4" className="icon__line"></path></StyledIcon>;
}

export {
    SensorIcon,
    HubIcon,
    BarIcon,
    SettingsIcon,
    MusicIcon,
    SpeakersIcon,
    VolumeIcon,
    Chevron
}