import styled from "styled-components";
import theme from "../../../../UI/theme";

export const GroupContainer = styled.div`
    background-color: ${theme.colors.darkSpace90};
    padding: 10px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;
    overflow: hidden;
`

export const ZoneGroupHeader = styled.label`
    font-size: 1.2rem;
`

export const GroupItemHeader = styled.div`
    font-weight: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
`