import Modal from "../../UiComponents/Modal";
import { ModalContainer, ModalHeader } from "../ModalStyles";
import SchedulesInfo from "../SchedulesInfo";
import ZoneAdvancedSettings from "./ZoneAdvancedSettings";
import ActivityChartContainer from "../charts/ActivityChartContainer";
import LiveChartWithIntervalSelector from "../charts/LiveChartWithIntervalSelector";
import { useContext } from "react";
import UserContext from "../../../hooks/UserContext";
import { hasRole, permissionTypes, roles } from "../../permission/permissions";

const ZoneInfoModal = props => {
    const userContext = useContext(UserContext);

    return <Modal {...props}>
        <ModalContainer>
            <ModalHeader>{props.zone?.zoneName}</ModalHeader>

            {hasRole(userContext, roles.impersonator) ?
                <LiveChartWithIntervalSelector zone={props.zone} zoneLive={props.zoneLive} processorConnected={props.processorConnected} detailed />
                : <></>}

            <ActivityChartContainer zone={props.zone} />

            <SchedulesInfo schedules={props.schedules} />

            <ZoneAdvancedSettings
                zone={props.zone}
                updateZoneWithEvent={props.updateZoneWithEvent}
                processorConnected={props.processorConnected}
            />
        </ModalContainer>
    </Modal>
}

export default ZoneInfoModal;