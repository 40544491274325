import Sensor from './Sensor';
import DeviceLayerController from "../device/DeviceLayerController";
import canvasController from "../CanvasController";
import heatmapTypes from "../../container/heatmapTypes";
import { getOuterWallElement } from '../floor/wallUtils';
import { getFloorElementsByType } from '../floor/floorUtils';
import { connectedStatus, getSensorConnectedStatus, getSensorStatus } from '../../../installation/utils/connectionStatus';

const OLD_LIVE_DATA_LIMIT = 10;

class SensorLayerController extends DeviceLayerController {
    constructor() {
        super();
        this.sensorData = null;
        this.layer.name = 'Sensor Layer';
        this.displayMethod = heatmapTypes.displayMethods.CONTINUOUS;
    }

    onFloorChange(floorData) {
        const outerWallElement = getOuterWallElement(floorData);
        const sensors = getFloorElementsByType(floorData, heatmapTypes.elementTypes.SENSOR);
        this.drawDevices(sensors);
        this.changeFloorHandler(outerWallElement);
    }

    onModeChange(modeName) {
        super.onModeChange(modeName);
        if (modeName === heatmapTypes.modes.DATA) {
            this.initDataLayerController();
            this.initDataMode();
        } else if (modeName === heatmapTypes.modes.SETUP) {
            this.removeData();
            this.initSetupMode();
        }
    }

    constructObject(sensor) {
        return new Sensor(sensor, this);
    }

    initSetupMode() {
        this.list.forEach(sensor => {
            sensor.removeStatusPath();
        })
    }

    showSensorData(sensorData) {
        if (!sensorData) {
            return;
        }
        this.layer.activate();
        this.sensorData = [...sensorData];

        this.checkConnectedStatus();

        if (this.activeMode === heatmapTypes.modes.DATA) {
            if (this.displayMethod === heatmapTypes.displayMethods.CONTINUOUS) {
                if (this.dataLayerController) {
                    this.dataLayerController.setSensorData(this.sensorData);
                }
            }
        }
    }

    initDataMode() {
        this.list.forEach(async (sensor) => {
            sensor.addDataModeEventHandlers();
            sensor.showConnectionStatus();
        });
    }

    initDataLayerController() {
        this.removeData();
        if (this.displayMethod === heatmapTypes.displayMethods.CONTINUOUS) {

            // try to get outerwallelement from react-state if it does not exist
            if (!this.outerWallElement) {
                canvasController.canvasCallback(heatmapTypes.actions.GET_OUTER_WALL, {
                    callback: (element) => {
                        if (element) {
                            this.outerWallElement = element;
                        }
                    }
                });
            }
            this.dataLayerController.initializeGrid(this.outerWallElement, this.list);
            this.showSensorData(this.sensorData);
        }
    }

    setDataType(dataType) {
        this.dataLayerController.setDataType(dataType);
        this.showSensorData(this.sensorData);
    }

    setDataMode(dataMode) {
        if (this.dataLayerController) {
            this.dataLayerController.setDataMode(dataMode);
        }
    }

    removeData() {
        this.list.forEach(sensor => {
            sensor.removeStatusPath();
        });

        if (this.dataLayerController) {
            this.dataLayerController.clearData();
        }
    }

    checkConnectedStatus() {
        // check for old data and ignore if older than limit 
        var ignoreCount = 0;
        this.sensorData.forEach(sensor => {
            var sensorObject = this.list.find(s => s.id === sensor.sensorId);
            var status = null;
            if (sensor.secondsSinceLastUpdate > OLD_LIVE_DATA_LIMIT || sensor.isConnected == 0 || sensor.averageDecibel == 0) {
                sensor.ignore = true;
                ignoreCount++;
                status = 'DISCONNECTED';
            } else {
                status = 'CONNECTED';
            }

            if (sensorObject) {
                sensorObject.setStatus(status);
            }

        });

        if (ignoreCount == this.sensorData.length) {
            // Display error message
        }
    }

    handleDeviceUpdates(updatedSensors, hubs) {
        updatedSensors.forEach(updatedSensor => {
            const sensor = this.list.find(d => d.id === updatedSensor.sensorId);
            if (sensor) {
                const hub = hubs.find(hub => hub.hubId === updatedSensor.hubId);
                const status = getSensorStatus(updatedSensor, hub, true);
                const updatedConnectedState = status === connectedStatus.CONNECTED;
                if (sensor.connectedState !== updatedConnectedState) {
                    sensor.setConnectedState(updatedConnectedState);
                }
            }
        });
    }

}

export default SensorLayerController;