import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/meter';

async function getMeters(customerId) {
    const res = await axios.get(`${hostname}${path}/meters?customerId=${customerId}`);
    return res.data;
}

async function updateMeters(meters) {
    const res = await axios.put(`${hostname}${path}/list`, meters);
    return res.data;
}

async function addMeter(meter) {
    const res = await axios.post(`${hostname}${path}`, meter);
    return res.data;
}

async function deleteMeter(meterId) {
    const res = await axios.delete(`${hostname}${path}?meterId=${meterId}`);
    return res.data;
}


export {
    getMeters,
    updateMeters,
    addMeter,
    deleteMeter
}