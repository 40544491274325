
import React from 'react';
import ChoiceField from '../../../fields/ChoiceField';
import ShowSourceMatrixTabButton from '../actions/ShowSourceMatrixTabButton';
import { sourceSelectorTypes } from '../sourceSelectorTypes';

const types = [{
    label: 'Normal',
    value: sourceSelectorTypes.NORMAL
}, {
    label: 'Matrix',
    value: sourceSelectorTypes.MATRIX
}];

const SourceSelectorTypeChoiceField = props => {
    const selectedItem = types.find(type => type.value === props.type) || types[0];
    return <>
        <ChoiceField
            selectedItem={selectedItem}
            items={types}
            setValue={(value) => props.setValue(value)}
            cellControl={props.cellControl}
            excludeNone={true}
        />

        {props.cellControl.isForm && props.type === sourceSelectorTypes.MATRIX && props.sourceMatrixes.length === 0 ? <>
            <span>No source matrixes have been added.</span>
            <ShowSourceMatrixTabButton setSelectedTab={props.setSelectedTab} />
        </> : <></>}
    </>
}

export default SourceSelectorTypeChoiceField;