import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { getAverageTrend } from "../../../../scripts/trends";
import AppModePieChart from "./AppModePieChart";

const ChartContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`

const AppModePieChartContainer = props => {
    const [averageTrend, setAverageTrend] = useState();
    let componentMounted = useRef(true);

    useEffect(() => {
        return () => {
            componentMounted.current = false;
            setAverageTrend(null);
        }
    }, [])

    useEffect(() => {
        getAppModeData();
    }, [props.objectID, props.customerId])

    const getAppModeData = async () => {
        var trends = await getAverageTrend(props.customerId, props.objectID, 6, 'SENSOR');
        if (componentMounted.current) {
            if (trends) {
                setAverageTrend(trends);
            } else {
                setAverageTrend(null);
            }
        }
    }

    return <>
        {averageTrend &&
            <div>
                <ChartContainer>
                    <AppModePieChart
                        data={averageTrend && averageTrend.data.length ? averageTrend.data : []}
                        labels={['AM0', 'AM1', 'AM2', 'AM3']} />
                </ChartContainer>
            </div>
        }
    </>
}

export default AppModePieChartContainer;