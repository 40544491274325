import { useState } from "react";
import { ClickableTableRow, HeaderRow, Table, TableData, TDHeader, THead } from "../support/SupportStyles";
import customerHealthMetricTypes from "./customerHealthMetricTypes";
import CustomerHealthOverview from "./CustomerHealthOverview";
import HealthMetricLabel from "./HealthMetricLabel";

const CustomerHealthSummary = props => {
    const [sortProperty, setSortProperty] = useState();
    const [sortAsc, setSortAsc] = useState(true);
    const averageMetricTypes = Object.keys(customerHealthMetricTypes).map(key => customerHealthMetricTypes[key]).filter(metricType => metricType.computeAverage === true);

    const sortBy = (property) => {
        setSortProperty(property);

        if (sortProperty === property) {
            setSortAsc(prev => !prev);
        } else {
            setSortAsc(false);
        }
    }

    const compareCustomers = (a, b) => {
        let aProp, bProp;

        if (sortProperty === 'customerId' || sortProperty === 'companyName') {
            aProp = a?.[sortProperty];
            bProp = b?.[sortProperty];
        } else if (averageMetricTypes?.some(type => type.key === sortProperty)) {
            aProp = props.customerAverages?.find(average => average?.customerId === a.customerId)?.[sortProperty];
            bProp = props.customerAverages?.find(average => average?.customerId === b.customerId)?.[sortProperty];
        }

        if (aProp === undefined || aProp === null) {
            return 1;
        } else if (bProp === undefined || bProp === null) {
            return -1;
        }

        if (sortAsc === false) {
            return aProp < bProp ? 1 : bProp < aProp ? -1 : 0;
        } else {

            return aProp < bProp ? -1 : bProp < aProp ? 1 : 0;
        }
    }

    return <Table>
        <colgroup>
            <col style={{ width: '1%' }} />
        </colgroup>
        <THead>
            <HeaderRow>
                <TDHeader sorted={sortProperty === 'customerId'} ascending={sortAsc} onClick={() => sortBy('customerId')}>Customer ID</TDHeader>
                <TDHeader sorted={sortProperty === 'companyName'} ascending={sortAsc} onClick={() => sortBy('companyName')}>Name</TDHeader>
                {averageMetricTypes?.map((metricType, index) => {
                    return <TDHeader key={index} sorted={sortProperty === metricType.key} ascending={sortAsc} onClick={() => sortBy(metricType?.key)}>Avg {metricType?.display}</TDHeader>
                })}
            </HeaderRow>
        </THead>
        <tbody>
            {props.customers
                ?.sort((a, b) => compareCustomers(a, b))
                ?.map(customer => {
                    const metrics = props.metrics?.filter(metric => metric.customerId === customer?.customerId);
                    const previousMetrics = props.previousMetrics?.filter(metric => metric?.customerId === customer?.customerId);
                    const customerAverages = props.customerAverages?.find(average => average?.customerId === customer?.customerId);
                    const previousAverages = props.previousAverages?.find(average => average?.customerId === customer?.customerId);

                    return <CustomerSummary
                        key={customer?.customerId}
                        customer={customer}
                        metrics={metrics}
                        previousMetrics={previousMetrics}
                        customerAverages={customerAverages}
                        previousAverages={previousAverages}
                        averageMetricTypes={averageMetricTypes}
                    />
                })}
        </tbody>
    </Table>
}

export default CustomerHealthSummary;

const CustomerSummary = props => {
    const [expanded, setExpanded] = useState(false);

    return <>
        <ClickableTableRow onClick={() => setExpanded(prev => !prev)}>
            <TableData>
                {props.customer?.customerId}
            </TableData>
            <TableData>
                {props.customer?.companyName}
            </TableData>
            {props.averageMetricTypes?.map((metricType, index) => {
                const currentMetric = props.customerAverages?.[metricType?.key];
                const previousMetric = props.previousAverages?.[metricType?.key];

                return <TableData key={index}>
                    <HealthMetricLabel metricType={metricType} currentMetric={currentMetric} previousMetric={previousMetric} />
                </TableData>
            })}
        </ClickableTableRow>

        {expanded === true ?
            <CustomerHealthOverview customer={props.customer} metrics={props.metrics} previousMetrics={props.previousMetrics} />
            : <></>}
    </>
}