import React, { useState } from 'react';
import theme from '../../../UI/theme';
import Checkbox from '../../UiComponents/Checkbox';
import { HeaderRow, Table, TableRow } from '../support/SupportStyles';

const HubTable = props => {
    const [allSelected, setAllSelected] = useState(false);

    function selectAll() {
        var selectedState = !allSelected;
        props.selectAll(selectedState);
        setAllSelected(selectedState);
    }

    const deviceList = props.devices.map(hub => {
        return <TableRow key={hub.hubId}>
            <td>
                <Checkbox
                    checked={props.isSelected(hub.hubId)}
                    onChange={() => props.selectHandler(hub.hubId)}
                    backgroundColor={theme.colors.darkSpace90}
                />
            </td>
            <td>{hub.customerId}</td>
            <td>{hub.networkType}</td>
            <td>{hub.hubId}</td>
            <td>{hub.online}</td>
            <td>{hub.version}</td>
            <td>{hub.programVersion}</td>
            <td>{hub.imageVersion}</td>
            <td>{hub.lgVersion}</td>
            <td>{hub.localAppVersion}</td>
            <td>{hub.connectedSensors}</td>
        </TableRow>
    })

    return <>
        <Table className="waved-table">
            <thead>
                <HeaderRow>
                    <th>
                        <Checkbox
                            checked={allSelected}
                            onChange={selectAll}
                            backgroundColor={theme.colors.darkSpace90}
                        />
                    </th>
                    <th>CustomerId</th>
                    <th>Type</th>
                    <th>Device ID</th>
                    <th>Online</th>
                    <th>Version</th>
                    <th>Program version</th>
                    <th>Image version</th>
                    <th>LG version</th>
                    <th>Local app version</th>
                    <th>Connected sensors</th>
                </HeaderRow>
            </thead>
            <tbody>
                {deviceList}
            </tbody>
        </Table>
    </>
}

export default HubTable;