import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../UI/theme';
import { Chart, registerables } from 'chart.js';
import { replaceZeroValues } from '../../../scripts/common';
import { chartAreaBackgroundColorPlugin } from './chartUtils';
Chart.register(...registerables);

const Container = styled.div`
    height: 25vh;
    min-height: 200px;
    max-height: 800px;
    width: 100%;
    overflow: hidden;
`

const chartData = {
    labels: [],
    datasets: [
        {
            type: 'line',
            label: 'Music',
            borderColor: theme.colors.yellowFever,
            backgroundColor: theme.colors.yellowFever,
            pointRadius: 0,
            tension: 0.3,
            fill: false,
            data: []
        },
        {
            type: 'line',
            label: 'Music trend',
            borderColor: `${theme.colors.yellowFever}70`,
            borderDash: [5, 5],
            backgroundColor: `${theme.colors.yellowFever}70`,
            pointRadius: 0,
            tension: 0.3,
            fill: false,
            data: []
        },
        {
            type: 'line',
            label: 'Ambience',
            borderColor: theme.colors.pinky,
            backgroundColor: theme.colors.pinky,
            pointRadius: 0,
            tension: 0.3,
            fill: false,
            data: []
        },
        {
            type: 'line',
            label: 'Ambience trend',
            borderColor: `${theme.colors.pinky}70`,
            borderDash: [5, 5],
            backgroundColor: `${theme.colors.pinky}70`,
            pointRadius: 0,
            tension: 0.3,
            fill: false,
            data: []
        },
    ]
};

const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
        x: {
            ticks: {
                display: true,
                maxTicksLimit: 14,
                color: theme.colors.textGray,
                maxRotation: 0,
            },
            beginAtZero: true,
            grid: {
                drawOnChartArea: true,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70,
                tickColor: theme.colors.darkSpace70,
                color: theme.colors.darkSpace70
            }
        },
        y: {
            ticks: {
                display: true,
                color: theme.colors.textGray
            },
            beginAtZero: true,
            grid: {
                drawOnChartArea: true,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70,
                tickColor: theme.colors.darkSpace70,
                color: theme.colors.darkSpace70
            },
            suggestedMin: 0,
            suggestedMax: 100,
        }
    },
    plugins: {
        legend: {
            display: true,
            position: 'top',
            align: 'center',
            labels: {
                color: theme.colors.textGray,
                textAlign: 'left',
                boxWidth: 7,
                boxHeight: 7,
                padding: 5,
                pointStyle: 'rectRounded',
                usePointStyle: true
            }
        }
    },
    pointHitRadius: 7,
}

const defaultChartConfig = {
    data: chartData,
    options
}

const OverviewChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const chartConfig = useRef(JSON.parse(JSON.stringify(defaultChartConfig)));

    chartConfig.current.data.datasets[0].data = replaceZeroValues(props.sysVol);
    chartConfig.current.data.datasets[1].data = replaceZeroValues(props.sysVolTrend);
    chartConfig.current.data.datasets[2].data = replaceZeroValues(props.noise);
    chartConfig.current.data.datasets[3].data = replaceZeroValues(props.noiseTrend);
    chartConfig.current.data.labels = props.labels;

    const isRegulatingID = `${props.chartID}-1`;
    chartConfig.current.options.plugins[isRegulatingID] = {
        chartID: props.chartID,
        stopColor: `${theme.colors.pinky}08`,
        stops: props.isRegulating
    }

    const isRegulatingAudioSourceID = `${props.chartID}-2`;
    chartConfig.current.options.plugins[isRegulatingAudioSourceID] = {
        chartID: props.chartID,
        stopColor: `${theme.colors.crystalBlue}08`,
        stops: props.isRegulatingAudioSource?.map((value, index) => +(value === 1 || props.isRegulating[index] === 0))
    }

    if (props.weekLabels === true) {
        chartConfig.current.options.scales.x.ticks.callback = function (val, index) {
            return index % 12 === 0 && index % 24 !== 0 ? this.getLabelForValue(val) : '';
        }
        chartConfig.current.options.scales.x.grid.tickColor = function (context) {
            if (context.tick.label === '') {
                return theme.colors.darkSpace70;
            }
        }
        chartConfig.current.options.scales.x.grid.color = function (context) {
            if (context.tick.label === '') {
                return theme.colors.darkSpace70;
            }
        }
    } else {
        chartConfig.current.options.scales.x.ticks.callback = function (val, index) { return this.getLabelForValue(val); }
        chartConfig.current.options.scales.x.grid.tickColor = theme.colors.darkSpace70;
        chartConfig.current.options.scales.x.grid.color = theme.colors.darkSpace70;
    }

    if (chartInstance) {
        chartInstance.update();
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current,
                {
                    ...chartConfig.current,
                    plugins: [
                        { ...chartAreaBackgroundColorPlugin, id: isRegulatingID },
                        { ...chartAreaBackgroundColorPlugin, id: isRegulatingAudioSourceID }]
                });
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <Container minWidth={props.minWidth}>
            <canvas id={props.chartID} ref={chartContainer} height={500} />
        </Container>
    );
}

export default OverviewChart;