import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { usePageVisibility } from "react-page-visibility";
import { getDateTime, getDefinedLogInterval } from "../../../../../scripts/log";
import zoneTypes from "../../../../live/zone/zoneTypes";
import { ChartContainer } from "../calibrationStyles";
import LiveChart from "./LiveChart";

const LiveChartContainer = props => {
    const [graphData, setGraphData] = useState([]);
    const isVisible = usePageVisibility();

    useEffect(() => {
        if (isVisible === true) {
            getData();
        }
    }, [isVisible]);

    useEffect(() => {
        let logs = graphData;
        setGraphData(prev => {
            const prevCopy = JSON.parse(JSON.stringify(prev));
            prevCopy.pop();
            prevCopy.unshift({ time: props.zoneLive?.time, averageDecibel: props.zoneLive?.averageDecibel, averageDiff: props.zoneLive?.averageDiff, sysvolAbsolute: props?.zoneLive?.sysvolAbsolute });

            logs = prevCopy;
            return prevCopy;
        });

        const avgFilteredDb = logs?.reduce((a, b) => a + b?.averageDecibel - b?.averageDiff, 0) / logs?.length;
        if (props.setAverageFilteredDb) {
            props.setAverageFilteredDb(avgFilteredDb);
        }

    }, [props.zoneLive?.time])

    const getData = async () => {
        let logs = await getDefinedLogInterval(props.zone?.customerId, props.zone?.zoneId, zoneTypes.intervals.LAST_15_MINUTES, null);
        let now = DateTime.now();
        logs = logs?.filter(log => {
            let logTime = DateTime.fromJSDate(getDateTime(log.time));
            return now - logTime < 1 * 60 * 1000;
        });

        const avgFilteredDb = logs?.reduce((a, b) => a + b?.averageDecibel - b?.averageDiff, 0) / logs?.length;

        setGraphData(logs);

        if (props.setAverageFilteredDb) {
            props.setAverageFilteredDb(avgFilteredDb);
        }
    }

    return <ChartContainer>
        <LiveChart
            data={graphData}
            baseLevel={props?.newCalibrationPoints[0]?.measuredDb}
            savedBaseLevel={props.savedCalibrationPoints[0]?.measuredDb}
            averageFilteredDb={props.averageFilteredDb}
        />
    </ChartContainer>
}

export default LiveChartContainer;