import { TextMuted } from "../../../../UiComponents/text/StyledText";
import SidePanelList from "../SidePanelList";

const VenueList = ({ venues, users, selectedVenueId, setSelectedVenueId }) => {
    
    const searchFilter = (venue, searchString) => venue.companyName?.toLowerCase().includes(searchString.toLowerCase()) || venue.customerId.toString().includes(searchString.toLowerCase());
    const renderItem = (venue) => {
        const nUsers = users.filter(user => venue.userIds.includes(user.userId)).length;
        return <div>
            <div>{venue.companyName}</div>
            <TextMuted>{nUsers} user{nUsers != 1 ? 's' : ''}</TextMuted>
        </div>
    }

    return <SidePanelList 
        items={venues}
        selectedId={selectedVenueId}
        setSelectedId={setSelectedVenueId}
        searchFilter={searchFilter}
        renderItem={renderItem}
        idField={'customerId'}
    />
}

export default VenueList;