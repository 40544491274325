import styled from "styled-components";
import theme from "../../UI/theme";
import ViewContainer from "./ViewContainer";

const HeatmapContainer = styled(ViewContainer)`
    &>div {
        @media only screen and (min-width: ${theme.screenSizes.medium}px) {
            width: 60em;
        }
    }
`

export default HeatmapContainer;