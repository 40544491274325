import React from 'react';
import styled from 'styled-components';
import theme from '../../UI/theme';
/*
takes the follwing props:
show - state 
close - method

children - content to be displayed in modal
*/

const backgroundColor = '#E2E8F0'

const StyledInfoBubbleOverlay = styled.div`
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
`

const StyledBubbleContainer = styled.div`
    position: absolute;
    bottom: calc(100vh + 24px - ${props => props.pageY}px);
    left: ${props => props.pageX-32}px;
    font-size: ${theme.fontSize.small};
    min-width: 50px;
    background: ${backgroundColor};
    border-radius: 10px;
    padding: 12px 16px;
    color: ${theme.colors.darkSpace80};
    display: flex;
    flex-direction: column;
`

const StyledTagMark = styled.div`
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 24px solid ${backgroundColor};
    border-right: 12px solid transparent;
    border-top: 12px solid ${backgroundColor};
    border-bottom: 20px solid transparent;
    left: 32px;
    bottom: -24px;
`



const InfoBubble = props => {
    
    function handleClick(ev) {
        if (ev.target.id === 'bubble-overlay') {
            props.close();
        }
    }
    if(props.show) {
        return(
            <StyledInfoBubbleOverlay id="bubble-overlay" onClick={handleClick}>
                <StyledBubbleContainer pageX={props.pageX} pageY={props.pageY}>
                    {props.children}
                    <StyledTagMark/>
                </StyledBubbleContainer>
            </StyledInfoBubbleOverlay>
        );
    } else {
        return <></>
    }
}

export default InfoBubble;