import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Chart } from 'chart.js';
import theme from '../../../../UI/theme';


const Container = styled.div`
    height: 140px;
    canvas {
        max-width: 600px;
        min-width: 180px;
        /* margin: 20px 0; */

        /* @media only screen and (min-width: ${theme.screenSizes.medium}px) {
            min-width: 200px;
        } */
    }
`

const chartData = {
    labels: [],
    datasets: [
        {
            type: 'pie',
            backgroundColor: [
                theme.colors.crystalBlue,
                theme.colors.yellowFever,
                theme.colors.pinky,
                theme.colors.raspberryRed
                
            ],
            borderWidth: 0,
            data: []
        }
    ]
};

const options = {
    type: 'pie',
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false
        }
    },
    tooltips: {
        enabled: false
    },
}

const chartConfig = {
    data: chartData,
    options
}

const BalancePieChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    if (props.data) {
        var modeSum = [0,0,0,0];
        var total = 0;
        props.data.forEach(dayData => {
            dayData.hourData.forEach(hour => {
                hour.forEach((element, index) => {
                    modeSum[index] += element;
                    total += element;
                })
            });
        });
        
        if (total<=0) {
            total=1;
        }
        chartConfig.data.datasets[0].data = [modeSum[0]/total, modeSum[1]/total, modeSum[2]/total, modeSum[3]/total];
        chartConfig.data.labels = props.labels;
        if (chartInstance) {
            chartInstance.update();
        }

    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfig);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <Container>
            <canvas ref={chartContainer} height={500} />
        </Container>
    );
}

export default BalancePieChart;