import Dropdown from "../../../UiComponents/Dropdown"

const ZoneGroupDropdown = props => {
    let items = [{ key: null, label: 'None', zoneGroupId: null }];
    for (let index = 0; index < props?.zoneGroups?.length; index++) {
        const zoneGroup = props?.zoneGroups[index];
        items.push({ ...zoneGroup, key: zoneGroup.zoneGroupId, label: zoneGroup?.name });
    }

    return <Dropdown
        small
        items={items}
        selectedItem={items?.find(item => item.key === props.zoneGroupId) ?? null}
        onClick={(item) => props?.onClick(item)} />
}

export default ZoneGroupDropdown;