import styled from "styled-components";
import theme from "../../../UI/theme";
import KeyMetric from "../../UiComponents/KeyMetric";
import { Indicator } from "../support/SupportStyles";
import { userInteractionTypes } from "./userInteractionTypes";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-right: 10px;

    @media only screen and (max-width: ${theme.screenSizes.small - 1}px) {
        flex-direction: column;
        margin-right: 0;
    }
`

const DetailsContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(6, 1fr);
    grid-auto-flow: column;
    grid-column-gap: 5px;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    white-space: nowrap;
    justify-content: flex-start;

    @media only screen and (max-width: ${theme.screenSizes.small - 1}px) {
        margin-top: 10px;
        grid-auto-flow: row;
    }
`

const DetailsLabel = styled.label`
    color: ${theme.colors.textGray};
`

const SubMetricContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-items: center;
    align-self: baseline;
    margin-bottom: 5px;
`

const StyledIndicator = styled(Indicator)`
    margin: 0 10px;

    &:hover {
        cursor: pointer;
        transform: scale(1.2);
    }

    background-color: ${props => props.selected ? props.color : 'transparent'};
    border: 1px solid ${props => props.color};
    box-sizing: border-box;
`

const UserInteractionsMetric = props => {

    return <Container>
        <KeyMetric
            title={'Total'}
            metric={props.userInteractions?.length}
            label={'user interactions in selected period'} />

        <DetailsContainer>
            {Object.keys(userInteractionTypes).map((type, index) => {
                const selected = props.filter?.some(key => key === userInteractionTypes[type].key);

                return <SubMetricContainer key={index}>
                    <StyledIndicator color={userInteractionTypes[type].color} selected={selected} onClick={() => props.updateFilter(userInteractionTypes[type].key)} />
                    <DetailsLabel>{props.userInteractions?.filter(userInteraction => userInteractionTypes[type].key === userInteraction.setting)?.length ?? 0}</DetailsLabel>
                    <DetailsLabel>&nbsp;{userInteractionTypes[type].display}</DetailsLabel>
                </SubMetricContainer>
            })}
        </DetailsContainer>

    </Container>
}

export default UserInteractionsMetric;