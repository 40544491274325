import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { updateZoneModes } from '../../../../scripts/zoneModes';
import VolumeRangeSlider from './VolumeRangeSlider';
import theme from '../../../../UI/theme';
import Button from '../../../UiComponents/Button';
import HeaderContainer from '../../../UiComponents/containers/HeaderContainer';
import AnimateHeight from 'react-animate-height';
import ZoneModeMeter from '../../../live/zone/ZoneModeMeter';
import ToggleButton from '../../../UiComponents/ToggleButton';

const BASE_MODE_ORDER_INDEX = 0;

const Container = styled.div`
`

const ZoneModeSettings = props => {
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
    const [edited, setEdited] = useState(false);
    const [zoneModes, setZoneModes] = useState(props.zoneModes.map(e => ({ ...e })));

    const setZoneModeAttributes = (zoneMode, updatedAttributes) => {
        setZoneModes(currentZoneModes => {
            var updatedZoneModes = [...currentZoneModes];
            var updatedZoneMode = updatedZoneModes.find(zm => zm.zoneModeId === zoneMode.zoneModeId);
            if (updatedZoneMode) {
                Object.keys(updatedAttributes).forEach(key => {
                    updatedZoneMode[key] = updatedAttributes[key];
                })
            }
            adjustAccordingToBaseMode(updatedZoneModes);
            keepModesWithinLimits(updatedZoneModes);
            return updatedZoneModes;
        })

    }

    const adjustVolumeLimits = (zoneMode, updatedVolumeLimits) => {
        if (getReadOnly(zoneMode)) {
            return;
        }
        var updatedZoneModes = zoneModes.map(z => ({ ...z }));
        var updatedZoneMode = updatedZoneModes.find(zm => zm.zoneModeId === zoneMode.zoneModeId);
        if (updatedZoneMode) {
            verifyUpdatedLimits(updatedVolumeLimits);
            updatedZoneMode.minVol = updatedVolumeLimits.minVol;
            updatedZoneMode.maxVol = updatedVolumeLimits.maxVol;
            adjustAccordingToBaseMode(updatedZoneModes);
            keepModesWithinLimits(updatedZoneModes);
            setZoneModes(updatedZoneModes);
            if (!edited) {
                setEdited(true);
            }
        }
    }

    const applySettings = async () => {

        var activeZoneMode = zoneModes.find(zm => zm.orderIndex === parseInt(props.zone.zoneModeOrderIndex)) || zoneModes.find(zm => zm.orderIndex === BASE_MODE_ORDER_INDEX);
        if (activeZoneMode) {
            var updatedZoneAttributes = {
                minVol: activeZoneMode.minVol,
                maxVol: activeZoneMode.maxVol,
                delta: activeZoneMode.delta,
                zoneModeOrderIndex: activeZoneMode.orderIndex
            }
            props.updateZoneHandler(updatedZoneAttributes);
        }

        var updated = await updateZoneModes(zoneModes);
        if (updated) {
            setEdited(false);
        }
    }

    const displayZoneModeItem = (zoneMode) => {
        return <ZoneModeItem
            key={zoneMode.zoneModeId}
            zoneMode={zoneMode}
            adjustVolumeLimits={adjustVolumeLimits}
            setZoneModeAttributes={setZoneModeAttributes}
            showAdvanced={showAdvancedSettings}
        />
    }

    return <div>
        <HeaderContainer>
            <div>
                <h4>Volume limits</h4>
            </div>
            <div>
                {edited ? <>
                    <Button primary small onClick={() => applySettings()}>Apply</Button>
                </> : <></>}
            </div>
        </HeaderContainer>

        <Container>

            <AnimateHeight duration={500} height={showAdvancedSettings ? 'auto' : 0}>
                <ItemContainer>
                    <div></div>
                    <div>Edited</div>
                </ItemContainer>
                {zoneModes.filter(z => z.orderIndex > BASE_MODE_ORDER_INDEX).map(zoneMode => displayZoneModeItem(zoneMode))}
            </AnimateHeight>

            <div>
                {displayZoneModeItem(zoneModes.find(m => m.orderIndex === BASE_MODE_ORDER_INDEX))}
            </div>
            <AnimateHeight duration={500} height={showAdvancedSettings ? 'auto' : 0}>
                {zoneModes.filter(z => z.orderIndex < BASE_MODE_ORDER_INDEX).map(zoneMode => displayZoneModeItem(zoneMode))}
            </AnimateHeight>

            <Button tertiary lightBorder onClick={() => setShowAdvancedSettings(s => !s)}>
                {showAdvancedSettings ? 'Hide a' : 'A'}dvanced settings
            </Button>
        </Container>
    </div>
}

export default ZoneModeSettings;


const ItemContainer = styled.div`

    ${props => !props.showAdvanced && css`
        width: 80%;
    `}

    ${props => props.showAdvanced && css`
        display: grid;
        grid-template-columns: 90% 10%;
        align-items: center;
        div {
            margin-right: 8px;
        }
    `}
`
const Flex = styled.div`
    display: flex;
    align-items: center;
`
const RangeContainer = styled.div`
    width: 100%;
`

const ZoneModeItem = props => {
    var zoneMode = props.zoneMode;
    return <ItemContainer showAdvanced={props.showAdvanced}>
        <Flex>
            {props.showAdvanced ? <>
                <ZoneModeMeter value={zoneMode.orderIndex} />
            </> : <></>}

            <RangeContainer>
                <VolumeRangeSlider
                    backgroundColor={theme.colors.darkSpace}
                    values={[zoneMode.minVol, zoneMode.maxVol]}
                    onChange={(newValues) => props.adjustVolumeLimits(props.zoneMode, { minVol: newValues[0], maxVol: newValues[1] })}
                    color={getReadOnly(zoneMode) ? theme.colors.textGray : null}
                />
            </RangeContainer>
        </Flex>
        <div>
            {zoneMode.orderIndex !== BASE_MODE_ORDER_INDEX ? <>
                <ToggleButton checked={zoneMode.edited} onToggle={(newState) => props.setZoneModeAttributes(props.zoneMode, { edited: newState })} />
            </> : <></>}
        </div>
    </ItemContainer>
}


function getOffsetModeValues(baseMode, index) {
    var minVol = Math.max(baseMode.minVol + 10 * index, 0);
    var maxVol = Math.min(baseMode.maxVol + 10 * index, 100);
    maxVol = Math.max(maxVol, 1);
    minVol = Math.min(minVol, 99);
    return {
        minVol: minVol,
        maxVol: maxVol,
    }
}

function verifyUpdatedLimits(updatedLimits) {
    updatedLimits.maxVol = Math.max(updatedLimits.maxVol, 1, updatedLimits.minVol + 1);
    updatedLimits.minVol = Math.min(updatedLimits.minVol, 99, updatedLimits.maxVol - 1);
}

function adjustAccordingToBaseMode(updatedZoneModes) {
    var baseMode = updatedZoneModes.find(zm => zm.orderIndex === BASE_MODE_ORDER_INDEX);
    if (baseMode) {
        updatedZoneModes.forEach((zoneMode, i) => {
            if (!zoneMode.edited) {
                updatedZoneModes[i] = { ...zoneMode, ...getOffsetModeValues(baseMode, zoneMode.orderIndex) };
            }
        });
    }
}

function keepModesWithinLimits(updatedZoneModes) {
    var prevMinVol = updatedZoneModes[updatedZoneModes.length - 1].minVol;
    updatedZoneModes.slice().reverse().forEach((zoneMode) => {
        zoneMode.minVol = Math.max(prevMinVol, zoneMode.minVol);
        prevMinVol = zoneMode.minVol;

    });

    var prevMaxVol = updatedZoneModes[0].maxVol;
    updatedZoneModes.forEach(zoneMode => {
        zoneMode.maxVol = Math.min(prevMaxVol, zoneMode.maxVol);
        prevMaxVol = zoneMode.maxVol;
    });
}

function getReadOnly(zoneMode) {
    return zoneMode.orderIndex !== BASE_MODE_ORDER_INDEX && !zoneMode.edited;
}
