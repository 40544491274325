import styled from 'styled-components';

const StyledIcon = styled.svg`

    .icon__line {
        fill: none;
        stroke: currentColor;
        stroke-width: ${props => props.strokeWidth || 2.5}px;
        stroke-linecap: butt;
        stroke-linejoin: round;
        vector-effect: non-scaling-stroke;
        shape-rendering: geometricPrecision;
    }

    &.nav-bar__icon {
        stroke-width: 3.5px;
        fill: none;
        opacity: 1;
        stroke: currentColor;
    }
`

export default StyledIcon;