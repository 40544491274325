import { musicPlayerProviders } from './musicPlayerProviders';
import SoundtrackYourBrandPlayer from './soundtrackYourBrand/SoundtrackYourBrandPlayer';
import SpotifyPlayer from './spotify/SpotifyPlayer';

function getPlayerProvider(playerInfo) {

    if (!playerInfo.type) {
        console.log('A PlayerProvider must have a type, no type given to getPlayerProvider()');
        return false;
    }
    switch (playerInfo.type) {
        case musicPlayerProviders.SOUNDTRACK_YOUR_BRAND.key:
            return new SoundtrackYourBrandPlayer(playerInfo);
        default:
            return undefined
    }
}

async function checkAvailablePlayers(type, credentials = false) {
    const playerInfo = { type: type };
    const playerProvider = getPlayerProvider(playerInfo);
    const availablePlayers = await playerProvider.checkAvailablePlayers(credentials);
    return availablePlayers;
}

export {
    getPlayerProvider,
    checkAvailablePlayers
}