import styled from "styled-components"
import theme from "../../../../UI/theme"
import SaveButton from "../../../installation/table/SaveButton"
import PanelButton from "../../../panel/PanelButton"
import { permissionTableTypes } from "./userAdministrationUtils"
import AddVenuePermissionButton from "./userPermission/AddVenuePermissionButton"
import AddVenueUserButton from "./AddVenueUserButton"

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const HeaderSection = styled.div`
    display: flex;
    align-items: center;

    gap: 10px;

    >h3 {
        color: ${theme.colors.textGray};
        margin: 0;
    }
`

const PermissionsTableHeader = ({ tableType, users, venues, permissions, savePermissions, showAdvanced, setShowAdvanced, isEdited, addPermissionBundle, refreshWithUpdate }) => {
    const title = (tableType === permissionTableTypes.venue) ? venues[0].companyName + ' Users' : 'Venues';
    return <HeaderContainer>
        <HeaderSection>
            <h3>{title}</h3>
        </HeaderSection>
        <HeaderSection>
            {tableType === permissionTableTypes.user ? <>
                {users[0]?.shared === 0 ? <>
                    <AddVenuePermissionButton user={users[0]} venues={venues} permissions={permissions} addPermissionBundle={addPermissionBundle} />
                </> : <></>}
            </> : <>
                <AddVenueUserButton venue={venues?.[0]} refreshWithUpdate={refreshWithUpdate} />
            </>}
            <SaveButton
                isEdited={isEdited}
                saveHandler={savePermissions}
            />
            <PanelButton tertiary small onClick={() => setShowAdvanced(!showAdvanced)}>
                {showAdvanced ? 'Simple' : 'Detailed'}
            </PanelButton>
        </HeaderSection>
    </HeaderContainer>
}
export default PermissionsTableHeader;

