import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

const env = process.env.REACT_APP_ENVIRONMENT;

const useMixpanel = () => {
    useEffect(() => {
        // Initialize Mixpanel
        mixpanel.init('448ae644977df4237a1e89ab341377b1');

        // Clean up on component unmount
        return () => {
            // Reset Mixpanel user
            mixpanel.reset();
        };
    }, []);

    const trackEvent = (eventName, properties) => {
        // Track custom event
        if (env === 'DEVELOPMENT') {
            console.log('Mixpanel Event stopped in development mode.');
        } else mixpanel.track(eventName, properties);
    };

    return { trackEvent };
};

export default useMixpanel;