import { useState } from "react";
import serverConnection from "../../../../../scripts/server/ServerConnection";
import Button from "../../../../UiComponents/Button";
import LoadingSpinner from "../../../../UiComponents/LoadingSpinner";
import { loadingStates } from "../../../installationTypes";
import { ButtonRowContainer } from "../../../../panel/ModalStyles";
import theme from "../../../../../UI/theme";
import { TextMuted } from "../../../../UiComponents/text/StyledText";
import styled from "styled-components";

const states = {
    ...loadingStates,
    STARTING: 'STARTING',
    STOPPING: 'STOPPING',
    OPEN: 'OPEN',
    CLOSED: 'STOPPED'
}

const ConnectionStringContainer = styled.div`
    display: inline-block;
    background-color: ${theme.colors.darkSpace80};
    padding: 6px;
    margin: 4px 0;

    cursor: text;
    
`

const RemoteConnection = ({ hub }) => {
    const [connectionString, setConnectionString] = useState();
    const [loadingState, setLoadingState] = useState(states.NONE);

    const startRemoteConnection = async () => {
        try {
            setLoadingState(states.STARTING);
            const result = await serverConnection.hubEventHandler.sendEvent(
                hub.hubId,
                hub.customerId,
                'START_REMOTE_CONNECTION',
                null,
                20000
            );

            if (result.url) {
                setConnectionString(result.url);
                setLoadingState(states.OPEN);
            } else {
                console.log('failed to connect', result);
                setLoadingState(states.FAILED);
            }
            
        } catch (err) {
            console.log('debug could not start remote connection', err);
            setLoadingState(states.FAILED);
        }

    }

    const stopRemoteConnection = async () => {
        try {
            setLoadingState(states.STOPPING);
            const result = await serverConnection.hubEventHandler.sendEvent(
                hub.hubId,
                hub.customerId,
                'STOP_REMOTE_CONNECTION',
                null,
                20000
            );

            setConnectionString(result.url);
            setLoadingState(states.CLOSED);
        } catch (err) {
            console.log('debug could not stop remote connection', err);
            setLoadingState(states.FAILED);
        }

    }

    return <div>

        <ButtonRowContainer>
            <Button
                disabled={loadingState === states.STARTING || loadingState === states.OPEN}
                secondary
                onClick={startRemoteConnection}>
                Start remote connection
            </Button>

            <Button
                disabled={loadingState === states.STOPPING || loadingState === states.CLOSED}
                secondary
                onClick={stopRemoteConnection}>
                Close remote connection
            </Button>
        </ButtonRowContainer>

        <div style={{ marginTop: '12px', color: theme.colors.darkSpace10 }}>
            {loadingState === states.FAILED ? <>
                <p>Something went wrong</p>
            </> : <></>}

            {loadingState === states.STARTING ? <>
                <div>Opening tunnel connection...</div>
                <LoadingSpinner />
            </> : <></>}

            {loadingState === states.OPEN ? <>
                <div>Connected!</div>
                <div style={{ margin: '12px 0' }}>
                    <ConnectionStringContainer>{parseConnectionString(connectionString)}</ConnectionStringContainer>
                </div>
                <TextMuted>(Url: {connectionString})</TextMuted>
            </> : <></>}

            {loadingState === states.STOPPING ? <>
                <div>Closing remote connection...</div>
                <LoadingSpinner />
            </> : <></>}

            {loadingState === states.CLOSED ? <>
                <div>Closed remote connection</div>
            </> : <></>}

        </div>
    </div>
}

export default RemoteConnection;

const parseConnectionString = (url) => {
    const [address, port] = url.replace(/tcp:\/\//g, '').split(':');
    return `ssh pi@${address} -p ${port}`;
}