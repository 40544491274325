import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../UI/theme';
import ViewHeader from '../UiComponents/ViewHeader';
import { SettingsSection } from '../settings/settingsStyles';
import InputField from '../UiComponents/InputField';
import Button from '../UiComponents/Button';
import { ButtonRowContainer } from '../panel/ModalStyles';
import { sendPasswordResetLink } from '../../scripts/user';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { loadingStates } from '../installation/installationTypes';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    background-color: ${theme.colors.darkSpace90};
    padding: 20px;
    box-sizing: border-box;
`

const ForgotPasswordView = (props) => {
    const [email, setEmail] = useState('');
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const history = useHistory();

    const sendPasswordLink = async () => {
        try {
            await sendPasswordResetLink(email);
            setLoadingState(loadingStates.SUCCESS);
        } catch (error) {
            setLoadingState(loadingStates.FAILED);
        }
    }

    return <>
        <ViewHeader headerText={'Forgot password'} />

        <Container>
            {loadingState === loadingStates.NONE ? <>
                <SettingsSection>
                    <label>Email</label>
                    <InputField value={email} type="email" onChange={(e) => setEmail(e.target.value)} />
                </SettingsSection>

                <SettingsSection>
                    <ButtonRowContainer>
                        <Button primary onClick={() => sendPasswordLink()}>Send password reset link</Button>
                        <Button secondary onClick={() => history.push('/')}>Return to sign in</Button>
                    </ButtonRowContainer>
                </SettingsSection>
            </> : <></>}

            {loadingState === loadingStates.SUCCESS ? <>
                <label>Password reset link sent to {email}.</label>
            </> : <></>}

            {loadingState === loadingStates.LOADING ? <>
                <label>Sending password reset link...</label>
            </> : <></>}

            {loadingState === loadingStates.FAILED ? <>
                <label>Something went wrong. Please refresh page and try again.</label>
            </> : <></>}

            {loadingState === loadingStates.SUCCESS || loadingState === loadingStates.FAILED ? <>
                <ButtonRowContainer>
                    <Button primary onClick={() => history.push('/')}>Go to sign-in</Button>
                </ButtonRowContainer>
            </> : <></>}
            
        </Container>
    </>
}

export default ForgotPasswordView;