
import colormap from 'colormap';

var customColorMap;
try {
    const getColorMap = require('../../../UI/colormap');
    customColorMap = getColorMap();
} catch (e) {
    /* Gradient tool: https://colordesigner.io/gradient-generator */
    customColorMap = ['#a8dc00','#a3d601','#9fd002','#9aca02','#96c403','#91be04','#8cb805','#88b206','#83ac07','#7fa607','#7aa008','#769a09','#71940a','#6c8e0b','#68880c','#63820c','#5f7c0d','#5a760e','#55700f','#516a10','#4c6410','#485e11','#435812','#3e5213','#3a4c14','#354615','#314015','#2c3a16','#283417','#232e18','#1e2819','#1a221a','#151c1a','#11161b','#0c101c'];
}


const dataParams = {
    maxVol: 80,
    minVol: 35
}

const colorMapStart = 0;

/* const dataColors = colormap({
    colormap: 'YIOrRd',
    nshades: Math.round((dataParams.maxVol - dataParams.minVol)+colorMapStart),
    format: 'hex',
    alpha: 1
}).reverse().slice(colorMapStart, dataParams.maxVol - dataParams.minVol+colorMapStart); */

const dataColors = customColorMap.reverse();


const zoneColors = ['#00BFFF', '#35C191', '#F06F9E', '#F9F871', '#8177CB', '#DFA01E'];

// Exported functions
function getDataColor(data) {
    var colorIndex = Math.round(data) - dataParams.minVol;
    if (colorIndex < 0) {
        colorIndex = 0;
    } else if (colorIndex > dataColors.length - 1) {
        colorIndex = dataColors.length - 1;
    }
    return dataColors[colorIndex];
}

function getZoneColor(currentZone, zones) {
    var currentZoneIndex = zones.findIndex(zone => zone.floorElementId === currentZone.floorElementId) % zoneColors.length;
    if (currentZoneIndex === -1) {
        return '#FFF';
    }
    var zoneColor = zoneColors[currentZoneIndex || 0];
    return zoneColor;
}

function getDataColors() {
    return dataColors;
}

function getDataParams() {
    return dataParams;
}


export {
    getDataColor,
    getZoneColor,
    getDataColors,
    getDataParams
}