import React, { useState, useRef, useEffect } from "react";
import serverConnection from "../scripts/server/ServerConnection";

function useSubscription(type, id) {
    const [value, setValue] = useState();
    const subscriptionId = useRef();

    useEffect(() => {
        subscriptionId.current = serverConnection.subscriptionController.addSubscription(type, id, setValue);
        return () => {
            serverConnection.subscriptionController.removeSubscription(subscriptionId.current)
        };
    }, []);

    return [value, setValue];
}

export { 
    useSubscription 
}; 