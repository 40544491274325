import { FaChevronRight } from "react-icons/fa";
import { useHistory, useRouteMatch } from "react-router";
import styled from "styled-components";
import theme from "../../../UI/theme";
import Card from "../../UiComponents/Card";
import InnerCard from "../../UiComponents/containers/InnerCard";

const CardContainer = styled(Card)`
    margin: 20px 0;
`

const ZonesContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ZoneRow = styled(InnerCard)`
    display: flex;
    flex-direction: row;
    margin-bottom: 8px;
    justify-content: space-between;
    align-items: center;
    color: ${theme.colors.textGray};
    cursor: pointer;
`

const CustomerZoneList = props => {
    const match = useRouteMatch();
    const history = useHistory();

    const goToZoneDetailsView = (zoneId) => {
        history.push(`${match.url}/${zoneId}`);
        window.scrollTo(0, 0);
    }

    return <CardContainer>
        <h2>Zone details</h2>

        <ZonesContainer>
            {props.zones?.map((zone, index) => {
                return <ZoneRow key={index} onClick={() => goToZoneDetailsView(zone.zoneId)}>
                    <label>
                        {zone.zoneName}
                    </label>
                    <FaChevronRight />
                </ZoneRow>
            })}
        </ZonesContainer>
    </CardContainer>
}

export default CustomerZoneList;