import React from 'react';
import CanvasBox from "./CanvasBox";
import FurnitureBankItem from "./FurnitureBankItem";
import furnitureTypes from "./furnitureTypes";
import heatmapTypes from "./heatmapTypes";
import List from "./StyledComponents/CanvasBoxList";

const FurnitureBank = props => {

    const placeFunitureHandler = (furniture) => {
        props.actionHandler(heatmapTypes.actions.ADD_FURNITURE, furniture);
    }

    return <CanvasBox>
        <h4>Furniture</h4>
        <List>
            {Object.keys(furnitureTypes).map((furnitureType) => {
                return (
                    <FurnitureBankItem
                        key={furnitureTypes[furnitureType].type}
                        displayName={furnitureTypes[furnitureType].displayName}
                        furniture={furnitureTypes[furnitureType]}
                        placeFurniture={placeFunitureHandler}
                    />
                )
            })}
        </List>
    </CanvasBox>
}

export default FurnitureBank;