import { SettingsParagraph, SettingsParagraphContainer, SettingsSection, SettingsSubHeader } from "../../settingsStyles";
import LinkItem from "../../LinkItem";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useState } from "react";
import { useEffect } from "react";
import serverConnection from "../../../../scripts/server/ServerConnection";
import { HubIcon } from "../../../installation/styling/Icons";

const LocalAppLink = props => {
    const [connectedSsid, setConnectedSsid] = useState();

    useEffect(() => {
        getConnectedWifiNetwork();
    }, []);

    const getConnectedWifiNetwork = async () => {
        const res = await serverConnection.hubEventHandler.sendEvent(
            props.hub?.hubId,
            props.hub?.customerId,
            'GET_CONNECTED_WIFI_NETWORK',
            null,
            20000
        );

        setConnectedSsid(res?.ssid);
    }

    return <>
        <SettingsSection>
            <SettingsSubHeader><HubIcon />&nbsp; {props.hub?.name ?? `${props.hub?.hubId}`}</SettingsSubHeader>

            {props.hub?.networkConfig?.wlan0 ? <>
                <LinkItem
                    key={'wlan0'}
                    to={{ pathname: `http://${props.hub?.networkConfig?.wlan0?.address}:4000` }}
                    target='_blank' display={`Local app on Wi-Fi network${connectedSsid ? `: ${connectedSsid}` : ''}`}
                    icon={FaExternalLinkAlt}
                />
            </> : <></>}

            {props.hub?.networkConfig?.eth0 ? <>
                <LinkItem
                    key={'eth0'}
                    to={{ pathname: `http://${props.hub?.networkConfig?.eth0?.address}:4000` }}
                    target='_blank' display={'Local app on ethernet network'}
                    icon={FaExternalLinkAlt}
                />
            </> : <></>}

            {!props.hub?.networkConfig?.wlan0 && !props.hub?.networkConfig?.eth0 ? <>
                <SettingsParagraph>
                    Local app not available.
                </SettingsParagraph>
            </> : <></>}

        </SettingsSection>
    </>
}

export default LocalAppLink;