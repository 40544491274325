import { FaNetworkWired, FaWifi } from "react-icons/fa";
import { ConfigureWifiButton, ShowPasswordButton } from "./WifiConfig";
import { interfaceKeys } from "./networkConfigTypes";
import IpConfigTable from "./IpConfigTable";
import { useEffect, useRef, useState } from "react";
import serverConnection from "../../../../../scripts/server/ServerConnection";
import Button from "../../../../UiComponents/Button";
import { postEvent } from "../../../../../scripts/event";
import { RowContainer, SetupLabel } from "../../../../setup/setupStyles";
import { ModalHeader, ModalText } from "../../../../panel/ModalStyles";

const NetworkConfig = ({ hub }) => {
    const isMounted = useRef(false);
    const networkConfig = hub.networkConfig;
    const [connectedSsid, setConnectedSsid] = useState();
    const checkWifiTimeout = useRef();

    useEffect(() => {
        isMounted.current = true;
        checkWiFiConnection();

        return () => {
            clearTimeout(checkWifiTimeout.current);

            isMounted.current = false;
        };
    }, []);

    const checkWiFiConnection = async () => {
        const res = await serverConnection.hubEventHandler.sendEvent(
            hub?.hubId,
            hub?.customerId,
            'GET_CONNECTED_WIFI_NETWORK',
            null,
            20000,
            true
        );

        setConnectedSsid(res?.ssid);

        clearTimeout(checkWifiTimeout.current);
        if (isMounted.current === true) {
            checkWifiTimeout.current = setTimeout(async () => {
                await checkWiFiConnection();
            }, 2000);
        }
    }

    const syncNetworkConfig = () => {
        postEvent(hub?.hubId, hub?.customerId, 'SYNC_NETWORK_CONFIG', null);
    }

    if (!networkConfig) {
        return <>No config</>
    }
    return <>

        <ModalHeader>Network config</ModalHeader>

        <SetupLabel><FaNetworkWired />Ethernet</SetupLabel>

        {networkConfig.eth0 ? <>
            <ModalText>Connected</ModalText>
            <IpConfigTable
                hub={hub}
                mainKey={interfaceKeys.ETH0}
                virtualKey={interfaceKeys.ETH0_VIRTUAL}
            />
        </> : <>
            <ModalText>Not configured</ModalText>
        </>}

        <RowContainer>
            <SetupLabel><FaWifi /> Wi-Fi</SetupLabel>
            <ConfigureWifiButton hub={hub} />
        </RowContainer>

        {connectedSsid ? <>
            <ModalText>Connected</ModalText>
            <ModalText>SSID: {connectedSsid}</ModalText>
            <div>
                <ShowPasswordButton hub={hub} />
            </div>
        </> : <>
            <ModalText>Not configured</ModalText>
        </>}

        {networkConfig.wlan0 ? <>
            <IpConfigTable
                hub={hub}
                mainKey={interfaceKeys.WLAN0}
                virtualKey={interfaceKeys.WLAN0_VIRTUAL}
            />
        </> : <></>}

        <label>Sync network config manually</label>
        <div>
            <Button tertiary small onClick={() => syncNetworkConfig()}>Sync</Button>
        </div>
    </ >
}

export default NetworkConfig;


