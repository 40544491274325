
const updatePropsList = (propsList, id, updatedProps, idField) => {
    const props = propsList.find(object => object[idField] === id);
    if (props) {
        Object.keys(updatedProps).forEach(key => {
            props[key] = updatedProps[key];
        });
    }
    return propsList;
}

const updateElementInListState = (listState, setListState, elementId, updatedProps, idField) => {
    const updatedList = listState.map(element => ({...element}));
    updatePropsList(updatedList, elementId, updatedProps, idField);
    setListState(updatedList);
}

const propHasChanged = (currentProp, updatedProp) => {
    if (typeof updatedProp === 'object' && updatedProp !== null) {
        return JSON.stringify(updatedProp) !== JSON.stringify(currentProp);
    } else {
        return currentProp !== updatedProp;
    }
}

export {
    updatePropsList,
    updateElementInListState,
    propHasChanged
}
