import { addSourceSelector } from "../../../../scripts/sourceSelector";
import { sourceSelectorTypes } from "./sourceSelectorTypes";

const addNewSourceSelector = async (props, sourceSelectors, processors, customerId) => {
    try {
        const newSourceSelector = {
            ...props,
            customerId,
            orderIndex: sourceSelectors.length ? Math.max(...sourceSelectors.map(s => s.orderIndex)) + 1 : 1
        }
        await addSourceSelector(newSourceSelector);
    } catch (err) {
        console.log('Failed to add new sourceSelector', err);
    }
}

const getAvailableSources = (sourceSelector, sources) => {
    const unavailableSources = getUnavailableSourceIds(sourceSelector);
    return sources?.filter(source => !unavailableSources.includes(source.sourceId));
}

const getUnavailableSourceIds = (sourceSelector) => {
    return sourceSelector?.sourceConfig?.unavailableSources || [];
}

const isMatrix = (sourceSelector) => {
    return sourceSelector?.type === sourceSelectorTypes.MATRIX;
}

export {
    addNewSourceSelector,
    getAvailableSources,
    isMatrix
}