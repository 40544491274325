const trendTypes = {
    intervals: {
        WEEK: 0,
        YEAR: 1
    },
    dataTypes: {
        averageDecibel: 0,
        difference: 1,
        balance: 2, // balance holds [BALANCE, MUSIC_DOM, NOISE_DOM]
        regulations: 3, // regulations hold [human regulations, waved regulations]
        sysvol: 4,
        noise: 5,
        appMode: 6, // appmode holds [AMO, AM1, AM2, AM3]
        isRegulating: 7,
        isRegulatingAudioSource: 8,
    },
    trendStructure: {
        averageDecibel: 0, // int
        sysvol: 0,
        difference: 0, //int
        balance: [0, 0, 0], //arr
        regulations: [0, 0], //arr
        noise: 0,
        appMode: [0, 0, 0, 0],
        isRegulating: 0,
        isRegulatingAudioSource: 0
    },
    objectTypes: {
        ZONE: 0,
        SENSOR: 1
    },
    hierarchyLevels: {
        GROUP: 'GROUP',
        VENUE: 'VENUE',
        ZONE: 'ZONE',
        SENSOR: 'SENSOR'
    },
    periods: {
        DAY: 'DAY',
        WEEK: 'WEEK',
        MONTH: 'MONTH',
        YEAR: 'YEAR'
    },
    dateQueryTypes: {
        PERIOD: 'PERIOD',
        SINGLE_DATE: 'SINGLE_DATE'
    },
    getKeyByValue: function (object, value) {
        return Object.keys(object).find(key => object[key] === value);
    },
    valueExists: function (object, value) {
        return Object.values(object).indexOf(value) > -1;
    }
}

module.exports = trendTypes;