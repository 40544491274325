import styled, { css } from "styled-components";
import theme from "../../UI/theme";
import { postUserInteraction } from "../../scripts/userInteraction";
import { userInteractionTypes } from "../admin/customerDetails/userInteractionTypes";
import { useContext } from "react";
import UserContext from "../../hooks/UserContext";
import VibeSlider from "./VibeSlider";
import WaUsers from "../UiComponents/Icons/WaUsers";
import WaSpeakers from "../UiComponents/Icons/WaSpeakers";
import PanelButton from "./PanelButton";
import { hasVenuePermission, permissionTypes } from "../permission/permissions";

const VibeContainer = styled.div`
    animation: 0.5s ease-out 0s 1 scaleUpX;

    display: grid;
    align-items: center;
    grid-template-rows: 1fr;
    grid-template-columns: 50px 1fr 50px;

    height: 50px;
    color: ${theme.colors.darkSpace50};

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        height: 40px;
        grid-template-columns: 40px 1fr 40px;
    }

    ${props => props.disabled && css`
        opacity: 40%;
    `}
`

const PanelVibeButton = styled(PanelButton)`
    background-color: transparent;
    color: ${theme.colors.textGray};

    > svg {
        stroke: ${theme.colors.textGray};
    }
`

const VibeButton = props => {
    const userContext = useContext(UserContext);

    const changeVibe = async (newValue) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }

        if (!isNaN(newValue) && newValue >= -2 && newValue <= 2) {
            const oldZoneModeOrderIndex = props.zone.zoneModeOrderIndex;
            await props.updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, zoneModeOrderIndex: newValue })

            postUserInteraction(props.zone.customerId, {
                zoneId: props.zone.zoneId,
                setting: userInteractionTypes.modeID.key,
                fromValue: oldZoneModeOrderIndex,
                toValue: newValue
            });
        }
    }

    return <VibeContainer {...props}>
        <PanelVibeButton
            onClick={() => changeVibe(props.vibe - 1)}
            disabled={props.disabled}>
            <WaUsers />
            <label>People</label>
        </PanelVibeButton>

        <VibeSlider
            vibe={props.vibe}
            disabled={props.disabled}
            changeVibe={changeVibe} />

        <PanelVibeButton
            onClick={() => changeVibe(props.vibe + 1)}
            disabled={props.disabled}>
            <WaSpeakers />
            <label>Music</label>
        </PanelVibeButton>
    </VibeContainer >
}

export default VibeButton;