import React from 'react';
import Dropdown from "../../UiComponents/Dropdown";
import { cellStates } from '../table/tableTypes';
import CellDropdown from './components/CellDropdown';
import { DisplayField } from './Styles';


const ChoiceField = props => {
    const onChange = (value) => {
        props.setValue(value);
    }


    return <CellDropdown
        selectedItem={props.selectedItem}
        items={props.items}
        onClick={onChange}
        cellControl={props.cellControl}
        excludeNone={props.excludeNone}
        disabled={props.disabled}
    />

}

export default ChoiceField;
