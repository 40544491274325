import { createZone } from "../../../../scripts/zone";

const addNewZone = async (zoneProps, zones, processors, customerId) => {
    try {
        const processor = processors.find(processor => processor.processorId === zoneProps.processorId);
        const hubId = processor.hubId;
        const newZone = {
            ...zoneProps,
            customerId,
            hubId,
            orderIndex: zones.length ? Math.max(...zones.map(zone => zone.orderIndex)) + 1 : 1
        }
        await createZone(newZone);
    } catch (err) {
        console.log('Failed to add new zone', err);
    }
}


export {
    addNewZone
}