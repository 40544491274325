import { Chart } from "chart.js";
import { useEffect, useRef, useState } from "react";
import theme from "../../../UI/theme";
import { DateTime } from "luxon";
import { decibelWarningThreshold } from "./decibelInsightsUtils";

const chartData = {
    labels: [],
    datasets: [
        {
            label: 'Decibel',
            type: 'bar',
            backgroundColor: theme.colors.crystalBlue,
            // hoverBackgroundColor: theme.colors.greenEnergy,
            borderRadius: 2,
            barPercentage: 1,
            categoryPercentage: 0.9,
            borderSkipped: false,
            data: []
        }
    ]
}

const commonOptions = {
    maintainAspectRatio: false,
}

const fullOptions = {
    ...commonOptions,
    scales: {
        x: {
            display: true,
            type: 'time',
            time: {
                unit: 'hour',
                displayFormats: {
                    hour: 'HH'
                }
            },
            grid: {
                display: true,
                drawOnChartArea: true,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70,
                drawTicks: true,
                tickColor: theme.colors.darkSpace70,
                color: theme.colors.darkSpace70
            },
            ticks: {
                color: theme.colors.textGray,
                source: 'date',
                maxRotation: 0,
            }
        },
        y: {
            display: true,
            type: 'linear',
            // max: 110,
            suggestedMax: 90,
            min: 0,
            grid: {
                display: true,
                drawOnChartArea: true,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70,
                drawTicks: true,
                tickColor: theme.colors.darkSpace70,
                color: theme.colors.darkSpace70
            },
            ticks: {
                color: theme.colors.textGray
            }
        }
    },
    plugins: {
        legend: {
            display: false
        }
    }
}

const thumbnailOptions = {
    ...commonOptions,
    scales: {
        x: {
            display: false,
            type: 'time',
            time: {
                unit: 'hour'
            },
            ticks: {
                color: theme.colors.textGray
            }
        },
        y: {
            display: false,
            type: 'linear',
            // max: 110,
            min: 0
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        }
    }
}

const DecibelChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    let chartConfig;
    if (props.thumbnail) {
        chartConfig = JSON.parse(JSON.stringify({ data: chartData, options: { ...thumbnailOptions } }));
    } else {
        chartConfig = JSON.parse(JSON.stringify({ data: chartData, options: { ...fullOptions } }));
    }

    const chartConfigRef = useRef(JSON.parse(JSON.stringify(chartConfig)));

    useEffect(() => {
        let backgroundColors = Array(props.data?.length).fill(theme.colors.crystalBlue);
        chartConfigRef.current.data.datasets[0].data = props.data?.map((value, index) => {
            if (value > decibelWarningThreshold) {
                backgroundColors[index] = theme.colors.raspberryRed;
            }

            return {
                x: DateTime.fromJSDate(new Date()).set({ hour: index }).startOf('hour'),
                y: value
            }
        })

        chartConfigRef.current.data.datasets[0].backgroundColor = backgroundColors;

        if (chartInstance) {
            chartInstance.update();
        }
    }, [props.data])

    useEffect(() => {
        if (chartContainer?.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfigRef.current);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer])

    return (
        <canvas ref={chartContainer} />
    );
}

export default DecibelChart;