import styled, { css } from "styled-components"
import theme from "../../../../UI/theme"
import { ListActionButton } from "../../../installation/table/styles/ActionStyles"

export const UserAdministrationViewContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    margin-top: 10px;
    box-sizing: border-box;
`

export const UserAdminSectionContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin: 0 10px;
    box-sizing: border-box;
    width: fill-available;
    height: fit-content;
`

export const TableContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`

export const SidePanelInnerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content;
    grid-gap: 10px;
    width: fill-available;
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        color: ${theme.colors.darkSpace20};
    }   
    `

export const ListContainer = styled.div`
    overflow: auto;
`

export const ListItem = styled.li`
    padding: 10px;
    color: ${theme.colors.darkSpace10};

    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace70};
        color: ${theme.colors.greenEnergy};
    `}

    :hover {
        background-color: ${theme.colors.darkSpace70};
        cursor: pointer;
    }
`

export const AddButton = styled(ListActionButton)`
    ${props => props.highlighted && css`
        color: ${theme.colors.darkSpace};
        svg {
            color: ${theme.colors.darkSpace};
        }
        background-color: ${theme.colors.greenEnergy};
    `}
`

export const TableLink = styled.div`
    :hover {
        text-decoration: underline;
    }
`