import { useState } from "react"
import styled, { css } from "styled-components"
import LiveChartWithIntervalSelector from "./LiveChartWithIntervalSelector"
import LiveChartContainer from "./LiveChartContainer"
import { ModalContainer, ModalHeader } from "../ModalStyles"
import Modal from "../../UiComponents/Modal"

const Container = styled.div`
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;

    ${props => props.disabled && css`
        opacity: 40%;
        cursor: inherit;
        pointer-events: none;
    `}
`

const StyledModalContainer = styled(ModalContainer)`
    width: 600px;
    max-width: fill-available;
`

const ZoneLiveChart = props => {
    const [showModal, setShowModal] = useState(false);

    return <>
        <Container onClick={() => setShowModal(true)}>
            <LiveChartContainer
                className={props.className}
                zone={props.zone}
                zoneLive={props.zoneLive}
                processorConnected={props.processorConnected} />
        </Container>

        <Modal show={showModal} close={() => { setShowModal(false);}}>
            <StyledModalContainer>
                <ModalHeader>{props.zone?.zoneName} - Live data</ModalHeader>

                <LiveChartWithIntervalSelector
                    zone={props.zone}
                    zoneLive={props.zoneLive}
                    processorConnected={props.processorConnected}
                    className={props.className} />
            </StyledModalContainer>
        </Modal>
    </>
}

export default ZoneLiveChart;