import { useContext } from "react";
import { FaHistory, FaSeedling } from "react-icons/fa";
import UserContext from "../../../hooks/UserContext";
import { postUserInteraction } from "../../../scripts/userInteraction";
import { userInteractionTypes } from "../../admin/customerDetails/userInteractionTypes";
import { ModalText, RowContainer } from "../ModalStyles";
import PanelButton from "../PanelButton";
import { hasVenuePermission, permissionTypes } from "../../permission/permissions";
import hierarchyTypes from "../../settings/venue/device/hierarchyTypes";

const ZoneAdvancedSettings = props => {
    const userContext = useContext(UserContext);

    const toggleMoodSustain = async (newState) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }

        await props.updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, moodSustain: +newState });
        postUserInteraction(props.zone?.customerId, {
            zoneId: props.zone?.zoneId,
            setting: userInteractionTypes.moodSustain.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    const toggleAutomaticReset = async (newState) => {
        if (!hasVenuePermission(userContext, permissionTypes.installationViewWrite)) {
            return;
        }

        await props.updateZoneWithEvent({ zoneId: props.zone.zoneId, hubId: props.zone.hubId, autoResetZoneSettings: newState });
    }

    return <>
        {props.zone?.shouldRegulate === 1 && props.zone?.hierarchyType !== hierarchyTypes.MASTER.value ? <>
            <label>Advanced settings</label>
            <RowContainer>
                <ModalText>
                    Mood sustain
                </ModalText>

                <PanelButton
                    onClick={() => toggleMoodSustain(!props.zone?.moodSustain)}
                    deactivated={!props?.zone?.moodSustain}
                    disabled={!props.processorConnected}
                >
                    <FaSeedling />
                    <label>{props.zone?.moodSustain ? 'On' : 'Off'}</label>
                </PanelButton>
            </RowContainer>

            {hasVenuePermission(userContext, permissionTypes.installationViewWrite) ?
                <RowContainer>
                    <ModalText>Automatic reset</ModalText>
                    <PanelButton
                        onClick={() => toggleAutomaticReset(!props.zone?.autoResetZoneSettings)}
                        deactivated={!props?.zone?.autoResetZoneSettings}
                        disabled={!props.processorConnected}
                    >
                        <FaHistory />
                        <label>{props.zone?.autoResetZoneSettings ? 'On' : 'Off'}</label>
                    </PanelButton>
                </RowContainer>
                : <></>
            }

        </> : <></>}
    </>
}

export default ZoneAdvancedSettings;