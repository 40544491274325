import React, { useState, useEffect } from 'react';
import { getAllHubs } from '../../../scripts/hub';
import TopNavigation from '../../navigation/TopNavigation';
import ViewHeader from '../../UiComponents/ViewHeader';
import ViewContainer from '../../UiComponents/ViewContainer';
import Selector from '../../UiComponents/Selector';
import tabs from './tabs';
import DeviceTableContainer from './DeviceTableContainer';
import { getAllSensors } from '../../../scripts/sensor';
import { sortByKey } from '../../../scripts/common';

const AdminDeviceView = props => {
    const [selectedTab, setSelectedTab] = useState(tabs.keys.HUBS);
    const [hubs, setHubs] = useState();
    const [sensors, setSensors] = useState();

    useEffect(() => {
        getDataHandler();
    }, []);

    const getDataHandler = async () => {
        try {
            let hubRes = await getAllHubs();
            hubRes = sortByKey(hubRes, 'customerId');
            let sensorRes = await getAllSensors();
            sensorRes = sortByKey(sensorRes, 'customerId');
            setHubs(hubRes);
            setSensors(sensorRes);
        } catch (err) {
        }
    }

    const selectedItem = tabs.items.find(item => item.key === selectedTab);
    return <ViewContainer minWidth={'80%'}>
        <div>
            <ViewHeader backLink={'/supportcenter'} headerText={'Devices'} />

            <Selector large items={tabs.items} selectedItem={selectedItem} onClick={newTab => setSelectedTab(newTab.key)} />

            {selectedTab === tabs.keys.HUBS && hubs ? <>
                <DeviceTableContainer
                    devices={hubs}
                    setDevices={setHubs}
                    deviceType={'HUB'}
                    refreshDevices={getDataHandler}
                />
            </> : <></>}

            {selectedTab === tabs.keys.SENSORS && sensors ? <>
                <DeviceTableContainer
                    devices={sensors}
                    setDevices={setSensors}
                    deviceType={'SENSOR'}
                    refreshDevices={getDataHandler}
                />
            </> : <></>}
        </div>
    </ViewContainer>

}

export default AdminDeviceView;
