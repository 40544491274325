import styled, { css } from "styled-components";
import theme from "../../UI/theme";

export const InsightsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    padding: 0px 20px 20px 20px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-gap: 10px;
        padding: 0 10px 10px 10px;
        margin-bottom: 85px;
    }
`

export const CardHeader = styled.label `
    font-size: 1.5rem;
    color: ${theme.colors.whiteSpace};
    white-space: nowrap;
`


export const ChartGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 100px;
    grid-gap: 20px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-gap: 10px;
        grid-template-columns: 1fr 1fr;
        grid-auto-rows: 70px;
    }
`

export const MainChartContainer = styled.div`
    overflow: hidden;
    background-color: ${theme.colors.darkSpace90};
    color: ${theme.colors.textGray};
    padding: 20px;
    border-radius: 2px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-gap: 20px;

    grid-column: 1 / -2;
    grid-row: 1 / span 4;

    ${props => props.noOfRelevantTrends === 1 && css`
        grid-column: 1 / -1;
    `}

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        padding: 10px;
        grid-gap: 10px;
        grid-column: 1 / -1;
        grid-row: 1 / span 4;
    }
`

export const ThumbnailContainer = styled.div`
    background-color: ${theme.colors.darkSpace90};
    overflow: hidden;
    border-radius: 2px;
    padding: 10px 20px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    grid-gap: 5px;
    color: ${theme.colors.textGray};
    border: 1px solid transparent;

    &>label{
        cursor: inherit;
    }

    ${props => !props.selected && css`
        @media (hover:hover) {
            &:hover {
                border-color: ${theme.colors.darkSpace70};
                cursor: pointer;
            }
        }
    `}

    ${props => props.selected && css`
        color: ${theme.colors.greenReadable};
        border-color: ${theme.colors.greenEnergy};
    `}

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        padding: 5px 10px;
        grid-gap: 0px;
    }
`

export const ChartContainer = styled.div`
    overflow: hidden;
`

export const MetricsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-gap: 10px;
    }
`