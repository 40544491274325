import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import theme from '../../../UI/theme';

const WavedSlider = props => {
  const classes = useStyles();

  const noAction = () => {

  }

  return (
    <div className={classes.root}>
      <WS
        valueLabelDisplay="auto"
        value={props.value}
        max={props.max}
        min={props.min}
        onChange={props.readOnly ? noAction : props.onChange}
        onChangeCommitted={props.readOnly ? noAction : props.onChangeCommitted}
        valueLabelDisplay={props.showValue ? props.showValue : 'auto'} // 'on', off', 'default' = show on hover
        marks={props.marks ? props.marks : []} // props.marks = {{value=0, label='firstLabel'}, {value=0, label='secondLabel'}}
      />
    </div>
  );
}

export default WavedSlider;

const useStyles = makeStyles(theme => ({
  root: {
    //width: 300 + theme.spacing(3) * 2,
    width: '100%'
  },
  margin: {
    height: theme.spacing(3),
  },
}));

const WS = withStyles({
  root: {
    color: theme.colors.greenReadable,
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: `2px solid ${theme.colors.greenReadable}`,
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  mark: {
    opacity: 0,
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markLabel: {
    color: '#FFFFFF'
  }
})(Slider);