import { useState } from "react";
import styled from "styled-components";
import { ClickableTableRow, TableData, TableRow } from "../support/SupportStyles";
import CustomerDetails from "./CustomerDetails";
import HealthMetricLabel from "./HealthMetricLabel";

const CustomerInfoTD = styled.td.attrs({ colSpan: 8 })`
`

const CustomerHealthMetric = props => {
    const [expanded, setExpanded] = useState();

    return <>
        <ClickableTableRow onClick={() => setExpanded(prev => !prev)}>
            <TableData>{props.customerId}</TableData>
            {props.customerMetricTypes?.map((metricType, index) => {
                const currentMetric = props.customerMetrics?.find(metric => metric.metricType === metricType.key)?.metricValue;
                const previousMetric = props.previousCustomerMetrics?.find(metric => metric.metricType === metricType.key)?.metricValue;

                return <TableData key={index}>
                    <HealthMetricLabel metricType={metricType} currentMetric={currentMetric} previousMetric={previousMetric} />
                </TableData>
            })}
        </ClickableTableRow>

        {expanded === true ? <TableRow>
            <CustomerInfoTD>
                <CustomerDetails customerId={props.customerId} />
            </CustomerInfoTD>
        </TableRow> : <></>}
    </>
}

export default CustomerHealthMetric;