import React, { useState } from "react";
import { TroubleshootingContainer, TroubleshootingButton, SolutionContainer } from "./StylesTroubleshooting";
import theme from "../../UI/theme";

const TroubleshootInternet = (props) => {

    const [expand, setExpand] = useState(false);

    return (
        <TroubleshootingContainer >
            <h4 >Warning</h4>
            <p>You seem to have lost internet connection</p>
            {!expand? 
                <TroubleshootingButton primary small onClick={() => setExpand(true)}>See solutions</TroubleshootingButton>
            :<>
                <TroubleshootingButton secondary small onClick={() => setExpand(false)}>Hide solutions</TroubleshootingButton>
                <SolutionContainer>
                    <h4>Possible solutions:</h4>
                    <ol>
                        <li>
                            <p>
                                <strong>If you are connected to the correct network at your venue, please contact your administrator!</strong>
                            </p>
                            <ul>
                                <li>
                                    <p>Meanwhile you can try accessing Waved via your local network:</p>
                                    <p><a target={'new'} href={`http://${props.hubs[0].ipAddress}:4000`}>Go to local app</a></p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>If you are not at your venue, please make sure you have a stable internet connection on this device.</p>
                        </li>
                    </ol>
                </SolutionContainer>
            </>}
        </TroubleshootingContainer>
    )
} 

export default TroubleshootInternet;