import { TextMuted } from "../../../../UiComponents/text/StyledText";
import SidePanelList from "../SidePanelList";
import { getUserDisplayName } from "../userAdministrationUtils";

const UserList = ({ users, selectedUserId, setSelectedUserId }) => {
    
    const searchFilter = (user, searchString) => getUserDisplayName(user).toLowerCase().includes(searchString.toLowerCase());
    const renderItem = (user) => {
        const nVenues = user.venueIds.length;
        return <div>
            <div>{getUserDisplayName(user)}</div>
            <TextMuted>{nVenues} venue{nVenues != 1 ? 's' : ''}</TextMuted>
        </div>
    }

    return <SidePanelList 
        items={users}
        selectedId={selectedUserId}
        setSelectedId={setSelectedUserId}
        searchFilter={searchFilter}
        renderItem={renderItem}
        idField={'userId'}
    />
}

export default UserList;