import React, { useState } from 'react';
import heatmapTypes from './heatmapTypes';
import CanvasBox from './CanvasBox';
import List from './StyledComponents/CanvasBoxList';
import BankItem from './BankItem';
import { getHubDisplayName } from '../../installation/modules/hub/hubUtils';

const DeviceBank = props => {

    const placeDeviceHandler = (device) => {
        props.actionHandler(heatmapTypes.actions.PLACE_DEVICE, device);
    }

    const removeDevice = (device) => {
        props.actionHandler(heatmapTypes.actions.REMOVE_DEVICE, device);
    }

    const markDevice = (device) => {
        props.actionHandler(heatmapTypes.actions.UNMARK_ALL_DEVICES, null);
        if (device.floorElementId != props.markedDevice) {
            props.actionHandler(heatmapTypes.actions.MARK_DEVICE, device);
            props.setMarkedDevice(device.floorElementId);
        } else {
            props.setMarkedDevice(null);
        }
    }

    return <CanvasBox>
        <h4>Hardware</h4>

        <List>
            {props.devices?.map((device) => {
                return <BankItem
                    key={device.floorElementId}
                    item={device}
                    displayName={getHubDisplayName(device)}
                    placeItem={placeDeviceHandler}
                    removeItem={() => removeDevice(device)}
                    isMarked={device.floorElementId === props.markedDevice}
                    markItem={markDevice}
                    floor={props.floors.find(floor => floor.floorId === device.floorId)}
                />
            })}
        </List>

    </CanvasBox>
}

export default DeviceBank;

