import { useState } from "react";
import AnimateHeight from "react-animate-height";
import { FaChevronDown, FaChevronUp, FaExclamationCircle } from "react-icons/fa";
import theme from "../../../../../UI/theme";
import { CalibrationCardHeader, CalibrationGroupContainer, ColumnContainer, MasterGroupHeaderRow } from "../calibrationStyles";
import MasterCalibrationItem from "./MasterCalibrationItem";

const MasterCalibration = props => {
    const [expanded, setExpanded] = useState(true);

    return <CalibrationGroupContainer expanded={expanded}>
        <MasterGroupHeaderRow onClick={() => setExpanded(prev => !prev)}>
            <CalibrationCardHeader>Master zones</CalibrationCardHeader>

            {props.zones?.some(zone => zone.fixedMasterVolume === null) ? <FaExclamationCircle color={theme.colors.raspberryRed} /> : <div></div>}

            {expanded === false ? <FaChevronDown /> : <FaChevronUp />}
        </MasterGroupHeaderRow>

        <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
            <ColumnContainer>
                {props.zones?.map(zone => {
                    const zoneSourceSelector = props?.sourceSelectors?.find(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId);
                    const zoneProcessor = props?.processors?.find(processor => processor?.processorId === zone?.processorId);
                    const zoneSources = props?.sources?.filter(source => source?.processorId === zoneProcessor?.processorId);

                    return <MasterCalibrationItem
                        key={zone?.zoneId}
                        zone={zone}
                        zoneLive={props.zoneLives?.find(zoneLive => zoneLive?.zoneId === zone?.zoneId)}
                        sourceSelector={zoneSourceSelector}
                        sources={zoneSources} />
                })}
            </ColumnContainer>
        </AnimateHeight>
    </CalibrationGroupContainer>
}

export default MasterCalibration;