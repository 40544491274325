import Card from "../components/UiComponents/Card";
import CenteredViewContainer from "../components/UiComponents/CenteredViewContainer";
import styled from 'styled-components';
import IconLogo from "../components/UiComponents/IconLogo";
import theme from "../UI/theme";
import LogoutButton from "../components/authentication/LogoutButton";
import Button from "../components/UiComponents/Button";
import { isLocalApp } from "../scripts/server/server";

const Logo = styled(IconLogo)`
    width: 30px;
    height: 30px;
`

const TitleContainer = styled.div`
    display: flex;
    align-items: center;

    div {
        padding-right: 16px;
    }
`

const Link = styled.a`
    color: ${theme.colors.darkSpace20};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

const ServiceUnavailableView = ({ }) => {

    return <CenteredViewContainer>
        <Card>
            <TitleContainer>
                <Logo />
                <h3>Service unavailable</h3>
            </TitleContainer>
            <p>The waved app is currently unavailable.</p>
            {isLocalApp() ? <>
                You're trying to access the local version of the app. Please verify that you are connected to the same network as the hub and that the hub's ip address is correct.
                You can try to open the online version of the app at <Link href={`https://waved.app`}>waved.app</Link>
            </> : <>
                Please verfiy that you are connected to a network with internet access.
            </>}
            <p>Call <Link href="tel:+4722222233"> +47 22 22 22 33</Link> for support or if you have any questions.</p>
            <Button secondary onClick={() => window.location.reload(false)}>Reload</Button>
        </Card>
    </CenteredViewContainer>
}

export default ServiceUnavailableView;