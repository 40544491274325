import styled from 'styled-components';
import theme from '../../UI/theme';

export const wPath = "M2.5,20.7h2h0c3.7,0,6.9,2.7,7.5,6.4l4,25.7c0.6,3.7,3.8,6.4,7.5,6.4h0c3.7,0,6.9-2.7,7.5-6.4l4-25.7 c0.6-3.7,3.8-6.4,7.5-6.4h0c3.7,0,6.9,2.7,7.5,6.4l4,25.7c0.6,3.7,3.8,6.4,7.5,6.4h0c3.7,0,6.9-2.7,7.5-6.4l4-25.7 c0.6-3.7,3.8-6.4,7.5-6.4h0h2";
 

export const IconPath = styled.path`   
    stroke: ${theme.colors.darkSpace};
    stroke-width: 9.3px; 
    stroke-linecap: butt;
    stroke-linejoin: round;
    fill: none;
    transform: translateX(-2.5px) translateY(1px);
`
const IconSvg = styled.svg`

`
const IconContainer = styled.rect`
    fill: ${theme.colors.greenEnergy};
    stroke: none;
`

const IconLogoStructure = props => {
    return (
        <IconSvg viewBox="0 0 80 80">
            <IconContainer x="0" width="80" height="80"/>
            <IconPath d={wPath}/>
        </IconSvg>
    );
}

export default IconLogoStructure;