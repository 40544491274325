import React, { useState, useEffect, useRef } from 'react'
import serverConnection from '../scripts/server/ServerConnection';

const useServerStatus = props => {
    const [status, setStatus] = useState();
    const hookId = useRef();

    const updateStatus = (newStatus) => {
        if (newStatus !== status) {
            setStatus(newStatus)
        }
    }

    useEffect(() => {
        hookId.current = serverConnection.addStatusHook(updateStatus);
        return () => {
            serverConnection.removeStatusHook(hookId.current);
        };
    }, []);

    return status;

}

export default useServerStatus;