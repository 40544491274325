import styled, { css } from "styled-components";
import theme from "../../UI/theme";

export const TreeContainer = styled.div`
    height: fill-available;
    min-height: fit-content;
    width: fill-available;
    overflow: auto;
    padding: 10px;
    box-sizing: border-box;

    li::before, li::after {
        content: "";
        position: absolute;
        left: -20px;
    }

    li::before {
        border-top: 2px solid ${theme.colors.textGray};
        top: 12px;
        width: 10px;
        height: 0;
    }

    li::after {
        border-left: 2px solid ${theme.colors.textGray};
        height: 100%;
        width: 0px;
        top: 4px;
    }

    ul > li:last-child::after {
        height: 8px;
    }

    svg {
        font-size: ${theme.fontSize.large};
        max-width: 1.1rem;
        max-height: 1.1rem;
    }
`

export const TreeUL = styled.ul`
    list-style-type: none;
    margin: 0 0 0 30px;
    padding: 1px 0;
    position: relative;
    display: ${props => props.expanded ? '' : 'none'};
`

export const TopLevelTreeUL = styled(TreeUL)`
    display: unset;
    margin: 0px;
`

export const TreeLI = styled.li`
    position: relative;
    padding: 1px 0;
`

export const TopLevelTreeLI = styled(TreeLI)`
    &:before, &:after {
        all: unset !important;
    }

    &:before {
        all: unset !important;
    }

    &:after {
        all: unset !important;
    }
`

export const LIHeader = styled.label`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    font-size: ${theme.fontSize.small};
    font-weight: lighter;
    color: ${theme.colors.textGray};
    stroke: ${theme.colors.textGray};
    white-space: nowrap;

    > svg {
        size: 1rem;
        height: 1rem;
        width: 1rem;
        font-size: 1rem;
        ${props => props.selected && css`
            :first-of-type {
                color: ${theme.colors.greenEnergy};
                stroke: ${theme.colors.greenEnergy};
            }
        `}
    }
`

export const BranchLabel = styled.label`
    cursor: pointer;

    ${props => props.selected && css`
        color: ${theme.colors.greenReadable};
    `}
`