import {IconContext} from 'react-icons';
import { MdOutlineLiveHelp } from "react-icons/md";

const  HelpIcon = (props) => {

  return (
    <IconContext.Provider
      value={{ color: props.color || "#FFFFFF", size: props.size || '28px' }}
    >
      <MdOutlineLiveHelp />
    </IconContext.Provider>
  )
}

export default HelpIcon;