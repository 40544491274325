import { useEffect, useState } from "react";
import Dropdown from "../../UiComponents/Dropdown";


export const getCountry = (customer) => {
    if (customer?.address?.includes(',')) {
        return customer?.address?.split(',')?.pop();
    } else {
        return null;
    }
}

const CountrySelector = props => {
    const [selectedCountry, setSelectedCountry] = useState(props.selectedCountry ?? null);
    const [countries, setCountries] = useState();

    const onSelectCountry = (country) => {
        setSelectedCountry(country);
        if (props.onSelect) {
            props.onSelect(country ?? null);
        }
    }

    useEffect(() => {
        const distinctCountries = props.customers?.
            map(customer => getCountry(customer))?.
            filter((country) => country !== null)?.
            filter((customer, index, array) => array?.indexOf(customer) === index)?.
            map(country => { return { key: country, label: country } })?.
            sort((a, b) => a?.label > b?.label ? 1 : -1);
        setCountries(distinctCountries);
    }, [props.customers])

    return <>
        <Dropdown
            items={countries}
            selectedItem={selectedCountry}
            onClick={(country) => onSelectCountry(country)}
            placeholder={'Filter country'}
            clearable
        />
    </>
}

export default CountrySelector;