import styled, { css } from "styled-components";
import { updateZoneUserSettings } from "../../../../scripts/zone";
import theme from "../../../../UI/theme";
import { GroupItemHeader } from "./groupStyles";
import ZoneGroupDropdown from "./ZoneGroupDropdown";

const Container = styled(GroupItemHeader)`
    background-color: ${theme.colors.darkSpace};
    padding: 10px;
    border-radius: 2px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 10px;

    ${props => props.hasSourceSelector === true && css`
        padding: 0;
    `}
`

const Zone = props => {
    const onChangeZoneGroup = async (newZoneGroup) => {
        const pickedZone = (({ customerId, zoneId }) => ({ customerId, zoneId }))(props.zone);
        await updateZoneUserSettings({ ...pickedZone, zoneGroupId: newZoneGroup?.zoneGroupId });
    }

    return <Container hasSourceSelector={props.hasSourceSelector}>
        <label>{props?.zone?.zoneName}</label>
        {props?.hasSourceSelector === false ?
            <ZoneGroupDropdown
                zoneGroups={props.zoneGroups}
                zoneGroupId={props.zone?.zoneGroupId}
                onClick={onChangeZoneGroup} />
            : <></>}
    </Container>
}

export default Zone;