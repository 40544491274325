import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/permission';

async function getUserPermissions() {
    const res = await axios.get(`${hostname}${path}/userPermissions`);
    return res.data;
}

async function getUserVenues() {
    const res = await axios.get(`${hostname}${path}/userVenues`);
    return res.data;
}

async function getVenues() {
    const res = await axios.get(`${hostname}${path}/venues`);
    return res.data;
}

async function getUsers() {
    const res = await axios.get(`${hostname}${path}/users`);
    return res.data;
}

async function getPermissions(venueId, userId) {
    const res = await axios.get(`${hostname}${path}/permissions?${venueId ? `venueId=${venueId}&` : ''}${userId ? `userId=${userId}` : ''}`);
    return res.data;
}

async function getVenueAdmins() {
    const res = await axios.get(`${hostname}${path}/venueAdmins`);
    return res.data;
}

async function addPermission(permission) {
    const res = await axios.post(`${hostname}${path}`, permission);
    return res.data;
}

async function deletePermission(permission) {
    const res = await axios.delete(`${hostname}${path}?userId=${permission.userId}&permissionType=${permission.permissionType}&entityType=${permission.entityType}&entityId=${permission.entityId}`);
    return res.data;
}

async function addInsallerPermissions(username, password, setupKey) {
    const res = await axios.post(`${hostname}${path}/installerPermissions`, { username, password, setupKey });
    return res.data;
}

export {
    getUserPermissions,
    getUserVenues,
    getVenues,
    getUsers,
    getPermissions,
    getVenueAdmins,
    addPermission,
    deletePermission,
    addInsallerPermissions
}