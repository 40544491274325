import React from 'react';
import styled from "styled-components";
import { useSubscription } from '../../hooks/useSubscription';
import { subscriptionsTypes } from '../../scripts/subscription/subscriptionTypes';
import ViewHeader from '../UiComponents/ViewHeader';
import TopNavigation from '../navigation/TopNavigation';
import Button from '../UiComponents/Button';
import Modal from '../UiComponents/Modal';
import { useState } from 'react';
import MusicPlayerSetup from './setup/MusicPlayerSetup';
import MusicPlayerSettings from './MusicPlayerSettings';
import Card from '../UiComponents/Card';

const MusicContentViewContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
`

const MusicContentView = (props) => {
    const [musicPlayers, setMusicPlayers] = useSubscription(subscriptionsTypes.musicPlayers);
    const [sources, setSources] = useSubscription(subscriptionsTypes.sources);
    const [showMusicPlayerSetup, setShowMusicPlayerSetup] = useState(false);

    return <>
        <ViewHeader headerText={'Music Players'} backLink={'/settings'} showVenueName={true} />

        <MusicContentViewContainer>
            <Card>
                <Button primary onClick={() => setShowMusicPlayerSetup(true)}>Add music player</Button>
            </Card>

            {musicPlayers?.map(musicPlayer => {
                return <MusicPlayerSettings key={musicPlayer?.musicPlayerId} musicPlayer={musicPlayer} sources={sources} />
            })}

            <Modal show={showMusicPlayerSetup} close={() => setShowMusicPlayerSetup(false)}>
                <MusicPlayerSetup customerId={props.customerId} musicPlayers={musicPlayers} />
            </Modal>
        </MusicContentViewContainer>
    </>
}

export default MusicContentView;