import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { getHubIdFromLocalApp } from '../../scripts/hub';
import LoadingSpinner from '../UiComponents/LoadingSpinner';
import CustomerSetup from './CustomerSetup';
import { networkInterfaces } from './networkInterfaces';
import NetworkSetup from './NetworkSetup';
import setupSteps from './setupSteps';
import { SectionContainer, SetupContainer } from './setupStyles';
import ViewHeader from '../UiComponents/ViewHeader';

const LocalSetupView = props => {
    const [setupStep, setSetupStep] = useState(setupSteps.NETWORK_SETUP);
    const [networkInterface, setNetworkInterface] = useState(networkInterfaces.ETHERNET.key);
    const [hubId, setHubId] = useState();
    const hubIdRef = useRef();

    useEffect(() => {
        hubIdRef.current = hubId;
    })

    useEffect(() => {
        const getHubIdInterval = setInterval(() => {
            if (hubIdRef.current === undefined) {
                fetchHubId();
            }
        }, 2000);

        return () => clearInterval(getHubIdInterval);
    }, [])

    const fetchHubId = async () => {
        const res = await getHubIdFromLocalApp();
        setHubId(res);
    }

    return <>
        <ViewHeader headerText={'Hub Setup'} />

        {hubId ? <>
            {setupStep === setupSteps.NETWORK_SETUP ?
                <NetworkSetup
                    hubId={hubId}
                    setSetupStep={setSetupStep}
                    networkInterface={networkInterface}
                    setNetworkInterface={setNetworkInterface}
                />
                : <></>}

            {setupStep === setupSteps.CUSTOMER_SETUP ?
                <CustomerSetup
                    setSetupStep={setSetupStep}
                    networkInterface={networkInterface}
                />
                : <></>}
        </> :
            <SetupContainer>
                <SectionContainer>
                    <label>Fetching hub ID. Please wait.</label>
                    <LoadingSpinner />
                </SectionContainer>
            </SetupContainer>}
    </>
}

export default LocalSetupView;