import React from 'react';
import styled from 'styled-components';
import theme from '../../../UI/theme';
import Button from '../../UiComponents/Button';

const ToolbarContainer = styled.div`
    background-color: ${theme.colors.darkSpace80};
    display: flex;
    align-items: center;
    border-radius: 2px;
`


const ToolButton = styled.button`
    background-color: ${props => props.active ? theme.colors.darkSpace70 : 'transparent'};
    border: none;
    color: white;
    padding: 16px;
    outline: none;
    font-size: ${theme.fontSize.small};
    font-weight: 500;
    border-radius: 2px;

    &:hover{
        background-color: ${theme.colors.darkSpace70};
        cursor: pointer;
    }
`

const tools = [{
    name: 'FLOOR_GENERAL',
    displayName: 'General',
    actionType: 'STAGE_SELECT'
}, {
    name: 'FLOOR',
    displayName: 'Floor Layout',
    actionType: 'STAGE_SELECT'
}, {
    name: 'HUB',
    displayName: 'Hub',
    actionType: 'STAGE_SELECT'
}, {
    name: 'PROCESSOR',
    displayName: 'Processor',
    actionType: 'STAGE_SELECT'
}, {
    name: 'ZONE',
    displayName: 'Zone',
    actionType: 'STAGE_SELECT'
}, {
    name: 'SENSOR',
    displayName: 'Sensor',
    actionType: 'STAGE_SELECT'
}, {
    name: 'FURNITURE',
    displayName: 'Furniture',
    actionType: 'STAGE_SELECT'
}];

const actions = [{
    name: 'SAVE',
    displayName: 'Save',
    actionType: 'SAVE'
}];

const Toolbar = props => {

    

    return <ToolbarContainer>
        <div>
            {tools.map(tool => {
                return <ToolButton
                    key={tool.name}
                    onClick={() => props.setActiveTool(tool.name)}
                    active={tool.name == props.activeTool}>
                    {tool.displayName}
                </ToolButton>
            })}
        </div>
        
        <div>
            {props.edited ? <>
                <Button primary small
                    onClick={() => props.actionHandler('SAVE', 'SAVE')}>
                    Save
                </Button>
            </> : <></>}
        </div>
    </ToolbarContainer>
}

export default Toolbar;