import { useState } from "react";
import SearchField from "../../../../UiComponents/SearchField";
import Checkbox from "../../../../UiComponents/Checkbox";
import { ListItem } from "../styles";
import Button from "../../../../UiComponents/Button";
import { ButtonRowContainer } from "../../../../panel/ModalStyles";

const searchFilter = (venue, searchString) => venue.companyName?.toLowerCase().includes(searchString.toLowerCase()) || venue.customerId.toString().includes(searchString.toLowerCase());

const VenueSelector = ({ venues, preselectedVenues, selectedVenues, setSelectedVenues }) => {
    const [searchString, setSearchString] = useState('');

    const selectVenue = (venue) => {
        const selected = isSelected(venue);
        if (selected) {
            setSelectedVenues(current => current.filter(v => v.customerId !== venue.customerId))
        } else {
            setSelectedVenues(current => [...current, venue]);
        }
    }

    const isSelected = (venue) => {
        return selectedVenues.some(v => v.customerId === venue.customerId);
    }
    const isPreselected = (venue) => {
        return preselectedVenues.some(v => v.customerId === venue.customerId);
    }

    const allSelected = () => {
        return !filteredItems.some(v => !isSelected(v) && !isPreselected(v))
    }

    const selectAll = () => {
        if (allSelected()) {
            setSelectedVenues([]);
        } else {
            setSelectedVenues(filteredItems.filter(v => !isPreselected(v)));
        }
    }

    const filteredItems = venues.filter(item => searchFilter(item, searchString));

    return <>

        <label>Select venues</label>
        
        <div style={{ display: 'grid', gridTemplateColumns: '1fr auto', gridGap: '10px' }}>
            <SearchField placeholder={'Search'} value={searchString} onChange={(e) => setSearchString(e.target.value)} />
            <Button tertiary small onClick={selectAll}>{allSelected() ? 'Select none' : 'Select all'}</Button>
        </div>
        
        <div style={{ maxHeight: '400px', overflow: 'auto' }}>
            <ul style={{ listStyleType: 'none', paddingLeft: '0', margin: 0 }}>

                {filteredItems.map(venue => {
                    const preselected = isPreselected(venue);
                    return <ListItem
                        key={venue.customerId}
                        onClick={() => selectVenue(venue)}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <Checkbox
                            disabled={preselected}
                            checked={preselected || selectedVenues.some(v => v.customerId === venue.customerId)}
                        />
                        <div style={{ marginLeft: '4px' }}>
                            {venue.companyName}
                        </div>
                    </ListItem>
                })}
            </ul>
        </div>
    </>
}

export default VenueSelector;