import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/log'

const getZoneLogs = async (zoneId, fromDate, toDate, detailed = false) => {
    const res = await axios.get(`${hostname}${path}/zone?zoneId=${zoneId}&fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}&detailed=${detailed}`);
    return res.data;
}

async function getLiveZoneLog(customerId, zoneId) {
    const res = await axios.get(`${hostname}/v2/log/zoneLive?customerId=${customerId}&zoneId=${zoneId}`);
    return res.data;
}

const getDefinedLogInterval = async (customerId, objectId, intervalType, objectType) => {
    const res = await axios.get(`${hostname}/v2/log/definedInterval?customerId=${customerId}&objectId=${objectId}&intervalType=${intervalType}&objectType=${objectType}`);
    return res.data;
}

const getBatteryLogs = async (customerId, sensorId) => {
    const res = await axios.get(`${hostname}/v2/log/batteryLogs?customerId=${customerId}&sensorId=${sensorId}`);
    return res.data;
}

function getDateTime(date_string) {
    if (date_string != null) {
        var t = date_string.split(/[- : T Z]/);
        var d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
        //var d = new Date(Date.parse(date_string));
        return d;
    } else {
        //console.log(date_string);
        return;
    }
}

export {
    getZoneLogs,
    getLiveZoneLog,
    getDefinedLogInterval,
    getDateTime,
    getBatteryLogs
}