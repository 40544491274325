import { useContext } from "react";
import UserContext from "../../hooks/UserContext";
import { hasRole, hasVenuePermission } from "./permissions";
import styled from "styled-components";
import { TextMuted } from "../UiComponents/text/StyledText";

const TextContainer = styled.div`
    text-align: center;
`

const PermissionChecker = (props) => {
    const userContext = useContext(UserContext);
    if (hasVenuePermission(userContext, props.permissionType) && hasRole(userContext, props.role)) {
        return <>
            {props.children}
        </>
    } else {
        return <TextContainer>
            <TextMuted>You do not have access to this content</TextMuted>
        </TextContainer>
    }
}

export default PermissionChecker;