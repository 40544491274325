import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/source';

async function getSources(customerId) {
    const res = await axios.get(`${hostname}${path}/sources?customerId=${customerId}`);
    return res.data;
}

async function updateSources(sources) {
    const res = await axios.put(`${hostname}${path}/list`, sources);
    return res.data;
}

async function addSource(source) {
    const res = await axios.post(`${hostname}${path}`, source);
    return res.data;
}

async function deleteSource(sourceId) {
    const res = await axios.delete(`${hostname}${path}?sourceId=${sourceId}`);
    return res.data;
}


export {
    getSources,
    updateSources,
    addSource,
    deleteSource
}