import { useState } from "react";
import { loadingStates } from "../../../installation/installationTypes";
import { SettingsParagraph, SettingsSection } from "../../settingsStyles";
import InputField from "../../../UiComponents/InputField";
import { ButtonRowContainer } from "../../../panel/ModalStyles";
import Button from "../../../UiComponents/Button";
import { addSharedUser } from "../../../../scripts/sharedUser";

const emptySharedUser = {
    firstName: '',
    username: '',
    password: ''
}

const SharedAccountForm = props => {
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [sharedUser, setSharedUser] = useState(JSON.parse(JSON.stringify(emptySharedUser)));
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const [error, setError] = useState();

    const updateUserState = (propsName, value) => {
        setSharedUser(prev => {
            let userObject = { ...prev };
            userObject[propsName] = value;
            return userObject;
        });
    }

    const handleCreateSharedUser = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            let userToCreate = JSON.parse(JSON.stringify(sharedUser));
            userToCreate.defaultVenueId = props.venue?.customerId;

            await addSharedUser(userToCreate);
            await props.refreshWithUpdate();
            setLoadingState(loadingStates.SUCCESS);
        } catch (error) {
            setLoadingState(loadingStates.FAILED);
            setError("Error. Could not create shared account");
        } finally {
            if (props.onSubmit) {
                props.onSubmit();
            }
        }
    }

    const resetForm = () => {
        setSharedUser(JSON.parse(JSON.stringify(emptySharedUser)));
        setConfirmedPassword('');
        setError(null);
        setLoadingState(loadingStates.NONE);
    }

    const handleClose = () => {
        resetForm();

        if (props.onClose) {
            props.onClose();
        }
    }

    const handleReset = () => {
        resetForm();
        if (props.onReset) {
            props.onReset();
        }
    }

    return <>
        {loadingState === loadingStates.NONE ? <>
            <SettingsSection>
                <SettingsParagraph>Display name</SettingsParagraph>
                <InputField value={sharedUser?.firstName} onChange={(e) => updateUserState('firstName', e.target.value)} />
            </SettingsSection>
            <SettingsSection>
                <SettingsParagraph>Username</SettingsParagraph>
                <InputField value={sharedUser?.userName} onChange={(e) => updateUserState('username', e.target.value)} />
            </SettingsSection>
            <SettingsSection>
                <SettingsParagraph>Password</SettingsParagraph>
                <InputField value={sharedUser?.password} type="password" onChange={(e) => updateUserState('password', e.target.value)} />
            </SettingsSection>
            <SettingsSection>
                <SettingsParagraph>Confirm password</SettingsParagraph>
                <InputField value={confirmedPassword} type="password" onChange={(e) => setConfirmedPassword(e.target.value)} />
            </SettingsSection>

            <SettingsSection>
                <ButtonRowContainer>
                    <Button primary onClick={() => handleCreateSharedUser()}>Create account</Button>
                    <Button secondary onClick={() => handleClose()}>Cancel</Button>
                </ButtonRowContainer>
            </SettingsSection>

        </> : <></>}

        {loadingState === loadingStates.LOADING ? <>
            <SettingsSection>
                <SettingsParagraph>Creating shared account...</SettingsParagraph>
            </SettingsSection>
        </> : <></>}

        {loadingState === loadingStates.SUCCESS ? <>
            <SettingsSection>
                <SettingsParagraph>Shared account created successfully.</SettingsParagraph>
            </SettingsSection>
        </> : <></>}

        {loadingState === loadingStates.FAILED ? <>
            <SettingsSection>
                <SettingsParagraph>{error}</SettingsParagraph>
            </SettingsSection>
        </> : <></>}

        {loadingState === loadingStates.SUCCESS ? <>
            <SettingsSection>
                <ButtonRowContainer>
                    <Button primary onClick={() => handleReset()}>Create another shared account</Button>
                    <Button secondary onClick={() => handleClose()}>Close</Button>
                </ButtonRowContainer>
            </SettingsSection>
        </> : <></>}

        {loadingState === loadingStates.FAILED ? <>
            <SettingsSection>
                <ButtonRowContainer>
                    <Button primary onClick={() => handleReset()}>Try again</Button>
                    <Button secondary onClick={() => handleClose()}>Close</Button>
                </ButtonRowContainer>
            </SettingsSection>
        </> : <></>}

    </>
}

export default SharedAccountForm;