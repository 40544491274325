import React from 'react';
import ChoiceField from '../../../fields/ChoiceField';

const MeterChoiceField = ({ zone, updateZone, meters, cellControl }) => {
    const choices = meters.filter(meter => meter.processorId === zone.processorId);
    const selectedMeter = choices.find(meter => meter.meterId === zone.meterId);
    return <ChoiceField
        selectedItem={selectedMeter && { value: selectedMeter.meterId, label: selectedMeter.name }}
        items={choices.map(meter => ({ value: meter.meterId, label: meter.name }))}
        setValue={(value) => updateZone({ meterId: value })}
        cellControl={cellControl}
    />
}

export default MeterChoiceField;
