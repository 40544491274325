import { useContext } from "react"
import UserContext from "../../hooks/UserContext"
import theme from "../../UI/theme"
import styled from "styled-components"

const Header = styled.div`
    text-align: center;
    color: ${theme.colors.textGray};
`

const VenueName = props => {
    const userContext = useContext(UserContext);

    return <Header>
        {userContext.customer?.companyName}
    </Header>
}

export default VenueName;