import styled from "styled-components";
import PopupElement from "../../UiComponents/PopupElement";
import { TextMuted } from "../../UiComponents/text/StyledText";
import { IndicatorDot } from "../styling/DeviceStyles"

const Container = styled.div`
    position: relative;
    width: 100%;
`

const MainContentContainer = styled.div`
    display: flex;
    align-items: center;
`

const StatusField = ({ statusColor, displayStatus, error }) => {

    const mainContent = <MainContentContainer>
        
        <IndicatorDot small>
            <div style={{ backgroundColor: statusColor }} ></div>
        </IndicatorDot>
        <TextMuted>
            {displayStatus}
        </TextMuted>
    </MainContentContainer>
    const popupContent = error;

    return <Container>
        <PopupElement 
            mainContent={mainContent}
            popupContent={popupContent}
        />
        
    </Container>
}

export default StatusField;