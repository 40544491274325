import React, { useState, useEffect, useContext } from 'react';
import ViewHeader from '../../UiComponents/ViewHeader';
import Card from '../../UiComponents/Card';
import Button from '../../UiComponents/Button';
import { SettingsContainer, SettingsParagraph, SettingsSection } from '../settingsStyles';
import UserContext from '../../../hooks/UserContext';
import { getUser, sendPasswordResetLink, updateUser } from '../../../scripts/user';
import Modal from '../../UiComponents/Modal';
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from '../../panel/ModalStyles';
import InputField from '../../UiComponents/InputField';
import { useHistory, useRouteMatch } from 'react-router-dom/cjs/react-router-dom';

const UserSettings = props => {
    const loggedInUser = useContext(UserContext);
    const [user, setUser] = useState();
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const match = useRouteMatch();
    const history = useHistory();

    useEffect(() => {
        getUserHandler();
    }, [loggedInUser?.user?.userId]);

    const getUserHandler = async () => {
        var userRes = await getUser(loggedInUser?.user?.userId);
        if (userRes) {
            setUser(userRes);
            setFirstName(userRes?.firstName);
            setLastName(userRes?.lastName);
        }
    }

    const sendPasswordLink = async () => {
        try {
            await sendPasswordResetLink(loggedInUser?.user?.email);
            setShowPasswordModal(false);
        } catch (error) {

        }
    }

    const handleSaveChanges = async () => {
        let userCopy = JSON.parse(JSON.stringify(user));
        userCopy.firstName = firstName;
        userCopy.lastName = lastName;

        await updateUser(userCopy);
        getUserHandler();
        setIsEditing(false);
    }

    if (user) {

        return <div>
            <ViewHeader headerText={'User Settings'} backLink={'/settings'} />
            <Card>
                <SettingsContainer>

                    <SettingsSection>
                        <label>Username</label>
                        <SettingsParagraph>{user.username}</SettingsParagraph>
                    </SettingsSection>

                    <SettingsSection>
                        <label>{user?.shared === 1 ? 'Display name' : 'First name'}</label>
                        {isEditing ? <>
                            <InputField value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                        </> : <>
                            <SettingsParagraph>{user?.firstName}</SettingsParagraph>
                        </>}
                    </SettingsSection>

                    {user?.shared === 0 ? <>
                        <SettingsSection>
                            <label>Last name</label>
                            {isEditing ? <>
                                <InputField value={lastName} onChange={(e) => setLastName(e.target.value)} />
                            </> : <>
                                <SettingsParagraph>{user?.lastName}</SettingsParagraph>
                            </>}
                        </SettingsSection>

                        <SettingsSection>
                            <label>Email</label>
                            <SettingsParagraph>{user?.email}</SettingsParagraph>
                        </SettingsSection>
                    </> : <></>}

                    <SettingsSection>
                        <ButtonRowContainer>
                            {isEditing ? <>
                                <Button onClick={() => handleSaveChanges()} small primary>Save</Button>

                            </> : <>
                                <Button onClick={() => setIsEditing(prev => !prev)} small tertiary>Edit</Button>
                            </>}
                            
                            {user?.shared === 0 ? <>
                                <Button onClick={() => history.push(`${match.url}/email`)} small tertiary>Change email</Button>
                                <Button onClick={() => setShowPasswordModal(true)} small tertiary>Change password</Button>
                            </> : <></>}

                        </ButtonRowContainer>
                    </SettingsSection>

                </SettingsContainer>

            </Card>

            <Modal show={showPasswordModal} close={() => setShowPasswordModal(false)}>
                <ModalContainer>
                    <ModalHeader>Change password</ModalHeader>
                    <ModalText>A password reset link will be sent to {user?.email}. Click the link in the e-mail and follow the instructions.</ModalText>
                    <ModalText>Are you sure you want to send a password reset link?</ModalText>
                    <ButtonRowContainer>
                        <Button primary onClick={() => sendPasswordLink()}>Send</Button>
                        <Button secondary onClick={() => setShowPasswordModal(false)}>Cancel</Button>
                    </ButtonRowContainer>
                </ModalContainer>
            </Modal>
        </div>
    } else {
        return <></>
    }
}

export default UserSettings;