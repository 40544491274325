import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import theme from "../../../../../UI/theme";
import { DragIndicatorIcon } from "../../../../UiComponents/Icons/materialIcons";

const AdvancedCalibrationPointsSlider = props => {
    const [values, setValues] = useState({ min: props.minVal, mid: props.midVal, max: props.maxVal });
    const minValRef = useRef(null);
    const midValRef = useRef(null);
    const maxValRef = useRef(null);
    const rangeRef = useRef(null);

    useEffect(() => {
        setValues({ min: props.minVal, mid: props.midVal, max: props.maxVal });
    }, [props.minVal, props.midVal, props.maxVal])

    const onChangeMin = (e) => {
        const newMin = Math.min(+e.target.value, values.mid - 1);
        setValues(prev => {
            return { ...prev, min: newMin };
        })
        minValRef.current.value = newMin.toString();

        if (props.setHasChanged) {
            props.setHasChanged(true);
        }
    }
    const onChangeMid = (e) => {
        const newMid = Math.max(Math.min(+e.target.value, values.max - 1), values.min + 1);
        setValues(prev => {
            return { ...prev, mid: newMid };
        })
        midValRef.current.value = newMid.toString();

        if (props.setHasChanged) {
            props.setHasChanged(true);
        }
    }
    const onChangeMax = (e) => {
        const newMax = Math.max(+e.target.value, values.mid + 1);
        setValues(prev => {
            return { ...prev, max: newMax };
        })
        maxValRef.current.value = newMax.toString();

        if (props.setHasChanged) {
            props.setHasChanged(true);
        }
    }

    const getPercent = useCallback(
        (value) => ((value - props.min) / (props.max - props.min)) * 100, [props.min, props.max]
    );

    useEffect(() => {
        if (maxValRef.current) {
            const minPercent = getPercent(values.min);
            const maxPercent = getPercent(+maxValRef.current.value);

            if (rangeRef.current) {
                rangeRef.current.style.left = `${minPercent}%`;
                rangeRef.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [values.min, getPercent]);

    useEffect(() => {
        if (minValRef.current) {
            const minPercent = getPercent(+minValRef.current.value);
            const maxPercent = getPercent(values.max);

            if (rangeRef.current) {
                rangeRef.current.style.width = `${maxPercent - minPercent}%`;
            }
        }
    }, [values.max, getPercent]);

    useEffect(() => {
        if (props.onChange && props.hasChanged === true) {
            props.onChange({ min: values.min, mid: values.mid, max: values.max });
        }
    }, [values.min, values.mid, values.max]);

    return <div className={window.matchMedia(`(max-width: ${theme.screenSizes.medium - 1}px)`).matches ? 'smallScreenSliderVariables' : 'largeScreenSliderVariables'} >
        <input
            type="range"
            className="hiddenInput"
            min={props?.min}
            max={props?.max}
            step={1}
            value={values?.min}
            ref={minValRef}
            onChange={(e) => onChangeMin(e, true)}
        />
        <input
            type="range"
            className="hiddenInput"
            min={props?.min}
            max={props?.max}
            step={1}
            value={values.mid}
            ref={midValRef}
            onChange={(e) => onChangeMid(e)}
        />
        <input
            type="range"
            className="hiddenInput"
            min={props?.min}
            max={props?.max}
            step={1}
            value={values.max}
            ref={maxValRef}
            onChange={(e) => onChangeMax(e, false)}
        />

        <div className="slider">
            <div className="track" />
            <div ref={rangeRef} className="range" />
            <div className="handle" style={{ left: `calc(${values.min}% - ${values.min / 100}*var(--thumbWidth))` }}>
                <DragIndicatorIcon />
            </div>
            <div className="handle" style={{ left: `calc(${values.mid}% - ${values.mid / 100}*var(--thumbWidth))` }}>
                <DragIndicatorIcon />
            </div>
            <div className="handle" style={{ left: `calc(${values.max}% - ${values.max / 100}*var(--thumbWidth))` }}>
                <DragIndicatorIcon />
            </div>
        </div>
    </div>
}

export default AdvancedCalibrationPointsSlider;