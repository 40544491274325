import { useEffect } from "react";
import { useState } from "react";
import { FaWifi } from "react-icons/fa";
import styled, { css } from "styled-components";
import { isLocalApp } from "../../../../../scripts/server/server";
import serverConnection from "../../../../../scripts/server/ServerConnection";
import theme from "../../../../../UI/theme";
import { loadingStates } from "../../../../installation/installationTypes";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../../panel/ModalStyles";
import { SetupLabel } from "../../../../setup/setupStyles";
import Button from "../../../../UiComponents/Button";
import InputField from "../../../../UiComponents/InputField";
import LoadingSpinner from "../../../../UiComponents/LoadingSpinner";
import Modal from "../../../../UiComponents/Modal";
import { TextMuted } from "../../../../UiComponents/text/StyledText";
import DisconnectWifiButton from "./DisconnectWifiButton";

const Container = styled.div`
    padding: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    cursor: pointer;
    border-radius: 2px;
    box-sizing: border-box;
    background-color: ${theme.colors.darkSpace90};

    &> label {
        cursor: inherit;
    }
    
    &:hover {
        background-color: ${theme.colors.darkSpace80};
    }

    ${props => props.selected && css`
        background-color: ${theme.colors.darkSpace80};
    `}
`

const NetworkNameLabel = styled(SetupLabel)`
    ${props => props.connected && css`
        color: ${theme.colors.greenReadable};
    `}
`

const WiFiNetworkItem = ({ hub, network, selected, onClick, connected, manualEntry, ...props }) => {
    const [state, setState] = useState(loadingStates.NONE);
    const [password, setPassword] = useState('');
    const [manualSsid, setManualSsid] = useState('');
    const [showConnectModal, setShowConnectModal] = useState(false);
    const hostname = window.location.hostname;

    useEffect(() => {
        if (connected === false && state === loadingStates.SUCCESS) {
            setState(loadingStates.NONE);
        }
    }, [connected])

    const connect = async () => {
        try {
            if (props.onConnecting) {
                props.onConnecting();
            }

            const ssid = manualEntry ? manualSsid : network.ssid;
            setState(loadingStates.LOADING);
            const res = await serverConnection.hubEventHandler.sendEvent(
                hub?.hubId ?? props.hubId,
                hub?.customerId,
                'CONNECT_TO_WIFI_NETWORK',
                { ssid, password },
                20000,
                true
            );
            if (res === true) {
                setState(loadingStates.SUCCESS);
                if (props.onConnect) {
                    await props.onConnect(ssid);
                }
            } else {
                setState(loadingStates.FAILED);
            }

        } catch (err) {
            setState(loadingStates.FAILED);
        }
    }

    const handleConnect = () => {
        if (!props.networkConfig?.wlan0) {
            connect();
        } else {
            setShowConnectModal(true);
        }
    }

    const onDisconnect = async () => {
        setState(loadingStates.NONE);
        if (props.onDisconnect) {
            await props.onDisconnect();
        }
    }

    return <Container selected={selected} connected={connected} onClick={onClick}>
        {manualEntry ? <>
            <label>Ssid</label>
            <InputField
                small
                value={manualSsid}
                onChange={(e) => setManualSsid(e.target.value)}
            />
        </> : <>
            <NetworkNameLabel connected={connected}><FaWifi />{network.ssid}</NetworkNameLabel>
            <TextMuted>Quality: {parseInt(network.quality * 100)}%{connected === true ? ', Connected' : ''}</TextMuted>
        </>}

        {selected && !connected ? <>
            <label>Password</label>
            <InputField
                small
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            {state === loadingStates.FAILED ? <>
                <TextMuted>Connection failed</TextMuted>
            </> : <></>}

            {state !== loadingStates.LOADING && !connected ? <>
                <div>
                    <Button primary small onClick={handleConnect}>{state === loadingStates.FAILED ? 'Retry' : 'Connect'}</Button>
                </div>
            </> : <></>}

            {state === loadingStates.LOADING ? <>
                <TextMuted>Connecting...</TextMuted>
                <div>
                    <LoadingSpinner />
                </div>
            </> : <></>}

        </> : <></>}

        {connected && selected ? <>
            <div>
                <DisconnectWifiButton
                    networkConfig={props.networkConfig}
                    connectedToEthernet={props.connectedToEthernet}
                    hubId={props.hubId}
                    onDisconnect={onDisconnect}
                    hub={hub} />
            </div>
        </> : <></>}

        <Modal show={showConnectModal} close={() => setShowConnectModal(false)}>
            <ModalContainer>
                {(isLocalApp() && props.networkConfig?.eth0?.address === hostname) || props.connectedToEthernet === true ? <>
                    <ModalHeader>Danger connecting to Wi-Fi!</ModalHeader>
                    <ModalText>Are you sure you want to connect to another Wi-Fi? This can make the hub unreachable.</ModalText>
                    <ButtonRowContainer>
                        <Button small primary onClick={() => { connect(); setShowConnectModal(false); }}>Connect</Button>
                        <Button small secondary margin onClick={() => setShowConnectModal(false)}>Cancel</Button>
                    </ButtonRowContainer>
                </> : <>
                    <ModalHeader>Connecting to Wi-Fi not allowed</ModalHeader>
                    <ModalText>It does not look like the hub is connected to cabled internet.</ModalText>
                </>}
            </ModalContainer>
        </Modal>
    </Container >
}

export default WiFiNetworkItem;