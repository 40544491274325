import { Chart } from "chart.js";
import { useEffect, useRef, useState } from "react";
import theme from "../../../../../UI/theme";

const chartData = {
    labels: [],
    datasets: [
        {
            label: 'Filtered db',
            type: 'line',
            borderColor: `${theme.colors.raspberryRed}`,
            backgroundColor: `${theme.colors.raspberryRed}`,
            borderWidth: 2,
            pointRadius: 0,
            tension: 0.2,
            fill: false,
            order: 1,
            data: [],
        }, {
            label: 'Total db',
            type: 'line',
            borderColor: `${theme.colors.blueMonday}`,
            backgroundColor: `${theme.colors.blueMonday}`,
            borderWidth: 2,
            pointRadius: 0,
            tension: 0.2,
            fill: false,
            order: 4,
            data: [],
        }, {
            label: 'Output Meter db',
            type: 'line',
            borderColor: `${theme.colors.greenMint}`,
            backgroundColor: `${theme.colors.greenMint}`,
            borderWidth: 2,
            pointRadius: 0,
            tension: 0.2,
            fill: false,
            order: 10,
            data: [],
        }, {
            label: 'Gain db',
            type: 'line',
            borderColor: `${theme.colors.purplePleasure}`,
            backgroundColor: `${theme.colors.purplePleasure}`,
            borderWidth: 2,
            pointRadius: 0,
            tension: 0.2,
            fill: false,
            order: 10,
            data: [],
        }]
}

const commonOptions = {
    maintainAspectRatio: false,
    animation: {
        duration: 0,
    }
}

const options = {
    ...commonOptions,
    layout: {
        padding: 0
    },
    scales: {
        x: {
            display: false,
            type: 'time',
        },
        y: {
            display: false,
            type: 'linear',
            suggestedMax: 50,
            suggestedMin: 30,
        }
    },
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            enabled: false
        }
    },
    pointHitRadius: 7,
}

const detailedOptions = {
    ...commonOptions,
    scales: {
        x: {
            display: true,
            type: 'time',
            grid: {
                display: true,
                drawOnChartArea: false,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70,
                drawTicks: true,
                tickColor: theme.colors.darkSpace70
            },
            ticks: {
                color: theme.colors.textGray
            }
        },
        y: {
            display: true,
            type: 'linear',
            min: 0,
            suggestedMax: 100,
            grid: {
                display: true,
                drawOnChartArea: false,
                drawBorder: true,
                borderColor: theme.colors.darkSpace70,
                drawTicks: true,
                tickColor: theme.colors.darkSpace70
            },
            ticks: {
                color: theme.colors.textGray
            }
        }
    },
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                color: theme.colors.textGray,
                boxWidth: 7,
                boxHeight: 7,
                useBorderRadius: true,
                borderRadius: 1,
            }
        },
        tooltip: {
            enabled: true
        }
    },
    pointHitRadius: 7,
}

const LiveChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    let chartConfig;
    chartConfig = JSON.parse(JSON.stringify({ data: chartData, options: { ...detailedOptions } }));

    const chartConfigRef = useRef(JSON.parse(JSON.stringify(chartConfig)));

    useEffect(() => {
        chartConfigRef.current.data.datasets[0].data = props.data?.map(value => ({ x: value.timestamp, y: value.filteredDb }));
        chartConfigRef.current.data.datasets[1].data = props.data?.map(value => ({ x: value.timestamp, y: value.totDb }));
        chartConfigRef.current.data.datasets[2].data = props.data?.map(value => ({ x: value.timestamp, y: value.meterDb }));
        chartConfigRef.current.data.datasets[3].data = props.data?.map(value => ({ x: value.timestamp, y: value.gainDb }));

        if (chartInstance) {
            chartInstance.update();
        }
    }, [props.data])

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfigRef.current);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <canvas ref={chartContainer} />
    );
}

export default LiveChart;