import { useContext } from "react";
import UserContext from "../../../hooks/UserContext";
import { postUserInteraction } from "../../../scripts/userInteraction";
import { updateZoneLiveSettingsList } from "../../../scripts/zone";
import { userInteractionTypes } from "../../admin/customerDetails/userInteractionTypes";
import WaVolumeOff from "../../UiComponents/Icons/WaVolumeOff";
import WaVolumeUp from "../../UiComponents/Icons/WaVolumeUp";
import PanelButton from "../PanelButton"
import { hasVenuePermission, permissionTypes } from "../../permission/permissions";

const GroupMuteButton = props => {
    const userContext = useContext(UserContext);

    const toggleGroupMute = async (newState) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }

        const updatedZones = props.zones.map(zone => ({zoneId: zone.zoneId, mute:+newState}));
        await updateZoneLiveSettingsList(updatedZones);

        postUserInteraction(props.customerId, {
            setting: userInteractionTypes.globalMute.key,
            fromValue: +!newState,
            toValue: +newState
        });
    }

    return <PanelButton
        onClick={() => toggleGroupMute(!props.groupMuted)}
        deactivated={props.groupMuted}
        disabled={props.disabled}
    >
        {props.groupMuted ?
            <WaVolumeOff /> :
            <WaVolumeUp />}
        <label>{props.groupMuted ? 'Muted' : 'On'}</label>
    </PanelButton>
}

export default GroupMuteButton;