import { DeviceError } from "../../styling/DeviceStyles"

const ProcessorError = ({ error }) => {

    return <DeviceError>
        <label>Error</label>
        <div>
            {error}
        </div>
    </DeviceError>
}

export default ProcessorError;