import { useState } from "react"
import Button from "../../../../UiComponents/Button"
import Modal from "../../../../UiComponents/Modal";
import ProcessorSetup from "../ProcessorSetup";

const ProcessorScanButton = props => {
    const [showModal, setShowModal] = useState(false);

    const submitHandler = async (object, submitProps) => {
        await props.onSubmit(object, submitProps);
        setShowModal(false);
    }

    return <>
        <Button
            small
            secondary
            onClick={() => setShowModal(true)}>
            Processor scan
        </Button>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ProcessorSetup 
                {...props}
                onSubmit={submitHandler}
                cancel={() => setShowModal(false)}
            />
        </Modal>
    </>
}

export default ProcessorScanButton;