import React, { useState } from 'react';
import InputField from '../../UiComponents/InputField';
import Button from '../../UiComponents/Button';
import { ButtonRowContainer, ModalText } from '../../panel/ModalStyles';

const CustomEvent = props => {
    const [event, setEvent] = useState({ command: '', payload: '' });
    const [error, setError] = useState();

    const changeHandler = (newValue, key) => {
        setEvent(current => {
            var updated = { ...current };
            updated[key] = newValue;
            return updated;
        });
    }

    const sumbitHandler = () => {
        // parse payload is json
        try {
            var payload = JSON.parse(event.payload);
            if (typeof payload == 'object') {
                props.handleEvent(event.command, payload);
            } else {
                setError('Could not parse as payload as json');
            }
        } catch (e) {
            setError('Could not parse as payload as json');
        }

    }

    return <>

        <div>
            <ModalText>Event Name</ModalText>
            <div>
                <InputField value={event.command} onChange={(e) => changeHandler(e.target.value, 'command')} />
            </div>
        </div>

        <div>
            <ModalText>Payload</ModalText>
            <div>
                <textarea value={event.payload} onChange={(e) => changeHandler(e.target.value, 'payload')} />
            </div>
        </div>

        <ModalText>Number of devices to update: {props.nDevices}</ModalText>

        {error}

        <ButtonRowContainer>
            <Button onClick={sumbitHandler} primary>Confirm</Button>
            <Button onClick={() => props.cancel()} secondary>Cancel</Button>
        </ButtonRowContainer>
    </>
}

export default CustomEvent;