import React from "react";
import { TroubleshootingContainer } from "./StylesTroubleshooting";

const TroubleshootServer = (props) => {

    return (
        <TroubleshootingContainer>
            <h4>Whoops!</h4>
            <br/>
            <p>There are issues with our server but we are fixing it ASAP.</p>
            <p>We appologise for any inconvenience.</p>
        </TroubleshootingContainer>
    )
} 

export default TroubleshootServer;