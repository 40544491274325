import styled, { css } from "styled-components";
import theme from "../../../UI/theme";
import { Indicator } from "../support/SupportStyles";

const StyledIndicator = styled(Indicator)`
    box-sizing: border-box;
    ${props => props.clickable && css`
        box-shadow: 2px 2px 2px ${theme.colors.darkSpace};
        cursor: pointer;
    `}
`
const UITableIndicator = props => {
    const clickable = typeof props.clickHandler === 'function';

    const clickHandler = () => {
        if (clickable) {
            props.clickHandler();
        }
    }
    
    return <StyledIndicator
        onClick={clickHandler}
        color={props.color}
        clickable={clickable} />
}

export default UITableIndicator;