import { useRef, useState } from "react";
import { installationTabs } from "../installation/installationTypes";
import Button from "../UiComponents/Button";
import Modal from "../UiComponents/Modal";
import TabSelector from "../UiComponents/TabSelector";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../panel/ModalStyles";
import  {singleSpeakerSystems} from "../installation/modules/speaker/speakerUtils"

const InstallationTabSelector = props => {
    const [showModal, setShowModal] = useState(false);
    const nextTab = useRef();

    const onClick = tab => {
        if (props.editableTableRef.current?.isEdited()) {
            nextTab.current = tab;
            setShowModal(true);
        } else {
            props.onClick(tab);
        }
    }

    const save = async () => {
        try {
            if (props.editableTableRef.current?.saveHandler) {
                await props.editableTableRef.current?.saveHandler();
            }
            goToNextTab();
        } catch (err) {
            console.log('Failed to save', err);
        } finally {
            nextTab.current = null;
        }
    }

    const goToNextTab = () => {
        setShowModal(false);
        if (nextTab.current) {
            props.onClick(nextTab.current);
        }
    }

    const conditionalTabs = [{
        key: 'SOURCE_MATRIX',
        show: props.sourceMatrixes.length > 0
    }, {
        key: 'METER',
        show: props.meters.length > 0
    }, {
        key: 'SPEAKER',
        show: props.processors.filter(p => singleSpeakerSystems.includes(p.type)).length > 0 
    }]
    const displayedTabs = installationTabs.filter(tab => {
        if (props.selectedTab.key === tab.key) {
            return true;
        } else {
            const condTab = conditionalTabs.find(condTab => condTab.key === tab.key);
            if (condTab) {
                return condTab.show;
            } else {
                return true;
            }
        }
    });

    return <>
        <TabSelector tabs={displayedTabs} selectedTab={props.selectedTab} onClick={onClick} />
        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Continue without saving?</ModalHeader>
                <ModalText>You have unsaved changes on the current tab. Do you want to save before continuing?</ModalText>
                <ButtonRowContainer>
                    <Button primary onClick={save}>Save</Button>
                    <Button secondary onClick={goToNextTab}>Cancel</Button>
                </ButtonRowContainer>
            </ModalContainer>
        </Modal>
    </>
}

export default InstallationTabSelector;