import ChoiceField from '../../../fields/ChoiceField';
import { requiredStates } from '../../../utils/installationTypes';
import { powerSourceChoices, powerSourceKeys } from '../sensorTypes';

const PowerSourceChoiceField = props => {
    const selectedItem = powerSourceChoices.find(choice => choice.value === props.powerSource);
    return <ChoiceField
        selectedItem={selectedItem}
        items={powerSourceChoices}
        setValue={(value) => props.setValue(value)}
        cellControl={props.cellControl}
    />
}

export const getPowerSourceFieldRequiredState = (sensor) => {
    return Object.values(powerSourceKeys).includes(sensor.powerSource) ? requiredStates.OPTIONAL : requiredStates.RECOMMENDED;
}

export default PowerSourceChoiceField;