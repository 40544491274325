import styled, {css} from 'styled-components';
import theme from '../../UI/theme';

const SplashScreen = styled.div`
    height: 100vh;
    background-color: ${theme.colors.darkSpace};
    position: relative;
    justify-content: center;
    display: flex;
    align-items: center;
    ${props => props.light && css`
        background-color: ${theme.colors.whiteSpace};
    `}
`
export default SplashScreen;
