import { getUuid } from "../common";
import { postEvent } from "../event";
import { isLocalApp } from "../server/server";

class HubEventHandler {
    constructor() {
        this.list = [];
    }

    sendEvent(hubId, customerId, command, payload, timeout, createNew = false) {
        return new Promise(async (resolve, reject) => {
            const event = {
                ...payload,
                eventId: getUuid(),
                source: isLocalApp() ? 'LOCAL' : 'SERVER'
            };

            event.resolve = resolve;
            event.reject = reject;
            this.list.push(event);
            await postEvent(hubId, customerId, command, event, createNew);
            event.timeout = setTimeout(() => {
                event.reject('timeout');
                this.removeFromList(event.eventId);
            }, timeout || 5000);
        });
    }

    startEventStream(hubId, customerId, command, payload, callback) {
        const event = {
            ...payload,
            eventId: getUuid(),
            source: isLocalApp() ? 'LOCAL' : 'SERVER',
            type: 'STREAM'
        };
        this.list.push({...event, callback});
        postEvent(hubId, customerId, command, event);
    }

    handleResponse(response) {
        const event = this.list.find(event => event.eventId === response.eventId);
        if (event?.type === 'STREAM') {
            this.handleStreamResponse(event, response);
        } else if (event) {
            clearTimeout(event.timeout);
            event.resolve(response.data);
            this.removeFromList(event.eventId);
        }
    }

    handleStreamResponse(event, response) {
        event.callback(response.data);
        if (response.status === 'STREAM_END') {
            this.removeFromList(event.eventId);
        }
    };

    removeFromList(eventId) {
        this.list = this.list.filter(event => event.eventId !== eventId);
    }
}

export default HubEventHandler;