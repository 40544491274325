import React from 'react';
import Checkbox from '../../UiComponents/Checkbox';
import errorPriorities from './errorPriorities';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
`


const PriorityFilter = props => {

    const onChange = (priority) => {
        props.setPriorityFilter(prev => {
            var newFilter = [];
            let priorityIndex = prev.indexOf(priority);
            if (priorityIndex > -1) {
                newFilter = Object.values(errorPriorities).filter(pri => pri.value < priority).map(pri => pri.value);
            } else {
                newFilter = Object.values(errorPriorities).filter(pri => pri.value <= priority).map(pri => pri.value);
            }
            return newFilter;
        })
    }

    return <Container>
        {Object.values(errorPriorities).map((priority) => {
            return <span key={priority.value}>
                <Checkbox checkedBackgroundColor={priority.color} hideIcon={true}
                    checked={props.priorityFilter.includes(priority.value)}
                    onChange={() => onChange(priority.value)} />
                {/* <label>{priority.value}</label> */}
            </span>
        })}
    </Container>
}

export default PriorityFilter;
