const playFromTypes = {
    PLAYLIST: {
        key: 'PLAYLIST',
        display: "Playlist"
    },
    SCHEDULE: {
        key: 'SCHEDULE',
        display: 'Schedule'
    },
    UNKNOWN: {
        key: 'UNKNOWN',
        display: 'Unknown'
    },
    NULL: {
        key:'NULL',
        display: 'N/A'
    }
}

export default playFromTypes;