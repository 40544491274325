import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../UiComponents/Button';
import Card from '../UiComponents/Card';
import { TextMuted } from '../UiComponents/text/StyledText';
import styled from 'styled-components';

const Container = styled.div`
    text-align: center;
`

const TextContainer = styled.div`
    margin-bottom: 20px;
`

const TitleContiner = styled.h2`
    margin: 12px 0;
`


const GoToSettingsLink = props => {

    return <Card>
        <Container>
            <TextContainer>
                <TitleContiner>Your hub has been setup</TitleContiner>
                <TextMuted>Go to installation view to continue</TextMuted>
            </TextContainer>
            <div>
                <Link to={`/installation`}>
                    <Button primary>
                        Go to installation view
                </Button>
                </Link>
            </div>
        </Container>
    </Card>
}

export default GoToSettingsLink;