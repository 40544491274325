import { useEffect, useState } from "react"
import { getSchedules } from "../../../../scripts/schedules";
import { getZones } from "../../../../scripts/zone";
import ViewHeader from '../../../UiComponents/ViewHeader';
import ScheduleItem from "./ScheduleItem";
import ScheduleMenu from "./ScheduleMenu";

const ScheduleView = props => {
    const [zones, setZones] = useState();
    const [schedules, setSchedules] = useState();
    const [filter, setFilter] = useState();
    const [filteredSchedules, setFilteredSchedules] = useState(props.schedules);

    useEffect(() => {
        getDataHandler();
        if (filter) {
            onSetFilter(filter);
        }
    }, [props.customerId]);

    const onSetFilter = newFilter => {
        setFilter(newFilter);
        filterSchedules(newFilter);
    }
    
    const getDataHandler = () => {
        getZonesHandler();
        getSchedulesHandler();
    }

    const getZonesHandler = async () => {
        var venueZones = await getZones(props.customerId);
        if (venueZones) {
            setZones(venueZones);
        }
    }

    const getSchedulesHandler = async () => {
        var schedulesRes = await getSchedules(props.customerId);
        if (schedulesRes) {
            setSchedules(schedulesRes);
            setFilteredSchedules(schedulesRes);
        }
    }

    const filterSchedules = (filterObject) => {
        var newFilteredSchedules = schedules;
        if (filterObject) {
            if (filterObject.zones?.length) {
                newFilteredSchedules = newFilteredSchedules.filter(schedule => {
                    var result = false;
                    filterObject.zones.forEach(zone => {
                        if (schedule.zones.includes(zone)) {
                            result = true;
                        }
                    })
                    return result;
                })
            }
            if (filterObject.days?.length) {
                newFilteredSchedules = newFilteredSchedules.filter(schedule => {
                    var result = false;
                    filterObject.days.forEach(day => {
                        if (schedule.days.includes(day)) {
                            result = true;
                        }
                    })
                    return result;
                })
            }
        }
        setFilteredSchedules(newFilteredSchedules);
    }

    return <>
        <ViewHeader headerText={'Schedules'} backLink={'/settings'} showVenueName={true} />

        <ScheduleMenu zones={zones} customerId={props.customerId} refreshSchedules={getSchedulesHandler} filter={filter} setFilter={onSetFilter} />

        <div>
            {filteredSchedules?.map((schedule, index) => {
                return <ScheduleItem
                    key={index}
                    schedule={schedule}
                    zones={zones}
                    refreshSchedules={getSchedulesHandler}
                    customerId={props.customerId} />
            })}
        </div>
    </>
}

export default ScheduleView;