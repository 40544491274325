import { FaCamera } from "react-icons/fa";
import IconButton from "../../../UiComponents/buttons/IconButton";
import Modal from "../../../UiComponents/Modal";
import InstallationItemInfo from "../../modules/installationDocumentation/info/InstallationItemInfo";
import { useState } from "react";
import { DocumentationIcon } from "../../styling/Icons";
import styled from "styled-components";
import theme from "../../../../UI/theme";

const StyledIconButton = styled(IconButton)`
    @media only screen and (max-width: ${theme.screenSizes.small - 1}px) {
        margin: 0px 2px;
    }
`

const InstallationInfoButton = ({ customerId, deviceId, deviceType, name }) => {
    const [showModal, setShowModal] = useState(false);

    return <>
        <div>
            <StyledIconButton onClick={() => setShowModal(true)}>
                <DocumentationIcon />
            </StyledIconButton>
        </div>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <InstallationItemInfo customerId={customerId} selectedItem={{id: deviceId, type: deviceType, name }} />
        </Modal>
    </>

}

export default InstallationInfoButton;


