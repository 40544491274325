import React from 'react';
import styled from 'styled-components';
import theme from '../UI/theme';
import Button from '../components/UiComponents/Button';
import { MdPlayArrow } from "react-icons/md";
import PanelButton from '../components/panel/PanelButton';
import WaVolumeUp from '../components/UiComponents/Icons/WaVolumeUp'
import WaSpeakers from '../components/UiComponents/Icons/WaSpeakers';
import WaInput from '../components/UiComponents/Icons/WaInput';
import Footer from '../components/navigation/Footer';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 100px;

    h1, h2 {
        color: ${theme.colors.greenEnergy};
        margin-left: 20px;
    }

    h3, h4 {
        margin-left: 20px;
    }

    ol {
        padding: 20px; 
        margin: 0px; 
    }
    
    a {
        
        color: ${theme.colors.greenEnergy};
    }

    p {
        margin: 0px 20px;
    }

    @media (max-width: ${theme.screenSizes.medium}px) {
        padding: 0px 50px;
        
    }
    
    @media (max-width: ${theme.screenSizes.small}px) {
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px 10px;
    }
`

const SolutionContainer = styled.div`

`

const Solution = styled.div`
    display: grid; 
    grid-template-columns: 60px repeat(7, 1fr);
    grid-template-rows: repeat(4, 20px);
    padding: 10px 20px;
    min-width: 340px;
    

    div {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 4;
        min-width: 10px;
        min-height: 10px;

        display flex;
        justify-content: center;
        align-items: center;
    }
    
    h4 {
        grid-column-start: 2;
        grid-column-end: 6;
        align-self: center;
        margin: 0px;
        margin-left: 20px;
    }

    p {
        grid-column-start: 2;
        grid-column-end: 9;
        grid-row-start: 2;
        grid-row-end: 5;
        list-style-type: none;
        padding: 0px;
        margin: 0px;
        margin-left: 20px; 
        color: ${theme.colors.textGray};
    }

    @media (max-width: ${theme.screenSizes.medium}px) {
        max-width: 600px;
    }

`
const OptionContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
`

const HelpView = ({ address }) => {
    
    const goToSupportCenter = () => {
        window.open('https://help.waved.co/en/collections/9432890-waved-support', '_blank');
    }

    const goToLearnMore = () => {
        window.open('https://help.waved.co/en/collections/9432889-waved-academy', '_blank');
    }

    return (
        <Container>
            <h1 style={{marginBottom: '5px'}}>Hei, how can we help?</h1>
            <h4>No music? First try these steps:</h4>
            <div>
            <Solution>
                <h4>Play/Pause</h4>
                <div><PanelButton><MdPlayArrow/><label>Play</label></PanelButton></div>
                <p>Has the music been paused?<br/>Double check that music is in fact playing.</p>
            </Solution>
            <Solution>
                <h4>Mute/Unmute</h4>
                <div><PanelButton><WaVolumeUp /><label>On</label></PanelButton></div>
                <p>Could any of your zones be muted?<br/>Check if all zones are unmuted and ready to play!</p>
            </Solution>
            <Solution>
                <h4>Source</h4>
                <div><PanelButton><WaInput/><label>Source</label></PanelButton></div>
                <p>Are all zones playing the correct source?<br/>Some sources may not be playing music.</p>
            </Solution>
            <Solution>
                <h4>Music Volume</h4>
                <div><PanelButton><WaSpeakers/><label>Music</label></PanelButton></div>
                <p>Did the volume change outside of Waved?<br/>Check your music players, sound system or other apps!</p>
            </Solution>
            </div>
            
            <OptionContainer>
                <div>
                    <h3 style={{fontWeight: 'normal', marginTop: '40px', marginBottom: '0px'}}>
                        Need more help?
                    </h3>
                <Button primary style={{margin: '20px'}} onClick={goToSupportCenter}>Waved Support</Button>
                </div>
                <div>
                    <h3 style={{minWidth: '80px', fontWeight: 'normal', marginTop: '40px', marginBottom: '0px'}}>
                        Let's learn more?
                    </h3>
                    <Button primary style={{margin: '20px'}} onClick={goToLearnMore}>Waved Academy</Button>
                </div>
            </OptionContainer>
            {/*<Footer address={address} />*/}
        </Container>
    );
}

export default HelpView;