import React, { useState, useEffect } from "react";
import styled from "styled-components";
import theme from "../../UI/theme";

const StyledNsmCounter = styled.h1`
    color: ${theme.colors.greenEnergy};
    font-size: 3.5rem;
    line-height: 1.2;
    margin-top: 0px;
    margin-bottom: 0px;
`

const NsmCounter = (props) => {

    const [displayWeekly, setDisplayWeekly] = useState('m i s s i n g   d a t a');

///**
    useEffect(() => {
        if (props.weekly) {
            setDisplayWeekly('');
            var cpy = props.weekly.toString();;
            var asm = '0 0 ';
            for (let i = 0; i < cpy.length; i++) {
                asm += ' '+cpy[i];
            }
            setDisplayWeekly(asm);
        }
    }, [props.weekly]);
 //*/

    return (
        <StyledNsmCounter>
            {displayWeekly} 
        </StyledNsmCounter>
    );
}

export default NsmCounter;