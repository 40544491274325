import axios from 'axios';
import { getHostname } from './server/server';

const hostname = getHostname();
const path = '/v2/customerHealthMetric';

async function getCustomerHealthMetrics(datetimeString) {
    const res = await axios.get(`${hostname}${path}/customerHealthMetrics?datetime=${datetimeString ?? null}`);
    return res.data;
}

export {
    getCustomerHealthMetrics
}