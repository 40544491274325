import { addMeter } from "../../../../scripts/meter";

const addNewMeter = async (props, processors, customerId) => {
    try {
        const newMeter = {
            ...props,
            customerId
        }
        await addMeter(newMeter);
    } catch (err) {
        console.log('Failed to add new meter', err);
    }
}

export {
    addNewMeter
}
