import styled, { css } from "styled-components";
import theme from "../../../UI/theme";

export const SkeletonItem = styled.div`
    @keyframes skeletonShimmer{
        0%{
            background-position: -100% 0
        }
        100%{
            background-position: 100% 0
        }
    }

    width: 100%;
    height: 100%;
    background: linear-gradient(to right, ${theme.colors.darkSpace90} 10%, ${theme.colors.darkSpace70} 18%, ${theme.colors.darkSpace90} 33%);
    background-size: 200%;
    animation: skeletonShimmer 2s infinite reverse;
`

export const BarChartSkeletonContainer = styled.div`
    @keyframes height {
        0%{
            height: 100%;
        }
        50%{
            height: 20%;
        }
        100%{
            height: 100%;
        }
    }

    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    ${props => props.numberOfBars && css`
        grid-template-columns: repeat(${props.numberOfBars}, 1fr);
    `}

    grid-gap: 10px;
    border-radius: 2px;
    align-items: end;
    border: 1px solid ${theme.colors.darkSpace80};
    border-width: 0 0 2px 2px;
    box-sizing: border-box;
    padding: 5px;
`

export const BarChartBar = styled.div`
    height: 100%;
    width: fill-available;
    animation: height 2s infinite;
    animation-delay: ${props => `${props.index / 10}s`};
    background-color: ${theme.colors.darkSpace80};
`