export const sortZones = (zones) => {
    const zonesCopy = JSON.parse(JSON.stringify(zones));

    return zones?.sort((a, b) => {
        let minA = a.orderIndex;
        let zonesA = zonesCopy?.filter(zone => zone.sourceSelectorId && zone.sourceSelectorId === a.sourceSelectorId);
        if (zonesA.length > 0) {
            minA = Math.min(...zonesA?.map(zone => zone.orderIndex));
        }

        let minB = b.orderIndex;
        let zonesB = zonesCopy?.filter(zone => zone.sourceSelectorId && zone.sourceSelectorId === b.sourceSelectorId);
        if (zonesB.length > 0) {
            minB = Math.min(...zonesB?.map(zone => zone.orderIndex));
        }

        if (minA < minB) {
            return -1;
        } else if (minA > minB) {
            return 1;
        } else {
            if (a.orderIndex < b.orderIndex) {
                return -1;
            } else if (a.orderIndex > b.orderIndex) {
                return 1;
            } else {
                return 0
            }
        }
    })
}