import { useState } from "react";
import styled from "styled-components";
import { ClickableTableRow, TableData, TableRow } from "../support/SupportStyles";
import HealthMetricLabel from "./HealthMetricLabel";
import ZoneDetails from "./ZoneDetails";

const ZoneInfoTD = styled.td.attrs({ colSpan: 8 })`
`

const ZoneHealthMetric = props => {
    const [expanded, setExpanded] = useState();

    return <>
        <ClickableTableRow onClick={() => setExpanded(prev => !prev)}>
            <TableData>{props.customerId ?? props.zoneMetrics?.[0]?.customerId}</TableData>
            <TableData>{props.zoneId}</TableData>
            {props.zoneMetricTypes?.map((metricType, index) => {
                const currentMetric = props.zoneMetrics?.find(metric => metric.metricType === metricType.key)?.metricValue;
                const previousMetric = props.previousZoneMetrics?.find(metric => metric.metricType === metricType.key)?.metricValue;

                return <TableData key={index}>
                    <HealthMetricLabel metricType={metricType} currentMetric={currentMetric} previousMetric={previousMetric} />
                </TableData>
            })}
        </ClickableTableRow>

        {expanded === true ? <TableRow>
            <ZoneInfoTD>
                <ZoneDetails customerId={props.customerId ?? props.zoneMetrics?.[0]?.customerId} zoneId={props.zoneId} />
            </ZoneInfoTD>
        </TableRow> : <></>}
    </>
}

export default ZoneHealthMetric;