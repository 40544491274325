import { useState } from "react";
import styled, { css } from "styled-components";
import theme from "../UI/theme";
import SearchField from "../components/UiComponents/SearchField";
import { TextMuted } from "../components/UiComponents/text/StyledText";
import LogoutButton from "../components/authentication/LogoutButton";
import { ButtonRowContainer, ModalContainer, ModalHeader } from "../components/panel/ModalStyles";
import Impersonate from "../components/admin/impersonate/Impersonate";
import { hasRole, roles } from "../components/permission/permissions";

const Container = styled(ModalContainer)`
    overflow: hidden;
    grid-auto-rows: min-content;
`

const VenueCardContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;
    height: fit-content;
    max-height: 50vh;
    overflow: auto;
`

const VenueCard = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    padding: 10px;
    box-sizing: border-box;

    color: ${theme.colors.textGray};
    background-color: ${theme.colors.darkSpace90};

    &:hover {
        cursor: pointer;
        background-color: ${theme.colors.darkSpace80};
    }

    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace80};
        color: ${theme.colors.greenEnergy};
    `}

    > label {
        cursor: inherit;
    }
`

const searchFilter = (venue, searchString) => venue.companyName?.toLowerCase().includes(searchString.toLowerCase()) || venue.customerId.toString().includes(searchString.toLowerCase());

const MyVenuesView = ({ venues, user, customer, setCustomer, onClick, showSignOutButton }) => {
    const [searchString, setSearchString] = useState('');

    const onVenueClick = (venue) => {
        setCustomer(venue.customerId)
        if (onClick) {
            onClick();
        }
    }

    const filteredVenues = venues?.filter(v => searchFilter(v, searchString))?.sort((a, b) => a?.customerId === user?.defaultVenueId ? -1 : 1);

    return <Container>
        <ModalHeader>My venues</ModalHeader>

        {venues?.length > 7 ? <>
            <SearchField placeholder={'Search for venue'} value={searchString} onChange={(e) => setSearchString(e.target.value)} />
        </> : <></>}

        {venues.length === 0 ? <>
            {hasRole({ user }, roles.impersonator) ? <>
                <Impersonate
                    user={user}
                    customer={customer}
                    setCustomer={setCustomer}
                />
                <TextMuted>You are not added to any venue, but you can use the impersonate button to access the venue you want.</TextMuted>
            </> : <>
                <TextMuted>You do not have access to any venue.</TextMuted>
            </>}
        </> : <></>}

        {filteredVenues?.length > 0 ? <>
            <VenueCardContainer>
                {filteredVenues.map(venue => {
                    const isDefault = user.defaultVenueId === venue.customerId;
                    return <VenueCard
                        onClick={() => onVenueClick(venue)}
                        active={customer?.customerId === venue.customerId}
                    >
                        <label>{venue.companyName}</label>
                        {isDefault ? <>
                            <TextMuted>Default</TextMuted>
                        </> : <>
                        </>}
                    </VenueCard>
                })}
            </VenueCardContainer>
        </> : <></>}
        {showSignOutButton ? <>
            <ButtonRowContainer>
                <LogoutButton />
            </ButtonRowContainer>
        </> : <></>}
    </Container>
}

export default MyVenuesView;