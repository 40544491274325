import React from 'react';
import styled from 'styled-components';
import ZoneModeSettings from './ZoneModeSettings';
import theme from '../../../../UI/theme';

const Container = styled.div`
    display: grid;
    grid-template-column: 1fr;
    grid-gap: 10px;
    background-color: ${theme.colors.darkSpace90};
    box-sizing: border-box;
    padding: 20px;
`

const ZoneSettingsItem = props => {
    return <Container>
        {props.zone?.shouldRegulate ?
            <>
                {props.zoneModes?.length ? <>
                    <ZoneModeSettings
                        customerId={props.customerId}
                        zone={props.zone}
                        zoneModes={props.zoneModes}
                        setZoneModes={props.setZoneModes}
                        updateZoneHandler={props.updateZoneHandler}
                        getZoneModes={props.getZoneModes}
                    />
                </> : <></>}
            </> : <></>}
    </Container>
}

export default ZoneSettingsItem;