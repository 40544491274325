const emptyAddressWarning = {type: 'WARNING', message: 'Address should not be empty'};

class Xilica {

    static validateObjectAddress(value) {
        if (checkIfEmpty(value)) {
            return emptyAddressWarning;
        }
    }

    static validateMuteAddress(value) {
        if (checkIfEmpty(value)) {
            return emptyAddressWarning;
        }
    }

    static validateSourceSelectorAddress(value) {
        if (checkIfEmpty(value)) {
            return emptyAddressWarning;
        }
    }

    static suggestObjectAddress() {
    }

    static suggestMuteAddress() {
    }

    static testFormat(address) {
    }
}

Xilica.props = {
    muteAddress: true
}

export default Xilica;

const checkIfEmpty = (value) => {
    return (value === '' || value === null || value === undefined);
}

