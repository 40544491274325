import styled from "styled-components";
import ViewHeader from "../components/UiComponents/ViewHeader";
import TopNavigation from "../components/navigation/TopNavigation";
import { ButtonRowContainer } from "../components/panel/ModalStyles";
import InputField from "../components/UiComponents/InputField";
import Button from "../components/UiComponents/Button";
import theme from "../UI/theme";
import { sendEmailChangeRequest, sendEmailChangeVerification } from "../scripts/user";
import { useState } from "react";
import { SettingsParagraph, SettingsSection } from "../components/settings/settingsStyles";
import { loadingStates } from "../components/installation/installationTypes";
import { isValidEmail } from "../scripts/common";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    background-color: ${theme.colors.darkSpace90};
    padding: 20px;
    box-sizing: border-box;
`

const EmailChangeView = props => {
    const [email, setEmail] = useState('');
    const [verificationCodeSent, setVerificationCodeSent] = useState(false);
    const [oldEmailVerificationCode, setOldEmailVerificationCode] = useState('');
    const [newEmailVerificationCode, setNewEmailVerificationCode] = useState('');
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [error, setError] = useState('');
    const match = useRouteMatch();
    const history = useHistory();

    const sendEmailChangeRequestHandler = async () => {
        try {
            await sendEmailChangeRequest(email);
            setVerificationCodeSent(true);
        } catch (error) {

        }
    }

    const confirmEmailChangeHandler = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            await sendEmailChangeVerification(oldEmailVerificationCode, newEmailVerificationCode);
            setLoadingState(loadingStates.SUCCESS);
        } catch (error) {
            if (error?.response?.status === 410 || error?.response?.status === 400) {
                setError('Error changing email: ' + error?.response?.data?.error);
            } else {
                setError('Email change failed.');
            }
            setLoadingState(loadingStates.FAILED);
        }
    }

    const handleRetry = () => {
        setEmail('');
        setVerificationCodeSent(false);
        setOldEmailVerificationCode('');
        setNewEmailVerificationCode('');
        setLoadingState(loadingStates.NONE);
        setError('');
    }

    return <>
        <ViewHeader headerText={'Change Email'} backLink={'/settings/user'} />

        <Container>
            {verificationCodeSent === false ? <>
                <SettingsSection>
                    <label>New email address</label>
                    <InputField value={email} onChange={(e) => setEmail(e.target.value)} />
                </SettingsSection>

                <SettingsSection>
                    <SettingsParagraph>A verification code will be sent to both your old and new email address. Both codes are nescessary to complete the email change.</SettingsParagraph>
                </SettingsSection>

                <SettingsSection>
                    <ButtonRowContainer>
                        <Button disabled={!isValidEmail(email)} primary onClick={() => sendEmailChangeRequestHandler()}>Send verification codes</Button>
                    </ButtonRowContainer>
                </SettingsSection>
            </> : <>

                {loadingState === loadingStates.NONE ? <>
                    <SettingsSection>
                        <label>Old email verification code</label>
                        <InputField value={oldEmailVerificationCode} onChange={(e) => setOldEmailVerificationCode(e.target.value)} />
                    </SettingsSection>

                    <SettingsSection>
                        <label>New email verification code</label>
                        <InputField value={newEmailVerificationCode} onChange={(e) => setNewEmailVerificationCode(e.target.value)} />
                    </SettingsSection>

                    <SettingsSection>
                        <ButtonRowContainer>
                            <Button onClick={() => confirmEmailChangeHandler()} primary>Confirm email change</Button>
                        </ButtonRowContainer>
                    </SettingsSection>
                </> : <></>}

                {loadingState === loadingStates.SUCCESS ? <>
                    <SettingsSection>
                        <SettingsParagraph>Email changed successfully!</SettingsParagraph>
                    </SettingsSection>

                    <SettingsSection>
                        <ButtonRowContainer>
                            <ButtonRowContainer>
                                <Button onClick={() => history.push(`${match.url?.split('/email')[0]}`)} primary>Return to user settings</Button>
                            </ButtonRowContainer>
                        </ButtonRowContainer>
                    </SettingsSection>
                </> : <></>}

                {loadingState === loadingStates.LOADING ? <>
                    <label>Changing email...</label>
                </> : <></>}
                {loadingState === loadingStates.FAILED ? <>
                    <SettingsSection>
                        <SettingsParagraph>{error}</SettingsParagraph>
                    </SettingsSection>

                    <SettingsSection>
                        <ButtonRowContainer>
                            <ButtonRowContainer>
                                <Button onClick={() => handleRetry()} primary>Try again</Button>
                            </ButtonRowContainer>
                        </ButtonRowContainer>
                    </SettingsSection>
                </> : <></>}

            </>}

        </Container>
    </>
}

export default EmailChangeView;