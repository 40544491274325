import theme from "../../../UI/theme"

export default {
    ONE: {
        value: 1,
        color: theme.colors.raspberryRed
    },
    TWO: {
        value: 2,
        color: theme.colors.mediumRare
    },
    THREE: {
        value: 3,
        color: theme.colors.yellowFever
    },
    FOUR: {
        value: 4,
        color: theme.colors.whiteSpace
    }
}

