import React, { useEffect, useState } from 'react';
import SensorDetail from './SensorDetail';
import styled from 'styled-components';
import HeatmapView from '../../../views/HeatmapView';
import theme from '../../../UI/theme';
import { getSensors } from '../../../scripts/sensor';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const OuterContainer = styled.div`
    display: flex;
    justify-content: center;
`

const Container = styled.div`
    max-width: 120em;

    @media only screen and (min-width: ${theme.screenSizes.large}px) {
        display: grid;
        grid-template-columns: 1fr 2fr;
        >div {
            margin: 0 12px;
        }
    }   
`

const HeatmapContainer = styled.div`
`

const SensorDetailContainer = styled.div`
`

const SensorDetailView = props => {
    const { initialSensorID } = useParams();
    const [sensors, setSensors] = useState([]);
    const [selectedSensorID, setSelectedSensorID] = useState(initialSensorID ?? null);
    const [selectedSensor, setSelectedSensor] = useState();

    useEffect(() => {
        getInitialSensor();
    }, [])

    const getInitialSensor = async () => {
        var allSensors = await getSensors(props.customerId);
        if (initialSensorID) {
            var newSelectedSensor = allSensors?.find(sensor => sensor?.id === initialSensorID);
            if (newSelectedSensor) {
                setSelectedSensor(newSelectedSensor);
            } else {
                setSelectedSensor(null);
            }
        }
    }

    useEffect(() => {
        getSensorData();
    }, [props.customerId])

    useEffect(() => {
        if (selectedSensorID) {
            var newSelectedSensor = sensors?.find(sensor => sensor?.sensorId === selectedSensorID);
            if (newSelectedSensor) {
                setSelectedSensor(newSelectedSensor);
            } else {
                setSelectedSensor(null);
            }
        }
    }, [sensors, selectedSensorID])

    const onSensorClickHandler = (clickedSensor) => {
        setSelectedSensorID(clickedSensor.sensorId);
    }

    const getSensorData = async () => {
        var allSensors = await getSensors(props.customerId);
        setSensors([...allSensors]);
    }
    
    return <OuterContainer>
        <Container>
            <div>
                <HeatmapContainer>
                    <div>
                        <HeatmapView
                            customerId={props.customerId}
                            height={'35vh'}
                            onSensorClickHandler={onSensorClickHandler}
                            hideBackButton={true}
                        />
                    </div>
                </HeatmapContainer>
            </div>
            {selectedSensorID && selectedSensor ? <SensorDetailContainer>
                <SensorDetail sensor={selectedSensor} customerId={props.customerId} />
            </SensorDetailContainer>
                : <></>}
        </Container>
    </OuterContainer>
}

export default SensorDetailView;