import { FaCheckCircle, FaTimes } from "react-icons/fa";
import { testStates } from "../../installationTypes"
import { FieldButton, ResultContainer, ResultValue } from "../styling/FieldStyles"

const FieldTestResult = ({ disabled, state, testResult, close, hide}) => {
    return <>
        {!disabled && (state !== testStates.NONE && hide !== true) ? <>
            <ResultContainer>
                <div>
                    {state === testStates.SUCCESS ? <>
                        <span>
                            <ResultValue>
                                <FaCheckCircle />
                                <span>Value: </span>
                                <span>{testResult}</span>
                            </ResultValue>
                        </span>
                    </> : <></>}


                    <span>
                        {state === testStates.FAILED ? <>
                            Failed<span>: {testResult}</span>
                        </> : <></>}
                    </span>

                    <span>
                        {state === testStates.TESTING ? <>
                            Testing...
                        </> : <></>}
                    </span>
                </div>
                <div>
                    <FieldButton onClick={close}>
                        <FaTimes style={{color: 'white'}}/>
                    </FieldButton>
                </div>
            </ResultContainer>
        </> : <></>}
    </>
}

export default FieldTestResult;