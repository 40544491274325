import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import styled from "styled-components";
import { deleteZoneGroup, updateZoneGroup } from "../../../../scripts/zoneGroup";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../panel/ModalStyles";
import Button from "../../../UiComponents/Button";
import InputField from "../../../UiComponents/InputField";
import Modal from "../../../UiComponents/Modal";
import { GroupContainer, ZoneGroupHeader } from "./groupStyles";
import SourceSelectorGroup from "./SourceSelectorGroup";
import Zone from "./Zone";

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    overflow: hidden;
`

const StyledInputField = styled(InputField)`
    width: 50%;
`

const ZoneGroup = props => {
    const [isEditing, setIsEditing] = useState(false);
    const [hasBeenEdited, setHasBeenEdited] = useState(false);
    const [zoneGroupName, setZoneGroupName] = useState(props.zoneGroup?.name);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleChange = (value) => {
        setHasBeenEdited(true);
        setZoneGroupName(value);
    }

    const handleCancelChange = () => {
        setIsEditing(false);
        setZoneGroupName(props.zoneGroup?.name);
        setHasBeenEdited(false);
    }

    const handleSaveChange = async () => {
        let pickedZoneGroup = (({ zoneGroupId, customerId }) => ({ zoneGroupId, customerId }))(props.zoneGroup);
        await updateZoneGroup({ ...pickedZoneGroup, name: zoneGroupName });
        setHasBeenEdited(false);
        setIsEditing(false);
    }

    const handleDelete = async () => {
        await deleteZoneGroup(props.zoneGroup?.zoneGroupId);
        setShowDeleteModal(false);
    }

    return <GroupContainer>
        <HeaderRow>
            {isEditing === true ? <>
                <StyledInputField value={zoneGroupName} onChange={(e) => handleChange(e.target.value)} />
                <ButtonRowContainer>

                    {hasBeenEdited === true ?
                        <Button small primary onClick={() => handleSaveChange()}>Save</Button>
                        : <></>
                    }
                    <Button small secondary onClick={() => handleCancelChange()}>Cancel</Button>
                </ButtonRowContainer>
            </> :
                <>
                    <ZoneGroupHeader>
                        {props.zoneGroup?.name ?? 'Group'}
                    </ZoneGroupHeader>
                    <ButtonRowContainer>
                        <Button small tertiary onClick={() => props?.changeZoneGroupOrder(props?.zoneGroup?.zoneGroupId, -1)}><FaChevronUp /></Button>
                        <Button small tertiary onClick={() => props?.changeZoneGroupOrder(props?.zoneGroup?.zoneGroupId, 1)}><FaChevronDown /></Button>
                        <Button small secondary onClick={() => setIsEditing(prev => !prev)}>Edit</Button>
                        <Button small tertiary onClick={() => setShowDeleteModal(true)}>Delete</Button>
                    </ButtonRowContainer>
                </>
            }
        </HeaderRow>

        {props?.sourceSelectors?.map((sourceSelector, index) => {
            const hubId = props?.processors?.find(processor => processor?.processorId === sourceSelector?.processorId)?.hubId;

            return <SourceSelectorGroup
                key={index}
                hubId={hubId}
                sourceSelector={sourceSelector}
                zones={props?.zones?.filter(zone => zone.sourceSelectorId === sourceSelector.sourceSelectorId)}
                zoneGroups={props.zoneGroups} />
        })}

        {props?.zones?.filter(zone => !props?.sourceSelectors?.some(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId)).map((zone, index) => {
            return <Zone
                key={index}
                zone={zone}
                hasSourceSelector={false}
                zoneGroups={props.zoneGroups} />
        })}

        <Modal show={showDeleteModal} close={() => setShowDeleteModal(false)}>
            <ModalContainer>
                <ModalHeader>Delete group?</ModalHeader>
                <ModalText>Are you sure you want to delete this group?</ModalText>
                <ButtonRowContainer>
                    <Button small secondary onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button small primary onClick={() => handleDelete()}>Delete</Button>
                </ButtonRowContainer>
            </ModalContainer>
        </Modal>
    </GroupContainer>
}

export default ZoneGroup;