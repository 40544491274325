import styled, { css } from "styled-components";
import theme from "../../../UI/theme";
import { cloneElement, useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { FaExpandAlt } from "react-icons/fa";
import React from "react";

const FLEX_BASIS_TRANSITION = 1000;

const Container = styled.div`
    display: flex;
    width: 100%;
    box-sizing: border-box;
    border: 2px solid ${theme.colors.darkSpace80};
    border-radius: 2px;
    user-select: none;
`

const Resizer = styled.div`
    width: 30px;
    color: ${theme.colors.textGray};
    background-color: ${theme.colors.darkSpace80};
    cursor: col-resize;
    touch-action: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px 0;
    border-right: 1px solid ${theme.colors.darkSpace};

    ${props => props.disabled && css`
        cursor: initial;
    `}

    & > :not(:last-child) {
        margin-bottom: 5px;
    }
`

const ResizableColumn = styled.div`
    box-sizing: border-box;
    overflow: hidden;

    ${props => props.resizeTransitionEnabled === true && css`
        transition: flex-basis ${FLEX_BASIS_TRANSITION / 1000}s;
    `}
`

const ColumnButton = styled.button`
    all: unset;
    width: 20px;
    height: 20px;
    background-color: ${theme.colors.darkSpace};
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: ${theme.fontSize.tiny};
    border-radius: 2px;
    cursor: pointer;

    @media (hover:hover) {
        &:hover {
            color: ${theme.colors.greenEnergy};
            background-color: ${theme.colors.darkSpace70};
        }
    }
`

const ColumnName = styled.label`
    white-space: nowrap;
    transform-origin: top left;
    writing-mode: vertical-rl;
    overflow: hidden;
    cursor: inherit;
    font-size: ${theme.fontSize.tiny};
`

const ResizableColumnContainer = props => {
    const [isResizing, setIsResizing] = useState(false);
    const [initialX, setInitialX] = useState(0);
    const [resizingIndex, setResizingIndex] = useState(null);
    const [columnWidths, setColumnWidths] = useState(props.defaultColumnWidths || Array(props.children?.length).fill(100 / props.children?.length));
    const containerRef = useRef();
    const [containerRect, setContainerRect] = useState();
    const [resizeTransitionEnabled, setResizeTransitionEnabled] = useState(true);

    const onStartResize = (event, index) => {
        if (index > 0) {
            setIsResizing(true);
            setResizeTransitionEnabled(false);
            setInitialX(event.touches ? event.touches[0].clientX : event.clientX);
            setResizingIndex(index);
            setContainerRect(containerRef.current.getBoundingClientRect());
        }
    }

    const maximize = (event, index) => {
        event.stopPropagation();
        let newColumnWidths = Array(columnWidths.length).fill(0);
        newColumnWidths[index] = 100;
        setColumnWidths(newColumnWidths);

        if (props.onStopResize) {
            setTimeout(() => {
                props.onStopResize(newColumnWidths);
            }, FLEX_BASIS_TRANSITION);
        }
    }

    useEffect(() => {
        const onResize = (event) => {
            if (isResizing) {
                const minColSize = 0;
                const clientX = event.touches ? event.touches[0].clientX : event.clientX;
                const deltaX = clientX - initialX;
                const newColumnWidths = [...columnWidths];
                const newLeftWidth = newColumnWidths[resizingIndex - 1] + (deltaX / containerRect.width) * 100;
                const newRightWidth = newColumnWidths[resizingIndex] - (deltaX / containerRect.width) * 100;
                if (newLeftWidth * containerRect.width / 100 >= minColSize && newRightWidth * containerRect.width / 100 >= minColSize) {
                    newColumnWidths[resizingIndex - 1] += (deltaX / containerRect.width) * 100;
                    newColumnWidths[resizingIndex] -= (deltaX / containerRect.width) * 100;

                    setColumnWidths(newColumnWidths);
                    setInitialX(clientX);
                } else {
                    if (newLeftWidth * containerRect.width / 100 < minColSize) {
                        newColumnWidths[resizingIndex - 1] = minColSize;
                        newColumnWidths[resizingIndex] += columnWidths[resizingIndex - 1] - minColSize;
                        setColumnWidths(newColumnWidths);
                        setInitialX(clientX);
                    } else if (newRightWidth * containerRect.width / 100 < minColSize) {
                        newColumnWidths[resizingIndex - 1] += columnWidths[resizingIndex] - minColSize;
                        newColumnWidths[resizingIndex] = minColSize;
                        setColumnWidths(newColumnWidths);
                        setInitialX(clientX);
                    }
                }
            }
        }

        const onStopResize = (event) => {
            setIsResizing(false);
            setResizingIndex(null);
            setResizeTransitionEnabled(true);

            if (props.onStopResize) {
                props.onStopResize(columnWidths);
            }

            document.removeEventListener('mousemove', onResize);
            document.removeEventListener('mouseup', onStopResize);
            document.removeEventListener('touchmove', onResize);
            document.removeEventListener('touchend', onStopResize);
        }

        if (isResizing === true) {
            document.addEventListener('mousemove', onResize);
            document.addEventListener('mouseup', onStopResize);
            document.addEventListener('touchmove', onResize);
            document.addEventListener('touchend', onStopResize);
        }

        return () => {
            document.removeEventListener('mousemove', onResize);
            document.removeEventListener('mouseup', onStopResize);
            document.removeEventListener('touchmove', onResize);
            document.removeEventListener('touchend', onStopResize);
        }
    }, [isResizing, resizingIndex, initialX, columnWidths]);

    return <Container ref={containerRef}>
        {props.children?.length ? <>
            {props?.children?.map((child, index) => {
                return <React.Fragment key={index}>
                    <Resizer
                        disabled={index === 0}
                        onMouseDown={(e) => onStartResize(e, index)}
                        onTouchStart={(e) => onStartResize(e, index)}>
                        <ColumnButton
                            onMouseDown={(e) => e.stopPropagation()}
                            onTouchStart={(e) => e.stopPropagation()}
                            onClick={(e) => maximize(e, index)}>
                            <FaExpandAlt />
                        </ColumnButton>
                        {child.props.columntitle ?
                            <ColumnName>{child.props.columntitle}</ColumnName>
                            : <></>}
                    </Resizer>
                    <ResizableColumn resizeTransitionEnabled={resizeTransitionEnabled} style={{ flexBasis: `${columnWidths[index]}%` }}>
                        {cloneElement(child, { width: columnWidths[index] })}
                    </ResizableColumn>
                </React.Fragment>
            })}
        </> : <></>}
    </Container>
}

export default ResizableColumnContainer;