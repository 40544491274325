import { useState } from "react";
import SensorList from "./SensorList";
import Button from "../../../../UiComponents/Button";
import SensorDetail from "./SensorDetail";
import { useSubscription } from "../../../../../hooks/useSubscription";
import { subscriptionsTypes } from "../../../../../scripts/subscription/subscriptionTypes";

const SensorContainer = ({ customerId, zoneId }) => {
    const [sensors, _] = useSubscription(subscriptionsTypes.sensors);
    const [selectedSensorId, setSelectedSensorId] = useState();

    const zoneSensors = sensors?.filter(sensor => sensor.zoneId === zoneId);
    const selectedSensor = sensors?.find(sensor => sensor.sensorId === selectedSensorId)

    return <div>
        <h3>Sensors</h3>
        {zoneSensors?.length && <>
            {selectedSensor ? <>
                <SensorDetail sensor={selectedSensor} />
                <Button tertiary small onClick={() => setSelectedSensorId(null)}>Back</Button>
            </> : <>
                <SensorList sensors={zoneSensors} onSelect={(sensor) => setSelectedSensorId(sensor.sensorId)} />
            </>}
        </>}
    </div>
}

export default SensorContainer;