import styled from 'styled-components';
import theme from '../../UI/theme';

const ViewContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &>div {
        @media only screen and (min-width: ${theme.screenSizes.medium}px) {
            width: ${props => props.minWidth || '40em'};
        }

        @media only screen and (max-width: ${theme.screenSizes.medium-1}px) {
            width: 100%;
        }
    }

    @media only screen and (max-width: ${theme.screenSizes.medium-1}px) {
        margin-bottom: 84px;
    }
`

export default ViewContainer;