import styled from 'styled-components';
import theme from '../../../UI/theme';

const InnerCard = styled.div`
    padding: 16px 10px;
    transition: transform 0.2s, height 0.7s;
    background-color: ${theme.colors.darkSpace80};
    border-radius: 2px;
`

export default InnerCard;