import React from 'react';
import { InfoContainer, InfoRow } from '../../../styling/DeviceStyles';
import NodeTypeField from './NodeTypeField';

const ZigbeePropsDisplayField = ({ sensor }) => {

    return <InfoContainer>
        <h4>Zigbee properties</h4>
        <InfoRow>
            <div>NodeType: <NodeTypeField nodeType={sensor.nodeType} /></div>
            <div>Node Id: {sensor.nodeId}</div>
            <div>LQI: {sensor.lqi}</div>
        </InfoRow>
    </InfoContainer>
}

export default ZigbeePropsDisplayField;