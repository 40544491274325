import styled from 'styled-components';

const InnerWallSymbol = styled.div`
    height: 12px;
    width: 3px;
    border-radius: 2px;
    background-color: gray;
    transform: rotate(40deg);
    margin-right: 12px;
    margin-left: 4px;
`

export default InnerWallSymbol;