import styled from 'styled-components';
import theme from '../../UI/theme';

export const CardHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
    }
`

const Card = styled.div`
    background-color: ${theme.colors.darkSpace90};
    padding: 10px;
    box-sizing: border-box;
    
    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        padding: 20px;
    }

`

export default Card;
