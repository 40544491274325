function validateIPaddress(ipaddress) {
    if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
        return (true)
    }
    return (false)
}

function isSameSubnet(address1, address2) {
    // Should check network mask, but for now only supports 255.255.255.0 masks
    var isSame = false;
    try {
        var subnet1 = address1.split('.').slice(0,3).join('.');
        var subnet2 = address2.split('.').slice(0,3).join('.');
        if (subnet1 === subnet2) {
            isSame = true;
        }
    } catch (e) {}
    return isSame;
}

export {
    validateIPaddress,
    isSameSubnet
}