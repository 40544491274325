import React, { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../../../UI/theme';
import { HeaderRow, StyledTable } from '../../../../installation/table/styles/TableStyles';
import Button from '../../../../UiComponents/Button';
import Checkbox from '../../../../UiComponents/Checkbox';
import InputField from '../../../../UiComponents/InputField';
import Modal from '../../../../UiComponents/Modal';
import { TextMuted } from '../../../../UiComponents/text/StyledText';
import { ButtonRowContainer } from '../DeviceStyles';
import { isSameSubnet, validateIPaddress } from './networkUtils';
import ValidationError from './ValidationError';
import { interfaceKeys } from './networkConfigTypes';
import { loadingStates } from '../../../../installation/installationTypes';
import serverConnection from '../../../../../scripts/server/ServerConnection';
import LoadingSpinner from '../../../../UiComponents/LoadingSpinner';
import { ModalContainer } from '../../../../panel/ModalStyles';

const AddressCell = styled.td`
    width: 130px;
`
const FailMessage = styled.span`
    color: ${theme.colors.raspberryRed};
    font-size: ${theme.fontSize.small};
`

const HeaderContainer = styled.div`
    >h4 {
        margin: 0 10px 0 0;
    }
    display: flex;
    align-items: center;
`

const IpConfigTable = ({ hub, mainKey, virtualKey }) => {
    const [editState, setEditState] = useState(false);
    const [networkConfig, setNetworkConfig] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [errorMessage, setErrorMessage] = useState();

    const setEditStateHandler = () => {
        var newEditState = !editState;
        if (newEditState) {
            setNetworkConfig({ ...hub.networkConfig });
            validateNetworkConfig();
            setLoadingState(loadingStates.NONE);
            setErrorMessage(null);
        }
        setEditState(newEditState);
    }

    const updateNetworkConfig = (interfaceKey, key, value) => {
        var updatedNetworkConfig = { ...networkConfig };
        updatedNetworkConfig[interfaceKey][key] = value;
        validateNetworkConfig(updatedNetworkConfig);
        setNetworkConfig(updatedNetworkConfig);
    }

    const addVirtualInterface = () => {
        var updatedNetworkConfig = { ...networkConfig };
        updatedNetworkConfig[virtualKey] = {
            address: '',
            isStatic: true
        };
        validateNetworkConfig(updatedNetworkConfig);
        setNetworkConfig(updatedNetworkConfig);
    }

    const removeVirtualInterface = () => {
        var updatedNetworkConfig = { ...networkConfig };
        delete updatedNetworkConfig[virtualKey];
        validateNetworkConfig(updatedNetworkConfig);
        setNetworkConfig(updatedNetworkConfig);
    }

    const validateInterface = (updatedNetworkConfig, interfaceKey) => {
        let updatedValidationErrors = {};
        let iface = updatedNetworkConfig[interfaceKey];
        if (iface) {
            if (!validateIPaddress(iface.address)) {
                updatedValidationErrors[interfaceKey] = { message: 'Not a valid ip address', type: 'ERROR' };
            } else {

                if (interfaceKey === mainKey) {
                    var current = hub.networkConfig[mainKey];
                    if (!isSameSubnet(current.address, iface.address)) {
                        updatedValidationErrors[interfaceKey] = { message: 'Changed subnet', type: 'WARNING' };
                    }
                } else if (interfaceKey === virtualKey) {
                    var mainInterface = updatedNetworkConfig[mainKey];
                    if (isSameSubnet(mainInterface.address, iface.address)) {
                        updatedValidationErrors[interfaceKey] = { message: 'Same subnet as ' + mainKey, type: 'WARNING' };
                    }
                }
            }
        }
        return updatedValidationErrors;
    }

    const validateNetworkConfig = (config) => {
        var updatedNetworkConfig = config || networkConfig;
        let updatedValidationErrors = validateInterface(updatedNetworkConfig, mainKey);
        updatedValidationErrors = { ...updatedValidationErrors, ...validateInterface(updatedNetworkConfig, virtualKey) }
        setValidationErrors(updatedValidationErrors);
    }

    const saveClickHandler = () => {
        var hasErrors = Object.keys(validationErrors).filter(key => validationErrors[key].type === 'ERROR').length;
        var hasWarnings = Object.keys(validationErrors).filter(key => validationErrors[key].type === 'WARNING').length;
        var content
        if (hasErrors) {
            content = <>
                <p>You cannot save network config with errors</p>
                <Button primary onClick={() => setShowModal(false)}>OK</Button>
            </>
        } else if (hasWarnings) {
            content = <>
                <div>
                    <p>Are you sure you want to save network config with warnings? This can potentially make the hub unreachable</p>
                    <Button primary margin onClick={() => saveHandler()}>Yes</Button>
                    <Button secondary margin onClick={() => setShowModal(false)}>Cancel</Button>
                </div>
            </>
        }

        if (content) {
            setModalContent(content);
            setShowModal(true);
        } else {
            saveHandler();
        }
    }

    const saveHandler = async () => {
        try {
            setErrorMessage(null);
            setLoadingState(loadingStates.LOADING);
            const updatedConfig = hub.networkConfig;
            updatedConfig[mainKey] = networkConfig[mainKey];
            updatedConfig[virtualKey] = networkConfig[virtualKey];
            const res = await serverConnection.hubEventHandler.sendEvent(
                hub.hubId,
                hub.customerId,
                'UPDATE_NETWORK_CONFIG_DIRECT',
                { networkConfig: updatedConfig },
                20000
            );

            if (res.updated) {
                setLoadingState(loadingStates.SUCCESS);
                setEditState(false);
            } else {
                setErrorMessage('Failed to update network config');
                setLoadingState(loadingStates.FAILED);
            }

        } catch (err) {
            console.log('Error - failed to save network config', err)
            setErrorMessage('Failed to update network config');
            setLoadingState(loadingStates.FAILED);
        } finally {
            setShowModal(false);
        }
    }

    const displayInterface = (interfaceKey) => {
        var iface = networkConfig[interfaceKey];
        var validationError = validationErrors[interfaceKey];
        if (editState) {
            return <tr key={interfaceKey}>
                <td>{interfaceKey}</td>
                <AddressCell>
                    {iface.isStatic ? <>
                        <InputField
                            small
                            width={130}
                            value={iface.address} onChange={(e) => updateNetworkConfig(interfaceKey, 'address', e.target.value)} />

                        <br />
                        {validationError ? <>
                            <ValidationError validationError={validationError} />
                        </> : <></>}

                    </> : <>
                        {iface.address}
                    </>}
                </AddressCell>
                <td>
                    <Checkbox
                        checked={iface.isStatic}
                        disabled={!canSetStatic(interfaceKey)}
                        onChange={() => updateNetworkConfig(interfaceKey, 'isStatic', !iface.isStatic)}
                        backgroundColor={theme.colors.darkSpace}
                    />
                </td>
                <td>
                    {interfaceKey === virtualKey ? <>
                        <Button secondary small onClick={removeVirtualInterface}>Remove</Button>
                    </> : <></>}
                </td>
            </tr>
        } else {
            var iface = hub.networkConfig[interfaceKey];
            return <tr key={interfaceKey}>
                <td>{interfaceKey}</td>
                <AddressCell>{getDisplayAddress(interfaceKey, iface)}</AddressCell>
                <td>{iface.isStatic ? 'Yes' : 'No'}</td>
            </tr>
        }
    }

    const hasVirutalInterface = () => {
        if (editState) {
            return !!networkConfig[virtualKey];
        } else {
            return !!hub.networkConfig[virtualKey];
        }
    }

    return <>
        {hub.networkConfig && hub.networkConfig[mainKey] ? <>
            <HeaderContainer>
                <h4>Ip addresses</h4>
                <div>
                    <Button small tertiary lightBorder onClick={() => setEditStateHandler()}>
                        {editState ? 'Cancel' : 'Edit'}
                    </Button>
                </div>
            </HeaderContainer>

            <StyledTable borderColor={theme.colors.darkSpace80}>
                <thead>
                    <HeaderRow backgroundColor={theme.colors.darkSpace80}>
                        <th>Interface</th>
                        <th>Address</th>
                        <th>Static</th>
                        {editState && <th></th>}
                    </HeaderRow>
                </thead>
                <tbody>
                    {displayInterface(mainKey)}
                    {hasVirutalInterface() ? <>
                        {displayInterface(virtualKey)}
                    </> : <></>}
                </tbody>
            </StyledTable>
            {editState ? <>
                <div>
                    {!networkConfig[virtualKey] ? <>
                        <Button
                            secondary
                            small
                            onClick={addVirtualInterface}
                            disabled={networkConfig[virtualKey]}
                        >
                            Add virtual interface
                        </Button>
                    </> : <></>}


                    <ButtonRowContainer>
                        {loadingState === loadingStates.NONE ? <>
                            <Button small primary onClick={saveClickHandler}>Save</Button>
                            <Button small secondary onClick={() => setEditStateHandler(false)}>Cancel</Button>
                        </> : <></>}

                        {loadingState === loadingStates.FAILED ? <>
                            <div><FailMessage>{errorMessage}</FailMessage></div>
                            <Button small primary onClick={saveClickHandler}>Retry</Button>
                            <Button small secondary onClick={() => setEditStateHandler(false)}>Cancel</Button>
                        </> : <></>}

                        {loadingState === loadingStates.LOADING ? <>
                            <LoadingSpinner />
                        </> : <></>}

                    </ButtonRowContainer>
                </div>
            </> : <>
                <div>
                    {loadingState === loadingStates.SUCCESS ? <>
                        <span>Updated</span>
                    </> : <></>}
                </div>
            </>}
        </> : <></>}

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                {modalContent}
            </ModalContainer>
        </Modal>

    </>
}

export default IpConfigTable;

const canSetStatic = (key) => {
    return key === interfaceKeys.ETH0;
}

const getDisplayAddress = (key, iface) => {
    var displayAddress = <span>{iface.address}</span>;
    if (key === interfaceKeys.ETH0 && iface.isStatic) {
        if (iface.staticAddress && iface.address != iface.staticAddress) {
            displayAddress = <span>
                Current: {iface.address}<br />
                Set: {iface.staticAddress} <br />
                <TextMuted >Reboot to apply</TextMuted>
            </span>
        }
    }
    return displayAddress;
}