import { useState } from 'react';
import styled from 'styled-components';
import theme from '../../../../UI/theme';
import NeutralSpinner from '../../../UiComponents/Animations/NeutralSpinner';
import Button from '../../../UiComponents/Button';
import InputField from '../../../UiComponents/InputField';
import soundtrackYourBrandLogo from '../../logos/soundtrackYourBrand-logo-white.png';
import { ProviderLogo } from '../../musicPlayerStyles';
import { musicPlayerProviders } from '../musicPlayerProviders';
import { checkAvailablePlayers } from '../providerUtils';

const PlayerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
    align-items: center;
    gap: 10px;
    background-color: ${theme.colors.darkSpace70};
    padding: 10px;
    box-sizing: border-box;
    border-radius: 5px;
`

const loadingStates = {
    NONE: 'NONE',
    LOADING: 'LOADING',
    DONE: 'DONE',
    ERROR: 'ERROR'
}

const SoundtrackYourBrandSetup = props => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [customerInfo, setCustomerInfo] = useState();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    const getCustomerInfo = async () => {
        try {
            setLoadingState(loadingStates.LOADING);

            const info = await checkAvailablePlayers(
                musicPlayerProviders.SOUNDTRACK_YOUR_BRAND.key,
                {
                    email: email,
                    password: password
                }
            );

            setCustomerInfo(info);
            setLoadingState(loadingStates.DONE);
        } catch (error) {
            setLoadingState(loadingStates.ERROR);
        }
    }

    const addPlayer = async (zone) => {
        const remoteInfo = {
            remoteId: zone.id,
            name: zone.name,
            displayName: zone.name,
            token: customerInfo.token,
            refreshToken: customerInfo.refreshToken,
            type: customerInfo.type
        }

        await props.addMusicPlayer(remoteInfo);
    }

    return <>
        <ProviderLogo src={soundtrackYourBrandLogo} alt="Soundtrack Your Brand" />

        {!customerInfo ? <>
            <label>Email</label>
            <InputField value={email} onChange={(e) => setEmail(e.target.value)} />

            <label>Password</label>
            <InputField type='password' value={password} onChange={(e) => setPassword(e.target.value)} />

            {loadingState === loadingStates.LOADING ? <NeutralSpinner /> : <></>}

            <Button
                primary
                disabled={loadingState === loadingStates.LOADING}
                onClick={() => getCustomerInfo()}>
                Get players
            </Button>

            {loadingState === loadingStates.ERROR ? <>
                <label>An unexpected error occurred, please try again..</label>
            </> : <></>}
        </> : <>
            {customerInfo?.zones?.filter(zone => !props.musicPlayers?.some(musicPlayer => musicPlayer.remoteId === zone.id))?.length ? <>
                <label>Available players</label>
                {customerInfo?.zones?.filter(zone => !props.musicPlayers?.some(musicPlayer => musicPlayer.remoteId === zone.id))?.map((zone, index) => {
                    return <PlayerContainer key={index}>
                        <label>{zone.name}</label>
                        <Button small primary onClick={() => addPlayer(zone)}>Add</Button>
                    </PlayerContainer>
                })}
            </> : <label>No available players</label>}
        </>}
    </>
}

export default SoundtrackYourBrandSetup;