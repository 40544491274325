
import React from 'react';
import ChoiceField from '../../../fields/ChoiceField';
import { meterTypes } from '../meterTypes';

const types = [{
    label: 'Input',
    value: meterTypes.INPUT
}, {
    label: 'Output',
    value: meterTypes.OUTPUT
}];

const MeterTypeChoiceField = props => {
    const selectedItem = types.find(type => type.value === props.type) || types[0];
    return <>
        <ChoiceField
            selectedItem={selectedItem}
            items={types}
            setValue={(value) => props.setValue(value)}
            cellControl={props.cellControl}
            excludeNone={true}
        />
    </>
}

export default MeterTypeChoiceField;