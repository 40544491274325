import React, {useState} from 'react';
import styled from 'styled-components';
import heatmapTypes from './heatmapTypes';
import CanvasBox from './CanvasBox';
import List from './StyledComponents/CanvasBoxList';
import BankItem from './BankItem';
import ColorMarker from './StyledComponents/ColorMarker';
import BlinkButton from '../../UiComponents/BlinkButton'
import { getZoneColor } from '../canvas/colorUtils';
import { postEvent } from '../../../scripts/event'

const ZoneContainer = styled.div`
    margin: 10px 0;
    padding: 10px 0;
`

const ZoneName = styled.h4`
    margin: 0px;
`

const ZoneNameContainer = styled.div`
    display: flex;
    align-items: center;
`


const SensorBank = props => {

    const [blinkingSensors, setBlinkingSensors] = useState(props.sensors.map(s => ({...s , isBlinking: false})));

    var zoneGroups = [];
    props.zones.forEach(zone => {
        zoneGroups.push({
            ...zone,
            sensors: props.sensors.filter(sensor => sensor.zoneId === zone.floorElementId)
        })
    });

    zoneGroups.push({
        zoneName: 'Sensors w/o zone',
        sensors: props.sensors.filter(sensor => !props.zones.some(zone => zone.floorElementId === sensor.zoneId))
    })

    const placeSensorHandler = (sensor) => {
        props.actionHandler(heatmapTypes.actions.PLACE_DEVICE, sensor);
    }

    const removeSensor = (sensor) => {
        props.actionHandler(heatmapTypes.actions.REMOVE_DEVICE, sensor);
    }

    const markSensor = (sensor) => {
        // unmark others
        props.actionHandler(heatmapTypes.actions.UNMARK_ALL_DEVICES, null);

        // mark selected if not already
        if (sensor.floorElementId != props.markedDevice) {
            props.actionHandler(heatmapTypes.actions.MARK_DEVICE, sensor);
            props.setMarkedDevice(sensor.floorElementId);
        } else {
            props.setMarkedDevice(null);
        }
    }

    const blinkSensor = (sensor) => {
        var updatedBlinkingSensors = [...blinkingSensors];
        updatedBlinkingSensors.forEach(s => {
            if(s.floorElementId == sensor.floorElementId) {
                var command;
                if (s.isBlinking) command = 'STOP_BLINK';
                else {
                    command = 'BLINK';
                    setTimeout(() => blinkingTimeout(sensor.floorElementId), 48*1000);
                }
                postEvent(sensor.hubId, props.customerId, command, {sensorId: sensor.floorElementId});
                s.isBlinking = !s.isBlinking;
            };
        });
        setBlinkingSensors(updatedBlinkingSensors);
    }

    const blinkingTimeout = (sensorId) => {
        var updatedBlinkingSensors = [...blinkingSensors];
        updatedBlinkingSensors.forEach(s => {if(s.floorElementId == sensorId) s.isBlinking = false;});
        setBlinkingSensors(updatedBlinkingSensors);
    }
    
    
    return <CanvasBox>
        <h4>Zone groups</h4>
        
            {zoneGroups.map(zone => {
                var zoneColor = getZoneColor(zone, props.zones);
                return <ZoneContainer key={zone.floorElementId}>
                        {zoneGroups.length > 1 ? <>
                            <ZoneNameContainer>
                                <ColorMarker color={zoneColor}/>
                                <ZoneName>{zone.zoneName}</ZoneName>
                            </ZoneNameContainer>
                        </> : <></>}                        
                        <List>
                        {zone.sensors.map((sensor) => {
                                return <BankItem 
                                            key={sensor.floorElementId} 
                                            item={sensor}
                                            displayName={getSensorName(sensor)} 
                                            placeItem={placeSensorHandler}
                                            removeItem={() => removeSensor(sensor)}
                                            isMarked={sensor.floorElementId === props.markedDevice}
                                            markItem={markSensor}
                                            floor={props.floors.find(floor => floor.floorId === sensor.floorId)}
                                            blinkButton={
                                                <BlinkButton
                                                    onClick={() => blinkSensor(sensor)}
                                                    isBlinking={blinkingSensors.find(s => s.floorElementId==sensor.floorElementId).isBlinking}
                                                />
                                            }
                                        />
                            })}
                        </List>
                    </ZoneContainer>
            })}
        
        
    </CanvasBox>
}

export default SensorBank;

function getSensorName(sensor) {
    var number = sensor.floorElementId.slice(sensor.floorElementId.length-3, sensor.floorElementId.length);
    if (sensor.ipAddress) {
        if (sensor.ipAddress.includes('.')) {
            var splitIP = sensor.ipAddress.split('.');
            var number = splitIP[splitIP.length - 1];
        }
    }
    return `Sensor ${number}`;
}