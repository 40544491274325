import Checkbox from "../../UiComponents/Checkbox"
import styled, { css } from 'styled-components';
import { displayModes } from "../utils/installationTypes";

const Container = styled.div`
    display: flex;
    ${props => props.centered && css`
        justify-content: center;
    `}
`

const CheckboxField = props => {
    return <Container centered={props.cellControl.displayMode === displayModes.TABLE}>
        <Checkbox checked={props.checked} onChange={props.setValue} />
    </Container>
}

export default CheckboxField;