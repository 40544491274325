import { useEffect, useState } from "react";


const useNetwork = () => {

    const [state, setState] = useState({
        since: new Date().toString(),
        online: navigator.onLine
    });

    function getNetworkConnection() {
        return (
            navigator.connection ||
            navigator.mozConnection ||
            navigator.webkitConnection ||
            undefined
        );
    }

    useEffect(() => {
        const handleOnline = () => {
            setState({
                online: true,
                since: new Date().toString(),
            });
        };
        const handleOffline = () => {
            setState({
                online: false,
                since: new Date().toString(),
            });
        };
        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);
        const connection = getNetworkConnection();
        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);
        };
    }, []);
    return state;
}

export default useNetwork;