import React from "react";
import { useState } from "react";
import AnimateHeight from "react-animate-height";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { sortZones } from "../../../../panel/panelUtils";
import { CalibrationCardHeader, CalibrationGroupContainer, ColumnContainer, GroupHeaderRow } from "../calibrationStyles"
import ZoneCalibrationItem from "./ZoneCalibrationItem";

const ZoneGroupCalibrationItem = props => {
    const [expanded, setExpanded] = useState(true);
    const filteredZones = props?.zones?.filter(zone => zone?.shouldRegulate === 1 && zone?.hide !== 1);

    if (filteredZones?.length > 0) {
        return <CalibrationGroupContainer expanded={expanded}>
            <GroupHeaderRow onClick={() => setExpanded(prev => !prev)}>
                <CalibrationCardHeader>{props?.zoneGroup?.name}</CalibrationCardHeader>

                {expanded === false ? <FaChevronDown /> : <FaChevronUp />}
            </GroupHeaderRow>

            <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
                <ColumnContainer>
                    {sortZones(filteredZones)?.map(zone => {
                        const zoneSourceSelector = props?.sourceSelectors?.find(sourceSelector => sourceSelector?.sourceSelectorId === zone?.sourceSelectorId);
                        const zoneProcessor = props?.processors?.find(processor => processor?.processorId === zone?.processorId);
                        const zoneSources = props?.sources?.filter(source => source?.processorId === zoneProcessor?.processorId);

                        if (zone?.shouldRegulate === 1 && zone?.hide !== 1) {
                            return <ZoneCalibrationItem
                                key={zone?.zoneId}
                                zone={zone}
                                zoneLive={props?.zoneLives?.find(zoneLive => zoneLive?.zoneId === zone?.zoneId)}
                                sourceSelector={zoneSourceSelector}
                                processor={zoneProcessor}
                                sources={zoneSources} />
                        } else {
                            return <React.Fragment key={zone?.zoneId}></React.Fragment>
                        }
                    })}
                </ColumnContainer>
            </AnimateHeight>
        </CalibrationGroupContainer>
    } else {
        return <></>
    }

}

export default ZoneGroupCalibrationItem;