const entityTypes = {
    customer: {
        key: 'customer',
    },
    hub: {
        key: 'hub',
    },
    processor: {
        key: 'processor',
    },
    zone: {
        key: 'zone',
    },
    sensor: {
        key: 'sensor',
    }
}

module.exports = entityTypes;