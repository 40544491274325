import React, { useState, useEffect, useRef } from 'react';
import { formatDateTimeLabel } from '../../../../scripts/common';
import { getBatteryLogs, getDateTime } from '../../../../scripts/log';
import theme from '../../../../UI/theme';
import LiveGraph from './SensorLiveGraph';

const chartData = {
    labels: [],
    datasets: [
        {
            data: [],
        }
    ]
}

const N_DATA_POINTS = 101;

const BatteryLevelGraphContainer = props => {
    const [graphData, setGraphData] = useState(chartData);
    let componentMounted = useRef(true);

    useEffect(() => {
        return () => {
            componentMounted.current = false;
            setGraphData(chartData);
        }
    }, [])

    useEffect(() => {
        getBatteryData(props.sensorId)
    }, [props.sensorId])

    const getBatteryData = async (sensorId) => {
        var newData = await getBatteryLogs(props.customerId, sensorId);
        if (newData && newData.length) {
            var updatedData = JSON.parse(JSON.stringify(graphData));
            newData = newData.reverse();
            if (newData.length > N_DATA_POINTS) {
                const step = Math.floor(newData.length / N_DATA_POINTS);
                newData = newData.filter((_, i) => i % step === 0);
            }
            updatedData.labels = newData.map(d => formatDateTimeLabel(getDateTime(d.time)));
            updatedData.datasets[0].data = newData.map(d => d.batteryLevel);
            if (componentMounted.current) {
                setGraphData(updatedData);
            }
        }
    }

    return <div>
        <LiveGraph
            data={graphData}
            chartID={props.sensorId}
            lineProps={{ label: 'Battery Level %', backgroundColor: theme.colors.yellowFever, borderColor: theme.colors.yellowFever }}
        />
    </div>
}

export default BatteryLevelGraphContainer;