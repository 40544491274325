const hierarchyTypes = {
    NONE: {
        value: 0,
        display: 'None'
    },
    MASTER: {
        value: 1,
        display: 'Master'
    },
    SLAVE: {
        value: 2,
        display: 'Slave'
    }
};

export default hierarchyTypes;