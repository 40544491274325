import Paper, { Point, Size, Rectangle, Group } from 'paper';
import furnitureTypes from '../../container/furnitureTypes';
import heatmapTypes from '../../container/heatmapTypes';
import BoundHandle from './BoundHandle';
import canvasController from '../CanvasController';

const FURNITURE_COLOR = "#333333"

class FurniturePiece {
    constructor(floorElementID, type, group, removeFurnitureHandler, deselectAllFurnitureHandler, layer, geometry = null, selected = false) {
        this.floorElementID = floorElementID;
        this.type = type;
        this.removeFurnitureHandler = removeFurnitureHandler;
        this.deselectAllFurnitureHandler = deselectAllFurnitureHandler;
        this.layer = layer;

        this.furniture = furnitureTypes[type];
        this.source = this.furniture.source;

        this.boundingRect = null;
        this.rotation = 0;
        if (geometry) {
            var bounds = geometry.bounds;
            this.boundingRect = new Rectangle(bounds.x, bounds.y, bounds.width, bounds.height);
            this.rotation = geometry.rotation;
        } else {
            var size = new Size(this.furniture.defaultSize, this.furniture.defaultSize);
            var point = Paper.view.center;
            var topLeftPoint = new Point(point.x - size.width / 2, point.y - size.height / 2);
            this.boundingRect = new Rectangle(topLeftPoint, size);
        }

        this.layer.activate();
        this.group = group.clone();
        this.group.rotate(this.rotation);
        this.group.fitBounds(this.boundingRect);
        this.group.fillColor = FURNITURE_COLOR;

        this.boundHandle = new BoundHandle(
            this.boundingRect,
            this.layer,
            (event) => this.onMouseDown(event),
            (event) => this.onMouseDrag(event),
            (event) => this.onMouseUp(event),
            (event, rect) => this.onScale(event, rect),
            () => this.onScaleEnd(),
            (rotation) => this.onRotate(rotation),
            () => this.onRotateEnd(),
            (event) => this.onDelete(event));

        if (selected) {
            this.activate();
            this.select();
        }
    }

    onMouseDown(event) {
        this.deselectAllFurnitureHandler();
        this.select();
    }

    onMouseDrag(event) {
        this.group.position = this.group.position.add(event.delta);
        canvasController.canvasCallback(heatmapTypes.actions.ADD_FURNITURE, this.toFloorElement());
    }

    onMouseUp(event) {
    }

    onScale(event, backgroundRect) {
        this.boundingRect = new Rectangle(backgroundRect);
        this.group.fitBounds(backgroundRect);
    }

    onScaleEnd() {
        canvasController.canvasCallback(heatmapTypes.actions.ADD_FURNITURE, this.toFloorElement());
    }

    onRotate(rotation, centerOfRotation = this.group.bounds.center) {
        this.group.rotate(rotation, centerOfRotation);
        this.rotation += rotation;
    }

    onRotateEnd() {
        this.boundingRect = this.group.bounds;
        this.boundHandle.updateBounds(this.boundingRect);
        this.boundHandle.select();
        canvasController.canvasCallback(heatmapTypes.actions.ADD_FURNITURE, this.toFloorElement());
    }

    onDelete(event) {
        this.remove();
        canvasController.canvasCallback(heatmapTypes.actions.REMOVE_FURNITURE, this.floorElementID);
    }

    toFloorElement() {
        var bounds = this.group.bounds;
        return {
            id: this.floorElementID,
            bounds: { x: bounds.x, y: bounds.y, width: bounds.width, height: bounds.height },
            rotation: this.rotation,
            type: this.type,
            source: this.source
        };
    }

    remove() {
        this.group.remove();
        this.group = null;
        this.removeFurnitureHandler();
    }

    select() {
        this.selected = true;
        this.group.bringToFront();
        this.boundHandle.select();
    }

    deselect() {
        this.selected = false;
        this.boundHandle.deselect();
    }

    activate() {
        this.boundHandle.activate();
        this.boundHandle.deselect();
    }

    deactivate() {
        this.selected = false;
        this.boundHandle.deactivate();
    }
}

export default FurniturePiece;