import { useState } from "react";
import { inviteUser } from "../../../../scripts/user";
import Button from "../../../UiComponents/Button";
import InputField from "../../../UiComponents/InputField";
import { ButtonRowContainer } from "../../../panel/ModalStyles";
import { SettingsParagraph, SettingsSection } from "../../settingsStyles";
import { loadingStates } from "../../../installation/installationTypes";

const UserInviteForm = props => {
    const [email, setEmail] = useState();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);
    const [error, setError] = useState('');

    const handleSendInvite = async () => {
        try {
            setLoadingState(loadingStates.LOADING);
            await inviteUser(email, props?.venue?.customerId);
            await props.refreshWithUpdate();
            setLoadingState(loadingStates.SUCCESS);
        } catch (error) {
            if (error.response?.status === 400) {
                setError('Invalid email. Try again.');
            } else if (error.response?.status === 409) {
                setError(`A user with email address ${email} already has access to this venue.`);
            } else {
                setError('Something went wrong. Try again later.')
            }
            setLoadingState(loadingStates.FAILED);
        } finally {
            if (props.onSubmit) {
                props.onSubmit();
            }
        }
    }

    const handleReset = () => {
        setError('');
        setLoadingState(loadingStates.NONE);
        setEmail('');
        if (props.onReset) {
            props.onReset();
        }
    }

    const handleClose = () => {
        handleReset();
        if (props.onClose) {
            props.onClose();
        }
    }

    return <>
        {loadingState === loadingStates.NONE ? <>
            <SettingsSection>
                <SettingsParagraph>Email</SettingsParagraph>
                <InputField value={email} onChange={(e) => setEmail(e.target.value)} />
            </SettingsSection>

            <SettingsSection>
                <ButtonRowContainer>
                    <Button primary onClick={() => handleSendInvite()}>Send invite</Button>
                    <Button secondary onClick={() => handleClose()}>Cancel</Button>
                </ButtonRowContainer>
            </SettingsSection>
        </> : <></>}

        {loadingState === loadingStates.LOADING ? <>
            <SettingsSection>
                <SettingsParagraph>Sending invite link...</SettingsParagraph>
            </SettingsSection>
        </> : <></>}

        {loadingState === loadingStates.SUCCESS ? <>
            <SettingsSection>
                <SettingsParagraph>An invitation has been sent to {email}.</SettingsParagraph>
            </SettingsSection>
        </> : <></>}

        {loadingState === loadingStates.FAILED ? <>
            <SettingsSection>
                <SettingsParagraph>{error}</SettingsParagraph>
            </SettingsSection>
        </> : <></>}

        {loadingState === loadingStates.SUCCESS || loadingState === loadingStates.FAILED ? <>
            <SettingsSection>
                <ButtonRowContainer>
                    <Button primary onClick={() => handleReset()}>{loadingState === loadingStates.SUCCESS ? 'Invite another user' : 'Try again'}</Button>
                    <Button secondary onClick={() => handleClose()}>Cancel</Button>
                </ButtonRowContainer>
            </SettingsSection>
        </> : <></>}
    </>
}

export default UserInviteForm;