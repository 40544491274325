import { useEffect } from "react";
import { useState } from "react";
import styled, { css } from "styled-components";
import theme from "../../../UI/theme";
import playFromTypes from "../../musicPlayer/playFromTypes";
import Button from "../../UiComponents/Button";
import Modal from "../../UiComponents/Modal"
import Selector from "../../UiComponents/Selector";
import { ModalContainer, ModalHeader } from "../ModalStyles";
import { hasVenuePermission, permissionTypes } from "../../permission/permissions";

const PlayFromItem = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 10px;
    background-color: ${theme.colors.darkSpace90};
    border: 1px solid transparent;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 2px;
    align-items: center;
    height: fit-content;

    ${props => props.selected && css`
        // background-color: ${theme.colors.greenEnergy};
        border-color: ${theme.colors.greenEnergy};
        color: ${theme.colors.greenReadable};
    `}
`

const PlayFromList = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;
    max-height: 400px;
    overflow: auto;
`

const PlayFromModal = props => {
    const availableTabs = resolveAvailableTabs(props.musicPlayer);
    const [selectedTab, setSelectedTab] = useState();
    const [playingFrom, setPlayingFrom] = useState(props.musicPlayer?.music?.playFrom?.id);

    useEffect(() => {
        setPlayingFrom(props.musicPlayer?.music?.playFrom?.id);
    }, [props.musicPlayer?.music?.playFrom?.id])

    useEffect(() => {
        if (!selectedTab) {
            setSelectedTab(availableTabs[0] ?? null);
        }
    }, [availableTabs])

    const changePlayList = async (playlist) => {
        if (!hasVenuePermission(props.userContext, permissionTypes.panelViewWrite)) {
            return;
        }
        let result = await props.musicPlayer?.playPlaylist(playlist?.id);
        if (result === true) {
            setPlayingFrom(playlist?.id);
        }
    }

    const queuePlaylist = (playlist) => {
        if (!hasVenuePermission(props.userContext, permissionTypes.panelViewWrite)) {
            return;
        }
        props.musicPlayer?.queuePlaylist(playlist?.id);
    }

    return <Modal {...props}>
        <ModalContainer>
            <ModalHeader>Change music</ModalHeader>

            {availableTabs?.length > 1 ?
                <Selector items={availableTabs} selectedItem={selectedTab} onClick={(tab) => setSelectedTab(tab)} />
                : <></>}

            <PlayFromList>
                {selectedTab?.key === playFromTypes.PLAYLIST.key ? <>
                    {props.musicPlayer?.music?.availablePlaylists?.map((playlist, index) => {
                        return <PlayFromItem key={index} selected={playingFrom === playlist?.id}>
                            <label>{playlist?.name}</label>
                            <Button small tertiary onClick={() => queuePlaylist(playlist)}>Queue</Button>
                            <Button small tertiary onClick={() => changePlayList(playlist)}>Play now</Button>
                        </PlayFromItem>
                    })}
                </> : <></>}

                {selectedTab?.key === playFromTypes.SCHEDULE.key ? <>
                    {props.musicPlayer?.music?.availableSchedules?.map((schedule, index) => {
                        return <PlayFromItem key={index} selected={playingFrom === schedule?.id}>
                            <label>{schedule?.name}</label>
                            <Button small tertiary onClick={() => queuePlaylist(schedule)}>Queue</Button>
                            <Button small tertiary onClick={() => changePlayList(schedule)}>Play now</Button>
                        </PlayFromItem>
                    })}
                </> : <></>}
            </PlayFromList>

        </ModalContainer>
    </Modal>
}

export default PlayFromModal;

const resolveAvailableTabs = (musicPlayer) => {
    let availableTabs = [];
    if (musicPlayer?.music?.availablePlaylists?.length > 0) {
        availableTabs.push({
            display: "Playlists",
            key: playFromTypes.PLAYLIST.key,
        });
    }

    if (musicPlayer?.music?.availableSchedules?.length > 0) {
        availableTabs.push({
            display: "Schedules",
            key: playFromTypes.SCHEDULE.key,
        })
    }

    return availableTabs;
}