import axios from 'axios';

// Default axios config
axios.defaults.withCredentials = true

// is reachable axios config
const IS_REACHABLE_AXIOS_TIMEOUT = 1000;
const isReachableAxiosInstance = axios.create();
isReachableAxiosInstance.defaults.timeout = IS_REACHABLE_AXIOS_TIMEOUT;

const hostType = process.env.REACT_APP_HOST_TYPE;
const remoteHostname = process.env.REACT_APP_HOSTNAME; 
const environment = process.env.REACT_APP_ENVIRONMENT;

let localHostName;
if (hostType === 'HUB' && environment !== 'LOCAL_APP_DEVELOPMENT') {
    localHostName =  `http://${window.location.hostname}:4000`;
    console.log('Got local hostname', localHostName);
}


const getHostname = () => {
    return localHostName || remoteHostname;
}

const isLocalApp = () => {
    return hostType === 'HUB';
}

const serverIsReachable = async () => {
    try {
        await isReachableAxiosInstance.get(`${getHostname()}/v2/appVersion`);
        return true;
    } catch (err) {
        return false;
    }
}


export {
    getHostname,
    isLocalApp,
    serverIsReachable
}

