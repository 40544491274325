import { useEffect, useState } from "react";
import theme from "../../../../../UI/theme";
import HeatmapView from "../../../../../views/HeatmapView"
import styled from "styled-components";

const Container = styled.div`
    padding: 10px;
    box-sizing: border-box;
`

const getFixedDimesions = (screenWidth, screenHeight) => {
    if (screenWidth < theme.screenSizes.large) {
        return {
            height: Math.round(screenHeight * 0.6),
            width: Math.round(screenWidth * 0.9)
        }
    } else {
        return null;
    }
}

const MapContainer = ({ customerId, selectedItem, setSelectedItem, screenWidth, hubs, processors, sensors, zones, addResizeHook }) => {
    const [fixedDimensions, setFixedDimensions] = useState(getFixedDimesions(screenWidth, window.innerHeight));

    useEffect(() => {
        const handleOrientationChange = () => {
            setTimeout(() => {
                const newDims = getFixedDimesions(window.innerWidth, window.innerHeight)
                setFixedDimensions(newDims);
            }, 100);
        };
        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        }
    }, [])

    return <Container>
        <HeatmapView
            customerId={customerId}
            hideBackButton={true}
            fixedWidth={fixedDimensions?.width}
            fixedHeight={fixedDimensions?.height}
            enableDocumentationMode={true}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            hubs={hubs}
            processors={processors}
            sensors={sensors}
            zones={zones}
            addResizeHook={addResizeHook}
        />
    </Container>
}

export default MapContainer;