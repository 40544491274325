import styled, { css } from "styled-components";
import theme from "../../../UI/theme";

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    &>label{
        cursor: inherit;
    }
`

const ChangeLabel = styled.label`
    color: ${theme.colors.darkSpace50};

    ${props => props.change > 0 && css`
        color: ${props.positiveDirectionality === true ? theme.colors.greenReadable : props.positiveDirectionality === false ? theme.colors.raspberryRed : ''};
    `}
    ${props => props.change < 0 && css`
        color: ${props.positiveDirectionality === false ? theme.colors.greenReadable : props.positiveDirectionality === true ? theme.colors.raspberryRed : ''};
    `}
`

const HealthMetricLabel = props => {
    const isRatio = props.metricType?.isRatio;
    let change;
    if (!isNaN(props.currentMetric) && props.currentMetric !== null && !isNaN(props.previousMetric) && props.previousMetric !== null) {
        change = props.currentMetric - props.previousMetric;
        if (isRatio === true) {
            change = change * 100;
        }
        change = change.toFixed(1);
    }

    return <Container>
        <label>
            {props.currentMetric !== null && !isNaN(props.currentMetric) ? isRatio === true ? `${(props.currentMetric * 100).toFixed(1)}%` : props.currentMetric : ''}
        </label>
        <ChangeLabel change={change} positiveDirectionality={props.metricType?.positiveDirectionality}>
            {change !== null && !isNaN(change) ? `${change >= 0 ? '+' : ''}${change}` : ''}
        </ChangeLabel>
    </Container>
}

export default HealthMetricLabel;