import React from 'react';
import Selector from '../../../UiComponents/Selector';
import zoneTypes from '../zoneTypes';
import styled from 'styled-components';

const Container = styled.div`
    margin: 12px;
`

const intervals = [{
    key: zoneTypes.intervals.LIVE,
    display: 'Live',
}, {
    key: zoneTypes.intervals.LAST_4_HOURS,
    display: '4 hours',
}, {
    key: zoneTypes.intervals.LAST_24_HOURS,
    display: '24 hours',
}];

const IntervalSelector = props => {

    var items = intervals;
    var selectedItem = items.find(item => item.key === props.selectedInterval);

    return <Container>
        <Selector items={items} selectedItem={selectedItem} onClick={(item) => props.setSelectedInterval(item.key)}/>
    </Container>
}

export default IntervalSelector;