import React from 'react';
import StringField from './StringField';

const NameField = ({object, updateObject, cellState, objects, idField, nameField}) => {

    const validate = (name) => {
        if (name && objects.filter(o => o[idField] !== object[idField]).map(o => o[nameField]?.toLowerCase()).includes(name.toLowerCase())) {
            return ({type: 'WARNING', message: 'Name already exists'});
        }
    }

    return <StringField
        value={object[nameField]}
        setValue={(value) => updateObject({ [nameField]: value })}
        error={validate(object[nameField])}
        cellState={cellState}
    />
}

export default NameField;