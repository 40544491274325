import { useState } from "react";
import { addZoneGroup } from "../../../../scripts/zoneGroup";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../panel/ModalStyles";
import Button from "../../../UiComponents/Button";
import InputField from "../../../UiComponents/InputField";
import Modal from "../../../UiComponents/Modal";

const NewZoneGroupModal = props => {
    const [zoneGroupName, setZoneGroupName] = useState('');

    const handleClose = async () => {
        setZoneGroupName('');
        props.close();
    }

    const handleCreate = async () => {
        await addZoneGroup({ customerId: props.customerId, name: zoneGroupName, orderIndex: props?.zoneGroups?.length ?? 0 });
        handleClose()
    }

    return <Modal {...props}>
        <ModalContainer>
            <ModalHeader>New group</ModalHeader>
            <ModalText>Name</ModalText>
            <InputField value={zoneGroupName} onChange={(e) => setZoneGroupName(e.target.value)} />
            <ButtonRowContainer>
                <Button small secondary onClick={() => handleClose()}>Cancel</Button>
                <Button small primary onClick={() => handleCreate()}>Create group</Button>
            </ButtonRowContainer>
        </ModalContainer>
    </Modal>
}

export default NewZoneGroupModal;