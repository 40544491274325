import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import serverConnection from "../../../../../scripts/server/ServerConnection";
import { loadingStates } from "../../../../installation/installationTypes";
import { networkInterfaces } from "../../../../setup/networkInterfaces";
import Button from "../../../../UiComponents/Button";
import LoadingSpinner from "../../../../UiComponents/LoadingSpinner";
import WiFiNetworkItem from "./WiFiNetworkItem";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`
const ListContainer = styled.div`
    max-height: 40vh;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
`

const WiFiNetworks = (props) => {
    const isMounted = useRef(false);
    const [state, setState] = useState(loadingStates.NONE);
    const [availableNetworks, setAvailableNetworks] = useState();
    const [selectedNetwork, setSelectedNetwork] = useState();
    const [connectedNetwork, setConnectedNetwork] = useState();
    const [networkConfig, setNetworkConfig] = useState();
    const [connectedToEthernet, setConnectedToEthernet] = useState(false);
    const [connectedToWiFi, setConnectedToWiFi] = useState(false);
    const ethernetTimeout = useRef(null);
    const wifiTimeout = useRef(null);
    const networkConfigTimeout = useRef(null);
    const connectedNetworkTimeout = useRef(null);

    useEffect(() => {
        isMounted.current = true;
        getAvailableNetworks();
        getConnectedNetwork();
        getNetworkConfig();
        checkEthernetConnection();
        checkWiFiConnection();

        return () => {
            clearTimeout(ethernetTimeout.current);
            clearTimeout(wifiTimeout.current);
            clearTimeout(networkConfigTimeout.current);
            clearTimeout(connectedNetworkTimeout.current);
            isMounted.current = false;
        }
    }, []);

    const getAvailableNetworks = async () => {
        try {
            setState(loadingStates.LOADING);
            const res = await serverConnection.hubEventHandler.sendEvent(
                props.hub?.hubId ?? props.hubId,
                props.hub?.customerId,
                'GET_AVAILABLE_WIFI_NETWORKS',
                null,
                20000,
                true
            );

            setAvailableNetworks(res);
            setState(loadingStates.SUCCESS);
        } catch (err) {
            setAvailableNetworks(null);
            setState(loadingStates.FAILED);
        }
    }

    const getConnectedNetwork = async () => {
        try {
            const res = await serverConnection.hubEventHandler.sendEvent(
                props.hub?.hubId ?? props.hubId,
                props.hub?.customerId,
                'GET_CONNECTED_WIFI_NETWORK',
                null,
                5000,
                true
            );
            setConnectedNetwork(res?.ssid);
        } catch (error) {
            setConnectedNetwork(null);
        }

        clearTimeout(connectedNetworkTimeout.current);
        if (isMounted.current === true) {
            connectedNetworkTimeout.current = setTimeout(async () => {
                await getConnectedNetwork();
            }, 2000);
        }
    }

    const getNetworkConfig = async () => {
        const networkConfigResult = await serverConnection.hubEventHandler.sendEvent(
            props.hub?.hubId ?? props.hubId,
            props.hub?.customerId,
            'GET_NETWORK_CONFIG',
            null,
            20000,
            true
        );

        setNetworkConfig(networkConfigResult);

        clearTimeout(networkConfigTimeout.current);
        if (isMounted.current === true) {
            networkConfigTimeout.current = setTimeout(async () => {
                await getNetworkConfig();
            }, 5000);
        }
    }

    const checkEthernetConnection = async () => {
        try {
            const connectionStatus = await serverConnection.hubEventHandler.sendEvent(
                props.hub?.hubId ?? props.hubId,
                props.hub?.customerId,
                'CHECK_INTERNET_CONNECTION_STATE',
                { interfaceName: networkInterfaces.ETHERNET.interfaceName, preventEventLog: true },
                20000,
                true
            );
            setConnectedToEthernet(connectionStatus.connected);
        } catch (error) {
            setConnectedToEthernet(false);
        }

        clearTimeout(ethernetTimeout.current);
        if (isMounted.current === true) {
            ethernetTimeout.current = setTimeout(async () => {
                await checkEthernetConnection();
            }, 2000);
        }
    }

    const checkWiFiConnection = async () => {
        try {
            const connectionStatus = await serverConnection.hubEventHandler.sendEvent(
                props.hub?.hubId ?? props.hubId,
                props.hub?.customerId,
                'CHECK_INTERNET_CONNECTION_STATE',
                { interfaceName: networkInterfaces.WIFI.interfaceName, preventEventLog: true },
                20000,
                true
            );
            setConnectedToWiFi(connectionStatus.connected);
        } catch (error) {
            setConnectedToWiFi(false);
        }

        clearTimeout(wifiTimeout.current);
        if (isMounted.current === true) {
            wifiTimeout.current = setTimeout(async () => {
                await checkWiFiConnection();
            }, 2000);
        }
    }

    const handleConnecting = async () => {
        if (props.onConnecting) {
            props.onConnecting();
        }
    }

    const handleConnect = async (ssid) => {
        setConnectedNetwork(ssid);

        if (props.onConnect) {
            props.onConnect(ssid);
        }
    }

    const handleDisconnect = async () => {
        setConnectedNetwork(null);

        if (props.onDisconnect) {
            props.onDisconnect();
        }
    }

    return <Container>

        {state === loadingStates.LOADING ? <>
            <LoadingSpinner />
        </> : <></>}

        {state === loadingStates.SUCCESS ? <>
            {availableNetworks.length ? <>
                <ListContainer>
                    {availableNetworks?.map((network, ix) => {
                        return <WiFiNetworkItem
                            key={ix}
                            hub={props.hub}
                            network={network}
                            onClick={() => setSelectedNetwork(network?.ssid)}
                            selected={network.ssid === selectedNetwork}
                            connected={connectedNetwork === network?.ssid && connectedToWiFi}
                            networkConfig={networkConfig}
                            connectedToEthernet={connectedToEthernet}
                            onConnecting={() => handleConnecting()}
                            onConnect={(ssid) => handleConnect(ssid)}
                            onDisconnect={() => handleDisconnect()}
                        />
                    })}
                </ListContainer>
            </> : <>
                <label>No networks found</label>
            </>}
        </> : <></>}

        <div>
            <Button
                disabled={state === loadingStates.LOADING}
                tertiary
                small
                onClick={() => getAvailableNetworks()}>
                {state === loadingStates.LOADING ? 'Scanning' : 'Rescan'}
            </Button>
        </div>

    </Container>
}

export default WiFiNetworks;