import { Link } from "react-router-dom";
import styled from "styled-components";
import theme from "../../UI/theme";

export const ModalContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    width: 100%;

    @media only screen and (max-width: ${theme.screenSizes.small - 1}px) {
        width: 85vw;
    }
    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        min-width: 500px;
    }
`

export const ModalHeader = styled.label`
    font-size: 1.5rem;
`

export const ModalText = styled.label`
    color: ${theme.colors.textGray};
    cursor: inherit;
`

export const RowContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`

export const ModalLink = styled(Link)`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    color: ${theme.colors.textGray};
    cursor: pointer;
    box-sizing: border-box;
    background-color: ${theme.colors.darkSpace90};
    border: 1px solid transparent;
    padding: 5px;

    @media (hover:hover) {
        &:hover {
            border-color: ${theme.colors.textGray};
        }
    }
`

export const ButtonRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
        margin-bottom: 10px;
    }

    > :not(:last-child) {
        margin-right: 10px;
    }

    @supports(gap: 10px) {
        gap: 10px;

        > * {
            margin-bottom: 0px;
        }
    
        > :not(:last-child) {
            margin-right: 0px;
        }
    }
`