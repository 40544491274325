import { useContext, useState } from "react";
import { postEvent } from "../../../../../scripts/event";
import Button from "../../../../UiComponents/Button";
import Modal from "../../../../UiComponents/Modal";
import { FieldButton } from "../../../fields/styling/FieldStyles";
import { ButtonRowContainer, ModalContainer, ModalHeader } from "../../../../panel/ModalStyles";
import RemoteConnection from "../actions/RemoteConnection";
import { isLocalApp } from "../../../../../scripts/server/server";
import { hasRole, roles } from "../../../../permission/permissions";
import UserContext from "../../../../../hooks/UserContext";


const SystemActionsField = ({ hub }) => {
    const [showModal, setShowModal] = useState(false);
    const userContext = useContext(UserContext);

    const performAction = async (command) => {
        try {
            await postEvent(hub.hubId, hub.customerId, command);
            setShowModal(false);
        } catch (err) {
            console.log('Failed to perform action');
        }
    }

    return <>
        <FieldButton small tertiary onClick={() => setShowModal(true)}>System actions</FieldButton>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>System actions</ModalHeader>
                <ButtonRowContainer>
                    <Button secondary onClick={() => performAction('RESTART_APP')}>Restart program</Button>
                    <Button secondary onClick={() => performAction('REBOOT')}>Reboot</Button>
                    <Button secondary onClick={() => performAction('REBOOT_HAT')}>Reboot hat</Button>
                </ButtonRowContainer>

                {!isLocalApp() && hasRole(userContext, roles.admin) ? <>
                    <div>
                        <h4>Remote ssh connection</h4>
                        <RemoteConnection hub={hub} />
                    </div>
                </> : <></>}
            </ModalContainer>
        </Modal>
    </>
}

export default SystemActionsField;