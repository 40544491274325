import Paper from "paper";
import heatmapTypes from '../container/heatmapTypes';
import FloorLayerController from "./floor/FloorLayerController";
import SensorLayerController from './sensor/SensorLayerController';
import FurnitureLayerController from "./furniture/FurnitureLayerController";
import GridLayerController from "./GridLayerController";
import canvasController from './CanvasController';
import ZoneLayerController from "./zone/ZoneLayerController";
import DataLayerController from './DataLayerController';
import HubLayerController from "./hub/HubLayerController";
import ProcessorLayerController from "./processor/ProcessorLayerController";
import { addPanEvents, initHeatmap, setZoomAndCenter } from "./heatmapUtils";
import { getOuterWallElement } from "./floor/wallUtils";

const heatmap = (props) => {

  // Global properties
  var activeTool = heatmapTypes.tools.NONE;
  canvasController.init(props);

  const canvasSize = Paper.view.viewSize;
  const gridLayerController = new GridLayerController(canvasSize);
  const floorLayerController = new FloorLayerController();
  const zoneLayerController = new ZoneLayerController();
  const furnitureLayerController = new FurnitureLayerController();
  const sensorLayerController = new SensorLayerController();
  const hubLayerController = new HubLayerController();
  const processorLayerController = new ProcessorLayerController();
  const dataLayerController = new DataLayerController();
  dataLayerController.layer.insertBelow(floorLayerController.layer);
  sensorLayerController.dataLayerController = dataLayerController;

  canvasController.layerControllers = {
    grid: gridLayerController, 
    data: dataLayerController, 
    floor: floorLayerController, 
    furniture: furnitureLayerController, 
    zone: zoneLayerController, 
    hub: hubLayerController, 
    sensor: sensorLayerController,
    processor: processorLayerController
  };

  
  canvasController.stackLayers();

  const view = Paper.view;
  initHeatmap(view);


  async function selectFloorHandler(floorData) {
    await canvasController.onFloorChange(floorData);
    const outerWallElement = getOuterWallElement(floorData);
    setZoomAndCenter(outerWallElement);
    if (floorData.activeMode) {
      selectModeHandler(floorData.activeMode);
    }
    if (floorData.activeMode === heatmapTypes.modes.SETUP) {
      setActiveTool({ toolName: activeTool });
    }
  }

  async function selectModeHandler(modeName) {
    canvasController.deactivateAllLayers();
    addPanEvents();
    canvasController.onModeChange(modeName);
    sensorLayerController.activeMode = modeName;
  }

  function setActiveTool(floorData) {
    const tool = floorData.toolName;
    canvasController.deactivateAllLayers();
    canvasController.onToolSelect(tool, floorData);
  }

  function canvasAction(type, data) {
    switch (type) {
      case heatmapTypes.actions.TOOL_SELECT:
        setActiveTool(data);
        return;

      case heatmapTypes.actions.PLACE_DEVICE:
        canvasController.getDeviceLayerController(data.type)?.placeDevice(data);
        return;

      case heatmapTypes.actions.REMOVE_DEVICE:
        canvasController.getDeviceLayerController(data.type)?.removeDevice(data);
        return;

      case heatmapTypes.actions.MARK_DEVICE:
        canvasController.getDeviceLayerController(data.type)?.markDevice(data);
        return;

      case heatmapTypes.actions.UNMARK_ALL_DEVICES:
        canvasController.unmarkAllDevices();
        return;

      case heatmapTypes.actions.FLOOR_SELECT:
        selectFloorHandler(data);
        return;

      case heatmapTypes.actions.MODE_SELECT:
        selectModeHandler(data);
        return;

      case heatmapTypes.actions.WALL_SELECT:
        canvasController.layerControllers.floor.selectWallHandler(data);
        return;

      case heatmapTypes.actions.WALL_DESELECT:
        canvasController.layerControllers.floor.deselectWallHandler(data);
        return;

      case heatmapTypes.actions.DESELECT_ALL_WALLS:
        canvasController.layerControllers.floor.deactivateAllWalls();
        return;

      case heatmapTypes.actions.ADD_INNER_WALL:
        canvasController.layerControllers.floor.addInnerWall(data);
        return;

      case heatmapTypes.actions.ADD_OUTER_WALL:
        canvasController.layerControllers.floor.addOuterWall(data);
        return;

      case heatmapTypes.actions.COMPLETE_INNER_WALL:
        canvasController.layerControllers.floor.completeInnerWall(data);
        return;

      case heatmapTypes.actions.DELETE_WALL:
        canvasController.layerControllers.floor.deleteWall(data);
        return;

      case heatmapTypes.actions.PLACE_ZONE:
        zoneLayerController.placeZone(data);
        return;

      case heatmapTypes.actions.REMOVE_ZONE:
        zoneLayerController.removeZone(data);
        addPanEvents();
        return;

      case heatmapTypes.actions.ZONE_SELECT:
        zoneLayerController.selectZone(data);
        return

      case heatmapTypes.actions.DESELECT_ALL_ZONES:
        zoneLayerController.deselectAllZones(data);
        addPanEvents();
        return;

      case heatmapTypes.actions.ADD_FURNITURE:
        furnitureLayerController.addFurniture(data);
        return;

      case heatmapTypes.actions.SENSOR_DATA:
        sensorLayerController.showSensorData(data);
        return;

      case heatmapTypes.actions.ZONE_DATA:
        zoneLayerController.showZoneData(data);
        return;

      case heatmapTypes.actions.SET_DATA_MODE:
        sensorLayerController.setDataMode(data);
        return;

      case heatmapTypes.actions.SET_DATA_TYPE:
        sensorLayerController.setDataType(data);
        return;

      case heatmapTypes.actions.CANVAS_CLEANUP:
        canvasController.canvasCleanup();
        return;

      case heatmapTypes.actions.DEVICE_UPDATE:
        canvasController.handleDeviceUpdate(data);
        return;

      default:
        return;
    }
  }

  selectFloorHandler({ activeFloor: props.activeFloor, floorElements: props.floorElements }).then(res => {
    selectModeHandler(props.activeMode || heatmapTypes.modes.DATA);
    sensorLayerController.setDataMode(heatmapTypes.dataModes.LIVE);
  });

  return canvasAction;
};

export default heatmap;