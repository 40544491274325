import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/app';

const getLocalAppVersion = async () => {
    const res = await axios.get(`${hostname}${path}/localAppVersion`);
    return res.data;
}

const buildLocalApp = async () => {
    const res = await axios.put(`${hostname}${path}/buildLocalApp`);
    return res.data;
}

export {
    getLocalAppVersion,
    buildLocalApp
}