import React from 'react';
import AddressField from '../../../fields/AddressField';
import { getProtocol } from '../../protocol/protocolUtils';

const MuteAddressField = ({ zone, updateZone, zones, processors, cellState }) => {
    const processor = processors.find(processor => processor.processorId === zone.processorId); 
    const Protocol = getProtocol(processor?.type);

    const validate = (address) => {
        const processorZones = zones.filter(z => z.processorId === zone.processorId); 
        const addressesOnSameProcessor = processorZones.filter(z => z.zoneId !== zone.zoneId).map(z => z.muteAddress?.toLowerCase());
        if (address && addressesOnSameProcessor.includes(address?.toLowerCase())) {
            return ({type: 'WARNING', message: 'Multiple zones with same address on same processor'});
        } else if (address && zones.map(z => z.objectAddress?.toLowerCase()).includes(address?.toLowerCase())) {
            return ({type: 'WARNING', message: 'Used as objectadress'})
        } else {
            return Protocol.validateObjectAddress(address);
        }
    }

    const disabled = !Protocol.props.muteAddress;

    return <AddressField
        processorId={zone.processorId}
        hubId={zone.hubId || processor?.hubId}
        type={'MUTE'}
        address={!disabled ? zone.muteAddress : 'N/A'}
        customerId={zone.customerId || processor?.customerId}
        setAddress={(address) => updateZone({ muteAddress: address })}
        error={validate(zone.muteAddress)}
        disabled={disabled}
        cellState={cellState}
    />
}

export default MuteAddressField;

export const hideMuteAddressField = (zones, processors) => {
    const processorIds = [...new Set(zones?.map(zone => zone.processorId))];
    for (let i = 0; i < processorIds.length; i++) {
        const processor = processors.find(processor => processor.processorId === processorIds[i]);
        const Protocol = getProtocol(processor?.type);
        if (Protocol.props.muteAddress) {
            return false;
        }
    }
    return true;
}
