import axios from 'axios';
import { postEvent } from '../../../../scripts/event'

const singleSpeakerSystems = [
  'SONOS',
  'GENELEC',
];

const action = {
  fetchSpeakers: 'SCAN_FOR_IP_SPEAKERS',
  muteSpeaker: 'MUTE_IP_SPEAKER',
  unmuteSpeaker: 'UNMUTE_IP_SPEAKER',
  updateIpSpeakerMuteState: 'UPDATE_IP_SPEAKER_MUTE_STATE',
  blinkSpeaker: 'BLINK_IP_SPEAKER',
}

async function fetchSpeakers(customerId, hubs, processors) {
  var speakerBrands = [];
  processors.forEach(processor => {
    speakerBrands.push(processor.type);
  });
  hubs.forEach(hub => {
    postEvent(hub.hubId, customerId, action.fetchSpeakers, {speakerBrands: speakerBrands});
  });
}

function getSingleSpeakerZones(zones, processors){
  var verifiedZones = [];
  zones.forEach(zone => {
    var zoneProcessor = processors.find(processor => processor.processorId === zone.processorId);
    if (singleSpeakerSystems.includes(zoneProcessor?.type)){
      verifiedZones.push(zone);
    }
  });
  return verifiedZones;
}

function muteSpeakers(speakers, hubs) {
  hubs.forEach(hub => {
    postEvent(hub.hubId, hub.customerId, action.muteSpeaker, {speakers: speakers});
  });
}

function unmuteSpeakers(speakers, hubs) {
  hubs.forEach(hub => {
    postEvent(hub.hubId, hub.customerId, action.unmuteSpeaker, {speakers: speakers});
  });
}

function updateIpSpeakerMuteStates(speakers, hubs){ // When and how to use this?
  hubs.forEach(hub => {
    postEvent(hub.hubId, hub.customerId, action.updateIpSpeakerMuteState, {speakers: speakers});
  });
}

function blinkSpeakers(speakers, hubs){
  hubs.forEach(hub => {
    postEvent(hub.hubId, hub.customerId, action.blinkSpeaker, {speakers: speakers});
  });
}

export {
  fetchSpeakers,
  getSingleSpeakerZones,
  muteSpeakers,
  unmuteSpeakers,
  updateIpSpeakerMuteStates,
  blinkSpeakers,
  singleSpeakerSystems,
}