import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/networkScanner';

async function downloadFile(filename) {
    const res = await axios.get(`${hostname}${path}/downloadFile?filename=${filename}`, { responseType: 'blob' });
    const href = URL.createObjectURL(res.data);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', filename); 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return;
}

async function getAvailableDownloads(name) {
    const res = await axios.get(`${hostname}${path}/availableDownloads?name=${name}`);
    return res.data;
}



export {
    downloadFile,
    getAvailableDownloads
}