import { Chart } from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import theme from '../../../../UI/theme';
import { balanceTypes } from '../balanceTypes';

const chartData = {
    labels: [],
    datasets: [
        {
            type: 'pie',
            data: [],
            borderWidth: 0,
            hoverOffset: 5
        },
    ]
}

const chartConfig = {
    type: 'pie',
    data: chartData,
    options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                labels: {
                    boxWidth: 10,
                    boxHeight: 10
                }
            }
        }
    }
}

const ZoneBalancePieGraph = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    if (chartInstance) {
        chartInstance.update();
    }

    if (props.data && props.data.zoneLogs) {
        chartConfig.data.datasets[0].data = [];
        chartConfig.data.datasets[0].backgroundColor = [];
        chartConfig.data.labels = [];
        const totalLength = props.data.zoneLogs.length;
        Object.keys(balanceTypes).forEach((type, index) => {
            chartConfig.data.datasets[0].data.push(100*(props.data.zoneLogs.filter(log => log.balance === balanceTypes[type].key).length/totalLength));
            chartConfig.data.datasets[0].backgroundColor.push(balanceTypes[type].color);
            chartConfig.data.labels.push(balanceTypes[type].display);
        });
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfig);
            setChartInstance(newChartInstance);
        }

        return () => {
            chartConfig.data.datasets.forEach(dataset => dataset.data = []);
        }
    }, [chartContainer]);

    return <canvas ref={chartContainer} style={{ height: '100%', width: '100%', maxWidth: '100%' }} ></canvas>
}

export default ZoneBalancePieGraph;