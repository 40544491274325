import React from 'react';
import { InfoContainer, InfoRow } from '../../../styling/DeviceStyles';

const BatteryDisplayField = ({ sensor }) => {

    return <InfoContainer>
        <h4>Battery</h4>
        <InfoRow>
            <div>{sensor.batteryLevel}%</div>
            <div>{sensor.batteryTemperature}°C</div>
            <div>{sensor.externalVoltage}V</div>
        </InfoRow>
    </InfoContainer>
}

export default BatteryDisplayField;