import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import theme from '../../../../UI/theme';
import { FaChevronUp } from "react-icons/fa";


const ContainerWrapper = styled.div`

    @media only screen and (max-width: ${theme.screenSizes.medium}px) {
        position: fixed;
        bottom: 0;
        z-index: 50;
        width: 100%;
    }
`

const ContentWrapper = styled.div`
    
    @media only screen and (max-width: ${theme.screenSizes.medium}px) {
        padding: 10px;
        background-color: white;
        height: ${props => props.hide ? '0' : '350px'};
        transition: 0.2s linear;
        overflow: auto;
    }
`

const HideButton = styled.button`
    svg {
        transition: transform 0.2s; 
        transform: rotate(${props => props.showState ? '180deg' : '0deg'});
    }
    
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    font-size: 16px;
    color: white;
    background-color: ${theme.colors.activeBlue};
`
const ButtonContainer = styled.div`
    padding: 15px;
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        display: none;
    }
`



const StyledContainer = props => {
    const [show, setShow] = useState(window.innerWidth > theme.screenSizes.medium); // todo: make dynamic

    return <ContainerWrapper>
        <ButtonContainer>
            <HideButton showState={show} onClick={() => setShow(s => !s)}>
                <FaChevronUp />
            </HideButton>
        </ButtonContainer>

        <ContentWrapper hide={!show}>
            {props.children}
        </ContentWrapper>

    </ContainerWrapper>
}

export default StyledContainer;