import styled from "styled-components";
import { toDisplayTime } from "../../../../scripts/common";
import Button from "../../../UiComponents/Button";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import Modal from "../../../UiComponents/Modal";
import { useState } from "react";
import OpeningHoursForm from "./OpeningHoursForm";

const Container = styled.div`
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 10px;
    align-items: center;
    margin: 5px 0;
`

const EditButton = styled(Button)`
    padding: 5px 5px;
`

const OpeningHoursItem = props => {
    const [showEditModal, setShowEditModal] = useState(false);

    return <>
        <Container>
            <span>{props.day}</span>

            {props.open ?
                <TextMuted>
                    {props.start !== null && props.end !== null ? `${toDisplayTime(props.start)} - ${toDisplayTime(props.end)}` : 'No opening hours'}
                </TextMuted>
                : <TextMuted>Closed</TextMuted>}

            <EditButton tertiary disabled={!props.editable} onClick={() => setShowEditModal(true)}>Edit</EditButton>
        </Container>

        <Modal show={showEditModal} close={() => setShowEditModal(false)}>
            <OpeningHoursForm
                {...props}
                onSave={(newHours) => { props.onSave(newHours); setShowEditModal(false); }}
                onCancel={() => setShowEditModal(false)} />
        </Modal>
    </>
}

export default OpeningHoursItem;