import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const path = '/v2/support';

async function getCustomerErrors() {
    const res = await axios.get(`${hostname}${path}/customerErrors`);
    return res.data;
}

async function getCustomerUseSummaries() {
    const res = await axios.get(`${hostname}${path}/customerUseSummaries`);
    return res.data;
}


export {
    getCustomerErrors,
    getCustomerUseSummaries
}