import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { BarIcon, SettingsIcon } from '../UiComponents/Icons';
import theme from '../../UI/theme';
import { Container, StyledLink, ItemTitle, InnerContainer, TopPlaceholder, StatusBanner } from './MenuBarStyles';
import Impersonate from '../admin/impersonate/Impersonate';
import EnvironmentTag from './EnvironmentTag';
import ConnectionStatus from './ConnectionStatus';
import { FaSolarPanel,  } from 'react-icons/fa';
import NewBadge from './NewBadge';
import UserContext from '../../hooks/UserContext';
import { HubIcon } from '../installation/styling/Icons';
import { MdBuild, MdLocationOn } from "react-icons/md";
import { isMobile } from '../../scripts/common';
import { hasRole, roles } from '../permission/permissions';
import VenuesMenuItem from './VenuesMenuItem';
import HomeIcon from '../UiComponents/Icons/styledReactIcons/HomeIcon';
import HelpIcon from '../UiComponents/Icons/styledReactIcons/HelpIcon';

const menuItems = [{
    name: 'home',
    displayName: 'Home',
    path: '/',
    icon: HomeIcon
}, {
    name: 'insights',
    displayName: 'Insights',
    path: '/insights',
    icon: BarIcon
}, {
    name: 'settings',
    displayName: 'Settings',
    path: '/settings',
    icon: SettingsIcon
}, {
    name: 'support',
    displayName: 'Support',
    path: '/support',
    icon: HelpIcon,
}, {
    name: 'installation',
    displayName: 'Installation',
    hide: (userContext) => !hasRole(userContext, roles.admin),
    path: '/installation',
    icon: HubIcon,
    badge: () => <NewBadge text={'Adm'} />
}, {
    name: 'tools',
    displayName: 'Tools',
    hide: (userContext) => !hasRole(userContext, roles.impersonator),
    path: '/supportcenter',
    icon: MdBuild,
    badge: () => <NewBadge text={'Adm'} />
}];



const MenuBar = props => {
    const [markEnvironment, setMarkEnvironment] = useState(true);
    const location = useLocation();
    const userContext = useContext(UserContext);

    const mobile = isMobile();
    return <>
        <Container backgroundColor={getBackgroundColor(props.env, markEnvironment)}>

            {menuItems.map((item, index) => {
                var Icon, Badge;
                if (item.icon) {
                    Icon = item.icon;
                }
                if (item.badge) {
                    Badge = item.badge;
                }
                if (item.hide && item.hide(userContext)) {
                    return <></>
                }
                return <StyledLink to={item.path} key={index}>
                    <InnerContainer>
                        {item.icon ? <>
                            <Icon color={isActive(location.pathname, item.path) ? theme.colors.greenEnergy : theme.colors.textGray} />
                        </> : <></>}
                        <ItemTitle>
                            {item.displayName}
                        </ItemTitle>
                        {item.badge && !mobile ? <>
                            <Badge />
                        </> : <></>}
                    </InnerContainer>
                </StyledLink>
            })}

            {props.venues.length > 1 ? <>
                <VenuesMenuItem
                    venues={props.venues}
                    user={props.user}
                    customer={userContext.customer}
                    setCustomer={props.setCustomer}
                />
            </> : <></>}

            {props.env === 'DEVELOPMENT' && markEnvironment ? <>
                <EnvironmentTag display={'DEV'} markEnvironment={markEnvironment} setMarkEnvironment={setMarkEnvironment} />
            </> : <></>}

            {hasRole(userContext, roles.impersonator) ? <>
                <Impersonate user={props.user} customer={props.customer} setCustomer={props.setCustomer} />
            </> : <></>}

            <StatusBanner>
                <ConnectionStatus />
            </StatusBanner>

        </Container>

        <TopPlaceholder />
    </>
}

export default MenuBar;

const isActive = (currentPath, itemPath) => {
    if (itemPath.length === 1) {
        return currentPath === itemPath;
    } else {
        return currentPath.slice(0, itemPath.length) === itemPath;
    }
}

const getBackgroundColor = (env, markEnvironment) => {
    if (env === 'DEVELOPMENT' && markEnvironment) {
        return theme.colors.blueMonday;
    } else {
        return null;
    }
}