import axios from "axios";
import { getHostname } from "./server/server";

const hostname = getHostname();
const path = '/v2/zoneGroup';

async function getZoneGroups(customerId) {
    const res = await axios.get(`${hostname}${path}/zoneGroups?customerId=${customerId}`);
    return res.data;
}

async function getZoneGroup(zoneGroupId) {
    const res = await axios.get(`${hostname}${path}?zoneGroupId=${zoneGroupId}`);
    return res.data;
}

async function updateZoneGroup(zoneGroup) {
    const res = await axios.put(`${hostname}${path}`, zoneGroup);
    return res.data;
}

async function addZoneGroup(zoneGroup) {
    const res = await axios.post(`${hostname}${path}`, zoneGroup);
    return res.data;
}

async function deleteZoneGroup(zoneGroupId) {
    const res = await axios.delete(`${hostname}${path}?zoneGroupId=${zoneGroupId}`);
    return res.data;
}

export {
    getZoneGroups,
    getZoneGroup,
    updateZoneGroup,
    addZoneGroup,
    deleteZoneGroup
}