import styled, { css } from "styled-components";
import theme from "../../../../UI/theme";
import Button from "../../../UiComponents/Button";

export const FieldButton = styled(Button)`
    font-size: 12px;
    padding: 6px;
    background-color: ${theme.colors.darkSpace80};
    border: none;
    color: ${props => (props.autoTest==='FAILED' || props.autoTest===3) ? theme.colors.raspberryRed : theme.colors.greenEnergy};
`

export const MainContainer = styled.div`
    display: flex;
    height: 40px;

    ${props => props.active && css`
        background-color: ${theme.colors.darkSpace90};
    `}
    >div {
        margin-right: 6px;
    }
`

export const ResultContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.darkSpace90};
    padding: 4px;
    color: ${theme.colors.textGray};
`

export const ResultValue = styled.span`
    svg {
        margin-right: 4px;
    }
`