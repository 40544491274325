import { useEffect } from "react";
import { useState } from "react";
import { FaSave } from "react-icons/fa";
import { postUserInteraction } from "../../../../../scripts/userInteraction";
import { updateZoneCalibration } from "../../../../../scripts/zone";
import { userInteractionTypes } from "../../../../admin/customerDetails/userInteractionTypes";
import PanelButton from "../../../../panel/PanelButton";
import VolumeSlider from "../../../../panel/VolumeSlider";
import { MasterCalibationSliderContainer, MasterCalibrationControlsContainer, VolumeMetric } from "../calibrationStyles";

const MasterCalibrationControls = props => {
    const [systemVolume, setSystemVolume] = useState(!isNaN(props?.zoneLive?.sysvolAbsolute) ? props?.zoneLive?.sysvolAbsolute : null);
    const [lastVolumeCommit, setLastVolumeCommit] = useState(0);
    const [isChangingVolume, setIsChangingVolume] = useState(false);

    useEffect(() => {
        if (isChangingVolume === false && (new Date()).getTime() - lastVolumeCommit >= 2000) {
            setSystemVolume(!isNaN(props?.zoneLive?.sysvolAbsolute) ? props?.zoneLive?.sysvolAbsolute : null);
        }
    }, [props?.zoneLive?.sysvolAbsolute])

    const onSaveHandler = async () => {
        try {
            const oldFixedMasterVolume = props.zone?.fixedMasterVolume;

            await updateZoneCalibration({
                    zoneId: props.zone.zoneId,
                    hubId: props.zone.hubId,
                    fixedMasterVolume: systemVolume
                });

            postUserInteraction(props.zone.customerId, {
                zoneId: props.zone.zoneId,
                setting: userInteractionTypes.calibrate.key,
                fromValue: oldFixedMasterVolume ?? 0,
                toValue: systemVolume
            });
        } catch (error) {
        }
    }

    return <MasterCalibrationControlsContainer>
        <VolumeMetric color="white">
            <label>Volume</label>
            <label>{systemVolume}</label>
        </VolumeMetric>
        <MasterCalibationSliderContainer>

            <VolumeSlider
                disabled={props.zone?.isRegulating && props.isRegulatingAudioSource}
                volume={systemVolume ?? 0}
                zone={props.zone}
                onChange={(newValue) => setSystemVolume(newValue)}
                onChangeStarted={() => setIsChangingVolume(true)}
                onChangeCommitted={() => setIsChangingVolume(false)}
                lastVolumeCommit={lastVolumeCommit}
                setLastVolumeCommit={setLastVolumeCommit}
            />

            {props.zone?.fixedMasterVolume !== null ?
                <label
                    className="calibrationPointLabel"
                    style={{ left: `calc((100% - var(--width))*(${props.zone?.fixedMasterVolume}/100))` }}>
                    {props.zone?.fixedMasterVolume?.toFixed()}
                </label>
                : <></>}

        </MasterCalibationSliderContainer>
        <PanelButton onClick={() => onSaveHandler()}>
            <FaSave />
            <label>Save</label>
        </PanelButton>
    </MasterCalibrationControlsContainer>
}

export default MasterCalibrationControls;