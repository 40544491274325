import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import styled from "styled-components";
import TopNavigation from "../components/navigation/TopNavigation";
import ViewHeader from "../components/UiComponents/ViewHeader";
import Card from "../components/UiComponents/Card";
import { getZones } from "../scripts/zone";
import { MemoizedZoneLogGraph } from "../components/admin/zoneDetails/graph/ZoneLogGraph";
import ZoneBalancePieGraph from "../components/admin/zoneDetails/graph/ZoneBalancePieGraph";
import theme from "../UI/theme";
import { DateTime, Interval } from "luxon";
import { getDateTime, getZoneLogs } from "../scripts/log";
import { getSchedules } from "../scripts/schedules";
import { getOpeningHours } from "../scripts/datasource";
import { getOpeningHoursWithinInterval } from "../components/statistics/charts/chartUtils";
import DatePicker from "../components/admin/zoneDetails/DatePicker";
import { getUserInteractions } from "../scripts/userInteraction";
import Dropdown from "../components/UiComponents/Dropdown";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 100px;
`

const ParameterContainer = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
`

const ZoneLogGraphContainer = styled.div`
    width: 100%;
    height: 65vh;
`

const ZoneBalanceContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

const ZoneBalancePieContainer = styled.div`
    width: fill-available;
    aspect-ratio: 1;
    max-width: 40vh;
    justify-self: center;
`

const ZoneDetailsView = props => {
    const [zones, setZones] = useState([]);
    const [zone, setZone] = useState();
    const [zoneLogs, setZoneLogs] = useState();
    const [userInteractions, setUserInteractions] = useState();
    const [schedules, setSchedules] = useState();
    const [openingHours, setOpeningHours] = useState();
    const [fromDateTime, setFromDateTime] = useState(DateTime.utc().minus({ hours: 4, minutes: 59 }).startOf('minute').toJSDate())
    const [toDateTime, setToDateTime] = useState(DateTime.utc().startOf('minute').toJSDate())
    const [detailedValues, setDetailedValues] = useState(false);
    const [zoomLimits, setZoomLimits] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { customerId, zoneId: initialZoneId } = useParams();
    const [zoneId, setZoneId] = useState(initialZoneId);

    useEffect(() => {
        loadZones();
        getData();

        return () => {
            setZone();
            setZoneLogs();
            setUserInteractions();
            setOpeningHours();
            setFromDateTime();
            setToDateTime();
            setDetailedValues();
            setZoomLimits();
            setIsLoading();
        }
    }, []);

    const clickHandler = () => {
        getData();
    }

    const loadZones = async () => {
        let venueZones = await getZones(customerId);
        setZones(venueZones);
    }

    const getData = async () => {
        setIsLoading(true);

        try {
            var newZoomLimits = {
                x: {
                    min: new Date(fromDateTime),
                    max: new Date(toDateTime)
                }
            }
            setZoomLimits(newZoomLimits);

            if (customerId && zoneId) {
                let venueZones = await getZones(customerId);
                var selectedZone = venueZones.find(z => z.zoneId === zoneId);
                if (selectedZone) {
                    setZone(selectedZone);
                    var updatedZoneLogs = await getZoneLogs(selectedZone.zoneId, fromDateTime, toDateTime, detailedValues);
                    setZoneLogs(updatedZoneLogs);
                }

                var allUserInteractions = await getUserInteractions(customerId, fromDateTime, toDateTime);
                setUserInteractions(allUserInteractions
                    .filter(ui =>
                        (ui.zoneId === zoneId || ui.zoneId === null) &&
                        getDateTime(ui.time) >= fromDateTime &&
                        getDateTime(ui.time) <= toDateTime
                    ));

                var allschedules = await getSchedules(customerId);
                var interval = Interval.fromDateTimes(DateTime.fromJSDate(fromDateTime), DateTime.fromJSDate(toDateTime));
                var zoneSchedules = allschedules.filter(schedule => schedule.zones?.includes(zoneId));
                var zoneSchedulesWithinInterval = getSchedulesWithinInterval(zoneSchedules, interval);
                setSchedules(zoneSchedulesWithinInterval);

                var customerOpeningHours = await getOpeningHours(customerId);
                var openingHoursWithinInterval = getOpeningHoursWithinInterval(customerOpeningHours, interval);
                setOpeningHours(openingHoursWithinInterval);
            }
        } catch (error) {
            console.log(error);
            console.log("Could not get zone data.");
        } finally {
            setIsLoading(false);
        }
    }

    const setFromDateTimeHandler = (date) => {
        setFromDateTime(date);
        if (detailedValues) {
            setToDateTime(DateTime.fromJSDate(date).plus({ hours: 10 }).toJSDate());
        }
    }

    const moveInterval = (hours) => {
        var intervalLength = DateTime.fromJSDate(toDateTime).diff(DateTime.fromJSDate(fromDateTime), 'hours').toObject().hours;

        setFromDateTime(prev => {
            var newValue = DateTime.fromJSDate(prev).plus({ hours: hours }).toJSDate();
            if (newValue <= new Date()) {
                return DateTime.fromJSDate(prev).plus({ hours: hours }).toJSDate();
            } else {
                return DateTime.utc().startOf('minute').minus({ hours: intervalLength }).toJSDate();
            }
        });
        setToDateTime(prev => {
            var newValue = DateTime.fromJSDate(prev).plus({ hours: hours }).toJSDate();
            if (newValue <= new Date()) {
                return DateTime.fromJSDate(prev).plus({ hours: hours }).toJSDate();
            } else {
                return DateTime.utc().startOf('minute').toJSDate();
            }
        });

    }

    const setDetailedValuesHandler = () => {
        setDetailedValues(prev => {
            if (!prev) {
                setToDateTime(DateTime.fromJSDate(fromDateTime).plus({ hours: 10 }).toJSDate());
            }
            return !prev;
        });
    }

    return <>
        <ViewHeader headerText={`Zone Details - ${zone?.zoneName}`} backLink={`/supportcenter/customerdetails/${customerId}`} />

        <Container>
            <ParameterContainer>
                <DatePicker
                    fromDateTime={fromDateTime}
                    toDateTime={toDateTime}
                    setFromDateTime={setFromDateTimeHandler}
                    setToDateTime={setToDateTime}
                    detailedValues={detailedValues}
                    setDetailedValues={setDetailedValuesHandler}
                    isLoading={isLoading}
                    clickHandler={clickHandler}
                    moveInterval={moveInterval}
                />

                <Dropdown
                    small
                    items={zones?.map(z => { return { label: z.zoneName, ...z } })}
                    selectedItem={{ label: zones?.find(z => z.zoneId === zoneId)?.zoneName, ...zones?.find(z => z.zoneId === zoneId) }}
                    onClick={(item) => setZoneId(item.zoneId)} />
            </ParameterContainer>

            <Card>
                <h2>Zone logs</h2>

                {!isLoading ?
                    <ZoneLogGraphContainer>
                        <MemoizedZoneLogGraph
                            data={{
                                zoneLogs: zoneLogs,
                                userInteractions: userInteractions,
                                schedules: schedules,
                                openingHours: openingHours
                            }}
                            zoomLimits={zoomLimits} />
                    </ZoneLogGraphContainer> : <div>Loading data...</div>}

            </Card>

            <Card>
                <h2>Zone balance</h2>

                {!isLoading ? <>
                    {zoneLogs?.every(log => log.balance !== undefined) ?
                        <ZoneBalanceContainer>
                            <ZoneBalancePieContainer>
                                <ZoneBalancePieGraph data={{ zoneLogs: zoneLogs }} />
                            </ZoneBalancePieContainer>
                        </ZoneBalanceContainer>
                        : <div>No balance data</div>
                    }
                </> : <div>Loading data...</div>}

            </Card>
        </Container>
    </>
}

export default ZoneDetailsView;

const getSchedulesWithinInterval = (schedules, interval) => {
    let day = interval.start.startOf('day');
    while (day < interval.end) {
        schedules.map(schedule => {
            if (schedule?.days.includes(day.weekday - 1)) {
                let scheduleTime = day.set({ hour: parseInt(schedule.time.substring(0, 2)), minute: parseInt(schedule.time.substring(2, 4)) });
                if (interval.contains(scheduleTime)) {
                    if (!schedule.dateTimes) {
                        schedule.dateTimes = []
                    };
                    schedule.dateTimes.push(scheduleTime);
                }
            }
            return schedule;
        });
        day = day.plus({ days: 1 });
    }

    return schedules.filter(schedule => schedule.dateTimes?.length > 0);
}