import styled, { css } from "styled-components";
import theme from "../../../../UI/theme";
import Card from "../../../UiComponents/Card";

export const ColumnContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`

export const CalibrationGroupContainer = styled(ColumnContainer)`
    transition: grid-gap 0.5s;
    ${props => props?.expanded === false && css`
        grid-gap: 0;
    `}
`

export const CalibrationContainerCard = styled(Card)`
    border: 4px solid ${theme.colors.darkSpace80};
    background-color: transparent;
    box-sizing: border-box;
    width: 100%;
`

export const CalibrationCardHeader = styled.label`
    font-size: 1.2rem;
    cursor: inherit;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

export const HeaderRow = styled.div`
    display: grid;
    grid-template-columns: 1fr auto auto auto auto auto;
    grid-gap: 10px;
    align-items: center;
    width: 100%;
    cursor: pointer;
    transition: padding 0.5s;

    & > svg {
        padding: 0 5px;
    }
`

export const MasterHeaderRow = styled(HeaderRow)`
    grid-template-columns: 1fr auto auto auto;
`

export const BaseLevelHeaderRow = styled(HeaderRow)`
    grid-template-columns: 1fr auto;

    & > * {
        cursor: pointer;
    }
`

export const SubHeader = styled.label`
    font-size: ${theme.fontSize.medium};
    color: ${theme.colors.textGray};
    width: 100%;
    display: flex;
    align-items: center;
`

export const GroupHeaderRow = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: center;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    border-bottom: 2px solid ${theme.colors.darkSpace80};
    padding: 0 10px 5px 10px;
    box-sizing: border-box;

    & > svg {
        padding: 0 5px;
    }
`

export const MasterGroupHeaderRow = styled(GroupHeaderRow)`
    grid-template-columns: 1fr auto auto;
`

export const MasterCalibrationControlsContainer = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    margin-top: 45px;
`

export const MasterCalibationSliderContainer = styled.div`
    position: relative;
`

export const LiveMetricsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
    align-items: center;
    margin: auto;
`

export const CalibrationMetric = styled.div`
    --fontSize: 0.7rem;
    --width: 50px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        --width: 40px;
        --fontSize: 0.6rem;
    }

    width: var(--width);
    text-align: center;
    white-space: nowrap;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
    font-size: var(--fontSize);
    line-height: var(--fontSize);
    color: ${theme.colors.textGray};
    background-color: ${theme.colors.darkSpace90};
    border: 1px solid ${theme.colors.darkSpace80};
    border-radius: 2px;
    box-sizing: border-box;
    padding: 3px;
    position: relative;

    & > label:last-of-type {
        color: ${props => props.color};
    }

    &::after {
        content: '${props => props.vibeIncrement}';
        color: ${theme.colors.textGray};
        position: absolute;
        font-size: calc(var(--fontSize) - 0.1rem);
        width: var(--width);
        height: calc(var(--fontSize) * 2);
        top: calc(var(--fontSize) * -2.3);
        display: flex;
        justify-content: center;
        align-items: flex-end;
        box-sizing: border-box;
        white-space: nowrap;
    }
`

export const NoiseLevelControlContainer = styled(ColumnContainer)`
    justify-items: center;
    align-items: start;
    margin: auto;
`

export const ChartContainer = styled.div`
    canvas {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }
`

export const SectionContainer = styled(ColumnContainer)`
    padding-top: 10px;
    box-sizing: border-box;
`

export const VolumeRow = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    align-items: start;
    margin-top: 30px;

    @media only screen and (max-width: ${theme.screenSizes.small - 1}px) {
        margin-top: 25px;
    }
`

export const VolumeMetric = styled(CalibrationMetric)`
    position: absolute;
    top: -5px;
    right: 0px;
    transform: translateY(-100%);
`

export const BaseLevelContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 10px;
    height: fit-content;
`

export const CalibrationSliderContainer = styled.div`
    position: relative;
`

export const CalibrationPointMark = styled.div`
    --tickWidth: 5px;
    will-change: left;
    
    position: absolute;
    top: 0px;
    width: 50px;
    left: ${props => `calc((100% - 50px)*(${props?.left}/100))`};

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        width: 40px;
        left: ${props => `calc((100% - 40px)*(${props?.left}/100))`};
    }

    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
        position: absolute;
        bottom: calc(var(--tickWidth) * -1);
        width: 0;
        height: 0;
        border-right: var(--tickWidth) solid transparent;
        border-bottom: var(--tickWidth) solid ${theme.colors.darkSpace50}50;
        border-left: var(--tickWidth) solid transparent;
        content: '';
    }
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: left;
    
    & > button:not(:last-of-type) {
        margin-right: 10px;
    }
`