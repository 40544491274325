import styled, { css } from "styled-components"
import theme from "../../UI/theme";
import { DragIndicatorIcon } from "../UiComponents/Icons/materialIcons";

const SliderContainer = styled.div`
    --sliderHeight: 2px;
    --thumbSize: 30px;

    @media only screen and (max-width: ${theme.screenSizes.medium}px) {
        --thumbSize: 30px;
    }

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: var(--sliderHeight);
`

const VibeRange = styled.input.attrs({ type: 'range' })`
    -webkit-appearance: none;
    width: 100%;
    height: 100%;
    background: ${theme.colors.textGray};
    background: linear-gradient(to right,
        transparent calc(var(--thumbSize)/2),
        ${theme.colors.textGray} calc(var(--thumbSize)/2),
        ${theme.colors.textGray} calc(100% - var(--thumbSize)/2),
        transparent calc(100% - var(--thumbSize)/2));
    pointer-events: none;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: var(--thumbSize);
        height: var(--thumbSize);
        border-radius: 50%;
        background-color: ${theme.colors.greenEnergy};
        cursor: pointer;
        pointer-events: all;

        ${props => props.disabled && css`
            cursor: inherit;
        `}
    }

    &::-moz-range-thumb {
        width: var(--thumbSize);
        height: var(--thumbSize);
        border-radius: 50%;
        border: none;
        background-color: ${theme.colors.greenEnergy};
        cursor: pointer;
        pointer-events: all;

        ${props => props.disabled && css`
            cursor: inherit;
        `}
    }
`

const Step = styled.div`
    --tickSize: 6px;

    border-radius: 50%;
    background-color: ${theme.colors.darkSpace90};
    border: 2px solid ${theme.colors.textGray};
    box-sizing: border-box;
    pointer-events: none;

    ${props => props.selected && css`
        visibility: hidden;
    `}

    position: absolute;
    left: ${props => `calc((100%*((${props.value + 2})/4)) - calc(${props.value} / 2 * var(--thumbSize)/2 ) - var(--tickSize)/2)` ?? '50%'};
    top: calc(-0.5*var(--tickSize) + calc(var(--sliderHeight)/2));
    width: var(--tickSize);
    height: var(--tickSize);
`

const DragIndicator = styled(DragIndicatorIcon)`
    color: ${theme.colors.darkSpace};
    opacity: 40%;
    font-size: 18px !important;
    pointer-events: none;
    visibility: visible;

    position: absolute;
    left: 0;
    top: 0;
    translate: calc(-50% + 1px) calc(-50% + 1px);
`

const vibeLevels = [-2, -1, 0, 1, 2];

const VibeSlider = props => {
    return <SliderContainer disabled={props.disabled}>
        <VibeRange
            value={props.vibe}
            disabled={props.disabled}
            min={-2}
            max={2}
            step={1}
            onChange={(e) => props.changeVibe(Number(e.target.value))}
        />
        {vibeLevels.map(value => {
            return <Step
                key={value}
                value={value}
                selected={props.vibe === value}>
                {props.vibe === value ? <DragIndicator /> : <></>}
            </Step>
        })}
    </SliderContainer>
}

export default VibeSlider;