import { useContext, useState } from "react";
import styled, { css } from "styled-components";
import UserContext from "../../hooks/UserContext";
import { useSubscription } from "../../hooks/useSubscription";
import { postEvent } from "../../scripts/event";
import { subscriptionsTypes } from "../../scripts/subscription/subscriptionTypes";
import { postUserInteraction } from "../../scripts/userInteraction";
import theme from "../../UI/theme";
import { userInteractionTypes } from "../admin/customerDetails/userInteractionTypes";
import { getSourceMatrixTypeDisplay } from "../installation/modules/sourceMatrix/fields/SourceMatrixTypeChoiceField";
import { sourceMatrixTypes } from "../installation/modules/sourceMatrix/sourceMatrixTypes";
import { getAvailableSources, isMatrix } from "../installation/modules/sourceSelector/sourceSelectorUtils";
import Modal from "../UiComponents/Modal";
import Selector from "../UiComponents/Selector";
import { ModalContainer, ModalHeader } from "./ModalStyles";
import { hasRole, hasVenuePermission, permissionTypes, roles } from "../permission/permissions";

const SourceContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5px;
`

const SourceNameLabel = styled.label`
    font-weight: bold;
    color: white;
    font-size: ${theme.fontSize.medium};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: inherit;

    ${props => props.selected && css`
        color: ${theme.colors.greenEnergy};
    `}
`

const SourceRegulationLabel = styled.label`
    color: ${theme.colors.textGray};
    font-weight: light;
    font-size: ${theme.fontSize.tiny};
    cursor: inherit;

    ${props => props.selected && css`
        color: ${theme.colors.textGray};
    `}
`

const SourceButton = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2px;
    background-color: ${theme.colors.darkSpace90};
    padding: 10px;
    box-sizing: border-box;
    border-radius: 2px;
    cursor: pointer;
    
    @media (hover:hover) {
        &:hover {
            background-color: ${theme.colors.darkSpace80};
        }
    }

    ${props => props.selected && css`
        background-color: ${theme.colors.darkSpace80};
        color: ${theme.colors.greenEnergy};
        cursor: default;
    `}

    ${props => props.disabled && css`
        cursor: default;
        pointer-events: none;
        opacity: 40%;
    `}
`

const selectorTypes = {
    NORMAL: 0,
    MATRIX: 1
}

const selectorTypeItems = [{
    key: selectorTypes.NORMAL,
    display: 'Selector'
}, {
    key: selectorTypes.MATRIX,
    display: 'Mixer'
}]

const SourceSelectorModal = props => {
    const userContext = useContext(UserContext);
    const activeSource = props.sources?.find(source => source.sourceId === props.sourceSelector?.activeSourceId);
    const [selectorType, setSelectorType] = useState(selectorTypes.NORMAL);

    const changeSource = async (sourceId) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }

        const pickedSourceSelector = (({ sourceSelectorId, name, processorId }) => ({ sourceSelectorId, name, processorId }))(props.sourceSelector);
        const oldSource = props.sources?.find(source => source.sourceId === props.sourceSelector?.activeSourceId);
        if (props.setActiveSource) {
            props.setActiveSource(sourceId);
        }

        await postEvent(
            props.processor.hubId,
            props.processor.customerId,
            'SET_SOURCE',
            {
                sourceSelectorId: props.sourceSelector.sourceSelectorId,
                sourceId: sourceId
            });

        postUserInteraction(props.processor.customerId, {
            setting: userInteractionTypes.source.key,
            payload: {
                ...pickedSourceSelector,
                from: {
                    activeSourceId: oldSource?.sourceId ?? null,
                    activeSourceDisplayName: oldSource?.name ?? 'Unknown source'
                },
                to: {
                    activeSourceId: sourceId,
                    activeSourceDisplayName: props.sources?.find(source => source.sourceId === sourceId)?.name ?? 'Unknown source'
                }
            }
        });
    }

    return <Modal {...props}>
        <ModalContainer>
            <ModalHeader>Select source - {props.sourceSelector?.name}</ModalHeader>

            {isMatrix(props.sourceSelector) && hasRole(userContext, roles.admin) ? <>
                <Selector
                    items={selectorTypeItems}
                    selectedItem={selectorTypeItems.find(item => item.key === selectorType)}
                    onClick={(item) => setSelectorType(item.key)}
                />
            </> : <></>}

            {selectorType === selectorTypes.MATRIX ? <>
                <MatrixSelector
                    sourceSelector={props.sourceSelector}
                    sources={props.sources}
                    processorConnected={props.processorConnected}
                    processor={props.processor}
                    userContext={userContext}
                />
            </> : <>
                <SourceContainer>
                    {getAvailableSources(props.sourceSelector, props.sources)
                        ?.sort((a, b) => sortSources(a, b))
                        ?.map((source, index) => {
                            let selected = activeSource?.sourceId === source?.sourceId;
                            return <SourceButton
                                key={index}
                                selected={selected}
                                disabled={!props.processorConnected}
                                onClick={() => changeSource(source?.sourceId)}>
                                <SourceNameLabel selected={selected}>{source.name ?? "Unknown source"}</SourceNameLabel>
                                <SourceRegulationLabel selected={selected}>{source.regulate ? 'Automated' : 'Manual'}</SourceRegulationLabel>
                            </SourceButton>
                        })}

                    {activeSource === undefined ? <SourceButton
                        selected={true}
                        disabled={!props.processorConnected}>
                        <SourceNameLabel selected={true}>Unknown source</SourceNameLabel>
                        <SourceRegulationLabel selected={true}>{props.sources?.length === 0 ? 'Automated' : 'Manual'}</SourceRegulationLabel>
                    </SourceButton> : <></>}
                </SourceContainer>
            </>}

        </ModalContainer>
    </Modal>
}

export default SourceSelectorModal;

const sortSources = (a, b) => {
    if (isFinite(a.address) && isFinite(b.address)) {
        return Number(a.address) > Number(b.address) ? 1 : -1;
    } else {
        return a.address?.localeCompare(b.address);
    }
}

const MatrixSelector = ({ sourceSelector, sources, processorConnected, processor, userContext }) => {
    const [sourceMatrix, setSourceMatrix] = useSubscription(subscriptionsTypes.sourceMatrix, sourceSelector.sourceMatrixId);

    const setMatrixCrosspoint = async (outputIndex, inputIndex, value) => {
        if (!hasVenuePermission(userContext, permissionTypes.panelViewWrite)) {
            return;
        }

        try {
            const updatedMatrix = sourceMatrix.matrix.map(row => [...row]);
            updatedMatrix[outputIndex][inputIndex] = value;
            setSourceMatrix({ ...sourceMatrix, matrix: updatedMatrix });
            await postEvent(processor.hubId, processor.customerId, 'SET_MATRIX_CROSSPOINT', {
                sourceMatrixId: sourceMatrix.sourceMatrixId,
                inputNumber: inputIndex + 1,
                outputNumber: outputIndex + 1,
                value
            });
        } catch (err) {
            console.log('Failed to set matrix crosspoint', err);
        }
    }

    const renderChannels = () => {
        if (sourceMatrix) {
            const outputIndex = sourceSelector.matrixOutputNumber - 1;
            const outputChannel = sourceMatrix.matrix?.[outputIndex];
            if (sourceMatrix.type === sourceMatrixTypes.STEREO_IN_MONO_OUT) {
                return outputChannel.map((inputChannel, index) => {
                    const inputNumber = Math.ceil((index + 1) / 2);
                    const source = sources.find(source => parseInt(source.address) === inputNumber);
                    const side = (index % 2) === 0 ? 'Left' : 'Right';
                    const channelLabel = source ? `${source.name} ${side}` : '';
                    const inputLabel = `Input ${index + 1}`;
                    let selected = inputChannel === 1;
                    return <>
                        <SourceButton
                            key={index}
                            selected={selected}
                            disabled={!processorConnected}
                            onClick={() => setMatrixCrosspoint(outputIndex, index, +!selected)}
                        >
                            <SourceNameLabel selected={selected}>{channelLabel || inputLabel}</SourceNameLabel>
                            {channelLabel ? <>
                                <SourceRegulationLabel selected={selected}>{inputLabel}</SourceRegulationLabel>
                            </> : <></>}
                        </SourceButton>
                    </>
                })
            } else if (sourceMatrix.type === sourceMatrixTypes.STEREO) {
                const stereoOutputChannel = sourceMatrix.matrix?.[sourceSelector.matrixOutputNumber];
                return <>
                    <div>Out L</div>
                    <div>Out R</div>
                    {outputChannel.map((inputChannel, index) => {
                        let selected = inputChannel === 1;
                        let stereoSelected = stereoOutputChannel[index] === 1;
                        const inputNumber = Math.ceil((index + 1) / 2);
                        const source = sources.find(source => parseInt(source.address) === inputNumber);
                        const side = (index % 2) === 0 ? 'Left' : 'Right';
                        const channelLabel = source ? `${source.name} ${side}` : '';
                        const inputLabel = `Input ${index + 1}`;
                        return <>
                            <SourceButton
                                key={index}
                                selected={selected}
                                disabled={!processorConnected}
                                onClick={() => setMatrixCrosspoint(outputIndex, index, +!selected)}
                            >
                                <SourceNameLabel selected={selected}>{channelLabel || inputLabel}</SourceNameLabel>
                                {channelLabel ? <>
                                    <SourceRegulationLabel selected={selected}>{inputLabel}</SourceRegulationLabel>
                                </> : <></>}
                            </SourceButton>
                            <SourceButton
                                key={index}
                                selected={stereoSelected}
                                disabled={!processorConnected}
                                onClick={() => setMatrixCrosspoint(outputIndex + 1, index, +!stereoSelected)}
                            >
                                <SourceNameLabel selected={stereoSelected}>{channelLabel || inputLabel}</SourceNameLabel>
                                {channelLabel ? <>
                                    <SourceRegulationLabel selected={stereoSelected}>{inputLabel}</SourceRegulationLabel>
                                </> : <></>}
                            </SourceButton>
                        </>
                    })}
                </>
            } else {
                return outputChannel.map((inputChannel, index) => {
                    const inputNumber = (index + 1);
                    const source = sources.find(source => parseInt(source.address) === inputNumber);
                    const channelLabel = source ? source.name : '';
                    const inputLabel = `Input ${index + 1}`;
                    let selected = inputChannel === 1;
                    return <>
                        <SourceButton
                            key={index}
                            selected={selected}
                            disabled={!processorConnected}
                            onClick={() => setMatrixCrosspoint(outputIndex, index, +!selected)}
                        >
                            <SourceNameLabel selected={selected}>{channelLabel || inputLabel}</SourceNameLabel>
                            {channelLabel ? <>
                                <SourceRegulationLabel selected={selected}>{inputLabel}</SourceRegulationLabel>
                            </> : <></>}

                        </SourceButton>
                    </>
                })
            }
        } else {
            return <></>
        }
    }
    return <>
        {sourceMatrix ? <>
            {getSourceMatrixTypeDisplay(sourceMatrix.type)}
        </> : <></>}
        <SourceContainer>
            {renderChannels()}
        </SourceContainer>
    </>
}
