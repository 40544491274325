import React from 'react';
import styled from 'styled-components';
import theme from '../../UI/theme';

const Container = styled.div`
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
`

const Slider = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    border: 1px solid ${theme.colors.textGray};
    border-radius: 104px;
    transition: .4s;

    &::before {
        position: absolute;
        content: "";
        height: 16px;
        width: 16px;
        left: 3px;
        bottom: 3px;
        background-color: ${theme.colors.textGray};
        border-radius: 104px;
        transition: .4s;
    }

`

const Checkbox = styled.input`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${Slider} {
        border-color: ${theme.colors.greenEnergy};

        &::before {
            -webkit-transform: translateX(16px);
            -ms-transform: translateX(16px);
            transform: translateX(16px);
            background-color: ${theme.colors.greenEnergy};
        }
    }

`

const ToggleButton = props => {

    return <Container >
        {props.checked !== null && props.checked !== undefined ?
            <Checkbox type='checkbox' checked={props.checked} readOnly />
            : <></>}
        <Slider onClick={(e) => { e.stopPropagation(); props.onToggle(!props.checked); }} />
    </Container>
}

export default ToggleButton;