import React from 'react';
import styled from 'styled-components';
import Button from '../../UiComponents/Button';
import theme from '../../../UI/theme';

const Container = styled.div`
    padding: 40px;
    text-align: center;
    max-width: 50em;
`

const GetStarted = props => {

    const showEditButton = (window.innerWidth > theme.screenSizes.medium);

    return <Container>
        <p>Your map has not been created yet</p>
        
        <div>
            {showEditButton ? <>
                <p>Press the button below to start creating your own map </p>
                <Button primary onClick={props.onGetStarted}>Get started</Button>
            </> : <>
                <p>Start creating your map on a computer</p>
            </>}
            
        </div>
        
    </Container>
}

export default GetStarted;