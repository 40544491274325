import styled from "styled-components";
import { getUserDisplayName } from "../userAdministrationUtils";
import theme from "../../../../../UI/theme";
import { ButtonRowContainer } from "../../../../panel/ModalStyles";
import UpdateSharedAccountPasswordButton from "./UpdateSharedAccountPasswordButton";
import { SettingsParagraph, SettingsSection } from "../../../settingsStyles";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`

const HighlightedSpan = styled.span`
    color: ${theme.colors.whiteSpace};
`

const Header = styled.h3`
    color: ${theme.colors.whiteSpace};
    margin: 0;
`

const UserInfo = ({ user }) => {
    return <Container>
        <Header>{getUserDisplayName(user)}</Header>

        <SettingsSection>
            {user?.shared === 1 ? <>
                <SettingsParagraph>Shared account</SettingsParagraph>
            </> : <></>}

            <SettingsParagraph>Email: <HighlightedSpan>{user?.email}</HighlightedSpan></SettingsParagraph>
            <SettingsParagraph>Username: <HighlightedSpan>{user?.username}</HighlightedSpan></SettingsParagraph>
            <SettingsParagraph>Account status: <HighlightedSpan>{user?.accountStatus}</HighlightedSpan></SettingsParagraph>
        </SettingsSection>

        {user?.shared === 1 ? <>
            <ButtonRowContainer>
                <UpdateSharedAccountPasswordButton user={user} />
            </ButtonRowContainer>
        </> : <></>}
    </Container>
}

export default UserInfo;