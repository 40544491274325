import styled from "styled-components";
import theme from "../../../UI/theme";
import MusicPlayerItem from "./MusicPlayerItem";

const Container = styled.div`
    padding: 0 8px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    touch-action: manipulation;
    border-radius: 2px;
`

const Header = styled.label`
    font-size: 1.5rem;
    line-height: 50px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        line-height: 40px;
    }
`

const PlayerContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    // grid-gap: 2px;
`

const PlayerName = styled.label`
    color: ${theme.colors.darkSpace50};
`

const MusicPlayerList = props => {
    return <Container>
        <Header>Music</Header>
        {props.musicPlayers?.map((musicPlayer) => {
            return <PlayerContainer>
                <PlayerName>{musicPlayer?.name}</PlayerName>
                <MusicPlayerItem key={musicPlayer?.musicPlayerId} musicPlayer={musicPlayer} />
            </PlayerContainer>
        })}
    </Container>
}

export default MusicPlayerList;