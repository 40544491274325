import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/processor';

async function getProcessors(customerId) {
    const res = await axios.get(`${hostname}${path}/processors?customerId=${customerId}`);
    return res.data;
}

async function updateProcessors(processors) {
    const res = await axios.put(`${hostname}${path}/list`, processors);
    return res.data;
}

async function addProcessor(processor) {
    const res = await axios.post(`${hostname}${path}`, processor);
    return res.data;
}

async function deleteProcessor(processorId) {
    const res = await axios.delete(`${hostname}${path}?processorId=${processorId}`);
    return res.data;
}

export {
    getProcessors,
    updateProcessors,
    addProcessor,
    deleteProcessor
}