import React from 'react';
import styled from 'styled-components';
import theme from '../../UI/theme';

const Container = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
    align-items: center;
    overflow: hidden;
    color: ${theme.colors.whiteSpace};
    background-color: ${theme.colors.darkSpace80};
    border-radius: 2px;
    padding: 10px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        padding: 5px;
        grid-gap: 5px;
    }
`

const IconContainer = styled.div`
    color: ${theme.colors.greenEnergy};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;

    > svg {
        width: 25px;
        height: 25px;

        @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
            width: 20px;
            height: 20px;
        }
    }
`

const InfoContainer = styled.div`
    display: grid;
    grid-gap: 5px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-gap: 0px;
    }
`

const Title = styled.label`
    font-size: ${theme.fontSize.tiny};
    line-height: ${theme.fontSize.tiny};
    color: ${theme.colors.textGray};
    font-weight: lighter;
`

const Metric = styled.label`
    font-size: 1.8rem;
    line-height: 1.8rem;
    white-space: nowrap;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        font-size: 1.3rem;
    }
`

const KeyMetric = props => {
    return <Container>
        <IconContainer>
            {props.icon}
        </IconContainer>

        <InfoContainer>
            <Metric>{props.metric}</Metric>
            <Title>{props.title}</Title>
        </InfoContainer>
    </Container>
}

export default KeyMetric;