import theme from "../../../../UI/theme";
import { Chart } from "chart.js";
import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import 'chartjs-adapter-luxon';
import { getDateTime } from "../../../../scripts/log";

const chartData = {
    labels: [],
    datasets: [
        {
            label: 'UIs',
            data: [],
            backgroundColor: `${theme.colors.crystalBlue}70`,
            hoverBackgroundColor: theme.colors.crystalBlue,
            barPercentage: 1,
            categoryPercentage: 1,
            pointHitRadius: 5,
        }
    ]
}

const defaultChartConfig = {
    type: 'bar',
    data: chartData,
    options: {
        layout: {
            padding: {
                top: 1,
                right: 1,
                bottom: 1,
                left: 1
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                display: false,
                type: 'time',
                time: {
                    tooltipFormat: 'dd.MM',
                    displayFormats: {
                        'day': 'D'
                    },
                    unit: 'day'
                },
                grid: {
                    display: false
                }
            },
            y: {
                display: false,
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            tooltip: {
                padding: 2,
                titleSpacing: 1,
                bodySpacing: 1,
                titleMarginBottom: 1,
                titleFont: { size: 8 },
                bodyFont: { size: 8 },
                boxWidth: 0,
                boxHeight: 0,
                callbacks: {
                    title: function (ctx) {
                        return `${ctx[0]?.label}: ${ctx[0]?.formattedValue}`;
                    },
                    label: function (ctx) {
                        return false;
                    }
                }
            },
            legend: {
                display: false
            }
        }
    }
}

const NoOfUserInteractionsMinimizedGraph = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const chartConfig = useRef(JSON.parse(JSON.stringify(defaultChartConfig)));

    chartConfig.current.data.labels = [];
    chartConfig.current.data.datasets[0].data = [];

    if (props.from && props.to && props.userInteractions) {
        var from = DateTime.fromJSDate(props.from).startOf('day');
        var to = DateTime.fromJSDate(props.to).startOf('day');

        while (from <= to) {
            chartConfig.current.data.labels.push(new Date(from.toJSDate()));
            chartConfig.current.data.datasets[0].data.push(props.userInteractions.filter(ui =>
                DateTime.fromJSDate(getDateTime(ui.time)) > from &&
                DateTime.fromJSDate(getDateTime(ui.time)) < from.plus({ days: 1 })).length);
            from = from.plus({ days: 1 }).startOf('day');
        }
    }

    if (chartInstance) {
        chartInstance.update();
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfig.current);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return <canvas ref={chartContainer} style={{ height: '100%', width: '100%', maxWidth: '100%' }} ></canvas>

}

export default NoOfUserInteractionsMinimizedGraph;