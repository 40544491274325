import InnerCard from "../../../UiComponents/containers/InnerCard";
import styled from "styled-components";
import theme from "../../../../UI/theme";
import AnimateHeight from "react-animate-height";
import OpeningHours from "./OpeningHours";
import { useState } from "react";
import ExpandCollapseButton from "../../../UiComponents/buttons/ExpandCollapseButton";

const NavigationContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    :hover {
        cursor: pointer;
    }

    h4 {
        margin: 16px 0;
    }
`

const StyledCard = styled(InnerCard)`
    padding: 6px 16px;
`

const OpeningHoursMenuItem = props => {
    const [expanded, setExpanded] = useState(false);

    return <StyledCard>
        <NavigationContainer onClick={() => setExpanded(prev => { return !prev })}>
            <h4>Opening hours</h4>
            <ExpandCollapseButton showState={expanded} color={theme.colors.darkSpace60} />
        </NavigationContainer>

        <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
            <OpeningHours
                customerId={props.customerId}
                placeID={props.placeID}
                openingHours={props.openingHours}
                refresh={props.refresh}/>
        </AnimateHeight>
    </StyledCard>
}

export default OpeningHoursMenuItem;