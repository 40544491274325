import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import CanvasBox from './CanvasBox';
import InputField from '../../UiComponents/InputField'
import Modal from '../../UiComponents/Modal';
import Button from '../../UiComponents/Button';
import theme from '../../../UI/theme';
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from '../../panel/ModalStyles';

const Container = styled.div`
    margin-top: 20px;
`

const NameContainer = styled.div`
    padding: 12px 0;
`
const InputFieldLabel = styled.label`
    font-weight: bold;
    color: white;
`

const FloorInfo = props => {

    const [editedFloorName, setEditedFloorName] = useState(props.floor.name.toString())
    const [showModal, setShowModal] = useState(false);

    useEffect(() => { setEditedFloorName(props.floor.name.toString()) }, [props.floor.name]);

    function floorNameOnChangeHandler(newFloorName) {
        setEditedFloorName(newFloorName)
    }

    function floorNameOnKeyPressHandler(event) {
        if (event.key === 'Enter') event.target.blur();
    }

    function floorNameOnBlurHandler(newFloorName) {
        props.updateFloorName(props.floor.floorId, newFloorName);
    }

    function deleteFloorAttempt() {
        setShowModal(true);
    }

    async function deleteFloor() {
        setShowModal(false);
        const floorDeleted = await props.deleteFloorHandler(props.customerId, props.floor.floorId);
    }

    return <CanvasBox>
        <Container>
            <NameContainer>
                <div>
                    <InputFieldLabel>Name</InputFieldLabel>
                </div>
                <InputField
                    width={120}
                    value={editedFloorName}
                    onChange={(event) => floorNameOnChangeHandler(event.target.value)}
                    onKeyPress={(event) => floorNameOnKeyPressHandler(event)}
                    onBlur={(event) => floorNameOnBlurHandler(event.target.value)}
                />
            </NameContainer>
            <div>
                <Button secondary onClick={deleteFloorAttempt}>Delete this floor</Button>
            </div>
        </Container>
        <Modal show={showModal} close={() => { setShowModal(false) }}>
            <ModalContainer>
                <ModalHeader>Warning!</ModalHeader>
                <ModalText>Are you sure you want to delete this floor: {props.floor.name}?</ModalText>
                <ButtonRowContainer>
                    <Button primary onClick={() => deleteFloor()}>Delete</Button>
                    <Button secondary onClick={() => setShowModal(false)}>Cancel</Button>
                </ButtonRowContainer>
            </ModalContainer>
        </Modal>
    </CanvasBox>
}

export default FloorInfo;