import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/sourceSelector';

async function getSourceSelectors(customerId) {
    const res = await axios.get(`${hostname}${path}/sourceSelectors?customerId=${customerId}`);
    return res.data;
}

async function getSourceSelector(sourceSelectorId) {
    const res = await axios.get(`${hostname}${path}?sourceSelectorId=${sourceSelectorId}`);
    return res.data;
}

const updateSourceSelector = async (sourceSelector) => {
    const result = await axios.put(`${hostname}${path}`, sourceSelector);
    return result.data;
}

const updateSourceSelectors = async (sourceSelectors) => {
    const result = await axios.put(`${hostname}${path}/list`, sourceSelectors);
    return result.data;
}

async function updateSourceSelectorZoneGroup(sourceSelector) {
    const res = await axios.put(`${hostname}${path}/zoneGroup`, sourceSelector);
    return res.data;
}

async function addSourceSelector(sourceSelector) {
    const res = await axios.post(`${hostname}${path}`, sourceSelector);
    return res.data;
}

async function deleteSourceSelector(sourceSelectorId) {
    const res = await axios.delete(`${hostname}${path}?sourceSelectorId=${sourceSelectorId}`);
    return res.data;
}


export {
    getSourceSelectors,
    getSourceSelector,
    updateSourceSelector,
    updateSourceSelectors,
    updateSourceSelectorZoneGroup,
    addSourceSelector,
    deleteSourceSelector
}