import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/northstar';

async function getNorthstar(customerId) {
    const res = await axios.get(`${hostname}${path}?customerId=${customerId}`);
    return res.data;
}

export {
    getNorthstar
}