import { useState } from "react"
import { FaHistory } from "react-icons/fa";
import styled from "styled-components";
import { getCalibrationHistoryForZone } from "../../../../../scripts/calibrationHistory";
import theme from "../../../../../UI/theme";
import { loadingStates } from "../../../../installation/installationTypes";
import { ModalContainer, ModalHeader, ModalText } from "../../../../panel/ModalStyles";
import PanelButton from "../../../../panel/PanelButton";
import Button from "../../../../UiComponents/Button";
import Modal from "../../../../UiComponents/Modal";
import { CalibrationMetric, ColumnContainer } from "../calibrationStyles";

const CalibrationHistoryItem = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 5px;

    padding-bottom: 10px;
    border-bottom: 2px solid ${theme.colors.darkSpace80};
    box-sizing: border-box;
`

const HistoryLabelContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 5px;
    font-size: 0.7rem;
    line-height: 0.7rem;
    white-space: nowrap;

    > label:not(:first-of-type) {
        color: ${theme.colors.darkSpace50};
    }
`

const CalibrationPointsRow = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto 1fr;
    grid-gap: 10px;
    align-items: center;
    justify-items: end;
`

export const CalibrationHistoryButton = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [calibrationHistoryList, setCalibrationHistoryList] = useState();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    const showHistory = async () => {
        try {
            setShowModal(true);
            setLoadingState(loadingStates.LOADING);
            const list = await getCalibrationHistoryForZone(props.zone?.customerId, props.zone?.zoneId);
            setCalibrationHistoryList(list);
            setLoadingState(loadingStates.SUCCESS);
        } catch (err) {
            console.log('could not get calibration history', err)
            setLoadingState(loadingStates.FAILED);
        }
    }

    const applyCalibrationPoints = (points) => {
        props.setNewCalibrationPoints(prev => {
            let prevCopy = JSON.parse(JSON.stringify(prev));
            for (let index = 0; index < points?.length; index++) {
                prevCopy[index].sysvol = points?.[index]?.sysvol;
                prevCopy[index].measuredDb = points?.[index]?.measuredDb;
            }
            return prevCopy;
        })
        props.setHasChanged(true);
        setShowModal(false);
    }

    return <>
        <PanelButton onClick={showHistory}>
            <FaHistory />
            <label>History</label>
        </PanelButton>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Calibration History - {props.zone?.zoneName}</ModalHeader>

                {loadingState === loadingStates.SUCCESS ? <>
                    {calibrationHistoryList.length ? <>
                        <ColumnContainer>
                            {calibrationHistoryList.map(record =>
                                <CalibrationHistoryItem key={record.calibrationHistoryId}>
                                    <HistoryLabelContainer>
                                        <label>{record.time}</label>
                                        <label>{record.userId} {record.username}</label>
                                    </HistoryLabelContainer>
                                    <CalibrationPointsRow>
                                        {record.calibrationPoints?.map((point, index) => {
                                            const header = index === 0 ? 'Low' : index === 1 ? 'Med' : index === 2 ? 'High' : '';
                                            return <CalibrationMetric key={index} vibeIncrement={0}>
                                                <label>{header}</label>
                                                {point?.sysvol !== undefined ?
                                                    <label style={{ color: `${theme.colors.yellowFever}` }}>{point?.sysvol?.toFixed(2)}</label>
                                                    : <label>-</label>}
                                            </CalibrationMetric>

                                        })}

                                        <CalibrationMetric>
                                            <label>Base</label>
                                            {record.calibrationPoints[0]?.measuredDb !== undefined ?
                                                <label style={{ color: `${theme.colors.purplePleasure}` }}>{record.calibrationPoints[0]?.measuredDb?.toFixed(2)}</label>
                                                : <label>-</label>}
                                        </CalibrationMetric>

                                        <Button tertiary small onClick={() => applyCalibrationPoints(record.calibrationPoints)}>Apply</Button>
                                    </CalibrationPointsRow>
                                </CalibrationHistoryItem>
                            )}
                        </ColumnContainer>
                    </> : <>
                        <ModalText>No previous calibrations</ModalText>
                    </>}

                </> : <></>}

                {loadingState === loadingStates.FAILED ? <>
                    <ModalText>Failed to get calibration history</ModalText>
                    <Button primary onClick={showHistory}>Retry</Button>
                </> : <></>}
            </ModalContainer>
        </Modal>
    </>
}