import heatmapTypes from "../container/heatmapTypes";

const deviceLayerKeys = {
    [heatmapTypes.elementTypes.SENSOR]: 'sensor',
    [heatmapTypes.elementTypes.HUB]: 'hub',
    [heatmapTypes.elementTypes.PROCESSOR]: 'processor'
}

class CanvasController {
    constructor() {
        this.canvasCallback = null;
        this.heatmapType = null;
    }

    init(props) {
        this.canvasCallback = props.canvasCallback;
        this.heatmapType = props.heatmapType;
    }

    getLayerControllerList() {
        if (this.layerControllers) {
            return Object.keys(this.layerControllers).map(key => this.layerControllers[key]);
        }
    }

    deactivateAllLayers() {
        this.getLayerControllerList()?.forEach(layerController => {
            layerController.deactivate();
        });
        this.stackLayers();
    }

    stackLayers() {
        const layers = this.getLayerControllerList();
        if (layers) {
            layers[0].layer.sendToBack();
            for (var i = 1; i < layers.length; i++) {
                layers[i].layer.insertAbove(layers[i - 1].layer);
            }
        }
    }

    getDeviceLayerController(type) {
        return this.layerControllers[deviceLayerKeys[type]];
    }

    unmarkAllDevices() {
        Object.values(deviceLayerKeys).forEach(value => {
            this.layerControllers[value]?.unmarkAllDevices();
        })
    }

    canvasCleanup() {
        this.layerControllers.data.clearData();
    }

    onModeChange(modeName) {
        this.activeMode = modeName;
        this.getLayerControllerList()?.forEach(layerController => {
            layerController.onModeChange(modeName);
        });
    }

    async onFloorChange(floorData) {
        const layerControllerList = this.getLayerControllerList();
        for (let i = 0; i < layerControllerList.length; i++) {
            await layerControllerList[i].onFloorChange(floorData);
        }
    }

    onToolSelect(tool, floorData) {
        switch (tool) {
            case heatmapTypes.tools.FLOOR:
                this.layerControllers.floor.onToolSelect();
                break;
            case heatmapTypes.tools.ZONE:
                this.layerControllers.zone.onToolSelect(floorData);
                break;
            case heatmapTypes.tools.HUB:
                this.layerControllers.hub.activate();
                break;
            case heatmapTypes.tools.PROCESSOR:
                this.layerControllers.processor.activate();
                break;
            case heatmapTypes.tools.SENSOR:
                this.layerControllers.sensor.activate();
                break;
            case heatmapTypes.tools.FURNITURE:
                this.layerControllers.furniture.onToolSelect();
                break;
        }
    }

    handleDeviceUpdate(devices) {
        if (this.activeMode === heatmapTypes.modes.DOCUMENTATION) {
            this.layerControllers.hub.handleDeviceUpdates(devices.hubs);
            this.layerControllers.processor.handleDeviceUpdates(devices.processors, devices.hubs);
            this.layerControllers.sensor.handleDeviceUpdates(devices.sensors, devices.hubs);
        }
    }
}

const canvasController = new CanvasController();

export default canvasController;

