import { Link } from 'react-router-dom';
import BackButton from './BackButton';

const TopNavigation = props => {

    return <Link to={props.to || '/'}>
        <BackButton />
    </Link>
}

export default TopNavigation;