import styled from "styled-components"
import CenteredViewContainer from "../components/UiComponents/CenteredViewContainer"
import LoadingSpinner from "../components/UiComponents/LoadingSpinner"

const Flex = styled.div`
    display: flex;
    justify-content: center;
`

const LoadingView = () => {
    return <CenteredViewContainer>
        <Flex>
            <LoadingSpinner />
        </Flex>
    </CenteredViewContainer>
}

export default LoadingView;