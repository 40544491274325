import { useState } from "react";
import AnimateHeight from "react-animate-height";
import { FaChevronDown, FaChevronUp, FaExclamationCircle } from "react-icons/fa";
import theme from "../../../../../UI/theme";
import { CalibrationCardHeader, CalibrationContainerCard, CalibrationMetric, MasterHeaderRow } from "../calibrationStyles";
import MasterCalibrationControls from "./MasterCalibrationControls";

const MasterCalibrationItem = props => {
    const [expanded, setExpanded] = useState(false);
    const isRegulatingAudioSource = !props?.sourceSelector
        || props.sources?.find(source => source?.sourceId === props?.sourceSelector?.activeSourceId)?.regulate == true
        || props.sources?.length === 0 ? true : false;
        
    return <CalibrationContainerCard>
        <MasterHeaderRow onClick={() => setExpanded(prev => !prev)}>
            <CalibrationCardHeader>{props?.zone?.zoneName}</CalibrationCardHeader>

            {props.zone?.fixedMasterVolume === null ? <FaExclamationCircle color={theme.colors.raspberryRed} /> : <div></div>}

            <CalibrationMetric>
                <label>Fixed</label>
                {props.zone?.fixedMasterVolume !== null ?
                    <label style={{ color: `${theme.colors.yellowFever}` }}>{props.zone?.fixedMasterVolume?.toFixed()}</label>
                    : <label>-</label>
                }
            </CalibrationMetric>

            {expanded === false ? <FaChevronDown /> : <FaChevronUp />}
        </MasterHeaderRow>

        <AnimateHeight duration={500} height={expanded ? 'auto' : 0}>
            <MasterCalibrationControls
                zone={props.zone}
                zoneLive={props.zoneLive}
                isRegulatingAudioSource={isRegulatingAudioSource} />
        </AnimateHeight>
    </CalibrationContainerCard>
}

export default MasterCalibrationItem;