import React from 'react';
import styled, { css } from 'styled-components';
import theme from '../../UI/theme';

const Item = styled.button`
    background: transparent;
    display: flex;
    flex: ${props => props.flex ? props.flex : ''};
    width: ${props => props.width ? props.width : ''};
    justify-content: center;
    border: 1px solid ${theme.colors.grayBorder};
    border-radius: 2px;
    padding: 1px 10px 4px;
    margin-right: 10px;
    color: ${theme.colors.textGray};
    white-space: nowrap;

    &:hover {
        cursor: pointer;
        ${props => !props.selected && css`
        {
            border-color: ${theme.colors.grayHover};
        }
        `}
    }

    ${props => props.selected && css`
    {
        border-color: ${theme.colors.greenEnergy};
        color: ${theme.colors.greenReadable};
    }
    `}

    ${props => props.disabled && css`
        &:hover {
            border-color: ${theme.colors.grayBorder};
            cursor: auto;
        }
        background-color: ${theme.colors.textGray}30;
    `}

    ${props => props.fontSize && css`
        font-size: ${props => props.fontSize};
    `}

    ${props => props.large && css`
        font-size: 16px;
        @media only screen and (min-width: ${theme.screenSizes.medium}px) {
            font-size: 18px;
        }
    `}
`

const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    ${Item}:last-child{
        margin-right: 0;
    }
`

const Selector = props => {

    const clickHandler = item => {
        if (props.onClick) {
            props.onClick(item);
        }
    }

    return (
        <Container>
            {props.items?.map(item => {
                return <Item key={item.key}
                    selected={props.selectedItem && props.selectedItem.key === item.key}
                    onClick={() => clickHandler(item)} disabled={item.disabled ?? false}
                    width={props.width}
                    flex={props.flex}
                    large={props.large}
                    fontSize={props.fontSize}
                    >
                    {item.display}
                </Item>
            })}
        </Container>
    )
}

export default Selector;