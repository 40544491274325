import styled, { css } from "styled-components";
import theme from "../../UI/theme";
import { useEffect } from "react";
import { useState } from "react";
import { Interval } from "luxon";
import { DateTime } from "luxon";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import trendTypes from "../statistics/trendTypes";
import DatePickerInput from "../UiComponents/datepicker/StyledComponents/DatePickerInput";

const Container = styled.div`
    --maxWidth: 600px;

    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;

    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        top: 85px;
    }

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    background-color: ${theme.colors.darkSpace90};
    color: ${theme.colors.textGray};
    padding: 20px;
    box-sizing: border-box;
    justify-items: center;
    overflow: hidden;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        padding: 10px;
        grid-template-rows: 0.7fr 1fr;
    }
`

const TopContainer = styled.div`
    display: grid;
    width: fill-available;
    align-items: center;
    max-width: var(--maxWidth);
    grid-template-columns: 40px 1fr 40px;
    grid-gap: 10px;
    overflow: visible;

    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        grid-template-columns: 50px 1fr 50px;
    }
`

const WeekChangeButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: calc(min(100%, 40px));
    color: ${theme.colors.textGray};
    cursor: pointer;

    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        width: calc(min(100%, 50px));
    }

    ${props => props.disabled && css`
        opacity: 0.4;
        cursor: initial;
        pointer-events: none;
    `}
`

const DatesContainer = styled.div`
    width: fill-available;
    max-width: calc(min(100%, var(--maxWidth)));
    display: flex;
    justify-content: space-between;
    direction: rtl;
`

const DateContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    flex: 0 0 auto;
    text-align: center;
    overflow: hidden;
    border-radius: 2px;
    width: 40px;
    height: 40px;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    font-size: ${theme.fontSize.small};

    > label {
        cursor: pointer;
    }

    @media only screen and (min-width: ${theme.screenSizes.small}px) {
        width: 50px;
        height: 50px;
    }

    ${props => props.selected && css`
        color: ${theme.colors.greenEnergy};
        background-color: ${theme.colors.darkSpace80};
        border-radius: 2px;
    `}

    ${props => props.disabled && css`
        opacity: 0.4;
        cursor: initial;
        pointer-events: none;
    `}
`

const DayOfWeekLabel = styled.label`
    color: ${theme.colors.darkSpace60};
    font-weight: 100;
    font-size: ${theme.fontSize.small};

    ${props => props.selected && css`
        color: ${theme.colors.textGray};
    `}
`

const PeriodButton = styled.div`
    width: 100%;
    background-color: ${theme.colors.darkSpace80};
    color: ${theme.colors.greenReadable};
    border-radius: 2px;
    box-sizing: border-box;
    overflow: hidden;
    font-size: ${theme.fontSize.small};
    font-weight: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 2px 5px;
`

const DatePickerElement = styled(DatePickerInput)`
    &:after {
        font-size: 1rem;
        border: none;
    }
`

const trendPeriods = Object.keys(trendTypes.periods)
    .filter(period => period != trendTypes.periods.MONTH && period != trendTypes.periods.YEAR)
    .map(period => { return { key: trendTypes.periods[`${period}`], display: period.charAt(0).toUpperCase() + period.slice(1).toLowerCase() } });

const InsightsDatePicker = props => {
    const [pickerInterval, setPickerInterval] = useState([]);
    const [disableNextButton, setDisableNextButton] = useState(false);

    useEffect(() => {
        if (props.dateTime instanceof DateTime) {
            const intervalStart = props.dateTime?.startOf('week');
            const intervalEnd = props.dateTime?.endOf('week');
            let newPickerInterval = Interval.fromDateTimes(intervalStart, intervalEnd);
            setPickerInterval(newPickerInterval?.splitBy({ days: 1 })?.map(interval => interval.s)?.reverse());
        }
    }, [props.dateTime])

    useEffect(() => {
        let periodValue = 'day';
        if (props.period === trendTypes.periods.WEEK) {
            periodValue = 'week';
        }

        if (props.dateTime?.startOf(periodValue) >= DateTime.fromObject({}, { zone: props.timezone }).startOf(periodValue)) {
            setDisableNextButton(true);
        } else {
            setDisableNextButton(false);
        }
    }, [props.dateTime, props.period])

    const handleClick = (dateTime) => {
        if (props.setDateTime) {
            props.setDateTime(dateTime);
        }
    }

    const handleWeekChange = (increment) => {
        if (props.setDateTime && props.dateTime instanceof DateTime) {
            if (props.period === trendTypes.periods.WEEK) {
                props.setDateTime(props.dateTime.plus({ weeks: increment }));
            } else {
                props.setDateTime(props.dateTime.plus({ day: increment }));
            }
        }
    }

    const handlePeriodChange = () => {
        if (props.setPeriod) {
            if (props.period === trendTypes.periods.DAY) {
                props.setPeriod(trendTypes.periods.WEEK);
            } else if (props.period === trendTypes.periods.WEEK) {
                props.setPeriod(trendTypes.periods.DAY);
            }
        }
    }

    return <Container>
        <TopContainer>
            <div></div>
            <DatePickerElement
                type={'date'}
                content={`'${props.dateTime?.toFormat('LLLL y')}'`}
                max={DateTime.fromObject({}, { zone: props.timezone }).startOf('day')?.toFormat('yyyy-MM-dd')}
                value={props.dateTime?.toFormat('yyyy-MM-dd')}
                onChange={(e) => props.setDateTime(DateTime.fromFormat(e.target.value, 'yyyy-MM-dd'))}
            />
            {props.showPeriod === true ? <>
                <PeriodButton onClick={() => handlePeriodChange()} >{trendPeriods?.find(period => period?.key === props.period)?.display}</PeriodButton>
            </> : <></>}
        </TopContainer>
        <DatesContainer>
            <WeekChangeButton disabled={disableNextButton} onClick={() => handleWeekChange(1)}><FaChevronRight /></WeekChangeButton>
            {pickerInterval?.map(day => {
                const isSelected = props.dateTime?.startOf('day')?.ts === day?.startOf('day')?.ts || props.period === trendTypes.periods.WEEK;
                const isDisabled = day?.startOf('day') > DateTime.fromObject({}, { zone: props.timezone }).startOf('day');
                return <DateContainer
                    key={day?.ts}
                    selected={isSelected}
                    disabled={isDisabled}
                    onClick={() => handleClick(day)}
                >
                    <DayOfWeekLabel selected={isSelected}>{day?.toFormat('ccc')}</DayOfWeekLabel>
                    <label>{day?.day}</label>
                </DateContainer>
            })}
            <WeekChangeButton onClick={() => handleWeekChange(-1)}><FaChevronLeft /></WeekChangeButton>
        </DatesContainer>
    </Container>
}

export default InsightsDatePicker;