import { days } from "../../../../scripts/common";
import { setOpeningHours } from "../../../../scripts/datasource";
import OpeningHoursItem from "./OpeningHoursItem";

const OpeningHours = props => {
    const saveHandler = async (index, newHours) => {
        var copy = JSON.parse(JSON.stringify(props.openingHours));
        copy[index] = newHours;
        await setOpeningHours(props.customerId, copy);
        props.refresh();
    }

    return <>
        {props.openingHours?.map((day, index) => {
            return <div key={index}>
                <OpeningHoursItem
                    day={days[index]}
                    onSave={(newHours) => saveHandler(index, newHours)}
                    editable={props.placeID === null}
                    {...day} />
            </div>
        })}
    </>
}

export default OpeningHours;