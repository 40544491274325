import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-WQM7TNC'
}

TagManager.initialize(tagManagerArgs);


export default function Tracker() {
    return <></>;
}