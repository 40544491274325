import deviceTypes from "../../../settings/venue/device/deviceTypes";
import { getSensorDisplayStatus } from "../../utils/connectionStatus";


const getFullSensorDisplayStatus = (sensor, status) => {
    let nodeTypeDisplay;
    nodeTypeDisplay = getNodeTypeDisplay(sensor.nodeType); 
    let part1 = nodeTypeDisplay ? nodeTypeDisplay + ' - ' : '';
    return `${part1}${getSensorDisplayStatus(status)}`
}

const getNodeTypeDisplay = (typeValue) => {
    var typeDisplay = deviceTypes.nodeTypes.find(nodeType => nodeType.key === parseInt(typeValue))?.display;
    if (typeDisplay) {
        return typeDisplay;
    } else {
        return 'Unknown';
    }
}

export {
    getFullSensorDisplayStatus,
    getNodeTypeDisplay
}