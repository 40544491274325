import React, { useEffect } from "react";
import styled from "styled-components";
import theme from "../../UI/theme";

const TotalHours = (props) => {

    useEffect(() => {

    }, [props.total]);

    return (
        <h3>
            {props.total?
                `With an all time total of ${props.total} hours!`
            :
                `With an all time total of "missing data" hours!`
            }
        </h3>
    );
}

export default TotalHours;