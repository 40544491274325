import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSubscription } from "../../../../hooks/useSubscription";
import { subscriptionsTypes } from "../../../../scripts/subscription/subscriptionTypes";
import { updateZoneUserSettings } from "../../../../scripts/zone";
import ViewHeader from "../../../UiComponents/ViewHeader";
import ZoneSettingsItem from "./ZoneSettingsItem";
import { useContext } from "react";
import UserContext from "../../../../hooks/UserContext";
import { hasVenuePermission, permissionTypes } from "../../../permission/permissions";

const ZoneSettings = props => {
    const userContext = useContext(UserContext);
    const { zoneId } = useParams();
    const [zone, setZone] = useSubscription(subscriptionsTypes.zone, zoneId);
    const [zoneModes, setZoneModes] = useSubscription(subscriptionsTypes.zoneModes);

    const updateZoneWithEvent = async (zoneProps) => {
        if (!hasVenuePermission(userContext, permissionTypes.settingsWrite)) {
            return;
        }
        try {
            await updateZoneUserSettings({ ...zoneProps, zoneId: zone?.zoneId });
        } catch (err) {
        }
    }
    
    return <>
        <ViewHeader headerText={`${zone?.zoneName} - Settings`} backLink={'/settings/zones'} showVenueName={true} />

        <ZoneSettingsItem
            customerId={props.customerId}
            key={zone?.zoneId}
            zone={zone}
            updateZoneHandler={updateZoneWithEvent}
            zoneModes={zoneModes?.filter(mode => mode.zoneId === zone?.zoneId)}
        />
    </>
}

export default ZoneSettings;