import { useEffect, useRef, useState } from 'react';
import { daysShort, getDayOfWeek } from '../../scripts/common';
import { getAverageTrend, getTrendData } from '../../scripts/trends';
import OverviewChart from './charts/OverviewChart';
import trendTypes from './trendTypes';
import { filterOpeningHours, getOpeningHourLabels } from './trendUtils';

const OverviewChartContainer = props => {
    const [chartData, setChartData] = useState();
    const dayOfWeek = props.date ? getDayOfWeek(props.date) : null;
    let componentMounted = useRef(true);

    useEffect(() => {
        return () => {
            componentMounted.current = false;
            setChartData(null);
        }
    }, [])

    useEffect(() => {
        if (props.date && props.trendPeriod) {
            if (chartData?.averageDecibel) {
                var startOfWeek = new Date(chartData.averageDecibel?.startDate);
                var endOfWeek = new Date(chartData.averageDecibel?.endDate);
                if (props.date < startOfWeek || props.date >= endOfWeek) {
                    updateTrends();
                }
            } else {
                updateTrends();
            }
        } else {
            if (componentMounted.current) {
                setChartData(null);
            }
        }
    }, [props.date, props.customerId, props.trendPeriod, props.useOpeningHours]);

    const updateTrends = async () => {
        try {

            const promises = [
                getTrend(trendTypes.dataTypes.sysvol),
                getTrendAverage(trendTypes.dataTypes.sysvol),
                getTrend(trendTypes.dataTypes.noise),
                getTrendAverage(trendTypes.dataTypes.noise),
                getTrend(trendTypes.dataTypes.isRegulating),
                getTrend(trendTypes.dataTypes.isRegulatingAudioSource)
            ];
            const [sysVol, sysVolTrend, noise, noiseTrend, isRegulating, isRegulatingAudioSource] = await Promise.all(promises);
            
            if (componentMounted.current) {
                setChartData({
                    sysVol: sysVol,
                    sysVolTrend: sysVolTrend,
                    noise: noise,
                    noiseTrend: noiseTrend,
                    isRegulating: isRegulating,
                    isRegulatingAudioSource: isRegulatingAudioSource,
                    labels: getOpeningHourLabels(null)
                });
            }
        } catch (e) {
            console.log('Failed to update trends');
        }
    }

    const getTrend = async (dataType) => {
        var trends = await getTrendData(props.customerId, trendTypes.periods.WEEK, props.date, props.hierarchyLevel, props.objectID, dataType);
        var newTrend = trends?.filter(trend => trend?.DataTypeValue === dataType)?.[0];

        if (newTrend) {
            return { ...newTrend };
        } else {
            return null;
        }
    }

    const getTrendAverage = async (dataType) => {
        var averageTrend = null;
        averageTrend = await getAverageTrend(props.customerId, props.objectID, dataType, props.hierarchyLevel);
        if (averageTrend) {
            return averageTrend;
        } else {
            return null;
        }
    }

    return <>
        <OverviewChart
            {...resolveChartDataForPeriod(chartData, props.trendPeriod, dayOfWeek, props.objectID, props.useOpeningHours)}
            minWidth={props.minWidth}
        />
    </>
}

export default OverviewChartContainer;

const resolveChartDataForPeriod = (chartData, trendPeriod, dayOfWeek, chartID, useOpeningHours = false) => {
    var filteredChartData = chartData;
    var openingHours = chartData?.sysVol?.OpeningHours;
    if (chartData && useOpeningHours) {
        filteredChartData = {
            sysVol: filterOpeningHours(chartData.sysVol, chartData.sysVol?.OpeningHours),
            sysVolTrend: filterOpeningHours(chartData.sysVolTrend, chartData.sysVol?.OpeningHours),
            noise: filterOpeningHours(chartData.noise, chartData.noise?.OpeningHours),
            noiseTrend: filterOpeningHours(chartData.noiseTrend, chartData.noise?.OpeningHours),
            isRegulating: filterOpeningHours(chartData.isRegulating, chartData.isRegulating?.OpeningHours),
            isRegulatingAudioSource: filterOpeningHours(chartData.isRegulatingAudioSource, chartData.isRegulating?.OpeningHours),
            labels: getOpeningHourLabels(useOpeningHours && openingHours ? openingHours : null),
            chartID: chartID
        }
    }

    if (trendPeriod === trendTypes.periods.DAY) {
        return {
            sysVol: filteredChartData?.sysVol?.data?.[dayOfWeek].hourData,
            sysVolTrend: filteredChartData?.sysVolTrend?.data?.[dayOfWeek].hourData,
            noise: filteredChartData?.noise?.data?.[dayOfWeek].hourData,
            noiseTrend: filteredChartData?.noiseTrend?.data?.[dayOfWeek].hourData,
            isRegulating: filteredChartData?.isRegulating?.data?.[dayOfWeek].hourData,
            isRegulatingAudioSource: filteredChartData?.isRegulatingAudioSource?.data?.[dayOfWeek].hourData,
            labels: filteredChartData?.labels?.[dayOfWeek],
            chartID: chartID
        }
    } else if (trendPeriod === trendTypes.periods.WEEK) {
        return {
            sysVol: [].concat.apply([], filteredChartData?.sysVol?.data?.map(day => day?.hourData)),
            sysVolTrend: [].concat.apply([], filteredChartData?.sysVolTrend?.data?.map(day => day?.hourData)),
            noise: [].concat.apply([], filteredChartData?.noise?.data?.map(day => day?.hourData)),
            noiseTrend: [].concat.apply([], filteredChartData?.noiseTrend?.data?.map(day => day?.hourData)),
            isRegulating: [].concat.apply([], filteredChartData?.isRegulating?.data?.map(day => day?.hourData)),
            isRegulatingAudioSource: [].concat.apply([], filteredChartData?.isRegulatingAudioSource?.data?.map(day => day?.hourData)),
            labels: [].concat.apply([], filteredChartData?.labels.map((dayLabels, index) => Array.from(dayLabels, (v, k) => { return daysShort[index] }))),
            chartID: chartID,
            weekLabels: true,
        }
    }
}