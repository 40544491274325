import { deleteMeter, updateMeters } from "../../../../scripts/meter";
import AddressField from "../../fields/AddressField";
import NameField from "../../fields/NameField";
import ProcessorChoiceField from "../../fields/ProcessorChoiceField";
import { DisplayField } from "../../fields/Styles";
import { MeterIcon } from "../../styling/Icons";
import { getDefaultId } from "../../table/tableUtils";
import MeterTypeChoiceField from "./fields/MeterTypeChoiceField";
import { addNewMeter } from "./meterUtils";

const idField = 'meterId';

export const getMeterTableProps = ({ meters, processors, customerId }) => {

    const getColumns = (meters) => [{
        key: 'name',
        displayName: 'Name',
        form: true,
        width: '150px',
        render: (meter, updateMeter, cellControl) => (
            <NameField
                object={meter}
                updateObject={updateMeter}
                objects={meters}
                idField={'meterId'}
                nameField={'name'}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'meterId',
        displayName: 'Id',
        width: '80px',
        readOnly: true,
        render: (meter) => (
            <DisplayField>{meter.meterId}</DisplayField>
        )
    }, {
        key: 'processorId',
        displayName: 'Processor',
        width: '150px',
        form: true,
        getSuggested: () => getDefaultId(processors, 'processorId'),
        render: (meter, updateMeter, cellControl) => (
            <ProcessorChoiceField
                object={meter}
                processors={processors}
                updateObject={updateMeter}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'type',
        displayName: 'Type',
        width: '150px',
        form: true,
        render: (meter, updateMeter, cellControl) => (
            <MeterTypeChoiceField
                type={meter.type}
                setValue={(value) => updateMeter({ type: value })}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'address',
        displayName: 'Address',
        width: '150px',
        form: true,
        render: (meter, updateMeter, cellControl) => (
            <AddressField
                address={meter.address}
                setAddress={(value) => updateMeter({ address: value })}
                cellState={cellControl.cellState}
            />
        )
    }];

    const saveObjects = async (changedMeters) => {
        try {
            await updateMeters(changedMeters);
        } catch (err) {
            console.log('Failed to update meters');
        }
    }

    const deleteObject = async (meter) => {
        try {
            await deleteMeter(meter.meterId);
        } catch (err) {
            console.log('Error failed to delete meter');
        }
    }

    const addObject = processors.length ? (newMeter) => addNewMeter(newMeter, processors, customerId) : null;

    const getListItemProps = (meter) => {
        return {
            displayName: meter.name,
            Icon: MeterIcon
        }
    }

    const getPlaceholder = () => {
        return <div>
            {processors.length ? <>
                <p>No meters have been added. Add the available meters for your processor(s)</p>
            </> : <>
                <p>Add a processor before adding meters</p>
            </>}
        </div>
    }

    const getActions = (meter) => [];

    return {
        title: 'Meters',
        getColumns,
        parentObjects: meters,
        getListItemProps,
        idField,
        saveObjects,
        deleteObject,
        addObject,
        getActions,
        getPlaceholder
    }
}
