import { FaAngleDown, FaAngleUp, FaAngleDoubleDown, FaAngleDoubleUp, FaMinusSquare } from "react-icons/fa";

const VibeChangeIcon = props => {
    if (props.vibeChange === -2) {
        return <FaAngleDoubleDown color={props.color} />
    }
    else if (props.vibeChange === -1) {
        return <FaAngleDown color={props.color} />
    }
    else if (props.vibeChange === 0) {
        return <FaMinusSquare color={props.color} />
    }
    else if (props.vibeChange === 1) {
        return <FaAngleUp color={props.color} />
    }
    else if (props.vibeChange === 2) {
        return <FaAngleDoubleUp color={props.color} />
    } else {
        return <></>
    }
}

export default VibeChangeIcon;