import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import theme from '../../UI/theme';

const popupAnimation = keyframes`
    0% {transform: translateY(20px);}
    100% {transform: translateY(0);}
`

export const StyledModalOverlay = styled.div`
    background-color:#4e4e4ea1;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 110;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ModalFrame = styled.div`
    background-color: ${theme.colors.darkSpace};
    padding: 20px;
    height: fit-content;
    width: fit-content;
    margin: auto;
    display: flex;
    animation: ${popupAnimation} 0.2s;
`

const ModalAnchor = styled.div`
    position: relative;
`

const StyledModalContainer = styled.div`
    font-size: 1rem;
    color: white;
    border-radius: 2px;
    z-index: 20;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    max-height: 70vh;
    max-width: 85vw;
    overscroll-behavior: contain;

    ::-webkit-scrollbar {
        display: none;
    }
`

const Modal = props => {
    
    function handleClick(ev) {
        if (ev.target.id === 'modal-overlay') {
            ev.stopPropagation();
            props.close();
        }
    }

    if (props.show) {
        return (
            <StyledModalOverlay id="modal-overlay" onClick={handleClick}>
                <ModalFrame onClick={e => e.stopPropagation()}>
                    <ModalAnchor>
                        <StyledModalContainer>
                            {props.children}
                        </StyledModalContainer>
                    </ModalAnchor>
                </ModalFrame>
            </StyledModalOverlay>
        );
    } else {
        return <></>
    }
}

export default Modal;