import theme from "../../../UI/theme";

export const balanceTypes = {
    musicDom: {
        key: 'MUSIC_DOM',
        display: 'Music DOM',
        color: theme.colors.pinky
    },
    balance: {
        key: 'BALANCE',
        display: 'Balance',
        color: theme.colors.crystalBlue
    },
    noiseDom: {
        key: 'NOISE_DOM',
        display: 'Noise DOM',
        color: theme.colors.mellowYellow
    }
}