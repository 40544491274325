import axios from 'axios';
import { getHostname } from './server/server'

const hostname = getHostname();
const authPath = '/v2/auth';

const loginUser = async (username, password) => {
    const res = await axios.post(`${hostname}${authPath}/login`, { username, password });
    return res.data;
}

const authorizeAdmin = async (pin) => {
    const res = await axios.post(`${hostname}${authPath}/authorizeAdmin`, { pin });
    return res.data;
}

const logoutUser = async () => {
    await axios.post(`${hostname}${authPath}/logout`);
    window.location.reload();
}

export {
    loginUser,
    authorizeAdmin,
    logoutUser,
}