import Paper, { Point, Path, Size, Rectangle, Group, PointText } from 'paper';
import canvasController from '../CanvasController';
import heatmapTypes from '../../container/heatmapTypes';
import theme from '../../../../UI/theme';

const defaultParams = {
    activeStrokeColor: theme.colors.yellowFever,
    activeStrokeWidth: 0.2,
    markedStrokeColor: theme.colors.greenEnergy,
    markedStrokeWidth: 0.2
}

class Device {
    constructor(elementInfo, layer, elementParams) {
        this.id = elementInfo.floorElementId;
        this.placed = elementInfo.placed;
        this.coordinates = elementInfo.geometry;
        this.layer = layer;
        this.status = null;
        this.elementParams = { ...defaultParams, ...elementParams };


        if (this.placed && this.coordinates) {
            this.placeElement();
        }

    }

    async placeElement() {
        if (this.coordinates) {
            var point = new Point(this.coordinates.x, this.coordinates.y);
            await this.drawElement(point);
        }
    }

    async drawElement(point) {
        this.isOnMap = true;
        if (canvasController.heatmapType === heatmapTypes.heatmapTypes.MINI) {
            return;
        }
        this.layer.layer.activate();
        this.element = new Group();

        await this.drawElementInGroup(point);
    }

    drawLabeledElement(point) {
        const text = new PointText(point);
        text.justification = 'center';
        text.fillColor = 'white';
        text.fontSize = 1;
        text.content = this.elementParams.label;
        text.fontFamily = 'Karelia';
        text.name = 'TEXT';

        // apply scaling because paper does not work well with font-sizes below 1
        text.scale(0.5);
        this.element.addChild(text);
        const bounds = this.element.bounds;

        var boundingRect = new Rectangle(bounds);
        boundingRect.height += 0.3;
        boundingRect.width += 0.8;
        boundingRect.center = bounds.center;

        const boundingPath = new Path.Rectangle(boundingRect, this.elementParams.radius);
        boundingPath.fillColor = this.elementParams.fillColor;
        boundingPath.name = 'PATH';
        this.element.addChild(boundingPath);
        boundingPath.sendToBack();
        this.elementPath = boundingPath;
    }

    async drawIconElement(point) {
        const rectangle = new Rectangle(point, new Size(this.elementParams.size, this.elementParams.size));
        const radius = new Size(this.elementParams.radius, this.elementParams.radius);
        const boundingRect = new Path.Rectangle(rectangle, radius);
        boundingRect.position = point;
        boundingRect.fillColor = this.elementParams.fillColor;
        this.elementPath = boundingRect;
        this.element.addChild(boundingRect);
        const iconGroup = await this.importIcon(this.elementParams.icon);
        iconGroup.fillColor = this.elementParams.iconColor || 'white';
        iconGroup.fitBounds(boundingRect.bounds);
        iconGroup.scale(0.6);
        iconGroup.insertAbove(boundingRect);

        this.element.addChild(iconGroup);
        this.iconPath = iconGroup;
        this.statusPath = iconGroup;
        this.elementPath = boundingRect;
    }

    async importIcon(source) {
        return new Promise((resolve, reject) => {
            Paper.project.importSVG(source, {
                onLoad: (group) => {
                    group.fillColor = 'transparent';
                    resolve(group);
                }
            })
        })
    }

    drawActivated() {
        if (this.elementPath) {
            if (!this.marked) {
                this.elementPath.strokeColor = this.elementParams.activeStrokeColor;
            }
            this.elementPath.strokeWidth = this.elementParams.activeStrokeWidth;
        }
    }

    drawDeactivated() {
        if (this.elementPath && !this.marked) {
            this.elementPath.strokeColor = 'transparent';
        }
    }

    remove() {
        canvasController.canvasCallback(heatmapTypes.actions.DEVICE_REMOVED, this);
        this.element.remove();
    }

    mark() {
        this.marked = true;
        this.elementPath.strokeColor = this.elementParams.markedStrokeColor;
        this.elementPath.strokeWidth = this.elementParams.markedStrokeWidth;
    }

    unmark() {
        this.marked = false;
        this.elementPath.strokeColor = 'transparent';
        if (this.layer.active) {
            this.drawActivated();
        }
    }

    addEventHandlers() {
        if (this.element) {
            this.element.onMouseDrag = (event) => {
                this.element.position = this.element.position.add(event.delta);
                if (!this.marked) {
                    canvasController.unmarkAllDevices();
                    this.mark();
                    canvasController.canvasCallback(heatmapTypes.actions.DEVICE_MARKED, this.id);
                }
                event.stopPropagation();
            }
            this.element.onMouseUp = (event) => {
                this.setPlaced(this.element.position);
                if (this.marked) {
                    canvasController.canvasCallback(heatmapTypes.actions.DEVICE_MARKED, null);
                    this.unmark();
                } else {
                    canvasController.unmarkAllDevices();
                    this.mark();
                    canvasController.canvasCallback(heatmapTypes.actions.DEVICE_MARKED, this.id);
                }
            }

            this.element.onMouseDown = (event) => {

            }

            this.element.onMouseEnter = (event) => {
                Paper.view.element.style.setProperty('cursor', 'move');
            }

            this.element.onMouseLeave = (event) => {
                Paper.view.element.style.setProperty('cursor', null);
            }
        }
        this.drawActivated();
    }

    addDocumentationModeEventHandlers() {
        if (this.element) {
            this.element.onClick = (event) => {
                if (!this.marked) {
                    canvasController.unmarkAllDevices();
                    this.mark();
                    canvasController.canvasCallback(heatmapTypes.actions.DEVICE_MARKED, this.id);
                } else {
                    canvasController.canvasCallback(heatmapTypes.actions.DEVICE_MARKED, null);
                    this.unmark();
                }
            }

            this.element.onMouseEnter = (event) => {
                Paper.view.element.style.setProperty('cursor', 'pointer');
            }

            this.element.onMouseLeave = (event) => {
                Paper.view.element.style.setProperty('cursor', null);
            }
        }
    }

    startPlacing() {
        Paper.view.onMouseMove = (event) => {
            if (!(this.state == 'PLACING')) {
                this.drawElement(event.point);


                this.state = 'PLACING';
                this.element.onMouseDown = (event) => {
                    if (this.state === 'PLACING') {
                        //this.setPlaced(event.point);
                        Paper.view.onMouseMove = null;
                        this.addEventHandlers();
                    }
                }

            }
            if (this.element && this.state === 'PLACING') {
                this.element.position = event.point
            }
        }
    }

    removeEventHandlers() {
        if (this.element) {
            this.element.onMouseDrag = null;
            this.element.onClick = null;
            this.element.onMouseUp = null;
            this.element.onMouseDown = null;
            this.element.onMouseEnter = null;
            this.element.onMouseLeave = null;
            Paper.view.element.style.setProperty('cursor', null);
        }
        this.drawDeactivated();
    }

    setPlaced(position) {
        this.state = 'PLACED';
        this.coordinates = { x: position.x, y: position.y };
        canvasController.canvasCallback(heatmapTypes.actions.DEVICE_PLACED, this);
    }

    showConnectionStatus() {
        if (this.connectedState !== undefined && this.statusPath) {
            if (this.connectedState) {
                this.statusPath.fillColor = theme.colors.greenEnergy
            } else {
                this.statusPath.fillColor = theme.colors.raspberryRed
            }
        }
    }

    hideConnectionStatus() {
        if (this.statusPath) {
            this.statusPath.fillColor = 'white'
        } 
    }

    setConnectedState(state) {
        this.connectedState = state;
        this.showConnectionStatus();
    }
}

export default Device;