import Button from "../../../UiComponents/Button";
import { useState } from "react";
import InputField from "../../../UiComponents/InputField";
import styled from "styled-components";
import { TextMuted } from "../../../UiComponents/text/StyledText";
import ScheduleDays from "./ScheduleDays";
import Checkbox from "../../../UiComponents/Checkbox";
import { createSchedule, updateSchedule } from "../../../../scripts/schedules";
import scheduleTypes from "./scheduleTypes";
import theme from "../../../../UI/theme";
import { FaExclamationTriangle, FaAngleDown, FaAngleUp, FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import Radio from "../../../UiComponents/Radio";
import MultipleSelector from "../../../UiComponents/MultipleSelector";
import hierarchyTypes from "../device/hierarchyTypes";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../panel/ModalStyles";

const ColContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-self: start;
`

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

const RadioContainer = styled(RowContainer)`
    display: flex;
    flex-direction: row;

    :not(:last-of-type) {
        margin: 0 0 10px 0;
    }

    >div:not(:first-of-type) {
        margin-left: 10px;
    }
`

const ClickableText = styled(TextMuted)`
    display: block;
    cursor: pointer;
`

const ErrorMessage = styled.label`
    display: flex;
    align-items: center;
    color: ${theme.colors.raspberryRed};
`

const emptySchedule = {
    scheduleTypes: [],
    zones: [],
    days: [],
    isRegulating: false,
    vibeChange: 0,
    mute: false,
    moodSustain: false,
    displayName: '',
    time: '12:00'
}

const scheduleTypeSelectorItems = [
    { key: scheduleTypes.types.VIBE, display: "Change vibe" },
    { key: scheduleTypes.types.REGULATION, display: "Turn automation on/off" },
    { key: scheduleTypes.types.MUTE, display: "Mute" },
    { key: scheduleTypes.types.MOOD_SUSTAIN, display: "Mood sustain" }];

const ScheduleForm = props => {
    const [schedule, setSchedule] = useState(props.schedule ?? { ...emptySchedule, customerId: props.customerId });
    const [time, setTime] = useState(toInputTime(props.schedule?.time) ?? '12:00');
    const [selectedSchedules, setSelectedSchedules] = useState(scheduleTypeSelectorItems.filter(item => props.schedule?.scheduleTypes?.some(type => type === item.key)) ?? []);
    const [errorMessage, setErrorMessage] = useState();

    const selectZoneHandler = (zone) => {
        setSchedule(prev => {
            var newZones = [...prev.zones];
            if (newZones.includes(zone)) {
                newZones = newZones.filter(z => z !== zone);
            } else {
                newZones.push(zone);
            }
            return { ...prev, zones: newZones };
        });
    }

    const changeScheduleTypeHandler = (newType) => {
        setSelectedSchedules(prev => {
            if (prev?.some(scheduleType => scheduleType.key === newType.key)) {
                return prev.filter(scheduleType => scheduleType.key !== newType.key);
            } else {
                var newScheduleTypes = [...prev];
                newScheduleTypes.push(newType);

                if (newType.key === scheduleTypes.types.VIBE) {
                    setSchedule({ ...schedule, vibeChange: 0 });
                }
                if (newType.key === scheduleTypes.types.REGULATION) {
                    setSchedule({ ...schedule, isRegulating: true });
                }
                if (newType.key === scheduleTypes.types.MUTE) {
                    setSchedule({ ...schedule, mute: true });
                }
                if (newType.key === scheduleTypes.types.MOOD_SUSTAIN) {
                    setSchedule({ ...schedule, moodSustain: true });
                }

                return newScheduleTypes;
            }
        })
    }

    const saveScheduleHandler = async () => {
        var newSchedule = {
            customerId: schedule.customerId,
            scheduleTypes: selectedSchedules.map(type => type.key),
            displayName: schedule.displayName,
            time: toScheduleTime(time),
            zones: [...schedule.zones],
            days: [...schedule.days],
        }

        if (schedule.scheduleId) {
            newSchedule.scheduleId = schedule.scheduleId;
        }

        if (selectedSchedules.some(type => type.key === scheduleTypes.types.VIBE)) {
            newSchedule.vibeChange = schedule.vibeChange;
        }
        if (selectedSchedules.some(type => type.key === scheduleTypes.types.REGULATION)) {
            newSchedule.isRegulating = +schedule.isRegulating;
        }
        if (selectedSchedules.some(type => type.key === scheduleTypes.types.MUTE)) {
            newSchedule.mute = +schedule.mute;
        }
        if (selectedSchedules.some(type => type.key === scheduleTypes.types.MOOD_SUSTAIN)) {
            newSchedule.moodSustain = +schedule.moodSustain;
        }

        if (newSchedule.scheduleId) {
            try {
                var res = await updateSchedule(newSchedule);
            } catch (error) {

            }
        } else {
            try {
                var res = await createSchedule(newSchedule);
            } catch (error) {

            }
        }
        if (res.valid === undefined && res.error === undefined) {
            props.setShowScheduleModal(false);
            await props.refreshSchedules();
        } else {
            setErrorMessage(res.error);
        }
    }

    return <ModalContainer>
        <ModalHeader>Schedule</ModalHeader>

        <ModalText>Schedule Name</ModalText>
        <InputField placeholder='Closing time' value={schedule.displayName} onChange={(e) => setSchedule({ ...schedule, displayName: e.target.value })} />

        <ModalText>Time</ModalText>
        <InputField type="time" value={time} onChange={(e) => setTime(e.target.value)} />

        <ModalText>Days</ModalText>
        <ScheduleDays editable days={schedule.days} onSelectDay={(newDays) => setSchedule({ ...schedule, days: newDays })} />

        <ModalText>Zones</ModalText>
        <div>
            {props.zones?.filter(zone => zone.hierarchyType !== hierarchyTypes.MASTER.value).map((zone, index) => {
                if (zone.hide) {
                    return;
                }
                return <RowContainer key={index}>
                    <Checkbox checked={schedule.zones?.includes(zone.zoneId)} onChange={() => { selectZoneHandler(zone.zoneId); }} />
                    <ClickableText onClick={() => { selectZoneHandler(zone.zoneId); }} >&nbsp;{zone.zoneName}</ClickableText>
                </RowContainer>
            })}
        </div>

        <div>
            <ModalText>Schedule actions</ModalText>
            <MultipleSelector editable items={scheduleTypeSelectorItems} selectedItems={selectedSchedules} onSelectItem={(selectedItem) => changeScheduleTypeHandler(selectedItem)} />
        </div>

        {selectedSchedules?.length > 0 ? <>
            <ColContainer>
                {selectedSchedules.some(type => type.key === scheduleTypes.types.VIBE) ?
                    <>
                        <TextMuted>Vibe</TextMuted>

                        <RadioContainer>
                            <Radio name="vibe" checked={schedule.vibeChange === -2} onChange={() => setSchedule({ ...schedule, vibeChange: -2 })} />
                            <TextMuted><FaAngleDoubleDown /></TextMuted>
                            <Radio name="vibe" checked={schedule.vibeChange === -1} onChange={() => setSchedule({ ...schedule, vibeChange: -1 })} />
                            <TextMuted><FaAngleDown /></TextMuted>
                            <Radio name="vibe" checked={schedule.vibeChange === 1} onChange={() => setSchedule({ ...schedule, vibeChange: 1 })} />
                            <TextMuted><FaAngleUp /></TextMuted>
                            <Radio name="vibe" checked={schedule.vibeChange === 2} onChange={() => setSchedule({ ...schedule, vibeChange: 2 })} />
                            <TextMuted><FaAngleDoubleUp /></TextMuted>
                            <Radio name="vibe" checked={schedule.vibeChange === 0} onChange={() => setSchedule({ ...schedule, vibeChange: 0 })} />
                            <TextMuted>Reset</TextMuted>
                        </RadioContainer>
                    </>
                    : <></>}

                {selectedSchedules.some(type => type.key === scheduleTypes.types.REGULATION) ?
                    <>
                        <TextMuted>Automation</TextMuted>

                        <RadioContainer>
                            <Radio name="regulation" checked={schedule.isRegulating} onChange={() => setSchedule({ ...schedule, isRegulating: true })} />
                            <TextMuted>On</TextMuted>
                            <Radio name="regulation" checked={!schedule.isRegulating} onChange={() => setSchedule({ ...schedule, isRegulating: false })} />
                            <TextMuted>Off</TextMuted>
                        </RadioContainer>
                    </>
                    : <></>}

                {selectedSchedules.some(type => type.key === scheduleTypes.types.MUTE) ?
                    <>
                        <TextMuted>Music</TextMuted>

                        <RadioContainer>
                            <Radio name="mute" checked={schedule.mute} onChange={() => setSchedule({ ...schedule, mute: true })} />
                            <TextMuted>Mute</TextMuted>
                            <Radio name="mute" checked={!schedule.mute} onChange={() => setSchedule({ ...schedule, mute: false })} />
                            <TextMuted>Unmute</TextMuted>
                        </RadioContainer>
                    </>
                    : <></>}

                {selectedSchedules.some(type => type.key === scheduleTypes.types.MOOD_SUSTAIN) ?
                    <>
                        <TextMuted>Mood sustain</TextMuted>

                        <RadioContainer>
                            <Radio name="moodsustain" checked={schedule.moodSustain} onChange={() => setSchedule({ ...schedule, moodSustain: true })} ></Radio>
                            <TextMuted>On</TextMuted>
                            <Radio name="moodsustain" checked={!schedule.moodSustain} onChange={() => setSchedule({ ...schedule, moodSustain: false })} ></Radio>
                            <TextMuted>Off</TextMuted>
                        </RadioContainer>
                    </> : <></>}

            </ColContainer>
        </> : <></>}

        {errorMessage ? <ErrorMessage><FaExclamationTriangle />&nbsp;{errorMessage}</ErrorMessage> : <></>}

        <ButtonRowContainer>
            <Button primary onClick={() => saveScheduleHandler()}>Save</Button>
            <Button secondary onClick={() => props.setShowScheduleModal(false)}>Cancel</Button>
        </ButtonRowContainer>

    </ModalContainer>
}

export default ScheduleForm;

function toScheduleTime(timeInput) {
    if (timeInput && timeInput.length === 5) {
        return `${timeInput.substring(0, 2)}${timeInput.substring(3, 5)}`;
    } else {
        return null;
    }
}

function toInputTime(scheduleTime) {
    if (scheduleTime?.length === 4) {
        return `${scheduleTime.substring(0, 2)}:${scheduleTime.substring(2, 4)}`;
    } else {
        return undefined;
    }
}