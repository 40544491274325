import { useEffect, useState } from "react";
import styled from "styled-components";
import { downloadFile, getAvailableDownloads } from "../../../scripts/networkScanner";
import theme from "../../../UI/theme";
import TopNavigation from "../../navigation/TopNavigation";
import NeutralSpinner from "../../UiComponents/Animations/NeutralSpinner";
import Card from "../../UiComponents/Card";
import WaDownload from "../../UiComponents/Icons/WaDownload";
import ViewContainer from "../../UiComponents/ViewContainer"
import ViewHeader from "../../UiComponents/ViewHeader";

const DownloadSection = styled(Card)`
    button {
        margin-right: 10px;
    }
`
const StyledLink = styled.a`
`
const StyledButton = styled.button`
    padding: 10px;
    border-radius: 4px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;

    &:hover {
        background-color: ${theme.colors.grayBorder};
        cursor: pointer;
    }

    svg {
        width: 1rem;
        stroke: ${theme.colors.greenEnergy};
    }
`

const DownloadListItem = styled.li`
    margin: 10px 0;
`
const downloadStates = {
    NONE: 0,
    LOADING: 1,
    COMPLETED: 2,
    FAILED: 3
}

const DownloadsView = ({ }) => {
    const [availableDownloads, setAvailableDownloads] = useState();
    const [downloadState, setDownloadState] = useState(downloadStates.NONE);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            const res = await getAvailableDownloads('HubFinder');
            setAvailableDownloads(res);
        } catch (err) {
            console.log('Failed to get available downloads');
        }
    }

    const startDownload = async (filename) => {
        setDownloadState(downloadStates.LOADING);
        try {
            await downloadFile(filename);
            setDownloadState(downloadStates.COMPLETED);
        } catch (err) {
            console.log('failed to download hubfinder');
            setDownloadState(downloadStates.FAILED);
        }
    }

    return <ViewContainer minWidth={'60%'}>
        <ViewHeader backLink={'/supportcenter'} headerText={'Downloads'} />

        <DownloadSection>
            <h3>Hubfinder</h3>
            <p>Find hubs on your local network. Make sure that you have Node installed before running Hubfinder <StyledLink href="https://nodejs.org/en/download/">(download and install node here)</StyledLink>.</p>
            <h4>Available versions</h4>
            {availableDownloads ? <>
                <ul>
                    {availableDownloads.map((filename, ix) => (
                        <DownloadListItem key={ix}>
                            <StyledButton key={ix} onClick={() => startDownload(filename)}>
                                <WaDownload />
                                <span>{filename}</span>
                            </StyledButton>
                        </DownloadListItem>
                    ))}

                </ul>
            </> : <></>}
            {downloadState === downloadStates.LOADING ? <>
                Downloading...<NeutralSpinner />
            </> : <></>}


        </DownloadSection>
    </ViewContainer>

}

export default DownloadsView;