import axios from 'axios';
import { toLocalISOString } from './common';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/userInteraction';

async function getUserInteractions(customerId, fromDate, toDate) {
    const res = await axios.get(`${hostname}${path}/userInteractions?customerId=${customerId}&from=${toLocalISOString(fromDate)}&to=${toLocalISOString(toDate)}`);
    return res.data;
}


async function postUserInteraction(customerId, userInteraction) {
    const res = await axios.post(`${hostname}/v2/userInteraction?customerId=${customerId}`, userInteraction);
    return res.data;
}

export {
    getUserInteractions,
    postUserInteraction
}