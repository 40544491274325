import styled from "styled-components";
import { updateZoneLiveSettingsList } from "../../../scripts/zone";
import WaSpeakers from "../../UiComponents/Icons/WaSpeakers";
import WaUsers from "../../UiComponents/Icons/WaUsers";
import hierarchyTypes from "../../settings/venue/device/hierarchyTypes";
import PanelButton from "../PanelButton"
import { postUserInteraction } from "../../../scripts/userInteraction";
import { userInteractionTypes } from "../../admin/customerDetails/userInteractionTypes";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useState } from "react";
import { useEffect } from "react";

const StyledPanelButton = styled(PanelButton)`
    display: ${props => props.display ? props.display : ''};
`

const GroupVibeButton = props => {
    const [disabled, setDisabled] = useState(false);
    const [hidden, setHidden] = useState(false);

    const isRegulatingString = props.zones?.map(zone => zone?.isRegulating)?.join(',');
    const zoneModeOrderIndexString = props.zones?.map(zone => zone?.zoneModeOrderIndex)?.join(',');

    useEffect(() => {
        checkDisabled();
        checkHidden();
    }, [isRegulatingString, zoneModeOrderIndexString])

    const getVibeUpdatedZone = (zone) => {
        if (zone?.shouldRegulate === 1 && zone?.isRegulating === 1 && zone?.hierarchyType !== hierarchyTypes.MASTER.value) {
            const newSuggestedValue = zone?.zoneModeOrderIndex + props.increment;
            const newVibe = Math.min(Math.max(-2, newSuggestedValue), 2);
            if (newVibe !== zone?.zoneModeOrderIndex) {
                return { zoneId: zone?.zoneId, zoneModeOrderIndex: newVibe };
            }
        }
        return null;
    }

    const clickHandler = async () => {
        const oldZonesCopy = JSON.parse(JSON.stringify(props.zones));

        const updatedZones = props.zones
            ?.map(zone => getVibeUpdatedZone(zone))
            ?.filter(zone => zone !== null);

        if (updatedZones?.length > 0) {
            await updateZoneLiveSettingsList(updatedZones);

            for (let index = 0; index < updatedZones?.length; index++) {
                const newZone = updatedZones[index];
                postUserInteraction(props.customerId, {
                    zoneId: newZone?.zoneId,
                    setting: userInteractionTypes.modeID.key,
                    fromValue: oldZonesCopy?.find(oldZone => oldZone?.zoneId === newZone?.zoneId)?.zoneModeOrderIndex,
                    toValue: newZone?.zoneModeOrderIndex
                });
            }
        }
    }

    const checkDisabled = () => {
        if (!props.zones?.some(zone =>
            zone?.isRegulating === 1 &&
            zone?.hierarchyType !== hierarchyTypes.MASTER.value &&
            Math.abs(zone?.zoneModeOrderIndex + props.increment) <= 2)) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }

    const checkHidden = () => {
        if (
            props.zones?.filter(zone => zone?.shouldRegulate === 1 && zone?.hierarchyType !== hierarchyTypes.MASTER.value)?.length <= 1 ||
            !props.zones?.some(zone => zone?.shouldRegulate === 1)) {
            setHidden(true);
        } else {
            setHidden(false);
        }
    }

    return <StyledPanelButton
        onClick={() => clickHandler()}
        disabled={disabled}
        display={hidden ? 'none' : ''}
    >
        {props.increment > 0 ? <>
            <FaPlus />
            Music
        </> : <></>}

        {props.increment < 0 ? <>
            <FaMinus />
            Music
        </> : <></>}
    </StyledPanelButton>
}

export default GroupVibeButton;