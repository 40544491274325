import { getDeviceNumber } from '../../../../scripts/common';
import { deleteSensor, updateSensors } from '../../../../scripts/sensor';
import { SensorIcon } from '../../../UiComponents/Icons';
import CheckboxField from '../../fields/CheckBoxField';
import StatusField from '../../fields/StatusField';
import { DisplayField } from '../../fields/Styles';
import { hideFormNetworkField } from '../hub/actions/FormNetwork';
import OpenNetworkButton from '../hub/actions/OpenNetworkButton';
import { SensorZigbeeSetupButton } from '../hub/actions/ZigbeeConfig';
import { hideInList } from '../../table/tableUtils';
import { getSensorDisplayStatus, getSensorStatus, getSensorStatusColor } from '../../utils/connectionStatus';
import { actionTypes } from '../../utils/installationTypes';
import BatteryDisplayField from './fields/BatteryDisplayField';
import BlinkButtonField from './fields/BlinkButtonField';
import NodeTypeField from './fields/NodeTypeField';
import ZigbeePropsDisplayField from './fields/ZigbeePropsDisplayField';
import ZoneChoiceField, { getZoneFieldRequiredState } from './fields/ZoneChoiceField';
import { getFullSensorDisplayStatus } from './sensorUtils';
import SleepLimitField, { getSleepLimitFieldRequiredState } from './fields/SleepLimitField';
import { getHubDisplayName } from '../hub/hubUtils';
import PowerSourceChoiceField, { getPowerSourceFieldRequiredState } from './fields/PowerSourceChoiceField';
import InstallationInfoButton from '../../fields/components/InstallationInfoButton';
import heatmapTypes from '../../../heatmap/container/heatmapTypes';

const idField = 'sensorId';

export const getSensorTableProps = ({ sensors, zones, hubs, customerId, serverStatus, displayMode }) => {

    const getColumns = (sensors) => [{
        key: 'sensorId',
        displayName: 'Id',
        width: '100px',
        readOnly: true,
        render: (sensor) => (
            <DisplayField>{sensor.sensorId}</DisplayField>
        )
    }, {
        displayName: 'Status',
        key: 'status',
        hide: hideInList(displayMode),
        render: (sensor) => {
            const hub = hubs.find(hub => hub.hubId === sensor.hubId);
            const status = getSensorStatus(sensor, hub, serverStatus);
            return <StatusField
                displayStatus={getSensorDisplayStatus(status)}
                statusColor={getSensorStatusColor(status)}
            />
        }
    }, {
        key: 'hubId',
        displayName: 'Hub',
        width: '100px',
        readOnly: true,
        render: (sensor) => (
            <DisplayField>
                {getHubDisplayName(hubs.find(hub => hub.hubId === sensor.hubId), sensor.hubId)}
            </DisplayField>
        )
    }, {
        key: 'zoneId',
        displayName: 'Zone',
        width: '120px',
        getRequiredState: (sensor) => getZoneFieldRequiredState(sensor, zones),
        render: (sensor, updateSensor, cellControl) => (
            <ZoneChoiceField
                sensor={sensor}
                updateSensor={updateSensor}
                zones={zones}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'isConnected',
        displayName: 'Connected',
        width: '50px',
        readOnly: true,
        render: (sensor) => (
            <DisplayField>{sensor.isConnected}</DisplayField>
        )
    }, {
        key: 'version',
        displayName: 'Version',
        width: '50px',
        readOnly: true,
        render: (sensor) => (
            <DisplayField>{sensor.version}</DisplayField>
        )
    }, {
        key: 'nodeType',
        displayName: 'NodeType',
        width: '50px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (sensor) => (
            <NodeTypeField nodeType={sensor.nodeType} />
        )
    }, {
        key: 'powerSource',
        displayName: 'Power Source',
        width: '120px',
        readOnly: true,
        getRequiredState: getPowerSourceFieldRequiredState,
        render: (sensor, updateSensor, cellControl) => (
            <PowerSourceChoiceField
                powerSource={sensor.powerSource}
                setValue={(value) => updateSensor({ powerSource: value })}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'enableOta',
        displayName: 'Enable Ota',
        width: '50px',
        readOnly: true,
        render: (sensor, updateSensor, cellControl) => (
            <CheckboxField
                checked={sensor.enableOta}
                setValue={() => updateSensor({ enableOta: +!sensor.enableOta })}
                cellControl={cellControl}
            />
        )
    }, {
        key: 'otaDownloadPercentage',
        displayName: 'Ota download %',
        width: '50px',
        readOnly: true,
        render: (sensor) => (
            <DisplayField>{sensor.otaDownloadPercentage}</DisplayField>
        )
    }, {
        key: 'sleepLimit',
        displayName: 'SleepLimit (dB)',
        width: '50px',
        getRequiredState: (sensor) => getSleepLimitFieldRequiredState(sensor),
        readOnly: true,
        render: (sensor, updateSensor, cellControl) => (
            <SleepLimitField
                value={sensor.sleepLimit}
                setValue={(value) => updateSensor({ sleepLimit: value })}
                cellState={cellControl.cellState}
            />
        )
    }, {
        key: 'appMode',
        displayName: 'AppMode',
        width: '50px',
        readOnly: true,
        render: (sensor) => (
            <DisplayField>{sensor.appMode}</DisplayField>
        )
    }, {
        key: 'nodeId',
        displayName: 'NodeId',
        width: '50px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (sensor) => (
            <DisplayField>{sensor.nodeId}</DisplayField>
        )
    }, {
        key: 'parentNodeId',
        displayName: 'ParentNode',
        width: '50px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (sensor) => (
            <DisplayField>{sensor.parentNodeId}</DisplayField>
        )
    }, {
        key: 'lqi',
        displayName: 'LQI',
        width: '50px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (sensor) => (
            <DisplayField>{sensor.lqi}</DisplayField>
        )
    }, {
        key: 'batteryLevel',
        displayName: 'Battery%',
        width: '50px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (sensor) => (
            <DisplayField>{sensor.batteryLevel}%</DisplayField>
        )
    }, {
        key: 'batteryTemperature',
        displayName: 'Bat Temp',
        width: '50px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (sensor) => (
            <DisplayField>{sensor.batteryTemperature}°C</DisplayField>
        )
    }, {
        key: 'externalVoltage',
        displayName: 'Ext volt',
        width: '50px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (sensor) => (
            <DisplayField>{sensor.externalVoltage}V</DisplayField>
        )
    }, {
        key: 'blink',
        displayName: 'Blink',
        width: '50px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (sensor) => (
            <BlinkButtonField sensor={sensor} />
        )
    }, {
        key: 'installationDocumentation',
        displayName: 'Doc',
        width: '40px',
        readOnly: true,
        hide: hideInList(displayMode),
        render: (sensor) => (
            <InstallationInfoButton 
                customerId={sensor.customerId} 
                deviceId={sensor.sensorId} 
                deviceType={heatmapTypes.elementTypes.SENSOR} 
                name={`Sensor ${getDeviceNumber(sensor)}`}
            />
        )
    }]

    const saveObjects = async (changedSensors) => {
        try {
            await updateSensors(changedSensors)
        } catch (err) {
            console.log('Failed to update sensors');
        }
    }

    const deleteObject = async (sensor) => {
        try {
            await deleteSensor(sensor.sensorId);
        } catch (err) {
            console.log('Error failed to delete sensor');
        }
    }

    const getActions = (sensor) => [{
        key: 'blink',
        type: actionTypes.PRIMARY,
        render: () => (
            <BlinkButtonField sensor={sensor} />
        )
    },{
        key: 'installationinfo',
        type: actionTypes.PRIMARY,
        render: () => (
            <InstallationInfoButton 
                customerId={sensor.customerId} 
                deviceId={sensor.sensorId} 
                deviceType={heatmapTypes.elementTypes.SENSOR} 
                name={`Sensor ${getDeviceNumber(sensor)}`}
            />
        )
    }, {
        key: 'openNetwork',
        type: actionTypes.LIST,
        hide: !hideFormNetworkField(hubs),
        render: () => (
            <OpenNetworkButton hubs={hubs} />
        )
    }]

    const getListItemProps = (sensor) => {
        const hub = hubs.find(hub => hub.hubId === sensor.hubId);
        const status = getSensorStatus(sensor, hub, serverStatus);
        return {
            displayName: `Sensor ${getDeviceNumber(sensor)}`,
            Icon: SensorIcon,
            displayStatus: getFullSensorDisplayStatus(sensor, status),
            statusColor: getSensorStatusColor(status),
            renderCustomComponent: () => <>
                <BatteryDisplayField sensor={sensor} />
                <ZigbeePropsDisplayField sensor={sensor} />
            </>
        }
    }

    const sortObjects = (sensors) => {
        const groups = [];
        const zoneIds = zones.map(zone => zone.zoneId);
        if (sensors && sensors.length) {
            zones.forEach(zone => {
                const zoneSensors = sensors.filter(sensor => sensor.zoneId === zone.zoneId);
                if (zoneSensors.length) {
                    groups.push({
                        title: zone.zoneName,
                        objects: zoneSensors
                    })
                }
            });

            const sensorsWithoutZone = sensors.filter(sensor => !zoneIds.includes(sensor.zoneId));
            if (sensorsWithoutZone.length) {
                groups.push({
                    title: 'Without zone',
                    objects: sensorsWithoutZone
                })
            }
        }

        return groups;
    }

    const getPlaceholder = () => {
        return <div>
            {hideFormNetworkField(hubs) ? <>
                <p>No sensors have been added. Open the zigbee network and add sensors by double clicking the status-button</p>
                <OpenNetworkButton hubs={hubs} />
            </> : <>
                <p>A zigbee network has not yet been created. Press the button below to start zigbee setup.</p>
                <SensorZigbeeSetupButton hubs={hubs} />
            </>}
        </div>
    }


    return {
        title: 'Sensors',
        getColumns,
        parentObjects: sensors,
        getListItemProps,
        idField,
        saveObjects,
        deleteObject,
        sortObjects,
        getActions,
        getPlaceholder
    }
}