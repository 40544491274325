import styled from "styled-components";
import MasterCalibration from "../components/settings/venue/calibration/master/MasterCalibration";
import ZoneGroupCalibrationItem from "../components/settings/venue/calibration/zone/ZoneGroupCalibrationItem";
import hierarchyTypes from "../components/settings/venue/device/hierarchyTypes";
import { getSourceSelectorsInZoneGroup, getSourceSelectorsWithoutZoneGroup, getZonesInZoneGroup, getZonesWithoutZoneGroup } from "../components/settings/venue/group/zoneGroupUtils";
import ViewHeader from "../components/UiComponents/ViewHeader";
import { useSubscription } from "../hooks/useSubscription";
import { subscriptionsTypes } from "../scripts/subscription/subscriptionTypes";

const CalibrationViewContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;

    touch-action: manipulation;
    user-select: none;
`

const CalibrationView = props => {
    const [zoneGroups, setZoneGroups] = useSubscription(subscriptionsTypes.zoneGroups);
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);
    const [zoneLives, setZoneLives] = useSubscription(subscriptionsTypes.zoneLives);
    const [sourceSelectors, setSourceSelectors] = useSubscription(subscriptionsTypes.sourceSelectors);
    const [processors, setProcessors] = useSubscription(subscriptionsTypes.processors);
    const [sources, setSources] = useSubscription(subscriptionsTypes.sources);

    const sourceSelectorsWithoutZoneGroup = getSourceSelectorsWithoutZoneGroup(sourceSelectors, zoneGroups);
    const zonesWihoutZoneGroup = getZonesWithoutZoneGroup(zones, sourceSelectors, zoneGroups)?.filter(zone => zone.hierarchyType !== hierarchyTypes.MASTER.value);

    return <>
        <ViewHeader headerText={'Calibration'} backLink={'/settings'} showVenueName={true} />

        <CalibrationViewContainer>

            {zones?.some(zone => zone.hierarchyType === hierarchyTypes.MASTER.value) ?
                <MasterCalibration
                    zones={zones?.filter(zone => zone.hierarchyType === hierarchyTypes.MASTER.value)}
                    zoneLives={zoneLives}
                    sourceSelectors={sourceSelectors}
                    processors={processors}
                    sources={sources}
                />
                : <></>}

            {zoneGroups?.sort((a, b) => { return a?.orderIndex - b?.orderIndex })?.map(zoneGroup => {
                let sourceSelectorsInZoneGroup = getSourceSelectorsInZoneGroup(sourceSelectors, zoneGroup);
                let zonesInZoneGroup = getZonesInZoneGroup(zones, sourceSelectors, zoneGroup)?.filter(zone => zone.hierarchyType !== hierarchyTypes.MASTER.value);

                return <ZoneGroupCalibrationItem
                    key={zoneGroup?.zoneGroupId}
                    zoneGroup={zoneGroup}
                    sourceSelectors={sourceSelectorsInZoneGroup}
                    zones={zonesInZoneGroup}
                    processors={processors}
                    sources={sources}
                    zoneLives={zoneLives}
                />
            })}

            <ZoneGroupCalibrationItem
                key={`ZonesWithoutZoneGroup${props.customerId}`}
                zoneGroup={{ name: 'Zones' }}
                sourceSelectors={sourceSelectorsWithoutZoneGroup}
                zones={zonesWihoutZoneGroup}
                processors={processors}
                sources={sources}
                zoneLives={zoneLives}
            />
        </CalibrationViewContainer >
    </>
}

export default CalibrationView;