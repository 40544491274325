import ResizeObserver from 'resize-observer-polyfill';
import { Chart, registerables } from 'chart.js';

const chartJsSetup = () => {
    Chart.defaults.font.family = 'Karelia';
    Chart.register(...registerables);
}

const checkResizeObserver = () => {
    try {
        if (!window.ResizeObserver) {
            window.ResizeObserver = ResizeObserver;
        }
    } catch (error) {
        console.log("Could not add resize observer.");
    }
}

export {
    checkResizeObserver,
    chartJsSetup
}