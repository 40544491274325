import { useContext, useEffect, useRef, useState } from "react";
import { loadingStates } from "../../../installation/installationTypes";
import { getVenues, getUsers } from "../../../../scripts/permission";
import UserContext from "../../../../hooks/UserContext";
import VenuePermissions from "./venuePermission/VenuePermissions";
import Selector from "../../../UiComponents/Selector";
import UserPermissions from "./userPermission/UserPermissions";
import LoadDataContainer, { loadDataHandler } from "../../../UiComponents/containers/LoadDataContainer";
import Modal from "../../../UiComponents/Modal";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../../../panel/ModalStyles";
import Button from "../../../UiComponents/Button";
import { UserAdminSectionContainer, UserAdministrationViewContainer } from "./styles";

const viewKeys = {
    venue: 'venue',
    user: 'user'
}

const viewItems = [{
    key: viewKeys.venue,
    display: 'Venues'
}, {
    key: viewKeys.user,
    display: 'Users'
}]

const UserAdministrationView = () => {
    const userContext = useContext(UserContext);
    const [viewState, _setViewState] = useState({
        view: viewKeys.venue,
        venueId: userContext.customer?.customerId,
        userId: null
    });
    const [showModal, setShowModal] = useState(false);
    const nextState = useRef();
    const permissionTableRef = useRef({});
    const [venues, setVenues] = useState();
    const [users, setUsers] = useState();
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    useEffect(() => {
        loadDataHandler(getData, setLoadingState);
    }, [])

    const getData = async () => {
        const venuesRes = await getVenues();
        const usersRes = await getUsers();
        setUsers(usersRes);
        setVenues(venuesRes);
    }

    const setViewState = (viewState) => {
        if (permissionTableRef.current.isEdited) {
            nextState.current = viewState;
            setShowModal(true);
        } else {
            _setViewState(viewState);
        }
    }

    return <>
        <UserAdministrationViewContainer>
            <LoadDataContainer loadingState={loadingState}>
                {venues && users ? <>
                    {venues.length > 1 ? <UserAdminSectionContainer>
                        <Selector
                            large
                            items={viewItems}
                            selectedItem={viewItems.find(i => i.key === viewState.view)}
                            onClick={(item) => setViewState(current => ({ ...current, view: item.key }))}
                        />
                    </UserAdminSectionContainer> : <></>}

                    {viewState.view === viewKeys.venue ? <>
                        <VenuePermissions
                            venues={venues}
                            users={users}
                            viewState={viewState}
                            setViewState={setViewState}
                            permissionTableRef={permissionTableRef}
                            refreshUsers={getData}
                        />
                    </> : <></>}

                    {viewState.view === viewKeys.user ? <>
                        <UserPermissions
                            venues={venues}
                            users={users}
                            viewState={viewState}
                            setViewState={setViewState}
                            permissionTableRef={permissionTableRef}
                            refreshUsers={getData}
                        />
                    </> : <></>}
                </> : <></>}
            </LoadDataContainer>
        </UserAdministrationViewContainer>
        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <ModalHeader>Continue without saving?</ModalHeader>
                <ModalText>You have unsaved changes</ModalText>
                <ButtonRowContainer>
                    <Button primary onClick={() => { _setViewState(nextState.current); setShowModal(false) }}>Continue</Button>
                    <Button secondary onClick={() => setShowModal(false)}>Cancel</Button>
                </ButtonRowContainer>
            </ModalContainer>
        </Modal>
    </ >
}

export default UserAdministrationView;