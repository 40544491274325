import { FaRegHdd, FaSolarPanel, FaUser } from "react-icons/fa";
import { TableCell } from "../../../installation/fields/Styles";
import { HeaderRow, StyledTable } from "../../../installation/table/styles/TableStyles";
import { entityTypes, includesPermission, permissionTypes } from "../../../permission/permissions";
import Checkbox from "../../../UiComponents/Checkbox";
import { BarIcon, SettingsIcon } from "../../../UiComponents/Icons";
import { getUserDisplayName, permissionTableTypes } from "./userAdministrationUtils";

const columns = [{
    value: 'panel',
    icon: FaSolarPanel,
    display: 'Panel',
    subColumns: [permissionTypes.panelViewWrite, permissionTypes.panelViewRead]
}, {
    value: 'insights',
    icon: BarIcon,
    display: 'Insights',
    subColumns: [permissionTypes.insightsRead]
}, {
    value: 'settings',
    icon: SettingsIcon,
    display: 'Settings',
    subColumns: [permissionTypes.settingsWrite, permissionTypes.settingsRead]
}, {
    value: 'calibration',
    display: 'Calibration',
    subColumns: [permissionTypes.calibrationWrite, permissionTypes.calibrationRead]
}, {
    value: 'installation',
    icon: FaRegHdd,
    display: 'Installation',
    subColumns: [permissionTypes.installationViewWrite, permissionTypes.installationViewRead]
}, {
    value: 'useradministration',
    icon: FaUser,
    display: 'User adm',
    subColumns: [permissionTypes.userAdministrationWrite, permissionTypes.userAdministrationRead]
}]


const CustomPermissionsTable = ({ tableType, permissionObjects, permissions, setPermissions }) => {

    const onChange = (permissionObject, permissionType, toState) => {
        let updatedPermissions;
        if (toState) {
            updatedPermissions = [...permissions, {
                userId: permissionObject.userId,
                permissionType,
                entityType: entityTypes.venue,
                entityId: permissionObject.venueId.toString()
            }];
        } else {
            updatedPermissions = permissions.filter(p => !(p.entityId === permissionObject.venueId.toString() && p.userId === permissionObject.userId && p.permissionType === permissionType))
        }
        setPermissions(updatedPermissions);
    }

    return <StyledTable>
        <thead>
            <HeaderRow>
                <th rowSpan="2">{tableType === permissionTableTypes.venue ? 'User' : 'Venue'}</th>
                {columns.map(col => {
                    const Icon = col.icon;
                    return <th key={col.value} colSpan={col.subColumns.length} style={{ textAlign: 'center' }}>
                        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {col.icon ? <>
                            <Icon style={{marginRight: '6px', height: '20px'}}/>
                        </> : <></>}
                        {col.display}
                        </div>
                    </th>
                })}
            </HeaderRow>
            <HeaderRow>

                {columns.map(col => col.subColumns.map(subCol => {
                    return <th key={subCol} style={{ textAlign: 'center' }}>
                        {subCol.includes('.read') ? 'Read' : 'Write'}
                    </th>
                })
                )}
            </HeaderRow>
        </thead>
        <tbody>
            {permissionObjects.map(permissionObject => <tr key={permissionObject.userId}>
                <TableCell>{tableType === permissionTableTypes.venue ? getUserDisplayName(permissionObject) : permissionObject.companyName}</TableCell>
                {columns.map(col => col.subColumns.map(subCol => {
                    const hasPermission = permissionObject.permissions.some(p => p.permissionType === subCol);
                    const hasPermissionAsChild = !hasPermission && permissionObject.permissions.some(p => includesPermission(p.permissionType, subCol));
                    return <TableCell key={subCol}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Checkbox
                                checked={hasPermission || hasPermissionAsChild}
                                disabled={hasPermissionAsChild}
                                onChange={() => onChange(permissionObject, subCol, !hasPermission)}
                            />
                        </div>
                    </TableCell>
                })
                )}
            </tr>)}
        </tbody>
    </StyledTable>
}

export default CustomPermissionsTable;