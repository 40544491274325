
const displayModes = {
    TABLE: 0,
    LIST: 1
}

const actionTypes = {
    PRIMARY: 0,
    SECONDARY: 1,
    LIST: 2
}

const requiredStates = {
    REQUIRED: 0,
    RECOMMENDED: 1,
    OPTIONAL: 2
}

export {
    displayModes,
    actionTypes,
    requiredStates
}