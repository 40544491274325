import styled from "styled-components";
import theme from "../../UI/theme";
import { FaPhone, FaEnvelope } from 'react-icons/fa';

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: fill-available;
    color: ${theme.colors.textGray};
    align-items: center;
    margin: 30px 0 30px 0;
    justify-content: center;
`

const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
`

const Link = styled.a`
    font-size: ${theme.fontSize.small};
    color: ${theme.colors.textGray};
    text-decoration: none;
    margin-left: 5px;

    &:hover {
        text-decoration: underline;
    }
`

const supportContacts = [
    {
        phone: '+4722222233',
        phone_display: '+47 22 22 22 33',
        email: 'support@waved.no',
        countries: ['Norway']
    },
    {
        phone: '+4408083045163',
        phone_display: '+44 (0) 808 304 5163',
        email: 'support@waved.co',
        countries: ['UK', 'Austria']
    }
]

const Footer = props => {

    const addressParts = props?.address?.split(',');
    const addressCountry = addressParts?.[addressParts?.length - 1]?.trim();
    const contacts = supportContacts?.find(contacts => contacts?.countries?.some(country => country.toLowerCase() === addressCountry?.toLowerCase())) ?? supportContacts[0];

    return <FooterContainer>
        <LinkContainer>
            <FaPhone />
            <Link href={`tel:${contacts?.phone}`}>{contacts?.phone_display}</Link>
        </LinkContainer>
        <LinkContainer>
            <FaEnvelope />
            <Link href={`mailto: ${contacts?.email}`}>{contacts?.email}</Link>
        </LinkContainer>
    </FooterContainer>
}

export default Footer;