import { useContext } from "react"
import UserContext from "../../hooks/UserContext"
import { hasVenuePermission } from "./permissions";
import styled from "styled-components";
import theme from "../../UI/theme";
import Card from "../UiComponents/Card";
import Button from "../UiComponents/Button";
import { useState } from "react";

const Container = styled(Card)`
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding: 12px;
    margin-top: 12px;
    
    h4, p {
        padding: 0;
        margin: 0;
    }
    h4 {
        color: ${theme.colors.greenEnergy};
        font-weight: normal;
    }

    p {
        color: ${theme.colors.textGray};   
    }
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        max-width: 70em;
    }
`

const ReadOnlyInfo = ({ readPermissionType, writePermissionType }) => {
    const userContext = useContext(UserContext);
    const [show, setShow] = useState(true);

    if (show && hasVenuePermission(userContext, readPermissionType) && !hasVenuePermission(userContext, writePermissionType)) {
        return <Container>
            <div>
                <h4>Read only</h4>
                <p>You can view the content, but not make any changes to it</p>
            </div>
            <div>
                <Button tertiary small onClick={() => setShow(false)}>Hide</Button>
            </div>
        </Container>
    } else {
        return <></>
    }
}

export default ReadOnlyInfo;