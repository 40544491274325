import React from 'react';
import styled from 'styled-components';
import theme from '../../UI/theme';

const Wrapper = styled.span`
    display: inline-block;
    position: relative;
    bottom: 6px;
`

const BadgeContainer = styled.div`
    background-color: ${theme.colors.greenEnergy};
    padding: 1px 2px;
    border-radius: 4px;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const BadgeContent = styled.div`
    color: black;
    font-size: 9px;
    font-weight: normal;
    padding-top: 0;
`

const NewBadge = props => {
    return <Wrapper>
        <BadgeContainer>
            <BadgeContent>{props.text}</BadgeContent>
        </BadgeContainer>
    </Wrapper>
}

export default NewBadge;