import { useState } from "react"
import { FaSave } from "react-icons/fa"
import styled, { css } from "styled-components"
import theme from "../../../UI/theme"
import NeutralSpinner from "../../UiComponents/Animations/NeutralSpinner"
import { loadingStates } from "../installationTypes"
import { ListActionButton } from "./styles/ActionStyles"

const StyledButton = styled(ListActionButton)`
    ${props => !props.disabled && css`
        color: ${theme.colors.darkSpace};
        svg {
            color: ${theme.colors.darkSpace};
        }
        background-color: ${theme.colors.greenEnergy};
    `}
`

const SaveButton = ({ isEdited, saveHandler }) => {
    const [loadingState, setLoadingState] = useState(loadingStates.NONE);

    const onClick = async () => {
        try {
            const timeout = setTimeout(() => {
                setLoadingState(loadingStates.LOADING);
            }, 250);
            await saveHandler();
            clearTimeout(timeout);
            setLoadingState(loadingStates.NONE);
        } catch (err) {

        } finally {
            setLoadingState(loadingStates.NONE);
        }
    }
    return <StyledButton disabled={!isEdited()} onClick={onClick}>
        {loadingState === loadingStates.LOADING ? <>
            <NeutralSpinner />
        </> : <>
            <FaSave />
            <label>Save</label>
        </>}

    </StyledButton>
}

export default SaveButton;