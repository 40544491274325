import { sybGraphQl } from './SoundtrackYourBrandGraphQl';
import playFromTypes from '../../playFromTypes';
import { executeSYB } from '../../../../scripts/musicPlayer';
import { createApolloClient, createWebSocketLink } from "./GraphQLWebSocket";
import { gql } from "@apollo/client";

const SYB_WSS_URL = 'wss://api.soundtrackyourbrand.com/v2/graphql-transport-ws';

class SoundtrackYourBrandPlayer {
    constructor(playerInfo) {
        this.id = playerInfo?.remoteId;
        this.customerId = playerInfo.customerId;
        this.musicPlayerId = playerInfo.musicPlayerId;
        this.token = playerInfo?.token;
        this.refreshToken = playerInfo?.refreshToken;
        this.nowPlayingSubscription = undefined;
        this.playbackSubscription = undefined;
        this.apolloClient = undefined;
        this.config = {
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': "*",
            }
        };
    };

    async checkAvailablePlayers(credentials) {
        const email = credentials.email;
        const password = credentials.password;
        const login = await this.login(email, password);
        if (!login) return false;
        const zones = await this.getZones();
        if (!zones) return false;
        const availablePlayers = {
            token: this.token,
            refreshToken: this.refreshToken,
            zones: zones,
            type: 'SoundtrackYourBrand'
        }
        return availablePlayers;
    }

    async execute(query, config) {
        try {
            let result = await executeSYB(this.customerId, this.musicPlayerId, query, config);
            if (result.errors) {
                result.errors?.forEach(error => {
                    console.log("SYB Error:", error, "while executing query", query);
                    return false;
                })
            } else {
                return result.data;
            }
        } catch (e) {
            console.log("SYB Error:", e, "while executing query", query);
            return false;
        }
    }

    async login(email, password) {
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const query = sybGraphQl.mutation.login(email, password);
        try {
            const result = await this.execute(query, config);
            if (!result) return false;
            this.token = result.loginUser.token;
            this.refreshToken = result.loginUser.refreshToken;
            this.config = {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': "*",
                }
            };
            return true;
        } catch (err) {
            console.log(err);
            console.log("Not able to fetch SYB-Token");
            return false;
        }
    }

    async getZones() {
        const query = sybGraphQl.query.getZones();
        const result = await this.execute(query, this.config);
        if (!result) return false;
        var zones = []
        try {
            const accounts = result.me.accounts.edges;
            accounts.forEach(a => {
                var locations = a.node.locations.edges;
                locations.forEach(l => {
                    var lZones = l.node.soundZones.edges;
                    lZones.forEach(z => {
                        var zone = z.node;
                        zone.account = a.node.businessName;
                        zone.location = l.node.name;
                        zone.address = l.node.address;
                        zones.push(zone);
                    })
                })
            });
        } catch {
            console.log('ERROR: Failed to fetch SYB zones');
            return false;
        }
        return zones;
    }

    async play() {
        const mutation = sybGraphQl.mutation.play(this.id);
        const result = await this.execute(mutation, this.config);
        return result;
    }

    async pause() {
        const mutation = sybGraphQl.mutation.pause(this.id);
        const result = await this.execute(mutation, this.config);
        return result;
    }

    async nextSong() {
        const mutation = sybGraphQl.mutation.nextSong(this.id);
        const result = await this.execute(mutation, this.config);
        return result;
    }

    async queuePlaylist(playlistId) {
        const query = sybGraphQl.mutation.queuePlaylist(this.id, playlistId)
        const result = await this.execute(query, this.config);
        return result
    }

    async playPlaylist(playlistID) {
        const query = sybGraphQl.mutation.setPlayFrom(this.id, playlistID);
        const result = await this.execute(query, this.config);
        return result;
    }

    async getState() {
        const query = sybGraphQl.query.state(this.id);
        const result = await this.execute(query, this.config);
        if (result.soundZone.playback.state === 'playing') return 'PLAYING';
        else if (result.soundZone.playback.state === 'paused') return 'PAUSED';
        else return false;
    }

    async getVolume() {
        const query = sybGraphQl.query.getVolume(this.id);
        const result = await this.execute(query, this.config);
        if (result.soundZone) return result.soundZone.playback.volume;
        else return false;
    }

    async setVolume(volume) {
        if (0 > volume || volume > 16) return false;
        const query = sybGraphQl.query.setVolume(this.id, volume);
        const result = await this.execute(query, this.config);
        return result;
    }

    async getPlayingMusic() {
        const query = sybGraphQl.query.getPlayingMusic(this.id);
        var playingInfo = await this.execute(query, this.config);
        playingInfo = playingInfo?.nowPlaying;
        const state = await this.getState();
        const volume = await this.getVolume();
        var artists = [];
        playingInfo?.track?.artists?.forEach(artist => { artists.push(artist.name) });

        const music = {
            state: state,
            volume: volume,
            songTitle: playingInfo?.track?.title,
            artists: artists,
            album: playingInfo?.track?.album?.title,
            playFrom: { id: playingInfo?.playFrom?.id, name: playingInfo?.playFrom?.name, type: this.resolvePlayFromType(playingInfo?.playFrom?.__typename) },
            imageUrl: playingInfo?.track?.album?.image?.url,
            songColor: playingInfo?.track?.display?.colors?.primary?.hex,
        }
        return music;
    }

    async getAvailablePlaylists() {
        const query = sybGraphQl.query.getAvailablePlaylists(this.id);
        const result = await this.execute(query, this.config);
        const pl = result.soundZone.account.musicLibrary.playlists.edges;
        var playlists = [];
        pl.forEach(element => {
            var list = {
                name: element.node.name,
                id: element.node.id,
                imageUrl: element.node.display.image.placeholder
            }
            playlists.push(list);
        });
        return playlists;
    }

    async getAvailableSchedules() {
        const query = sybGraphQl.query.getAvailableSchedules(this.id);
        const result = await this.execute(query, this.config);
        const scheduleList = result?.soundZone?.account?.musicLibrary?.schedules?.edges;
        let schedules = [];
        scheduleList?.forEach(scheduleElement => {
            let schedule = {
                name: scheduleElement?.node?.name,
                id: scheduleElement?.node?.id,
                imageUrl: scheduleElement?.node?.display?.image?.placeholder
            };
            schedules.push(schedule);
        });
        return schedules;
    }

    async checkAuthorization() {
        const query = sybGraphQl.query.authentication(this.id);
        const result = await this.execute(query, this.config);
        try {
            if (result.soundZone.id) return true;
            else return false;
        } catch (e) {
            return false;
        }
    }

    async checkConnection() {
        const query = sybGraphQl.query.connection(this.id);
        const result = await this.execute(query, this.config);
        try {
            if (result?.soundZone?.online === true && result?.soundZone?.isPaired === true) {
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    }

    async wakeupPlayer() {
        this.play();
        return true;
    }

    async updateAuthorization() {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const mutation = sybGraphQl.mutation.updateAuthorization(this.refreshToken);
            const result = await this.execute(mutation, config);
            var newToken = result.refreshLogin.token;
            var newRefreshToken = result.refreshLogin.refreshToken;
            return ({
                token: newToken,
                refreshToken: newRefreshToken
            })
        } catch (err) {
            console.log(err);
            console.log("Not able to refresh SYB-Token");
            return false;
        }
    }

    async subscribe(musicPlayer) {
        const wslink = createWebSocketLink(SYB_WSS_URL, this.token);
        this.apolloClient = createApolloClient(wslink);

        // NOW PLAYING SUBSCRIPTION
        const nowPlayingObserver = this.apolloClient.subscribe({
            query: gql`${sybGraphQl.subscription.nowPlaying(this.id)}`,
            variables: { input: { soundZone: this.id } }
        });

        if (this.nowPlayingSubscription?.unsubscribe) {
            this.nowPlayingSubscription?.unsubscribe();
        }
        this.nowPlayingSubscription = nowPlayingObserver.subscribe({
            next: (data) => {
                this.nowPlayingSubscriptionCallback(musicPlayer, data);
            },
            error: (error) => {
                this.subscribe(musicPlayer);
            }
        })

        // PLAYBACK SUBSCRIPTION
        const playbackObserver = this.apolloClient.subscribe({
            query: gql`${sybGraphQl.subscription.playback(this.id)}`,
            variables: { input: { soundZone: this.id } }
        });

        if (this.playbackSubscription?.unsubscribe) {
            this.playbackSubscription?.unsubscribe();
        }
        this.playbackSubscription = playbackObserver.subscribe({
            next: (data) => {
                this.playbackSubscriptionCallback(musicPlayer, data);
            },
            error: (error) => {
                this.subscribe(musicPlayer);
            }
        })
    }

    async cleanup() {
        try {
            if (this.nowPlayingSubscription) {
                this.nowPlayingSubscription.unsubscribe();
            }
            if (this.playbackSubscription) {
                this.playbackSubscription.unsubscribe();
            }
            if (this.apolloClient) {
                this.apolloClient.stop();
            }
        } catch (e) {
            console.log('Error disconnecting sockets');
            console.log(e);
        }
    }

    nowPlayingSubscriptionCallback = (musicPlayer, data) => {
        try {
            const track = data?.data?.nowPlayingUpdate?.nowPlaying?.track;
            const playFrom = data?.data?.nowPlayingUpdate?.nowPlaying?.playFrom;
            const newData = {
                songTitle: track?.title,
                album: track?.album?.title,
                playFrom: {
                    id: playFrom?.id,
                    name: playFrom?.name,
                    type: this.resolvePlayFromType(playFrom?.__typename)
                },
                imageUrl: track?.album?.image?.url,
                songColor: track?.display?.colors?.primary?.hex,
                artists: track?.artists?.map(artist => artist?.name)
            };
            musicPlayer?.subscriptionCallback(newData);
        } catch (error) {
            console.log("Soundtrack your brand nowplaying subscription error");
            this.getPlayingMusic().then(data => {
                musicPlayer?.subscriptionCallback(data);
            })
        }
    }

    playbackSubscriptionCallback = (musicPlayer, data) => {
        try {
            const state = data?.data?.playbackUpdate?.playback?.state;
            const volume = data?.data?.playbackUpdate?.playback?.volume;
            const newData = {
                state: state === 'playing' ? 'PLAYING' : state === 'paused' ? 'PAUSED' : '',
                volume: volume
            };
            musicPlayer.subscriptionCallback(newData);
        } catch (error) {
            console.log('Soundtrack your brand playback subscription error');
            this.getState().then((state) => {
                musicPlayer.subscriptionCallback({ state: state });
            });
            this.getVolume().then((volume) => {
                musicPlayer.subscriptionCallback({ volume: Number(volume) });
            })
        }
    }

    resolvePlayFromType = typename => {
        return typename === 'Playlist' ? playFromTypes.PLAYLIST.key :
            typename === 'Schedule' ? playFromTypes.SCHEDULE.key :
                typename === undefined ? playFromTypes.NULL :
                    playFromTypes.UNKNOWN.key;
    }

}
export default SoundtrackYourBrandPlayer;