import { useEffect, useState } from "react";
import trendTypes, { dataTypes, hierarchyLevels, periods } from "../../statistics/trendTypes";
import { subscriptionsTypes } from "../../../scripts/subscription/subscriptionTypes";
import { useSubscription } from "../../../hooks/useSubscription";
import { getAverageTrend, getTrendData } from "../../../scripts/trends";
import { DateTime } from "luxon";
import { CardHeader, ChartContainer, ChartGrid, MainChartContainer, MetricsContainer, ThumbnailContainer } from "../insightsStyles";
import AutomationChart from "./AutomationChart";
import KeyMetric from "../../UiComponents/KeyMetric";
import { FaRegClock } from "react-icons/fa";
import { MusicIcon } from "../../UiComponents/Icons";
import BarChartSkeleton from "../../panel/skeletons/BarChartSkeleton";
import hierarchyTypes from "../../settings/venue/device/hierarchyTypes";
import { resolveTimeText } from "../decibel/decibelInsightsUtils";
import useMixpanel from "../../../hooks/useMixpanel";

const AutomationInsights = props => {
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);
    const [sysvolTrends, setSysvolTrends] = useState();
    const [averageSysvolTrends, setAverageSysvolTrends] = useState();
    const [noiseTrends, setNoiseTrends] = useState();
    const [averageNoiseTrends, setAverageNoiseTrends] = useState();
    const [regulationsTrends, setRegulationsTrends] = useState();
    const [selectedZoneId, setSelectedZoneId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const mix = useMixpanel();

    useEffect(() => {
        setSelectedZoneId(null);
        setSysvolTrends(null);
        setAverageSysvolTrends(null);
        setNoiseTrends(null);
        setAverageNoiseTrends(null);
        setRegulationsTrends(null);
        getData();
        mix.trackEvent('Automation Insights Viewed', { venue: props.customer.companyName, customer: props.customer });
    }, [props.customer?.customerId])

    useEffect(() => {
        getData();
        if (!selectedZoneId && zones?.length > 0) {
            setSelectedZoneId(zones?.[0]?.zoneId)
        }
    }, [props.dateTime, zones?.length])

    const getData = async () => {
        const currentWeekStart = DateTime.fromISO(sysvolTrends?.[0]?.startDate)?.startOf('week')?.ts;
        if (props.dateTime?.startOf('week')?.ts !== currentWeekStart) {
            if (zones?.length > 0) {
                setIsLoading(true);
                let newSysvolTrends = [];
                let newNoiseTrends = [];
                let newRegulationsTrends = [];
                let newAverageSysvolTrends = [];
                let newAverageNoiseTrends = [];
                for (let index = 0; index < zones.length; index++) {
                    const zone = zones[index];
                    if (zone?.hide === 0 && zone?.shouldRegulate === 1) {
                        const zoneSysvolTrend = await getTrendData(props.customer?.customerId, trendTypes.periods.WEEK, props.dateTime.toJSDate(), hierarchyLevels.ZONE, zone?.zoneId, dataTypes?.sysvol);
                        newSysvolTrends.push(zoneSysvolTrend[0]);
                        const zoneNoiseTrend = await getTrendData(props.customer?.customerId, trendTypes.periods.WEEK, props.dateTime.toJSDate(), hierarchyLevels.ZONE, zone?.zoneId, dataTypes?.noise);
                        newNoiseTrends.push(zoneNoiseTrend[0]);
                        const zoneRegulationsTrend = await getTrendData(props.customer?.customerId, trendTypes.periods.WEEK, props.dateTime.toJSDate(), hierarchyLevels.ZONE, zone?.zoneId, dataTypes?.regulations);
                        newRegulationsTrends.push(zoneRegulationsTrend[0]);
                        const averageZoneSysvolTrend = await getAverageTrend(props.customer?.customerId, zone?.zoneId, dataTypes.sysvol, hierarchyLevels.ZONE);
                        newAverageSysvolTrends.push(averageZoneSysvolTrend);
                        const averageZoneNoiseTrend = await getAverageTrend(props.customer?.customerId, zone?.zoneId, dataTypes.noise, hierarchyLevels.ZONE);
                        newAverageNoiseTrends.push(averageZoneNoiseTrend);
                    }
                }

                setSysvolTrends(newSysvolTrends);
                setNoiseTrends(newNoiseTrends);
                setRegulationsTrends(newRegulationsTrends);
                setAverageSysvolTrends(newAverageSysvolTrends);
                setAverageNoiseTrends(newAverageNoiseTrends);
                setIsLoading(false);
            }
        }
    }

    return <>
        <ChartGrid>
            <MainChart
                zone={zones?.find(zone => zone?.zoneId === selectedZoneId)}
                dateTime={props.dateTime}
                trendPeriod={props.trendPeriod}
                sysvolTrend={sysvolTrends?.find(trend => trend?.id === selectedZoneId)}
                averageSysvolTrend={averageSysvolTrends?.find(trend => trend?.id === selectedZoneId)}
                noiseTrend={noiseTrends?.find(trend => trend?.id === selectedZoneId)}
                averageNoiseTrend={averageNoiseTrends?.find(trend => trend?.id === selectedZoneId)}
                regulationsTrend={regulationsTrends?.find(trend => trend?.id === selectedZoneId)}
                noOfRelevantTrends={sysvolTrends?.length}
                isLoading={isLoading}
            />

            {zones?.sort((a, b) => a?.orderIndex > b?.orderIndex)?.map((zone) => {
                const zoneSysvolTrend = sysvolTrends?.find(trend => trend?.id === zone?.zoneId);
                const zoneNoiseTrend = noiseTrends?.find(trend => trend?.id === zone?.zoneId);
                const averageZoneSysvolTrend = averageSysvolTrends?.find(trend => trend?.id === zone?.zoneId);
                const averageZoneNoiseTrend = averageNoiseTrends?.find(trend => trend?.id === zone?.zoneId);

                if (zone?.shouldRegulate === 1 && zone?.hide === 0 && zone?.hierarchyType !== hierarchyTypes.MASTER.value) {
                    return <ThumbnailContainer
                        key={zone?.zoneId}
                        onClick={() => setSelectedZoneId(zone?.zoneId)}
                        selected={zone?.zoneId === selectedZoneId}
                    >
                        <label>{zone?.zoneName}</label>
                        {isLoading === false ? <>
                            <ChartContainer>
                                <AutomationChart
                                    thumbnail
                                    sysvolData={getRelevantHourData(zoneSysvolTrend, props.dateTime?.weekday, props.trendPeriod) ?? []}
                                    noiseData={getRelevantHourData(zoneNoiseTrend, props.dateTime?.weekday, props.trendPeriod) ?? []}
                                    averageSysvolData={getRelevantHourData(averageZoneSysvolTrend, props.dateTime?.weekday, props.trendPeriod)}
                                    averageNoiseData={getRelevantHourData(averageZoneNoiseTrend, props.dateTime?.weekday, props.trendPeriod)}
                                    dateTime={props.dateTime}
                                    period={props.trendPeriod}
                                />
                            </ChartContainer>
                        </> : <>
                            <BarChartSkeleton />
                        </>}
                    </ThumbnailContainer>
                }
            })}
        </ChartGrid>
    </>
}

export default AutomationInsights;

const MainChart = props => {
    const sysvolHourData = getRelevantHourData(props.sysvolTrend, props.dateTime?.weekday, props.trendPeriod) ?? [];
    const averageSysvolHourData = getRelevantHourData(props.averageSysvolTrend, props.dateTime?.weekday, props.trendPeriod) ?? [];
    const noiseHourData = getRelevantHourData(props.noiseTrend, props.dateTime?.weekday, props.trendPeriod) ?? [];
    const averageNoiseHourData = getRelevantHourData(props.averageNoiseTrend, props.dateTime?.weekday, props.trendPeriod) ?? [];
    const regulationsHourData = getRelevantHourData(props.regulationsTrend, props.dateTime?.weekday, props.trendPeriod) ?? [];
    const maxNoise = Math.max(...noiseHourData);
    const peakHour = noiseHourData?.indexOf(maxNoise);
    const peakWeekDay = Math.floor(peakHour / 24) + 1;
    const numberOfRegulations = regulationsHourData?.reduce((sum, subArray) => sum + subArray?.[1], 0);

    return <MainChartContainer>
        <CardHeader>{props.zone?.zoneName}</CardHeader>
        {props.isLoading === false ? <>
            <ChartContainer>
                <AutomationChart
                    sysvolData={sysvolHourData}
                    averageSysvolData={averageSysvolHourData}
                    noiseData={noiseHourData}
                    averageNoiseData={averageNoiseHourData}
                    dateTime={props.dateTime}
                    period={props.trendPeriod}
                />
            </ChartContainer>
        </> : <>
            <BarChartSkeleton />
        </>}

        <MetricsContainer>
            <KeyMetric
                metric={peakHour > -1 ? props.trendPeriod === trendTypes.periods.WEEK ? `${props.dateTime?.set({ weekday: peakWeekDay })?.toFormat('EEE')} ${resolveTimeText(peakHour % 24)}` : resolveTimeText(peakHour) : '-'}
                title={'Peak hour'}
                icon={<FaRegClock />}
            />

            <KeyMetric
                metric={numberOfRegulations?.toFixed(0) ?? '-'}
                title={"Adjustments"}
                icon={<MusicIcon />}
            />
        </MetricsContainer>
    </MainChartContainer>
}

const getRelevantHourData = (trend, weekday, trendPeriod) => {
    if (trendPeriod === trendTypes.periods.WEEK) {
        const hourData = trend?.data?.map(day => day?.hourData)?.flat();
        return hourData?.map(value => value === 0 ? null : value);
    } else if (trendPeriod === trendTypes.periods.DAY) {
        const hourData = trend?.data?.[weekday - 1]?.hourData;
        return hourData?.map(value => value === 0 ? null : value);
    }
}