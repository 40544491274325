import { Chart } from 'chart.js';
import 'chartjs-adapter-luxon';
import zoomPlugin from 'chartjs-plugin-zoom';
import React, { useEffect, useRef, useState } from 'react';
import { getDateTime } from '../../../../scripts/log';
import theme from '../../../../UI/theme';
import { chartAreaBackground } from '../../../statistics/charts/chartUtils';
import { userInteractionTypes } from '../../customerDetails/userInteractionTypes';
import { balanceTypes } from '../balanceTypes';
import { isNumber } from '../../../../scripts/common';

Chart.register(zoomPlugin);

const chartData = {
    labels: [],
    datasets: [
        {
            type: 'scatter',
            showLine: true,
            label: 'Ambience (%)',
            fill: false,
            backgroundColor: theme.colors.pinky,
            borderColor: theme.colors.pinky,
            borderWidth: 0.5,
            pointRadius: 0,
            pointStyle: 'rect',
            pointHitRadius: 3,
            data: []
        },
        {
            type: 'scatter',
            showLine: true,
            label: 'Music (%)',
            fill: false,
            backgroundColor: theme.colors.yellowFever,
            borderColor: theme.colors.yellowFever,
            borderWidth: 0.5,
            pointRadius: 0,
            pointStyle: 'rect',
            pointHitRadius: 3,
            data: []
        },
        {
            type: 'scatter',
            showLine: true,
            label: 'Measured (dB)',
            fill: false,
            backgroundColor: theme.colors.crystalBlue,
            borderColor: theme.colors.crystalBlue,
            borderWidth: 0.5,
            pointRadius: 0,
            pointStyle: 'rect',
            pointHitRadius: 3,
            data: []
        }, {
            type: 'scatter',
            showLine: true,
            label: 'Signal level',
            fill: false,
            backgroundColor: theme.colors.mellowYellow,
            borderColor: theme.colors.mellowYellow,
            borderWidth: 0.5,
            pointRadius: 0,
            pointStyle: 'rect',
            pointHitRadius: 3,
            data: []
        }, {
            type: 'line',
            showLine: true,
            label: 'Automation',
            pointRadius: 0,
            pointStyle: 'line',
            borderColor: theme.colors.greenEnergy,
            backgroundColor: theme.colors.greenEnergy,
            borderWidth: 1,
            pointHitRadius: 3,
            data: [],
            yAxisID: 'y2',
            stepped: true,
        },
        {
            type: 'scatter',
            showLine: true,
            label: 'Source automation',
            borderColor: theme.colors.crystalBlue,
            backgroundColor: theme.colors.crystalBlue,
            pointRadius: 0,
            pointStyle: 'line',
            borderWidth: 1,
            pointHitRadius: 3,
            data: [],
            yAxisID: 'y3',
            stepped: true,
        },
        {
            type: 'scatter',
            showLine: true,
            label: 'mute',
            borderColor: theme.colors.raspberryRed,
            backgroundColor: theme.colors.raspberryRed,
            pointRadius: 0,
            pointStyle: 'line',
            borderWidth: 1,
            pointHitRadius: 3,
            data: [],
            yAxisID: 'y4',
            stepped: true,
        },
        {
            type: 'line',
            label: 'Balance',
            pointRadius: 0,
            pointStyle: 'line',
            borderColor: theme.colors.pinky,
            backgroundColor: theme.colors.pinky,
            borderWidth: 1,
            pointHitRadius: 3,
            data: [],
            yAxisID: 'y5',
            stepped: true,
        },
        {
            type: 'scatter',
            label: 'User interactions',
            fill: false,
            pointRadius: 5,
            pointStyle: 'circle',
            pointHitRadius: 3,
            data: [],
            yAxisID: 'y6',
            parsing: {
                yAxisKey: 'yValue'
            }
        },
        {
            type: 'scatter',
            label: 'Schedules',
            backgroundColor: theme.colors.greenEnergy,
            borderColor: theme.colors.greenEnergy,
            pointRadius: 5,
            pointStyle: 'circle',
            pointHitRadius: 3,
            data: [],
            yAxisID: 'y7',
            parsing: {
                yAxisKey: 'yValue'
            }
        }
    ]
}

const chartConfig = {
    type: 'scatter',
    data: chartData,
    options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'minute',
                    tooltipFormat: 'EEE dd.MM T',
                    displayFormats: {
                        'minute': 'EEE dd.MM T',
                    }
                },
                ticks: {
                    maxTicksLimit: 7,
                    maxRotation: 30,
                },
                grid: {
                    color: theme.colors.darkSpace80,
                }
            },
            y: {
                grid: {
                    color: theme.colors.darkSpace80,
                    borderColor: theme.colors.darkSpace80,
                },
                min: 0,
                stack: 'plotStack',
                stackWeight: 10,
                offset: true,
            },
            y2: {
                type: 'category',
                labels: ['Automation ON', 'Automation OFF'],
                offset: true,
                stack: 'plotStack',
                stackWeight: 1,
                stacked: true,
                ticks: {
                    autoSkip: false,
                },
                grid: {
                    color: theme.colors.darkSpace80,
                    borderColor: theme.colors.greenEnergy,
                    borderWidth: 3
                }
            },
            y3: {
                type: 'category',
                labels: ['Source ON', 'Source OFF'],
                offset: true,
                stack: 'plotStack',
                stackWeight: 1,
                stacked: true,
                ticks: {
                    autoSkip: false,
                },
                grid: {
                    color: theme.colors.darkSpace80,
                    borderColor: theme.colors.crystalBlue,
                    borderWidth: 3
                }
            },
            y4: {
                type: 'category',
                labels: ['Unmuted', 'Muted'],
                offset: true,
                stack: 'plotStack',
                stackWeight: 1,
                stacked: true,
                ticks: {
                    autoSkip: false,
                },
                grid: {
                    color: theme.colors.darkSpace80,
                    borderColor: theme.colors.raspberryRed,
                    borderWidth: 3
                }
            },
            y5: {
                type: 'category',
                labels: Object.keys(balanceTypes).map(key => balanceTypes[key].display),
                offset: true,
                stack: 'plotStack',
                stackWeight: 1.5,
                stacked: true,
                ticks: {
                    autoSkip: false,
                },
                grid: {
                    color: theme.colors.darkSpace80,
                    borderColor: theme.colors.pinky,
                    borderWidth: 3
                }
            },
            y6: {
                type: 'category',
                labels: ['UI'],
                offset: true,
                stack: 'plotStack',
                stackWeight: 0.5,
                stacked: true,
                grid: {
                    color: theme.colors.darkSpace60,
                    borderColor: theme.colors.darkSpace60,
                    borderWidth: 3
                }
            },
            y7: {
                type: 'category',
                labels: ['Schedules'],
                offset: true,
                stack: 'plotStack',
                stackWeight: 0.5,
                stacked: true,
                grid: {
                    color: theme.colors.darkSpace60,
                    borderColor: theme.colors.darkSpace60,
                    borderWidth: 3
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                    boxHeight: 10,
                    filter: function (legendItem, data) {
                        switch (legendItem.datasetIndex) {
                            case 5:
                                return false;
                            default:
                                return true;
                        }
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        if (context.raw?.label) {
                            return [context.label, ...context.raw.label];
                        } else {
                            return `${context.label} // ${context.formattedValue}`;
                        }
                    }
                }
            },
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                    },
                    pinch: {
                        enabled: true
                    },
                    mode: 'x'
                },
                pan: {
                    enabled: true,
                    mode: 'x'
                }
            },
            chartAreaBackground: {
                stops: []
            }
        }
    }
}

const ZoneLogGraph = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);

    if (props.zoomLimits) {
        chartConfig.options.plugins.zoom.limits = {
            ...props.zoomLimits
        }
        chartConfig.options.scales.x.min = props.zoomLimits.x.min;
        chartConfig.options.scales.x.max = props.zoomLimits.x.max;
    }

    chartConfig.data.datasets.forEach(dataset => dataset.data = []);

    if (props.data?.zoneLogs) {
        var lastIsRegulating = null;
        var lastIsRegulatingAudioSource = null;
        var lastMute = null;
        var lastBalanceType = null;
        var zoneLogLength = props.data.zoneLogs?.length;

        for (let index = 0; index < props.data.zoneLogs.length; index++) {
            const log = props.data.zoneLogs[index];
            const time = getDateTime(log.time);

            chartConfig.data.datasets[0].data.push({ x: time, y: log.noise });
            chartConfig.data.datasets[1].data.push({ x: time, y: log.sysvol });
            chartConfig.data.datasets[2].data.push({ x: time, y: log.averageDecibel });
            chartConfig.data.datasets[3].data.push({ x: time, y: isNumber(log.meterDb) ? log.meterDb + 80 : null });

            if (index === 0 || index === zoneLogLength - 1 || log.isRegulating !== lastIsRegulating) {
                chartConfig.data.datasets[4].data.push({ x: time, y: log.isRegulating ? 'Automation ON' : 'Automation OFF' });
                lastIsRegulating = log.isRegulating;
            }

            if (log.isRegulatingAudioSource !== undefined) {
                if (index === 0 || index === zoneLogLength - 1 || log.isRegulatingAudioSource !== lastIsRegulatingAudioSource) {
                    chartConfig.data.datasets[5].data.push({ x: time, y: log.isRegulatingAudioSource ? 'Source ON' : 'Source OFF' });
                    lastIsRegulatingAudioSource = log.isRegulatingAudioSource;
                }
            }

            if (log.mute !== undefined) {
                if (index === 0 || index === zoneLogLength - 1 || log.mute !== lastMute) {
                    chartConfig.data.datasets[6].data.push({ x: time, y: log.mute ? 'Muted' : 'Unmuted' });
                    lastMute = log.mute;
                }
            }

            if (log.balance !== undefined) {
                var balanceType = balanceTypes[Object.keys(balanceTypes).find(type => balanceTypes[type].key === log.balance)]?.display;
                if (index === 0 || index === zoneLogLength - 1 || balanceType !== lastBalanceType) { // Only plot changes and endpoints
                    chartConfig.data.datasets[7].data.push({
                        x: time,
                        y: balanceTypes[Object.keys(balanceTypes).find(type => balanceTypes[type].key === log.balance)]?.display
                    });
                    lastBalanceType = balanceType;
                }
            }
        }
    }

    chartConfig.data.datasets[7].data = [];
    chartConfig.data.datasets[7].backgroundColor = [];
    if (props.data?.userInteractions) {
        props.data.userInteractions
            .map(ui => {
                var uiType = userInteractionTypes[Object.keys(userInteractionTypes).find(type => userInteractionTypes[type].key === ui.setting)];
                chartConfig.data.datasets[7].data.push({
                    x: getDateTime(ui.time),
                    yValue: 'UI',
                    label: [`Type: ${uiType?.display ?? 'Other'}`, `Value: ${ui.fromValue} \u2192 ${ui.toValue}`]
                });
                chartConfig.data.datasets[7].backgroundColor.push(uiType?.color ?? 'white');
            })
    }

    chartConfig.data.datasets[8].data = [];
    if (props.data?.schedules) {
        props.data.schedules
            .map(schedule => {
                schedule.dateTimes?.map(dateTime => {
                    chartConfig.data.datasets[8].data.push({
                        x: dateTime.toJSDate(),
                        yValue: 'Schedules',
                        label: [`Name: ${schedule.displayName}`, `Automation: ${schedule.isRegulating}`, `Vibe: ${schedule.vibeChange}`, `Mute: ${schedule.mute}`, `Mood sustain: ${schedule.moodSustain}`]
                    })
                })
            })
    }

    chartConfig.options.plugins.chartAreaBackground.stops = [];
    if (props.data.openingHours?.length) {
        props.data.openingHours.map((openingHour, index) => {
            chartConfig.options.plugins.chartAreaBackground.stops.push({
                x: props.data.openingHours[index].milliseconds,
                color: openingHour.open ? theme.colors.darkSpace70 : theme.colors.greenEnergy
            });
            chartConfig.options.plugins.chartAreaBackground.stops.push({
                x: openingHour.milliseconds,
                color: openingHour.open ? theme.colors.greenEnergy : theme.colors.darkSpace70
            });
        })
    }

    if (chartInstance) {
        chartInstance.update();
        chartInstance.resetZoom();
    }

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, { ...chartConfig, plugins: [{ ...chartAreaBackground, id: 'chartAreaBackground' }] });
            setChartInstance(newChartInstance);
        }

        return () => {
            chartConfig.data.datasets.forEach(dataset => dataset.data = []);
        }
    }, [chartContainer]);

    return <canvas ref={chartContainer} style={{ height: '100%', width: '100%', maxWidth: '100%' }} ></canvas>
}

export default ZoneLogGraph;

export const MemoizedZoneLogGraph = React.memo(ZoneLogGraph, (() => { return true })); // To avoid rerenders when props haven't changed