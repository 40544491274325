import React, { useState, useEffect, useRef } from 'react';
import LiveGraph from './SensorLiveGraph';
import { getDefinedLogInterval, getDateTime } from '../../../../scripts/log';
import styled from 'styled-components';
import IntervalSelector from '../../../live/zone/graph/IntervalSelector';
import zoneTypes from '../../../live/zone/zoneTypes';
import { formatDateTimeLabel } from '../../../../scripts/common';


const Container = styled.div`
    margin-top: 10px;
`

const chartData = {
    labels: [],
    datasets: [
        {
            data: [],
        }
    ]
}

const N_DATA_POINTS = 100;

const LiveGraphContainer = props => {
    const [graphData, setGraphData] = useState(chartData);
    const [appModeGraphData, setAppModeGraphData] = useState(chartData);
    const [selectedInterval, setSelectedInterval] = useState(zoneTypes.intervals.LIVE);
    let componentMounted = useRef(true);

    useEffect(() => {
        return () => {
            componentMounted.current = false;
            setGraphData(chartData);
            setAppModeGraphData(chartData);
        }
    }, [])

    useEffect(() => {
        if (!props.hidden) {
            getDataHandler();
        }
    }, [props.customerId, props.objectID, props.hidden, selectedInterval]);

    useEffect(() => {
        if (!props.hidden && selectedInterval === zoneTypes.intervals.LIVE) {
            updateWithLiveValues(props.liveValues);
        }
    }, [props.liveValues]);

    const getDataHandler = async () => {
        try {
            var newData = await getDefinedLogInterval(props.customerId, props.objectID, selectedInterval, props.objectType);
            var updatedData = JSON.parse(JSON.stringify(chartData));
            if (newData && newData.length) {
                newData = newData.sort((a, b) => { return (getDateTime(a.time) > getDateTime(b.time)) ? 1 : -1; });
                if (newData.length > N_DATA_POINTS) {
                    const step = Math.floor(newData.length / N_DATA_POINTS);
                    newData = newData.filter((_, i) => i % step === 0);
                }
                updatedData.labels = newData.map(d => formatDateTimeLabel(getDateTime(d.time)));
                updatedData.datasets[0].data = getMovingAverage(newData.map(d => d.averageDecibel), 0);
                var appModeData = JSON.parse(JSON.stringify(updatedData));
                appModeData.datasets[0].data = getMovingAverage(newData.map(d => d.appMode), 0);

                if (componentMounted.current) {
                    setGraphData(updatedData);
                    setAppModeGraphData(appModeData)
                }
            } else {
                if (componentMounted.current) {
                    setGraphData(updatedData);
                    setAppModeGraphData(updatedData);
                }
            }
        } catch (error) {

        }
    }

    const updateWithLiveValues = (liveValues) => {
        if (liveValues && !isNaN(parseInt(liveValues.sysvol))) {
            updateData(liveValues.averageDecibel, liveValues.time);
        }
    }

    const updateData = (db, newLabel) => {
        if (componentMounted.current) {
            setGraphData(currentGraphData => {

                if (currentGraphData.datasets) {
                    var updatedData = JSON.parse(JSON.stringify(currentGraphData));

                    if (updatedData.datasets[0].data.length > N_DATA_POINTS) {
                        updatedData.labels.shift();
                        updatedData.datasets[0].data.shift();

                    }
                    updatedData.labels.push(formatDateTimeLabel(getDateTime(newLabel)));
                    updatedData.datasets[0].data.push(db);
                }

                return updatedData;
            });
        }
    }


    return <Container>
        <IntervalSelector selectedInterval={selectedInterval} setSelectedInterval={setSelectedInterval} />
        <LiveGraph key='1' data={graphData} chartID={props.objectID} />
        <LiveGraph
            key='2'
            data={appModeGraphData}
            chartID={props.objectID + 'appmode'}
            ylims={[0, 3]}
            lineProps={{ label: 'App mode' }}
        />
    </Container>
}

export default LiveGraphContainer;


function getMovingAverage(data, width) {

    var movingAvg = data;
    const L = movingAvg.length;
    for (var i = 0; i < L; i++) {
        var avg;
        if (i >= width && i <= L - width - 1) {
            avg = data.slice(i - width, i + width + 1).reduce(sum) / (2 * width + 1);
        } else if (i < width) {
            avg = (data[i] + data[i + 1]) / 2;
        } else if (i > L - width - 1) {
            avg = (data[i - 1] + data[i]) / 2;
        }
        movingAvg[i] = Math.round(avg);
    }

    return movingAvg;
}

function sum(total, num) {
    return total + num;
}