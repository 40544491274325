import styled from "styled-components";
import theme from "../../../../UI/theme";

const DatePickerInput = styled.input`
    position: relative;
    color: transparent;
    background-color: transparent;
    border: none;
    font-size: 0;
    height: 100%;
    max-width: 100%;
    overflow: visible;
    width: 100%;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;

    &::-webkit-calendar-picker-indicator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;

        &:hover {
            cursor: pointer;
        }
    }

    &:after {
        position: absolute;
        top: 0;
        visibility: visible;
        font-size: ${theme.fontSize.tiny};
        font: 'Karelia';
        display: flex;
        justify-content: center;
        align-items: center;
        content: ${props => props.content ? props.content : ''};
        height: fill-available;
        width: fill-available;
        box-sizing: border-box;
        background-color: transparent;
        border: 1px solid ${theme.colors.grayBorder};
        border-radius: 2px;
        color: ${theme.colors.textGray};
        white-space: nowrap;
    }
`

export default DatePickerInput;