import Button from "../../../../UiComponents/Button";
import { installationTabs } from "../../../installationTypes";

const ShowSourceMatrixTabButton = ({
    setSelectedTab
}) => <Button
    small
    secondary
    onClick={() => setSelectedTab(installationTabs.find(tab => tab.key === 'SOURCE_MATRIX'))}
>
    Add source matrix
</Button>

export default ShowSourceMatrixTabButton;