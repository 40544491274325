import { useEffect, useState } from "react";
import styled from "styled-components";
import { deleteMusicPlayer, updateMusicPlayer } from "../../scripts/musicPlayer";
import { ButtonRowContainer, ModalContainer, ModalHeader, ModalText } from "../panel/ModalStyles";
import MusicPlayerItem from "../panel/musicPlayer/MusicPlayerItem";
import Button from "../UiComponents/Button";
import Card from "../UiComponents/Card";
import Dropdown from "../UiComponents/Dropdown";
import InputField from "../UiComponents/InputField";
import Modal from "../UiComponents/Modal";

const MusicPlayerCard = styled(Card)`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`

const RowContainer = styled.div`
    display: flex;
    > *:not(:last-child) {
        margin-right: 10px;
    }

    justify-content: space-between;
    align-items: center;
`

const MusicPlayerSettings = props => {
    const [isEditing, setIsEditing] = useState(false);
    const [changesMade, setChangesMade] = useState(false);
    const [name, setName] = useState(props?.musicPlayer?.name ?? '');
    const [defaultVolume, setDefaultVolume] = useState(props?.musicPlayer?.defaultVolume);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [music, setMusic] = useState();

    const resolveConnectedSource = () => {
        let musicPlayerSource = props?.sources?.find(source => source?.sourceId === props?.musicPlayer?.sourceId);
        if (musicPlayerSource) {
            return ({
                ...musicPlayerSource,
                key: musicPlayerSource?.sourceId,
                label: musicPlayerSource?.name
            });
        } else {
            return null
        }
    }

    const [connectedSource, setConnectedSource] = useState(resolveConnectedSource());

    const onUpdateMusicPlayer = async () => {
        let pickedMusicPlayer = (({ musicPlayerId, customerId }) => ({ musicPlayerId, customerId }))(props.musicPlayer);
        await updateMusicPlayer({ ...pickedMusicPlayer, name: name, defaultVolume: defaultVolume, sourceId: connectedSource?.sourceId });
        setIsEditing(false);
        setChangesMade(false);
    }

    const onChangeName = (newValue) => {
        setChangesMade(true);
        setName(newValue);
    }

    const onChangeDefaultVolume = (newValue) => {
        let newDefaultVolume = Number(newValue);
        setChangesMade(true);
        setDefaultVolume(newDefaultVolume);
    }

    const onChangeSource = (newValue) => {
        setChangesMade(true);
        setConnectedSource(newValue);
    }

    const onCancelChanges = () => {
        setIsEditing(false);
        setName(props?.musicPlayer?.name ?? '');
        setDefaultVolume(props?.musicPlayer?.defaultVolume);
        setConnectedSource(resolveConnectedSource());
        setChangesMade(false);
    }

    return <>
        <MusicPlayerCard key={props?.musicPlayer?.musicPlayerId}>
            <MusicPlayerItem musicPlayer={props?.musicPlayer} onMusicChange={(music) => setMusic(music)} />

            <RowContainer>
                <label>Name</label>
                {isEditing === true ? <InputField value={name} onChange={(e) => onChangeName(e.target.value)} /> : <label>{props?.musicPlayer?.name}</label>}
            </RowContainer>

            <RowContainer>
                <label>Default volume</label>
                {isEditing === true ? <InputField value={defaultVolume} type="number" min={0} step={1} onChange={(e) => onChangeDefaultVolume(e.target.value)} /> : <label>{props?.musicPlayer?.defaultVolume ?? 'None'}</label>}
            </RowContainer>

            <RowContainer>
                <label>Current volume</label>
                <label>{music?.volume}</label>
            </RowContainer>

            <RowContainer>
                <label>Connected source</label>
                {isEditing === true ?
                    <Dropdown
                        items={props?.sources?.map(source => { return { ...source, label: source.name, key: source.sourceId } })}
                        selectedItem={connectedSource}
                        onClick={(item) => onChangeSource(item)} />
                    : <label>
                        {connectedSource?.name ?? 'None'}
                    </label>}
            </RowContainer>

            <ButtonRowContainer>
                {isEditing === true ?
                    <Button secondary small onClick={() => onCancelChanges()}>Cancel</Button> :
                    <Button secondary small onClick={() => setIsEditing(true)}>Edit</Button>}
                <Button tertiary small onClick={() => setShowDeleteModal(true)}> Delete</Button>
                {isEditing === true && changesMade === true ?
                    <Button primary small onClick={() => onUpdateMusicPlayer()}>Save changes</Button>
                    : <></>}
            </ButtonRowContainer>

        </MusicPlayerCard>

        <Modal show={showDeleteModal} close={() => setShowDeleteModal(false)}>
            <ModalContainer>
                <ModalHeader>Delete music player?</ModalHeader>
                <ModalText>Are you sure you want to delete this music player?</ModalText>
                <ButtonRowContainer>
                    <Button secondary small onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                    <Button primary small onClick={() => deleteMusicPlayer(props?.musicPlayer?.musicPlayerId)}>Delete</Button>
                </ButtonRowContainer>
            </ModalContainer>
        </Modal >
    </>
}

export default MusicPlayerSettings;