import Card from "../../../UiComponents/Card";
import Button from "../../../UiComponents/Button";
import { FaFilter, FaPlus } from "react-icons/fa";
import Modal from "../../../UiComponents/Modal";
import { useContext, useState } from "react";
import ScheduleForm from "./ScheduleForm";
import styled from "styled-components";
import ScheduleFilter from "./ScheduleFilter";
import ScheduleFilterSummary from "./ScheduleFilterSummary";
import UserContext from "../../../../hooks/UserContext";
import { hasVenuePermission, permissionTypes } from "../../../permission/permissions";

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const ScheduleMenu = props => {
    const userContext = useContext(UserContext);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    const canEdit = hasVenuePermission(userContext, permissionTypes.settingsWrite);

    return <>
        <Card>
            <FlexRow>
                <Button primary disabled={!canEdit} onClick={() => setShowScheduleModal(true)}>New schedule&nbsp;<FaPlus /></Button>
                <Button secondary onClick={() => setShowFilterModal(true)}>Filter&nbsp;<FaFilter /></Button>
            </FlexRow>

            <ScheduleFilterSummary filter={props.filter} setFilter={props.setFilter} zones={props.zones} />

            <Modal show={showScheduleModal} close={() => setShowScheduleModal(false)}>
                <ScheduleForm setShowScheduleModal={setShowScheduleModal} customerId={props.customerId} zones={props.zones} refreshSchedules={props.refreshSchedules} />
            </Modal>

            <Modal show={showFilterModal} close={() => setShowFilterModal(false)}>
                <ScheduleFilter zones={props.zones} setShowFilterModal={setShowFilterModal} filter={props.filter} setFilter={props.setFilter} />
            </Modal>
        </Card>
    </>
}

export default ScheduleMenu;