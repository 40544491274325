import React from 'react';
import RoundButton from './RoundButton';
import styled from 'styled-components';
import { FaChevronUp } from "react-icons/fa";

const StyledButton = styled(RoundButton)`
    svg {
        transition: transform 0.2s; 
        transform: rotate(${props => props.showState ? '0deg' : '180deg'});
    }
`

const ExpandCollapseButton = props => {

    return <StyledButton {...props}>
        <FaChevronUp />
    </StyledButton>
}

export default ExpandCollapseButton;