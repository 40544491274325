import React from 'react';
import IconLogo from '../components/UiComponents/IconLogo';
import SplashScreen from '../components/UiComponents/SplashScreen';


const SplashScreenView = (props) => {
    return (
        <SplashScreen>
            <IconLogo reveal/>
        </SplashScreen>
    );
}

export default SplashScreenView;