import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { AddButton } from "../styles";
import Modal from "../../../../UiComponents/Modal";
import { ButtonRowContainer, ModalContainer, ModalHeader } from "../../../../panel/ModalStyles";
import { bundleKeys, containsVenuePermission, getUserDisplayName } from "../userAdministrationUtils";
import Button from "../../../../UiComponents/Button";
import { bundleColumns } from "../PermissionsTable";
import Radio from "../../../../UiComponents/Radio";
import theme from "../../../../../UI/theme";
import VenueSelector from "./VenueSelector";
import { TextMuted } from "../../../../UiComponents/text/StyledText";
import { SettingsSection } from "../../../settingsStyles";

const AddVenuePermissionButton = ({ user, venues, permissions, addPermissionBundle }) => {
    const [showModal, setShowModal] = useState(false);

    const onAdd = async () => {
        setShowModal(false);
    }

    return <>
        <AddButton onClick={() => setShowModal(true)} >
            <FaPlus />
            <label>New</label>
        </AddButton>

        <Modal show={showModal} close={() => setShowModal(false)}>
            <ModalContainer>
                <VenuePermissionForm
                    user={user}
                    venues={venues}
                    permissions={permissions}
                    onAdd={onAdd}
                    addPermissionBundle={addPermissionBundle}
                />
            </ModalContainer>
        </Modal>
    </>
}

export default AddVenuePermissionButton;


const states = {
    venue: 'venue',
    permission: 'permission'
}

const VenuePermissionForm = ({ user, venues, permissions, onAdd, addPermissionBundle }) => {
    const [selectedVenues, setSelectedVenues] = useState([]);
    const [state, setState] = useState(states.venue);
    const [selectedPermissionBundle, setSelectedPermissionBundle] = useState(bundleKeys.basic);

    const addPermissions = async () => {
        await addPermissionBundle(user, selectedVenues, selectedPermissionBundle);
        onAdd();
    }

    return <>
        <ModalHeader>Add venue permissions for {getUserDisplayName(user)}</ModalHeader>
        {state === states.permission ? <>
            <SettingsSection>
                <label>Selected venues</label>
                <ul style={{ color: theme.colors.textGray, margin: 0 }}>
                    {selectedVenues.map(venue => <li key={venue.customerId}>
                        {venue.companyName}
                    </li>)}
                </ul>
            </SettingsSection>

            <ButtonRowContainer>
                <Button small secondary onClick={() => setState(states.venue)}>Change venues</Button>
            </ButtonRowContainer>

            <SettingsSection>
                <label>Select permissions</label>
                {bundleColumns.filter(b => b.value !== bundleKeys.custom).map(bundleCol => {
                    return <div key={bundleCol.value} style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gridGap: '5px', alignItems: 'center' }}>
                        <Radio
                            checked={selectedPermissionBundle === bundleCol.value}
                            onChange={() => setSelectedPermissionBundle(bundleCol.value)}
                        />
                        <TextMuted>{bundleCol.display}</TextMuted>
                    </div>
                })}
            </SettingsSection>


            {selectedVenues.length && selectedPermissionBundle ? <>
                <ButtonRowContainer>
                    <Button primary onClick={addPermissions}>Add permissions</Button>
                    <Button secondary onClick={() => onAdd()}>Cancel</Button>
                </ButtonRowContainer>
            </> : <></>}
        </> : <>
            <VenueSelector
                venues={venues}
                preselectedVenues={venues.filter(v => containsVenuePermission(permissions, user.userId, v.customerId))}
                selectedVenues={selectedVenues}
                setSelectedVenues={setSelectedVenues}
            />

            <ButtonRowContainer>
                <Button primary disabled={selectedVenues.length === 0} onClick={() => setState(states.permission)}>Next</Button>
            </ButtonRowContainer>

        </>}
    </>
}
