import styled from "styled-components";
import SidePanelContainer from "../../../../UiComponents/containers/SidePanelContainer";
import UserList from "./UserList";
import UserPermissionsTable from "./UserPermissionsTable";
import UserInfo from "./UserInfo";
import { UserAdminSectionContainer } from "../styles";
import UserToolBar from "./UserToolBar";
import { hasRole, roles } from "../../../../permission/permissions";
import { useContext } from "react";
import UserContext from "../../../../../hooks/UserContext";

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
`

const UserPermissions = ({ venues, users, viewState, setViewState, permissionTableRef, refreshUsers }) => {
    const userContext = useContext(UserContext);
    const selectedUser = users.find(u => u.userId === viewState.userId);

    return <Container>
        {users.length > 1 ? <>
            <SidePanelContainer defaultExpanded={!selectedUser}>
                <UserList
                    users={users}
                    selectedUserId={viewState.userId}
                    setSelectedUserId={(userId) => setViewState(current => ({ ...current, userId }))}
                />
            </SidePanelContainer>
        </> : <></>}

        <UserAdminSectionContainer>

            {hasRole(userContext, roles.impersonator) ?
                <UserToolBar />
                : <></>}

            {selectedUser && venues ? <>

                <UserInfo user={selectedUser} />

                <UserPermissionsTable
                    user={selectedUser}
                    venues={venues}
                    permissionTableRef={permissionTableRef}
                    setViewState={setViewState}
                    refreshUsers={refreshUsers}
                />
            </> : <></>}
        </UserAdminSectionContainer>
    </Container>
}

export default UserPermissions;
