import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/calibrationHistory';

async function getCalibrationHistoryForZone(customerId, zoneId) {
    const res = await axios.get(`${hostname}${path}/list?customerId=${customerId}&zoneId=${zoneId}`);
    return res.data;
}

async function addCalibrationHistoryRecord(customerId, calibrationHistoryRecord) {
    const res = await axios.post(`${hostname}${path}?customerId=${customerId}`, calibrationHistoryRecord);
    return res.data;
}

async function deleteCalibrationHistoryRecord(customerId, calibrationHistoryId) {
    const res = await axios.delete(`${hostname}${path}?customerId=${customerId}&calibrationHistoryId=${calibrationHistoryId}`);
    return res.data;
}


export {
    getCalibrationHistoryForZone,
    addCalibrationHistoryRecord,
    deleteCalibrationHistoryRecord
}