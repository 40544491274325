module.exports = {
    HUB_OFFLINE: 'HUB_OFFLINE',
    HUB_NO_DATA: 'HUB_NO_DATA',
    HUB_EVENT_ERROR: 'HUB_EVENT_ERROR',
    PROCESSOR_CONNECTION_ERROR: 'PROCESSOR_CONNECTION_ERROR',
    PROCESSOR_ERROR: 'PROCESSOR_ERROR',
    ZONE_NO_DATA_NO_BACKUP: 'ZONE_NO_DATA_NO_BACKUP',
    ZONE_NO_DATA: 'ZONE_NO_DATA',
    SENSORS_OFFLINE: 'SENSORS_OFFLINE',
    ZONE_UNEXPECTED_BEHAVIOR: 'ZONE_UNEXPECTED_BEHAVIOR',
    SENSOR_LOW_BATTERY: 'SENSOR_LOW_BATTERY',
    SENSOR_DISCONNECTED: 'SENSOR_DISCONNECTED',
    SENSOR_UNEXPECTED_BEHAVIOR: 'SENSOR_UNEXPECTED_BEHAVIOR',
    ZONE_MUTED: 'ZONE_MUTED',
    ZONE_REGULATION_OFF: 'ZONE_REGULATION_OFF'
}