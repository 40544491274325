const appStates = {
    NONE: 0,
    LOADING: 1,
    SUCCESS: 2,
    NO_INTERNET: 3,
    SERVICE_UNAVAILABLE: 4
}

export {
    appStates
};