import { useState } from "react";
import { useEffect } from "react";
import { Chart } from 'chart.js';
import theme from "../../../UI/theme";
import { DateTime } from "luxon";
import { PDFDownloadLink, PDFViewer, pdf } from "@react-pdf/renderer";
import HistoryInsightsPdfReport from "./DecibelInsightsPdfReport";
import { decibelWarningThreshold } from "./decibelInsightsUtils";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Button from "../../UiComponents/Button";
import styled, { css } from "styled-components";
import { FaFileDownload, FaSave } from "react-icons/fa";

const chartConfig = {
    type: 'bar',
    data: {
        datasets: [
            {
                label: 'Decibel',
                type: 'bar',
                backgroundColor: theme.colors.crystalBlue,
                borderRadius: 2,
                barPercentage: 1,
                categoryPercentage: 0.9,
                borderSkipped: false,
            }
        ]
    },
    options: {
        responsive: false,
        maintainAspectRatio: true,
        devicePixelRatio: 2,
        animation: false,
        scales: {
            x: {
                display: true,
                type: 'time',
                time: {
                    unit: 'hour',
                    displayFormats: {
                        hour: 'HH'
                    }
                },
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawBorder: true,
                    borderColor: theme.colors.darkSpace70,
                    drawTicks: true,
                    tickColor: theme.colors.darkSpace70,
                    color: theme.colors.darkSpace70
                },
                ticks: {
                    color: theme.colors.textGray,
                    autoSkip: false,
                    source: 'date',
                    maxRotation: 0,
                    font: {
                        size: 10
                    }
                },
                title: {
                    display: true,
                    text: 'Hour',
                    color: theme.colors.textGray
                }
            },
            y: {
                display: true,
                type: 'linear',
                // max: 110,
                suggestedMax: 90,
                min: 0,
                grid: {
                    display: true,
                    drawOnChartArea: true,
                    drawBorder: true,
                    borderColor: theme.colors.darkSpace70,
                    drawTicks: true,
                    tickColor: theme.colors.darkSpace70,
                    color: theme.colors.darkSpace70
                },
                ticks: {
                    color: theme.colors.textGray,
                    font: {
                        size: 10
                    }
                },
                title: {
                    display: true,
                    text: 'Decibel',
                    color: theme.colors.textGray
                }
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    }
}

const datalabelsConfig = {
    anchor: 'end',
    align: 'top',
    color: theme.colors.textGray,
    font: {
        size: 10
    },
    formatter: (value) => {
        if (value.y > 0) {
            return Math.round(value.y);
        } else {
            return '';
        }
    }
}

const DownloadPDFButton = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    padding: 20px;
    background-color: ${theme.colors.darkSpace90};
    border: 1px solid transparent;
    box-sizing: border-box;
    color: ${theme.colors.greenReadable};

    @media (hover:hover) {
        &:hover {
            cursor: pointer;
            border-color: ${theme.colors.darkSpace70};
        }
    }

    ${props => props.primary && css`
        color: ${theme.colors.darkSpace};
        background-color: ${theme.colors.greenEnergy};
    `}

    ${props => props.disabled && css`
        color: ${theme.colors.darkSpace70};
        pointer-events: none;

        @media (hover:hover) {
            &:hover {
                border-color: transparent;
                cursor: initial;
            }
        }
    `}

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        padding: 10px;
    }
`

const HistoryInsightsPdfReportContainer = props => {
    const [isLoadingReport, setIsLoadingReport] = useState(true);
    const [imageStrings, setImageStrings] = useState([]);
    const [reportGenerated, setReportGenerated] = useState(false);

    useEffect(() => {
        if (reportGenerated) {
            if (props?.zones?.length && props.trends?.length && props.dateTime) {
                setIsLoadingReport(true);
                const chartStrings = [];
                for (let index = 0; index < props.zones?.length; index++) {
                    const zone = props.zones?.[index];
                    const zoneTrend = props.trends?.find(trend => trend?.id === zone?.zoneId);

                    if (zoneTrend) {
                        let canvas = document.createElement('canvas');
                        canvas.width = 535;
                        canvas.height = 225;

                        document.body.append(canvas);
                        let ctx = canvas.getContext('2d');
                        let zoneChartConfig = JSON.parse(JSON.stringify(chartConfig));
                        let zoneHourData = zoneTrend?.data?.[props.dateTime?.weekday - 1]?.hourData;
                        let backgroundColors = Array(zoneHourData?.length).fill(theme.colors.crystalBlue);
                        zoneChartConfig.data.datasets[0].data = zoneHourData?.map((value, index) => {
                            if (value > decibelWarningThreshold) {
                                backgroundColors[index] = theme.colors.raspberryRed;
                            }

                            return {
                                x: DateTime.fromJSDate(new Date()).set({ hour: index }).startOf('hour'),
                                y: value
                            }
                        });
                        zoneChartConfig.data.datasets[0].backgroundColor = backgroundColors;
                        zoneChartConfig.options.plugins.datalabels = datalabelsConfig;

                        const newChart = new Chart(ctx, { ...zoneChartConfig, plugins: [ChartDataLabels] })

                        newChart.render();
                        chartStrings.push({ zoneId: zone?.zoneId, imageString: newChart.toBase64Image() });
                        canvas.remove();
                    }
                }

                setImageStrings(chartStrings);
            }
        }
    }, [reportGenerated])

    useEffect(() => {
        setReportGenerated(false);
    }, [props.zones?.length, props.trends, props.dateTime])

    useEffect(() => {
        if (imageStrings?.length) {
            setIsLoadingReport(false);
        }
    }, [imageStrings])

    const downloadPdfReport = async () => {
        const pdfBlob = await pdf(getReport()).toBlob();
        const a = document.createElement('a');
        a.href = URL.createObjectURL(pdfBlob);
        a.download = `Waved Decibel Report - ${props.customer?.companyName} - ${props.dateTime?.toFormat('MMMM dd, yyyy')}`;
        // window.open(a.href, '_blank');
        a.click();
    }

    const getReport = () => {
        return <HistoryInsightsPdfReport companyName={props.customer?.companyName} dateTime={props.dateTime} zones={props.zones} imageStrings={imageStrings} trends={props.trends} />
    }

    if (!reportGenerated) {
        return <DownloadPDFButton onClick={() => setReportGenerated(true)}>Generate PDF report</DownloadPDFButton>
    } else {
        return <>
            {/* <PDFDownloadLink document={<HistoryInsightsPdfReport dateTime={props.dateTime} zones={props.zones} imageStrings={imageStrings} />} fileName="report.pdf">
                {({ blob, url, loading, error }) =>
                    <>{loading ? 'Loading PDF Report' : 'Download PDF Report'}</>
                }
            </PDFDownloadLink> */}

            <DownloadPDFButton primary disabled={isLoadingReport} onClick={() => downloadPdfReport()}><FaFileDownload />&nbsp; Download PDF report</DownloadPDFButton>

            {/* <PDFViewer style={{ width: '100%', height: '700px' }}>
                {getReport()}
            </PDFViewer> */}
        </>
    }
}

export default HistoryInsightsPdfReportContainer;