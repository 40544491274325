import theme from "../../../../UI/theme";

const deviceTypes = {
    nodeTypes: [
        {
            key: 0,
            display: 'Coordinator',
            displayColor: theme.colors.textGray
        },
        {
            key: 1,
            display: 'Router',
            displayColor: theme.colors.crystalBlue
        },
        {
            key: 2,
            display: 'End device',
            displayColor: theme.colors.yellowFever
        },
        {
            key: 3,
            display: 'Unknown',
            displayColor: theme.colors.textGray
        }
    ]
} 

export default deviceTypes;