export const powerSourceKeys = {
    POWER_PLUG: 1,
    BATTERY_PACK: 2,
    TRACKMOUNT: 3
}

export const powerSourceChoices = [{
    label: 'Power plug',
    value: powerSourceKeys.POWER_PLUG
}, {
    label: 'Battery pack',
    value: powerSourceKeys.BATTERY_PACK
}, {
    label: 'Trackmount',
    value: powerSourceKeys.TRACKMOUNT
}];