import NumberField from "../../../fields/NumberField"
import { requiredStates } from "../../../utils/installationTypes";

const SleepLimitField = NumberField;
export default SleepLimitField;

export const getSleepLimitFieldRequiredState = (sensor) => {
    if (parseInt(sensor.nodeType) === 2 && (sensor.sleepLimit === null || isNaN(sensor.sleepLimit))) {
        return requiredStates.RECOMMENDED;
    } else {
        return;
    }
}