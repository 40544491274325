import { addProcessor } from "../../../../scripts/processor";
import { processorTypes } from "../../../settings/venue/device/AudioSystem/processorTypes";
import { addNewSource } from "../source/sourceUtils";
import { addNewSourceSelector } from "../sourceSelector/sourceSelectorUtils";
import { addNewZone } from "../zone/zoneUtils";

const addNewProcessor = async (processor, customerId, submitProps) => {
    try {
        const newProcessor = {...processor, customerId};
        const addedProcessor = await addProcessor(newProcessor);
        await handleOnSubmitProps(addedProcessor, customerId, submitProps);
    } catch (err) {
        console.log('Failed to add new processor', err);
    }
}

const handleOnSubmitProps = async (processor, customerId, submitProps) => {
    const promises = [];
    if (submitProps.addPredefinedConfig) {
        const processorType = processorTypes[processor.type];
        const predefinedConfig = processorType?.predefinedConfig;
        if (predefinedConfig) {
            predefinedConfig.sources?.forEach(predefinedSource => {
                const source = {...predefinedSource, processorId: processor.processorId};
                const promise = addNewSource(source, [processor], customerId);
                promises.push(promise);
            })

            predefinedConfig.sourceSelectors?.forEach(predefinedSourceSelector => {
                const sourceSelector = {...predefinedSourceSelector, processorId: processor.processorId};
                const promise = addNewSourceSelector(sourceSelector, [], [processor], customerId);
                promises.push(promise);
            })

            predefinedConfig.zones?.forEach(predefinedZone => {
                const zone = {...predefinedZone, processorId: processor.processorId, hubId: processor.hubId};
                const promise = addNewZone(zone, [], [processor], customerId);
                promises.push(promise);
            })
        }
    }

    await Promise.all(promises);
}

export {
    addNewProcessor
}