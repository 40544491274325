import React from "react";
import styled from "styled-components";
import { CgSpinner } from 'react-icons/cg';

const NeutralSpinner = styled(CgSpinner)`

    font-size: ${props => (props.size? props.size : '40')}px;
    color: ${props => (props.color? props.color : 'white')};

    -webkit-animation: icon-spin ${props => (props.speed? props.speed : '1.4')}s infinite ease;
    animation: icon-spin ${props => (props.speed? props.speed : '1.4')}s infinite ease;

    @-webkit-keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
    }

    @keyframes icon-spin {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(359deg);
                transform: rotate(359deg);
    }
    }
`
export default NeutralSpinner
