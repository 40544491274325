import { useState } from "react";
import styled from "styled-components";
import { addMusicPlayer } from "../../../scripts/musicPlayer";
import { ModalContainer, ModalHeader, ModalText } from "../../panel/ModalStyles";
import InnerCard from "../../UiComponents/containers/InnerCard";
import Dropdown from "../../UiComponents/Dropdown";
import { musicPlayerProviders } from "../providers/musicPlayerProviders";
import SoundtrackYourBrandSetup from "../providers/soundtrackYourBrand/SoundtrackYourBrandSetup";

const ProviderCardContainer = styled(InnerCard)`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`

let musicPlayerProviderItems = Object.keys(musicPlayerProviders)?.map(key => {
    return { ...musicPlayerProviders[key], label: musicPlayerProviders[key].display }
});

const MusicPlayerSetup = props => {
    const [provider, setProvider] = useState();

    const renderProviderSetup = () => {
        switch (provider?.key) {
            case musicPlayerProviders.SOUNDTRACK_YOUR_BRAND.key:
                return <SoundtrackYourBrandSetup
                    addMusicPlayer={createMusicPlayer}
                    musicPlayers={props.musicPlayers} />;
            default:
                return <></>;
        }
    }

    const createMusicPlayer = async (providerData) => {
        const newPlayer = {
            customerId: props.customerId,
            remoteId: providerData.remoteId,
            name: providerData.name,
            displayName: providerData.displayName,
            token: providerData.token,
            refreshToken: providerData.refreshToken,
            type: providerData.type
        }

        await addMusicPlayer(newPlayer);
    }

    return <ModalContainer>
        <ModalHeader>Add music player</ModalHeader>
        <ModalText>Select provider</ModalText>
        <Dropdown
            items={musicPlayerProviderItems}
            selectedItem={provider}
            onClick={(item) => setProvider(item)} />

        {provider ?
            <ProviderCardContainer>
                {renderProviderSetup()}
            </ProviderCardContainer>
            : <></>}

    </ModalContainer>
}

export default MusicPlayerSetup;