import { useHistory } from "react-router";
import { getCustomerErrors } from "../../../scripts/support";
import { useEffect, useState } from "react";
import SupportTableHeader from "./SupportTableHeader";
import { Table, TableContainer, THead } from "./SupportStyles";
import SupportTableRow from "./SupportTableRow";
import { applyErrorTypeFilter, applyPriorityFilter } from "./errorUtils";
import errorColumns from "./errorColumns";

const SupportTable = props => {
    const history = useHistory();
    const [filter, setFilter] = useState();
    const [priorityFilter, setPriorityFilter] = useState([]);
    const [sortProperty, setSortProperty] = useState();
    const [sortAsc, setSortAsc] = useState(false);

    function setImpersonation(customerId) {
        props.setCustomer(customerId);
        history.push(`/installation`);
    };

    function setSort(newProperty) {
        if (newProperty === sortProperty) {
            setSortAsc(prev => { return !prev });
        } else {
            setSortProperty(newProperty);
            setSortAsc(false);
        }
    }

    function goToSensorDetail(customerId, sensorId) {
        props.setCustomer(customerId);
        setTimeout(() => { history.push(`/admin/sensor-detail/${sensorId}`); }, 500)
    }

    return <TableContainer>
        <Table>
            <THead>
                <SupportTableHeader
                    errorColumns={errorColumns}
                    filter={filter}
                    setFilter={setFilter}
                    priorityFilter={priorityFilter}
                    setPriorityFilter={setPriorityFilter}
                    sortProperty={sortProperty}
                    setSort={setSort}
                    sortAsc={sortAsc} />
            </THead>
            {props.customerErrors ? <>
                <tbody>
                    {applyFilter(props.customerErrors, filter, priorityFilter)
                        ?.sort((a, b) => { return sortRecords(a, b, sortProperty, sortAsc) })
                        ?.map((customer, index) => {
                            return <SupportTableRow
                                errorColumns={errorColumns}
                                key={index}
                                {...customer}
                                setImpersonation={setImpersonation}
                                goToSensorDetail={goToSensorDetail} />
                        })}
                </tbody>
            </> : <></>}
        </Table>
    </TableContainer>

}

export default SupportTable;

const applyFilter = (array, columnFilter, priorityFilter) => {
    var filteredArray = applyErrorTypeFilter(array, columnFilter);
    filteredArray = applyPriorityFilter(filteredArray, priorityFilter);
    return filteredArray;
}

const sortRecords = (a, b, sortParameter, sortAsc) => {
    var result = 0;

    if (sortParameter) {
        let errorColumn = errorColumns?.find(column => column?.key === sortParameter);

        let totalCountA = a?.totalCount?.[errorColumn?.objectType];
        let errorCountA = a?.objects?.filter(object => object?.errors?.some(error => errorColumn?.errorTypes?.some(errorType => errorType === error?.name)))?.length;

        let totalCountB = b?.totalCount?.[errorColumn?.objectType];
        let errorCountB = b?.objects?.filter(object => object?.errors?.some(error => errorColumn?.errorTypes?.some(errorType => errorType === error?.name)))?.length;

        if (totalCountA > 0 && totalCountB > 0) {
            result = (errorCountA / totalCountA) - (errorCountB / totalCountB);
            if (result === 0) {
                result = errorCountA - errorCountB;
            }
        } else {
            result = errorCountA - errorCountB;
        }
    }

    if (sortAsc) {
        return result;
    } else {
        return -result;
    }
}