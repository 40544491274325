import Card from "../components/UiComponents/Card";
import CenteredViewContainer from "../components/UiComponents/CenteredViewContainer";
import styled from 'styled-components';
import IconLogo from "../components/UiComponents/IconLogo";
import theme from "../UI/theme";
import LogoutButton from "../components/authentication/LogoutButton";

const Logo = styled(IconLogo)`
    width: 30px;
    height: 30px;
`

const TitleContainer = styled.div`
    display: flex;
    align-items: center;

    div {
        padding-right: 16px;
    }
`

const Link = styled.a`
    color: ${theme.colors.darkSpace20};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`

const LockedOutView = ({ }) => {

    return <CenteredViewContainer>
        <Card>
            <TitleContainer>
                <Logo />
                <h3>Canceled subscription</h3>
            </TitleContainer>
            <p>Your Waved subscription has been canceled. You will not have access to the app or any of its features until the subscription has been activated again.</p>
            <p>Please call <Link href="tel:+4722222233"> +47 22 22 22 33</Link> to reactivate the subscription or if you have any questions.</p>

            <LogoutButton />
        </Card>
    </CenteredViewContainer>
}

export default LockedOutView;