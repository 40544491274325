import React from 'react';
import styled, {css, keyframes} from 'styled-components';
import {HiLightBulb} from "react-icons/hi";

const StyledBlinkButton = styled.button`
    background-color: transparent;
    color: darkgray;
    border: none;
    font-size: 24px;

    &:hover {
        cursor:pointer;
        opacity: 0.7;
    }

    ${props => props.isBlinking && css`
        animation: ${Blinker} 1.5s linear infinite;
        color: #faa21b;
    `}
`

const Blinker = keyframes`
    50% {
        opacity: 0.2;
    }
`

const BlinkButton = props => {
    return <StyledBlinkButton {...props}>
        <HiLightBulb/>
    </StyledBlinkButton>
}

export default BlinkButton;