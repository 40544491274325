import styled, { css } from "styled-components";
import { loadingStates } from "../../installation/installationTypes"
import LoadingSpinner from "../LoadingSpinner";

export const loadDataHandler = async (getData, setLoadingState) => {
    try {
        setLoadingState(loadingStates.NONE);
        const timeout = setTimeout(() => {
            setLoadingState(loadingStates.LOADING);
        }, 500)
        await getData();
        clearTimeout(timeout);
        setLoadingState(loadingStates.SUCCESS);
    } catch (err) {
        setLoadingState(loadingStates.FAILED);
    }
}

const Placeholder = styled.div`
    ${props => props.height && css`
        height: ${props.height};
    `}}
`

const LoadDataContainer = props => {

    return <>
        {props.loadingState === loadingStates.SUCCESS ? <>
            {props.children}
        </> : <>
            <Placeholder {...props.placeholder}>
                {props.loadingState === loadingStates.LOADING ? <>
                    <LoadingSpinner />
                </> : <></>}

                {props.loadingState === loadingStates.FAILED ? <>
                    Something went wrong
                </> : <></>}
            </Placeholder>
        </>}


    </>
}

export default LoadDataContainer