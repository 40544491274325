import styled from "styled-components";
import theme from "../../UI/theme";
import Card from "../UiComponents/Card";
import Button from "../UiComponents/Button";

const TroubleshootingButton = styled(Button)`
    margin-top: 12px;
    max-width: 150px;
`

const HubInfoContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 10px;
`

const TroubleshootingContainer = styled(Card)`
    display: flex;
    flex-direction: column; 
    justify-content: left;
    padding: 12px 24px;
    margin: auto;
    margin-top: 12px;
    border-style: solid;
    border-color: ${theme.colors.raspberryRed};
    
    h4, p {
        padding: 0;
        margin: 0;
    }
    h4 {
        color: ${theme.colors.raspberryRed};
        font-weight: normal;
    }

    p {
        color: ${theme.colors.textGray};   
    }
    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        max-width: 70em;
    }
    @media only screen and (max-width: ${theme.screenSizes.small}px) {
        padding: 4px;
    }
`
const SolutionContainer = styled.div`
    min-width: 100%;
    padding: 12px 0px;
    box-sizing: border-box;

    h4 {
        color: ${theme.colors.whiteSpace};
        font-weight: normal;
    }
    li {
        color: ${theme.colors.textGray};
        margin-bottom: 12px;
    }
    a {
        color: ${theme.colors.greenEnergy};
    }
    strong {
        color: ${theme.colors.raspberryRed};
    }
`
const InstallationInfoWrapper = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`

const LoadingBar = styled.div`
    @keyframes shimmer{
        0%{
            background-position: -100% 0
        }
        100%{
            background-position: 100% 0
        }
    }

    text-align: center;
    width: 200px;
    height: 35px;
    line-height: 35px;
    background: linear-gradient(to right, ${theme.colors.darkSpace90} 10%, ${theme.colors.darkSpace70} 18%, ${theme.colors.darkSpace90} 33%);
    background-size: 200%;
    animation: shimmer 1.5s infinite reverse;
`

export {
    TroubleshootingButton,
    TroubleshootingContainer,
    SolutionContainer,
    LoadingBar,
    HubInfoContainer,
    InstallationInfoWrapper
}