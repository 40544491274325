import styled from "styled-components";

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    button:not(:last-of-type) {
        margin-right: 10px;
    }
`

export default ButtonContainer;