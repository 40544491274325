import styled from 'styled-components';

const HeaderContainer = styled.div`
    display: flex;
    align-items: baseline;
    h1, h2, h3, h4 {
        margin: 6px 12px 12px 0;
    }
    button {
        margin: 0 4px;
    }
`

export default HeaderContainer;