import theme from "../../../../UI/theme";
import InnerCard from "../../../UiComponents/containers/InnerCard";
import styled from "styled-components";
import Button from "../../../UiComponents/Button";
import { days } from "../../../../scripts/common";

const FilterCard = styled(InnerCard)`
    margin-top: 20px;
`

const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
`

const FilterColumn = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 70%;
`

const FilterContainer = styled.div`
    display: flex;
    flex-flow: wrap;
`

const FilterItem = styled.div`
    background-color: ${theme.colors.grayHover};
    padding: 1px 10px 4px;
    border-radius: 1044px;
    margin: 5px 5px 0 0;
`

const ScheduleFilterSummary = props => {
    return <>
        {props.filter ?
            <FilterCard>
                <FlexRow>
                    <FilterColumn>
                        {props.filter?.zones?.length ?
                            <>
                                <div>Zones</div>
                                <FilterContainer>
                                    {props.filter?.zones?.map((zone, index) => {
                                        return <FilterItem key={index}>{props.zones?.find(z => z.zoneId === zone)?.zoneName}</FilterItem>
                                    })}
                                </FilterContainer>
                            </> : <></>}

                        {props.filter?.days?.length ?
                            <>
                                <div>Days</div>
                                <FilterContainer>
                                    {props.filter?.days?.map((day, index) => {
                                        return <FilterItem key={index}>{days[day]}</FilterItem>
                                    })}
                                </FilterContainer>
                            </> : <></>}
                    </FilterColumn>

                    <Button primary onClick={() => props.setFilter(null)}>Clear filter</Button>
                </FlexRow>
            </FilterCard>
            : <></>}
    </>
}

export default ScheduleFilterSummary;