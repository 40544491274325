import London from "./protocols/London"
import None from "./protocols/None";
import Xilica from "./protocols/Xilica";
import Yamaha from "./protocols/Yamaha";

const getProtocol = (processorType) => {
    switch (processorType) {
        case 'LONDON':
            return London;
        case 'XILICA':
            return Xilica;
        case 'YAMAHA':
            return Yamaha;
        default:
            return None;
    }
}

export {
    getProtocol
}