import React, { useState } from "react";
import { TroubleshootingContainer, TroubleshootingButton, SolutionContainer } from "./StylesTroubleshooting";
import InstallationItemInfo from "../installation/modules/installationDocumentation/info/InstallationItemInfo";
import { performHubAction } from "./utils";
import { LoadingBar } from "./StylesTroubleshooting";
import Button from "../UiComponents/Button";
import theme from "../../UI/theme";
import { HUB_ATTTEMPT_TIMEOUT } from "./utils";

const action = {
    restartHubProgram: 'RESTART_APP',
}

const TroubleshootHubError = (props) => {

    const [expand, setExpand] = useState(false);
    const [showMap, setShowMap] = useState(false);
    const [restartAttempt, setRestartAttempt] = useState();
    const [ongoingAttempt, setOngoingAttempt] = useState(false);

    async function restartHubPrograms() {
        if (ongoingAttempt) return;
        toggleOngoingAttempt(action.restartHubProgram);
        for (let i=0; i<props.notOkHubs.length; i++) {
            var hub = props.notOkHubs[i]
            await performHubAction(hub.hubId, hub.customerId, action.restartHubProgram);
        }
    }

    function toggleOngoingAttempt(attempt) {
        setOngoingAttempt(attempt)
        setTimeout(() => {
            setOngoingAttempt(false);
            if (attempt === action.restartHubProgram) setRestartAttempt(true);
        }, HUB_ATTTEMPT_TIMEOUT);
    }
    

    return (
        <TroubleshootingContainer>
            <h4>Warning</h4>
            <p>Waved may have some unexpected errors</p>
            {!expand? 
                <TroubleshootingButton primary small onClick={() => setExpand(true)}>See solutions</TroubleshootingButton>
            :<>
                <TroubleshootingButton secondary small onClick={() => setExpand(false)}>Hide solutions</TroubleshootingButton>
                <SolutionContainer>
                    <h4>Possible solutions:</h4>
                    <ol>
                        <li>
                            <p>Restart Waved</p>
                            {ongoingAttempt===action.restartHubProgram?
                                <LoadingBar>Restarting...</LoadingBar>
                            :<>
                                {ongoingAttempt?
                                    <Button style={{color: theme.colors.grayBorder}} tertiary small >Restart</Button>
                                :
                                    <Button tertiary small onClick={restartHubPrograms}>Restart</Button>
                                }
                                
                            </>}
                        </li>
                        {restartAttempt?
                            <li>
                                <p>Reboot Waved hub.</p>
                                <ul>
                                    {props.usesEthernet?
                                        <li><p>Locate the Waved hub and make sure it's connected to power (USB-C cable) and an Ethernet cable (with orange and green light).</p></li>
                                    :
                                        <li><p>Locate the Waved hub and make sure it's connected to power (USB-C cable).</p></li>
                                    }
                                    <li><p>If it's already connected, disconnect the USB-C cable, wait 10 secs and reconnect.</p></li>
                                    <li>
                                        <p>You can use the info below to locate the hub at your venue:</p>
                                        {!showMap?
                                            <TroubleshootingButton primary small onClick={() => setShowMap(true)}>Get info</TroubleshootingButton>
                                        :
                                            <TroubleshootingButton secondary small onClick={() => setShowMap(false)}>Hide info</TroubleshootingButton>
                                        }
                                    </li>
                                </ul>
                            </li>
                        :<></>}
                    </ol>
                    {showMap?
                            <InstallationItemInfo
                                columntitle={"Info"}
                                selectedItem={{id: props.hubs[0].hubId, name:props.hubs[0].name, type: "HUB"}}
                                customerId={props.hubs[0].customerId}
                                troubleshooting={true}
                                readOnly={true}
                            />
                            
                    :<></>}
                </SolutionContainer>
            </>}
        </TroubleshootingContainer>
    )
} 

export default TroubleshootHubError;
