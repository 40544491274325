import { useRef, useState } from "react";
import styled from "styled-components";
import serverConnection from "../../../../../scripts/server/ServerConnection";
import NeutralSpinner from "../../../../UiComponents/Animations/NeutralSpinner";
import Button from "../../../../UiComponents/Button";
import { ButtonRowContainer, ModalText } from "../../../../panel/ModalStyles";

const ReportList = styled.ul`
    height: 300px;
    overflow-y: auto;
    padding: 0;
    margin: 10px 0;

    >li {
        list-style: none;
        margin-bottom: 2px;
    }
`

const channelScanStates = {
    NONE: 0,
    SCANNING: 1,
    COMPLETED: 2,
    FAILED: 3,
    STOPPING: 4
}

const ChannelScan = ({ hub, cancel, formNetwork }) => {
    const [state, setState] = useState(channelScanStates.NONE);
    const [reports, setReports] = useState([]);
    const [scanResult, setScanResult] = useState();
    const listBottomRef = useRef();
    const eventStreamId = useRef();

    const start = () => {
        setState(channelScanStates.SCANNING);
        eventStreamId.current = serverConnection.hubEventHandler.startEventStream(
            hub.hubId,
            hub.customerId,
            'CHANNEL_SCAN',
            { action: 'START' },
            onData
        );
    }

    const stop = async () => {
        try {
            setState(channelScanStates.STOPPING);
            const result = await serverConnection.hubEventHandler.sendEvent(
                hub.hubId,
                hub.customerId,
                'CHANNEL_SCAN',
                { action: 'STOP' }
            );
            setState(channelScanStates.COMPLETED);
        } catch (err) {
            console.log('failed to stop channel scan');
        }
    }

    const onData = (data) => {
        if (data.type === 'REPORT') {
            setReports(current => ([...current, data]));
            listBottomRef.current?.scrollIntoView({ behavior: "smooth" })
        } else if (data.type === 'RESULT') {
            setScanResult(data.scanResult);
            setState(channelScanStates.COMPLETED);
        }
    }

    const getBestChannel = () => {
        let bestResult = 0;
        let bestChannel;
        if (scanResult) {
            Object.keys(scanResult).forEach(key => {
                if (scanResult[key] > bestResult) {
                    bestResult = scanResult[key];
                    bestChannel = key;
                }
            })
        }
        return bestChannel;
    }

    return <>
        {state === channelScanStates.NONE ? <>
            <ModalText>
                Use a channel scan sensor to report link quality index on available zigbee channels.
                Starting a new channel scan will close the current network and disconnect all connected sensors.
                Place the channel scan sensor about 10 meters from the hub and turn it on before proceeding to the next step.
            </ModalText>
            <ButtonRowContainer>
                <Button primary onClick={start}>Start</Button>
                <Button secondary margin onClick={cancel}>Cancel</Button>
            </ButtonRowContainer>
        </> : <></>}

        {state === channelScanStates.COMPLETED && scanResult ? <>
            <label>Scan result (channel averages)</label>
            <div>
                <ul>
                    {Object.keys(scanResult).map(key => <li key={key}>
                        Channel {key}: {scanResult[key]}
                    </li>)}
                </ul>
            </div>

            <ModalText>Channel scan is completed. Make sure the channel scan sensor is turned off before continuing to forming the network.</ModalText>
            <ButtonRowContainer>
                <Button primary onClick={() => formNetwork(getBestChannel())}>Form Network</Button>
                <Button secondary onClick={cancel}>Cancel</Button>
            </ButtonRowContainer>
        </> : <></>}

        {state !== channelScanStates.NONE ? <>
            <label>Scan reports</label>
            <div>
                {state === channelScanStates.SCANNING ? <>
                    <NeutralSpinner
                        size={20}
                        speed={1.4}
                    />
                </> : <></>}
            </div>

            <div>
                <ReportList>
                    {reports.map((report, ix) => <li key={ix}>
                        Channel {report.channel}: {report.lqi}
                    </li>)}
                    <li ref={listBottomRef}></li>
                </ReportList>
            </div>

        </> : <></>}

        {state === channelScanStates.SCANNING ? <>
            <ButtonRowContainer>
                <Button secondary onClick={stop}>Stop</Button>
            </ButtonRowContainer>
        </> : <></>}
        {state === channelScanStates.FAILED ? <>
            <ButtonRowContainer>
                <Button primary onClick={() => setState(channelScanStates.NONE)}>Retry</Button>
            </ButtonRowContainer>
        </> : <></>}
    </>
}

export default ChannelScan;