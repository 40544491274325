import { Layer } from "paper";
import { Color } from "paper/dist/paper-core";
import { getZoneColor } from '../colorUtils';
import heatmapTypes from "../../container/heatmapTypes";
import canvasController from "../CanvasController";
import Zone from "./Zone";
import LayerController from "../LayerController";
import { getOuterWallElement } from "../floor/wallUtils";
import { getFloorElementsByType } from "../floor/floorUtils";

class ZoneLayerController extends LayerController {
    constructor() {
        super();
        this.layer = new Layer();
        this.active = false;
        this.zones = [];
        this.outerWallElement = null;
        this.layer.name = 'Zone Layer';
    }

    onFloorChange(floorData) {
        const outerWallElement = getOuterWallElement(floorData);
        const zoneElements = getFloorElementsByType(floorData, heatmapTypes.elementTypes.ZONE);
        this.initializeZones(zoneElements, outerWallElement, floorData.activeFloor, floorData.activeMode);
    }

    onModeChange(modeName) {
        if (modeName === heatmapTypes.modes.DATA) {
            this.initDataMode();
        } else if (modeName === heatmapTypes.modes.SETUP) {    
            this.initSetupMode();
        }
    }

    onToolSelect(floorData) {
        const outerWallElement = getOuterWallElement(floorData);
        this.outerWallElement = outerWallElement;
        this.activate();
    }

    initializeZones(zoneElements, outerWallElement, activeFloor, mode) {
        this.activate();
        this.layer.removeChildren();
        this.outerWallElement = outerWallElement;
        this.zones.forEach(zone => zone.deactivate());
        this.allZones = zoneElements;
        this.zones = [];
        var floorZones = zoneElements.filter(e => e.floorId === activeFloor);
        floorZones.forEach((element) => {
            var hexColor = getZoneColor(element, this.allZones);
            let color = new Color(hexColor);
            color.alpha = 0.3;
            this.zones.push(new Zone(element, outerWallElement, color));
        });
    }

    placeZone(zone) {
        var zoneInArray = this.zones.find(e => e.floorElementID === zone.floorElementId);
        if (zoneInArray) {
            zoneInArray.select();
        } else {
            var hexColor = getZoneColor(zone, this.allZones);
            var color = new Color(hexColor);
            color.alpha = 0.3;
            this.zones.push(new Zone(zone, this.outerWallElement, color));
            this.zones[this.zones.length - 1].select();
        }
    }

    removeZone(zone) {
        var zoneInArray = this.zones.find(z => z.floorElementID === zone.floorElementId);
        if (zoneInArray) {
            zoneInArray.remove();
        } else {
            canvasController.canvasCallback(heatmapTypes.actions.ZONE_REMOVED, { id: zone.floorElementId });
        }
    }

    selectZone(zone) {
        this.deselectAllZones();
        var zoneInArray = this.zones.find(z => z.floorElementID === zone.floorElementId);
        if (zoneInArray) {
            zoneInArray.select();
        }
    }

    deselectAllZones() {
        this.zones.forEach(zone => zone.deselect());
    }

    activate() {
        this.layer.activate();
        this.active = true;
    }

    deactivate() {
        this.deselectAllZones();
        this.active = false;
    }

    initDataMode() {
        this.layer.activate();
        this.zones.forEach(zone => {
            if (zone.croppedZone) {
                zone.croppedZone.strokeColor = 'transparent';
                zone.croppedZone.fillColor = 'transparent';
                zone.croppedZone.dashArray = null;
                zone.croppedZone.strokeWidth = 0.25;
                zone.drawInnerPath();
            }
        })
    }

    initSetupMode() {
        this.zones.forEach(zone => {
            if (zone.croppedZone) {
                zone.croppedZone.fillColor = zone.color;
                zone.removeInnerPath();
            }
        })
    }

    showZoneData(zoneDataArray) {
        this.zones.forEach(zone => {
            if (zone.croppedZone) {
                var zoneData = zoneDataArray.find(z => z.zoneId === zone.floorElementID);
                if (zoneData) {
                    zone.showZoneData(Math.round(zoneData.averageDecibel)); // TODO: find out what datatype to use
                }
            }
        })
    }
}

export default ZoneLayerController;