import React from 'react';
import styled from 'styled-components';
import CanvasBox from './CanvasBox';
import heatmapTypes from './heatmapTypes';
import WallBankItem from './WallBankItem';
import {getUuid} from '../../../scripts/common';
import {FaPlus} from 'react-icons/fa';
import Button from '../../UiComponents/Button';
import OuterWallSymbol from './StyledComponents/OuterWallSymbol';
import InnerWallSymbol from './StyledComponents/InnerWallSymbol';

const List = styled.ul`
    list-style: none;
    padding: 0;
    margin: 5px 0;
`
const AddButton = styled(Button)`
    border: 2px dashed gray;
    margin-top: 12px;
`

const WallBank = props => {

    const selectWallHandler = (wallElement, highlight) => {
        props.actionHandler(heatmapTypes.actions.WALL_SELECT, {wallElement, highlight});
        if (!highlight) {
            props.setSelectedWall(wallElement.floorElementId);
        }
    }

    const deselectWallHandler = (wallElement, highlight, isSelected) => {
        props.actionHandler(heatmapTypes.actions.WALL_DESELECT, {wallElement, highlight, isSelected});
        if (!highlight) {
            props.setSelectedWall(null);
        }
    }

    const deselectAllWallsHandler = () => {
        props.actionHandler(heatmapTypes.actions.DESELECT_ALL_WALLS, {});
        props.setSelectedWall(null);
    }

    const addWallHandler = (elementType) => {
        var uuid = getUuid();
        var action = (elementType === heatmapTypes.elementTypes.OUTERWALL) ? heatmapTypes.actions.ADD_OUTER_WALL : heatmapTypes.actions.ADD_INNER_WALL;
        props.actionHandler(action, uuid);
        props.setSelectedWall(uuid);
    }

    const completeWallHandler = (id) => {
        props.actionHandler(heatmapTypes.actions.COMPLETE_INNER_WALL, id);
    }

    const deleteWallHandler = (id) => {
        props.actionHandler(heatmapTypes.actions.DELETE_WALL, id);
    }

    return <CanvasBox>
        <h4>Wall Elements</h4>
        {props.outerWallElement ? 
        <List>
            <WallBankItem
                wall={props.outerWallElement}
                selectWall={selectWallHandler}
                deselectWall={deselectWallHandler}
                deselectAllWalls={deselectAllWallsHandler}
                symbol={<OuterWallSymbol />}
                isSelected={props.selectedWall === props.outerWallElement.floorElementId} />
        </List> : 
        <Button primary onClick={() => addWallHandler(heatmapTypes.elementTypes.OUTERWALL)}>
            <span>Add outer wall</span>
        </Button>
        }
        
        {props.outerWallElement ? <>
            <List>
                {props.innerWallElements.map(wallElement => {
                    return <WallBankItem
                        key={wallElement.floorElementId}
                        wall={wallElement}
                        selectWall={selectWallHandler}
                        deselectWall={deselectWallHandler}
                        deselectAllWalls={deselectAllWallsHandler}
                        completeWallHandler={completeWallHandler}
                        deleteWallHandler={deleteWallHandler}
                        symbol={<InnerWallSymbol />}
                        isSelected={props.selectedWall === wallElement.floorElementId} />
                })}
            </List>
            <AddButton icon onClick={() => addWallHandler(heatmapTypes.elementTypes.INNERWALL)}>
                <span>Add inner wall</span>
                <FaPlus />
            </AddButton>
        </> : <></>}


    </CanvasBox>
}

export default WallBank;