import styled, { css } from "styled-components"
import theme from "../../../UI/theme"

export const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px;
`

export const GroupContainer = styled(GridContainer)`
    box-sizing: border-box;
    padding: 0 10px;
    touch-action: manipulation;
    border-radius: 2px;
    transition: all 0.5s;

    ${props => props.expanded === false && css`
        grid-gap: 0;
    `}
`

export const GroupRow = styled.div`
    display: grid;
    align-items: center;
    grid-gap: 10px;
    grid-template-columns: 1fr auto;
    overflow: hidden;
`

export const GroupSettingsContainer = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-auto-flow: column;
    padding-right: 14px;
`

export const GroupHeader = styled.label`
    font-size: 1.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

export const SubGrid = styled.div`
    display: grid;
    grid-template-columns: 50px auto;
    background-color: ${theme.colors.darkSpace80};

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-template-columns: 40px auto;
    }

    grid-gap: 4px;
    padding: 4px;
`