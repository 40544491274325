import React, { useRef, useState } from 'react';
import { useSubscription } from '../../hooks/useSubscription';
import { subscriptionsTypes } from '../../scripts/subscription/subscriptionTypes';
import ViewContainer from '../UiComponents/ViewContainer';
import NumberField from '../installation/fields/NumberField';
import Button from '../UiComponents/Button';
import Socket from './socket';
import InputField from '../UiComponents/InputField';

const WebSocketView = props => {
    const [nConnections, setNConnections] = useState(0);
    const [isRunning, setIsRunning] = useState(false);
    const connections = useRef([]);

    const startHandler = () => {
        setIsRunning(true);
        try {
            for (let i = 0; i < nConnections; i++) {
                const socket = new Socket({ socketCallback: (data) => console.log(data) });
                socket.connect();
                connections.current.push(socket);
            }

            setTimeout(() => {
                stopHandler();
            }, 300000);
        } catch (err) {
            console.log('Something went wrong', err);
        }
    }

    const stopHandler = () => {
        connections.current.forEach(conn => {
            try {
                conn.socket.close();
            } catch (err) {
                console.log('Failed to close', err);
            }
        });

        connections.current = [];
        setIsRunning(false);
    }

    return <ViewContainer>
        <div>
            WebSocket
            <div>
            <InputField type='number' value={nConnections} onChange={(e) => setNConnections(e.target.value)}></InputField>
            </div>
            {!isRunning ? <>
                <Button primary onClick={startHandler}>Start</Button>
            </> : <>
                <Button secondary onClick={stopHandler}>Stop</Button>
            </>}
        </div>
    </ViewContainer>
}

export default WebSocketView;
