import { updateMusicPlayer } from '../../scripts/musicPlayer';
import { getPlayerProvider } from './providers/providerUtils';

const updatableProps = ['state', 'volume', 'songTitle', 'artists', 'album', 'playFrom', 'imageUrl', 'songColor'];

const SECONDS = 1000;
const MINUTES = 60 * SECONDS;
const HOURS = 60 * MINUTES;

class MusicPlayer {
    constructor(playerInfo, callback) {
        this.name = playerInfo.name;
        this.displayName = playerInfo.displayName;
        this.type = playerInfo.type;
        this.customerId = playerInfo.customerId;
        this.musicPlayerId = playerInfo.musicPlayerId;
        this.remoteId = playerInfo.remoteId;
        this.sourceId = playerInfo.sourceId;
        this.sourceDisplayName = playerInfo.sourceDisplayName;
        this.defaultVolume = playerInfo.defaultVolume;
        this.state = undefined;
        this.isAuthorized = undefined;
        this.isConnected = undefined;
        this.isSubscribing = false;
        this.token = playerInfo.token;
        this.refreshToken = playerInfo.refreshToken;
        this.playerProvider = getPlayerProvider(playerInfo);
        this.music = {};
        this.callback = callback;
        this.reinitiationInterval = undefined;
    }

    async init() {
        try {
            const initialIsAuthorized = await this.checkAuthorization();
            this.startReinitiationInterval();
            if (initialIsAuthorized !== true) {
                await this.updateAuthorization();
                const isAuthorized = await this.checkAuthorization();
                if (isAuthorized === true) {
                    return await this.onAuthorize();
                } else {
                    return false;
                }
            } else {
                return await this.onAuthorize();
            }
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async onAuthorize() {
        try {
            const connected = await this.checkConnection();

            if (connected === true) {
                await this.getMusic();
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    startReinitiationInterval() {
        if (this.reinitiationInterval) clearInterval(this.reinitiationInterval);
        this.reinitiationInterval = setInterval(() => {
           this.cleanup();
           this.init();
        }, 24 * HOURS);
    }

    async getMusic() {
        await this.getPlayingMusic();
        await this.getAvailablePlaylists();
        await this.getAvailableSchedules();
        await this.subscribe();
    }

    async play() {
        try {
            const result = await this.playerProvider.play();
            if (result) return true;
            else return false;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async pause() {
        try {
            const result = await this.playerProvider.pause();
            if (result) return true;
            else return false;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async nextSong() {
        try {
            const result = await this.playerProvider.nextSong();
            if (result) return true;
            else return false;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async getState() {
        try {
            const state = await this.playerProvider.getState();
            if (state === 'PLAYING' || state === 'PAUSED') {
                this.state = state;
                return this.state;
            }
            else return false;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async getVolume() {
        try {
            var volume = await this.playerProvider.getVolume();
            if (typeof (volume) != "number") {
                volume = Number(volume);
            }
            this.volume = volume;
            return this.volume;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async setVolume(volume) {
        try {
            if (typeof (volume) != 'number') {
                return false;
            }
            const result = await this.playerProvider.setVolume(volume);
            if (result) {
                this.volume = volume;
                return true;
            }
            else {
                return false
            };
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async getPlayingMusic() {
        try {
            const music = await this.playerProvider.getPlayingMusic();
            var receivedMusicInfo = {}
            if (music.state === 'PLAYING' || music.state === 'PAUSED') {
                this.state = music.state;
                receivedMusicInfo.state = music.state;
                receivedMusicInfo.volume = music.volume;
                receivedMusicInfo.songTitle = music.songTitle;
                receivedMusicInfo.artists = music.artists;
                receivedMusicInfo.album = music.album;
                receivedMusicInfo.playFrom = music.playFrom;
                receivedMusicInfo.imageUrl = music.imageUrl;
                receivedMusicInfo.songColor = music.songColor;
                this.music = receivedMusicInfo;
                this.callback(receivedMusicInfo);
                return true;
            } else {
                this.state = undefined;
                return false;
            }
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async getAvailablePlaylists() {
        try {
            const playlists = await this.playerProvider.getAvailablePlaylists();
            if (playlists) {
                playlists.forEach(list => { list.label = list.name });
                this.music.availablePlaylists = playlists;
                this.callback(this.music);
                return true;
            } else return false;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async getAvailableSchedules() {
        try {
            const schedules = await this.playerProvider.getAvailableSchedules();
            if (schedules) {
                schedules?.forEach(schedule => { schedule.label = schedule.name });
                this.music.availableSchedules = schedules;
                this.callback(this.music);
            }
        } catch (e) {
            return false;
        }
    }

    async playPlaylist(playlistID) {
        try {
            const result = this.playerProvider.playPlaylist(playlistID);
            if (result) return true;
            else return false;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async queuePlaylist(playlistID) {
        try {
            const result = this.playerProvider.queuePlaylist(playlistID);
            if (result) return true;
            else return false;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async checkAuthorization() {
        try {
            const result = await this.playerProvider.checkAuthorization();
            if (result) {
                this.isAuthorized = true;
                return true
            }
            else {
                this.isAuthorized = false;
                return false
            };
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async checkConnection() {
        try {
            const result = await this.playerProvider.checkConnection();
            if (result) {
                this.isConnected = true;
                return true
            }
            else {
                this.isConnected = false;
                return false
            };
        } catch (e) {
            console.log(e);
            return false;
        }

    }

    async wakeupPlayer() {
        try {
            const result = await this.playerProvider.wakeupPlayer();
            if (result) {
                return true;
            }
            else {
                return false;
            }
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async updateAuthorization() {
        try {
            const updatedTokens = await this.playerProvider.updateAuthorization();
            if (updatedTokens) {
                const newToken = updatedTokens.token;
                const newRefreshToken = updatedTokens.refreshToken;
                await updateMusicPlayer({
                    musicPlayerId: this.musicPlayerId,
                    token: newToken,
                    refreshToken: newRefreshToken
                });
                this.token = newToken;
                this.refreshToken = newRefreshToken;
                this.playerProvider = getPlayerProvider({ type: this.type, remoteId: this.remoteId, token: this.token, refreshToken: this.refreshToken });
            }
            return true;
        } catch (e) {
            console.log(e);
            this.isAuthorized = false;
        }
    }

    async subscribe() {
        try {
            const subscribing = await this.playerProvider.subscribe(this);
            if (subscribing) this.isSubscribing = true;
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async cleanup() {
        try {
            if (this.reinitiationInterval) clearInterval(this.reinitiationInterval);
            this.playerProvider.cleanup();
        } catch (e) {
            console.log(e);
        }

    }

    subscriptionCallback(data) {
        updatableProps.forEach(prop => {
            if (data?.[`${prop}`]) {
                this.music[`${prop}`] = data[`${prop}`];
            }
        })
        this.callback(this.music);
    }
}
export default MusicPlayer;