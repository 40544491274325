import React from 'react';
import styled from 'styled-components';
import { FaExclamationCircle, FaTimesCircle } from 'react-icons/fa';
import theme from '../../../../../UI/theme';

const StyledValidationError = styled.span`
    font-size: ${theme.fontSize.tiny};
    svg {
        padding-right: 4px;
    }
`

const ValidationError = props => {

    if (props.validationError) {
        return <>
            <StyledValidationError>
                {getValidationIcon(props.validationError.type)}
                <span>{props.validationError.message}</span>
            </StyledValidationError>
        </>
    } else {
        return <></>
    }

}

export default ValidationError;

const getValidationIcon = (errorType) => {
    if (errorType === 'ERROR') {
        return <FaTimesCircle color={theme.colors.raspberryRed} />;
    } else if (errorType === 'WARNING') {
        return <FaExclamationCircle color={theme.colors.yellowFever} />;
    } else {
        return <></>;
    }
}