import { Chart } from "chart.js";
import annotationPlugin from 'chartjs-plugin-annotation';
import { useEffect, useRef, useState } from "react";
import { DateTime } from "luxon";
import theme from "../../../../../UI/theme";
import { getDateTime } from "../../../../../scripts/log";
Chart.register(annotationPlugin);

const chartData = {
    labels: [],
    datasets: [
        {
            label: 'Ambience dB',
            type: 'line',
            borderColor: `${theme.colors.blueMonday}`,
            backgroundColor: `${theme.colors.blueMonday}`,
            borderWidth: 2,
            pointRadius: 0,
            tension: 0.5,
            fill: false,
            order: 2,
            data: [],
        },
        {
            label: 'System Volume',
            type: 'line',
            borderColor: `${theme.colors.yellowFever}`,
            backgroundColor: `${theme.colors.yellowFever}`,
            borderWidth: 2,
            pointRadius: 0,
            tension: 0.5,
            fill: false,
            order: 1,
            hidden: true,
            data: [],
        },
        {
            label: 'Measured dB',
            type: 'line',
            borderColor: `${theme.colors.crystalBlue}`,
            backgroundColor: `${theme.colors.crystalBlue}`,
            borderWidth: 2,
            pointRadius: 0,
            tension: 0.5,
            fill: false,
            order: 3,
            hidden: true,
            data: [],
        }]
}

const options = {
    maintainAspectRatio: false,
    animation: {
        duration: 0,
    },
    scales: {
        x: {
            title: {
                display: false,
                text: 'Time',
                color: theme.colors.darkSpace50,
                padding: 0,
                font: {
                    size: 10
                },
            },
            display: true,
            type: 'time',
            time: {
                unit: 'second',
                tooltipFormat: 'TT',
                displayFormats: {
                    'minute': 'TT',
                }
            },
            ticks: {
                maxTicksLimit: 3,
                maxRotation: 0,
                color: theme.colors.darkSpace50
            },
            grid: {
                color: theme.colors.darkSpace80
            }
        },
        y: {
            type: 'linear',
            ticks: {
                color: theme.colors.darkSpace50,
            },
            grid: {
                color: theme.colors.darkSpace80
            }
        }
    },
    plugins: {
        title: {
            display: false,
            text: "Live measurements",
            color: theme.colors.textGray,
            padding: {
                top: 5
            }
        },
        legend: {
            display: false,
            labels: {
                usePointStyle: true,
                pointStyle: 'rectRounded',
                color: theme.colors.darkSpace50,
                boxWidth: 7,
                boxHeight: 7,
                useBorderRadius: true,
                borderRadius: 1,
                padding: 5,
                font: {
                    size: 10
                },
            }
        },
        tooltip: {
            enabled: true
        },
        annotation: {
            annotations: {
                savedBaseLevel: {
                    type: 'line',
                    borderColor: theme.colors.darkSpace50,
                    borderWidth: 2,
                    borderDash: [5, 5],
                },
                averageFilteredDb: {
                    type: 'line',
                    borderColor: theme.colors.blueMonday,
                    borderWidth: 2,
                    borderDash: [5, 5],
                    label: {
                        display: false,
                        content: 'Avg amb dB',
                        position: 'start',
                        backgroundColor: `${theme.colors.darkSpace90}`,
                        borderColor: theme.colors.purplePleasure,
                        borderWidth: 1,
                        borderDash: [5, 5],
                        borderRadius: 2,
                        color: theme.colors.purplePleasure,
                        yAdjust: -30,
                        padding: 2,
                        font: {
                            size: 10
                        }
                    }
                },
                baseLevel: {
                    type: 'line',
                    borderColor: theme.colors.purplePleasure,
                    borderWidth: 2,
                    label: {
                        display: false,
                        content: 'Base level',
                        position: 'start',
                        backgroundColor: `${theme.colors.darkSpace90}`,
                        borderColor: theme.colors.greenEnergy,
                        borderWidth: 1,
                        borderDash: [5, 5],
                        borderRadius: 2,
                        color: theme.colors.greenEnergy,
                        yAdjust: 0,
                        padding: 2,
                        font: {
                            size: 10
                        }
                    }
                }
            }
        }
    },
    pointHitRadius: 7,
}

const LiveChart = props => {
    const chartContainer = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    let chartConfig = JSON.parse(JSON.stringify({ data: chartData, options: { ...options } }));

    const chartConfigRef = useRef(JSON.parse(JSON.stringify(chartConfig)));

    useEffect(() => {
        chartConfigRef.current.data.datasets[0].data = props.data?.map(value => { return { x: DateTime.fromJSDate(getDateTime(value.time)), y: value.averageDiff > 0 && value.averageDecibel > 0 ? value.averageDecibel - value.averageDiff : null } });
        chartConfigRef.current.data.datasets[1].data = props.data?.map(value => { return { x: DateTime.fromJSDate(getDateTime(value.time)), y: value.sysvolAbsolute } });
        chartConfigRef.current.data.datasets[2].data = props.data?.map(value => { return { x: DateTime.fromJSDate(getDateTime(value.time)), y: value.averageDecibel > 0 ? value.averageDecibel : null } });

        if (chartInstance) {
            chartInstance.update();
        }
    }, [props.data])

    useEffect(() => {
        chartConfigRef.current.options.plugins.annotation.annotations.averageFilteredDb.yMin = props?.averageFilteredDb;
        chartConfigRef.current.options.plugins.annotation.annotations.averageFilteredDb.yMax = props?.averageFilteredDb;

        if (chartInstance) {
            chartInstance.update();
        }
    }, [props.averageFilteredDb])

    useEffect(() => {
        chartConfigRef.current.options.plugins.annotation.annotations.baseLevel.yMin = props?.baseLevel;
        chartConfigRef.current.options.plugins.annotation.annotations.baseLevel.yMax = props?.baseLevel;

        if (chartInstance) {
            chartInstance.update();
        }
    }, [props.baseLevel])

    useEffect(() => {
        chartConfigRef.current.options.plugins.annotation.annotations.savedBaseLevel.yMin = props?.savedBaseLevel;
        chartConfigRef.current.options.plugins.annotation.annotations.savedBaseLevel.yMax = props?.savedBaseLevel;

        if (chartInstance) {
            chartInstance.update();
        }
    }, [props.savedBaseLevel])

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            if (chartInstance) {
                chartInstance.destroy();
            }
            const newChartInstance = new Chart(chartContainer.current, chartConfigRef.current);
            setChartInstance(newChartInstance);
        }
    }, [chartContainer]);

    return (
        <canvas ref={chartContainer} />
    );
}

export default LiveChart;