import React from 'react';
import ChoiceField from '../../../fields/ChoiceField';
import { sourceMatrixTypes } from '../sourceMatrixTypes';

const choices = [{
    label: 'Mono',
    value: sourceMatrixTypes.MONO
}, {
    label: 'Stereo',
    value: sourceMatrixTypes.STEREO
}, {
    label: 'Stereo in',
    value: sourceMatrixTypes.STEREO_IN_MONO_OUT
}];

const SourceMatrixTypeChoiceField = props => {
    const selectedItem = choices.find(choice => choice.value === props.type);
    return <ChoiceField
        selectedItem={selectedItem}
        items={choices}
        setValue={props.setValue}
        cellControl={props.cellControl}
        excludeNone={true}
    />
}

export const getSourceMatrixTypeDisplay = (type) => {
    return choices.find(choice => choice.value === type)?.label;
}

export default SourceMatrixTypeChoiceField;