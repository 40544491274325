import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { SensorIcon } from "../UiComponents/Icons";
import { getHubDisplayName } from "./modules/hub/hubUtils";
import { TreeUL, TreeLI, LIHeader, TreeContainer, TopLevelTreeLI, TopLevelTreeUL, BranchLabel } from "./installationStyles";
import { HubIcon, ProcessorIcon, SourceSelectorIcon, ZoneIcon } from "./styling/Icons";

const SystemTree = props => {
    return <TreeContainer>
        <TopLevelTreeUL>
            {props.hubs?.map(hub => {
                const processorsForHub = props.processors?.filter(processor => processor.hubId === hub.hubId);

                return <HubBranch
                    key={hub.hubId}
                    hub={hub}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    selectedItemHierarchy={props.selectedItemHierarchy}
                    setSelectedItemHierarchy={props.setSelectedItemHierarchy}
                    ancestors={[]}
                    processors={processorsForHub}
                    sourceSelectors={props.sourceSelectors?.filter(sourceSelector => processorsForHub?.some(processor => processor.processorId === sourceSelector.processorId))}
                    zones={props.zones?.filter(zone => zone.hubId === hub.hubId)}
                    sensors={props.sensors?.filter(sensor => sensor.hubId === hub.hubId)}
                />
            })}
        </TopLevelTreeUL>
    </TreeContainer>
}

export default SystemTree;

const Branch = ({ children, Icon, hierarchyItem, ancestors, ...props }) => {
    const [expanded, setExpanded] = useState(true);
    const hasChildren = children?.length && !(Array.isArray(children) && children?.every(list => list.length === 0));
    const selected = props.selectedItem?.id === hierarchyItem?.id;

    const onClick = () => {
        if (props.setSelectedItem) {
            props.setSelectedItem({ ...hierarchyItem });
        }
        if (props.setSelectedItemHierarchy) {
            props.setSelectedItemHierarchy([...ancestors, { ...hierarchyItem }]);
        }
    }

    return <>
        <LIHeader selected={selected}>
            <Icon />
            <BranchLabel
                selected={selected}
                onClick={() => onClick()}>
                {props.header}
            </BranchLabel>
            {!expanded && hasChildren ? <FaChevronDown style={{ cursor: 'pointer' }} onClick={() => { setExpanded(true) }} /> :
                expanded && hasChildren ? <FaChevronUp style={{ cursor: 'pointer' }} onClick={() => setExpanded(false)} /> :
                    <></>}
        </LIHeader>

        {hasChildren ?
            <TreeUL expanded={expanded}>
                {children}
            </TreeUL>
            : <></>}
    </>
}

const HubBranch = props => {
    const zonesWithoutProcessor = props.zones?.filter(zone => !props.processors?.some(processor => processor.processorId === zone.processorId));
    const sensorsWithoutZone = props.sensors?.filter(sensor => !props.zones?.some(zone => zone.zoneId === sensor.zoneId));
    const hierarchyItem = { id: props.hub?.hubId, type: 'HUB', name: getHubDisplayName(props.hub) };

    return <TopLevelTreeLI>
        <Branch
            header={`${getHubDisplayName(props.hub)}`}
            Icon={HubIcon}
            hierarchyItem={hierarchyItem}
            {...props}
        >
            {props.processors?.map(processor => {
                const zonesForProcessor = props.zones?.filter(zone => zone.processorId === processor.processorId);
                const sensorsForProcessor = props.sensors?.filter(sensor => zonesForProcessor?.some(zone => zone.zoneId === sensor.zoneId));

                return <ProcessorBranch
                    key={processor.processorId}
                    processor={processor}
                    sourceSelectors={props.sourceSelectors?.filter(sourceSelector => sourceSelector.processorId === processor.processorId)}
                    zones={zonesForProcessor}
                    sensors={sensorsForProcessor}
                    ancestors={[...props.ancestors, hierarchyItem]}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    selectedItemHierarchy={props.selectedItemHierarchy}
                    setSelectedItemHierarchy={props.setSelectedItemHierarchy}
                />
            })}

            {zonesWithoutProcessor?.map(zone => {
                return <ZoneBranch
                    key={zone.zoneId}
                    zone={zone}
                    sensors={props.sensors?.filter(sensor => sensor.zoneId === zone.zoneId)}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    selectedItemHierarchy={props.selectedItemHierarchy}
                    setSelectedItemHierarchy={props.setSelectedItemHierarchy}
                    ancestors={[...props.ancestors, hierarchyItem]}
                />
            })}

            {sensorsWithoutZone?.map(sensor => {
                return <SensorBranch
                    key={sensor.sensorId}
                    sensor={sensor}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    selectedItemHierarchy={props.selectedItemHierarchy}
                    setSelectedItemHierarchy={props.setSelectedItemHierarchy}
                    ancestors={[...props.ancestors, hierarchyItem]}
                />
            })}
        </Branch>
    </TopLevelTreeLI>
}

const ProcessorBranch = props => {
    const zonesWithoutSourceSelector = props.zones?.filter(zone => !props.sourceSelectors?.some(sourceSelector => sourceSelector.sourceSelectorId === zone.sourceSelectorId));
    const hierarchyItem = { id: props.processor?.processorId, type: 'PROCESSOR', name: props.processor?.name };

    return <TreeLI>
        <Branch
            header={props.processor?.name}
            Icon={ProcessorIcon}
            hierarchyItem={hierarchyItem}
            {...props}
        >
            {props.sourceSelectors?.map(sourceSelector => {
                const zonesForSourceSelector = props.zones?.filter(zone => zone.sourceSelectorId === sourceSelector.sourceSelectorId);
                const sensorsForSourceSelector = props.sensors?.filter(sensor => zonesForSourceSelector?.some(zone => zone.zoneId === sensor.zoneId));

                return <SourceSelectorBranch
                    key={sourceSelector.sourceSelectorId}
                    sourceSelector={sourceSelector}
                    zones={zonesForSourceSelector}
                    sensors={sensorsForSourceSelector}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    selectedItemHierarchy={props.selectedItemHierarchy}
                    setSelectedItemHierarchy={props.setSelectedItemHierarchy}
                    ancestors={[...props.ancestors, hierarchyItem]}
                />
            })}

            {zonesWithoutSourceSelector?.map(zone => {
                return <ZoneBranch
                    key={zone.zoneId}
                    zone={zone}
                    sensors={props.sensors?.filter(sensor => sensor.zoneId === zone.zoneId)}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    selectedItemHierarchy={props.selectedItemHierarchy}
                    setSelectedItemHierarchy={props.setSelectedItemHierarchy}
                    ancestors={[...props.ancestors, hierarchyItem]}
                />
            })}
        </Branch>
    </TreeLI>
}

const SourceSelectorBranch = props => {
    const hierarchyItem = { id: props.sourceSelector?.sourceSelectorId, type: 'SOURCE_SELECTOR', name: props.sourceSelector?.name };

    return <TreeLI>
        <Branch
            header={props.sourceSelector?.name}
            Icon={SourceSelectorIcon}
            hierarchyItem={hierarchyItem}
            {...props}
        >
            {props.zones?.map(zone => {
                return <ZoneBranch
                    key={zone.zoneId}
                    zone={zone}
                    sensors={props.sensors?.filter(sensor => sensor.zoneId === zone.zoneId)}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    selectedItemHierarchy={props.selectedItemHierarchy}
                    setSelectedItemHierarchy={props.setSelectedItemHierarchy}
                    ancestors={[...props.ancestors, hierarchyItem]}
                />
            })}
        </Branch>
    </TreeLI>
}

const ZoneBranch = props => {
    const hierarchyItem = { id: props.zone?.zoneId, type: 'ZONE', name: props.zone?.zoneName };

    return <TreeLI>
        <Branch
            header={props.zone?.zoneName}
            Icon={ZoneIcon}
            hierarchyItem={hierarchyItem}
            {...props}
        >
            {props.sensors?.map(sensor => {
                return <SensorBranch
                    key={sensor.sensorId}
                    sensor={sensor}
                    selectedItem={props.selectedItem}
                    setSelectedItem={props.setSelectedItem}
                    selectedItemHierarchy={props.selectedItemHierarchy}
                    setSelectedItemHierarchy={props.setSelectedItemHierarchy}
                    ancestors={[...props.ancestors, hierarchyItem]}
                />
            })}
        </Branch>
    </TreeLI>
}

const SensorBranch = props => {
    const hierarchyItem = { id: props.sensor?.sensorId, type: 'SENSOR', name: props.sensor?.sensorId };

    return <TreeLI>
        <Branch
            header={`Sensor ${props.sensor?.sensorId}`}
            Icon={SensorIcon}
            hierarchyItem={hierarchyItem}
            {...props}
        />
    </TreeLI>
}