import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const path = '/v2/storage';

async function uploadImage(customerId, formData) {
    const res = await axios.post(`${hostname}${path}/upload?customerId=${customerId}`, formData);
    return res.data;
}

async function deleteImage(customerId, filename) {
    const res = await axios.delete(`${hostname}${path}?customerId=${customerId}&filename=${filename}`);
    return res.data;
}

export {
    uploadImage,
    deleteImage
}
