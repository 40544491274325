import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Overlay from './Overlay';
import { FaChevronDown, FaTimesCircle } from "react-icons/fa";
import theme from '../../UI/theme';

const DropdowItem = styled.li`
    padding: 10px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    :not(:last-child) {
        border-bottom: 1px solid ${theme.colors.darkSpace80};
    }

    &:hover {
        background-color: ${theme.colors.darkSpace80};
    }
`

const DropdownList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: fill-available;
    background-color: ${theme.colors.darkSpace90};
    color: ${theme.colors.textGray};
    border-radius: 1px;
    z-index: 100;
    border: 1px solid ${theme.colors.darkSpace80};
    opacity: 0;
    transition: opacity 0.1s;
`

const DropdownButton = styled.button`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;
    padding: 10px;
    border: none;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: ${theme.fontSize.medium};
    align-items: center;
    width: ${props => props.width ? `${props.width}px` : 'fill-available'};
    background-color: ${theme.colors.darkSpace80};
    color: ${theme.colors.textGray};
    cursor: pointer;
    border: 1px solid transparent;

    &> svg {
        width: 20px;
        height: 20px;
    }

    &:focus, &:hover {
        border: 1px solid ${theme.colors.textGray};
    }
`

const SelectedItemLabel = styled.label`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: start;
    cursor: inherit;
`

const ClearSelectionLabel = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 5px;
    overflow: hidden;
    align-items: center;

    &> svg {
        width: 15px;
        height: 15px;
    }

    &> label {
        cursor: inherit;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const Dropdown = props => {
    const [showList, setShowList] = useState(false);
    const listRef = useRef();
    const buttonRef = useRef();

    const updateListPosition = () => {
        const listBoundingRect = listRef?.current?.getBoundingClientRect();
        const screenHeight = window.innerHeight;
        const buttonBoundingRect = buttonRef?.current?.getBoundingClientRect();

        if (buttonBoundingRect?.y + buttonBoundingRect?.height + listBoundingRect?.height >= screenHeight && buttonBoundingRect?.y > screenHeight * 2 / 3) {
            listRef.current.style.maxHeight = `${buttonBoundingRect?.y}px`;
            const updated = listRef?.current?.getBoundingClientRect();
            listRef.current.style.top = `${buttonRef?.current?.offsetTop - updated?.height}px`;
        } else {
            listRef.current.style.maxHeight = `${screenHeight - buttonBoundingRect?.y - buttonBoundingRect?.height - 20}px`;
            listRef.current.style.top = `${buttonRef?.current?.offsetTop + buttonBoundingRect?.height}px`;
        }

        listRef.current.style.width = `${buttonBoundingRect?.width}px`;
        listRef.current.style.left = `${buttonRef?.current?.offsetLeft}px`;
        listRef.current.style.opacity = 1;
    }

    useEffect(() => {
        if (showList === true) {
            updateListPosition();
        }
    }, [showList])

    function clickHandler(item) {
        setShowList(false);
        if (props.onClick) {
            props.onClick(item);
        }
    }

    function displayItems(items) {
        return items?.map((item, index) =>
            <DropdowItem index={index}
                onClick={() => clickHandler(item)}>
                {item.label}
            </DropdowItem>);
    }

    return <>
        <DropdownButton ref={buttonRef} {...props} onClick={() => setShowList(s => !s)} >
            <SelectedItemLabel>{props.selectedItem ? props.selectedItem?.label : props.placeholder ?? '-'}</SelectedItemLabel>
            <FaChevronDown />
        </DropdownButton>

        {showList === true ?
            <>
                <DropdownList ref={listRef}>
                    {props.selectedItem && props.clearable === true ? <>
                        <DropdowItem onClick={() => clickHandler(null)}>
                            <ClearSelectionLabel>
                                <label>Clear</label>
                                <FaTimesCircle />
                            </ClearSelectionLabel>
                        </DropdowItem>
                    </> : <></>}

                    {displayItems(props.items)}
                </DropdownList>
                <Overlay onClick={() => setShowList(false)} />
            </>
            : <></>}
    </>
}


export default Dropdown;