import styled, { css } from "styled-components"
import { StyledModalOverlay } from "../UiComponents/Modal"
import { useState } from "react";
import IconButton from "../UiComponents/buttons/IconButton";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import theme from "../../UI/theme";

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    background-color: ${theme.colors.darkSpace90};

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        width: 100%;
    }

    @media only screen and (min-width: ${theme.screenSizes.medium}px) {
        width: 800px;
    }
`

const Arrow = styled(IconButton)`
    position: absolute;
    top: 50%;
    background-color: ${theme.colors.darkSpace70};
    padding: 6px;
    border-radius: 50%;
    opacity: 0.7;
    > svg {
        color: white;
    }
    ${props => props.direction && css`
        ${props.direction}: 10px;
    `}

    ${props => props.disabled && css`
        visibility: hidden;
    `}
`

const Counter = styled.div`
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -12px;
    background-color: ${theme.colors.darkSpace70};
    padding: 6px;
    border-radius: 6px;
    opacity: 0.8;
`

const OverLay = styled(StyledModalOverlay)`
    background-color:#4e4e4ee1;
`

const ImageCarousel = ({ imageUrls, startIndex, close }) => {
    const [selectedIndex, setSelectedIndex] = useState(startIndex || 0);

    const arrowClick = (e, nextIndex) => {
        e.stopPropagation();
        setSelectedIndex(nextIndex);
    }

    function handleOverlayClick(ev) {
        if (ev.target.id === 'overlay') {
            ev.stopPropagation();
            close();
        }
    }

    return <OverLay onClick={handleOverlayClick} id="overlay">
        <ImageContainer>
            <div>
                <img style={{ maxWidth: '100%', maxHeight: '80vh' }} src={imageUrls[selectedIndex]} alt="Image" />
            </div>
            <Arrow direction={'left'} disabled={selectedIndex === 0} onClick={(e) => arrowClick(e, selectedIndex - 1)}><FaChevronLeft /></Arrow>
            <Arrow direction={'right'} disabled={selectedIndex === imageUrls.length - 1} onClick={(e) => arrowClick(e, selectedIndex + 1)}><FaChevronRight /></Arrow>
            <Counter>
                {(selectedIndex + 1).toString()}/{imageUrls.length}
            </Counter>
        </ImageContainer>
    </OverLay>
}

export default ImageCarousel;