import React from 'react';
import styled from 'styled-components';
import { Range, Direction } from 'react-range';
import theme from '../../../UI/theme';

const colormap = [];
const defaultHeight = '60px';
const defaultWidth = '36px';
const defaultThumbHeight = '6px';
const defaultBorderWidth = '3px';

const ZoneModeMeter = props => {

    return <div onClick={(e) => { e.stopPropagation() }}>
        <Slider
            value={props.value}
            thumbColor={getThumbColor(props)}
            thumbHeight={props.thumbHeight}
            height={props.height}
            width={props.width}
            borderWidth={props.borderWidth}
        />
    </div>
}

export default ZoneModeMeter;

const Thumb = styled.div`
    height: ${props => props.thumbHeight ?? '6px'};
    width: 50%;
    background-color: ${props => props.thumbColor || theme.colors.greenEnergy};
`

const Track = styled.div`
    border: 3px solid ${theme.colors.textGray}30;
    border-radius: 4px;
    border-width: ${props => props.borderWidth || '3px'};
    direction: initial;
`

const STEP = 0.1;
const MIN = -3;
const MAX = 3

const Slider = props => {
    var thumbColor = props.thumbColor;
    const height = props.height || defaultHeight;
    const width = props.width || defaultWidth;
    const thumbHeight = props.thumbHeight || defaultThumbHeight;
    const borderWidth = props.borderWidth || defaultBorderWidth;

    return (
        <Range
            step={STEP}
            min={MIN}
            max={MAX}
            values={[props.value]}
            onChange={() => { }}
            direction={Direction.Up}
            renderTrack={({ props, children }) => (
                <Track
                    {...props}
                    style={{
                        ...props.style,
                        height: height,
                        width: width,
                        backgroundColor: theme.colors.darkSpace,
                        borderWidth: borderWidth,
                        cursor: 'auto'
                    }}
                >
                    {children}
                </Track>
            )}
            renderThumb={({ props }) => (
                <Thumb
                    {...props}
                    thumbColor={thumbColor}
                    thumbHeight={thumbHeight}
                    style={{
                        ...props.style,
                        cursor: 'auto',
                    }}
                />
            )}
        />
    );
}


function getThumbColor(props) {
    var thumbColor;
    if (props.thumbColor) {
        thumbColor = props.thumbColor;
    } else if (props.disabled) {
        thumbColor = theme.colors.darkSpace70;
    }
    return thumbColor;
}