import React, { useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { checkExistingCustomer, createCustomer, getCustomer } from '../../scripts/datasource';
import { localSetup } from '../../scripts/hub';
import Button from '../UiComponents/Button';
import InputField from '../UiComponents/InputField';
import Radio from '../UiComponents/Radio';
import { TextMuted } from '../UiComponents/text/StyledText';
import setupSteps from './setupSteps';
import { RowContainer, Header, SectionContainer, SetupContainer, ErrorLabel } from './setupStyles';
import { ButtonRowContainer } from '../panel/ModalStyles';
import AddInstallerPermission from './AddInstallerPermission';

const hubSetupStates = {
    NONE: 0,
    SETTING_UP: 1,
    SETUP_COMPLETE: 2,
    SETUP_FAILED: 3
}

const customerConfigurations = {
    NEW_CUSTOMER: 0,
    EXISTING_CUSTOMER: 1
}

const CustomerSetup = props => {
    const history = useHistory();
    const [hubSetupState, setHubSetupState] = useState(hubSetupStates.NONE);
    const [customer, setCustomer] = useState();
    const [customerConfiguration, setCustomerConfiguration] = useState(customerConfigurations.NEW_CUSTOMER);
    const [errorText, setErrorText] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [customerId, setCustomerId] = useState();
    const [customerSetupKey, setCustomerSetupKey] = useState();

    const createCustomerHandler = async () => {
        try {
            setHubSetupState(hubSetupStates.NONE);
            const newCustomerObject = { companyName: customerName };
            let newCustomer = await createCustomer(newCustomerObject);
            setCustomer(newCustomer);
            setCustomerSetupKey(newCustomer.setupKey);
        } catch (error) {
            setHubSetupState(hubSetupStates.SETUP_FAILED);
            setErrorText('Could not create venue.');
        }
    }

    const loadCustomerHandler = async () => {
        try {
            setHubSetupState(hubSetupStates.NONE);
            let existingCustomer = await checkExistingCustomer(customerId);
            setCustomer(existingCustomer);
            setCustomerName(existingCustomer?.companyName);
        } catch (error) {
            setCustomer(null);
            setCustomerName('');
            setHubSetupState(hubSetupStates.SETUP_FAILED);
            if (error?.response?.status === 404) {
                setErrorText(`Venue with ID ${customerId} not found.`);
            } else {
                setErrorText('Could not load venue.');
            }
        }
    }

    const setupHub = async () => {
        setHubSetupState(hubSetupStates.SETTING_UP);
        setErrorText('');
        let setupParameters = {
            customerId: customer?.customerId,
            networkInterface: props.networkInterface
        }

        try {
            await localSetup(setupParameters);
            setTimeout(() => {
                setHubSetupState(hubSetupStates.SETUP_COMPLETE);
                history.push('/installation');
                window.location.reload();
            }, 4000);
        } catch (error) {
            setHubSetupState(hubSetupStates.SETUP_FAILED);
            setErrorText("Could not complete hub setup.");
        }
    }

    const clearCustomer = () => {
        setCustomer(null);
        setCustomerName('');
        setCustomerId(null);
    }

    return <SetupContainer>
        <Header>Venue configuration</Header>

        <SectionContainer>
            <label>Should the hub be set up for a new or an existing venue?</label>
            <RowContainer>
                <Radio
                    name={"customerConfiguration"}
                    checked={customerConfiguration === customerConfigurations.NEW_CUSTOMER}
                    onChange={() => { setCustomerConfiguration(customerConfigurations.NEW_CUSTOMER); clearCustomer(); }}
                />
                <TextMuted>New venue</TextMuted>
            </RowContainer>
            <RowContainer>
                <Radio
                    name={"customerConfiguration"}
                    checked={customerConfiguration === customerConfigurations.EXISTING_CUSTOMER}
                    onChange={() => { setCustomerConfiguration(customerConfigurations.EXISTING_CUSTOMER); clearCustomer(); }}
                />
                <TextMuted>Existing venue</TextMuted>
            </RowContainer>
        </SectionContainer>

        {customer ? <>
            <SectionContainer>
                <label>Selected venue</label>

                <TextMuted>Venue: {customer?.companyName}</TextMuted>
                <TextMuted>Venue ID: {customer?.customerId}</TextMuted>

                <div>
                    <Button tertiary small onClick={() => clearCustomer()}>Change venue</Button>
                </div>

                {customerConfiguration === customerConfigurations.NEW_CUSTOMER ? <>
                    <div>
                        <AddInstallerPermission customerId={customer?.customerId} customerSetupKey={customerSetupKey} />
                    </div>
                </> : <></>}
            </SectionContainer>

        </> : <>
            {customerConfiguration === customerConfigurations.NEW_CUSTOMER ? <>
                <SectionContainer>
                    <label>Create venue</label>

                    <TextMuted>Name</TextMuted>
                    <InputField value={customerName ?? ''} onChange={(e) => setCustomerName(e.target.value)} />

                    <ButtonRowContainer>
                        <Button
                            small
                            primary
                            onClick={() => createCustomerHandler()}
                            disabled={customerName?.length < 1}
                        >Create venue
                        </Button>
                    </ButtonRowContainer>
                </SectionContainer>
            </> : customerConfiguration === customerConfigurations.EXISTING_CUSTOMER ? <>
                <SectionContainer>
                    <label>Load existing venue</label>

                    <TextMuted>ID</TextMuted>
                    <InputField type='number' value={customerId} onChange={(e) => setCustomerId(e.target.value)} />

                    <div>
                        <Button small primary onClick={() => loadCustomerHandler()}>Load venue</Button>
                    </div>
                </SectionContainer>
            </> : <></>}
        </>}

        {hubSetupState === hubSetupStates.SETUP_FAILED ? <>
            <SectionContainer>
                <label>Error</label>
                <ErrorLabel><FaExclamationCircle /> {errorText}</ErrorLabel>
            </SectionContainer>
        </> : <></>}

        <SectionContainer>
            <label>Complete setup</label>

            {!customer ? <TextMuted>Create or load an existing venue to complete setup.</TextMuted> : <></>}

            {hubSetupState === hubSetupStates.SETTING_UP ? <TextMuted>Setting up ...</TextMuted> : <></>}


            <RowContainer>
                <Button
                    tertiary
                    small
                    disabled={hubSetupState === hubSetupStates.SETTING_UP}
                    onClick={() => props.setSetupStep(setupSteps.NETWORK_SETUP)}>
                    Go back
                </Button>
                <Button
                    primary
                    small
                    disabled={
                        !props.networkInterface ||
                        !customer?.customerId ||
                        hubSetupState === hubSetupStates.SETTING_UP}
                    onClick={() => setupHub()}>
                    Complete setup
                </Button>
            </RowContainer>
        </SectionContainer>

    </SetupContainer>
}

export default CustomerSetup;