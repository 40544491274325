import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { getTrendData } from "../../../scripts/trends";
import { dataTypes, hierarchyLevels, periods } from "../../statistics/trendTypes";
import { useSubscription } from "../../../hooks/useSubscription";
import { subscriptionsTypes } from "../../../scripts/subscription/subscriptionTypes";
import DecibelChart from "./DecibelChart";
import theme from "../../../UI/theme";
import { getDecibelWarnings } from "./decibelInsightsUtils";
import HistoryInsightsReport from "./DecibelInsightsReport";
import HistoryInsightsPdfReportContainer from "./DecibelInsightsPdfReportContainer";
import { CardHeader, ChartContainer, ChartGrid, MainChartContainer, MetricsContainer, ThumbnailContainer } from "../insightsStyles";
import KeyMetric from "../../UiComponents/KeyMetric";
import { VolumeIcon } from "../../UiComponents/Icons";
import BarChartSkeleton from "../../panel/skeletons/BarChartSkeleton";
import hierarchyTypes from "../../settings/venue/device/hierarchyTypes";
import useMixpanel from "../../../hooks/useMixpanel";

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;

    @media only screen and (max-width: ${theme.screenSizes.medium - 1}px) {
        grid-gap: 10px;
    }
`

const DecibelInsights = props => {
    const [zones, setZones] = useSubscription(subscriptionsTypes.zones);
    const [sensors, setSensors] = useSubscription(subscriptionsTypes.sensors);
    const [weekTrends, setWeekTrends] = useState();
    const [selectedZoneId, setSelectedZoneId] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const mix = useMixpanel();

    useEffect(() => {
        setSelectedZoneId(null);
        setWeekTrends(null);
        getData();
        mix.trackEvent('Decibel Insights Viewed', { venue: props.customer.companyName, customer: props.customer });
    }, [props.customer?.customerId])
    
    useEffect(() => {
        getData()
    }, [props.dateTime, zones?.length, sensors?.length])

    const getData = async () => {
        const currentWeekStart = DateTime.fromISO(weekTrends?.[0]?.startDate)?.startOf('week')?.ts;
        if (props.dateTime?.startOf('week')?.ts !== currentWeekStart) {
            if (zones?.length > 0 && sensors?.length > 0) {
                setIsLoading(true);
                let newWeekTrends = [];
                for (let index = 0; index < zones.length; index++) {
                    const zone = zones[index];
                    if (zone?.hide === 0 &&
                        zone?.shouldRegulate === 1 &&
                        zone?.hierarchyType !== hierarchyTypes.MASTER.value &&
                        (
                            sensors?.some(
                                sensor => sensor?.zoneId === zone?.zoneId ||
                                    sensor?.zoneId === zone?.backupZoneId
                            )
                        )
                    ) {
                        const weekTrend = await getTrendData(props.customer?.customerId, periods.WEEK, props.dateTime.toJSDate(), hierarchyLevels.ZONE, zone?.zoneId, dataTypes.averageDecibel);
                        getDecibelWarnings(weekTrend[0], zone);
                        newWeekTrends.push(weekTrend[0]);
                    }
                }

                const newRelevantTrends = newWeekTrends?.filter(trend => zones?.some(zone => zone?.zoneId === trend?.id));
                setWeekTrends(newRelevantTrends);
                if (!newRelevantTrends?.some(trend => trend?.id === selectedZoneId)) {
                    setSelectedZoneId(newRelevantTrends?.[0]?.id);
                }
                setIsLoading(false);
            }
        }
    }

    return <Container>

        {weekTrends?.length > 0 || isLoading === true ?
            <>
                <ChartGrid>
                    <MainChart
                        zone={zones?.find(zone => zone?.zoneId === selectedZoneId)}
                        trend={weekTrends?.find(trend => trend?.id === selectedZoneId)}
                        dateTime={props.dateTime}
                        noOfRelevantTrends={weekTrends?.length}
                        isLoading={isLoading}
                    />

                    {weekTrends?.length > 1 ? <>
                        {zones?.sort((a, b) => a?.orderIndex > b?.orderIndex)?.map(zone => {
                            const zoneTrend = weekTrends?.find(trend => trend?.id === zone?.zoneId);
                            if (zoneTrend) {
                                return <ThumbnailContainer key={zoneTrend?.id} onClick={() => setSelectedZoneId(zone?.zoneId)} selected={zoneTrend?.id === selectedZoneId}>
                                    <label>{zone?.zoneName}</label>
                                    {isLoading === false ? <>
                                        <ChartContainer>
                                            <DecibelChart thumbnail data={zoneTrend?.data?.[props.dateTime?.weekday - 1]?.hourData} />
                                        </ChartContainer>
                                    </> : <>
                                        <BarChartSkeleton numberOfBars={24} />
                                    </>}
                                </ThumbnailContainer>
                            }
                        })}
                    </> : <></>}
                </ChartGrid>

                <HistoryInsightsReport warnings={weekTrends?.map(weekTrend => weekTrend?.data?.[props.dateTime?.weekday - 1]?.warnings)} zones={zones} />

                <HistoryInsightsPdfReportContainer customer={props.customer} dateTime={props.dateTime} zones={zones} trends={weekTrends} />
            </>
            : <>
                <label>No data available for selected date</label>
            </>}

    </Container>
}

export default DecibelInsights;

const MainChart = props => {
    const hourData = props.trend?.data?.[props.dateTime?.weekday - 1]?.hourData ?? [];
    const nonZeroHourData = hourData?.filter(value => value > 0);
    const peakValue = Math.max(...nonZeroHourData);
    const avg = nonZeroHourData?.reduce((a, b) => a + b, 0) / nonZeroHourData?.length;

    return <MainChartContainer noOfRelevantTrends={props.noOfRelevantTrends}>
        <CardHeader>{props.zone?.zoneName}</CardHeader>
        {props.isLoading === false ? <>
            <ChartContainer>
                <DecibelChart data={hourData} />
            </ChartContainer>
        </> : <>
            <BarChartSkeleton numberOfBars={24} />
        </>}
        <MetricsContainer>
            <KeyMetric
                title={'Max decibel'}
                metric={nonZeroHourData?.length > 0 ? `${peakValue?.toFixed(1)} dB` : '-'}
                icon={<VolumeIcon />}
            />
            <KeyMetric
                title={'Average decibel'}
                metric={nonZeroHourData?.length > 0 ? `${avg?.toFixed(1)} dB` : '-'}
                icon={<VolumeIcon />}
            />
        </MetricsContainer>
    </MainChartContainer>
}