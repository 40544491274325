import axios from 'axios';
import { getHostname } from './server/server' 

const hostname = getHostname();
const sensorPath = '/v2/sensor';

async function getSensor(sensorId) {
    const res = await axios.get(`${hostname}${sensorPath}?sensorId=${sensorId}`);
    return res.data;
}

async function getSensors(customerId) {
    const res = await axios.get(`${hostname}${sensorPath}/sensors?customerId=${customerId}`);
    return res.data;
}

async function updateSensor(sensor) {
    const res = await axios.put(`${hostname}${sensorPath}`, sensor);
    return res.data;
}

async function updateSensors(sensors) {
    const res = await axios.put(`${hostname}${sensorPath}/list`, sensors);
    return res.data;
}

async function deleteSensor(sensorId) {
    const res = await axios.delete(`${hostname}${sensorPath}?sensorId=${sensorId}`);
    return res.data;
}

async function getAllSensors() {
    const res = await axios.get(`${hostname}${sensorPath}/all`);
    return res.data;
}

export {
    getSensor,
    getSensors,
    updateSensor,
    updateSensors,
    deleteSensor,
    getAllSensors
}