import React, { useState } from "react";
import { TroubleshootingContainer, TroubleshootingButton, SolutionContainer, HubInfoContainer, InstallationInfoWrapper } from "./StylesTroubleshooting";
import InstallationItemInfo from "../installation/modules/installationDocumentation/info/InstallationItemInfo";
import useScreenSize from "../../hooks/useScreenSize"
import theme from "../../UI/theme";

const TroubleshootHubOffline = (props) => {

    const screenSize = useScreenSize();
    const [expand, setExpand] = useState(false);
    const [showMap, setShowMap] = useState(false);

    return (
        <TroubleshootingContainer>
            <h4>Warning</h4>
            <p>Waved appears to have lost internet connection.</p>
            {!expand? 
                <TroubleshootingButton primary small onClick={() => setExpand(true)}>See solutions</TroubleshootingButton>
            :<>
                <TroubleshootingButton secondary small onClick={() => setExpand(false)}>Hide solutions</TroubleshootingButton>
                <SolutionContainer>
                    <h4>Possible causes & solutions:</h4>
                    <ol>
                        <li>
                            The internet connection at your venue is down.
                            <ul>
                                <li>
                                    <p>
                                        Check the internet connection at your venue.<br/>
                                        This is usually the same network that your music system uses.
                                    </p></li>
                                <li><p>If your internet is still down, please contact your network administrator! </p></li>    
                            </ul>
                        </li>
                        <li>
                            Waved's main unit (Waved hub) is unplugged.
                            <ul>
                                {props.usesEthernet?
                                    <li><p>Locate the Waved hub and make sure it's connected to power (USB-C cable) and an Ethernet cable (with orange and green light).</p></li>
                                :
                                    <li><p>Locate the Waved hub and make sure it's connected to power (USB-C cable).</p></li>
                                }
                                <li><p>If it's already connected, disconnect the USB-C cable, wait 10 secs and reconnect.</p></li>
                                <li>
                                    <p>You can use the info below to locate the hub at your venue:</p>
                                    {!showMap?
                                        <TroubleshootingButton primary small onClick={() => setShowMap(true)}>Get info</TroubleshootingButton>
                                    :
                                        <TroubleshootingButton secondary small onClick={() => setShowMap(false)}>Hide info</TroubleshootingButton>
                                    }
                                </li>
                            </ul>
                        </li>
                    </ol>
                    {showMap?
                        <>
                            {screenSize.width > theme.screenSizes.medium?
                                <HubInfoContainer>
                                    {props.offlineHubs.map((hub) => (
                                        <InstallationInfoWrapper>
                                            <InstallationItemInfo
                                                columntitle={"Info"}
                                                selectedItem={{id: hub.hubId, name:hub.name, type: "HUB"}}
                                                customerId={hub.customerId}
                                                troubleshooting={true}
                                                readOnly={true}
                                            />
                                        </InstallationInfoWrapper>
                                    ))}
                                </HubInfoContainer>
                            :
                                <InstallationItemInfo
                                    columntitle={"Info"}
                                    selectedItem={{id: props.offlineHubs[0].hubId, name:props.offlineHubs[0].name, type: "HUB"}}
                                    customerId={props.offlineHubs[0].customerId}
                                    troubleshooting={true}
                                    readOnly={true}
                                />
                            }
                        </>
                    :<></>}
                </SolutionContainer>
            </>}
        </TroubleshootingContainer>
    )
} 

export default TroubleshootHubOffline;
