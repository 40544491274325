import React from 'react';
import heatmapTypes from '../heatmapTypes';
import Dropdown from '../../../UiComponents/Dropdown';

const intervals = [
    {
         name: heatmapTypes.dataIntervals.LAST_15_MINUTES,
         label: 'Last 15 minutes'
    }, {
        name: heatmapTypes.dataIntervals.LAST_4_HOURS,
        label: 'Last 4 hours'
    }, {
        name: heatmapTypes.dataIntervals.LAST_24_HOURS,
        label: 'Last 24 hours'
    }];

const HistoryModeDropdown = props => {

    var selectedInterval = intervals.find(interval => interval.name === props.selectedInterval);

    const onSelect = (item) => {
        props.setSelectedInterval(item.name);
    }

    return <div>
        <Dropdown items={intervals} selectedItem={selectedInterval} onClick={onSelect} />
    </div>
}

export default HistoryModeDropdown;